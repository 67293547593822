import {
  AutocompleteInput,
  ReferenceInput,
  useDataProvider,
  useGetIdentity,
} from 'react-admin';

import { Button } from '@mui/material';
import { UserRole } from '../@generated/schemas';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const UserInput = (props) => {
  const { watch, setValue } = useFormContext();
  const dataProvider = useDataProvider();
  const value = watch('userId');

  const { identity } = useGetIdentity();

  const defaultUserFilter = {
    deletedAt: {
      equals: null,
    },
    companyId:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? { equals: identity.companyId }
        : {},
  };

  const handleSelectUser = async () => {
    if (!value) return false;
    const user = await dataProvider.getOne('User', { id: value });

    setValue('firstName', user.data.firstName);
    setValue('lastName', user.data.lastName);
    setValue('address', user.data.address);
    setValue('houseNumber', user.data.houseNumber);
    setValue('city', user.data.city);
    setValue('country', user.data.country);
    setValue('birthdate', user.data.birthdate);
  };

  return (
    <Row>
      <ReferenceInput
        source="userId"
        reference="User"
        filter={defaultUserFilter}
        label="Connect to user"
        style={{ flex: 1, width: '100%' }}
        allowEmpty
        {...props}>
        <AutocompleteInput
          variant="outlined"
          size="small"
          style={{ flex: 1, width: '100%', marginRight: 20 }}
          optionText={(record) => {
            if (!record || !record.id) {
              return 'Clear';
            }
            return `${record.firstName || ''} ${record.lastName || ''} (${
              record.email
            })`;
          }}
        />
      </ReferenceInput>
      <Button
        size="small"
        disabled={!value}
        style={{ height: 35, float: 'left', marginTop: 12 }}
        variant="contained"
        onClick={handleSelectUser}>
        Prefill details
      </Button>
    </Row>
  );
};

export default UserInput;
