import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const fragment = gql`
  fragment ChargingStationTransactionFragment on ChargingStationTransaction {
    id
    transactionId
    installationId
    kwh
    vat
    lastStatus
    createdAt
    startDate
    finishAt
    endDate
    auth
    paymentHash
    stripeInvoiceId
    stripeInvoiceConfirmedAt
    stripePaymentIntentId
    stripePaymentIntentConfirmedAt
    user {
      id
      email
      firstName
      lastName
      phone
    }
    chargingStation {
      id
      status
      evesId
      status
      name
      latitude
      longitude
      operatorCompanyName
      operatorCompanyPerson
      operatorCompanyPhone
      operatorCompanyEmail
    }
    chargingStationRfid {
      id
      tag
      description
      owner
      billingAccount {
        id
        name
        contactPersonPhoneNumber
        contactPersonEmail
      }
    }
    chargingStationTransactionUsages {
      id
      kwh
      createdAt
    }
  }
`;

const fragmentList = gql`
  fragment ChargingStationTransactionFragment on ChargingStationTransaction {
    id
    transactionId
    installationId
    kwh
    lastStatus
    createdAt
    startDate
    finishAt
    endDate
    auth
    paymentHash
    stripeInvoiceId
    stripeInvoiceConfirmedAt
    stripePaymentIntentId
    stripePaymentIntentConfirmedAt
    user {
      id
      email
      firstName
      lastName
      phone
    }
    chargingStation {
      id
      evesId
      name
      latitude
      longitude
      operatorCompanyName
      operatorCompanyPerson
      operatorCompanyPhone
      operatorCompanyEmail
    }
    chargingStationRfid {
      id
      tag
      description
      owner
      billingAccount {
        id
        name
        contactPersonPhoneNumber
        contactPersonEmail
      }
    }
    calculation {
      tariffs {
        id
        name
        price
        amountPerMinute
        percentage
        usedkWh
        startDate
        endDate
      }
      totalCost
      totalkWh
      totalMinutes
    }
  }
`;

export const ChargingStationTransaction: ResourceView = {
  resource: 'ChargingStationTransaction',
  fragment: {
    one: {
      type: 'document',
      mode: 'replace',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'replace',
      doc: fragmentList,
    },
  },
};
