import {
  TextInput as AdminTextInput,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FunctionField,
  List as RAList,
  ReferenceInput,
  TextField,
  useGetIdentity,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';

import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CustomPagination from '../../CustomPagination';
import DateInput from '../../common/DateInput';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SelectInput from '../../common/SelectInput';
import { UserRole } from '../../@generated/schemas';
import { convertEnumFilterItems } from '../../libs/convertEnumFilterItems';
import { getTokenSalt } from '../../libs/tokenHash';

export const UserFilter = (props: any) => {
  const { identity } = useGetIdentity();

  return (
    <Filter {...props}>
      <AdminTextInput label="Search" source="q" key="search" alwaysOn />
      <SelectInput
        choices={[
          { id: 0, name: 'Level 0' },
          { id: 1, name: 'Level 1' },
          { id: 2, name: 'Level 2' },
        ]}
        source="userLevel"
      />
      <AdminTextInput source="email" key="email" />
      <AdminTextInput source="firstName" />
      <AdminTextInput source="lastName" />
      <DateInput source="phone" />
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <ReferenceInput reference="Company" source="companyId">
          <SelectInput optionText="name" alwaysOn />
        </ReferenceInput>
      )}
      <SelectInput
        source="userRole"
        choices={convertEnumFilterItems(UserRole)}
      />
      <DateInput source="createdAt_gte" label="Created at from" />
      <DateInput source="createdAt_lte" label="Created at to" />
    </Filter>
  );
};

const UnlockAccount = () => {
  const record = useRecordContext();
  const refreshData = useRefresh();
  const [update, { isLoading }] = useUpdate();
  const { identity } = useGetIdentity();

  const handleClick = async (data) => {
    await update('User', { id: record.id, data });
    refreshData();
  };

  if (!record.loginBlockedAt) {
    return (
      <Button
        variant="text"
        onClick={() =>
          handleClick({
            loginBlockedAt: new Date(),
            failedLoginAttempts: 0,
            tokenSalt: getTokenSalt(),
          })
        }
        color="primary"
        disabled={isLoading || identity?.id === record.id}
        startIcon={<LockIcon />}>
        Lock
      </Button>
    );
  }

  return (
    <Button
      variant="text"
      onClick={() =>
        handleClick({
          loginBlockedAt: {
            set: null,
          },
          failedLoginAttempts: 0,
        })
      }
      color="primary"
      disabled={isLoading || identity?.id === record.id}
      startIcon={<LockOpenIcon />}>
      Unlock
    </Button>
  );
};

const CustomDeleteButton = () => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();

  if (identity?.id === record?.id) return null;

  return <DeleteWithConfirmButton mutationMode="pessimistic" record={record} />;
};

export const UsersList = (props: any) => {
  const { identity } = useGetIdentity();
  const defaultFilter = {
    deletedAt: { equals: null },
    companyId:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? {
            equals: identity.companyId,
          }
        : {},
  };

  return (
    <RAList
      {...props}
      filters={<UserFilter />}
      filter={defaultFilter}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="email" />
        <FunctionField
          label="Company"
          render={(row) => row?.company?.name || 'Drivemycar'}
        />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phone" />
        <FunctionField
          label="User level"
          render={(row) => {
            if (row.userLevel.level < 50) {
              return '0';
            }
            if (row.userLevel.level < 100) {
              return '1';
            }
            return (
              <div style={{ display: 'flex' }}>
                <span style={{ marginTop: 2, marginRight: 8 }}>2</span>{' '}
                <CheckIcon style={{ color: 'green' }} />
              </div>
            );
          }}
        />
        <DateField source="loginBlockedAt" locales="de-DE" showTime />
        <DateField source="createdAt" locales="de-DE" showTime />
        <EditButton />
        <UnlockAccount />
        <CustomDeleteButton />
      </Datagrid>
    </RAList>
  );
};
