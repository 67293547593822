import { FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import {
  ReferenceArrayField,
  Tab,
  TabbedShowLayout,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {
  Vehicle,
  VehicleConnectionType,
  VehiclePhotoType,
} from '../../@generated/schemas';
import VehicleAdditionalInfo, {
  requiredFields as additionalInfoRequiredFields,
} from './additionalInfo';
import VehicleBasicDetails, {
  requiredFields as basicInfoRequiredFields,
} from './basicInfo';
import {
  VehiclePrices,
  requiredFields as pricesRequiredFields,
} from './prices';
import { useEffect, useState } from 'react';

import { Booking } from './booking';
import CheckIcon from '@mui/icons-material/Check';
import { TrendingUpOutlined } from '@mui/icons-material';
import { UnpublishReasonModal } from './unpublishReasonModal';
import { VehicleAvailability } from './availability';
import { VehicleDamageList } from '../vehicleDamages/vehicleDamages';
import { VehicleGallery } from './gallery';
import { VehicleInsurance } from './insurance';
import VehicleLocations from './locations';
import VehicleTroubleshoot from './troubleshoot';
import { get } from 'lodash';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const TABS = {
  basicInfo: 'basicInfo',
  additionalInfo: 'additionalInfo',
  gallery: 'gallery',
  locations: 'locations',
  availability: 'availability',
  prices: 'prices',
  booking: 'booking',
  troubleshoot: 'troubleshoot',
  insurance: 'insurance',
  damages: 'damages',
};

export const activateToggleTooltip = (
  formData: Vehicle,
  skipDisabled = false,
): string | boolean => {
  if (!formData?.isOwnerStripeOnboarded) {
    return 'User is not connected to stripe account';
  }
  if (!formData?.userId) {
    return 'Missing assigned user';
  }
  if (
    !formData?.qnr &&
    formData?.connectionType === VehicleConnectionType.Cloudboxx
  ) {
    return 'Cloudboxx should be connected';
  }
  if (
    !formData?.cloudBoxxActivatedAt &&
    formData?.connectionType === VehicleConnectionType.Cloudboxx
  ) {
    return 'Cloudboxx should be activated';
  }

  if (formData.ownInsurance) {
    if (
      !formData.vehicleInsuranceType?.name ||
      !formData.vehicleInsuranceType?.policyNumber ||
      !formData.vehicleInsuranceType?.policyHolder ||
      !formData.vehicleInsuranceType?.postalAddress
    ) {
      return 'Missing setup for own insurance';
    }
    if (formData.vehicleInsuranceType?.vehicleInsuranceRuleSets?.length === 0) {
      return 'Missing at least one ruleset for own insurance';
    }
  }

  if (!formData.ownInsurance && !formData.vehicleInsuranceType) {
    return 'Missing insurance';
  }

  if (formData?.disabled && !skipDisabled) {
    return formData.unpublishReason || 'Vehicle is not visible';
  }

  return false;
};

export const VehicleTabs = (props) => {
  const record = useRecordContext<Vehicle>();
  const notify = useNotify();
  const refreshData = useRefresh();
  const dataProvider = useDataProvider();
  const { formState, getValues, watch } = useFormContext();

  const allProperties = watch() as Vehicle;
  const vehiclePhotos =
    allProperties.vehiclePhotos?.filter(
      (image) => image.type === VehiclePhotoType.Gallery,
    ) || [];

  const [isOpenedReason, setOpenReason] = useState(false);

  const handleClose = () => setOpenReason(false);

  const handleSaveReason = async (value) => {
    const vehicle = await dataProvider.update<Vehicle>('Vehicle', {
      id: record?.id,
      data: {
        unpublishReason: typeof value === 'string' ? value : '',
        disabled: !allProperties.disabled,
      },
      previousData: {},
    });
    handleClose();
    refreshData();

    if (allProperties.disabled === true && vehicle?.data?.disabled === false) {
      notify('Vehicle is published!', { type: 'success' });
    } else {
      notify('Vehicle is not visible in the map!', { type: 'warning' });
    }
  };

  useEffect(() => {
    const values = getValues() as Vehicle;
    if (typeof activateToggleTooltip(values, true) === 'string') {
      notify(
        `Vehicle cannot be published due to: ${activateToggleTooltip(values)}`,
        { type: 'warning' },
      );
    }
  }, [
    allProperties.isOwnerStripeOnboarded,
    allProperties.userId,
    allProperties.qnr,
    allProperties.cloudBoxxActivatedAt,
    allProperties.ownInsurance,
    allProperties.vehicleInsuranceType,
  ]);

  let basicInfoFinished = false;
  let additionalInfoFinished = false;
  const galleryFinished = vehiclePhotos.length >= 5;
  const locationsFinished = record?.vehicleLocation?.length > 0;
  const availabilityFinished = record?.availability?.length > 0;
  let pricesFinished = false;
  let bookingDurationFinished = false;
  let insuranceFinished = false;

  if (
    allProperties.ownInsurance
      ? (allProperties.vehicleInsuranceType?.name &&
        allProperties.vehicleInsuranceType?.policyNumber &&
        allProperties.vehicleInsuranceType?.policyHolder &&
        allProperties.vehicleInsuranceType?.postalAddress &&
        allProperties.vehicleInsuranceType?.vehicleInsuranceRuleSets?.length >
        0) ||
      false
      : true
  ) {
    insuranceFinished = true;
  }

  if (
    record?.preBookingInterval &&
    record?.postBookingInterval &&
    record?.earlyOpenInterval
  ) {
    bookingDurationFinished = true;
  }

  // checking basic info
  const foundBasicInfoError = Object.keys(formState.errors).find((field) =>
    basicInfoRequiredFields.includes(field),
  );
  const foundBasicInfoRequiredField = basicInfoRequiredFields.find(
    (field) => record && !record[field],
  );
  if (!foundBasicInfoError && !foundBasicInfoRequiredField && record?.id) {
    basicInfoFinished = true;
  }

  // checking additional info
  const foundAdditionalInfoError = Object.keys(formState.errors).find((field) =>
    additionalInfoRequiredFields.includes(field),
  );
  const foundAdditionalInfoRequiredField = additionalInfoRequiredFields.find(
    (field) => {
      if (record?.connectionType === VehicleConnectionType.Cloudboxx) {
        if (field === 'smartcarVehicle') {
          // skipped
          return false;
        }

        return !record[field];
      } else if (record?.connectionType === VehicleConnectionType.Smartcar) {
        if (field === 'qnr') {
          // skipped
          return false;
        }

        return !record[field];
      } else if (record) {
        return !record[field];
      }
    },
  );
  if (!foundAdditionalInfoError && !foundAdditionalInfoRequiredField) {
    additionalInfoFinished = true;
  }

  // checking prices
  const foundPricesError = Object.keys(formState.errors).find((field) =>
    pricesRequiredFields.includes(field),
  );
  const foundPricesRequiredField = pricesRequiredFields.find(
    (field) => record && !record[field],
  );
  if (!foundPricesError && !foundPricesRequiredField) {
    pricesFinished = true;
  }

  const renderLabel = (label: string, isFinished = false) => {
    if (isFinished) {
      return (
        <Row>
          {label}
          <CheckIcon style={{ color: 'green' }} />
        </Row>
      );
    }
    return label;
  };

  return (
    <div style={{ position: 'relative' }}>
      <span style={{ display: 'flex' }}>
        <Typography variant="h5">
          {props.mode === 'edit' ? 'Edit vehicle' : 'Create vehicle'}
        </Typography>
        {props.mode === 'edit' && (
          <Tooltip
            placement="top"
            title={
              allProperties.disabled
                ? typeof activateToggleTooltip(allProperties) === 'string'
                  ? activateToggleTooltip(allProperties)
                  : 'Ready for submit'
                : 'Already in map'
            }>
            <div style={{ marginLeft: 20 }}>
              <FormControlLabel
                disabled={
                  typeof activateToggleTooltip(allProperties, true) === 'string'
                }
                onChange={() =>
                  allProperties?.disabled
                    ? handleSaveReason(true)
                    : setOpenReason(true)
                }
                control={<Switch checked={!allProperties.disabled} />}
                label={
                  allProperties?.disabled ||
                    !allProperties?.user ||
                    !allProperties?.isOwnerStripeOnboarded ||
                    !allProperties?.qnr ||
                    !allProperties?.cloudBoxxActivatedAt ||
                    !insuranceFinished
                    ? 'Hidden in map'
                    : 'Published in map'
                }
              />
            </div>
          </Tooltip>
        )}
        {record?.unpublishReason && (
          <div>
            Unpublish reason: <i>{record?.unpublishReason}</i>
          </div>
        )}
      </span>

      {props.mode === 'create' && (
        <VehicleBasicDetails options={props.options} />
      )}
      {props.mode === 'edit' && (
        <TabbedShowLayout>
          <Tab label={renderLabel('Basic', basicInfoFinished)} tabIndex={0}>
            <VehicleBasicDetails options={props.options} />
          </Tab>
          <Tab
            label={renderLabel('Additional', additionalInfoFinished)}
            path={TABS.additionalInfo}>
            <VehicleAdditionalInfo options={props.options} />
          </Tab>
          <Tab
            label={renderLabel('Gallery', galleryFinished)}
            path={TABS.gallery}>
            <VehicleGallery />
          </Tab>
          <Tab
            label={renderLabel('Locations', locationsFinished)}
            path={TABS.locations}>
            <VehicleLocations />
          </Tab>
          <Tab
            label={renderLabel('Availability', availabilityFinished)}
            path={TABS.availability}>
            <VehicleAvailability />
          </Tab>
          <Tab label={renderLabel('Prices', pricesFinished)} path={TABS.prices}>
            <VehiclePrices />
          </Tab>
          <Tab
            label={renderLabel('Booking', bookingDurationFinished)}
            path={TABS.booking}>
            <Booking />
          </Tab>
          <Tab
            label={renderLabel('Insurance', insuranceFinished)}
            path={TABS.insurance}
            disabled={!allProperties.ownInsurance}>
            <VehicleInsurance />
          </Tab>
          <Tab label={renderLabel('Troubleshoot')} path={TABS.troubleshoot}>
            <VehicleTroubleshoot />
          </Tab>
          <Tab label={renderLabel('Damages')} path={TABS.damages}>
            <ReferenceArrayField reference="VehicleDamage">
              <VehicleDamageList
                filter={{
                  vehicleId: { equals: record.id },
                }}
              />
            </ReferenceArrayField>
          </Tab>
        </TabbedShowLayout>
      )}

      <UnpublishReasonModal
        isOpened={isOpenedReason}
        handleClose={handleClose}
        onSave={handleSaveReason}
        unpublishReasonTypes={props.options?.unpublishReasonTypes}
      />
    </div>
  );
};
