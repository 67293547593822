export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAvailability = {
  __typename?: 'AggregateAvailability';
  _count?: Maybe<AvailabilityCountAggregate>;
  _min?: Maybe<AvailabilityMinAggregate>;
  _max?: Maybe<AvailabilityMaxAggregate>;
};

export type AggregateBillingAccount = {
  __typename?: 'AggregateBillingAccount';
  _count?: Maybe<BillingAccountCountAggregate>;
  _min?: Maybe<BillingAccountMinAggregate>;
  _max?: Maybe<BillingAccountMaxAggregate>;
};

export type AggregateBillingAccountDocument = {
  __typename?: 'AggregateBillingAccountDocument';
  _count?: Maybe<BillingAccountDocumentCountAggregate>;
  _min?: Maybe<BillingAccountDocumentMinAggregate>;
  _max?: Maybe<BillingAccountDocumentMaxAggregate>;
};

export type AggregateBiometricToken = {
  __typename?: 'AggregateBiometricToken';
  _count?: Maybe<BiometricTokenCountAggregate>;
  _min?: Maybe<BiometricTokenMinAggregate>;
  _max?: Maybe<BiometricTokenMaxAggregate>;
};

export type AggregateBodyType = {
  __typename?: 'AggregateBodyType';
  _count?: Maybe<BodyTypeCountAggregate>;
  _min?: Maybe<BodyTypeMinAggregate>;
  _max?: Maybe<BodyTypeMaxAggregate>;
};

export type AggregateBooking = {
  __typename?: 'AggregateBooking';
  _count?: Maybe<BookingCountAggregate>;
  _avg?: Maybe<BookingAvgAggregate>;
  _sum?: Maybe<BookingSumAggregate>;
  _min?: Maybe<BookingMinAggregate>;
  _max?: Maybe<BookingMaxAggregate>;
};

export type AggregateBookingAdditionalDriver = {
  __typename?: 'AggregateBookingAdditionalDriver';
  _count?: Maybe<BookingAdditionalDriverCountAggregate>;
  _min?: Maybe<BookingAdditionalDriverMinAggregate>;
  _max?: Maybe<BookingAdditionalDriverMaxAggregate>;
};

export type AggregateBookingAnswer = {
  __typename?: 'AggregateBookingAnswer';
  _count?: Maybe<BookingAnswerCountAggregate>;
  _min?: Maybe<BookingAnswerMinAggregate>;
  _max?: Maybe<BookingAnswerMaxAggregate>;
};

export type AggregateBookingAvailability = {
  __typename?: 'AggregateBookingAvailability';
  _count?: Maybe<BookingAvailabilityCountAggregate>;
  _min?: Maybe<BookingAvailabilityMinAggregate>;
  _max?: Maybe<BookingAvailabilityMaxAggregate>;
};

export type AggregateBookingChangeHistory = {
  __typename?: 'AggregateBookingChangeHistory';
  _count?: Maybe<BookingChangeHistoryCountAggregate>;
  _avg?: Maybe<BookingChangeHistoryAvgAggregate>;
  _sum?: Maybe<BookingChangeHistorySumAggregate>;
  _min?: Maybe<BookingChangeHistoryMinAggregate>;
  _max?: Maybe<BookingChangeHistoryMaxAggregate>;
};

export type AggregateBookingFuelPassword = {
  __typename?: 'AggregateBookingFuelPassword';
  _count?: Maybe<BookingFuelPasswordCountAggregate>;
  _min?: Maybe<BookingFuelPasswordMinAggregate>;
  _max?: Maybe<BookingFuelPasswordMaxAggregate>;
};

export type AggregateBookingQuestion = {
  __typename?: 'AggregateBookingQuestion';
  _count?: Maybe<BookingQuestionCountAggregate>;
  _avg?: Maybe<BookingQuestionAvgAggregate>;
  _sum?: Maybe<BookingQuestionSumAggregate>;
  _min?: Maybe<BookingQuestionMinAggregate>;
  _max?: Maybe<BookingQuestionMaxAggregate>;
};

export type AggregateBookingRefunding = {
  __typename?: 'AggregateBookingRefunding';
  _count?: Maybe<BookingRefundingCountAggregate>;
  _avg?: Maybe<BookingRefundingAvgAggregate>;
  _sum?: Maybe<BookingRefundingSumAggregate>;
  _min?: Maybe<BookingRefundingMinAggregate>;
  _max?: Maybe<BookingRefundingMaxAggregate>;
};

export type AggregateBookingVehicleDamages = {
  __typename?: 'AggregateBookingVehicleDamages';
  _count?: Maybe<BookingVehicleDamagesCountAggregate>;
  _min?: Maybe<BookingVehicleDamagesMinAggregate>;
  _max?: Maybe<BookingVehicleDamagesMaxAggregate>;
};

export type AggregateBrandType = {
  __typename?: 'AggregateBrandType';
  _count?: Maybe<BrandTypeCountAggregate>;
  _min?: Maybe<BrandTypeMinAggregate>;
  _max?: Maybe<BrandTypeMaxAggregate>;
};

export type AggregateChargingStation = {
  __typename?: 'AggregateChargingStation';
  _count?: Maybe<ChargingStationCountAggregate>;
  _avg?: Maybe<ChargingStationAvgAggregate>;
  _sum?: Maybe<ChargingStationSumAggregate>;
  _min?: Maybe<ChargingStationMinAggregate>;
  _max?: Maybe<ChargingStationMaxAggregate>;
};

export type AggregateChargingStationCluster = {
  __typename?: 'AggregateChargingStationCluster';
  _count?: Maybe<ChargingStationClusterCountAggregate>;
  _min?: Maybe<ChargingStationClusterMinAggregate>;
  _max?: Maybe<ChargingStationClusterMaxAggregate>;
};

export type AggregateChargingStationRfid = {
  __typename?: 'AggregateChargingStationRfid';
  _count?: Maybe<ChargingStationRfidCountAggregate>;
  _min?: Maybe<ChargingStationRfidMinAggregate>;
  _max?: Maybe<ChargingStationRfidMaxAggregate>;
};

export type AggregateChargingStationRfidAlias = {
  __typename?: 'AggregateChargingStationRfidAlias';
  _count?: Maybe<ChargingStationRfidAliasCountAggregate>;
  _min?: Maybe<ChargingStationRfidAliasMinAggregate>;
  _max?: Maybe<ChargingStationRfidAliasMaxAggregate>;
};

export type AggregateChargingStationRfidInvisia = {
  __typename?: 'AggregateChargingStationRfidInvisia';
  _count?: Maybe<ChargingStationRfidInvisiaCountAggregate>;
};

export type AggregateChargingStationTariff = {
  __typename?: 'AggregateChargingStationTariff';
  _count?: Maybe<ChargingStationTariffCountAggregate>;
  _avg?: Maybe<ChargingStationTariffAvgAggregate>;
  _sum?: Maybe<ChargingStationTariffSumAggregate>;
  _min?: Maybe<ChargingStationTariffMinAggregate>;
  _max?: Maybe<ChargingStationTariffMaxAggregate>;
};

export type AggregateChargingStationTariffRule = {
  __typename?: 'AggregateChargingStationTariffRule';
  _count?: Maybe<ChargingStationTariffRuleCountAggregate>;
  _avg?: Maybe<ChargingStationTariffRuleAvgAggregate>;
  _sum?: Maybe<ChargingStationTariffRuleSumAggregate>;
  _min?: Maybe<ChargingStationTariffRuleMinAggregate>;
  _max?: Maybe<ChargingStationTariffRuleMaxAggregate>;
};

export type AggregateChargingStationTransaction = {
  __typename?: 'AggregateChargingStationTransaction';
  _count?: Maybe<ChargingStationTransactionCountAggregate>;
  _avg?: Maybe<ChargingStationTransactionAvgAggregate>;
  _sum?: Maybe<ChargingStationTransactionSumAggregate>;
  _min?: Maybe<ChargingStationTransactionMinAggregate>;
  _max?: Maybe<ChargingStationTransactionMaxAggregate>;
};

export type AggregateChargingStationTransactionAppliedTariff = {
  __typename?: 'AggregateChargingStationTransactionAppliedTariff';
  _count?: Maybe<ChargingStationTransactionAppliedTariffCountAggregate>;
  _avg?: Maybe<ChargingStationTransactionAppliedTariffAvgAggregate>;
  _sum?: Maybe<ChargingStationTransactionAppliedTariffSumAggregate>;
  _min?: Maybe<ChargingStationTransactionAppliedTariffMinAggregate>;
  _max?: Maybe<ChargingStationTransactionAppliedTariffMaxAggregate>;
};

export type AggregateChargingStationTransactionReport = {
  __typename?: 'AggregateChargingStationTransactionReport';
  _count?: Maybe<ChargingStationTransactionReportCountAggregate>;
  _min?: Maybe<ChargingStationTransactionReportMinAggregate>;
  _max?: Maybe<ChargingStationTransactionReportMaxAggregate>;
};

export type AggregateChargingStationTransactionUsage = {
  __typename?: 'AggregateChargingStationTransactionUsage';
  _count?: Maybe<ChargingStationTransactionUsageCountAggregate>;
  _avg?: Maybe<ChargingStationTransactionUsageAvgAggregate>;
  _sum?: Maybe<ChargingStationTransactionUsageSumAggregate>;
  _min?: Maybe<ChargingStationTransactionUsageMinAggregate>;
  _max?: Maybe<ChargingStationTransactionUsageMaxAggregate>;
};

export type AggregateChildSeatType = {
  __typename?: 'AggregateChildSeatType';
  _count?: Maybe<ChildSeatTypeCountAggregate>;
  _min?: Maybe<ChildSeatTypeMinAggregate>;
  _max?: Maybe<ChildSeatTypeMaxAggregate>;
};

export type AggregateColorType = {
  __typename?: 'AggregateColorType';
  _count?: Maybe<ColorTypeCountAggregate>;
  _min?: Maybe<ColorTypeMinAggregate>;
  _max?: Maybe<ColorTypeMaxAggregate>;
};

export type AggregateCompany = {
  __typename?: 'AggregateCompany';
  _count?: Maybe<CompanyCountAggregate>;
  _avg?: Maybe<CompanyAvgAggregate>;
  _sum?: Maybe<CompanySumAggregate>;
  _min?: Maybe<CompanyMinAggregate>;
  _max?: Maybe<CompanyMaxAggregate>;
};

export type AggregateCompanyDocument = {
  __typename?: 'AggregateCompanyDocument';
  _count?: Maybe<CompanyDocumentCountAggregate>;
  _min?: Maybe<CompanyDocumentMinAggregate>;
  _max?: Maybe<CompanyDocumentMaxAggregate>;
};

export type AggregateConsumptionType = {
  __typename?: 'AggregateConsumptionType';
  _count?: Maybe<ConsumptionTypeCountAggregate>;
  _min?: Maybe<ConsumptionTypeMinAggregate>;
  _max?: Maybe<ConsumptionTypeMaxAggregate>;
};

export type AggregateDoorType = {
  __typename?: 'AggregateDoorType';
  _count?: Maybe<DoorTypeCountAggregate>;
  _min?: Maybe<DoorTypeMinAggregate>;
  _max?: Maybe<DoorTypeMaxAggregate>;
};

export type AggregateDriveType = {
  __typename?: 'AggregateDriveType';
  _count?: Maybe<DriveTypeCountAggregate>;
  _min?: Maybe<DriveTypeMinAggregate>;
  _max?: Maybe<DriveTypeMaxAggregate>;
};

export type AggregateEmissionCodeType = {
  __typename?: 'AggregateEmissionCodeType';
  _count?: Maybe<EmissionCodeTypeCountAggregate>;
  _min?: Maybe<EmissionCodeTypeMinAggregate>;
  _max?: Maybe<EmissionCodeTypeMaxAggregate>;
};

export type AggregateEnergyEfficiencyType = {
  __typename?: 'AggregateEnergyEfficiencyType';
  _count?: Maybe<EnergyEfficiencyTypeCountAggregate>;
  _min?: Maybe<EnergyEfficiencyTypeMinAggregate>;
  _max?: Maybe<EnergyEfficiencyTypeMaxAggregate>;
};

export type AggregateEquipmentType = {
  __typename?: 'AggregateEquipmentType';
  _count?: Maybe<EquipmentTypeCountAggregate>;
  _min?: Maybe<EquipmentTypeMinAggregate>;
  _max?: Maybe<EquipmentTypeMaxAggregate>;
};

export type AggregateFuelType = {
  __typename?: 'AggregateFuelType';
  _count?: Maybe<FuelTypeCountAggregate>;
  _min?: Maybe<FuelTypeMinAggregate>;
  _max?: Maybe<FuelTypeMaxAggregate>;
};

export type AggregateGateController = {
  __typename?: 'AggregateGateController';
  _count?: Maybe<GateControllerCountAggregate>;
  _avg?: Maybe<GateControllerAvgAggregate>;
  _sum?: Maybe<GateControllerSumAggregate>;
  _min?: Maybe<GateControllerMinAggregate>;
  _max?: Maybe<GateControllerMaxAggregate>;
};

export type AggregateGateControllerDocument = {
  __typename?: 'AggregateGateControllerDocument';
  _count?: Maybe<GateControllerDocumentCountAggregate>;
  _min?: Maybe<GateControllerDocumentMinAggregate>;
  _max?: Maybe<GateControllerDocumentMaxAggregate>;
};

export type AggregateGateControllerRelUser = {
  __typename?: 'AggregateGateControllerRelUser';
  _count?: Maybe<GateControllerRelUserCountAggregate>;
  _min?: Maybe<GateControllerRelUserMinAggregate>;
  _max?: Maybe<GateControllerRelUserMaxAggregate>;
};

export type AggregateGateControllerRelVehicle = {
  __typename?: 'AggregateGateControllerRelVehicle';
  _count?: Maybe<GateControllerRelVehicleCountAggregate>;
  _min?: Maybe<GateControllerRelVehicleMinAggregate>;
  _max?: Maybe<GateControllerRelVehicleMaxAggregate>;
};

export type AggregateGateControllerRule = {
  __typename?: 'AggregateGateControllerRule';
  _count?: Maybe<GateControllerRuleCountAggregate>;
  _avg?: Maybe<GateControllerRuleAvgAggregate>;
  _sum?: Maybe<GateControllerRuleSumAggregate>;
  _min?: Maybe<GateControllerRuleMinAggregate>;
  _max?: Maybe<GateControllerRuleMaxAggregate>;
};

export type AggregateGateControllerRuleSet = {
  __typename?: 'AggregateGateControllerRuleSet';
  _count?: Maybe<GateControllerRuleSetCountAggregate>;
  _min?: Maybe<GateControllerRuleSetMinAggregate>;
  _max?: Maybe<GateControllerRuleSetMaxAggregate>;
};

export type AggregateGateControllerTransaction = {
  __typename?: 'AggregateGateControllerTransaction';
  _count?: Maybe<GateControllerTransactionCountAggregate>;
  _min?: Maybe<GateControllerTransactionMinAggregate>;
  _max?: Maybe<GateControllerTransactionMaxAggregate>;
};

export type AggregateGateControllerType = {
  __typename?: 'AggregateGateControllerType';
  _count?: Maybe<GateControllerTypeCountAggregate>;
  _min?: Maybe<GateControllerTypeMinAggregate>;
  _max?: Maybe<GateControllerTypeMaxAggregate>;
};

export type AggregateMessage = {
  __typename?: 'AggregateMessage';
  _count?: Maybe<MessageCountAggregate>;
  _min?: Maybe<MessageMinAggregate>;
  _max?: Maybe<MessageMaxAggregate>;
};

export type AggregateModelType = {
  __typename?: 'AggregateModelType';
  _count?: Maybe<ModelTypeCountAggregate>;
  _min?: Maybe<ModelTypeMinAggregate>;
  _max?: Maybe<ModelTypeMaxAggregate>;
};

export type AggregateNotificationDevices = {
  __typename?: 'AggregateNotificationDevices';
  _count?: Maybe<NotificationDevicesCountAggregate>;
  _min?: Maybe<NotificationDevicesMinAggregate>;
  _max?: Maybe<NotificationDevicesMaxAggregate>;
};

export type AggregateParticularUseType = {
  __typename?: 'AggregateParticularUseType';
  _count?: Maybe<ParticularUseTypeCountAggregate>;
  _min?: Maybe<ParticularUseTypeMinAggregate>;
  _max?: Maybe<ParticularUseTypeMaxAggregate>;
};

export type AggregatePxlVisionManualVerification = {
  __typename?: 'AggregatePxlVisionManualVerification';
  _count?: Maybe<PxlVisionManualVerificationCountAggregate>;
  _min?: Maybe<PxlVisionManualVerificationMinAggregate>;
  _max?: Maybe<PxlVisionManualVerificationMaxAggregate>;
};

export type AggregateRevokeReasonType = {
  __typename?: 'AggregateRevokeReasonType';
  _count?: Maybe<RevokeReasonTypeCountAggregate>;
  _min?: Maybe<RevokeReasonTypeMinAggregate>;
  _max?: Maybe<RevokeReasonTypeMaxAggregate>;
};

export type AggregateSeatFrontType = {
  __typename?: 'AggregateSeatFrontType';
  _count?: Maybe<SeatFrontTypeCountAggregate>;
  _min?: Maybe<SeatFrontTypeMinAggregate>;
  _max?: Maybe<SeatFrontTypeMaxAggregate>;
};

export type AggregateSeatType = {
  __typename?: 'AggregateSeatType';
  _count?: Maybe<SeatTypeCountAggregate>;
  _min?: Maybe<SeatTypeMinAggregate>;
  _max?: Maybe<SeatTypeMaxAggregate>;
};

export type AggregateSharedVehicleGroup = {
  __typename?: 'AggregateSharedVehicleGroup';
  _count?: Maybe<SharedVehicleGroupCountAggregate>;
  _avg?: Maybe<SharedVehicleGroupAvgAggregate>;
  _sum?: Maybe<SharedVehicleGroupSumAggregate>;
  _min?: Maybe<SharedVehicleGroupMinAggregate>;
  _max?: Maybe<SharedVehicleGroupMaxAggregate>;
};

export type AggregateSharedVehicleGroupInvitedEmail = {
  __typename?: 'AggregateSharedVehicleGroupInvitedEmail';
  _count?: Maybe<SharedVehicleGroupInvitedEmailCountAggregate>;
  _min?: Maybe<SharedVehicleGroupInvitedEmailMinAggregate>;
  _max?: Maybe<SharedVehicleGroupInvitedEmailMaxAggregate>;
};

export type AggregateSharedVehicleGroupRelAvailability = {
  __typename?: 'AggregateSharedVehicleGroupRelAvailability';
  _count?: Maybe<SharedVehicleGroupRelAvailabilityCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelAvailabilityMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelAvailabilityMaxAggregate>;
};

export type AggregateSharedVehicleGroupRelCompany = {
  __typename?: 'AggregateSharedVehicleGroupRelCompany';
  _count?: Maybe<SharedVehicleGroupRelCompanyCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelCompanyMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelCompanyMaxAggregate>;
};

export type AggregateSharedVehicleGroupRelUser = {
  __typename?: 'AggregateSharedVehicleGroupRelUser';
  _count?: Maybe<SharedVehicleGroupRelUserCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelUserMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelUserMaxAggregate>;
};

export type AggregateSharedVehicleGroupRelVehicle = {
  __typename?: 'AggregateSharedVehicleGroupRelVehicle';
  _count?: Maybe<SharedVehicleGroupRelVehicleCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelVehicleMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelVehicleMaxAggregate>;
};

export type AggregateShieldColorType = {
  __typename?: 'AggregateShieldColorType';
  _count?: Maybe<ShieldColorTypeCountAggregate>;
  _min?: Maybe<ShieldColorTypeMinAggregate>;
  _max?: Maybe<ShieldColorTypeMaxAggregate>;
};

export type AggregateSmartcarAuth = {
  __typename?: 'AggregateSmartcarAuth';
  _count?: Maybe<SmartcarAuthCountAggregate>;
  _min?: Maybe<SmartcarAuthMinAggregate>;
  _max?: Maybe<SmartcarAuthMaxAggregate>;
};

export type AggregateTransmissionType = {
  __typename?: 'AggregateTransmissionType';
  _count?: Maybe<TransmissionTypeCountAggregate>;
  _min?: Maybe<TransmissionTypeMinAggregate>;
  _max?: Maybe<TransmissionTypeMaxAggregate>;
};

export type AggregateUnpublishReasonType = {
  __typename?: 'AggregateUnpublishReasonType';
  _count?: Maybe<UnpublishReasonTypeCountAggregate>;
  _min?: Maybe<UnpublishReasonTypeMinAggregate>;
  _max?: Maybe<UnpublishReasonTypeMaxAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _avg?: Maybe<UserAvgAggregate>;
  _sum?: Maybe<UserSumAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _max?: Maybe<UserMaxAggregate>;
};

export type AggregateUserInvitedEmail = {
  __typename?: 'AggregateUserInvitedEmail';
  _count?: Maybe<UserInvitedEmailCountAggregate>;
  _min?: Maybe<UserInvitedEmailMinAggregate>;
  _max?: Maybe<UserInvitedEmailMaxAggregate>;
};

export type AggregateVehicle = {
  __typename?: 'AggregateVehicle';
  _count?: Maybe<VehicleCountAggregate>;
  _avg?: Maybe<VehicleAvgAggregate>;
  _sum?: Maybe<VehicleSumAggregate>;
  _min?: Maybe<VehicleMinAggregate>;
  _max?: Maybe<VehicleMaxAggregate>;
};

export type AggregateVehicleCloudBoxxLog = {
  __typename?: 'AggregateVehicleCloudBoxxLog';
  _count?: Maybe<VehicleCloudBoxxLogCountAggregate>;
  _min?: Maybe<VehicleCloudBoxxLogMinAggregate>;
  _max?: Maybe<VehicleCloudBoxxLogMaxAggregate>;
};

export type AggregateVehicleCloudBoxxStatusLog = {
  __typename?: 'AggregateVehicleCloudBoxxStatusLog';
  _count?: Maybe<VehicleCloudBoxxStatusLogCountAggregate>;
  _min?: Maybe<VehicleCloudBoxxStatusLogMinAggregate>;
  _max?: Maybe<VehicleCloudBoxxStatusLogMaxAggregate>;
};

export type AggregateVehicleConnection = {
  __typename?: 'AggregateVehicleConnection';
  _count?: Maybe<VehicleConnectionCountAggregate>;
  _min?: Maybe<VehicleConnectionMinAggregate>;
  _max?: Maybe<VehicleConnectionMaxAggregate>;
};

export type AggregateVehicleDamage = {
  __typename?: 'AggregateVehicleDamage';
  _count?: Maybe<VehicleDamageCountAggregate>;
  _avg?: Maybe<VehicleDamageAvgAggregate>;
  _sum?: Maybe<VehicleDamageSumAggregate>;
  _min?: Maybe<VehicleDamageMinAggregate>;
  _max?: Maybe<VehicleDamageMaxAggregate>;
};

export type AggregateVehicleDamageComment = {
  __typename?: 'AggregateVehicleDamageComment';
  _count?: Maybe<VehicleDamageCommentCountAggregate>;
  _min?: Maybe<VehicleDamageCommentMinAggregate>;
  _max?: Maybe<VehicleDamageCommentMaxAggregate>;
};

export type AggregateVehicleEquipment = {
  __typename?: 'AggregateVehicleEquipment';
  _count?: Maybe<VehicleEquipmentCountAggregate>;
  _min?: Maybe<VehicleEquipmentMinAggregate>;
  _max?: Maybe<VehicleEquipmentMaxAggregate>;
};

export type AggregateVehicleInsuranceDocument = {
  __typename?: 'AggregateVehicleInsuranceDocument';
  _count?: Maybe<VehicleInsuranceDocumentCountAggregate>;
  _min?: Maybe<VehicleInsuranceDocumentMinAggregate>;
  _max?: Maybe<VehicleInsuranceDocumentMaxAggregate>;
};

export type AggregateVehicleInsuranceRule = {
  __typename?: 'AggregateVehicleInsuranceRule';
  _count?: Maybe<VehicleInsuranceRuleCountAggregate>;
  _avg?: Maybe<VehicleInsuranceRuleAvgAggregate>;
  _sum?: Maybe<VehicleInsuranceRuleSumAggregate>;
  _min?: Maybe<VehicleInsuranceRuleMinAggregate>;
  _max?: Maybe<VehicleInsuranceRuleMaxAggregate>;
};

export type AggregateVehicleInsuranceRuleSet = {
  __typename?: 'AggregateVehicleInsuranceRuleSet';
  _count?: Maybe<VehicleInsuranceRuleSetCountAggregate>;
  _avg?: Maybe<VehicleInsuranceRuleSetAvgAggregate>;
  _sum?: Maybe<VehicleInsuranceRuleSetSumAggregate>;
  _min?: Maybe<VehicleInsuranceRuleSetMinAggregate>;
  _max?: Maybe<VehicleInsuranceRuleSetMaxAggregate>;
};

export type AggregateVehicleInsuranceType = {
  __typename?: 'AggregateVehicleInsuranceType';
  _count?: Maybe<VehicleInsuranceTypeCountAggregate>;
  _avg?: Maybe<VehicleInsuranceTypeAvgAggregate>;
  _sum?: Maybe<VehicleInsuranceTypeSumAggregate>;
  _min?: Maybe<VehicleInsuranceTypeMinAggregate>;
  _max?: Maybe<VehicleInsuranceTypeMaxAggregate>;
};

export type AggregateVehicleKindType = {
  __typename?: 'AggregateVehicleKindType';
  _count?: Maybe<VehicleKindTypeCountAggregate>;
  _min?: Maybe<VehicleKindTypeMinAggregate>;
  _max?: Maybe<VehicleKindTypeMaxAggregate>;
};

export type AggregateVehicleLocation = {
  __typename?: 'AggregateVehicleLocation';
  _count?: Maybe<VehicleLocationCountAggregate>;
  _avg?: Maybe<VehicleLocationAvgAggregate>;
  _sum?: Maybe<VehicleLocationSumAggregate>;
  _min?: Maybe<VehicleLocationMinAggregate>;
  _max?: Maybe<VehicleLocationMaxAggregate>;
};

export type AggregateVehiclePhoto = {
  __typename?: 'AggregateVehiclePhoto';
  _count?: Maybe<VehiclePhotoCountAggregate>;
  _avg?: Maybe<VehiclePhotoAvgAggregate>;
  _sum?: Maybe<VehiclePhotoSumAggregate>;
  _min?: Maybe<VehiclePhotoMinAggregate>;
  _max?: Maybe<VehiclePhotoMaxAggregate>;
};

export type AggregateVehicleReport = {
  __typename?: 'AggregateVehicleReport';
  _count?: Maybe<VehicleReportCountAggregate>;
  _avg?: Maybe<VehicleReportAvgAggregate>;
  _sum?: Maybe<VehicleReportSumAggregate>;
  _min?: Maybe<VehicleReportMinAggregate>;
  _max?: Maybe<VehicleReportMaxAggregate>;
};

export type AggregateVehicleType = {
  __typename?: 'AggregateVehicleType';
  _count?: Maybe<VehicleTypeCountAggregate>;
  _min?: Maybe<VehicleTypeMinAggregate>;
  _max?: Maybe<VehicleTypeMaxAggregate>;
};

export type Availability = {
  __typename?: 'Availability';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicleId: Scalars['String'];
  availabilityId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  _count?: Maybe<AvailabilityCount>;
  vehicle: Vehicle;
  availability?: Maybe<Availability>;
  availabilityChildren: Array<Availability>;
  user: User;
  bookingAvailability: Array<BookingAvailability>;
  sharedVehicleGroupRelAvailability: Array<SharedVehicleGroupRelAvailability>;
};

export type AvailabilityAvailabilityChildrenArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<AvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AvailabilityScalarFieldEnum>>;
};

export type AvailabilityBookingAvailabilityArgs = {
  where?: Maybe<BookingAvailabilityWhereInput>;
  orderBy?: Maybe<Array<BookingAvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAvailabilityScalarFieldEnum>>;
};

export type AvailabilitySharedVehicleGroupRelAvailabilityArgs = {
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupRelAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelAvailabilityScalarFieldEnum>>;
};

export type AvailabilityCount = {
  __typename?: 'AvailabilityCount';
  availabilityChildren: Scalars['Int'];
  bookingAvailability: Scalars['Int'];
  sharedVehicleGroupRelAvailability: Scalars['Int'];
};

export type AvailabilityCountAggregate = {
  __typename?: 'AvailabilityCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  isAvailable: Scalars['Int'];
  comment: Scalars['Int'];
  startDateTime: Scalars['Int'];
  endDateTime: Scalars['Int'];
  type: Scalars['Int'];
  vehicleId: Scalars['Int'];
  availabilityId: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AvailabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  startDateTime?: Maybe<SortOrder>;
  endDateTime?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type AvailabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicle: VehicleCreateNestedOneWithoutAvailabilityInput;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityCreateNestedManyWithoutAvailabilityInput>;
  user: UserCreateNestedOneWithoutAvailabilityInput;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutAvailabilityInput>;
};

export type AvailabilityCreateManyAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicleId: Scalars['String'];
  userId: Scalars['String'];
};

export type AvailabilityCreateManyAvailabilityInputEnvelope = {
  data: Array<AvailabilityCreateManyAvailabilityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AvailabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicleId: Scalars['String'];
  availabilityId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AvailabilityCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicleId: Scalars['String'];
  availabilityId?: Maybe<Scalars['String']>;
};

export type AvailabilityCreateManyUserInputEnvelope = {
  data: Array<AvailabilityCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AvailabilityCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  availabilityId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AvailabilityCreateManyVehicleInputEnvelope = {
  data: Array<AvailabilityCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AvailabilityCreateNestedManyWithoutAvailabilityInput = {
  create?: Maybe<Array<AvailabilityCreateWithoutAvailabilityInput>>;
  connectOrCreate?: Maybe<
    Array<AvailabilityCreateOrConnectWithoutAvailabilityInput>
  >;
  createMany?: Maybe<AvailabilityCreateManyAvailabilityInputEnvelope>;
  connect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
};

export type AvailabilityCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<AvailabilityCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AvailabilityCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<AvailabilityCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
};

export type AvailabilityCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<AvailabilityCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<AvailabilityCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<AvailabilityCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
};

export type AvailabilityCreateNestedOneWithoutAvailabilityChildrenInput = {
  create?: Maybe<AvailabilityCreateWithoutAvailabilityChildrenInput>;
  connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutAvailabilityChildrenInput>;
  connect?: Maybe<AvailabilityWhereUniqueInput>;
};

export type AvailabilityCreateNestedOneWithoutBookingAvailabilityInput = {
  create?: Maybe<AvailabilityCreateWithoutBookingAvailabilityInput>;
  connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutBookingAvailabilityInput>;
  connect?: Maybe<AvailabilityWhereUniqueInput>;
};

export type AvailabilityCreateNestedOneWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    create?: Maybe<AvailabilityCreateWithoutSharedVehicleGroupRelAvailabilityInput>;
    connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutSharedVehicleGroupRelAvailabilityInput>;
    connect?: Maybe<AvailabilityWhereUniqueInput>;
  };

export type AvailabilityCreateOrConnectWithoutAvailabilityChildrenInput = {
  where: AvailabilityWhereUniqueInput;
  create: AvailabilityCreateWithoutAvailabilityChildrenInput;
};

export type AvailabilityCreateOrConnectWithoutAvailabilityInput = {
  where: AvailabilityWhereUniqueInput;
  create: AvailabilityCreateWithoutAvailabilityInput;
};

export type AvailabilityCreateOrConnectWithoutBookingAvailabilityInput = {
  where: AvailabilityWhereUniqueInput;
  create: AvailabilityCreateWithoutBookingAvailabilityInput;
};

export type AvailabilityCreateOrConnectWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    where: AvailabilityWhereUniqueInput;
    create: AvailabilityCreateWithoutSharedVehicleGroupRelAvailabilityInput;
  };

export type AvailabilityCreateOrConnectWithoutUserInput = {
  where: AvailabilityWhereUniqueInput;
  create: AvailabilityCreateWithoutUserInput;
};

export type AvailabilityCreateOrConnectWithoutVehicleInput = {
  where: AvailabilityWhereUniqueInput;
  create: AvailabilityCreateWithoutVehicleInput;
};

export type AvailabilityCreateWithoutAvailabilityChildrenInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicle: VehicleCreateNestedOneWithoutAvailabilityInput;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutAvailabilityChildrenInput>;
  user: UserCreateNestedOneWithoutAvailabilityInput;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutAvailabilityInput>;
};

export type AvailabilityCreateWithoutAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicle: VehicleCreateNestedOneWithoutAvailabilityInput;
  availabilityChildren?: Maybe<AvailabilityCreateNestedManyWithoutAvailabilityInput>;
  user: UserCreateNestedOneWithoutAvailabilityInput;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutAvailabilityInput>;
};

export type AvailabilityCreateWithoutBookingAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicle: VehicleCreateNestedOneWithoutAvailabilityInput;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityCreateNestedManyWithoutAvailabilityInput>;
  user: UserCreateNestedOneWithoutAvailabilityInput;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutAvailabilityInput>;
};

export type AvailabilityCreateWithoutSharedVehicleGroupRelAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicle: VehicleCreateNestedOneWithoutAvailabilityInput;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityCreateNestedManyWithoutAvailabilityInput>;
  user: UserCreateNestedOneWithoutAvailabilityInput;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutAvailabilityInput>;
};

export type AvailabilityCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicle: VehicleCreateNestedOneWithoutAvailabilityInput;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityCreateNestedManyWithoutAvailabilityInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutAvailabilityInput>;
};

export type AvailabilityCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityCreateNestedManyWithoutAvailabilityInput>;
  user: UserCreateNestedOneWithoutAvailabilityInput;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutAvailabilityInput>;
};

export type AvailabilityGroupBy = {
  __typename?: 'AvailabilityGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type: AvailabilityType;
  vehicleId: Scalars['String'];
  availabilityId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  _count?: Maybe<AvailabilityCountAggregate>;
  _min?: Maybe<AvailabilityMinAggregate>;
  _max?: Maybe<AvailabilityMaxAggregate>;
};

export type AvailabilityListRelationFilter = {
  every?: Maybe<AvailabilityWhereInput>;
  some?: Maybe<AvailabilityWhereInput>;
  none?: Maybe<AvailabilityWhereInput>;
};

export type AvailabilityMaxAggregate = {
  __typename?: 'AvailabilityMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type?: Maybe<AvailabilityType>;
  vehicleId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type AvailabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  startDateTime?: Maybe<SortOrder>;
  endDateTime?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type AvailabilityMinAggregate = {
  __typename?: 'AvailabilityMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  type?: Maybe<AvailabilityType>;
  vehicleId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type AvailabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  startDateTime?: Maybe<SortOrder>;
  endDateTime?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type AvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type AvailabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  startDateTime?: Maybe<SortOrder>;
  endDateTime?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<AvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<AvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<AvailabilityMinOrderByAggregateInput>;
};

export type AvailabilityOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  startDateTime?: Maybe<SortOrder>;
  endDateTime?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  availability?: Maybe<AvailabilityOrderByWithRelationInput>;
  availabilityId?: Maybe<SortOrder>;
  availabilityChildren?: Maybe<AvailabilityOrderByRelationAggregateInput>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  bookingAvailability?: Maybe<BookingAvailabilityOrderByRelationAggregateInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityOrderByRelationAggregateInput>;
};

export type AvailabilityRelationFilter = {
  is?: Maybe<AvailabilityWhereInput>;
  isNot?: Maybe<AvailabilityWhereInput>;
};

export enum AvailabilityScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ExpiredAt = 'expiredAt',
  IsAvailable = 'isAvailable',
  Comment = 'comment',
  StartDateTime = 'startDateTime',
  EndDateTime = 'endDateTime',
  Type = 'type',
  VehicleId = 'vehicleId',
  AvailabilityId = 'availabilityId',
  UserId = 'userId',
}

export type AvailabilityScalarWhereInput = {
  AND?: Maybe<Array<AvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<AvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<AvailabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  isAvailable?: Maybe<BoolFilter>;
  comment?: Maybe<StringNullableFilter>;
  startDateTime?: Maybe<DateTimeNullableFilter>;
  endDateTime?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumAvailabilityTypeFilter>;
  vehicleId?: Maybe<StringFilter>;
  availabilityId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringFilter>;
};

export type AvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AvailabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isAvailable?: Maybe<BoolWithAggregatesFilter>;
  comment?: Maybe<StringNullableWithAggregatesFilter>;
  startDateTime?: Maybe<DateTimeNullableWithAggregatesFilter>;
  endDateTime?: Maybe<DateTimeNullableWithAggregatesFilter>;
  type?: Maybe<EnumAvailabilityTypeWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  availabilityId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export enum AvailabilityType {
  Single = 'SINGLE',
  RegularDaily = 'REGULAR_DAILY',
  RegularWeekly = 'REGULAR_WEEKLY',
  RegularMonthly = 'REGULAR_MONTHLY',
}

export type AvailabilityUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutAvailabilityInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityUpdateManyWithoutAvailabilityInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutAvailabilityInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutAvailabilityInput>;
};

export type AvailabilityUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateManyWithWhereWithoutAvailabilityInput = {
  where: AvailabilityScalarWhereInput;
  data: AvailabilityUpdateManyMutationInput;
};

export type AvailabilityUpdateManyWithWhereWithoutUserInput = {
  where: AvailabilityScalarWhereInput;
  data: AvailabilityUpdateManyMutationInput;
};

export type AvailabilityUpdateManyWithWhereWithoutVehicleInput = {
  where: AvailabilityScalarWhereInput;
  data: AvailabilityUpdateManyMutationInput;
};

export type AvailabilityUpdateManyWithoutAvailabilityInput = {
  create?: Maybe<Array<AvailabilityCreateWithoutAvailabilityInput>>;
  connectOrCreate?: Maybe<
    Array<AvailabilityCreateOrConnectWithoutAvailabilityInput>
  >;
  upsert?: Maybe<
    Array<AvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput>
  >;
  createMany?: Maybe<AvailabilityCreateManyAvailabilityInputEnvelope>;
  set?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  update?: Maybe<
    Array<AvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput>
  >;
  updateMany?: Maybe<
    Array<AvailabilityUpdateManyWithWhereWithoutAvailabilityInput>
  >;
  deleteMany?: Maybe<Array<AvailabilityScalarWhereInput>>;
};

export type AvailabilityUpdateManyWithoutUserInput = {
  create?: Maybe<Array<AvailabilityCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AvailabilityCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<AvailabilityUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<AvailabilityCreateManyUserInputEnvelope>;
  set?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AvailabilityUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<AvailabilityUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<AvailabilityScalarWhereInput>>;
};

export type AvailabilityUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<AvailabilityCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<AvailabilityCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<Array<AvailabilityUpsertWithWhereUniqueWithoutVehicleInput>>;
  createMany?: Maybe<AvailabilityCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<AvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AvailabilityUpdateWithWhereUniqueWithoutVehicleInput>>;
  updateMany?: Maybe<Array<AvailabilityUpdateManyWithWhereWithoutVehicleInput>>;
  deleteMany?: Maybe<Array<AvailabilityScalarWhereInput>>;
};

export type AvailabilityUpdateOneRequiredWithoutBookingAvailabilityInput = {
  create?: Maybe<AvailabilityCreateWithoutBookingAvailabilityInput>;
  connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutBookingAvailabilityInput>;
  upsert?: Maybe<AvailabilityUpsertWithoutBookingAvailabilityInput>;
  connect?: Maybe<AvailabilityWhereUniqueInput>;
  update?: Maybe<AvailabilityUpdateWithoutBookingAvailabilityInput>;
};

export type AvailabilityUpdateOneWithoutAvailabilityChildrenInput = {
  create?: Maybe<AvailabilityCreateWithoutAvailabilityChildrenInput>;
  connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutAvailabilityChildrenInput>;
  upsert?: Maybe<AvailabilityUpsertWithoutAvailabilityChildrenInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AvailabilityWhereUniqueInput>;
  update?: Maybe<AvailabilityUpdateWithoutAvailabilityChildrenInput>;
};

export type AvailabilityUpdateOneWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    create?: Maybe<AvailabilityCreateWithoutSharedVehicleGroupRelAvailabilityInput>;
    connectOrCreate?: Maybe<AvailabilityCreateOrConnectWithoutSharedVehicleGroupRelAvailabilityInput>;
    upsert?: Maybe<AvailabilityUpsertWithoutSharedVehicleGroupRelAvailabilityInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<AvailabilityWhereUniqueInput>;
    update?: Maybe<AvailabilityUpdateWithoutSharedVehicleGroupRelAvailabilityInput>;
  };

export type AvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput = {
  where: AvailabilityWhereUniqueInput;
  data: AvailabilityUpdateWithoutAvailabilityInput;
};

export type AvailabilityUpdateWithWhereUniqueWithoutUserInput = {
  where: AvailabilityWhereUniqueInput;
  data: AvailabilityUpdateWithoutUserInput;
};

export type AvailabilityUpdateWithWhereUniqueWithoutVehicleInput = {
  where: AvailabilityWhereUniqueInput;
  data: AvailabilityUpdateWithoutVehicleInput;
};

export type AvailabilityUpdateWithoutAvailabilityChildrenInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutAvailabilityInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutAvailabilityChildrenInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutAvailabilityInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutAvailabilityInput>;
};

export type AvailabilityUpdateWithoutAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutAvailabilityInput>;
  availabilityChildren?: Maybe<AvailabilityUpdateManyWithoutAvailabilityInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutAvailabilityInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutAvailabilityInput>;
};

export type AvailabilityUpdateWithoutBookingAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutAvailabilityInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityUpdateManyWithoutAvailabilityInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutAvailabilityInput>;
};

export type AvailabilityUpdateWithoutSharedVehicleGroupRelAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutAvailabilityInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityUpdateManyWithoutAvailabilityInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutAvailabilityInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutAvailabilityInput>;
};

export type AvailabilityUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutAvailabilityInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityUpdateManyWithoutAvailabilityInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutAvailabilityInput>;
};

export type AvailabilityUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  isAvailable?: Maybe<BoolFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDateTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumAvailabilityTypeFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutAvailabilityChildrenInput>;
  availabilityChildren?: Maybe<AvailabilityUpdateManyWithoutAvailabilityInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutAvailabilityInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutAvailabilityInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutAvailabilityInput>;
};

export type AvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput = {
  where: AvailabilityWhereUniqueInput;
  update: AvailabilityUpdateWithoutAvailabilityInput;
  create: AvailabilityCreateWithoutAvailabilityInput;
};

export type AvailabilityUpsertWithWhereUniqueWithoutUserInput = {
  where: AvailabilityWhereUniqueInput;
  update: AvailabilityUpdateWithoutUserInput;
  create: AvailabilityCreateWithoutUserInput;
};

export type AvailabilityUpsertWithWhereUniqueWithoutVehicleInput = {
  where: AvailabilityWhereUniqueInput;
  update: AvailabilityUpdateWithoutVehicleInput;
  create: AvailabilityCreateWithoutVehicleInput;
};

export type AvailabilityUpsertWithoutAvailabilityChildrenInput = {
  update: AvailabilityUpdateWithoutAvailabilityChildrenInput;
  create: AvailabilityCreateWithoutAvailabilityChildrenInput;
};

export type AvailabilityUpsertWithoutBookingAvailabilityInput = {
  update: AvailabilityUpdateWithoutBookingAvailabilityInput;
  create: AvailabilityCreateWithoutBookingAvailabilityInput;
};

export type AvailabilityUpsertWithoutSharedVehicleGroupRelAvailabilityInput = {
  update: AvailabilityUpdateWithoutSharedVehicleGroupRelAvailabilityInput;
  create: AvailabilityCreateWithoutSharedVehicleGroupRelAvailabilityInput;
};

export type AvailabilityWhereInput = {
  AND?: Maybe<Array<AvailabilityWhereInput>>;
  OR?: Maybe<Array<AvailabilityWhereInput>>;
  NOT?: Maybe<Array<AvailabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  isAvailable?: Maybe<BoolFilter>;
  comment?: Maybe<StringNullableFilter>;
  startDateTime?: Maybe<DateTimeNullableFilter>;
  endDateTime?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumAvailabilityTypeFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  availability?: Maybe<AvailabilityRelationFilter>;
  availabilityId?: Maybe<StringNullableFilter>;
  availabilityChildren?: Maybe<AvailabilityListRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  bookingAvailability?: Maybe<BookingAvailabilityListRelationFilter>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityListRelationFilter>;
};

export type AvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BillingAccount = {
  __typename?: 'BillingAccount';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  companyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency: BillingAccountPaymentFrequency;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  _count?: Maybe<BillingAccountCount>;
  company?: Maybe<Company>;
  billingAccountDocuments: Array<BillingAccountDocument>;
  chargingStationRFIDs: Array<ChargingStationRfid>;
  chargingStationTransactionReports: Array<ChargingStationTransactionReport>;
};

export type BillingAccountBillingAccountDocumentsArgs = {
  where?: Maybe<BillingAccountDocumentWhereInput>;
  orderBy?: Maybe<Array<BillingAccountDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BillingAccountDocumentScalarFieldEnum>>;
};

export type BillingAccountChargingStationRfiDsArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidScalarFieldEnum>>;
};

export type BillingAccountChargingStationTransactionReportsArgs = {
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionReportOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionReportScalarFieldEnum>>;
};

export type BillingAccountCount = {
  __typename?: 'BillingAccountCount';
  billingAccountDocuments: Scalars['Int'];
  chargingStationRFIDs: Scalars['Int'];
  chargingStationTransactionReports: Scalars['Int'];
};

export type BillingAccountCountAggregate = {
  __typename?: 'BillingAccountCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  companyId: Scalars['Int'];
  name: Scalars['Int'];
  departmentName: Scalars['Int'];
  address: Scalars['Int'];
  nationality: Scalars['Int'];
  houseNumber: Scalars['Int'];
  postCode: Scalars['Int'];
  city: Scalars['Int'];
  country: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  email: Scalars['Int'];
  uidNumber: Scalars['Int'];
  paymentFrequency: Scalars['Int'];
  contactPersonFullname: Scalars['Int'];
  contactPersonPhoneNumber: Scalars['Int'];
  contactPersonEmail: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripeAccountConfirmedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BillingAccountCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  departmentName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  paymentFrequency?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
};

export type BillingAccountCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<CompanyCreateNestedOneWithoutBillingAccountsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentCreateNestedManyWithoutBillingAccountInput>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidCreateNestedManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutBillingAccountInput>;
};

export type BillingAccountCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
};

export type BillingAccountCreateManyCompanyInputEnvelope = {
  data: Array<BillingAccountCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BillingAccountCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  companyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
};

export type BillingAccountCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<BillingAccountCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<BillingAccountCreateOrConnectWithoutCompanyInput>
  >;
  createMany?: Maybe<BillingAccountCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<BillingAccountWhereUniqueInput>>;
};

export type BillingAccountCreateNestedOneWithoutBillingAccountDocumentsInput = {
  create?: Maybe<BillingAccountCreateWithoutBillingAccountDocumentsInput>;
  connectOrCreate?: Maybe<BillingAccountCreateOrConnectWithoutBillingAccountDocumentsInput>;
  connect?: Maybe<BillingAccountWhereUniqueInput>;
};

export type BillingAccountCreateNestedOneWithoutChargingStationRfiDsInput = {
  create?: Maybe<BillingAccountCreateWithoutChargingStationRfiDsInput>;
  connectOrCreate?: Maybe<BillingAccountCreateOrConnectWithoutChargingStationRfiDsInput>;
  connect?: Maybe<BillingAccountWhereUniqueInput>;
};

export type BillingAccountCreateNestedOneWithoutChargingStationTransactionReportsInput =
  {
    create?: Maybe<BillingAccountCreateWithoutChargingStationTransactionReportsInput>;
    connectOrCreate?: Maybe<BillingAccountCreateOrConnectWithoutChargingStationTransactionReportsInput>;
    connect?: Maybe<BillingAccountWhereUniqueInput>;
  };

export type BillingAccountCreateOrConnectWithoutBillingAccountDocumentsInput = {
  where: BillingAccountWhereUniqueInput;
  create: BillingAccountCreateWithoutBillingAccountDocumentsInput;
};

export type BillingAccountCreateOrConnectWithoutChargingStationRfiDsInput = {
  where: BillingAccountWhereUniqueInput;
  create: BillingAccountCreateWithoutChargingStationRfiDsInput;
};

export type BillingAccountCreateOrConnectWithoutChargingStationTransactionReportsInput =
  {
    where: BillingAccountWhereUniqueInput;
    create: BillingAccountCreateWithoutChargingStationTransactionReportsInput;
  };

export type BillingAccountCreateOrConnectWithoutCompanyInput = {
  where: BillingAccountWhereUniqueInput;
  create: BillingAccountCreateWithoutCompanyInput;
};

export type BillingAccountCreateWithoutBillingAccountDocumentsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<CompanyCreateNestedOneWithoutBillingAccountsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidCreateNestedManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutBillingAccountInput>;
};

export type BillingAccountCreateWithoutChargingStationRfiDsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<CompanyCreateNestedOneWithoutBillingAccountsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentCreateNestedManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutBillingAccountInput>;
};

export type BillingAccountCreateWithoutChargingStationTransactionReportsInput =
  {
    id?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    company?: Maybe<CompanyCreateNestedOneWithoutBillingAccountsInput>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
    departmentName?: Maybe<Scalars['String']>;
    address: Scalars['String'];
    nationality?: Maybe<Scalars['String']>;
    houseNumber: Scalars['String'];
    postCode: Scalars['String'];
    city: Scalars['String'];
    country: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    uidNumber?: Maybe<Scalars['String']>;
    paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
    contactPersonFullname?: Maybe<Scalars['String']>;
    contactPersonPhoneNumber?: Maybe<Scalars['String']>;
    contactPersonEmail?: Maybe<Scalars['String']>;
    stripeCustomerId?: Maybe<Scalars['String']>;
    stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
    billingAccountDocuments?: Maybe<BillingAccountDocumentCreateNestedManyWithoutBillingAccountInput>;
    chargingStationRFIDs?: Maybe<ChargingStationRfidCreateNestedManyWithoutBillingAccountInput>;
  };

export type BillingAccountCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentCreateNestedManyWithoutBillingAccountInput>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidCreateNestedManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutBillingAccountInput>;
};

export type BillingAccountDocument = {
  __typename?: 'BillingAccountDocument';
  id: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  billingAccountId?: Maybe<Scalars['String']>;
  billingAccount?: Maybe<BillingAccount>;
};

export type BillingAccountDocumentCountAggregate = {
  __typename?: 'BillingAccountDocumentCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  originalName: Scalars['Int'];
  billingAccountId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BillingAccountDocumentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
};

export type BillingAccountDocumentCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
  billingAccount?: Maybe<BillingAccountCreateNestedOneWithoutBillingAccountDocumentsInput>;
};

export type BillingAccountDocumentCreateManyBillingAccountInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
};

export type BillingAccountDocumentCreateManyBillingAccountInputEnvelope = {
  data: Array<BillingAccountDocumentCreateManyBillingAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BillingAccountDocumentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
  billingAccountId?: Maybe<Scalars['String']>;
};

export type BillingAccountDocumentCreateNestedManyWithoutBillingAccountInput = {
  create?: Maybe<Array<BillingAccountDocumentCreateWithoutBillingAccountInput>>;
  connectOrCreate?: Maybe<
    Array<BillingAccountDocumentCreateOrConnectWithoutBillingAccountInput>
  >;
  createMany?: Maybe<BillingAccountDocumentCreateManyBillingAccountInputEnvelope>;
  connect?: Maybe<Array<BillingAccountDocumentWhereUniqueInput>>;
};

export type BillingAccountDocumentCreateOrConnectWithoutBillingAccountInput = {
  where: BillingAccountDocumentWhereUniqueInput;
  create: BillingAccountDocumentCreateWithoutBillingAccountInput;
};

export type BillingAccountDocumentCreateWithoutBillingAccountInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
};

export type BillingAccountDocumentGroupBy = {
  __typename?: 'BillingAccountDocumentGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  billingAccountId?: Maybe<Scalars['String']>;
  _count?: Maybe<BillingAccountDocumentCountAggregate>;
  _min?: Maybe<BillingAccountDocumentMinAggregate>;
  _max?: Maybe<BillingAccountDocumentMaxAggregate>;
};

export type BillingAccountDocumentListRelationFilter = {
  every?: Maybe<BillingAccountDocumentWhereInput>;
  some?: Maybe<BillingAccountDocumentWhereInput>;
  none?: Maybe<BillingAccountDocumentWhereInput>;
};

export type BillingAccountDocumentMaxAggregate = {
  __typename?: 'BillingAccountDocumentMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
};

export type BillingAccountDocumentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
};

export type BillingAccountDocumentMinAggregate = {
  __typename?: 'BillingAccountDocumentMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
};

export type BillingAccountDocumentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
};

export type BillingAccountDocumentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BillingAccountDocumentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
  _count?: Maybe<BillingAccountDocumentCountOrderByAggregateInput>;
  _max?: Maybe<BillingAccountDocumentMaxOrderByAggregateInput>;
  _min?: Maybe<BillingAccountDocumentMinOrderByAggregateInput>;
};

export type BillingAccountDocumentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  billingAccount?: Maybe<BillingAccountOrderByWithRelationInput>;
  billingAccountId?: Maybe<SortOrder>;
};

export enum BillingAccountDocumentScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  OriginalName = 'originalName',
  BillingAccountId = 'billingAccountId',
}

export type BillingAccountDocumentScalarWhereInput = {
  AND?: Maybe<Array<BillingAccountDocumentScalarWhereInput>>;
  OR?: Maybe<Array<BillingAccountDocumentScalarWhereInput>>;
  NOT?: Maybe<Array<BillingAccountDocumentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  originalName?: Maybe<StringFilter>;
  billingAccountId?: Maybe<StringNullableFilter>;
};

export type BillingAccountDocumentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BillingAccountDocumentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BillingAccountDocumentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BillingAccountDocumentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  originalName?: Maybe<StringWithAggregatesFilter>;
  billingAccountId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BillingAccountDocumentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutBillingAccountDocumentsInput>;
};

export type BillingAccountDocumentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BillingAccountDocumentUpdateManyWithWhereWithoutBillingAccountInput =
  {
    where: BillingAccountDocumentScalarWhereInput;
    data: BillingAccountDocumentUpdateManyMutationInput;
  };

export type BillingAccountDocumentUpdateManyWithoutBillingAccountInput = {
  create?: Maybe<Array<BillingAccountDocumentCreateWithoutBillingAccountInput>>;
  connectOrCreate?: Maybe<
    Array<BillingAccountDocumentCreateOrConnectWithoutBillingAccountInput>
  >;
  upsert?: Maybe<
    Array<BillingAccountDocumentUpsertWithWhereUniqueWithoutBillingAccountInput>
  >;
  createMany?: Maybe<BillingAccountDocumentCreateManyBillingAccountInputEnvelope>;
  set?: Maybe<Array<BillingAccountDocumentWhereUniqueInput>>;
  disconnect?: Maybe<Array<BillingAccountDocumentWhereUniqueInput>>;
  delete?: Maybe<Array<BillingAccountDocumentWhereUniqueInput>>;
  connect?: Maybe<Array<BillingAccountDocumentWhereUniqueInput>>;
  update?: Maybe<
    Array<BillingAccountDocumentUpdateWithWhereUniqueWithoutBillingAccountInput>
  >;
  updateMany?: Maybe<
    Array<BillingAccountDocumentUpdateManyWithWhereWithoutBillingAccountInput>
  >;
  deleteMany?: Maybe<Array<BillingAccountDocumentScalarWhereInput>>;
};

export type BillingAccountDocumentUpdateWithWhereUniqueWithoutBillingAccountInput =
  {
    where: BillingAccountDocumentWhereUniqueInput;
    data: BillingAccountDocumentUpdateWithoutBillingAccountInput;
  };

export type BillingAccountDocumentUpdateWithoutBillingAccountInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BillingAccountDocumentUpsertWithWhereUniqueWithoutBillingAccountInput =
  {
    where: BillingAccountDocumentWhereUniqueInput;
    update: BillingAccountDocumentUpdateWithoutBillingAccountInput;
    create: BillingAccountDocumentCreateWithoutBillingAccountInput;
  };

export type BillingAccountDocumentWhereInput = {
  AND?: Maybe<Array<BillingAccountDocumentWhereInput>>;
  OR?: Maybe<Array<BillingAccountDocumentWhereInput>>;
  NOT?: Maybe<Array<BillingAccountDocumentWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  originalName?: Maybe<StringFilter>;
  billingAccount?: Maybe<BillingAccountRelationFilter>;
  billingAccountId?: Maybe<StringNullableFilter>;
};

export type BillingAccountDocumentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BillingAccountGroupBy = {
  __typename?: 'BillingAccountGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  companyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  departmentName?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  houseNumber: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency: BillingAccountPaymentFrequency;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  _count?: Maybe<BillingAccountCountAggregate>;
  _min?: Maybe<BillingAccountMinAggregate>;
  _max?: Maybe<BillingAccountMaxAggregate>;
};

export type BillingAccountListRelationFilter = {
  every?: Maybe<BillingAccountWhereInput>;
  some?: Maybe<BillingAccountWhereInput>;
  none?: Maybe<BillingAccountWhereInput>;
};

export type BillingAccountMaxAggregate = {
  __typename?: 'BillingAccountMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  companyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  departmentName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
};

export type BillingAccountMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  departmentName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  paymentFrequency?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
};

export type BillingAccountMinAggregate = {
  __typename?: 'BillingAccountMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  companyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  departmentName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  paymentFrequency?: Maybe<BillingAccountPaymentFrequency>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
};

export type BillingAccountMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  departmentName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  paymentFrequency?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
};

export type BillingAccountOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BillingAccountOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  departmentName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  paymentFrequency?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  _count?: Maybe<BillingAccountCountOrderByAggregateInput>;
  _max?: Maybe<BillingAccountMaxOrderByAggregateInput>;
  _min?: Maybe<BillingAccountMinOrderByAggregateInput>;
};

export type BillingAccountOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  deletedAt?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  departmentName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  paymentFrequency?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentOrderByRelationAggregateInput>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidOrderByRelationAggregateInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportOrderByRelationAggregateInput>;
};

export enum BillingAccountPaymentFrequency {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export type BillingAccountRelationFilter = {
  is?: Maybe<BillingAccountWhereInput>;
  isNot?: Maybe<BillingAccountWhereInput>;
};

export enum BillingAccountScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  CompanyId = 'companyId',
  Name = 'name',
  DepartmentName = 'departmentName',
  Address = 'address',
  Nationality = 'nationality',
  HouseNumber = 'houseNumber',
  PostCode = 'postCode',
  City = 'city',
  Country = 'country',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  UidNumber = 'uidNumber',
  PaymentFrequency = 'paymentFrequency',
  ContactPersonFullname = 'contactPersonFullname',
  ContactPersonPhoneNumber = 'contactPersonPhoneNumber',
  ContactPersonEmail = 'contactPersonEmail',
  StripeCustomerId = 'stripeCustomerId',
  StripeAccountConfirmedAt = 'stripeAccountConfirmedAt',
}

export type BillingAccountScalarWhereInput = {
  AND?: Maybe<Array<BillingAccountScalarWhereInput>>;
  OR?: Maybe<Array<BillingAccountScalarWhereInput>>;
  NOT?: Maybe<Array<BillingAccountScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  departmentName?: Maybe<StringNullableFilter>;
  address?: Maybe<StringFilter>;
  nationality?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringFilter>;
  postCode?: Maybe<StringFilter>;
  city?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  uidNumber?: Maybe<StringNullableFilter>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFilter>;
  contactPersonFullname?: Maybe<StringNullableFilter>;
  contactPersonPhoneNumber?: Maybe<StringNullableFilter>;
  contactPersonEmail?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableFilter>;
};

export type BillingAccountScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BillingAccountScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BillingAccountScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BillingAccountScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  departmentName?: Maybe<StringNullableWithAggregatesFilter>;
  address?: Maybe<StringWithAggregatesFilter>;
  nationality?: Maybe<StringNullableWithAggregatesFilter>;
  houseNumber?: Maybe<StringWithAggregatesFilter>;
  postCode?: Maybe<StringWithAggregatesFilter>;
  city?: Maybe<StringWithAggregatesFilter>;
  country?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  uidNumber?: Maybe<StringNullableWithAggregatesFilter>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyWithAggregatesFilter>;
  contactPersonFullname?: Maybe<StringNullableWithAggregatesFilter>;
  contactPersonPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  contactPersonEmail?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type BillingAccountUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutBillingAccountsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  departmentName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<StringFieldUpdateOperationsInput>;
  postCode?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentUpdateManyWithoutBillingAccountInput>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidUpdateManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutBillingAccountInput>;
};

export type BillingAccountUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  departmentName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<StringFieldUpdateOperationsInput>;
  postCode?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BillingAccountUpdateManyWithWhereWithoutCompanyInput = {
  where: BillingAccountScalarWhereInput;
  data: BillingAccountUpdateManyMutationInput;
};

export type BillingAccountUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<BillingAccountCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<BillingAccountCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<Array<BillingAccountUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<BillingAccountCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<BillingAccountWhereUniqueInput>>;
  disconnect?: Maybe<Array<BillingAccountWhereUniqueInput>>;
  delete?: Maybe<Array<BillingAccountWhereUniqueInput>>;
  connect?: Maybe<Array<BillingAccountWhereUniqueInput>>;
  update?: Maybe<Array<BillingAccountUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<
    Array<BillingAccountUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<BillingAccountScalarWhereInput>>;
};

export type BillingAccountUpdateOneWithoutBillingAccountDocumentsInput = {
  create?: Maybe<BillingAccountCreateWithoutBillingAccountDocumentsInput>;
  connectOrCreate?: Maybe<BillingAccountCreateOrConnectWithoutBillingAccountDocumentsInput>;
  upsert?: Maybe<BillingAccountUpsertWithoutBillingAccountDocumentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BillingAccountWhereUniqueInput>;
  update?: Maybe<BillingAccountUpdateWithoutBillingAccountDocumentsInput>;
};

export type BillingAccountUpdateOneWithoutChargingStationRfiDsInput = {
  create?: Maybe<BillingAccountCreateWithoutChargingStationRfiDsInput>;
  connectOrCreate?: Maybe<BillingAccountCreateOrConnectWithoutChargingStationRfiDsInput>;
  upsert?: Maybe<BillingAccountUpsertWithoutChargingStationRfiDsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BillingAccountWhereUniqueInput>;
  update?: Maybe<BillingAccountUpdateWithoutChargingStationRfiDsInput>;
};

export type BillingAccountUpdateOneWithoutChargingStationTransactionReportsInput =
  {
    create?: Maybe<BillingAccountCreateWithoutChargingStationTransactionReportsInput>;
    connectOrCreate?: Maybe<BillingAccountCreateOrConnectWithoutChargingStationTransactionReportsInput>;
    upsert?: Maybe<BillingAccountUpsertWithoutChargingStationTransactionReportsInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<BillingAccountWhereUniqueInput>;
    update?: Maybe<BillingAccountUpdateWithoutChargingStationTransactionReportsInput>;
  };

export type BillingAccountUpdateWithWhereUniqueWithoutCompanyInput = {
  where: BillingAccountWhereUniqueInput;
  data: BillingAccountUpdateWithoutCompanyInput;
};

export type BillingAccountUpdateWithoutBillingAccountDocumentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutBillingAccountsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  departmentName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<StringFieldUpdateOperationsInput>;
  postCode?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidUpdateManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutBillingAccountInput>;
};

export type BillingAccountUpdateWithoutChargingStationRfiDsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutBillingAccountsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  departmentName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<StringFieldUpdateOperationsInput>;
  postCode?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentUpdateManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutBillingAccountInput>;
};

export type BillingAccountUpdateWithoutChargingStationTransactionReportsInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    company?: Maybe<CompanyUpdateOneWithoutBillingAccountsInput>;
    deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    departmentName?: Maybe<NullableStringFieldUpdateOperationsInput>;
    address?: Maybe<StringFieldUpdateOperationsInput>;
    nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
    houseNumber?: Maybe<StringFieldUpdateOperationsInput>;
    postCode?: Maybe<StringFieldUpdateOperationsInput>;
    city?: Maybe<StringFieldUpdateOperationsInput>;
    country?: Maybe<StringFieldUpdateOperationsInput>;
    phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
    email?: Maybe<NullableStringFieldUpdateOperationsInput>;
    uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
    paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFieldUpdateOperationsInput>;
    contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
    contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
    contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    billingAccountDocuments?: Maybe<BillingAccountDocumentUpdateManyWithoutBillingAccountInput>;
    chargingStationRFIDs?: Maybe<ChargingStationRfidUpdateManyWithoutBillingAccountInput>;
  };

export type BillingAccountUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  departmentName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<StringFieldUpdateOperationsInput>;
  postCode?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentUpdateManyWithoutBillingAccountInput>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidUpdateManyWithoutBillingAccountInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutBillingAccountInput>;
};

export type BillingAccountUpsertWithWhereUniqueWithoutCompanyInput = {
  where: BillingAccountWhereUniqueInput;
  update: BillingAccountUpdateWithoutCompanyInput;
  create: BillingAccountCreateWithoutCompanyInput;
};

export type BillingAccountUpsertWithoutBillingAccountDocumentsInput = {
  update: BillingAccountUpdateWithoutBillingAccountDocumentsInput;
  create: BillingAccountCreateWithoutBillingAccountDocumentsInput;
};

export type BillingAccountUpsertWithoutChargingStationRfiDsInput = {
  update: BillingAccountUpdateWithoutChargingStationRfiDsInput;
  create: BillingAccountCreateWithoutChargingStationRfiDsInput;
};

export type BillingAccountUpsertWithoutChargingStationTransactionReportsInput =
  {
    update: BillingAccountUpdateWithoutChargingStationTransactionReportsInput;
    create: BillingAccountCreateWithoutChargingStationTransactionReportsInput;
  };

export type BillingAccountWhereInput = {
  AND?: Maybe<Array<BillingAccountWhereInput>>;
  OR?: Maybe<Array<BillingAccountWhereInput>>;
  NOT?: Maybe<Array<BillingAccountWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  company?: Maybe<CompanyRelationFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  departmentName?: Maybe<StringNullableFilter>;
  address?: Maybe<StringFilter>;
  nationality?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringFilter>;
  postCode?: Maybe<StringFilter>;
  city?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  uidNumber?: Maybe<StringNullableFilter>;
  paymentFrequency?: Maybe<EnumBillingAccountPaymentFrequencyFilter>;
  contactPersonFullname?: Maybe<StringNullableFilter>;
  contactPersonPhoneNumber?: Maybe<StringNullableFilter>;
  contactPersonEmail?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableFilter>;
  billingAccountDocuments?: Maybe<BillingAccountDocumentListRelationFilter>;
  chargingStationRFIDs?: Maybe<ChargingStationRfidListRelationFilter>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportListRelationFilter>;
};

export type BillingAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BiometricToken = {
  __typename?: 'BiometricToken';
  id: Scalars['String'];
  token: Scalars['String'];
  deviceId: Scalars['String'];
  userId: Scalars['String'];
  user: User;
};

export type BiometricTokenCountAggregate = {
  __typename?: 'BiometricTokenCountAggregate';
  id: Scalars['Int'];
  token: Scalars['Int'];
  deviceId: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BiometricTokenCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type BiometricTokenCreateInput = {
  id?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  deviceId: Scalars['String'];
  user: UserCreateNestedOneWithoutBiometricTokenInput;
};

export type BiometricTokenCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  deviceId: Scalars['String'];
  userId: Scalars['String'];
};

export type BiometricTokenCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  deviceId: Scalars['String'];
};

export type BiometricTokenCreateManyUserInputEnvelope = {
  data: Array<BiometricTokenCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BiometricTokenCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BiometricTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BiometricTokenCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<BiometricTokenCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BiometricTokenWhereUniqueInput>>;
};

export type BiometricTokenCreateOrConnectWithoutUserInput = {
  where: BiometricTokenWhereUniqueInput;
  create: BiometricTokenCreateWithoutUserInput;
};

export type BiometricTokenCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  deviceId: Scalars['String'];
};

export type BiometricTokenGroupBy = {
  __typename?: 'BiometricTokenGroupBy';
  id: Scalars['String'];
  token: Scalars['String'];
  deviceId: Scalars['String'];
  userId: Scalars['String'];
  _count?: Maybe<BiometricTokenCountAggregate>;
  _min?: Maybe<BiometricTokenMinAggregate>;
  _max?: Maybe<BiometricTokenMaxAggregate>;
};

export type BiometricTokenListRelationFilter = {
  every?: Maybe<BiometricTokenWhereInput>;
  some?: Maybe<BiometricTokenWhereInput>;
  none?: Maybe<BiometricTokenWhereInput>;
};

export type BiometricTokenMaxAggregate = {
  __typename?: 'BiometricTokenMaxAggregate';
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type BiometricTokenMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type BiometricTokenMinAggregate = {
  __typename?: 'BiometricTokenMinAggregate';
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type BiometricTokenMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type BiometricTokenOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BiometricTokenOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<BiometricTokenCountOrderByAggregateInput>;
  _max?: Maybe<BiometricTokenMaxOrderByAggregateInput>;
  _min?: Maybe<BiometricTokenMinOrderByAggregateInput>;
};

export type BiometricTokenOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum BiometricTokenScalarFieldEnum {
  Id = 'id',
  Token = 'token',
  DeviceId = 'deviceId',
  UserId = 'userId',
}

export type BiometricTokenScalarWhereInput = {
  AND?: Maybe<Array<BiometricTokenScalarWhereInput>>;
  OR?: Maybe<Array<BiometricTokenScalarWhereInput>>;
  NOT?: Maybe<Array<BiometricTokenScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  token?: Maybe<StringFilter>;
  deviceId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type BiometricTokenScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BiometricTokenScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BiometricTokenScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BiometricTokenScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  token?: Maybe<StringWithAggregatesFilter>;
  deviceId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type BiometricTokenUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  deviceId?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBiometricTokenInput>;
};

export type BiometricTokenUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  deviceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BiometricTokenUpdateManyWithWhereWithoutUserInput = {
  where: BiometricTokenScalarWhereInput;
  data: BiometricTokenUpdateManyMutationInput;
};

export type BiometricTokenUpdateManyWithoutUserInput = {
  create?: Maybe<Array<BiometricTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BiometricTokenCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<BiometricTokenUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<BiometricTokenCreateManyUserInputEnvelope>;
  set?: Maybe<Array<BiometricTokenWhereUniqueInput>>;
  disconnect?: Maybe<Array<BiometricTokenWhereUniqueInput>>;
  delete?: Maybe<Array<BiometricTokenWhereUniqueInput>>;
  connect?: Maybe<Array<BiometricTokenWhereUniqueInput>>;
  update?: Maybe<Array<BiometricTokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<BiometricTokenUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<BiometricTokenScalarWhereInput>>;
};

export type BiometricTokenUpdateWithWhereUniqueWithoutUserInput = {
  where: BiometricTokenWhereUniqueInput;
  data: BiometricTokenUpdateWithoutUserInput;
};

export type BiometricTokenUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  deviceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BiometricTokenUpsertWithWhereUniqueWithoutUserInput = {
  where: BiometricTokenWhereUniqueInput;
  update: BiometricTokenUpdateWithoutUserInput;
  create: BiometricTokenCreateWithoutUserInput;
};

export type BiometricTokenWhereInput = {
  AND?: Maybe<Array<BiometricTokenWhereInput>>;
  OR?: Maybe<Array<BiometricTokenWhereInput>>;
  NOT?: Maybe<Array<BiometricTokenWhereInput>>;
  id?: Maybe<StringFilter>;
  token?: Maybe<StringFilter>;
  deviceId?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type BiometricTokenWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
};

export type BodyType = {
  __typename?: 'BodyType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<BodyTypeCount>;
  vehicle: Array<Vehicle>;
};

export type BodyTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type BodyTypeCount = {
  __typename?: 'BodyTypeCount';
  vehicle: Scalars['Int'];
};

export type BodyTypeCountAggregate = {
  __typename?: 'BodyTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BodyTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type BodyTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutBodyTypeInput>;
};

export type BodyTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type BodyTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<BodyTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<BodyTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<BodyTypeWhereUniqueInput>;
};

export type BodyTypeCreateOrConnectWithoutVehicleInput = {
  where: BodyTypeWhereUniqueInput;
  create: BodyTypeCreateWithoutVehicleInput;
};

export type BodyTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type BodyTypeGroupBy = {
  __typename?: 'BodyTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<BodyTypeCountAggregate>;
  _min?: Maybe<BodyTypeMinAggregate>;
  _max?: Maybe<BodyTypeMaxAggregate>;
};

export type BodyTypeMaxAggregate = {
  __typename?: 'BodyTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type BodyTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type BodyTypeMinAggregate = {
  __typename?: 'BodyTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type BodyTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type BodyTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<BodyTypeCountOrderByAggregateInput>;
  _max?: Maybe<BodyTypeMaxOrderByAggregateInput>;
  _min?: Maybe<BodyTypeMinOrderByAggregateInput>;
};

export type BodyTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type BodyTypeRelationFilter = {
  is?: Maybe<BodyTypeWhereInput>;
  isNot?: Maybe<BodyTypeWhereInput>;
};

export enum BodyTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type BodyTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BodyTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BodyTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BodyTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BodyTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutBodyTypeInput>;
};

export type BodyTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type BodyTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<BodyTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<BodyTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<BodyTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BodyTypeWhereUniqueInput>;
  update?: Maybe<BodyTypeUpdateWithoutVehicleInput>;
};

export type BodyTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type BodyTypeUpsertWithoutVehicleInput = {
  update: BodyTypeUpdateWithoutVehicleInput;
  create: BodyTypeCreateWithoutVehicleInput;
};

export type BodyTypeWhereInput = {
  AND?: Maybe<Array<BodyTypeWhereInput>>;
  OR?: Maybe<Array<BodyTypeWhereInput>>;
  NOT?: Maybe<Array<BodyTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type BodyTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Booking = {
  __typename?: 'Booking';
  id: Scalars['String'];
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission: Scalars['Float'];
  vehicleId: Scalars['String'];
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  _count?: Maybe<BookingCount>;
  revokedByUser?: Maybe<User>;
  user?: Maybe<User>;
  vehicle: Vehicle;
  vehicleLocation?: Maybe<VehicleLocation>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  vehicleDamage: Array<VehicleDamage>;
  bookingAvailability: Array<BookingAvailability>;
  vehicleCloudBoxxLog: Array<VehicleCloudBoxxLog>;
  bookingAdditionalDriver: Array<BookingAdditionalDriver>;
  bookingAnswer: Array<BookingAnswer>;
  bookingFuelPassword: Array<BookingFuelPassword>;
  bookingRefunding: Array<BookingRefunding>;
  gateControllerTransactions: Array<GateControllerTransaction>;
  bookingChangeHistory: Array<BookingChangeHistory>;
  bookingStatus?: Maybe<BookingStatus>;
  calculation?: Maybe<BookingCalculationResponseDto>;
};

export type BookingVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageScalarFieldEnum>>;
};

export type BookingBookingAvailabilityArgs = {
  where?: Maybe<BookingAvailabilityWhereInput>;
  orderBy?: Maybe<Array<BookingAvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAvailabilityScalarFieldEnum>>;
};

export type BookingVehicleCloudBoxxLogArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxLogScalarFieldEnum>>;
};

export type BookingBookingAdditionalDriverArgs = {
  where?: Maybe<BookingAdditionalDriverWhereInput>;
  orderBy?: Maybe<Array<BookingAdditionalDriverOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAdditionalDriverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAdditionalDriverScalarFieldEnum>>;
};

export type BookingBookingAnswerArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
  orderBy?: Maybe<Array<BookingAnswerOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAnswerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAnswerScalarFieldEnum>>;
};

export type BookingBookingFuelPasswordArgs = {
  where?: Maybe<BookingFuelPasswordWhereInput>;
  orderBy?: Maybe<Array<BookingFuelPasswordOrderByWithRelationInput>>;
  cursor?: Maybe<BookingFuelPasswordWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingFuelPasswordScalarFieldEnum>>;
};

export type BookingBookingRefundingArgs = {
  where?: Maybe<BookingRefundingWhereInput>;
  orderBy?: Maybe<Array<BookingRefundingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingRefundingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingRefundingScalarFieldEnum>>;
};

export type BookingGateControllerTransactionsArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTransactionScalarFieldEnum>>;
};

export type BookingBookingChangeHistoryArgs = {
  where?: Maybe<BookingChangeHistoryWhereInput>;
  orderBy?: Maybe<Array<BookingChangeHistoryOrderByWithRelationInput>>;
  cursor?: Maybe<BookingChangeHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingChangeHistoryScalarFieldEnum>>;
};

export type BookingAdditionalDriver = {
  __typename?: 'BookingAdditionalDriver';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
  booking?: Maybe<Booking>;
  user?: Maybe<User>;
};

export type BookingAdditionalDriverCountAggregate = {
  __typename?: 'BookingAdditionalDriverCountAggregate';
  id: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  bookingId: Scalars['Int'];
  userId: Scalars['Int'];
  photoFrontName: Scalars['Int'];
  photoBackName: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingAdditionalDriverCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  photoFrontName?: Maybe<SortOrder>;
  photoBackName?: Maybe<SortOrder>;
};

export type BookingAdditionalDriverCreateInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  booking?: Maybe<BookingCreateNestedOneWithoutBookingAdditionalDriverInput>;
  user?: Maybe<UserCreateNestedOneWithoutBookingAdditionalDriverInput>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
};

export type BookingAdditionalDriverCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
};

export type BookingAdditionalDriverCreateManyBookingInputEnvelope = {
  data: Array<BookingAdditionalDriverCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingAdditionalDriverCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
};

export type BookingAdditionalDriverCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
};

export type BookingAdditionalDriverCreateManyUserInputEnvelope = {
  data: Array<BookingAdditionalDriverCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingAdditionalDriverCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<BookingAdditionalDriverCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAdditionalDriverCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<BookingAdditionalDriverCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
};

export type BookingAdditionalDriverCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BookingAdditionalDriverCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAdditionalDriverCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<BookingAdditionalDriverCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
};

export type BookingAdditionalDriverCreateOrConnectWithoutBookingInput = {
  where: BookingAdditionalDriverWhereUniqueInput;
  create: BookingAdditionalDriverCreateWithoutBookingInput;
};

export type BookingAdditionalDriverCreateOrConnectWithoutUserInput = {
  where: BookingAdditionalDriverWhereUniqueInput;
  create: BookingAdditionalDriverCreateWithoutUserInput;
};

export type BookingAdditionalDriverCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingAdditionalDriverInput>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
};

export type BookingAdditionalDriverCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  booking?: Maybe<BookingCreateNestedOneWithoutBookingAdditionalDriverInput>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
};

export type BookingAdditionalDriverGroupBy = {
  __typename?: 'BookingAdditionalDriverGroupBy';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  photoFrontName: Scalars['String'];
  photoBackName: Scalars['String'];
  _count?: Maybe<BookingAdditionalDriverCountAggregate>;
  _min?: Maybe<BookingAdditionalDriverMinAggregate>;
  _max?: Maybe<BookingAdditionalDriverMaxAggregate>;
};

export type BookingAdditionalDriverListRelationFilter = {
  every?: Maybe<BookingAdditionalDriverWhereInput>;
  some?: Maybe<BookingAdditionalDriverWhereInput>;
  none?: Maybe<BookingAdditionalDriverWhereInput>;
};

export type BookingAdditionalDriverMaxAggregate = {
  __typename?: 'BookingAdditionalDriverMaxAggregate';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  photoFrontName?: Maybe<Scalars['String']>;
  photoBackName?: Maybe<Scalars['String']>;
};

export type BookingAdditionalDriverMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  photoFrontName?: Maybe<SortOrder>;
  photoBackName?: Maybe<SortOrder>;
};

export type BookingAdditionalDriverMinAggregate = {
  __typename?: 'BookingAdditionalDriverMinAggregate';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  photoFrontName?: Maybe<Scalars['String']>;
  photoBackName?: Maybe<Scalars['String']>;
};

export type BookingAdditionalDriverMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  photoFrontName?: Maybe<SortOrder>;
  photoBackName?: Maybe<SortOrder>;
};

export type BookingAdditionalDriverOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingAdditionalDriverOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  photoFrontName?: Maybe<SortOrder>;
  photoBackName?: Maybe<SortOrder>;
  _count?: Maybe<BookingAdditionalDriverCountOrderByAggregateInput>;
  _max?: Maybe<BookingAdditionalDriverMaxOrderByAggregateInput>;
  _min?: Maybe<BookingAdditionalDriverMinOrderByAggregateInput>;
};

export type BookingAdditionalDriverOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  photoFrontName?: Maybe<SortOrder>;
  photoBackName?: Maybe<SortOrder>;
};

export enum BookingAdditionalDriverScalarFieldEnum {
  Id = 'id',
  FirstName = 'firstName',
  LastName = 'lastName',
  BookingId = 'bookingId',
  UserId = 'userId',
  PhotoFrontName = 'photoFrontName',
  PhotoBackName = 'photoBackName',
}

export type BookingAdditionalDriverScalarWhereInput = {
  AND?: Maybe<Array<BookingAdditionalDriverScalarWhereInput>>;
  OR?: Maybe<Array<BookingAdditionalDriverScalarWhereInput>>;
  NOT?: Maybe<Array<BookingAdditionalDriverScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  bookingId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  photoFrontName?: Maybe<StringFilter>;
  photoBackName?: Maybe<StringFilter>;
};

export type BookingAdditionalDriverScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingAdditionalDriverScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingAdditionalDriverScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingAdditionalDriverScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  firstName?: Maybe<StringNullableWithAggregatesFilter>;
  lastName?: Maybe<StringNullableWithAggregatesFilter>;
  bookingId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  photoFrontName?: Maybe<StringWithAggregatesFilter>;
  photoBackName?: Maybe<StringWithAggregatesFilter>;
};

export type BookingAdditionalDriverUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneWithoutBookingAdditionalDriverInput>;
  user?: Maybe<UserUpdateOneWithoutBookingAdditionalDriverInput>;
  photoFrontName?: Maybe<StringFieldUpdateOperationsInput>;
  photoBackName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BookingAdditionalDriverUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  photoFrontName?: Maybe<StringFieldUpdateOperationsInput>;
  photoBackName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BookingAdditionalDriverUpdateManyWithWhereWithoutBookingInput = {
  where: BookingAdditionalDriverScalarWhereInput;
  data: BookingAdditionalDriverUpdateManyMutationInput;
};

export type BookingAdditionalDriverUpdateManyWithWhereWithoutUserInput = {
  where: BookingAdditionalDriverScalarWhereInput;
  data: BookingAdditionalDriverUpdateManyMutationInput;
};

export type BookingAdditionalDriverUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<BookingAdditionalDriverCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAdditionalDriverCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<
    Array<BookingAdditionalDriverUpsertWithWhereUniqueWithoutBookingInput>
  >;
  createMany?: Maybe<BookingAdditionalDriverCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  delete?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  connect?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingAdditionalDriverUpdateWithWhereUniqueWithoutBookingInput>
  >;
  updateMany?: Maybe<
    Array<BookingAdditionalDriverUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<BookingAdditionalDriverScalarWhereInput>>;
};

export type BookingAdditionalDriverUpdateManyWithoutUserInput = {
  create?: Maybe<Array<BookingAdditionalDriverCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAdditionalDriverCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<BookingAdditionalDriverUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<BookingAdditionalDriverCreateManyUserInputEnvelope>;
  set?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  delete?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  connect?: Maybe<Array<BookingAdditionalDriverWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingAdditionalDriverUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<BookingAdditionalDriverUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<BookingAdditionalDriverScalarWhereInput>>;
};

export type BookingAdditionalDriverUpdateWithWhereUniqueWithoutBookingInput = {
  where: BookingAdditionalDriverWhereUniqueInput;
  data: BookingAdditionalDriverUpdateWithoutBookingInput;
};

export type BookingAdditionalDriverUpdateWithWhereUniqueWithoutUserInput = {
  where: BookingAdditionalDriverWhereUniqueInput;
  data: BookingAdditionalDriverUpdateWithoutUserInput;
};

export type BookingAdditionalDriverUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingAdditionalDriverInput>;
  photoFrontName?: Maybe<StringFieldUpdateOperationsInput>;
  photoBackName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BookingAdditionalDriverUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneWithoutBookingAdditionalDriverInput>;
  photoFrontName?: Maybe<StringFieldUpdateOperationsInput>;
  photoBackName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BookingAdditionalDriverUpsertWithWhereUniqueWithoutBookingInput = {
  where: BookingAdditionalDriverWhereUniqueInput;
  update: BookingAdditionalDriverUpdateWithoutBookingInput;
  create: BookingAdditionalDriverCreateWithoutBookingInput;
};

export type BookingAdditionalDriverUpsertWithWhereUniqueWithoutUserInput = {
  where: BookingAdditionalDriverWhereUniqueInput;
  update: BookingAdditionalDriverUpdateWithoutUserInput;
  create: BookingAdditionalDriverCreateWithoutUserInput;
};

export type BookingAdditionalDriverWhereInput = {
  AND?: Maybe<Array<BookingAdditionalDriverWhereInput>>;
  OR?: Maybe<Array<BookingAdditionalDriverWhereInput>>;
  NOT?: Maybe<Array<BookingAdditionalDriverWhereInput>>;
  id?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  photoFrontName?: Maybe<StringFilter>;
  photoBackName?: Maybe<StringFilter>;
};

export type BookingAdditionalDriverWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BookingAnswer = {
  __typename?: 'BookingAnswer';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  answer?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  bookingQuestion?: Maybe<BookingQuestion>;
  user?: Maybe<User>;
  booking?: Maybe<Booking>;
};

export type BookingAnswerCountAggregate = {
  __typename?: 'BookingAnswerCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  answer: Scalars['Int'];
  bookingQuestionId: Scalars['Int'];
  userId: Scalars['Int'];
  bookingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingAnswerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  answer?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type BookingAnswerCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingAnswerInput>;
  user?: Maybe<UserCreateNestedOneWithoutBookingAnswerInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutBookingAnswerInput>;
};

export type BookingAnswerCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type BookingAnswerCreateManyBookingInputEnvelope = {
  data: Array<BookingAnswerCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingAnswerCreateManyBookingQuestionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingAnswerCreateManyBookingQuestionInputEnvelope = {
  data: Array<BookingAnswerCreateManyBookingQuestionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingAnswerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingAnswerCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingAnswerCreateManyUserInputEnvelope = {
  data: Array<BookingAnswerCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingAnswerCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<BookingAnswerCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAnswerCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<BookingAnswerCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
};

export type BookingAnswerCreateNestedManyWithoutBookingQuestionInput = {
  create?: Maybe<Array<BookingAnswerCreateWithoutBookingQuestionInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAnswerCreateOrConnectWithoutBookingQuestionInput>
  >;
  createMany?: Maybe<BookingAnswerCreateManyBookingQuestionInputEnvelope>;
  connect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
};

export type BookingAnswerCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BookingAnswerCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BookingAnswerCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<BookingAnswerCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
};

export type BookingAnswerCreateOrConnectWithoutBookingInput = {
  where: BookingAnswerWhereUniqueInput;
  create: BookingAnswerCreateWithoutBookingInput;
};

export type BookingAnswerCreateOrConnectWithoutBookingQuestionInput = {
  where: BookingAnswerWhereUniqueInput;
  create: BookingAnswerCreateWithoutBookingQuestionInput;
};

export type BookingAnswerCreateOrConnectWithoutUserInput = {
  where: BookingAnswerWhereUniqueInput;
  create: BookingAnswerCreateWithoutUserInput;
};

export type BookingAnswerCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingAnswerInput>;
  user?: Maybe<UserCreateNestedOneWithoutBookingAnswerInput>;
};

export type BookingAnswerCreateWithoutBookingQuestionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingAnswerInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutBookingAnswerInput>;
};

export type BookingAnswerCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingAnswerInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutBookingAnswerInput>;
};

export type BookingAnswerGroupBy = {
  __typename?: 'BookingAnswerGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  answer?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  _count?: Maybe<BookingAnswerCountAggregate>;
  _min?: Maybe<BookingAnswerMinAggregate>;
  _max?: Maybe<BookingAnswerMaxAggregate>;
};

export type BookingAnswerListRelationFilter = {
  every?: Maybe<BookingAnswerWhereInput>;
  some?: Maybe<BookingAnswerWhereInput>;
  none?: Maybe<BookingAnswerWhereInput>;
};

export type BookingAnswerMaxAggregate = {
  __typename?: 'BookingAnswerMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingAnswerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  answer?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type BookingAnswerMinAggregate = {
  __typename?: 'BookingAnswerMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  answer?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingAnswerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  answer?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type BookingAnswerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingAnswerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  answer?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  _count?: Maybe<BookingAnswerCountOrderByAggregateInput>;
  _max?: Maybe<BookingAnswerMaxOrderByAggregateInput>;
  _min?: Maybe<BookingAnswerMinOrderByAggregateInput>;
};

export type BookingAnswerOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  answer?: Maybe<SortOrder>;
  bookingQuestion?: Maybe<BookingQuestionOrderByWithRelationInput>;
  bookingQuestionId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
};

export enum BookingAnswerScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Answer = 'answer',
  BookingQuestionId = 'bookingQuestionId',
  UserId = 'userId',
  BookingId = 'bookingId',
}

export type BookingAnswerScalarWhereInput = {
  AND?: Maybe<Array<BookingAnswerScalarWhereInput>>;
  OR?: Maybe<Array<BookingAnswerScalarWhereInput>>;
  NOT?: Maybe<Array<BookingAnswerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  answer?: Maybe<StringNullableFilter>;
  bookingQuestionId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type BookingAnswerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingAnswerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingAnswerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingAnswerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  answer?: Maybe<StringNullableWithAggregatesFilter>;
  bookingQuestionId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  bookingId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BookingAnswerUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  answer?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingAnswerInput>;
  user?: Maybe<UserUpdateOneWithoutBookingAnswerInput>;
  booking?: Maybe<BookingUpdateOneWithoutBookingAnswerInput>;
};

export type BookingAnswerUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  answer?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type BookingAnswerUpdateManyWithWhereWithoutBookingInput = {
  where: BookingAnswerScalarWhereInput;
  data: BookingAnswerUpdateManyMutationInput;
};

export type BookingAnswerUpdateManyWithWhereWithoutBookingQuestionInput = {
  where: BookingAnswerScalarWhereInput;
  data: BookingAnswerUpdateManyMutationInput;
};

export type BookingAnswerUpdateManyWithWhereWithoutUserInput = {
  where: BookingAnswerScalarWhereInput;
  data: BookingAnswerUpdateManyMutationInput;
};

export type BookingAnswerUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<BookingAnswerCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAnswerCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<Array<BookingAnswerUpsertWithWhereUniqueWithoutBookingInput>>;
  createMany?: Maybe<BookingAnswerCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  delete?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  connect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  update?: Maybe<Array<BookingAnswerUpdateWithWhereUniqueWithoutBookingInput>>;
  updateMany?: Maybe<
    Array<BookingAnswerUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<BookingAnswerScalarWhereInput>>;
};

export type BookingAnswerUpdateManyWithoutBookingQuestionInput = {
  create?: Maybe<Array<BookingAnswerCreateWithoutBookingQuestionInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAnswerCreateOrConnectWithoutBookingQuestionInput>
  >;
  upsert?: Maybe<
    Array<BookingAnswerUpsertWithWhereUniqueWithoutBookingQuestionInput>
  >;
  createMany?: Maybe<BookingAnswerCreateManyBookingQuestionInputEnvelope>;
  set?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  delete?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  connect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingAnswerUpdateWithWhereUniqueWithoutBookingQuestionInput>
  >;
  updateMany?: Maybe<
    Array<BookingAnswerUpdateManyWithWhereWithoutBookingQuestionInput>
  >;
  deleteMany?: Maybe<Array<BookingAnswerScalarWhereInput>>;
};

export type BookingAnswerUpdateManyWithoutUserInput = {
  create?: Maybe<Array<BookingAnswerCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BookingAnswerCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<BookingAnswerUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<BookingAnswerCreateManyUserInputEnvelope>;
  set?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  delete?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  connect?: Maybe<Array<BookingAnswerWhereUniqueInput>>;
  update?: Maybe<Array<BookingAnswerUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<BookingAnswerUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<BookingAnswerScalarWhereInput>>;
};

export type BookingAnswerUpdateWithWhereUniqueWithoutBookingInput = {
  where: BookingAnswerWhereUniqueInput;
  data: BookingAnswerUpdateWithoutBookingInput;
};

export type BookingAnswerUpdateWithWhereUniqueWithoutBookingQuestionInput = {
  where: BookingAnswerWhereUniqueInput;
  data: BookingAnswerUpdateWithoutBookingQuestionInput;
};

export type BookingAnswerUpdateWithWhereUniqueWithoutUserInput = {
  where: BookingAnswerWhereUniqueInput;
  data: BookingAnswerUpdateWithoutUserInput;
};

export type BookingAnswerUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  answer?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingAnswerInput>;
  user?: Maybe<UserUpdateOneWithoutBookingAnswerInput>;
};

export type BookingAnswerUpdateWithoutBookingQuestionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  answer?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingAnswerInput>;
  booking?: Maybe<BookingUpdateOneWithoutBookingAnswerInput>;
};

export type BookingAnswerUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  answer?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingAnswerInput>;
  booking?: Maybe<BookingUpdateOneWithoutBookingAnswerInput>;
};

export type BookingAnswerUpsertWithWhereUniqueWithoutBookingInput = {
  where: BookingAnswerWhereUniqueInput;
  update: BookingAnswerUpdateWithoutBookingInput;
  create: BookingAnswerCreateWithoutBookingInput;
};

export type BookingAnswerUpsertWithWhereUniqueWithoutBookingQuestionInput = {
  where: BookingAnswerWhereUniqueInput;
  update: BookingAnswerUpdateWithoutBookingQuestionInput;
  create: BookingAnswerCreateWithoutBookingQuestionInput;
};

export type BookingAnswerUpsertWithWhereUniqueWithoutUserInput = {
  where: BookingAnswerWhereUniqueInput;
  update: BookingAnswerUpdateWithoutUserInput;
  create: BookingAnswerCreateWithoutUserInput;
};

export type BookingAnswerWhereInput = {
  AND?: Maybe<Array<BookingAnswerWhereInput>>;
  OR?: Maybe<Array<BookingAnswerWhereInput>>;
  NOT?: Maybe<Array<BookingAnswerWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  answer?: Maybe<StringNullableFilter>;
  bookingQuestion?: Maybe<BookingQuestionRelationFilter>;
  bookingQuestionId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type BookingAnswerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BookingAvailability = {
  __typename?: 'BookingAvailability';
  id: Scalars['String'];
  bookingId: Scalars['String'];
  availabilityId: Scalars['String'];
  booking: Booking;
  availability: Availability;
};

export type BookingAvailabilityCountAggregate = {
  __typename?: 'BookingAvailabilityCountAggregate';
  id: Scalars['Int'];
  bookingId: Scalars['Int'];
  availabilityId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingAvailabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
};

export type BookingAvailabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  booking: BookingCreateNestedOneWithoutBookingAvailabilityInput;
  availability: AvailabilityCreateNestedOneWithoutBookingAvailabilityInput;
};

export type BookingAvailabilityCreateManyAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  bookingId: Scalars['String'];
};

export type BookingAvailabilityCreateManyAvailabilityInputEnvelope = {
  data: Array<BookingAvailabilityCreateManyAvailabilityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingAvailabilityCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  availabilityId: Scalars['String'];
};

export type BookingAvailabilityCreateManyBookingInputEnvelope = {
  data: Array<BookingAvailabilityCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingAvailabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  bookingId: Scalars['String'];
  availabilityId: Scalars['String'];
};

export type BookingAvailabilityCreateNestedManyWithoutAvailabilityInput = {
  create?: Maybe<Array<BookingAvailabilityCreateWithoutAvailabilityInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAvailabilityCreateOrConnectWithoutAvailabilityInput>
  >;
  createMany?: Maybe<BookingAvailabilityCreateManyAvailabilityInputEnvelope>;
  connect?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
};

export type BookingAvailabilityCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<BookingAvailabilityCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAvailabilityCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<BookingAvailabilityCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
};

export type BookingAvailabilityCreateOrConnectWithoutAvailabilityInput = {
  where: BookingAvailabilityWhereUniqueInput;
  create: BookingAvailabilityCreateWithoutAvailabilityInput;
};

export type BookingAvailabilityCreateOrConnectWithoutBookingInput = {
  where: BookingAvailabilityWhereUniqueInput;
  create: BookingAvailabilityCreateWithoutBookingInput;
};

export type BookingAvailabilityCreateWithoutAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  booking: BookingCreateNestedOneWithoutBookingAvailabilityInput;
};

export type BookingAvailabilityCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  availability: AvailabilityCreateNestedOneWithoutBookingAvailabilityInput;
};

export type BookingAvailabilityGroupBy = {
  __typename?: 'BookingAvailabilityGroupBy';
  id: Scalars['String'];
  bookingId: Scalars['String'];
  availabilityId: Scalars['String'];
  _count?: Maybe<BookingAvailabilityCountAggregate>;
  _min?: Maybe<BookingAvailabilityMinAggregate>;
  _max?: Maybe<BookingAvailabilityMaxAggregate>;
};

export type BookingAvailabilityListRelationFilter = {
  every?: Maybe<BookingAvailabilityWhereInput>;
  some?: Maybe<BookingAvailabilityWhereInput>;
  none?: Maybe<BookingAvailabilityWhereInput>;
};

export type BookingAvailabilityMaxAggregate = {
  __typename?: 'BookingAvailabilityMaxAggregate';
  id?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
};

export type BookingAvailabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
};

export type BookingAvailabilityMinAggregate = {
  __typename?: 'BookingAvailabilityMinAggregate';
  id?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
};

export type BookingAvailabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
};

export type BookingAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingAvailabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
  _count?: Maybe<BookingAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<BookingAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<BookingAvailabilityMinOrderByAggregateInput>;
};

export type BookingAvailabilityOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
  availability?: Maybe<AvailabilityOrderByWithRelationInput>;
  availabilityId?: Maybe<SortOrder>;
};

export enum BookingAvailabilityScalarFieldEnum {
  Id = 'id',
  BookingId = 'bookingId',
  AvailabilityId = 'availabilityId',
}

export type BookingAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<BookingAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<BookingAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<BookingAvailabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  bookingId?: Maybe<StringFilter>;
  availabilityId?: Maybe<StringFilter>;
};

export type BookingAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingAvailabilityScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  bookingId?: Maybe<StringWithAggregatesFilter>;
  availabilityId?: Maybe<StringWithAggregatesFilter>;
};

export type BookingAvailabilityUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneRequiredWithoutBookingAvailabilityInput>;
  availability?: Maybe<AvailabilityUpdateOneRequiredWithoutBookingAvailabilityInput>;
};

export type BookingAvailabilityUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type BookingAvailabilityUpdateManyWithWhereWithoutAvailabilityInput = {
  where: BookingAvailabilityScalarWhereInput;
  data: BookingAvailabilityUpdateManyMutationInput;
};

export type BookingAvailabilityUpdateManyWithWhereWithoutBookingInput = {
  where: BookingAvailabilityScalarWhereInput;
  data: BookingAvailabilityUpdateManyMutationInput;
};

export type BookingAvailabilityUpdateManyWithoutAvailabilityInput = {
  create?: Maybe<Array<BookingAvailabilityCreateWithoutAvailabilityInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAvailabilityCreateOrConnectWithoutAvailabilityInput>
  >;
  upsert?: Maybe<
    Array<BookingAvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput>
  >;
  createMany?: Maybe<BookingAvailabilityCreateManyAvailabilityInputEnvelope>;
  set?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingAvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput>
  >;
  updateMany?: Maybe<
    Array<BookingAvailabilityUpdateManyWithWhereWithoutAvailabilityInput>
  >;
  deleteMany?: Maybe<Array<BookingAvailabilityScalarWhereInput>>;
};

export type BookingAvailabilityUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<BookingAvailabilityCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingAvailabilityCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<
    Array<BookingAvailabilityUpsertWithWhereUniqueWithoutBookingInput>
  >;
  createMany?: Maybe<BookingAvailabilityCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  delete?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<BookingAvailabilityWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingAvailabilityUpdateWithWhereUniqueWithoutBookingInput>
  >;
  updateMany?: Maybe<
    Array<BookingAvailabilityUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<BookingAvailabilityScalarWhereInput>>;
};

export type BookingAvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput = {
  where: BookingAvailabilityWhereUniqueInput;
  data: BookingAvailabilityUpdateWithoutAvailabilityInput;
};

export type BookingAvailabilityUpdateWithWhereUniqueWithoutBookingInput = {
  where: BookingAvailabilityWhereUniqueInput;
  data: BookingAvailabilityUpdateWithoutBookingInput;
};

export type BookingAvailabilityUpdateWithoutAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneRequiredWithoutBookingAvailabilityInput>;
};

export type BookingAvailabilityUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  availability?: Maybe<AvailabilityUpdateOneRequiredWithoutBookingAvailabilityInput>;
};

export type BookingAvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput = {
  where: BookingAvailabilityWhereUniqueInput;
  update: BookingAvailabilityUpdateWithoutAvailabilityInput;
  create: BookingAvailabilityCreateWithoutAvailabilityInput;
};

export type BookingAvailabilityUpsertWithWhereUniqueWithoutBookingInput = {
  where: BookingAvailabilityWhereUniqueInput;
  update: BookingAvailabilityUpdateWithoutBookingInput;
  create: BookingAvailabilityCreateWithoutBookingInput;
};

export type BookingAvailabilityWhereInput = {
  AND?: Maybe<Array<BookingAvailabilityWhereInput>>;
  OR?: Maybe<Array<BookingAvailabilityWhereInput>>;
  NOT?: Maybe<Array<BookingAvailabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringFilter>;
  availability?: Maybe<AvailabilityRelationFilter>;
  availabilityId?: Maybe<StringFilter>;
};

export type BookingAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingAvgAggregate = {
  __typename?: 'BookingAvgAggregate';
  convadisBookingIncrement?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Float']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Float']>;
};

export type BookingAvgOrderByAggregateInput = {
  convadisBookingIncrement?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveStart?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveEnd?: Maybe<SortOrder>;
  effectiveBookingCost?: Maybe<SortOrder>;
  cancellationFees?: Maybe<SortOrder>;
  stripePaymentAuthorisationAttempts?: Maybe<SortOrder>;
  excessKm?: Maybe<SortOrder>;
  excessKmCost?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  lateAttemptMessage?: Maybe<SortOrder>;
};

export type BookingCalculationItem = {
  __typename?: 'BookingCalculationItem';
  totalCost?: Maybe<Scalars['Float']>;
  totalCostWithoutInsurance?: Maybe<Scalars['Float']>;
  costForDuration?: Maybe<Scalars['Float']>;
  costForDistance?: Maybe<Scalars['Float']>;
  includedDistance_km?: Maybe<Scalars['Float']>;
  insuranceCost?: Maybe<Scalars['Float']>;
  totalHourlyCost?: Maybe<Scalars['Float']>;
  totalDailyCost?: Maybe<Scalars['Float']>;
  totalWeeklyCost?: Maybe<Scalars['Float']>;
  totalHours?: Maybe<Scalars['Float']>;
  totalDays?: Maybe<Scalars['Float']>;
  totalWeeks?: Maybe<Scalars['Float']>;
  deductibleReduction?: Maybe<Scalars['Float']>;
  excessKm?: Maybe<Scalars['Float']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  refunding?: Maybe<Array<BookingRefunding>>;
};

export type BookingCalculationResponseDto = {
  __typename?: 'BookingCalculationResponseDTO';
  startPrice?: Maybe<BookingCalculationItem>;
  endPrice?: Maybe<BookingCalculationItem>;
};

export type BookingCancellationFees = {
  __typename?: 'BookingCancellationFees';
  cancellationFees?: Maybe<Scalars['Float']>;
  chargedPercent?: Maybe<Scalars['Float']>;
};

export type BookingChangeHistory = {
  __typename?: 'BookingChangeHistory';
  id: Scalars['String'];
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  bookingId?: Maybe<Scalars['String']>;
  createdByUser: User;
  booking?: Maybe<Booking>;
};

export type BookingChangeHistoryAvgAggregate = {
  __typename?: 'BookingChangeHistoryAvgAggregate';
  originalTotalCost?: Maybe<Scalars['Float']>;
  newTotalCost?: Maybe<Scalars['Float']>;
};

export type BookingChangeHistoryAvgOrderByAggregateInput = {
  originalTotalCost?: Maybe<SortOrder>;
  newTotalCost?: Maybe<SortOrder>;
};

export type BookingChangeHistoryCountAggregate = {
  __typename?: 'BookingChangeHistoryCountAggregate';
  id: Scalars['Int'];
  originalTotalCost: Scalars['Int'];
  originalStartDate: Scalars['Int'];
  originalEndDate: Scalars['Int'];
  newTotalCost: Scalars['Int'];
  newStartDate: Scalars['Int'];
  newEndDate: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  createdAt: Scalars['Int'];
  bookingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingChangeHistoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  originalTotalCost?: Maybe<SortOrder>;
  originalStartDate?: Maybe<SortOrder>;
  originalEndDate?: Maybe<SortOrder>;
  newTotalCost?: Maybe<SortOrder>;
  newStartDate?: Maybe<SortOrder>;
  newEndDate?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type BookingChangeHistoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdByUser: UserCreateNestedOneWithoutBookingChangeHistoryInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  booking?: Maybe<BookingCreateNestedOneWithoutBookingChangeHistoryInput>;
};

export type BookingChangeHistoryCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingChangeHistoryCreateManyBookingInputEnvelope = {
  data: Array<BookingChangeHistoryCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingChangeHistoryCreateManyCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingChangeHistoryCreateManyCreatedByUserInputEnvelope = {
  data: Array<BookingChangeHistoryCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingChangeHistoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingChangeHistoryCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<BookingChangeHistoryCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingChangeHistoryCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<BookingChangeHistoryCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
};

export type BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<BookingChangeHistoryCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingChangeHistoryCreateOrConnectWithoutCreatedByUserInput>
  >;
  createMany?: Maybe<BookingChangeHistoryCreateManyCreatedByUserInputEnvelope>;
  connect?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
};

export type BookingChangeHistoryCreateOrConnectWithoutBookingInput = {
  where: BookingChangeHistoryWhereUniqueInput;
  create: BookingChangeHistoryCreateWithoutBookingInput;
};

export type BookingChangeHistoryCreateOrConnectWithoutCreatedByUserInput = {
  where: BookingChangeHistoryWhereUniqueInput;
  create: BookingChangeHistoryCreateWithoutCreatedByUserInput;
};

export type BookingChangeHistoryCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdByUser: UserCreateNestedOneWithoutBookingChangeHistoryInput;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingChangeHistoryCreateWithoutCreatedByUserInput = {
  id?: Maybe<Scalars['String']>;
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  booking?: Maybe<BookingCreateNestedOneWithoutBookingChangeHistoryInput>;
};

export type BookingChangeHistoryGroupBy = {
  __typename?: 'BookingChangeHistoryGroupBy';
  id: Scalars['String'];
  originalTotalCost: Scalars['Float'];
  originalStartDate: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  newTotalCost: Scalars['Float'];
  newStartDate: Scalars['DateTime'];
  newEndDate: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  bookingId?: Maybe<Scalars['String']>;
  _count?: Maybe<BookingChangeHistoryCountAggregate>;
  _avg?: Maybe<BookingChangeHistoryAvgAggregate>;
  _sum?: Maybe<BookingChangeHistorySumAggregate>;
  _min?: Maybe<BookingChangeHistoryMinAggregate>;
  _max?: Maybe<BookingChangeHistoryMaxAggregate>;
};

export type BookingChangeHistoryListRelationFilter = {
  every?: Maybe<BookingChangeHistoryWhereInput>;
  some?: Maybe<BookingChangeHistoryWhereInput>;
  none?: Maybe<BookingChangeHistoryWhereInput>;
};

export type BookingChangeHistoryMaxAggregate = {
  __typename?: 'BookingChangeHistoryMaxAggregate';
  id?: Maybe<Scalars['String']>;
  originalTotalCost?: Maybe<Scalars['Float']>;
  originalStartDate?: Maybe<Scalars['DateTime']>;
  originalEndDate?: Maybe<Scalars['DateTime']>;
  newTotalCost?: Maybe<Scalars['Float']>;
  newStartDate?: Maybe<Scalars['DateTime']>;
  newEndDate?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingChangeHistoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  originalTotalCost?: Maybe<SortOrder>;
  originalStartDate?: Maybe<SortOrder>;
  originalEndDate?: Maybe<SortOrder>;
  newTotalCost?: Maybe<SortOrder>;
  newStartDate?: Maybe<SortOrder>;
  newEndDate?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type BookingChangeHistoryMinAggregate = {
  __typename?: 'BookingChangeHistoryMinAggregate';
  id?: Maybe<Scalars['String']>;
  originalTotalCost?: Maybe<Scalars['Float']>;
  originalStartDate?: Maybe<Scalars['DateTime']>;
  originalEndDate?: Maybe<Scalars['DateTime']>;
  newTotalCost?: Maybe<Scalars['Float']>;
  newStartDate?: Maybe<Scalars['DateTime']>;
  newEndDate?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type BookingChangeHistoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  originalTotalCost?: Maybe<SortOrder>;
  originalStartDate?: Maybe<SortOrder>;
  originalEndDate?: Maybe<SortOrder>;
  newTotalCost?: Maybe<SortOrder>;
  newStartDate?: Maybe<SortOrder>;
  newEndDate?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type BookingChangeHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingChangeHistoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  originalTotalCost?: Maybe<SortOrder>;
  originalStartDate?: Maybe<SortOrder>;
  originalEndDate?: Maybe<SortOrder>;
  newTotalCost?: Maybe<SortOrder>;
  newStartDate?: Maybe<SortOrder>;
  newEndDate?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  _count?: Maybe<BookingChangeHistoryCountOrderByAggregateInput>;
  _avg?: Maybe<BookingChangeHistoryAvgOrderByAggregateInput>;
  _max?: Maybe<BookingChangeHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<BookingChangeHistoryMinOrderByAggregateInput>;
  _sum?: Maybe<BookingChangeHistorySumOrderByAggregateInput>;
};

export type BookingChangeHistoryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  originalTotalCost?: Maybe<SortOrder>;
  originalStartDate?: Maybe<SortOrder>;
  originalEndDate?: Maybe<SortOrder>;
  newTotalCost?: Maybe<SortOrder>;
  newStartDate?: Maybe<SortOrder>;
  newEndDate?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationInput>;
  createdByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
};

export enum BookingChangeHistoryScalarFieldEnum {
  Id = 'id',
  OriginalTotalCost = 'originalTotalCost',
  OriginalStartDate = 'originalStartDate',
  OriginalEndDate = 'originalEndDate',
  NewTotalCost = 'newTotalCost',
  NewStartDate = 'newStartDate',
  NewEndDate = 'newEndDate',
  CreatedByUserId = 'createdByUserId',
  CreatedAt = 'createdAt',
  BookingId = 'bookingId',
}

export type BookingChangeHistoryScalarWhereInput = {
  AND?: Maybe<Array<BookingChangeHistoryScalarWhereInput>>;
  OR?: Maybe<Array<BookingChangeHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<BookingChangeHistoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  originalTotalCost?: Maybe<FloatFilter>;
  originalStartDate?: Maybe<DateTimeFilter>;
  originalEndDate?: Maybe<DateTimeFilter>;
  newTotalCost?: Maybe<FloatFilter>;
  newStartDate?: Maybe<DateTimeFilter>;
  newEndDate?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type BookingChangeHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingChangeHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingChangeHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingChangeHistoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  originalTotalCost?: Maybe<FloatWithAggregatesFilter>;
  originalStartDate?: Maybe<DateTimeWithAggregatesFilter>;
  originalEndDate?: Maybe<DateTimeWithAggregatesFilter>;
  newTotalCost?: Maybe<FloatWithAggregatesFilter>;
  newStartDate?: Maybe<DateTimeWithAggregatesFilter>;
  newEndDate?: Maybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  bookingId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BookingChangeHistorySumAggregate = {
  __typename?: 'BookingChangeHistorySumAggregate';
  originalTotalCost?: Maybe<Scalars['Float']>;
  newTotalCost?: Maybe<Scalars['Float']>;
};

export type BookingChangeHistorySumOrderByAggregateInput = {
  originalTotalCost?: Maybe<SortOrder>;
  newTotalCost?: Maybe<SortOrder>;
};

export type BookingChangeHistoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originalTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  originalStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  originalEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  newStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutBookingChangeHistoryInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneWithoutBookingChangeHistoryInput>;
};

export type BookingChangeHistoryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originalTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  originalStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  originalEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  newStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BookingChangeHistoryUpdateManyWithWhereWithoutBookingInput = {
  where: BookingChangeHistoryScalarWhereInput;
  data: BookingChangeHistoryUpdateManyMutationInput;
};

export type BookingChangeHistoryUpdateManyWithWhereWithoutCreatedByUserInput = {
  where: BookingChangeHistoryScalarWhereInput;
  data: BookingChangeHistoryUpdateManyMutationInput;
};

export type BookingChangeHistoryUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<BookingChangeHistoryCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingChangeHistoryCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<
    Array<BookingChangeHistoryUpsertWithWhereUniqueWithoutBookingInput>
  >;
  createMany?: Maybe<BookingChangeHistoryCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingChangeHistoryUpdateWithWhereUniqueWithoutBookingInput>
  >;
  updateMany?: Maybe<
    Array<BookingChangeHistoryUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<BookingChangeHistoryScalarWhereInput>>;
};

export type BookingChangeHistoryUpdateManyWithoutCreatedByUserInput = {
  create?: Maybe<Array<BookingChangeHistoryCreateWithoutCreatedByUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingChangeHistoryCreateOrConnectWithoutCreatedByUserInput>
  >;
  upsert?: Maybe<
    Array<BookingChangeHistoryUpsertWithWhereUniqueWithoutCreatedByUserInput>
  >;
  createMany?: Maybe<BookingChangeHistoryCreateManyCreatedByUserInputEnvelope>;
  set?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<BookingChangeHistoryWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingChangeHistoryUpdateWithWhereUniqueWithoutCreatedByUserInput>
  >;
  updateMany?: Maybe<
    Array<BookingChangeHistoryUpdateManyWithWhereWithoutCreatedByUserInput>
  >;
  deleteMany?: Maybe<Array<BookingChangeHistoryScalarWhereInput>>;
};

export type BookingChangeHistoryUpdateWithWhereUniqueWithoutBookingInput = {
  where: BookingChangeHistoryWhereUniqueInput;
  data: BookingChangeHistoryUpdateWithoutBookingInput;
};

export type BookingChangeHistoryUpdateWithWhereUniqueWithoutCreatedByUserInput =
  {
    where: BookingChangeHistoryWhereUniqueInput;
    data: BookingChangeHistoryUpdateWithoutCreatedByUserInput;
  };

export type BookingChangeHistoryUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originalTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  originalStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  originalEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  newStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutBookingChangeHistoryInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BookingChangeHistoryUpdateWithoutCreatedByUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originalTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  originalStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  originalEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newTotalCost?: Maybe<FloatFieldUpdateOperationsInput>;
  newStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  newEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneWithoutBookingChangeHistoryInput>;
};

export type BookingChangeHistoryUpsertWithWhereUniqueWithoutBookingInput = {
  where: BookingChangeHistoryWhereUniqueInput;
  update: BookingChangeHistoryUpdateWithoutBookingInput;
  create: BookingChangeHistoryCreateWithoutBookingInput;
};

export type BookingChangeHistoryUpsertWithWhereUniqueWithoutCreatedByUserInput =
  {
    where: BookingChangeHistoryWhereUniqueInput;
    update: BookingChangeHistoryUpdateWithoutCreatedByUserInput;
    create: BookingChangeHistoryCreateWithoutCreatedByUserInput;
  };

export type BookingChangeHistoryWhereInput = {
  AND?: Maybe<Array<BookingChangeHistoryWhereInput>>;
  OR?: Maybe<Array<BookingChangeHistoryWhereInput>>;
  NOT?: Maybe<Array<BookingChangeHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  originalTotalCost?: Maybe<FloatFilter>;
  originalStartDate?: Maybe<DateTimeFilter>;
  originalEndDate?: Maybe<DateTimeFilter>;
  newTotalCost?: Maybe<FloatFilter>;
  newStartDate?: Maybe<DateTimeFilter>;
  newEndDate?: Maybe<DateTimeFilter>;
  createdByUser?: Maybe<UserRelationFilter>;
  createdByUserId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type BookingChangeHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BookingCount = {
  __typename?: 'BookingCount';
  vehicleDamage: Scalars['Int'];
  bookingAvailability: Scalars['Int'];
  vehicleCloudBoxxLog: Scalars['Int'];
  bookingAdditionalDriver: Scalars['Int'];
  bookingAnswer: Scalars['Int'];
  bookingFuelPassword: Scalars['Int'];
  bookingRefunding: Scalars['Int'];
  gateControllerTransactions: Scalars['Int'];
  bookingChangeHistory: Scalars['Int'];
};

export type BookingCountAggregate = {
  __typename?: 'BookingCountAggregate';
  id: Scalars['Int'];
  bookingHash: Scalars['Int'];
  convadisBookingIncrement: Scalars['Int'];
  vat: Scalars['Int'];
  noShowAt: Scalars['Int'];
  revokeReason: Scalars['Int'];
  revokedAt: Scalars['Int'];
  revokedByUserId: Scalars['Int'];
  adminChangeComment: Scalars['Int'];
  adminChangeCommentAt: Scalars['Int'];
  startDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  effectiveStartDate: Scalars['Int'];
  endDate: Scalars['Int'];
  effectiveEndDate: Scalars['Int'];
  beforeDrivingQuestionsAnsweredAt: Scalars['Int'];
  afterDrivingQuestionsAnsweredAt: Scalars['Int'];
  stripeTransferedAt: Scalars['Int'];
  skipVehicleStatus: Scalars['Int'];
  userId: Scalars['Int'];
  commission: Scalars['Int'];
  vehicleId: Scalars['Int'];
  vehicleLocationId: Scalars['Int'];
  sharedVehicleGroupId: Scalars['Int'];
  vehicleMileageOnEffectiveStart: Scalars['Int'];
  vehicleMileageOnEffectiveEnd: Scalars['Int'];
  effectiveBookingCost: Scalars['Int'];
  bluetoothToken: Scalars['Int'];
  bluetoothTokenUuid: Scalars['Int'];
  sessionKey: Scalars['Int'];
  cancelDate: Scalars['Int'];
  cancellationFees: Scalars['Int'];
  stripeIntentId: Scalars['Int'];
  stripePaymentCreateAt: Scalars['Int'];
  stripePaymentConfirmedAt: Scalars['Int'];
  stripePaymentAuthorisationAttempts: Scalars['Int'];
  stripeAdditionalPaymentIntentId: Scalars['Int'];
  stripeAdditionalPaymentConfirmed: Scalars['Int'];
  stripeAdditionalPaymentAuthorizedAt: Scalars['Int'];
  userConfirmedAt: Scalars['Int'];
  adminBookingComment: Scalars['Int'];
  excessKm: Scalars['Int'];
  excessKmCost: Scalars['Int'];
  priceHour: Scalars['Int'];
  priceDay: Scalars['Int'];
  priceKm: Scalars['Int'];
  priceWeek: Scalars['Int'];
  includedKmHour: Scalars['Int'];
  includedKmDay: Scalars['Int'];
  includedKmWeek: Scalars['Int'];
  totalCost: Scalars['Int'];
  lateAttemptMessage: Scalars['Int'];
  lateAttemptMessageAt: Scalars['Int'];
  last4Digits: Scalars['Int'];
  cardBrand: Scalars['Int'];
  vehicleInsuranceTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingHash?: Maybe<SortOrder>;
  convadisBookingIncrement?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  noShowAt?: Maybe<SortOrder>;
  revokeReason?: Maybe<SortOrder>;
  revokedAt?: Maybe<SortOrder>;
  revokedByUserId?: Maybe<SortOrder>;
  adminChangeComment?: Maybe<SortOrder>;
  adminChangeCommentAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  afterDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  stripeTransferedAt?: Maybe<SortOrder>;
  skipVehicleStatus?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleLocationId?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveStart?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveEnd?: Maybe<SortOrder>;
  effectiveBookingCost?: Maybe<SortOrder>;
  bluetoothToken?: Maybe<SortOrder>;
  bluetoothTokenUuid?: Maybe<SortOrder>;
  sessionKey?: Maybe<SortOrder>;
  cancelDate?: Maybe<SortOrder>;
  cancellationFees?: Maybe<SortOrder>;
  stripeIntentId?: Maybe<SortOrder>;
  stripePaymentCreateAt?: Maybe<SortOrder>;
  stripePaymentConfirmedAt?: Maybe<SortOrder>;
  stripePaymentAuthorisationAttempts?: Maybe<SortOrder>;
  stripeAdditionalPaymentIntentId?: Maybe<SortOrder>;
  stripeAdditionalPaymentConfirmed?: Maybe<SortOrder>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<SortOrder>;
  userConfirmedAt?: Maybe<SortOrder>;
  adminBookingComment?: Maybe<SortOrder>;
  excessKm?: Maybe<SortOrder>;
  excessKmCost?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  lateAttemptMessage?: Maybe<SortOrder>;
  lateAttemptMessageAt?: Maybe<SortOrder>;
  last4Digits?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
};

export type BookingCreateInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingCreateManyRevokedByUserInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingCreateManyRevokedByUserInputEnvelope = {
  data: Array<BookingCreateManyRevokedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingCreateManySharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
  vehicleLocationId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingCreateManySharedVehicleGroupInputEnvelope = {
  data: Array<BookingCreateManySharedVehicleGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingCreateManyUserInputEnvelope = {
  data: Array<BookingCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingCreateManyVehicleInputEnvelope = {
  data: Array<BookingCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingCreateManyVehicleInsuranceTypeInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
};

export type BookingCreateManyVehicleInsuranceTypeInputEnvelope = {
  data: Array<BookingCreateManyVehicleInsuranceTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingCreateManyVehicleLocationInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingCreateManyVehicleLocationInputEnvelope = {
  data: Array<BookingCreateManyVehicleLocationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingCreateNestedManyWithoutRevokedByUserInput = {
  create?: Maybe<Array<BookingCreateWithoutRevokedByUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutRevokedByUserInput>
  >;
  createMany?: Maybe<BookingCreateManyRevokedByUserInputEnvelope>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
};

export type BookingCreateNestedManyWithoutSharedVehicleGroupInput = {
  create?: Maybe<Array<BookingCreateWithoutSharedVehicleGroupInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutSharedVehicleGroupInput>
  >;
  createMany?: Maybe<BookingCreateManySharedVehicleGroupInputEnvelope>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
};

export type BookingCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BookingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BookingCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<BookingCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
};

export type BookingCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<BookingCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<Array<BookingCreateOrConnectWithoutVehicleInput>>;
  createMany?: Maybe<BookingCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
};

export type BookingCreateNestedManyWithoutVehicleInsuranceTypeInput = {
  create?: Maybe<Array<BookingCreateWithoutVehicleInsuranceTypeInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutVehicleInsuranceTypeInput>
  >;
  createMany?: Maybe<BookingCreateManyVehicleInsuranceTypeInputEnvelope>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
};

export type BookingCreateNestedManyWithoutVehicleLocationInput = {
  create?: Maybe<Array<BookingCreateWithoutVehicleLocationInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutVehicleLocationInput>
  >;
  createMany?: Maybe<BookingCreateManyVehicleLocationInputEnvelope>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
};

export type BookingCreateNestedOneWithoutBookingAdditionalDriverInput = {
  create?: Maybe<BookingCreateWithoutBookingAdditionalDriverInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingAdditionalDriverInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutBookingAnswerInput = {
  create?: Maybe<BookingCreateWithoutBookingAnswerInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingAnswerInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutBookingAvailabilityInput = {
  create?: Maybe<BookingCreateWithoutBookingAvailabilityInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingAvailabilityInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutBookingChangeHistoryInput = {
  create?: Maybe<BookingCreateWithoutBookingChangeHistoryInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingChangeHistoryInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutBookingFuelPasswordInput = {
  create?: Maybe<BookingCreateWithoutBookingFuelPasswordInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingFuelPasswordInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutBookingRefundingInput = {
  create?: Maybe<BookingCreateWithoutBookingRefundingInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingRefundingInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutGateControllerTransactionsInput = {
  create?: Maybe<BookingCreateWithoutGateControllerTransactionsInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutGateControllerTransactionsInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutVehicleCloudBoxxLogInput = {
  create?: Maybe<BookingCreateWithoutVehicleCloudBoxxLogInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutVehicleCloudBoxxLogInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateNestedOneWithoutVehicleDamageInput = {
  create?: Maybe<BookingCreateWithoutVehicleDamageInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutVehicleDamageInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
};

export type BookingCreateOrConnectWithoutBookingAdditionalDriverInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutBookingAdditionalDriverInput;
};

export type BookingCreateOrConnectWithoutBookingAnswerInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutBookingAnswerInput;
};

export type BookingCreateOrConnectWithoutBookingAvailabilityInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutBookingAvailabilityInput;
};

export type BookingCreateOrConnectWithoutBookingChangeHistoryInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutBookingChangeHistoryInput;
};

export type BookingCreateOrConnectWithoutBookingFuelPasswordInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutBookingFuelPasswordInput;
};

export type BookingCreateOrConnectWithoutBookingRefundingInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutBookingRefundingInput;
};

export type BookingCreateOrConnectWithoutGateControllerTransactionsInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutGateControllerTransactionsInput;
};

export type BookingCreateOrConnectWithoutRevokedByUserInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutRevokedByUserInput;
};

export type BookingCreateOrConnectWithoutSharedVehicleGroupInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutSharedVehicleGroupInput;
};

export type BookingCreateOrConnectWithoutUserInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutUserInput;
};

export type BookingCreateOrConnectWithoutVehicleCloudBoxxLogInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutVehicleCloudBoxxLogInput;
};

export type BookingCreateOrConnectWithoutVehicleDamageInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutVehicleDamageInput;
};

export type BookingCreateOrConnectWithoutVehicleInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutVehicleInput;
};

export type BookingCreateOrConnectWithoutVehicleInsuranceTypeInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutVehicleInsuranceTypeInput;
};

export type BookingCreateOrConnectWithoutVehicleLocationInput = {
  where: BookingWhereUniqueInput;
  create: BookingCreateWithoutVehicleLocationInput;
};

export type BookingCreateWithoutBookingAdditionalDriverInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutBookingAnswerInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutBookingAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutBookingChangeHistoryInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutBookingFuelPasswordInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutBookingRefundingInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutGateControllerTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutRevokedByUserInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutSharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutVehicleCloudBoxxLogInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutVehicleDamageInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutVehicleInsuranceTypeInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingCreateWithoutVehicleLocationInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUser?: Maybe<UserCreateNestedOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingInput>;
  vehicle: VehicleCreateNestedOneWithoutBookingsInput;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityCreateNestedManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordCreateNestedManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingCreateNestedManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutBookingInput>;
};

export type BookingFuelPassword = {
  __typename?: 'BookingFuelPassword';
  id: Scalars['String'];
  bookingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  booking: Booking;
};

export type BookingFuelPasswordCountAggregate = {
  __typename?: 'BookingFuelPasswordCountAggregate';
  id: Scalars['Int'];
  bookingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingFuelPasswordCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type BookingFuelPasswordCreateInput = {
  id?: Maybe<Scalars['String']>;
  booking: BookingCreateNestedOneWithoutBookingFuelPasswordInput;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingFuelPasswordCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingFuelPasswordCreateManyBookingInputEnvelope = {
  data: Array<BookingFuelPasswordCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingFuelPasswordCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  bookingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingFuelPasswordCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<BookingFuelPasswordCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingFuelPasswordCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<BookingFuelPasswordCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<BookingFuelPasswordWhereUniqueInput>>;
};

export type BookingFuelPasswordCreateOrConnectWithoutBookingInput = {
  where: BookingFuelPasswordWhereUniqueInput;
  create: BookingFuelPasswordCreateWithoutBookingInput;
};

export type BookingFuelPasswordCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingFuelPasswordGroupBy = {
  __typename?: 'BookingFuelPasswordGroupBy';
  id: Scalars['String'];
  bookingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  _count?: Maybe<BookingFuelPasswordCountAggregate>;
  _min?: Maybe<BookingFuelPasswordMinAggregate>;
  _max?: Maybe<BookingFuelPasswordMaxAggregate>;
};

export type BookingFuelPasswordListRelationFilter = {
  every?: Maybe<BookingFuelPasswordWhereInput>;
  some?: Maybe<BookingFuelPasswordWhereInput>;
  none?: Maybe<BookingFuelPasswordWhereInput>;
};

export type BookingFuelPasswordMaxAggregate = {
  __typename?: 'BookingFuelPasswordMaxAggregate';
  id?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingFuelPasswordMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type BookingFuelPasswordMinAggregate = {
  __typename?: 'BookingFuelPasswordMinAggregate';
  id?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingFuelPasswordMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type BookingFuelPasswordOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingFuelPasswordOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<BookingFuelPasswordCountOrderByAggregateInput>;
  _max?: Maybe<BookingFuelPasswordMaxOrderByAggregateInput>;
  _min?: Maybe<BookingFuelPasswordMinOrderByAggregateInput>;
};

export type BookingFuelPasswordOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum BookingFuelPasswordScalarFieldEnum {
  Id = 'id',
  BookingId = 'bookingId',
  CreatedAt = 'createdAt',
}

export type BookingFuelPasswordScalarWhereInput = {
  AND?: Maybe<Array<BookingFuelPasswordScalarWhereInput>>;
  OR?: Maybe<Array<BookingFuelPasswordScalarWhereInput>>;
  NOT?: Maybe<Array<BookingFuelPasswordScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  bookingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type BookingFuelPasswordScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingFuelPasswordScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingFuelPasswordScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingFuelPasswordScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  bookingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BookingFuelPasswordUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneRequiredWithoutBookingFuelPasswordInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BookingFuelPasswordUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BookingFuelPasswordUpdateManyWithWhereWithoutBookingInput = {
  where: BookingFuelPasswordScalarWhereInput;
  data: BookingFuelPasswordUpdateManyMutationInput;
};

export type BookingFuelPasswordUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<BookingFuelPasswordCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingFuelPasswordCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<
    Array<BookingFuelPasswordUpsertWithWhereUniqueWithoutBookingInput>
  >;
  createMany?: Maybe<BookingFuelPasswordCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<BookingFuelPasswordWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingFuelPasswordWhereUniqueInput>>;
  delete?: Maybe<Array<BookingFuelPasswordWhereUniqueInput>>;
  connect?: Maybe<Array<BookingFuelPasswordWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingFuelPasswordUpdateWithWhereUniqueWithoutBookingInput>
  >;
  updateMany?: Maybe<
    Array<BookingFuelPasswordUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<BookingFuelPasswordScalarWhereInput>>;
};

export type BookingFuelPasswordUpdateWithWhereUniqueWithoutBookingInput = {
  where: BookingFuelPasswordWhereUniqueInput;
  data: BookingFuelPasswordUpdateWithoutBookingInput;
};

export type BookingFuelPasswordUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BookingFuelPasswordUpsertWithWhereUniqueWithoutBookingInput = {
  where: BookingFuelPasswordWhereUniqueInput;
  update: BookingFuelPasswordUpdateWithoutBookingInput;
  create: BookingFuelPasswordCreateWithoutBookingInput;
};

export type BookingFuelPasswordWhereInput = {
  AND?: Maybe<Array<BookingFuelPasswordWhereInput>>;
  OR?: Maybe<Array<BookingFuelPasswordWhereInput>>;
  NOT?: Maybe<Array<BookingFuelPasswordWhereInput>>;
  id?: Maybe<StringFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type BookingFuelPasswordWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BookingGroupBy = {
  __typename?: 'BookingGroupBy';
  id: Scalars['String'];
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  commission: Scalars['Float'];
  vehicleId: Scalars['String'];
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  _count?: Maybe<BookingCountAggregate>;
  _avg?: Maybe<BookingAvgAggregate>;
  _sum?: Maybe<BookingSumAggregate>;
  _min?: Maybe<BookingMinAggregate>;
  _max?: Maybe<BookingMaxAggregate>;
};

export type BookingListRelationFilter = {
  every?: Maybe<BookingWhereInput>;
  some?: Maybe<BookingWhereInput>;
  none?: Maybe<BookingWhereInput>;
};

export type BookingMaxAggregate = {
  __typename?: 'BookingMaxAggregate';
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['Float']>;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingHash?: Maybe<SortOrder>;
  convadisBookingIncrement?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  noShowAt?: Maybe<SortOrder>;
  revokeReason?: Maybe<SortOrder>;
  revokedAt?: Maybe<SortOrder>;
  revokedByUserId?: Maybe<SortOrder>;
  adminChangeComment?: Maybe<SortOrder>;
  adminChangeCommentAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  afterDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  stripeTransferedAt?: Maybe<SortOrder>;
  skipVehicleStatus?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleLocationId?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveStart?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveEnd?: Maybe<SortOrder>;
  effectiveBookingCost?: Maybe<SortOrder>;
  bluetoothToken?: Maybe<SortOrder>;
  bluetoothTokenUuid?: Maybe<SortOrder>;
  sessionKey?: Maybe<SortOrder>;
  cancelDate?: Maybe<SortOrder>;
  cancellationFees?: Maybe<SortOrder>;
  stripeIntentId?: Maybe<SortOrder>;
  stripePaymentCreateAt?: Maybe<SortOrder>;
  stripePaymentConfirmedAt?: Maybe<SortOrder>;
  stripePaymentAuthorisationAttempts?: Maybe<SortOrder>;
  stripeAdditionalPaymentIntentId?: Maybe<SortOrder>;
  stripeAdditionalPaymentConfirmed?: Maybe<SortOrder>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<SortOrder>;
  userConfirmedAt?: Maybe<SortOrder>;
  adminBookingComment?: Maybe<SortOrder>;
  excessKm?: Maybe<SortOrder>;
  excessKmCost?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  lateAttemptMessage?: Maybe<SortOrder>;
  lateAttemptMessageAt?: Maybe<SortOrder>;
  last4Digits?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
};

export type BookingMinAggregate = {
  __typename?: 'BookingMinAggregate';
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  noShowAt?: Maybe<Scalars['DateTime']>;
  revokeReason?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  revokedByUserId?: Maybe<Scalars['String']>;
  adminChangeComment?: Maybe<Scalars['String']>;
  adminChangeCommentAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  afterDrivingQuestionsAnsweredAt?: Maybe<Scalars['DateTime']>;
  stripeTransferedAt?: Maybe<Scalars['DateTime']>;
  skipVehicleStatus?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['Float']>;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleLocationId?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  bluetoothToken?: Maybe<Scalars['String']>;
  bluetoothTokenUuid?: Maybe<Scalars['String']>;
  sessionKey?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripeIntentId?: Maybe<Scalars['String']>;
  stripePaymentCreateAt?: Maybe<Scalars['DateTime']>;
  stripePaymentConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  stripeAdditionalPaymentIntentId?: Maybe<Scalars['String']>;
  stripeAdditionalPaymentConfirmed?: Maybe<Scalars['Boolean']>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<Scalars['DateTime']>;
  userConfirmedAt?: Maybe<Scalars['DateTime']>;
  adminBookingComment?: Maybe<Scalars['String']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
  lateAttemptMessageAt?: Maybe<Scalars['DateTime']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type BookingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingHash?: Maybe<SortOrder>;
  convadisBookingIncrement?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  noShowAt?: Maybe<SortOrder>;
  revokeReason?: Maybe<SortOrder>;
  revokedAt?: Maybe<SortOrder>;
  revokedByUserId?: Maybe<SortOrder>;
  adminChangeComment?: Maybe<SortOrder>;
  adminChangeCommentAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  afterDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  stripeTransferedAt?: Maybe<SortOrder>;
  skipVehicleStatus?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleLocationId?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveStart?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveEnd?: Maybe<SortOrder>;
  effectiveBookingCost?: Maybe<SortOrder>;
  bluetoothToken?: Maybe<SortOrder>;
  bluetoothTokenUuid?: Maybe<SortOrder>;
  sessionKey?: Maybe<SortOrder>;
  cancelDate?: Maybe<SortOrder>;
  cancellationFees?: Maybe<SortOrder>;
  stripeIntentId?: Maybe<SortOrder>;
  stripePaymentCreateAt?: Maybe<SortOrder>;
  stripePaymentConfirmedAt?: Maybe<SortOrder>;
  stripePaymentAuthorisationAttempts?: Maybe<SortOrder>;
  stripeAdditionalPaymentIntentId?: Maybe<SortOrder>;
  stripeAdditionalPaymentConfirmed?: Maybe<SortOrder>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<SortOrder>;
  userConfirmedAt?: Maybe<SortOrder>;
  adminBookingComment?: Maybe<SortOrder>;
  excessKm?: Maybe<SortOrder>;
  excessKmCost?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  lateAttemptMessage?: Maybe<SortOrder>;
  lateAttemptMessageAt?: Maybe<SortOrder>;
  last4Digits?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
};

export type BookingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  bookingHash?: Maybe<SortOrder>;
  convadisBookingIncrement?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  noShowAt?: Maybe<SortOrder>;
  revokeReason?: Maybe<SortOrder>;
  revokedAt?: Maybe<SortOrder>;
  revokedByUserId?: Maybe<SortOrder>;
  adminChangeComment?: Maybe<SortOrder>;
  adminChangeCommentAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  afterDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  stripeTransferedAt?: Maybe<SortOrder>;
  skipVehicleStatus?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleLocationId?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveStart?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveEnd?: Maybe<SortOrder>;
  effectiveBookingCost?: Maybe<SortOrder>;
  bluetoothToken?: Maybe<SortOrder>;
  bluetoothTokenUuid?: Maybe<SortOrder>;
  sessionKey?: Maybe<SortOrder>;
  cancelDate?: Maybe<SortOrder>;
  cancellationFees?: Maybe<SortOrder>;
  stripeIntentId?: Maybe<SortOrder>;
  stripePaymentCreateAt?: Maybe<SortOrder>;
  stripePaymentConfirmedAt?: Maybe<SortOrder>;
  stripePaymentAuthorisationAttempts?: Maybe<SortOrder>;
  stripeAdditionalPaymentIntentId?: Maybe<SortOrder>;
  stripeAdditionalPaymentConfirmed?: Maybe<SortOrder>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<SortOrder>;
  userConfirmedAt?: Maybe<SortOrder>;
  adminBookingComment?: Maybe<SortOrder>;
  excessKm?: Maybe<SortOrder>;
  excessKmCost?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  lateAttemptMessage?: Maybe<SortOrder>;
  lateAttemptMessageAt?: Maybe<SortOrder>;
  last4Digits?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  _count?: Maybe<BookingCountOrderByAggregateInput>;
  _avg?: Maybe<BookingAvgOrderByAggregateInput>;
  _max?: Maybe<BookingMaxOrderByAggregateInput>;
  _min?: Maybe<BookingMinOrderByAggregateInput>;
  _sum?: Maybe<BookingSumOrderByAggregateInput>;
};

export type BookingOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  bookingHash?: Maybe<SortOrder>;
  convadisBookingIncrement?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  noShowAt?: Maybe<SortOrder>;
  revokeReason?: Maybe<SortOrder>;
  revokedAt?: Maybe<SortOrder>;
  revokedByUser?: Maybe<UserOrderByWithRelationInput>;
  revokedByUserId?: Maybe<SortOrder>;
  adminChangeComment?: Maybe<SortOrder>;
  adminChangeCommentAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  afterDrivingQuestionsAnsweredAt?: Maybe<SortOrder>;
  stripeTransferedAt?: Maybe<SortOrder>;
  skipVehicleStatus?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  vehicleLocation?: Maybe<VehicleLocationOrderByWithRelationInput>;
  vehicleLocationId?: Maybe<SortOrder>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByWithRelationInput>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveStart?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveEnd?: Maybe<SortOrder>;
  effectiveBookingCost?: Maybe<SortOrder>;
  bluetoothToken?: Maybe<SortOrder>;
  bluetoothTokenUuid?: Maybe<SortOrder>;
  sessionKey?: Maybe<SortOrder>;
  cancelDate?: Maybe<SortOrder>;
  cancellationFees?: Maybe<SortOrder>;
  stripeIntentId?: Maybe<SortOrder>;
  stripePaymentCreateAt?: Maybe<SortOrder>;
  stripePaymentConfirmedAt?: Maybe<SortOrder>;
  stripePaymentAuthorisationAttempts?: Maybe<SortOrder>;
  stripeAdditionalPaymentIntentId?: Maybe<SortOrder>;
  stripeAdditionalPaymentConfirmed?: Maybe<SortOrder>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<SortOrder>;
  userConfirmedAt?: Maybe<SortOrder>;
  adminBookingComment?: Maybe<SortOrder>;
  excessKm?: Maybe<SortOrder>;
  excessKmCost?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  lateAttemptMessage?: Maybe<SortOrder>;
  lateAttemptMessageAt?: Maybe<SortOrder>;
  last4Digits?: Maybe<SortOrder>;
  cardBrand?: Maybe<SortOrder>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeOrderByWithRelationInput>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  vehicleDamage?: Maybe<VehicleDamageOrderByRelationAggregateInput>;
  bookingAvailability?: Maybe<BookingAvailabilityOrderByRelationAggregateInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogOrderByRelationAggregateInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverOrderByRelationAggregateInput>;
  bookingAnswer?: Maybe<BookingAnswerOrderByRelationAggregateInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordOrderByRelationAggregateInput>;
  bookingRefunding?: Maybe<BookingRefundingOrderByRelationAggregateInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionOrderByRelationAggregateInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryOrderByRelationAggregateInput>;
};

export type BookingQuestion = {
  __typename?: 'BookingQuestion';
  id: Scalars['String'];
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  _count?: Maybe<BookingQuestionCount>;
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
  bookingQuestion?: Maybe<BookingQuestion>;
  bookingQuestionChildren: Array<BookingQuestion>;
  bookingAnswer: Array<BookingAnswer>;
  parsedTitle?: Maybe<Scalars['JSON']>;
};

export type BookingQuestionBookingQuestionChildrenArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
  orderBy?: Maybe<Array<BookingQuestionOrderByWithRelationInput>>;
  cursor?: Maybe<BookingQuestionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingQuestionScalarFieldEnum>>;
};

export type BookingQuestionBookingAnswerArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
  orderBy?: Maybe<Array<BookingAnswerOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAnswerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAnswerScalarFieldEnum>>;
};

export type BookingQuestionAvgAggregate = {
  __typename?: 'BookingQuestionAvgAggregate';
  orderIndex?: Maybe<Scalars['Float']>;
};

export type BookingQuestionAvgOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export type BookingQuestionCount = {
  __typename?: 'BookingQuestionCount';
  bookingQuestionChildren: Scalars['Int'];
  bookingAnswer: Scalars['Int'];
};

export type BookingQuestionCountAggregate = {
  __typename?: 'BookingQuestionCountAggregate';
  id: Scalars['Int'];
  kind: Scalars['Int'];
  type: Scalars['Int'];
  title: Scalars['Int'];
  orderIndex: Scalars['Int'];
  isEnabled: Scalars['Int'];
  defaultValue: Scalars['Int'];
  isRequired: Scalars['Int'];
  requiredValue: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  userId: Scalars['Int'];
  vehicleId: Scalars['Int'];
  bookingQuestionId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingQuestionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  defaultValue?: Maybe<SortOrder>;
  isRequired?: Maybe<SortOrder>;
  requiredValue?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
};

export type BookingQuestionCreateInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionCreateNestedManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingQuestionInput>;
};

export type BookingQuestionCreateManyBookingQuestionInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type BookingQuestionCreateManyBookingQuestionInputEnvelope = {
  data: Array<BookingQuestionCreateManyBookingQuestionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingQuestionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
};

export type BookingQuestionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
};

export type BookingQuestionCreateManyUserInputEnvelope = {
  data: Array<BookingQuestionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingQuestionCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
};

export type BookingQuestionCreateManyVehicleInputEnvelope = {
  data: Array<BookingQuestionCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingQuestionCreateNestedManyWithoutBookingQuestionInput = {
  create?: Maybe<Array<BookingQuestionCreateWithoutBookingQuestionInput>>;
  connectOrCreate?: Maybe<
    Array<BookingQuestionCreateOrConnectWithoutBookingQuestionInput>
  >;
  createMany?: Maybe<BookingQuestionCreateManyBookingQuestionInputEnvelope>;
  connect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
};

export type BookingQuestionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BookingQuestionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingQuestionCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<BookingQuestionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
};

export type BookingQuestionCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<BookingQuestionCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<BookingQuestionCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<BookingQuestionCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
};

export type BookingQuestionCreateNestedOneWithoutBookingAnswerInput = {
  create?: Maybe<BookingQuestionCreateWithoutBookingAnswerInput>;
  connectOrCreate?: Maybe<BookingQuestionCreateOrConnectWithoutBookingAnswerInput>;
  connect?: Maybe<BookingQuestionWhereUniqueInput>;
};

export type BookingQuestionCreateNestedOneWithoutBookingQuestionChildrenInput =
  {
    create?: Maybe<BookingQuestionCreateWithoutBookingQuestionChildrenInput>;
    connectOrCreate?: Maybe<BookingQuestionCreateOrConnectWithoutBookingQuestionChildrenInput>;
    connect?: Maybe<BookingQuestionWhereUniqueInput>;
  };

export type BookingQuestionCreateOrConnectWithoutBookingAnswerInput = {
  where: BookingQuestionWhereUniqueInput;
  create: BookingQuestionCreateWithoutBookingAnswerInput;
};

export type BookingQuestionCreateOrConnectWithoutBookingQuestionChildrenInput =
  {
    where: BookingQuestionWhereUniqueInput;
    create: BookingQuestionCreateWithoutBookingQuestionChildrenInput;
  };

export type BookingQuestionCreateOrConnectWithoutBookingQuestionInput = {
  where: BookingQuestionWhereUniqueInput;
  create: BookingQuestionCreateWithoutBookingQuestionInput;
};

export type BookingQuestionCreateOrConnectWithoutUserInput = {
  where: BookingQuestionWhereUniqueInput;
  create: BookingQuestionCreateWithoutUserInput;
};

export type BookingQuestionCreateOrConnectWithoutVehicleInput = {
  where: BookingQuestionWhereUniqueInput;
  create: BookingQuestionCreateWithoutVehicleInput;
};

export type BookingQuestionCreateWithoutBookingAnswerInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionCreateNestedManyWithoutBookingQuestionInput>;
};

export type BookingQuestionCreateWithoutBookingQuestionChildrenInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingQuestionChildrenInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingQuestionInput>;
};

export type BookingQuestionCreateWithoutBookingQuestionInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutBookingQuestionInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionCreateNestedManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingQuestionInput>;
};

export type BookingQuestionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionCreateNestedManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingQuestionInput>;
};

export type BookingQuestionCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionCreateNestedManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutBookingQuestionInput>;
};

export type BookingQuestionGroupBy = {
  __typename?: 'BookingQuestionGroupBy';
  id: Scalars['String'];
  kind: BookingQuestionKind;
  type: BookingQuestionType;
  title: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
  _count?: Maybe<BookingQuestionCountAggregate>;
  _avg?: Maybe<BookingQuestionAvgAggregate>;
  _sum?: Maybe<BookingQuestionSumAggregate>;
  _min?: Maybe<BookingQuestionMinAggregate>;
  _max?: Maybe<BookingQuestionMaxAggregate>;
};

export enum BookingQuestionKind {
  Boolean = 'BOOLEAN',
  Rating = 'RATING',
  String = 'STRING',
}

export type BookingQuestionListRelationFilter = {
  every?: Maybe<BookingQuestionWhereInput>;
  some?: Maybe<BookingQuestionWhereInput>;
  none?: Maybe<BookingQuestionWhereInput>;
};

export type BookingQuestionMaxAggregate = {
  __typename?: 'BookingQuestionMaxAggregate';
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<BookingQuestionKind>;
  type?: Maybe<BookingQuestionType>;
  title?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
};

export type BookingQuestionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  defaultValue?: Maybe<SortOrder>;
  isRequired?: Maybe<SortOrder>;
  requiredValue?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
};

export type BookingQuestionMinAggregate = {
  __typename?: 'BookingQuestionMinAggregate';
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<BookingQuestionKind>;
  type?: Maybe<BookingQuestionType>;
  title?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingQuestionId?: Maybe<Scalars['String']>;
};

export type BookingQuestionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  defaultValue?: Maybe<SortOrder>;
  isRequired?: Maybe<SortOrder>;
  requiredValue?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
};

export type BookingQuestionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingQuestionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  defaultValue?: Maybe<SortOrder>;
  isRequired?: Maybe<SortOrder>;
  requiredValue?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingQuestionId?: Maybe<SortOrder>;
  _count?: Maybe<BookingQuestionCountOrderByAggregateInput>;
  _avg?: Maybe<BookingQuestionAvgOrderByAggregateInput>;
  _max?: Maybe<BookingQuestionMaxOrderByAggregateInput>;
  _min?: Maybe<BookingQuestionMinOrderByAggregateInput>;
  _sum?: Maybe<BookingQuestionSumOrderByAggregateInput>;
};

export type BookingQuestionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  defaultValue?: Maybe<SortOrder>;
  isRequired?: Maybe<SortOrder>;
  requiredValue?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  bookingQuestion?: Maybe<BookingQuestionOrderByWithRelationInput>;
  bookingQuestionId?: Maybe<SortOrder>;
  bookingQuestionChildren?: Maybe<BookingQuestionOrderByRelationAggregateInput>;
  bookingAnswer?: Maybe<BookingAnswerOrderByRelationAggregateInput>;
};

export type BookingQuestionRelationFilter = {
  is?: Maybe<BookingQuestionWhereInput>;
  isNot?: Maybe<BookingQuestionWhereInput>;
};

export enum BookingQuestionScalarFieldEnum {
  Id = 'id',
  Kind = 'kind',
  Type = 'type',
  Title = 'title',
  OrderIndex = 'orderIndex',
  IsEnabled = 'isEnabled',
  DefaultValue = 'defaultValue',
  IsRequired = 'isRequired',
  RequiredValue = 'requiredValue',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  UserId = 'userId',
  VehicleId = 'vehicleId',
  BookingQuestionId = 'bookingQuestionId',
}

export type BookingQuestionScalarWhereInput = {
  AND?: Maybe<Array<BookingQuestionScalarWhereInput>>;
  OR?: Maybe<Array<BookingQuestionScalarWhereInput>>;
  NOT?: Maybe<Array<BookingQuestionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  kind?: Maybe<EnumBookingQuestionKindFilter>;
  type?: Maybe<EnumBookingQuestionTypeFilter>;
  title?: Maybe<StringFilter>;
  orderIndex?: Maybe<IntNullableFilter>;
  isEnabled?: Maybe<BoolNullableFilter>;
  defaultValue?: Maybe<StringNullableFilter>;
  isRequired?: Maybe<BoolNullableFilter>;
  requiredValue?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
  bookingQuestionId?: Maybe<StringNullableFilter>;
};

export type BookingQuestionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingQuestionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingQuestionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingQuestionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  kind?: Maybe<EnumBookingQuestionKindWithAggregatesFilter>;
  type?: Maybe<EnumBookingQuestionTypeWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  orderIndex?: Maybe<IntNullableWithAggregatesFilter>;
  isEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  defaultValue?: Maybe<StringNullableWithAggregatesFilter>;
  isRequired?: Maybe<BoolNullableWithAggregatesFilter>;
  requiredValue?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleId?: Maybe<StringNullableWithAggregatesFilter>;
  bookingQuestionId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BookingQuestionSumAggregate = {
  __typename?: 'BookingQuestionSumAggregate';
  orderIndex?: Maybe<Scalars['Int']>;
};

export type BookingQuestionSumOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export enum BookingQuestionType {
  Post = 'POST',
  Pre = 'PRE',
}

export type BookingQuestionUpdateDataInput = {
  kind?: Maybe<BookingQuestionKind>;
  type?: Maybe<BookingQuestionType>;
  title?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Float']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  requiredValue?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type BookingQuestionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<EnumBookingQuestionKindFieldUpdateOperationsInput>;
  type?: Maybe<EnumBookingQuestionTypeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  defaultValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isRequired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  requiredValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionUpdateManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingQuestionInput>;
};

export type BookingQuestionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<EnumBookingQuestionKindFieldUpdateOperationsInput>;
  type?: Maybe<EnumBookingQuestionTypeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  defaultValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isRequired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  requiredValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BookingQuestionUpdateManyWithWhereWithoutBookingQuestionInput = {
  where: BookingQuestionScalarWhereInput;
  data: BookingQuestionUpdateManyMutationInput;
};

export type BookingQuestionUpdateManyWithWhereWithoutUserInput = {
  where: BookingQuestionScalarWhereInput;
  data: BookingQuestionUpdateManyMutationInput;
};

export type BookingQuestionUpdateManyWithWhereWithoutVehicleInput = {
  where: BookingQuestionScalarWhereInput;
  data: BookingQuestionUpdateManyMutationInput;
};

export type BookingQuestionUpdateManyWithoutBookingQuestionInput = {
  create?: Maybe<Array<BookingQuestionCreateWithoutBookingQuestionInput>>;
  connectOrCreate?: Maybe<
    Array<BookingQuestionCreateOrConnectWithoutBookingQuestionInput>
  >;
  upsert?: Maybe<
    Array<BookingQuestionUpsertWithWhereUniqueWithoutBookingQuestionInput>
  >;
  createMany?: Maybe<BookingQuestionCreateManyBookingQuestionInputEnvelope>;
  set?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  delete?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  connect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingQuestionUpdateWithWhereUniqueWithoutBookingQuestionInput>
  >;
  updateMany?: Maybe<
    Array<BookingQuestionUpdateManyWithWhereWithoutBookingQuestionInput>
  >;
  deleteMany?: Maybe<Array<BookingQuestionScalarWhereInput>>;
};

export type BookingQuestionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<BookingQuestionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingQuestionCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<Array<BookingQuestionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<BookingQuestionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  delete?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  connect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  update?: Maybe<Array<BookingQuestionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<BookingQuestionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<BookingQuestionScalarWhereInput>>;
};

export type BookingQuestionUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<BookingQuestionCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<BookingQuestionCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<BookingQuestionUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<BookingQuestionCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  delete?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  connect?: Maybe<Array<BookingQuestionWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingQuestionUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<BookingQuestionUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<BookingQuestionScalarWhereInput>>;
};

export type BookingQuestionUpdateOneWithoutBookingAnswerInput = {
  create?: Maybe<BookingQuestionCreateWithoutBookingAnswerInput>;
  connectOrCreate?: Maybe<BookingQuestionCreateOrConnectWithoutBookingAnswerInput>;
  upsert?: Maybe<BookingQuestionUpsertWithoutBookingAnswerInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingQuestionWhereUniqueInput>;
  update?: Maybe<BookingQuestionUpdateWithoutBookingAnswerInput>;
};

export type BookingQuestionUpdateOneWithoutBookingQuestionChildrenInput = {
  create?: Maybe<BookingQuestionCreateWithoutBookingQuestionChildrenInput>;
  connectOrCreate?: Maybe<BookingQuestionCreateOrConnectWithoutBookingQuestionChildrenInput>;
  upsert?: Maybe<BookingQuestionUpsertWithoutBookingQuestionChildrenInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingQuestionWhereUniqueInput>;
  update?: Maybe<BookingQuestionUpdateWithoutBookingQuestionChildrenInput>;
};

export type BookingQuestionUpdateWithWhereUniqueWithoutBookingQuestionInput = {
  where: BookingQuestionWhereUniqueInput;
  data: BookingQuestionUpdateWithoutBookingQuestionInput;
};

export type BookingQuestionUpdateWithWhereUniqueWithoutUserInput = {
  where: BookingQuestionWhereUniqueInput;
  data: BookingQuestionUpdateWithoutUserInput;
};

export type BookingQuestionUpdateWithWhereUniqueWithoutVehicleInput = {
  where: BookingQuestionWhereUniqueInput;
  data: BookingQuestionUpdateWithoutVehicleInput;
};

export type BookingQuestionUpdateWithoutBookingAnswerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<EnumBookingQuestionKindFieldUpdateOperationsInput>;
  type?: Maybe<EnumBookingQuestionTypeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  defaultValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isRequired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  requiredValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionUpdateManyWithoutBookingQuestionInput>;
};

export type BookingQuestionUpdateWithoutBookingQuestionChildrenInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<EnumBookingQuestionKindFieldUpdateOperationsInput>;
  type?: Maybe<EnumBookingQuestionTypeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  defaultValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isRequired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  requiredValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingQuestionChildrenInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingQuestionInput>;
};

export type BookingQuestionUpdateWithoutBookingQuestionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<EnumBookingQuestionKindFieldUpdateOperationsInput>;
  type?: Maybe<EnumBookingQuestionTypeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  defaultValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isRequired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  requiredValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingQuestionInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutBookingQuestionInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionUpdateManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingQuestionInput>;
};

export type BookingQuestionUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<EnumBookingQuestionKindFieldUpdateOperationsInput>;
  type?: Maybe<EnumBookingQuestionTypeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  defaultValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isRequired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  requiredValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionUpdateManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingQuestionInput>;
};

export type BookingQuestionUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<EnumBookingQuestionKindFieldUpdateOperationsInput>;
  type?: Maybe<EnumBookingQuestionTypeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  isEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  defaultValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isRequired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  requiredValue?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingQuestionInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateOneWithoutBookingQuestionChildrenInput>;
  bookingQuestionChildren?: Maybe<BookingQuestionUpdateManyWithoutBookingQuestionInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingQuestionInput>;
};

export type BookingQuestionUpsertWithWhereUniqueWithoutBookingQuestionInput = {
  where: BookingQuestionWhereUniqueInput;
  update: BookingQuestionUpdateWithoutBookingQuestionInput;
  create: BookingQuestionCreateWithoutBookingQuestionInput;
};

export type BookingQuestionUpsertWithWhereUniqueWithoutUserInput = {
  where: BookingQuestionWhereUniqueInput;
  update: BookingQuestionUpdateWithoutUserInput;
  create: BookingQuestionCreateWithoutUserInput;
};

export type BookingQuestionUpsertWithWhereUniqueWithoutVehicleInput = {
  where: BookingQuestionWhereUniqueInput;
  update: BookingQuestionUpdateWithoutVehicleInput;
  create: BookingQuestionCreateWithoutVehicleInput;
};

export type BookingQuestionUpsertWithoutBookingAnswerInput = {
  update: BookingQuestionUpdateWithoutBookingAnswerInput;
  create: BookingQuestionCreateWithoutBookingAnswerInput;
};

export type BookingQuestionUpsertWithoutBookingQuestionChildrenInput = {
  update: BookingQuestionUpdateWithoutBookingQuestionChildrenInput;
  create: BookingQuestionCreateWithoutBookingQuestionChildrenInput;
};

export type BookingQuestionWhereInput = {
  AND?: Maybe<Array<BookingQuestionWhereInput>>;
  OR?: Maybe<Array<BookingQuestionWhereInput>>;
  NOT?: Maybe<Array<BookingQuestionWhereInput>>;
  id?: Maybe<StringFilter>;
  kind?: Maybe<EnumBookingQuestionKindFilter>;
  type?: Maybe<EnumBookingQuestionTypeFilter>;
  title?: Maybe<StringFilter>;
  orderIndex?: Maybe<IntNullableFilter>;
  isEnabled?: Maybe<BoolNullableFilter>;
  defaultValue?: Maybe<StringNullableFilter>;
  isRequired?: Maybe<BoolNullableFilter>;
  requiredValue?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
  bookingQuestion?: Maybe<BookingQuestionRelationFilter>;
  bookingQuestionId?: Maybe<StringNullableFilter>;
  bookingQuestionChildren?: Maybe<BookingQuestionListRelationFilter>;
  bookingAnswer?: Maybe<BookingAnswerListRelationFilter>;
};

export type BookingQuestionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BookingRefunding = {
  __typename?: 'BookingRefunding';
  id: Scalars['String'];
  bookingId: Scalars['String'];
  refunding: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  booking: Booking;
};

export type BookingRefundingAvgAggregate = {
  __typename?: 'BookingRefundingAvgAggregate';
  refunding?: Maybe<Scalars['Float']>;
};

export type BookingRefundingAvgOrderByAggregateInput = {
  refunding?: Maybe<SortOrder>;
};

export type BookingRefundingCountAggregate = {
  __typename?: 'BookingRefundingCountAggregate';
  id: Scalars['Int'];
  bookingId: Scalars['Int'];
  refunding: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingRefundingCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  refunding?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type BookingRefundingCreateInput = {
  id?: Maybe<Scalars['String']>;
  booking: BookingCreateNestedOneWithoutBookingRefundingInput;
  refunding: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingRefundingCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  refunding: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingRefundingCreateManyBookingInputEnvelope = {
  data: Array<BookingRefundingCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingRefundingCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  bookingId: Scalars['String'];
  refunding: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingRefundingCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<BookingRefundingCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingRefundingCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<BookingRefundingCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<BookingRefundingWhereUniqueInput>>;
};

export type BookingRefundingCreateOrConnectWithoutBookingInput = {
  where: BookingRefundingWhereUniqueInput;
  create: BookingRefundingCreateWithoutBookingInput;
};

export type BookingRefundingCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  refunding: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingRefundingGroupBy = {
  __typename?: 'BookingRefundingGroupBy';
  id: Scalars['String'];
  bookingId: Scalars['String'];
  refunding: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  _count?: Maybe<BookingRefundingCountAggregate>;
  _avg?: Maybe<BookingRefundingAvgAggregate>;
  _sum?: Maybe<BookingRefundingSumAggregate>;
  _min?: Maybe<BookingRefundingMinAggregate>;
  _max?: Maybe<BookingRefundingMaxAggregate>;
};

export type BookingRefundingListRelationFilter = {
  every?: Maybe<BookingRefundingWhereInput>;
  some?: Maybe<BookingRefundingWhereInput>;
  none?: Maybe<BookingRefundingWhereInput>;
};

export type BookingRefundingMaxAggregate = {
  __typename?: 'BookingRefundingMaxAggregate';
  id?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  refunding?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingRefundingMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  refunding?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type BookingRefundingMinAggregate = {
  __typename?: 'BookingRefundingMinAggregate';
  id?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  refunding?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BookingRefundingMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  refunding?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type BookingRefundingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingRefundingOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  refunding?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<BookingRefundingCountOrderByAggregateInput>;
  _avg?: Maybe<BookingRefundingAvgOrderByAggregateInput>;
  _max?: Maybe<BookingRefundingMaxOrderByAggregateInput>;
  _min?: Maybe<BookingRefundingMinOrderByAggregateInput>;
  _sum?: Maybe<BookingRefundingSumOrderByAggregateInput>;
};

export type BookingRefundingOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
  refunding?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum BookingRefundingScalarFieldEnum {
  Id = 'id',
  BookingId = 'bookingId',
  Refunding = 'refunding',
  CreatedAt = 'createdAt',
}

export type BookingRefundingScalarWhereInput = {
  AND?: Maybe<Array<BookingRefundingScalarWhereInput>>;
  OR?: Maybe<Array<BookingRefundingScalarWhereInput>>;
  NOT?: Maybe<Array<BookingRefundingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  bookingId?: Maybe<StringFilter>;
  refunding?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type BookingRefundingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingRefundingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingRefundingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingRefundingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  bookingId?: Maybe<StringWithAggregatesFilter>;
  refunding?: Maybe<FloatWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BookingRefundingSumAggregate = {
  __typename?: 'BookingRefundingSumAggregate';
  refunding?: Maybe<Scalars['Float']>;
};

export type BookingRefundingSumOrderByAggregateInput = {
  refunding?: Maybe<SortOrder>;
};

export type BookingRefundingUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneRequiredWithoutBookingRefundingInput>;
  refunding?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BookingRefundingUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  refunding?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BookingRefundingUpdateManyWithWhereWithoutBookingInput = {
  where: BookingRefundingScalarWhereInput;
  data: BookingRefundingUpdateManyMutationInput;
};

export type BookingRefundingUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<BookingRefundingCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<BookingRefundingCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<
    Array<BookingRefundingUpsertWithWhereUniqueWithoutBookingInput>
  >;
  createMany?: Maybe<BookingRefundingCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<BookingRefundingWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingRefundingWhereUniqueInput>>;
  delete?: Maybe<Array<BookingRefundingWhereUniqueInput>>;
  connect?: Maybe<Array<BookingRefundingWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingRefundingUpdateWithWhereUniqueWithoutBookingInput>
  >;
  updateMany?: Maybe<
    Array<BookingRefundingUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<BookingRefundingScalarWhereInput>>;
};

export type BookingRefundingUpdateWithWhereUniqueWithoutBookingInput = {
  where: BookingRefundingWhereUniqueInput;
  data: BookingRefundingUpdateWithoutBookingInput;
};

export type BookingRefundingUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  refunding?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BookingRefundingUpsertWithWhereUniqueWithoutBookingInput = {
  where: BookingRefundingWhereUniqueInput;
  update: BookingRefundingUpdateWithoutBookingInput;
  create: BookingRefundingCreateWithoutBookingInput;
};

export type BookingRefundingWhereInput = {
  AND?: Maybe<Array<BookingRefundingWhereInput>>;
  OR?: Maybe<Array<BookingRefundingWhereInput>>;
  NOT?: Maybe<Array<BookingRefundingWhereInput>>;
  id?: Maybe<StringFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringFilter>;
  refunding?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type BookingRefundingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BookingRelationFilter = {
  is?: Maybe<BookingWhereInput>;
  isNot?: Maybe<BookingWhereInput>;
};

export enum BookingScalarFieldEnum {
  Id = 'id',
  BookingHash = 'bookingHash',
  ConvadisBookingIncrement = 'convadisBookingIncrement',
  Vat = 'vat',
  NoShowAt = 'noShowAt',
  RevokeReason = 'revokeReason',
  RevokedAt = 'revokedAt',
  RevokedByUserId = 'revokedByUserId',
  AdminChangeComment = 'adminChangeComment',
  AdminChangeCommentAt = 'adminChangeCommentAt',
  StartDate = 'startDate',
  CreatedAt = 'createdAt',
  EffectiveStartDate = 'effectiveStartDate',
  EndDate = 'endDate',
  EffectiveEndDate = 'effectiveEndDate',
  BeforeDrivingQuestionsAnsweredAt = 'beforeDrivingQuestionsAnsweredAt',
  AfterDrivingQuestionsAnsweredAt = 'afterDrivingQuestionsAnsweredAt',
  StripeTransferedAt = 'stripeTransferedAt',
  SkipVehicleStatus = 'skipVehicleStatus',
  UserId = 'userId',
  Commission = 'commission',
  VehicleId = 'vehicleId',
  VehicleLocationId = 'vehicleLocationId',
  SharedVehicleGroupId = 'sharedVehicleGroupId',
  VehicleMileageOnEffectiveStart = 'vehicleMileageOnEffectiveStart',
  VehicleMileageOnEffectiveEnd = 'vehicleMileageOnEffectiveEnd',
  EffectiveBookingCost = 'effectiveBookingCost',
  BluetoothToken = 'bluetoothToken',
  BluetoothTokenUuid = 'bluetoothTokenUuid',
  SessionKey = 'sessionKey',
  CancelDate = 'cancelDate',
  CancellationFees = 'cancellationFees',
  StripeIntentId = 'stripeIntentId',
  StripePaymentCreateAt = 'stripePaymentCreateAt',
  StripePaymentConfirmedAt = 'stripePaymentConfirmedAt',
  StripePaymentAuthorisationAttempts = 'stripePaymentAuthorisationAttempts',
  StripeAdditionalPaymentIntentId = 'stripeAdditionalPaymentIntentId',
  StripeAdditionalPaymentConfirmed = 'stripeAdditionalPaymentConfirmed',
  StripeAdditionalPaymentAuthorizedAt = 'stripeAdditionalPaymentAuthorizedAt',
  UserConfirmedAt = 'userConfirmedAt',
  AdminBookingComment = 'adminBookingComment',
  ExcessKm = 'excessKm',
  ExcessKmCost = 'excessKmCost',
  PriceHour = 'priceHour',
  PriceDay = 'priceDay',
  PriceKm = 'priceKm',
  PriceWeek = 'priceWeek',
  IncludedKmHour = 'includedKmHour',
  IncludedKmDay = 'includedKmDay',
  IncludedKmWeek = 'includedKmWeek',
  TotalCost = 'totalCost',
  LateAttemptMessage = 'lateAttemptMessage',
  LateAttemptMessageAt = 'lateAttemptMessageAt',
  Last4Digits = 'last4Digits',
  CardBrand = 'cardBrand',
  VehicleInsuranceTypeId = 'vehicleInsuranceTypeId',
}

export type BookingScalarWhereInput = {
  AND?: Maybe<Array<BookingScalarWhereInput>>;
  OR?: Maybe<Array<BookingScalarWhereInput>>;
  NOT?: Maybe<Array<BookingScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  bookingHash?: Maybe<StringNullableFilter>;
  convadisBookingIncrement?: Maybe<IntNullableFilter>;
  vat?: Maybe<FloatNullableFilter>;
  noShowAt?: Maybe<DateTimeNullableFilter>;
  revokeReason?: Maybe<StringNullableFilter>;
  revokedAt?: Maybe<DateTimeNullableFilter>;
  revokedByUserId?: Maybe<StringNullableFilter>;
  adminChangeComment?: Maybe<StringNullableFilter>;
  adminChangeCommentAt?: Maybe<DateTimeNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  endDate?: Maybe<DateTimeFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<DateTimeNullableFilter>;
  afterDrivingQuestionsAnsweredAt?: Maybe<DateTimeNullableFilter>;
  stripeTransferedAt?: Maybe<DateTimeNullableFilter>;
  skipVehicleStatus?: Maybe<BoolNullableFilter>;
  userId?: Maybe<StringFilter>;
  commission?: Maybe<FloatFilter>;
  vehicleId?: Maybe<StringFilter>;
  vehicleLocationId?: Maybe<StringNullableFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  vehicleMileageOnEffectiveStart?: Maybe<FloatNullableFilter>;
  vehicleMileageOnEffectiveEnd?: Maybe<FloatNullableFilter>;
  effectiveBookingCost?: Maybe<FloatNullableFilter>;
  bluetoothToken?: Maybe<StringNullableFilter>;
  bluetoothTokenUuid?: Maybe<StringNullableFilter>;
  sessionKey?: Maybe<StringNullableFilter>;
  cancelDate?: Maybe<DateTimeNullableFilter>;
  cancellationFees?: Maybe<FloatNullableFilter>;
  stripeIntentId?: Maybe<StringNullableFilter>;
  stripePaymentCreateAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentConfirmedAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentAuthorisationAttempts?: Maybe<IntNullableFilter>;
  stripeAdditionalPaymentIntentId?: Maybe<StringNullableFilter>;
  stripeAdditionalPaymentConfirmed?: Maybe<BoolNullableFilter>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<DateTimeNullableFilter>;
  userConfirmedAt?: Maybe<DateTimeNullableFilter>;
  adminBookingComment?: Maybe<StringNullableFilter>;
  excessKm?: Maybe<FloatNullableFilter>;
  excessKmCost?: Maybe<FloatNullableFilter>;
  priceHour?: Maybe<FloatNullableFilter>;
  priceDay?: Maybe<FloatNullableFilter>;
  priceKm?: Maybe<FloatNullableFilter>;
  priceWeek?: Maybe<FloatNullableFilter>;
  includedKmHour?: Maybe<FloatNullableFilter>;
  includedKmDay?: Maybe<FloatNullableFilter>;
  includedKmWeek?: Maybe<FloatNullableFilter>;
  totalCost?: Maybe<FloatNullableFilter>;
  lateAttemptMessage?: Maybe<IntNullableFilter>;
  lateAttemptMessageAt?: Maybe<DateTimeNullableFilter>;
  last4Digits?: Maybe<StringNullableFilter>;
  cardBrand?: Maybe<StringNullableFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableFilter>;
};

export type BookingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  bookingHash?: Maybe<StringNullableWithAggregatesFilter>;
  convadisBookingIncrement?: Maybe<IntNullableWithAggregatesFilter>;
  vat?: Maybe<FloatNullableWithAggregatesFilter>;
  noShowAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  revokeReason?: Maybe<StringNullableWithAggregatesFilter>;
  revokedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  revokedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  adminChangeComment?: Maybe<StringNullableWithAggregatesFilter>;
  adminChangeCommentAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  endDate?: Maybe<DateTimeWithAggregatesFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  afterDrivingQuestionsAnsweredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripeTransferedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  skipVehicleStatus?: Maybe<BoolNullableWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  commission?: Maybe<FloatWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  vehicleLocationId?: Maybe<StringNullableWithAggregatesFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleMileageOnEffectiveStart?: Maybe<FloatNullableWithAggregatesFilter>;
  vehicleMileageOnEffectiveEnd?: Maybe<FloatNullableWithAggregatesFilter>;
  effectiveBookingCost?: Maybe<FloatNullableWithAggregatesFilter>;
  bluetoothToken?: Maybe<StringNullableWithAggregatesFilter>;
  bluetoothTokenUuid?: Maybe<StringNullableWithAggregatesFilter>;
  sessionKey?: Maybe<StringNullableWithAggregatesFilter>;
  cancelDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cancellationFees?: Maybe<FloatNullableWithAggregatesFilter>;
  stripeIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentCreateAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePaymentConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePaymentAuthorisationAttempts?: Maybe<IntNullableWithAggregatesFilter>;
  stripeAdditionalPaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeAdditionalPaymentConfirmed?: Maybe<BoolNullableWithAggregatesFilter>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  userConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  adminBookingComment?: Maybe<StringNullableWithAggregatesFilter>;
  excessKm?: Maybe<FloatNullableWithAggregatesFilter>;
  excessKmCost?: Maybe<FloatNullableWithAggregatesFilter>;
  priceHour?: Maybe<FloatNullableWithAggregatesFilter>;
  priceDay?: Maybe<FloatNullableWithAggregatesFilter>;
  priceKm?: Maybe<FloatNullableWithAggregatesFilter>;
  priceWeek?: Maybe<FloatNullableWithAggregatesFilter>;
  includedKmHour?: Maybe<FloatNullableWithAggregatesFilter>;
  includedKmDay?: Maybe<FloatNullableWithAggregatesFilter>;
  includedKmWeek?: Maybe<FloatNullableWithAggregatesFilter>;
  totalCost?: Maybe<FloatNullableWithAggregatesFilter>;
  lateAttemptMessage?: Maybe<IntNullableWithAggregatesFilter>;
  lateAttemptMessageAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  last4Digits?: Maybe<StringNullableWithAggregatesFilter>;
  cardBrand?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum BookingStatus {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Running = 'RUNNING',
  Finished = 'FINISHED',
  Cancelled = 'CANCELLED',
  Aborted = 'ABORTED',
  NoShow = 'NO_SHOW',
  Revoked = 'REVOKED',
  PaymentFailed = 'PAYMENT_FAILED',
  OwnBooking = 'OWN_BOOKING',
  Late = 'LATE',
  WaitingForUser = 'WAITING_FOR_USER',
  Unknown = 'UNKNOWN',
}

export type BookingSumAggregate = {
  __typename?: 'BookingSumAggregate';
  convadisBookingIncrement?: Maybe<Scalars['Int']>;
  vat?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveStart?: Maybe<Scalars['Float']>;
  vehicleMileageOnEffectiveEnd?: Maybe<Scalars['Float']>;
  effectiveBookingCost?: Maybe<Scalars['Float']>;
  cancellationFees?: Maybe<Scalars['Float']>;
  stripePaymentAuthorisationAttempts?: Maybe<Scalars['Int']>;
  excessKm?: Maybe<Scalars['Float']>;
  excessKmCost?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  lateAttemptMessage?: Maybe<Scalars['Int']>;
};

export type BookingSumOrderByAggregateInput = {
  convadisBookingIncrement?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveStart?: Maybe<SortOrder>;
  vehicleMileageOnEffectiveEnd?: Maybe<SortOrder>;
  effectiveBookingCost?: Maybe<SortOrder>;
  cancellationFees?: Maybe<SortOrder>;
  stripePaymentAuthorisationAttempts?: Maybe<SortOrder>;
  excessKm?: Maybe<SortOrder>;
  excessKmCost?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  lateAttemptMessage?: Maybe<SortOrder>;
};

export type BookingUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type BookingUpdateManyWithWhereWithoutRevokedByUserInput = {
  where: BookingScalarWhereInput;
  data: BookingUpdateManyMutationInput;
};

export type BookingUpdateManyWithWhereWithoutSharedVehicleGroupInput = {
  where: BookingScalarWhereInput;
  data: BookingUpdateManyMutationInput;
};

export type BookingUpdateManyWithWhereWithoutUserInput = {
  where: BookingScalarWhereInput;
  data: BookingUpdateManyMutationInput;
};

export type BookingUpdateManyWithWhereWithoutVehicleInput = {
  where: BookingScalarWhereInput;
  data: BookingUpdateManyMutationInput;
};

export type BookingUpdateManyWithWhereWithoutVehicleInsuranceTypeInput = {
  where: BookingScalarWhereInput;
  data: BookingUpdateManyMutationInput;
};

export type BookingUpdateManyWithWhereWithoutVehicleLocationInput = {
  where: BookingScalarWhereInput;
  data: BookingUpdateManyMutationInput;
};

export type BookingUpdateManyWithoutRevokedByUserInput = {
  create?: Maybe<Array<BookingCreateWithoutRevokedByUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutRevokedByUserInput>
  >;
  upsert?: Maybe<Array<BookingUpsertWithWhereUniqueWithoutRevokedByUserInput>>;
  createMany?: Maybe<BookingCreateManyRevokedByUserInputEnvelope>;
  set?: Maybe<Array<BookingWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingWhereUniqueInput>>;
  delete?: Maybe<Array<BookingWhereUniqueInput>>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
  update?: Maybe<Array<BookingUpdateWithWhereUniqueWithoutRevokedByUserInput>>;
  updateMany?: Maybe<
    Array<BookingUpdateManyWithWhereWithoutRevokedByUserInput>
  >;
  deleteMany?: Maybe<Array<BookingScalarWhereInput>>;
};

export type BookingUpdateManyWithoutSharedVehicleGroupInput = {
  create?: Maybe<Array<BookingCreateWithoutSharedVehicleGroupInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutSharedVehicleGroupInput>
  >;
  upsert?: Maybe<
    Array<BookingUpsertWithWhereUniqueWithoutSharedVehicleGroupInput>
  >;
  createMany?: Maybe<BookingCreateManySharedVehicleGroupInputEnvelope>;
  set?: Maybe<Array<BookingWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingWhereUniqueInput>>;
  delete?: Maybe<Array<BookingWhereUniqueInput>>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingUpdateWithWhereUniqueWithoutSharedVehicleGroupInput>
  >;
  updateMany?: Maybe<
    Array<BookingUpdateManyWithWhereWithoutSharedVehicleGroupInput>
  >;
  deleteMany?: Maybe<Array<BookingScalarWhereInput>>;
};

export type BookingUpdateManyWithoutUserInput = {
  create?: Maybe<Array<BookingCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BookingCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<BookingUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<BookingCreateManyUserInputEnvelope>;
  set?: Maybe<Array<BookingWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingWhereUniqueInput>>;
  delete?: Maybe<Array<BookingWhereUniqueInput>>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
  update?: Maybe<Array<BookingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<BookingUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<BookingScalarWhereInput>>;
};

export type BookingUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<BookingCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<Array<BookingCreateOrConnectWithoutVehicleInput>>;
  upsert?: Maybe<Array<BookingUpsertWithWhereUniqueWithoutVehicleInput>>;
  createMany?: Maybe<BookingCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<BookingWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingWhereUniqueInput>>;
  delete?: Maybe<Array<BookingWhereUniqueInput>>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
  update?: Maybe<Array<BookingUpdateWithWhereUniqueWithoutVehicleInput>>;
  updateMany?: Maybe<Array<BookingUpdateManyWithWhereWithoutVehicleInput>>;
  deleteMany?: Maybe<Array<BookingScalarWhereInput>>;
};

export type BookingUpdateManyWithoutVehicleInsuranceTypeInput = {
  create?: Maybe<Array<BookingCreateWithoutVehicleInsuranceTypeInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutVehicleInsuranceTypeInput>
  >;
  upsert?: Maybe<
    Array<BookingUpsertWithWhereUniqueWithoutVehicleInsuranceTypeInput>
  >;
  createMany?: Maybe<BookingCreateManyVehicleInsuranceTypeInputEnvelope>;
  set?: Maybe<Array<BookingWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingWhereUniqueInput>>;
  delete?: Maybe<Array<BookingWhereUniqueInput>>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingUpdateWithWhereUniqueWithoutVehicleInsuranceTypeInput>
  >;
  updateMany?: Maybe<
    Array<BookingUpdateManyWithWhereWithoutVehicleInsuranceTypeInput>
  >;
  deleteMany?: Maybe<Array<BookingScalarWhereInput>>;
};

export type BookingUpdateManyWithoutVehicleLocationInput = {
  create?: Maybe<Array<BookingCreateWithoutVehicleLocationInput>>;
  connectOrCreate?: Maybe<
    Array<BookingCreateOrConnectWithoutVehicleLocationInput>
  >;
  upsert?: Maybe<
    Array<BookingUpsertWithWhereUniqueWithoutVehicleLocationInput>
  >;
  createMany?: Maybe<BookingCreateManyVehicleLocationInputEnvelope>;
  set?: Maybe<Array<BookingWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingWhereUniqueInput>>;
  delete?: Maybe<Array<BookingWhereUniqueInput>>;
  connect?: Maybe<Array<BookingWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingUpdateWithWhereUniqueWithoutVehicleLocationInput>
  >;
  updateMany?: Maybe<
    Array<BookingUpdateManyWithWhereWithoutVehicleLocationInput>
  >;
  deleteMany?: Maybe<Array<BookingScalarWhereInput>>;
};

export type BookingUpdateOneRequiredWithoutBookingAvailabilityInput = {
  create?: Maybe<BookingCreateWithoutBookingAvailabilityInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingAvailabilityInput>;
  upsert?: Maybe<BookingUpsertWithoutBookingAvailabilityInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutBookingAvailabilityInput>;
};

export type BookingUpdateOneRequiredWithoutBookingFuelPasswordInput = {
  create?: Maybe<BookingCreateWithoutBookingFuelPasswordInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingFuelPasswordInput>;
  upsert?: Maybe<BookingUpsertWithoutBookingFuelPasswordInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutBookingFuelPasswordInput>;
};

export type BookingUpdateOneRequiredWithoutBookingRefundingInput = {
  create?: Maybe<BookingCreateWithoutBookingRefundingInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingRefundingInput>;
  upsert?: Maybe<BookingUpsertWithoutBookingRefundingInput>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutBookingRefundingInput>;
};

export type BookingUpdateOneWithoutBookingAdditionalDriverInput = {
  create?: Maybe<BookingCreateWithoutBookingAdditionalDriverInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingAdditionalDriverInput>;
  upsert?: Maybe<BookingUpsertWithoutBookingAdditionalDriverInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutBookingAdditionalDriverInput>;
};

export type BookingUpdateOneWithoutBookingAnswerInput = {
  create?: Maybe<BookingCreateWithoutBookingAnswerInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingAnswerInput>;
  upsert?: Maybe<BookingUpsertWithoutBookingAnswerInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutBookingAnswerInput>;
};

export type BookingUpdateOneWithoutBookingChangeHistoryInput = {
  create?: Maybe<BookingCreateWithoutBookingChangeHistoryInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutBookingChangeHistoryInput>;
  upsert?: Maybe<BookingUpsertWithoutBookingChangeHistoryInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutBookingChangeHistoryInput>;
};

export type BookingUpdateOneWithoutGateControllerTransactionsInput = {
  create?: Maybe<BookingCreateWithoutGateControllerTransactionsInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutGateControllerTransactionsInput>;
  upsert?: Maybe<BookingUpsertWithoutGateControllerTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutGateControllerTransactionsInput>;
};

export type BookingUpdateOneWithoutVehicleCloudBoxxLogInput = {
  create?: Maybe<BookingCreateWithoutVehicleCloudBoxxLogInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutVehicleCloudBoxxLogInput>;
  upsert?: Maybe<BookingUpsertWithoutVehicleCloudBoxxLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutVehicleCloudBoxxLogInput>;
};

export type BookingUpdateOneWithoutVehicleDamageInput = {
  create?: Maybe<BookingCreateWithoutVehicleDamageInput>;
  connectOrCreate?: Maybe<BookingCreateOrConnectWithoutVehicleDamageInput>;
  upsert?: Maybe<BookingUpsertWithoutVehicleDamageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BookingWhereUniqueInput>;
  update?: Maybe<BookingUpdateWithoutVehicleDamageInput>;
};

export type BookingUpdateWithWhereUniqueWithoutRevokedByUserInput = {
  where: BookingWhereUniqueInput;
  data: BookingUpdateWithoutRevokedByUserInput;
};

export type BookingUpdateWithWhereUniqueWithoutSharedVehicleGroupInput = {
  where: BookingWhereUniqueInput;
  data: BookingUpdateWithoutSharedVehicleGroupInput;
};

export type BookingUpdateWithWhereUniqueWithoutUserInput = {
  where: BookingWhereUniqueInput;
  data: BookingUpdateWithoutUserInput;
};

export type BookingUpdateWithWhereUniqueWithoutVehicleInput = {
  where: BookingWhereUniqueInput;
  data: BookingUpdateWithoutVehicleInput;
};

export type BookingUpdateWithWhereUniqueWithoutVehicleInsuranceTypeInput = {
  where: BookingWhereUniqueInput;
  data: BookingUpdateWithoutVehicleInsuranceTypeInput;
};

export type BookingUpdateWithWhereUniqueWithoutVehicleLocationInput = {
  where: BookingWhereUniqueInput;
  data: BookingUpdateWithoutVehicleLocationInput;
};

export type BookingUpdateWithoutBookingAdditionalDriverInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutBookingAnswerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutBookingAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutBookingChangeHistoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutBookingFuelPasswordInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutBookingRefundingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutGateControllerTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutRevokedByUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutSharedVehicleGroupInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutVehicleCloudBoxxLogInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutVehicleDamageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutVehicleInsuranceTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateOneWithoutBookingInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpdateWithoutVehicleLocationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bookingHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisBookingIncrement?: Maybe<NullableIntFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  noShowAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokeReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revokedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  revokedByUser?: Maybe<UserUpdateOneWithoutBookingRevokedByUserInput>;
  adminChangeComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  adminChangeCommentAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  afterDrivingQuestionsAnsweredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeTransferedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  skipVehicleStatus?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  commission?: Maybe<FloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutBookingInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutBookingsInput>;
  vehicleMileageOnEffectiveStart?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleMileageOnEffectiveEnd?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  effectiveBookingCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bluetoothToken?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bluetoothTokenUuid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sessionKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cancellationFees?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  stripeIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentCreateAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentAuthorisationAttempts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stripeAdditionalPaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAdditionalPaymentConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  adminBookingComment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  excessKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  excessKmCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  totalCost?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lateAttemptMessage?: Maybe<NullableIntFieldUpdateOperationsInput>;
  lateAttemptMessageAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last4Digits?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cardBrand?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutBookingsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutBookingInput>;
  bookingAvailability?: Maybe<BookingAvailabilityUpdateManyWithoutBookingInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutBookingInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutBookingInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutBookingInput>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordUpdateManyWithoutBookingInput>;
  bookingRefunding?: Maybe<BookingRefundingUpdateManyWithoutBookingInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutBookingInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutBookingInput>;
};

export type BookingUpsertWithWhereUniqueWithoutRevokedByUserInput = {
  where: BookingWhereUniqueInput;
  update: BookingUpdateWithoutRevokedByUserInput;
  create: BookingCreateWithoutRevokedByUserInput;
};

export type BookingUpsertWithWhereUniqueWithoutSharedVehicleGroupInput = {
  where: BookingWhereUniqueInput;
  update: BookingUpdateWithoutSharedVehicleGroupInput;
  create: BookingCreateWithoutSharedVehicleGroupInput;
};

export type BookingUpsertWithWhereUniqueWithoutUserInput = {
  where: BookingWhereUniqueInput;
  update: BookingUpdateWithoutUserInput;
  create: BookingCreateWithoutUserInput;
};

export type BookingUpsertWithWhereUniqueWithoutVehicleInput = {
  where: BookingWhereUniqueInput;
  update: BookingUpdateWithoutVehicleInput;
  create: BookingCreateWithoutVehicleInput;
};

export type BookingUpsertWithWhereUniqueWithoutVehicleInsuranceTypeInput = {
  where: BookingWhereUniqueInput;
  update: BookingUpdateWithoutVehicleInsuranceTypeInput;
  create: BookingCreateWithoutVehicleInsuranceTypeInput;
};

export type BookingUpsertWithWhereUniqueWithoutVehicleLocationInput = {
  where: BookingWhereUniqueInput;
  update: BookingUpdateWithoutVehicleLocationInput;
  create: BookingCreateWithoutVehicleLocationInput;
};

export type BookingUpsertWithoutBookingAdditionalDriverInput = {
  update: BookingUpdateWithoutBookingAdditionalDriverInput;
  create: BookingCreateWithoutBookingAdditionalDriverInput;
};

export type BookingUpsertWithoutBookingAnswerInput = {
  update: BookingUpdateWithoutBookingAnswerInput;
  create: BookingCreateWithoutBookingAnswerInput;
};

export type BookingUpsertWithoutBookingAvailabilityInput = {
  update: BookingUpdateWithoutBookingAvailabilityInput;
  create: BookingCreateWithoutBookingAvailabilityInput;
};

export type BookingUpsertWithoutBookingChangeHistoryInput = {
  update: BookingUpdateWithoutBookingChangeHistoryInput;
  create: BookingCreateWithoutBookingChangeHistoryInput;
};

export type BookingUpsertWithoutBookingFuelPasswordInput = {
  update: BookingUpdateWithoutBookingFuelPasswordInput;
  create: BookingCreateWithoutBookingFuelPasswordInput;
};

export type BookingUpsertWithoutBookingRefundingInput = {
  update: BookingUpdateWithoutBookingRefundingInput;
  create: BookingCreateWithoutBookingRefundingInput;
};

export type BookingUpsertWithoutGateControllerTransactionsInput = {
  update: BookingUpdateWithoutGateControllerTransactionsInput;
  create: BookingCreateWithoutGateControllerTransactionsInput;
};

export type BookingUpsertWithoutVehicleCloudBoxxLogInput = {
  update: BookingUpdateWithoutVehicleCloudBoxxLogInput;
  create: BookingCreateWithoutVehicleCloudBoxxLogInput;
};

export type BookingUpsertWithoutVehicleDamageInput = {
  update: BookingUpdateWithoutVehicleDamageInput;
  create: BookingCreateWithoutVehicleDamageInput;
};

export type BookingVehicleDamages = {
  __typename?: 'BookingVehicleDamages';
  id: Scalars['String'];
  seenAt: Scalars['DateTime'];
  userId: Scalars['String'];
  vehicleId: Scalars['String'];
  user: User;
  vehicle: Vehicle;
};

export type BookingVehicleDamagesCountAggregate = {
  __typename?: 'BookingVehicleDamagesCountAggregate';
  id: Scalars['Int'];
  seenAt: Scalars['Int'];
  userId: Scalars['Int'];
  vehicleId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BookingVehicleDamagesCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  seenAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type BookingVehicleDamagesCreateInput = {
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutBookingVehicleDamagesInput;
  vehicle: VehicleCreateNestedOneWithoutBookingVehicleDamagesInput;
};

export type BookingVehicleDamagesCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  vehicleId: Scalars['String'];
};

export type BookingVehicleDamagesCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  vehicleId: Scalars['String'];
};

export type BookingVehicleDamagesCreateManyUserInputEnvelope = {
  data: Array<BookingVehicleDamagesCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingVehicleDamagesCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type BookingVehicleDamagesCreateManyVehicleInputEnvelope = {
  data: Array<BookingVehicleDamagesCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BookingVehicleDamagesCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BookingVehicleDamagesCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingVehicleDamagesCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<BookingVehicleDamagesCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
};

export type BookingVehicleDamagesCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<BookingVehicleDamagesCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<BookingVehicleDamagesCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<BookingVehicleDamagesCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
};

export type BookingVehicleDamagesCreateOrConnectWithoutUserInput = {
  where: BookingVehicleDamagesWhereUniqueInput;
  create: BookingVehicleDamagesCreateWithoutUserInput;
};

export type BookingVehicleDamagesCreateOrConnectWithoutVehicleInput = {
  where: BookingVehicleDamagesWhereUniqueInput;
  create: BookingVehicleDamagesCreateWithoutVehicleInput;
};

export type BookingVehicleDamagesCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  vehicle: VehicleCreateNestedOneWithoutBookingVehicleDamagesInput;
};

export type BookingVehicleDamagesCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutBookingVehicleDamagesInput;
};

export type BookingVehicleDamagesGroupBy = {
  __typename?: 'BookingVehicleDamagesGroupBy';
  id: Scalars['String'];
  seenAt: Scalars['DateTime'];
  userId: Scalars['String'];
  vehicleId: Scalars['String'];
  _count?: Maybe<BookingVehicleDamagesCountAggregate>;
  _min?: Maybe<BookingVehicleDamagesMinAggregate>;
  _max?: Maybe<BookingVehicleDamagesMaxAggregate>;
};

export type BookingVehicleDamagesListRelationFilter = {
  every?: Maybe<BookingVehicleDamagesWhereInput>;
  some?: Maybe<BookingVehicleDamagesWhereInput>;
  none?: Maybe<BookingVehicleDamagesWhereInput>;
};

export type BookingVehicleDamagesMaxAggregate = {
  __typename?: 'BookingVehicleDamagesMaxAggregate';
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type BookingVehicleDamagesMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  seenAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type BookingVehicleDamagesMinAggregate = {
  __typename?: 'BookingVehicleDamagesMinAggregate';
  id?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type BookingVehicleDamagesMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  seenAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type BookingVehicleDamagesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BookingVehicleDamagesOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  seenAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  _count?: Maybe<BookingVehicleDamagesCountOrderByAggregateInput>;
  _max?: Maybe<BookingVehicleDamagesMaxOrderByAggregateInput>;
  _min?: Maybe<BookingVehicleDamagesMinOrderByAggregateInput>;
};

export type BookingVehicleDamagesOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  seenAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
};

export enum BookingVehicleDamagesScalarFieldEnum {
  Id = 'id',
  SeenAt = 'seenAt',
  UserId = 'userId',
  VehicleId = 'vehicleId',
}

export type BookingVehicleDamagesScalarWhereInput = {
  AND?: Maybe<Array<BookingVehicleDamagesScalarWhereInput>>;
  OR?: Maybe<Array<BookingVehicleDamagesScalarWhereInput>>;
  NOT?: Maybe<Array<BookingVehicleDamagesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  seenAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
  vehicleId?: Maybe<StringFilter>;
};

export type BookingVehicleDamagesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BookingVehicleDamagesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BookingVehicleDamagesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BookingVehicleDamagesScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  seenAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
};

export type BookingVehicleDamagesUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  seenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBookingVehicleDamagesInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingVehicleDamagesInput>;
};

export type BookingVehicleDamagesUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  seenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BookingVehicleDamagesUpdateManyWithWhereWithoutUserInput = {
  where: BookingVehicleDamagesScalarWhereInput;
  data: BookingVehicleDamagesUpdateManyMutationInput;
};

export type BookingVehicleDamagesUpdateManyWithWhereWithoutVehicleInput = {
  where: BookingVehicleDamagesScalarWhereInput;
  data: BookingVehicleDamagesUpdateManyMutationInput;
};

export type BookingVehicleDamagesUpdateManyWithoutUserInput = {
  create?: Maybe<Array<BookingVehicleDamagesCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<BookingVehicleDamagesCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<BookingVehicleDamagesUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<BookingVehicleDamagesCreateManyUserInputEnvelope>;
  set?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  delete?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  connect?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingVehicleDamagesUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<BookingVehicleDamagesUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<BookingVehicleDamagesScalarWhereInput>>;
};

export type BookingVehicleDamagesUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<BookingVehicleDamagesCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<BookingVehicleDamagesCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<BookingVehicleDamagesUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<BookingVehicleDamagesCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  disconnect?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  delete?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  connect?: Maybe<Array<BookingVehicleDamagesWhereUniqueInput>>;
  update?: Maybe<
    Array<BookingVehicleDamagesUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<BookingVehicleDamagesUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<BookingVehicleDamagesScalarWhereInput>>;
};

export type BookingVehicleDamagesUpdateWithWhereUniqueWithoutUserInput = {
  where: BookingVehicleDamagesWhereUniqueInput;
  data: BookingVehicleDamagesUpdateWithoutUserInput;
};

export type BookingVehicleDamagesUpdateWithWhereUniqueWithoutVehicleInput = {
  where: BookingVehicleDamagesWhereUniqueInput;
  data: BookingVehicleDamagesUpdateWithoutVehicleInput;
};

export type BookingVehicleDamagesUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  seenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutBookingVehicleDamagesInput>;
};

export type BookingVehicleDamagesUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  seenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBookingVehicleDamagesInput>;
};

export type BookingVehicleDamagesUpsertWithWhereUniqueWithoutUserInput = {
  where: BookingVehicleDamagesWhereUniqueInput;
  update: BookingVehicleDamagesUpdateWithoutUserInput;
  create: BookingVehicleDamagesCreateWithoutUserInput;
};

export type BookingVehicleDamagesUpsertWithWhereUniqueWithoutVehicleInput = {
  where: BookingVehicleDamagesWhereUniqueInput;
  update: BookingVehicleDamagesUpdateWithoutVehicleInput;
  create: BookingVehicleDamagesCreateWithoutVehicleInput;
};

export type BookingVehicleDamagesWhereInput = {
  AND?: Maybe<Array<BookingVehicleDamagesWhereInput>>;
  OR?: Maybe<Array<BookingVehicleDamagesWhereInput>>;
  NOT?: Maybe<Array<BookingVehicleDamagesWhereInput>>;
  id?: Maybe<StringFilter>;
  seenAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
};

export type BookingVehicleDamagesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BookingWhereInput = {
  AND?: Maybe<Array<BookingWhereInput>>;
  OR?: Maybe<Array<BookingWhereInput>>;
  NOT?: Maybe<Array<BookingWhereInput>>;
  id?: Maybe<StringFilter>;
  bookingHash?: Maybe<StringNullableFilter>;
  convadisBookingIncrement?: Maybe<IntNullableFilter>;
  vat?: Maybe<FloatNullableFilter>;
  noShowAt?: Maybe<DateTimeNullableFilter>;
  revokeReason?: Maybe<StringNullableFilter>;
  revokedAt?: Maybe<DateTimeNullableFilter>;
  revokedByUser?: Maybe<UserRelationFilter>;
  revokedByUserId?: Maybe<StringNullableFilter>;
  adminChangeComment?: Maybe<StringNullableFilter>;
  adminChangeCommentAt?: Maybe<DateTimeNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  endDate?: Maybe<DateTimeFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  beforeDrivingQuestionsAnsweredAt?: Maybe<DateTimeNullableFilter>;
  afterDrivingQuestionsAnsweredAt?: Maybe<DateTimeNullableFilter>;
  stripeTransferedAt?: Maybe<DateTimeNullableFilter>;
  skipVehicleStatus?: Maybe<BoolNullableFilter>;
  userId?: Maybe<StringFilter>;
  commission?: Maybe<FloatFilter>;
  user?: Maybe<UserRelationFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  vehicleLocation?: Maybe<VehicleLocationRelationFilter>;
  vehicleLocationId?: Maybe<StringNullableFilter>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupRelationFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  vehicleMileageOnEffectiveStart?: Maybe<FloatNullableFilter>;
  vehicleMileageOnEffectiveEnd?: Maybe<FloatNullableFilter>;
  effectiveBookingCost?: Maybe<FloatNullableFilter>;
  bluetoothToken?: Maybe<StringNullableFilter>;
  bluetoothTokenUuid?: Maybe<StringNullableFilter>;
  sessionKey?: Maybe<StringNullableFilter>;
  cancelDate?: Maybe<DateTimeNullableFilter>;
  cancellationFees?: Maybe<FloatNullableFilter>;
  stripeIntentId?: Maybe<StringNullableFilter>;
  stripePaymentCreateAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentConfirmedAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentAuthorisationAttempts?: Maybe<IntNullableFilter>;
  stripeAdditionalPaymentIntentId?: Maybe<StringNullableFilter>;
  stripeAdditionalPaymentConfirmed?: Maybe<BoolNullableFilter>;
  stripeAdditionalPaymentAuthorizedAt?: Maybe<DateTimeNullableFilter>;
  userConfirmedAt?: Maybe<DateTimeNullableFilter>;
  adminBookingComment?: Maybe<StringNullableFilter>;
  excessKm?: Maybe<FloatNullableFilter>;
  excessKmCost?: Maybe<FloatNullableFilter>;
  priceHour?: Maybe<FloatNullableFilter>;
  priceDay?: Maybe<FloatNullableFilter>;
  priceKm?: Maybe<FloatNullableFilter>;
  priceWeek?: Maybe<FloatNullableFilter>;
  includedKmHour?: Maybe<FloatNullableFilter>;
  includedKmDay?: Maybe<FloatNullableFilter>;
  includedKmWeek?: Maybe<FloatNullableFilter>;
  totalCost?: Maybe<FloatNullableFilter>;
  lateAttemptMessage?: Maybe<IntNullableFilter>;
  lateAttemptMessageAt?: Maybe<DateTimeNullableFilter>;
  last4Digits?: Maybe<StringNullableFilter>;
  cardBrand?: Maybe<StringNullableFilter>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeRelationFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableFilter>;
  vehicleDamage?: Maybe<VehicleDamageListRelationFilter>;
  bookingAvailability?: Maybe<BookingAvailabilityListRelationFilter>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogListRelationFilter>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverListRelationFilter>;
  bookingAnswer?: Maybe<BookingAnswerListRelationFilter>;
  bookingFuelPassword?: Maybe<BookingFuelPasswordListRelationFilter>;
  bookingRefunding?: Maybe<BookingRefundingListRelationFilter>;
  gateControllerTransactions?: Maybe<GateControllerTransactionListRelationFilter>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryListRelationFilter>;
};

export type BookingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  bookingHash?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type BoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type BrandType = {
  __typename?: 'BrandType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<BrandTypeCount>;
  vehicle: Array<Vehicle>;
};

export type BrandTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type BrandTypeCount = {
  __typename?: 'BrandTypeCount';
  vehicle: Scalars['Int'];
};

export type BrandTypeCountAggregate = {
  __typename?: 'BrandTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BrandTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type BrandTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutBrandTypeInput>;
};

export type BrandTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type BrandTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<BrandTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<BrandTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<BrandTypeWhereUniqueInput>;
};

export type BrandTypeCreateOrConnectWithoutVehicleInput = {
  where: BrandTypeWhereUniqueInput;
  create: BrandTypeCreateWithoutVehicleInput;
};

export type BrandTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type BrandTypeGroupBy = {
  __typename?: 'BrandTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<BrandTypeCountAggregate>;
  _min?: Maybe<BrandTypeMinAggregate>;
  _max?: Maybe<BrandTypeMaxAggregate>;
};

export type BrandTypeMaxAggregate = {
  __typename?: 'BrandTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type BrandTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type BrandTypeMinAggregate = {
  __typename?: 'BrandTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type BrandTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type BrandTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<BrandTypeCountOrderByAggregateInput>;
  _max?: Maybe<BrandTypeMaxOrderByAggregateInput>;
  _min?: Maybe<BrandTypeMinOrderByAggregateInput>;
};

export type BrandTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type BrandTypeRelationFilter = {
  is?: Maybe<BrandTypeWhereInput>;
  isNot?: Maybe<BrandTypeWhereInput>;
};

export enum BrandTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type BrandTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BrandTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BrandTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BrandTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BrandTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutBrandTypeInput>;
};

export type BrandTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type BrandTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<BrandTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<BrandTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<BrandTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<BrandTypeWhereUniqueInput>;
  update?: Maybe<BrandTypeUpdateWithoutVehicleInput>;
};

export type BrandTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type BrandTypeUpsertWithoutVehicleInput = {
  update: BrandTypeUpdateWithoutVehicleInput;
  create: BrandTypeCreateWithoutVehicleInput;
};

export type BrandTypeWhereInput = {
  AND?: Maybe<Array<BrandTypeWhereInput>>;
  OR?: Maybe<Array<BrandTypeWhereInput>>;
  NOT?: Maybe<Array<BrandTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type BrandTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CalculationTariffItem = {
  __typename?: 'CalculationTariffItem';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStationId: Scalars['String'];
  tariffType: ChargingStationTariffType;
  _count?: Maybe<ChargingStationTariffCount>;
  chargingStation: ChargingStation;
  chargingStationTransactionAppliedTariffs: Array<ChargingStationTransactionAppliedTariff>;
  chargingStationRules: Array<ChargingStationTariffRule>;
  users: Array<User>;
  rfids: Array<ChargingStationRfid>;
  percentage: Scalars['Float'];
  usedkWh: Scalars['Float'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type CalculationTariffItemChargingStationTransactionAppliedTariffsArgs =
  {
    where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
    orderBy?: Maybe<
      Array<ChargingStationTransactionAppliedTariffOrderByWithRelationInput>
    >;
    cursor?: Maybe<ChargingStationTransactionAppliedTariffWhereUniqueInput>;
    take?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    distinct?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarFieldEnum>
    >;
  };

export type CalculationTariffItemChargingStationRulesArgs = {
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffRuleOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffRuleScalarFieldEnum>>;
};

export type CalculationTariffItemUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type CalculationTariffItemRfidsArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidScalarFieldEnum>>;
};

export type ChargingStation = {
  __typename?: 'ChargingStation';
  id: Scalars['String'];
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationClusterId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationCount>;
  chargingStationTransactions: Array<ChargingStationTransaction>;
  chargingStationTariffs: Array<ChargingStationTariff>;
  chargingStationCluster?: Maybe<ChargingStationCluster>;
  status?: Maybe<Scalars['String']>;
};

export type ChargingStationChargingStationTransactionsArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionScalarFieldEnum>>;
};

export type ChargingStationChargingStationTariffsArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffScalarFieldEnum>>;
};

export type ChargingStationAvgAggregate = {
  __typename?: 'ChargingStationAvgAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type ChargingStationAvgOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
};

export type ChargingStationCluster = {
  __typename?: 'ChargingStationCluster';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationClusterCount>;
  company?: Maybe<Company>;
  chargingStations: Array<ChargingStation>;
};

export type ChargingStationClusterChargingStationsArgs = {
  where?: Maybe<ChargingStationWhereInput>;
  orderBy?: Maybe<Array<ChargingStationOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationScalarFieldEnum>>;
};

export type ChargingStationClusterCount = {
  __typename?: 'ChargingStationClusterCount';
  chargingStations: Scalars['Int'];
};

export type ChargingStationClusterCountAggregate = {
  __typename?: 'ChargingStationClusterCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  companyId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationClusterCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type ChargingStationClusterCreateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyCreateNestedOneWithoutChargingStationClustersInput>;
  chargingStations?: Maybe<ChargingStationCreateNestedManyWithoutChargingStationClusterInput>;
};

export type ChargingStationClusterCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChargingStationClusterCreateManyCompanyInputEnvelope = {
  data: Array<ChargingStationClusterCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChargingStationClusterCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationClusterCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<ChargingStationClusterCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationClusterCreateOrConnectWithoutCompanyInput>
  >;
  createMany?: Maybe<ChargingStationClusterCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<ChargingStationClusterWhereUniqueInput>>;
};

export type ChargingStationClusterCreateNestedOneWithoutChargingStationsInput =
  {
    create?: Maybe<ChargingStationClusterCreateWithoutChargingStationsInput>;
    connectOrCreate?: Maybe<ChargingStationClusterCreateOrConnectWithoutChargingStationsInput>;
    connect?: Maybe<ChargingStationClusterWhereUniqueInput>;
  };

export type ChargingStationClusterCreateOrConnectWithoutChargingStationsInput =
  {
    where: ChargingStationClusterWhereUniqueInput;
    create: ChargingStationClusterCreateWithoutChargingStationsInput;
  };

export type ChargingStationClusterCreateOrConnectWithoutCompanyInput = {
  where: ChargingStationClusterWhereUniqueInput;
  create: ChargingStationClusterCreateWithoutCompanyInput;
};

export type ChargingStationClusterCreateWithoutChargingStationsInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyCreateNestedOneWithoutChargingStationClustersInput>;
};

export type ChargingStationClusterCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  chargingStations?: Maybe<ChargingStationCreateNestedManyWithoutChargingStationClusterInput>;
};

export type ChargingStationClusterGroupBy = {
  __typename?: 'ChargingStationClusterGroupBy';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationClusterCountAggregate>;
  _min?: Maybe<ChargingStationClusterMinAggregate>;
  _max?: Maybe<ChargingStationClusterMaxAggregate>;
};

export type ChargingStationClusterListRelationFilter = {
  every?: Maybe<ChargingStationClusterWhereInput>;
  some?: Maybe<ChargingStationClusterWhereInput>;
  none?: Maybe<ChargingStationClusterWhereInput>;
};

export type ChargingStationClusterMaxAggregate = {
  __typename?: 'ChargingStationClusterMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationClusterMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type ChargingStationClusterMinAggregate = {
  __typename?: 'ChargingStationClusterMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationClusterMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type ChargingStationClusterOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationClusterOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationClusterCountOrderByAggregateInput>;
  _max?: Maybe<ChargingStationClusterMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationClusterMinOrderByAggregateInput>;
};

export type ChargingStationClusterOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
  chargingStations?: Maybe<ChargingStationOrderByRelationAggregateInput>;
};

export type ChargingStationClusterRelationFilter = {
  is?: Maybe<ChargingStationClusterWhereInput>;
  isNot?: Maybe<ChargingStationClusterWhereInput>;
};

export enum ChargingStationClusterScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  CompanyId = 'companyId',
}

export type ChargingStationClusterScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationClusterScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationClusterScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationClusterScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type ChargingStationClusterScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChargingStationClusterScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChargingStationClusterScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChargingStationClusterScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringNullableWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChargingStationClusterUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutChargingStationClustersInput>;
  chargingStations?: Maybe<ChargingStationUpdateManyWithoutChargingStationClusterInput>;
};

export type ChargingStationClusterUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ChargingStationClusterUpdateManyWithWhereWithoutCompanyInput = {
  where: ChargingStationClusterScalarWhereInput;
  data: ChargingStationClusterUpdateManyMutationInput;
};

export type ChargingStationClusterUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<ChargingStationClusterCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationClusterCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationClusterUpsertWithWhereUniqueWithoutCompanyInput>
  >;
  createMany?: Maybe<ChargingStationClusterCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<ChargingStationClusterWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationClusterWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationClusterWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationClusterWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationClusterUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationClusterUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationClusterScalarWhereInput>>;
};

export type ChargingStationClusterUpdateOneWithoutChargingStationsInput = {
  create?: Maybe<ChargingStationClusterCreateWithoutChargingStationsInput>;
  connectOrCreate?: Maybe<ChargingStationClusterCreateOrConnectWithoutChargingStationsInput>;
  upsert?: Maybe<ChargingStationClusterUpsertWithoutChargingStationsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChargingStationClusterWhereUniqueInput>;
  update?: Maybe<ChargingStationClusterUpdateWithoutChargingStationsInput>;
};

export type ChargingStationClusterUpdateWithWhereUniqueWithoutCompanyInput = {
  where: ChargingStationClusterWhereUniqueInput;
  data: ChargingStationClusterUpdateWithoutCompanyInput;
};

export type ChargingStationClusterUpdateWithoutChargingStationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutChargingStationClustersInput>;
};

export type ChargingStationClusterUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chargingStations?: Maybe<ChargingStationUpdateManyWithoutChargingStationClusterInput>;
};

export type ChargingStationClusterUpsertWithWhereUniqueWithoutCompanyInput = {
  where: ChargingStationClusterWhereUniqueInput;
  update: ChargingStationClusterUpdateWithoutCompanyInput;
  create: ChargingStationClusterCreateWithoutCompanyInput;
};

export type ChargingStationClusterUpsertWithoutChargingStationsInput = {
  update: ChargingStationClusterUpdateWithoutChargingStationsInput;
  create: ChargingStationClusterCreateWithoutChargingStationsInput;
};

export type ChargingStationClusterWhereInput = {
  AND?: Maybe<Array<ChargingStationClusterWhereInput>>;
  OR?: Maybe<Array<ChargingStationClusterWhereInput>>;
  NOT?: Maybe<Array<ChargingStationClusterWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
  chargingStations?: Maybe<ChargingStationListRelationFilter>;
};

export type ChargingStationClusterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChargingStationCount = {
  __typename?: 'ChargingStationCount';
  chargingStationTransactions: Scalars['Int'];
  chargingStationTariffs: Scalars['Int'];
};

export type ChargingStationCountAggregate = {
  __typename?: 'ChargingStationCountAggregate';
  id: Scalars['Int'];
  evesId: Scalars['Int'];
  name: Scalars['Int'];
  deletedAt: Scalars['Int'];
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  operatorCompanyName: Scalars['Int'];
  operatorCompanyPerson: Scalars['Int'];
  operatorCompanyPhone: Scalars['Int'];
  operatorCompanyEmail: Scalars['Int'];
  chargingStationClusterId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  evesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  chargingStationClusterId?: Maybe<SortOrder>;
};

export type ChargingStationCreateInput = {
  id?: Maybe<Scalars['String']>;
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutChargingStationInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutChargingStationInput>;
  chargingStationCluster?: Maybe<ChargingStationClusterCreateNestedOneWithoutChargingStationsInput>;
};

export type ChargingStationCreateManyChargingStationClusterInput = {
  id?: Maybe<Scalars['String']>;
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
};

export type ChargingStationCreateManyChargingStationClusterInputEnvelope = {
  data: Array<ChargingStationCreateManyChargingStationClusterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChargingStationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationClusterId?: Maybe<Scalars['String']>;
};

export type ChargingStationCreateNestedManyWithoutChargingStationClusterInput =
  {
    create?: Maybe<
      Array<ChargingStationCreateWithoutChargingStationClusterInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationCreateOrConnectWithoutChargingStationClusterInput>
    >;
    createMany?: Maybe<ChargingStationCreateManyChargingStationClusterInputEnvelope>;
    connect?: Maybe<Array<ChargingStationWhereUniqueInput>>;
  };

export type ChargingStationCreateNestedOneWithoutChargingStationTariffsInput = {
  create?: Maybe<ChargingStationCreateWithoutChargingStationTariffsInput>;
  connectOrCreate?: Maybe<ChargingStationCreateOrConnectWithoutChargingStationTariffsInput>;
  connect?: Maybe<ChargingStationWhereUniqueInput>;
};

export type ChargingStationCreateNestedOneWithoutChargingStationTransactionsInput =
  {
    create?: Maybe<ChargingStationCreateWithoutChargingStationTransactionsInput>;
    connectOrCreate?: Maybe<ChargingStationCreateOrConnectWithoutChargingStationTransactionsInput>;
    connect?: Maybe<ChargingStationWhereUniqueInput>;
  };

export type ChargingStationCreateOrConnectWithoutChargingStationClusterInput = {
  where: ChargingStationWhereUniqueInput;
  create: ChargingStationCreateWithoutChargingStationClusterInput;
};

export type ChargingStationCreateOrConnectWithoutChargingStationTariffsInput = {
  where: ChargingStationWhereUniqueInput;
  create: ChargingStationCreateWithoutChargingStationTariffsInput;
};

export type ChargingStationCreateOrConnectWithoutChargingStationTransactionsInput =
  {
    where: ChargingStationWhereUniqueInput;
    create: ChargingStationCreateWithoutChargingStationTransactionsInput;
  };

export type ChargingStationCreateWithoutChargingStationClusterInput = {
  id?: Maybe<Scalars['String']>;
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutChargingStationInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutChargingStationInput>;
};

export type ChargingStationCreateWithoutChargingStationTariffsInput = {
  id?: Maybe<Scalars['String']>;
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutChargingStationInput>;
  chargingStationCluster?: Maybe<ChargingStationClusterCreateNestedOneWithoutChargingStationsInput>;
};

export type ChargingStationCreateWithoutChargingStationTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutChargingStationInput>;
  chargingStationCluster?: Maybe<ChargingStationClusterCreateNestedOneWithoutChargingStationsInput>;
};

export type ChargingStationGroupBy = {
  __typename?: 'ChargingStationGroupBy';
  id: Scalars['String'];
  evesId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationClusterId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationCountAggregate>;
  _avg?: Maybe<ChargingStationAvgAggregate>;
  _sum?: Maybe<ChargingStationSumAggregate>;
  _min?: Maybe<ChargingStationMinAggregate>;
  _max?: Maybe<ChargingStationMaxAggregate>;
};

export type ChargingStationInstallation = {
  __typename?: 'ChargingStationInstallation';
  eves_id?: Maybe<Scalars['String']>;
  fuseNumber?: Maybe<Array<Scalars['Float']>>;
  disabled?: Maybe<Scalars['Float']>;
  connectorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  serialnumber?: Maybe<Scalars['String']>;
  descriptionGui?: Maybe<Scalars['String']>;
  descriptionHmi?: Maybe<Scalars['String']>;
  meterId?: Maybe<Scalars['String']>;
  rfidEnabled?: Maybe<Scalars['Boolean']>;
  rfidLearningMode?: Maybe<Scalars['Float']>;
  installationId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationListRelationFilter = {
  every?: Maybe<ChargingStationWhereInput>;
  some?: Maybe<ChargingStationWhereInput>;
  none?: Maybe<ChargingStationWhereInput>;
};

export type ChargingStationMaxAggregate = {
  __typename?: 'ChargingStationMaxAggregate';
  id?: Maybe<Scalars['String']>;
  evesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationClusterId?: Maybe<Scalars['String']>;
};

export type ChargingStationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  evesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  chargingStationClusterId?: Maybe<SortOrder>;
};

export type ChargingStationMinAggregate = {
  __typename?: 'ChargingStationMinAggregate';
  id?: Maybe<Scalars['String']>;
  evesId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  chargingStationClusterId?: Maybe<Scalars['String']>;
};

export type ChargingStationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  evesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  chargingStationClusterId?: Maybe<SortOrder>;
};

export type ChargingStationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  evesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  chargingStationClusterId?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationCountOrderByAggregateInput>;
  _avg?: Maybe<ChargingStationAvgOrderByAggregateInput>;
  _max?: Maybe<ChargingStationMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationMinOrderByAggregateInput>;
  _sum?: Maybe<ChargingStationSumOrderByAggregateInput>;
};

export type ChargingStationOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  evesId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionOrderByRelationAggregateInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffOrderByRelationAggregateInput>;
  chargingStationCluster?: Maybe<ChargingStationClusterOrderByWithRelationInput>;
  chargingStationClusterId?: Maybe<SortOrder>;
};

export type ChargingStationRelationFilter = {
  is?: Maybe<ChargingStationWhereInput>;
  isNot?: Maybe<ChargingStationWhereInput>;
};

export type ChargingStationRfid = {
  __typename?: 'ChargingStationRfid';
  id: Scalars['String'];
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationRfidCount>;
  billingAccount?: Maybe<BillingAccount>;
  chargingStationTransactions: Array<ChargingStationTransaction>;
  chargingStationTariffs: Array<ChargingStationTariff>;
  chargingStationRfidAlias: Array<ChargingStationRfidAlias>;
};

export type ChargingStationRfidChargingStationTransactionsArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionScalarFieldEnum>>;
};

export type ChargingStationRfidChargingStationTariffsArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffScalarFieldEnum>>;
};

export type ChargingStationRfidChargingStationRfidAliasArgs = {
  where?: Maybe<ChargingStationRfidAliasWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidAliasOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidAliasWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidAliasScalarFieldEnum>>;
};

export type ChargingStationRfidAlias = {
  __typename?: 'ChargingStationRfidAlias';
  id: Scalars['String'];
  tag: Scalars['String'];
  chargingStationRfidId?: Maybe<Scalars['String']>;
  chargingStationRfid?: Maybe<ChargingStationRfid>;
};

export type ChargingStationRfidAliasCountAggregate = {
  __typename?: 'ChargingStationRfidAliasCountAggregate';
  id: Scalars['Int'];
  tag: Scalars['Int'];
  chargingStationRfidId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationRfidAliasCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
};

export type ChargingStationRfidAliasCreateInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  chargingStationRfid?: Maybe<ChargingStationRfidCreateNestedOneWithoutChargingStationRfidAliasInput>;
};

export type ChargingStationRfidAliasCreateManyChargingStationRfidInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type ChargingStationRfidAliasCreateManyChargingStationRfidInputEnvelope =
  {
    data: Array<ChargingStationRfidAliasCreateManyChargingStationRfidInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type ChargingStationRfidAliasCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  chargingStationRfidId?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidAliasCreateNestedManyWithoutChargingStationRfidInput =
  {
    create?: Maybe<
      Array<ChargingStationRfidAliasCreateWithoutChargingStationRfidInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationRfidAliasCreateOrConnectWithoutChargingStationRfidInput>
    >;
    createMany?: Maybe<ChargingStationRfidAliasCreateManyChargingStationRfidInputEnvelope>;
    connect?: Maybe<Array<ChargingStationRfidAliasWhereUniqueInput>>;
  };

export type ChargingStationRfidAliasCreateOrConnectWithoutChargingStationRfidInput =
  {
    where: ChargingStationRfidAliasWhereUniqueInput;
    create: ChargingStationRfidAliasCreateWithoutChargingStationRfidInput;
  };

export type ChargingStationRfidAliasCreateWithoutChargingStationRfidInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type ChargingStationRfidAliasGroupBy = {
  __typename?: 'ChargingStationRfidAliasGroupBy';
  id: Scalars['String'];
  tag: Scalars['String'];
  chargingStationRfidId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationRfidAliasCountAggregate>;
  _min?: Maybe<ChargingStationRfidAliasMinAggregate>;
  _max?: Maybe<ChargingStationRfidAliasMaxAggregate>;
};

export type ChargingStationRfidAliasListRelationFilter = {
  every?: Maybe<ChargingStationRfidAliasWhereInput>;
  some?: Maybe<ChargingStationRfidAliasWhereInput>;
  none?: Maybe<ChargingStationRfidAliasWhereInput>;
};

export type ChargingStationRfidAliasMaxAggregate = {
  __typename?: 'ChargingStationRfidAliasMaxAggregate';
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidAliasMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
};

export type ChargingStationRfidAliasMinAggregate = {
  __typename?: 'ChargingStationRfidAliasMinAggregate';
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidAliasMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
};

export type ChargingStationRfidAliasOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationRfidAliasOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationRfidAliasCountOrderByAggregateInput>;
  _max?: Maybe<ChargingStationRfidAliasMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationRfidAliasMinOrderByAggregateInput>;
};

export type ChargingStationRfidAliasOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  chargingStationRfid?: Maybe<ChargingStationRfidOrderByWithRelationInput>;
  chargingStationRfidId?: Maybe<SortOrder>;
};

export enum ChargingStationRfidAliasScalarFieldEnum {
  Id = 'id',
  Tag = 'tag',
  ChargingStationRfidId = 'chargingStationRfidId',
}

export type ChargingStationRfidAliasScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationRfidAliasScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationRfidAliasScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationRfidAliasScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  tag?: Maybe<StringFilter>;
  chargingStationRfidId?: Maybe<StringNullableFilter>;
};

export type ChargingStationRfidAliasScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChargingStationRfidAliasScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChargingStationRfidAliasScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChargingStationRfidAliasScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  tag?: Maybe<StringWithAggregatesFilter>;
  chargingStationRfidId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChargingStationRfidAliasUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  chargingStationRfid?: Maybe<ChargingStationRfidUpdateOneWithoutChargingStationRfidAliasInput>;
};

export type ChargingStationRfidAliasUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ChargingStationRfidAliasUpdateManyWithWhereWithoutChargingStationRfidInput =
  {
    where: ChargingStationRfidAliasScalarWhereInput;
    data: ChargingStationRfidAliasUpdateManyMutationInput;
  };

export type ChargingStationRfidAliasUpdateManyWithoutChargingStationRfidInput =
  {
    create?: Maybe<
      Array<ChargingStationRfidAliasCreateWithoutChargingStationRfidInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationRfidAliasCreateOrConnectWithoutChargingStationRfidInput>
    >;
    upsert?: Maybe<
      Array<ChargingStationRfidAliasUpsertWithWhereUniqueWithoutChargingStationRfidInput>
    >;
    createMany?: Maybe<ChargingStationRfidAliasCreateManyChargingStationRfidInputEnvelope>;
    set?: Maybe<Array<ChargingStationRfidAliasWhereUniqueInput>>;
    disconnect?: Maybe<Array<ChargingStationRfidAliasWhereUniqueInput>>;
    delete?: Maybe<Array<ChargingStationRfidAliasWhereUniqueInput>>;
    connect?: Maybe<Array<ChargingStationRfidAliasWhereUniqueInput>>;
    update?: Maybe<
      Array<ChargingStationRfidAliasUpdateWithWhereUniqueWithoutChargingStationRfidInput>
    >;
    updateMany?: Maybe<
      Array<ChargingStationRfidAliasUpdateManyWithWhereWithoutChargingStationRfidInput>
    >;
    deleteMany?: Maybe<Array<ChargingStationRfidAliasScalarWhereInput>>;
  };

export type ChargingStationRfidAliasUpdateWithWhereUniqueWithoutChargingStationRfidInput =
  {
    where: ChargingStationRfidAliasWhereUniqueInput;
    data: ChargingStationRfidAliasUpdateWithoutChargingStationRfidInput;
  };

export type ChargingStationRfidAliasUpdateWithoutChargingStationRfidInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ChargingStationRfidAliasUpsertWithWhereUniqueWithoutChargingStationRfidInput =
  {
    where: ChargingStationRfidAliasWhereUniqueInput;
    update: ChargingStationRfidAliasUpdateWithoutChargingStationRfidInput;
    create: ChargingStationRfidAliasCreateWithoutChargingStationRfidInput;
  };

export type ChargingStationRfidAliasWhereInput = {
  AND?: Maybe<Array<ChargingStationRfidAliasWhereInput>>;
  OR?: Maybe<Array<ChargingStationRfidAliasWhereInput>>;
  NOT?: Maybe<Array<ChargingStationRfidAliasWhereInput>>;
  id?: Maybe<StringFilter>;
  tag?: Maybe<StringFilter>;
  chargingStationRfid?: Maybe<ChargingStationRfidRelationFilter>;
  chargingStationRfidId?: Maybe<StringNullableFilter>;
};

export type ChargingStationRfidAliasWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidCount = {
  __typename?: 'ChargingStationRfidCount';
  chargingStationTransactions: Scalars['Int'];
  chargingStationTariffs: Scalars['Int'];
  chargingStationRfidAlias: Scalars['Int'];
};

export type ChargingStationRfidCountAggregate = {
  __typename?: 'ChargingStationRfidCountAggregate';
  id: Scalars['Int'];
  tag: Scalars['Int'];
  description: Scalars['Int'];
  owner: Scalars['Int'];
  billingAccountId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationRfidCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  owner?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
};

export type ChargingStationRfidCreateInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccount?: Maybe<BillingAccountCreateNestedOneWithoutChargingStationRfiDsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutChargingStationRfidInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutRfidsInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasCreateNestedManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidCreateManyBillingAccountInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidCreateManyBillingAccountInputEnvelope = {
  data: Array<ChargingStationRfidCreateManyBillingAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChargingStationRfidCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidCreateNestedManyWithoutBillingAccountInput = {
  create?: Maybe<Array<ChargingStationRfidCreateWithoutBillingAccountInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationRfidCreateOrConnectWithoutBillingAccountInput>
  >;
  createMany?: Maybe<ChargingStationRfidCreateManyBillingAccountInputEnvelope>;
  connect?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
};

export type ChargingStationRfidCreateNestedManyWithoutChargingStationTariffsInput =
  {
    create?: Maybe<
      Array<ChargingStationRfidCreateWithoutChargingStationTariffsInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationRfidCreateOrConnectWithoutChargingStationTariffsInput>
    >;
    connect?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  };

export type ChargingStationRfidCreateNestedOneWithoutChargingStationRfidAliasInput =
  {
    create?: Maybe<ChargingStationRfidCreateWithoutChargingStationRfidAliasInput>;
    connectOrCreate?: Maybe<ChargingStationRfidCreateOrConnectWithoutChargingStationRfidAliasInput>;
    connect?: Maybe<ChargingStationRfidWhereUniqueInput>;
  };

export type ChargingStationRfidCreateNestedOneWithoutChargingStationTransactionsInput =
  {
    create?: Maybe<ChargingStationRfidCreateWithoutChargingStationTransactionsInput>;
    connectOrCreate?: Maybe<ChargingStationRfidCreateOrConnectWithoutChargingStationTransactionsInput>;
    connect?: Maybe<ChargingStationRfidWhereUniqueInput>;
  };

export type ChargingStationRfidCreateOrConnectWithoutBillingAccountInput = {
  where: ChargingStationRfidWhereUniqueInput;
  create: ChargingStationRfidCreateWithoutBillingAccountInput;
};

export type ChargingStationRfidCreateOrConnectWithoutChargingStationRfidAliasInput =
  {
    where: ChargingStationRfidWhereUniqueInput;
    create: ChargingStationRfidCreateWithoutChargingStationRfidAliasInput;
  };

export type ChargingStationRfidCreateOrConnectWithoutChargingStationTariffsInput =
  {
    where: ChargingStationRfidWhereUniqueInput;
    create: ChargingStationRfidCreateWithoutChargingStationTariffsInput;
  };

export type ChargingStationRfidCreateOrConnectWithoutChargingStationTransactionsInput =
  {
    where: ChargingStationRfidWhereUniqueInput;
    create: ChargingStationRfidCreateWithoutChargingStationTransactionsInput;
  };

export type ChargingStationRfidCreateWithoutBillingAccountInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutChargingStationRfidInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutRfidsInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasCreateNestedManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidCreateWithoutChargingStationRfidAliasInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccount?: Maybe<BillingAccountCreateNestedOneWithoutChargingStationRfiDsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutChargingStationRfidInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutRfidsInput>;
};

export type ChargingStationRfidCreateWithoutChargingStationTariffsInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccount?: Maybe<BillingAccountCreateNestedOneWithoutChargingStationRfiDsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutChargingStationRfidInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasCreateNestedManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidCreateWithoutChargingStationTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccount?: Maybe<BillingAccountCreateNestedOneWithoutChargingStationRfiDsInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutRfidsInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasCreateNestedManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidGroupBy = {
  __typename?: 'ChargingStationRfidGroupBy';
  id: Scalars['String'];
  tag: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationRfidCountAggregate>;
  _min?: Maybe<ChargingStationRfidMinAggregate>;
  _max?: Maybe<ChargingStationRfidMaxAggregate>;
};

export type ChargingStationRfidInvisia = {
  __typename?: 'ChargingStationRfidInvisia';
  id?: Maybe<Scalars['String']>;
  originalId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  billingAccount?: Maybe<BillingAccount>;
  chargingStationRfidAlias?: Maybe<Array<ChargingStationRfidAlias>>;
};

export type ChargingStationRfidInvisiaCountAggregate = {
  __typename?: 'ChargingStationRfidInvisiaCountAggregate';
  _all: Scalars['Float'];
};

export type ChargingStationRfidInvisiaUpdateInput = {
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutChargingStationRfiDsInput>;
};

export type ChargingStationRfidInvisiaWhereInput = {
  id?: Maybe<StringNullableFilter>;
};

export type ChargingStationRfidInvisiaWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidListRelationFilter = {
  every?: Maybe<ChargingStationRfidWhereInput>;
  some?: Maybe<ChargingStationRfidWhereInput>;
  none?: Maybe<ChargingStationRfidWhereInput>;
};

export type ChargingStationRfidMaxAggregate = {
  __typename?: 'ChargingStationRfidMaxAggregate';
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  owner?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
};

export type ChargingStationRfidMinAggregate = {
  __typename?: 'ChargingStationRfidMinAggregate';
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
};

export type ChargingStationRfidMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  owner?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
};

export type ChargingStationRfidOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationRfidOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  owner?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationRfidCountOrderByAggregateInput>;
  _max?: Maybe<ChargingStationRfidMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationRfidMinOrderByAggregateInput>;
};

export type ChargingStationRfidOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  tag?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  owner?: Maybe<SortOrder>;
  billingAccount?: Maybe<BillingAccountOrderByWithRelationInput>;
  billingAccountId?: Maybe<SortOrder>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionOrderByRelationAggregateInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffOrderByRelationAggregateInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasOrderByRelationAggregateInput>;
};

export type ChargingStationRfidRelationFilter = {
  is?: Maybe<ChargingStationRfidWhereInput>;
  isNot?: Maybe<ChargingStationRfidWhereInput>;
};

export enum ChargingStationRfidScalarFieldEnum {
  Id = 'id',
  Tag = 'tag',
  Description = 'description',
  Owner = 'owner',
  BillingAccountId = 'billingAccountId',
}

export type ChargingStationRfidScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationRfidScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationRfidScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationRfidScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  tag?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  owner?: Maybe<StringNullableFilter>;
  billingAccountId?: Maybe<StringNullableFilter>;
};

export type ChargingStationRfidScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChargingStationRfidScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChargingStationRfidScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChargingStationRfidScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  tag?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  owner?: Maybe<StringNullableWithAggregatesFilter>;
  billingAccountId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChargingStationRfidUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  owner?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutChargingStationRfiDsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutChargingStationRfidInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutRfidsInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasUpdateManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  owner?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ChargingStationRfidUpdateManyWithWhereWithoutBillingAccountInput = {
  where: ChargingStationRfidScalarWhereInput;
  data: ChargingStationRfidUpdateManyMutationInput;
};

export type ChargingStationRfidUpdateManyWithWhereWithoutChargingStationTariffsInput =
  {
    where: ChargingStationRfidScalarWhereInput;
    data: ChargingStationRfidUpdateManyMutationInput;
  };

export type ChargingStationRfidUpdateManyWithoutBillingAccountInput = {
  create?: Maybe<Array<ChargingStationRfidCreateWithoutBillingAccountInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationRfidCreateOrConnectWithoutBillingAccountInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationRfidUpsertWithWhereUniqueWithoutBillingAccountInput>
  >;
  createMany?: Maybe<ChargingStationRfidCreateManyBillingAccountInputEnvelope>;
  set?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationRfidUpdateWithWhereUniqueWithoutBillingAccountInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationRfidUpdateManyWithWhereWithoutBillingAccountInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationRfidScalarWhereInput>>;
};

export type ChargingStationRfidUpdateManyWithoutChargingStationTariffsInput = {
  create?: Maybe<
    Array<ChargingStationRfidCreateWithoutChargingStationTariffsInput>
  >;
  connectOrCreate?: Maybe<
    Array<ChargingStationRfidCreateOrConnectWithoutChargingStationTariffsInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationRfidUpsertWithWhereUniqueWithoutChargingStationTariffsInput>
  >;
  set?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationRfidWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationRfidUpdateWithWhereUniqueWithoutChargingStationTariffsInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationRfidUpdateManyWithWhereWithoutChargingStationTariffsInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationRfidScalarWhereInput>>;
};

export type ChargingStationRfidUpdateOneWithoutChargingStationRfidAliasInput = {
  create?: Maybe<ChargingStationRfidCreateWithoutChargingStationRfidAliasInput>;
  connectOrCreate?: Maybe<ChargingStationRfidCreateOrConnectWithoutChargingStationRfidAliasInput>;
  upsert?: Maybe<ChargingStationRfidUpsertWithoutChargingStationRfidAliasInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChargingStationRfidWhereUniqueInput>;
  update?: Maybe<ChargingStationRfidUpdateWithoutChargingStationRfidAliasInput>;
};

export type ChargingStationRfidUpdateOneWithoutChargingStationTransactionsInput =
  {
    create?: Maybe<ChargingStationRfidCreateWithoutChargingStationTransactionsInput>;
    connectOrCreate?: Maybe<ChargingStationRfidCreateOrConnectWithoutChargingStationTransactionsInput>;
    upsert?: Maybe<ChargingStationRfidUpsertWithoutChargingStationTransactionsInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<ChargingStationRfidWhereUniqueInput>;
    update?: Maybe<ChargingStationRfidUpdateWithoutChargingStationTransactionsInput>;
  };

export type ChargingStationRfidUpdateWithWhereUniqueWithoutBillingAccountInput =
  {
    where: ChargingStationRfidWhereUniqueInput;
    data: ChargingStationRfidUpdateWithoutBillingAccountInput;
  };

export type ChargingStationRfidUpdateWithWhereUniqueWithoutChargingStationTariffsInput =
  {
    where: ChargingStationRfidWhereUniqueInput;
    data: ChargingStationRfidUpdateWithoutChargingStationTariffsInput;
  };

export type ChargingStationRfidUpdateWithoutBillingAccountInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  owner?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutChargingStationRfidInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutRfidsInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasUpdateManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidUpdateWithoutChargingStationRfidAliasInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  owner?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutChargingStationRfiDsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutChargingStationRfidInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutRfidsInput>;
};

export type ChargingStationRfidUpdateWithoutChargingStationTariffsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  owner?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutChargingStationRfiDsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutChargingStationRfidInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasUpdateManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidUpdateWithoutChargingStationTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tag?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  owner?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutChargingStationRfiDsInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutRfidsInput>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasUpdateManyWithoutChargingStationRfidInput>;
};

export type ChargingStationRfidUpsertWithWhereUniqueWithoutBillingAccountInput =
  {
    where: ChargingStationRfidWhereUniqueInput;
    update: ChargingStationRfidUpdateWithoutBillingAccountInput;
    create: ChargingStationRfidCreateWithoutBillingAccountInput;
  };

export type ChargingStationRfidUpsertWithWhereUniqueWithoutChargingStationTariffsInput =
  {
    where: ChargingStationRfidWhereUniqueInput;
    update: ChargingStationRfidUpdateWithoutChargingStationTariffsInput;
    create: ChargingStationRfidCreateWithoutChargingStationTariffsInput;
  };

export type ChargingStationRfidUpsertWithoutChargingStationRfidAliasInput = {
  update: ChargingStationRfidUpdateWithoutChargingStationRfidAliasInput;
  create: ChargingStationRfidCreateWithoutChargingStationRfidAliasInput;
};

export type ChargingStationRfidUpsertWithoutChargingStationTransactionsInput = {
  update: ChargingStationRfidUpdateWithoutChargingStationTransactionsInput;
  create: ChargingStationRfidCreateWithoutChargingStationTransactionsInput;
};

export type ChargingStationRfidWhereInput = {
  AND?: Maybe<Array<ChargingStationRfidWhereInput>>;
  OR?: Maybe<Array<ChargingStationRfidWhereInput>>;
  NOT?: Maybe<Array<ChargingStationRfidWhereInput>>;
  id?: Maybe<StringFilter>;
  tag?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  owner?: Maybe<StringNullableFilter>;
  billingAccount?: Maybe<BillingAccountRelationFilter>;
  billingAccountId?: Maybe<StringNullableFilter>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionListRelationFilter>;
  chargingStationTariffs?: Maybe<ChargingStationTariffListRelationFilter>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAliasListRelationFilter>;
};

export type ChargingStationRfidWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ChargingStationRuleProperty {
  Time = 'TIME',
}

export enum ChargingStationScalarFieldEnum {
  Id = 'id',
  EvesId = 'evesId',
  Name = 'name',
  DeletedAt = 'deletedAt',
  Latitude = 'latitude',
  Longitude = 'longitude',
  OperatorCompanyName = 'operatorCompanyName',
  OperatorCompanyPerson = 'operatorCompanyPerson',
  OperatorCompanyPhone = 'operatorCompanyPhone',
  OperatorCompanyEmail = 'operatorCompanyEmail',
  ChargingStationClusterId = 'chargingStationClusterId',
}

export type ChargingStationScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  evesId?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  operatorCompanyName?: Maybe<StringNullableFilter>;
  operatorCompanyPerson?: Maybe<StringNullableFilter>;
  operatorCompanyPhone?: Maybe<StringNullableFilter>;
  operatorCompanyEmail?: Maybe<StringNullableFilter>;
  chargingStationClusterId?: Maybe<StringNullableFilter>;
};

export type ChargingStationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChargingStationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChargingStationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChargingStationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  evesId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringNullableWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  latitude?: Maybe<FloatNullableWithAggregatesFilter>;
  longitude?: Maybe<FloatNullableWithAggregatesFilter>;
  operatorCompanyName?: Maybe<StringNullableWithAggregatesFilter>;
  operatorCompanyPerson?: Maybe<StringNullableWithAggregatesFilter>;
  operatorCompanyPhone?: Maybe<StringNullableWithAggregatesFilter>;
  operatorCompanyEmail?: Maybe<StringNullableWithAggregatesFilter>;
  chargingStationClusterId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChargingStationSumAggregate = {
  __typename?: 'ChargingStationSumAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type ChargingStationSumOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
};

export type ChargingStationTariff = {
  __typename?: 'ChargingStationTariff';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStationId: Scalars['String'];
  tariffType: ChargingStationTariffType;
  _count?: Maybe<ChargingStationTariffCount>;
  chargingStation: ChargingStation;
  chargingStationTransactionAppliedTariffs: Array<ChargingStationTransactionAppliedTariff>;
  chargingStationRules: Array<ChargingStationTariffRule>;
  users: Array<User>;
  rfids: Array<ChargingStationRfid>;
};

export type ChargingStationTariffChargingStationTransactionAppliedTariffsArgs =
  {
    where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
    orderBy?: Maybe<
      Array<ChargingStationTransactionAppliedTariffOrderByWithRelationInput>
    >;
    cursor?: Maybe<ChargingStationTransactionAppliedTariffWhereUniqueInput>;
    take?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    distinct?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarFieldEnum>
    >;
  };

export type ChargingStationTariffChargingStationRulesArgs = {
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffRuleOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffRuleScalarFieldEnum>>;
};

export type ChargingStationTariffUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type ChargingStationTariffRfidsArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidScalarFieldEnum>>;
};

export type ChargingStationTariffAvgAggregate = {
  __typename?: 'ChargingStationTariffAvgAggregate';
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffAvgOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
};

export type ChargingStationTariffCount = {
  __typename?: 'ChargingStationTariffCount';
  chargingStationTransactionAppliedTariffs: Scalars['Int'];
  chargingStationRules: Scalars['Int'];
  users: Scalars['Int'];
  rfids: Scalars['Int'];
};

export type ChargingStationTariffCountAggregate = {
  __typename?: 'ChargingStationTariffCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  price: Scalars['Int'];
  deletedAt: Scalars['Int'];
  guaranteeAmount: Scalars['Int'];
  amountPerMinute: Scalars['Int'];
  chargingStationId: Scalars['Int'];
  tariffType: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationTariffCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
  tariffType?: Maybe<SortOrder>;
};

export type ChargingStationTariffCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStation: ChargingStationCreateNestedOneWithoutChargingStationTariffsInput;
  tariffType: ChargingStationTariffType;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleCreateNestedManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserCreateNestedManyWithoutChargingStationTariffsInput>;
  rfids?: Maybe<ChargingStationRfidCreateNestedManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffCreateManyChargingStationInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  tariffType: ChargingStationTariffType;
};

export type ChargingStationTariffCreateManyChargingStationInputEnvelope = {
  data: Array<ChargingStationTariffCreateManyChargingStationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChargingStationTariffCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStationId: Scalars['String'];
  tariffType: ChargingStationTariffType;
};

export type ChargingStationTariffCreateNestedManyWithoutChargingStationInput = {
  create?: Maybe<Array<ChargingStationTariffCreateWithoutChargingStationInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTariffCreateOrConnectWithoutChargingStationInput>
  >;
  createMany?: Maybe<ChargingStationTariffCreateManyChargingStationInputEnvelope>;
  connect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
};

export type ChargingStationTariffCreateNestedManyWithoutRfidsInput = {
  create?: Maybe<Array<ChargingStationTariffCreateWithoutRfidsInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTariffCreateOrConnectWithoutRfidsInput>
  >;
  connect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
};

export type ChargingStationTariffCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<ChargingStationTariffCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTariffCreateOrConnectWithoutUsersInput>
  >;
  connect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
};

export type ChargingStationTariffCreateNestedOneWithoutChargingStationRulesInput =
  {
    create?: Maybe<ChargingStationTariffCreateWithoutChargingStationRulesInput>;
    connectOrCreate?: Maybe<ChargingStationTariffCreateOrConnectWithoutChargingStationRulesInput>;
    connect?: Maybe<ChargingStationTariffWhereUniqueInput>;
  };

export type ChargingStationTariffCreateNestedOneWithoutChargingStationTransactionAppliedTariffsInput =
  {
    create?: Maybe<ChargingStationTariffCreateWithoutChargingStationTransactionAppliedTariffsInput>;
    connectOrCreate?: Maybe<ChargingStationTariffCreateOrConnectWithoutChargingStationTransactionAppliedTariffsInput>;
    connect?: Maybe<ChargingStationTariffWhereUniqueInput>;
  };

export type ChargingStationTariffCreateOrConnectWithoutChargingStationInput = {
  where: ChargingStationTariffWhereUniqueInput;
  create: ChargingStationTariffCreateWithoutChargingStationInput;
};

export type ChargingStationTariffCreateOrConnectWithoutChargingStationRulesInput =
  {
    where: ChargingStationTariffWhereUniqueInput;
    create: ChargingStationTariffCreateWithoutChargingStationRulesInput;
  };

export type ChargingStationTariffCreateOrConnectWithoutChargingStationTransactionAppliedTariffsInput =
  {
    where: ChargingStationTariffWhereUniqueInput;
    create: ChargingStationTariffCreateWithoutChargingStationTransactionAppliedTariffsInput;
  };

export type ChargingStationTariffCreateOrConnectWithoutRfidsInput = {
  where: ChargingStationTariffWhereUniqueInput;
  create: ChargingStationTariffCreateWithoutRfidsInput;
};

export type ChargingStationTariffCreateOrConnectWithoutUsersInput = {
  where: ChargingStationTariffWhereUniqueInput;
  create: ChargingStationTariffCreateWithoutUsersInput;
};

export type ChargingStationTariffCreateWithoutChargingStationInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  tariffType: ChargingStationTariffType;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleCreateNestedManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserCreateNestedManyWithoutChargingStationTariffsInput>;
  rfids?: Maybe<ChargingStationRfidCreateNestedManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffCreateWithoutChargingStationRulesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStation: ChargingStationCreateNestedOneWithoutChargingStationTariffsInput;
  tariffType: ChargingStationTariffType;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserCreateNestedManyWithoutChargingStationTariffsInput>;
  rfids?: Maybe<ChargingStationRfidCreateNestedManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffCreateWithoutChargingStationTransactionAppliedTariffsInput =
  {
    id?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    price: Scalars['Float'];
    deletedAt?: Maybe<Scalars['DateTime']>;
    guaranteeAmount?: Maybe<Scalars['Float']>;
    amountPerMinute?: Maybe<Scalars['Float']>;
    chargingStation: ChargingStationCreateNestedOneWithoutChargingStationTariffsInput;
    tariffType: ChargingStationTariffType;
    chargingStationRules?: Maybe<ChargingStationTariffRuleCreateNestedManyWithoutChargingStationTariffInput>;
    users?: Maybe<UserCreateNestedManyWithoutChargingStationTariffsInput>;
    rfids?: Maybe<ChargingStationRfidCreateNestedManyWithoutChargingStationTariffsInput>;
  };

export type ChargingStationTariffCreateWithoutRfidsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStation: ChargingStationCreateNestedOneWithoutChargingStationTariffsInput;
  tariffType: ChargingStationTariffType;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleCreateNestedManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserCreateNestedManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStation: ChargingStationCreateNestedOneWithoutChargingStationTariffsInput;
  tariffType: ChargingStationTariffType;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleCreateNestedManyWithoutChargingStationTariffInput>;
  rfids?: Maybe<ChargingStationRfidCreateNestedManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffGroupBy = {
  __typename?: 'ChargingStationTariffGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStationId: Scalars['String'];
  tariffType: ChargingStationTariffType;
  _count?: Maybe<ChargingStationTariffCountAggregate>;
  _avg?: Maybe<ChargingStationTariffAvgAggregate>;
  _sum?: Maybe<ChargingStationTariffSumAggregate>;
  _min?: Maybe<ChargingStationTariffMinAggregate>;
  _max?: Maybe<ChargingStationTariffMaxAggregate>;
};

export type ChargingStationTariffListRelationFilter = {
  every?: Maybe<ChargingStationTariffWhereInput>;
  some?: Maybe<ChargingStationTariffWhereInput>;
  none?: Maybe<ChargingStationTariffWhereInput>;
};

export type ChargingStationTariffMaxAggregate = {
  __typename?: 'ChargingStationTariffMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStationId?: Maybe<Scalars['String']>;
  tariffType?: Maybe<ChargingStationTariffType>;
};

export type ChargingStationTariffMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
  tariffType?: Maybe<SortOrder>;
};

export type ChargingStationTariffMinAggregate = {
  __typename?: 'ChargingStationTariffMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  chargingStationId?: Maybe<Scalars['String']>;
  tariffType?: Maybe<ChargingStationTariffType>;
};

export type ChargingStationTariffMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
  tariffType?: Maybe<SortOrder>;
};

export type ChargingStationTariffOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationTariffOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
  tariffType?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationTariffCountOrderByAggregateInput>;
  _avg?: Maybe<ChargingStationTariffAvgOrderByAggregateInput>;
  _max?: Maybe<ChargingStationTariffMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationTariffMinOrderByAggregateInput>;
  _sum?: Maybe<ChargingStationTariffSumOrderByAggregateInput>;
};

export type ChargingStationTariffOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  chargingStation?: Maybe<ChargingStationOrderByWithRelationInput>;
  chargingStationId?: Maybe<SortOrder>;
  tariffType?: Maybe<SortOrder>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffOrderByRelationAggregateInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleOrderByRelationAggregateInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  rfids?: Maybe<ChargingStationRfidOrderByRelationAggregateInput>;
};

export type ChargingStationTariffRelationFilter = {
  is?: Maybe<ChargingStationTariffWhereInput>;
  isNot?: Maybe<ChargingStationTariffWhereInput>;
};

export type ChargingStationTariffRule = {
  __typename?: 'ChargingStationTariffRule';
  id: Scalars['String'];
  chargingStationTariffId: Scalars['String'];
  property: ChargingStationRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
  chargingStationTariff: ChargingStationTariff;
};

export type ChargingStationTariffRuleAvgAggregate = {
  __typename?: 'ChargingStationTariffRuleAvgAggregate';
  fromValue?: Maybe<Scalars['Float']>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleAvgOrderByAggregateInput = {
  fromValue?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type ChargingStationTariffRuleCountAggregate = {
  __typename?: 'ChargingStationTariffRuleCountAggregate';
  id: Scalars['Int'];
  chargingStationTariffId: Scalars['Int'];
  property: Scalars['Int'];
  fromCompareOperator: Scalars['Int'];
  fromValue: Scalars['Int'];
  toCompareOperator: Scalars['Int'];
  toValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationTariffRuleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  chargingStationTariffId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type ChargingStationTariffRuleCreateInput = {
  id?: Maybe<Scalars['String']>;
  chargingStationTariff: ChargingStationTariffCreateNestedOneWithoutChargingStationRulesInput;
  property: ChargingStationRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleCreateManyChargingStationTariffInput = {
  id?: Maybe<Scalars['String']>;
  property: ChargingStationRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleCreateManyChargingStationTariffInputEnvelope =
  {
    data: Array<ChargingStationTariffRuleCreateManyChargingStationTariffInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type ChargingStationTariffRuleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  chargingStationTariffId: Scalars['String'];
  property: ChargingStationRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleCreateNestedManyWithoutChargingStationTariffInput =
  {
    create?: Maybe<
      Array<ChargingStationTariffRuleCreateWithoutChargingStationTariffInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTariffRuleCreateOrConnectWithoutChargingStationTariffInput>
    >;
    createMany?: Maybe<ChargingStationTariffRuleCreateManyChargingStationTariffInputEnvelope>;
    connect?: Maybe<Array<ChargingStationTariffRuleWhereUniqueInput>>;
  };

export type ChargingStationTariffRuleCreateOrConnectWithoutChargingStationTariffInput =
  {
    where: ChargingStationTariffRuleWhereUniqueInput;
    create: ChargingStationTariffRuleCreateWithoutChargingStationTariffInput;
  };

export type ChargingStationTariffRuleCreateWithoutChargingStationTariffInput = {
  id?: Maybe<Scalars['String']>;
  property: ChargingStationRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleGroupBy = {
  __typename?: 'ChargingStationTariffRuleGroupBy';
  id: Scalars['String'];
  chargingStationTariffId: Scalars['String'];
  property: ChargingStationRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
  _count?: Maybe<ChargingStationTariffRuleCountAggregate>;
  _avg?: Maybe<ChargingStationTariffRuleAvgAggregate>;
  _sum?: Maybe<ChargingStationTariffRuleSumAggregate>;
  _min?: Maybe<ChargingStationTariffRuleMinAggregate>;
  _max?: Maybe<ChargingStationTariffRuleMaxAggregate>;
};

export type ChargingStationTariffRuleListRelationFilter = {
  every?: Maybe<ChargingStationTariffRuleWhereInput>;
  some?: Maybe<ChargingStationTariffRuleWhereInput>;
  none?: Maybe<ChargingStationTariffRuleWhereInput>;
};

export type ChargingStationTariffRuleMaxAggregate = {
  __typename?: 'ChargingStationTariffRuleMaxAggregate';
  id?: Maybe<Scalars['String']>;
  chargingStationTariffId?: Maybe<Scalars['String']>;
  property?: Maybe<ChargingStationRuleProperty>;
  fromCompareOperator?: Maybe<RuleCompareOperator>;
  fromValue?: Maybe<Scalars['Float']>;
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  chargingStationTariffId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type ChargingStationTariffRuleMinAggregate = {
  __typename?: 'ChargingStationTariffRuleMinAggregate';
  id?: Maybe<Scalars['String']>;
  chargingStationTariffId?: Maybe<Scalars['String']>;
  property?: Maybe<ChargingStationRuleProperty>;
  fromCompareOperator?: Maybe<RuleCompareOperator>;
  fromValue?: Maybe<Scalars['Float']>;
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  chargingStationTariffId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type ChargingStationTariffRuleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationTariffRuleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  chargingStationTariffId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationTariffRuleCountOrderByAggregateInput>;
  _avg?: Maybe<ChargingStationTariffRuleAvgOrderByAggregateInput>;
  _max?: Maybe<ChargingStationTariffRuleMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationTariffRuleMinOrderByAggregateInput>;
  _sum?: Maybe<ChargingStationTariffRuleSumOrderByAggregateInput>;
};

export type ChargingStationTariffRuleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  chargingStationTariff?: Maybe<ChargingStationTariffOrderByWithRelationInput>;
  chargingStationTariffId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export enum ChargingStationTariffRuleScalarFieldEnum {
  Id = 'id',
  ChargingStationTariffId = 'chargingStationTariffId',
  Property = 'property',
  FromCompareOperator = 'fromCompareOperator',
  FromValue = 'fromValue',
  ToCompareOperator = 'toCompareOperator',
  ToValue = 'toValue',
}

export type ChargingStationTariffRuleScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationTariffRuleScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationTariffRuleScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTariffRuleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  chargingStationTariffId?: Maybe<StringFilter>;
  property?: Maybe<EnumChargingStationRulePropertyFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFilter>;
  fromValue?: Maybe<FloatFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableFilter>;
  toValue?: Maybe<FloatNullableFilter>;
};

export type ChargingStationTariffRuleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChargingStationTariffRuleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChargingStationTariffRuleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChargingStationTariffRuleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  chargingStationTariffId?: Maybe<StringWithAggregatesFilter>;
  property?: Maybe<EnumChargingStationRulePropertyWithAggregatesFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorWithAggregatesFilter>;
  fromValue?: Maybe<FloatWithAggregatesFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableWithAggregatesFilter>;
  toValue?: Maybe<FloatNullableWithAggregatesFilter>;
};

export type ChargingStationTariffRuleSumAggregate = {
  __typename?: 'ChargingStationTariffRuleSumAggregate';
  fromValue?: Maybe<Scalars['Float']>;
  toValue?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffRuleSumOrderByAggregateInput = {
  fromValue?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type ChargingStationTariffRuleUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  chargingStationTariff?: Maybe<ChargingStationTariffUpdateOneRequiredWithoutChargingStationRulesInput>;
  property?: Maybe<EnumChargingStationRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type ChargingStationTariffRuleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<EnumChargingStationRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type ChargingStationTariffRuleUpdateManyWithWhereWithoutChargingStationTariffInput =
  {
    where: ChargingStationTariffRuleScalarWhereInput;
    data: ChargingStationTariffRuleUpdateManyMutationInput;
  };

export type ChargingStationTariffRuleUpdateManyWithoutChargingStationTariffInput =
  {
    create?: Maybe<
      Array<ChargingStationTariffRuleCreateWithoutChargingStationTariffInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTariffRuleCreateOrConnectWithoutChargingStationTariffInput>
    >;
    upsert?: Maybe<
      Array<ChargingStationTariffRuleUpsertWithWhereUniqueWithoutChargingStationTariffInput>
    >;
    createMany?: Maybe<ChargingStationTariffRuleCreateManyChargingStationTariffInputEnvelope>;
    set?: Maybe<Array<ChargingStationTariffRuleWhereUniqueInput>>;
    disconnect?: Maybe<Array<ChargingStationTariffRuleWhereUniqueInput>>;
    delete?: Maybe<Array<ChargingStationTariffRuleWhereUniqueInput>>;
    connect?: Maybe<Array<ChargingStationTariffRuleWhereUniqueInput>>;
    update?: Maybe<
      Array<ChargingStationTariffRuleUpdateWithWhereUniqueWithoutChargingStationTariffInput>
    >;
    updateMany?: Maybe<
      Array<ChargingStationTariffRuleUpdateManyWithWhereWithoutChargingStationTariffInput>
    >;
    deleteMany?: Maybe<Array<ChargingStationTariffRuleScalarWhereInput>>;
  };

export type ChargingStationTariffRuleUpdateWithWhereUniqueWithoutChargingStationTariffInput =
  {
    where: ChargingStationTariffRuleWhereUniqueInput;
    data: ChargingStationTariffRuleUpdateWithoutChargingStationTariffInput;
  };

export type ChargingStationTariffRuleUpdateWithoutChargingStationTariffInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<EnumChargingStationRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type ChargingStationTariffRuleUpsertWithWhereUniqueWithoutChargingStationTariffInput =
  {
    where: ChargingStationTariffRuleWhereUniqueInput;
    update: ChargingStationTariffRuleUpdateWithoutChargingStationTariffInput;
    create: ChargingStationTariffRuleCreateWithoutChargingStationTariffInput;
  };

export type ChargingStationTariffRuleWhereInput = {
  AND?: Maybe<Array<ChargingStationTariffRuleWhereInput>>;
  OR?: Maybe<Array<ChargingStationTariffRuleWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTariffRuleWhereInput>>;
  id?: Maybe<StringFilter>;
  chargingStationTariff?: Maybe<ChargingStationTariffRelationFilter>;
  chargingStationTariffId?: Maybe<StringFilter>;
  property?: Maybe<EnumChargingStationRulePropertyFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFilter>;
  fromValue?: Maybe<FloatFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableFilter>;
  toValue?: Maybe<FloatNullableFilter>;
};

export type ChargingStationTariffRuleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ChargingStationTariffScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Price = 'price',
  DeletedAt = 'deletedAt',
  GuaranteeAmount = 'guaranteeAmount',
  AmountPerMinute = 'amountPerMinute',
  ChargingStationId = 'chargingStationId',
  TariffType = 'tariffType',
}

export type ChargingStationTariffScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationTariffScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationTariffScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTariffScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  guaranteeAmount?: Maybe<FloatNullableFilter>;
  amountPerMinute?: Maybe<FloatNullableFilter>;
  chargingStationId?: Maybe<StringFilter>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFilter>;
};

export type ChargingStationTariffScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChargingStationTariffScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChargingStationTariffScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChargingStationTariffScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  price?: Maybe<FloatWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  guaranteeAmount?: Maybe<FloatNullableWithAggregatesFilter>;
  amountPerMinute?: Maybe<FloatNullableWithAggregatesFilter>;
  chargingStationId?: Maybe<StringWithAggregatesFilter>;
  tariffType?: Maybe<EnumChargingStationTariffTypeWithAggregatesFilter>;
};

export type ChargingStationTariffSumAggregate = {
  __typename?: 'ChargingStationTariffSumAggregate';
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
};

export type ChargingStationTariffSumOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
};

export enum ChargingStationTariffType {
  QrCode = 'QR_CODE',
  Rfid = 'RFID',
  Cards = 'CARDS',
  Emv = 'EMV',
  Ecarup = 'ECARUP',
}

export type ChargingStationTariffUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  chargingStation?: Maybe<ChargingStationUpdateOneRequiredWithoutChargingStationTariffsInput>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFieldUpdateOperationsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleUpdateManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserUpdateManyWithoutChargingStationTariffsInput>;
  rfids?: Maybe<ChargingStationRfidUpdateManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFieldUpdateOperationsInput>;
};

export type ChargingStationTariffUpdateManyWithWhereWithoutChargingStationInput =
  {
    where: ChargingStationTariffScalarWhereInput;
    data: ChargingStationTariffUpdateManyMutationInput;
  };

export type ChargingStationTariffUpdateManyWithWhereWithoutRfidsInput = {
  where: ChargingStationTariffScalarWhereInput;
  data: ChargingStationTariffUpdateManyMutationInput;
};

export type ChargingStationTariffUpdateManyWithWhereWithoutUsersInput = {
  where: ChargingStationTariffScalarWhereInput;
  data: ChargingStationTariffUpdateManyMutationInput;
};

export type ChargingStationTariffUpdateManyWithoutChargingStationInput = {
  create?: Maybe<Array<ChargingStationTariffCreateWithoutChargingStationInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTariffCreateOrConnectWithoutChargingStationInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationTariffUpsertWithWhereUniqueWithoutChargingStationInput>
  >;
  createMany?: Maybe<ChargingStationTariffCreateManyChargingStationInputEnvelope>;
  set?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationTariffUpdateWithWhereUniqueWithoutChargingStationInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationTariffUpdateManyWithWhereWithoutChargingStationInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationTariffScalarWhereInput>>;
};

export type ChargingStationTariffUpdateManyWithoutRfidsInput = {
  create?: Maybe<Array<ChargingStationTariffCreateWithoutRfidsInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTariffCreateOrConnectWithoutRfidsInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationTariffUpsertWithWhereUniqueWithoutRfidsInput>
  >;
  set?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationTariffUpdateWithWhereUniqueWithoutRfidsInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationTariffUpdateManyWithWhereWithoutRfidsInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationTariffScalarWhereInput>>;
};

export type ChargingStationTariffUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<ChargingStationTariffCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTariffCreateOrConnectWithoutUsersInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationTariffUpsertWithWhereUniqueWithoutUsersInput>
  >;
  set?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationTariffWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationTariffUpdateWithWhereUniqueWithoutUsersInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationTariffUpdateManyWithWhereWithoutUsersInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationTariffScalarWhereInput>>;
};

export type ChargingStationTariffUpdateOneRequiredWithoutChargingStationRulesInput =
  {
    create?: Maybe<ChargingStationTariffCreateWithoutChargingStationRulesInput>;
    connectOrCreate?: Maybe<ChargingStationTariffCreateOrConnectWithoutChargingStationRulesInput>;
    upsert?: Maybe<ChargingStationTariffUpsertWithoutChargingStationRulesInput>;
    connect?: Maybe<ChargingStationTariffWhereUniqueInput>;
    update?: Maybe<ChargingStationTariffUpdateWithoutChargingStationRulesInput>;
  };

export type ChargingStationTariffUpdateOneWithoutChargingStationTransactionAppliedTariffsInput =
  {
    create?: Maybe<ChargingStationTariffCreateWithoutChargingStationTransactionAppliedTariffsInput>;
    connectOrCreate?: Maybe<ChargingStationTariffCreateOrConnectWithoutChargingStationTransactionAppliedTariffsInput>;
    upsert?: Maybe<ChargingStationTariffUpsertWithoutChargingStationTransactionAppliedTariffsInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<ChargingStationTariffWhereUniqueInput>;
    update?: Maybe<ChargingStationTariffUpdateWithoutChargingStationTransactionAppliedTariffsInput>;
  };

export type ChargingStationTariffUpdateWithWhereUniqueWithoutChargingStationInput =
  {
    where: ChargingStationTariffWhereUniqueInput;
    data: ChargingStationTariffUpdateWithoutChargingStationInput;
  };

export type ChargingStationTariffUpdateWithWhereUniqueWithoutRfidsInput = {
  where: ChargingStationTariffWhereUniqueInput;
  data: ChargingStationTariffUpdateWithoutRfidsInput;
};

export type ChargingStationTariffUpdateWithWhereUniqueWithoutUsersInput = {
  where: ChargingStationTariffWhereUniqueInput;
  data: ChargingStationTariffUpdateWithoutUsersInput;
};

export type ChargingStationTariffUpdateWithoutChargingStationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFieldUpdateOperationsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleUpdateManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserUpdateManyWithoutChargingStationTariffsInput>;
  rfids?: Maybe<ChargingStationRfidUpdateManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffUpdateWithoutChargingStationRulesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  chargingStation?: Maybe<ChargingStationUpdateOneRequiredWithoutChargingStationTariffsInput>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFieldUpdateOperationsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserUpdateManyWithoutChargingStationTariffsInput>;
  rfids?: Maybe<ChargingStationRfidUpdateManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffUpdateWithoutChargingStationTransactionAppliedTariffsInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    price?: Maybe<FloatFieldUpdateOperationsInput>;
    deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    chargingStation?: Maybe<ChargingStationUpdateOneRequiredWithoutChargingStationTariffsInput>;
    tariffType?: Maybe<EnumChargingStationTariffTypeFieldUpdateOperationsInput>;
    chargingStationRules?: Maybe<ChargingStationTariffRuleUpdateManyWithoutChargingStationTariffInput>;
    users?: Maybe<UserUpdateManyWithoutChargingStationTariffsInput>;
    rfids?: Maybe<ChargingStationRfidUpdateManyWithoutChargingStationTariffsInput>;
  };

export type ChargingStationTariffUpdateWithoutRfidsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  chargingStation?: Maybe<ChargingStationUpdateOneRequiredWithoutChargingStationTariffsInput>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFieldUpdateOperationsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleUpdateManyWithoutChargingStationTariffInput>;
  users?: Maybe<UserUpdateManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffUpdateWithoutUsersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  chargingStation?: Maybe<ChargingStationUpdateOneRequiredWithoutChargingStationTariffsInput>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFieldUpdateOperationsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTariffInput>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleUpdateManyWithoutChargingStationTariffInput>;
  rfids?: Maybe<ChargingStationRfidUpdateManyWithoutChargingStationTariffsInput>;
};

export type ChargingStationTariffUpsertWithWhereUniqueWithoutChargingStationInput =
  {
    where: ChargingStationTariffWhereUniqueInput;
    update: ChargingStationTariffUpdateWithoutChargingStationInput;
    create: ChargingStationTariffCreateWithoutChargingStationInput;
  };

export type ChargingStationTariffUpsertWithWhereUniqueWithoutRfidsInput = {
  where: ChargingStationTariffWhereUniqueInput;
  update: ChargingStationTariffUpdateWithoutRfidsInput;
  create: ChargingStationTariffCreateWithoutRfidsInput;
};

export type ChargingStationTariffUpsertWithWhereUniqueWithoutUsersInput = {
  where: ChargingStationTariffWhereUniqueInput;
  update: ChargingStationTariffUpdateWithoutUsersInput;
  create: ChargingStationTariffCreateWithoutUsersInput;
};

export type ChargingStationTariffUpsertWithoutChargingStationRulesInput = {
  update: ChargingStationTariffUpdateWithoutChargingStationRulesInput;
  create: ChargingStationTariffCreateWithoutChargingStationRulesInput;
};

export type ChargingStationTariffUpsertWithoutChargingStationTransactionAppliedTariffsInput =
  {
    update: ChargingStationTariffUpdateWithoutChargingStationTransactionAppliedTariffsInput;
    create: ChargingStationTariffCreateWithoutChargingStationTransactionAppliedTariffsInput;
  };

export type ChargingStationTariffWhereInput = {
  AND?: Maybe<Array<ChargingStationTariffWhereInput>>;
  OR?: Maybe<Array<ChargingStationTariffWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTariffWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  guaranteeAmount?: Maybe<FloatNullableFilter>;
  amountPerMinute?: Maybe<FloatNullableFilter>;
  chargingStation?: Maybe<ChargingStationRelationFilter>;
  chargingStationId?: Maybe<StringFilter>;
  tariffType?: Maybe<EnumChargingStationTariffTypeFilter>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffListRelationFilter>;
  chargingStationRules?: Maybe<ChargingStationTariffRuleListRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
  rfids?: Maybe<ChargingStationRfidListRelationFilter>;
};

export type ChargingStationTariffWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChargingStationTransaction = {
  __typename?: 'ChargingStationTransaction';
  id: Scalars['String'];
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt: Scalars['DateTime'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
  chargingStationId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationTransactionCount>;
  user?: Maybe<User>;
  chargingStationRfid?: Maybe<ChargingStationRfid>;
  chargingStation?: Maybe<ChargingStation>;
  chargingStationTransactionAppliedTariffs: Array<ChargingStationTransactionAppliedTariff>;
  chargingStationTransactionUsages: Array<ChargingStationTransactionUsage>;
  calculation?: Maybe<TransactionCalculationTariff>;
};

export type ChargingStationTransactionChargingStationTransactionAppliedTariffsArgs =
  {
    where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
    orderBy?: Maybe<
      Array<ChargingStationTransactionAppliedTariffOrderByWithRelationInput>
    >;
    cursor?: Maybe<ChargingStationTransactionAppliedTariffWhereUniqueInput>;
    take?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    distinct?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarFieldEnum>
    >;
  };

export type ChargingStationTransactionChargingStationTransactionUsagesArgs = {
  where?: Maybe<ChargingStationTransactionUsageWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionUsageOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionUsageScalarFieldEnum>>;
};

export type ChargingStationTransactionAppliedTariff = {
  __typename?: 'ChargingStationTransactionAppliedTariff';
  id: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
  chargingStationTariffId?: Maybe<Scalars['String']>;
  chargingStationTransactionId?: Maybe<Scalars['String']>;
  chargingStationTariff?: Maybe<ChargingStationTariff>;
  chargingStationTransaction?: Maybe<ChargingStationTransaction>;
};

export type ChargingStationTransactionAppliedTariffAvgAggregate = {
  __typename?: 'ChargingStationTransactionAppliedTariffAvgAggregate';
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
};

export type ChargingStationTransactionAppliedTariffAvgOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  percentage?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  usedkWh?: Maybe<SortOrder>;
};

export type ChargingStationTransactionAppliedTariffCountAggregate = {
  __typename?: 'ChargingStationTransactionAppliedTariffCountAggregate';
  id: Scalars['Int'];
  price: Scalars['Int'];
  guaranteeAmount: Scalars['Int'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
  percentage: Scalars['Int'];
  amountPerMinute: Scalars['Int'];
  usedkWh: Scalars['Int'];
  chargingStationTariffId: Scalars['Int'];
  chargingStationTransactionId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationTransactionAppliedTariffCountOrderByAggregateInput =
  {
    id?: Maybe<SortOrder>;
    price?: Maybe<SortOrder>;
    guaranteeAmount?: Maybe<SortOrder>;
    startDate?: Maybe<SortOrder>;
    endDate?: Maybe<SortOrder>;
    percentage?: Maybe<SortOrder>;
    amountPerMinute?: Maybe<SortOrder>;
    usedkWh?: Maybe<SortOrder>;
    chargingStationTariffId?: Maybe<SortOrder>;
    chargingStationTransactionId?: Maybe<SortOrder>;
  };

export type ChargingStationTransactionAppliedTariffCreateInput = {
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
  chargingStationTariff?: Maybe<ChargingStationTariffCreateNestedOneWithoutChargingStationTransactionAppliedTariffsInput>;
  chargingStationTransaction?: Maybe<ChargingStationTransactionCreateNestedOneWithoutChargingStationTransactionAppliedTariffsInput>;
};

export type ChargingStationTransactionAppliedTariffCreateManyChargingStationTariffInput =
  {
    id?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    guaranteeAmount?: Maybe<Scalars['Float']>;
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    percentage?: Maybe<Scalars['Float']>;
    amountPerMinute?: Maybe<Scalars['Float']>;
    usedkWh?: Maybe<Scalars['Float']>;
    chargingStationTransactionId?: Maybe<Scalars['String']>;
  };

export type ChargingStationTransactionAppliedTariffCreateManyChargingStationTariffInputEnvelope =
  {
    data: Array<ChargingStationTransactionAppliedTariffCreateManyChargingStationTariffInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type ChargingStationTransactionAppliedTariffCreateManyChargingStationTransactionInput =
  {
    id?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    guaranteeAmount?: Maybe<Scalars['Float']>;
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    percentage?: Maybe<Scalars['Float']>;
    amountPerMinute?: Maybe<Scalars['Float']>;
    usedkWh?: Maybe<Scalars['Float']>;
    chargingStationTariffId?: Maybe<Scalars['String']>;
  };

export type ChargingStationTransactionAppliedTariffCreateManyChargingStationTransactionInputEnvelope =
  {
    data: Array<ChargingStationTransactionAppliedTariffCreateManyChargingStationTransactionInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type ChargingStationTransactionAppliedTariffCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
  chargingStationTariffId?: Maybe<Scalars['String']>;
  chargingStationTransactionId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTariffInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTariffInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateOrConnectWithoutChargingStationTariffInput>
    >;
    createMany?: Maybe<ChargingStationTransactionAppliedTariffCreateManyChargingStationTariffInputEnvelope>;
    connect?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
  };

export type ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTransactionInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTransactionInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateOrConnectWithoutChargingStationTransactionInput>
    >;
    createMany?: Maybe<ChargingStationTransactionAppliedTariffCreateManyChargingStationTransactionInputEnvelope>;
    connect?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
  };

export type ChargingStationTransactionAppliedTariffCreateOrConnectWithoutChargingStationTariffInput =
  {
    where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
    create: ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTariffInput;
  };

export type ChargingStationTransactionAppliedTariffCreateOrConnectWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
    create: ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTransactionInput;
  };

export type ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTariffInput =
  {
    id?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    guaranteeAmount?: Maybe<Scalars['Float']>;
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    percentage?: Maybe<Scalars['Float']>;
    amountPerMinute?: Maybe<Scalars['Float']>;
    usedkWh?: Maybe<Scalars['Float']>;
    chargingStationTransaction?: Maybe<ChargingStationTransactionCreateNestedOneWithoutChargingStationTransactionAppliedTariffsInput>;
  };

export type ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTransactionInput =
  {
    id?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    guaranteeAmount?: Maybe<Scalars['Float']>;
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    percentage?: Maybe<Scalars['Float']>;
    amountPerMinute?: Maybe<Scalars['Float']>;
    usedkWh?: Maybe<Scalars['Float']>;
    chargingStationTariff?: Maybe<ChargingStationTariffCreateNestedOneWithoutChargingStationTransactionAppliedTariffsInput>;
  };

export type ChargingStationTransactionAppliedTariffGroupBy = {
  __typename?: 'ChargingStationTransactionAppliedTariffGroupBy';
  id: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
  chargingStationTariffId?: Maybe<Scalars['String']>;
  chargingStationTransactionId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationTransactionAppliedTariffCountAggregate>;
  _avg?: Maybe<ChargingStationTransactionAppliedTariffAvgAggregate>;
  _sum?: Maybe<ChargingStationTransactionAppliedTariffSumAggregate>;
  _min?: Maybe<ChargingStationTransactionAppliedTariffMinAggregate>;
  _max?: Maybe<ChargingStationTransactionAppliedTariffMaxAggregate>;
};

export type ChargingStationTransactionAppliedTariffListRelationFilter = {
  every?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
  some?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
  none?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
};

export type ChargingStationTransactionAppliedTariffMaxAggregate = {
  __typename?: 'ChargingStationTransactionAppliedTariffMaxAggregate';
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
  chargingStationTariffId?: Maybe<Scalars['String']>;
  chargingStationTransactionId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionAppliedTariffMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  percentage?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  usedkWh?: Maybe<SortOrder>;
  chargingStationTariffId?: Maybe<SortOrder>;
  chargingStationTransactionId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionAppliedTariffMinAggregate = {
  __typename?: 'ChargingStationTransactionAppliedTariffMinAggregate';
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
  chargingStationTariffId?: Maybe<Scalars['String']>;
  chargingStationTransactionId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionAppliedTariffMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  percentage?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  usedkWh?: Maybe<SortOrder>;
  chargingStationTariffId?: Maybe<SortOrder>;
  chargingStationTransactionId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionAppliedTariffOrderByRelationAggregateInput =
  {
    _count?: Maybe<SortOrder>;
  };

export type ChargingStationTransactionAppliedTariffOrderByWithAggregationInput =
  {
    id?: Maybe<SortOrder>;
    price?: Maybe<SortOrder>;
    guaranteeAmount?: Maybe<SortOrder>;
    startDate?: Maybe<SortOrder>;
    endDate?: Maybe<SortOrder>;
    percentage?: Maybe<SortOrder>;
    amountPerMinute?: Maybe<SortOrder>;
    usedkWh?: Maybe<SortOrder>;
    chargingStationTariffId?: Maybe<SortOrder>;
    chargingStationTransactionId?: Maybe<SortOrder>;
    _count?: Maybe<ChargingStationTransactionAppliedTariffCountOrderByAggregateInput>;
    _avg?: Maybe<ChargingStationTransactionAppliedTariffAvgOrderByAggregateInput>;
    _max?: Maybe<ChargingStationTransactionAppliedTariffMaxOrderByAggregateInput>;
    _min?: Maybe<ChargingStationTransactionAppliedTariffMinOrderByAggregateInput>;
    _sum?: Maybe<ChargingStationTransactionAppliedTariffSumOrderByAggregateInput>;
  };

export type ChargingStationTransactionAppliedTariffOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  percentage?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  usedkWh?: Maybe<SortOrder>;
  chargingStationTariff?: Maybe<ChargingStationTariffOrderByWithRelationInput>;
  chargingStationTariffId?: Maybe<SortOrder>;
  chargingStationTransaction?: Maybe<ChargingStationTransactionOrderByWithRelationInput>;
  chargingStationTransactionId?: Maybe<SortOrder>;
};

export enum ChargingStationTransactionAppliedTariffScalarFieldEnum {
  Id = 'id',
  Price = 'price',
  GuaranteeAmount = 'guaranteeAmount',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Percentage = 'percentage',
  AmountPerMinute = 'amountPerMinute',
  UsedkWh = 'usedkWh',
  ChargingStationTariffId = 'chargingStationTariffId',
  ChargingStationTransactionId = 'chargingStationTransactionId',
}

export type ChargingStationTransactionAppliedTariffScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionAppliedTariffScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionAppliedTariffScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionAppliedTariffScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  price?: Maybe<FloatNullableFilter>;
  guaranteeAmount?: Maybe<FloatNullableFilter>;
  startDate?: Maybe<DateTimeNullableFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  percentage?: Maybe<FloatNullableFilter>;
  amountPerMinute?: Maybe<FloatNullableFilter>;
  usedkWh?: Maybe<FloatNullableFilter>;
  chargingStationTariffId?: Maybe<StringNullableFilter>;
  chargingStationTransactionId?: Maybe<StringNullableFilter>;
};

export type ChargingStationTransactionAppliedTariffScalarWhereWithAggregatesInput =
  {
    AND?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarWhereWithAggregatesInput>
    >;
    OR?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarWhereWithAggregatesInput>
    >;
    NOT?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarWhereWithAggregatesInput>
    >;
    id?: Maybe<StringWithAggregatesFilter>;
    price?: Maybe<FloatNullableWithAggregatesFilter>;
    guaranteeAmount?: Maybe<FloatNullableWithAggregatesFilter>;
    startDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
    endDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
    percentage?: Maybe<FloatNullableWithAggregatesFilter>;
    amountPerMinute?: Maybe<FloatNullableWithAggregatesFilter>;
    usedkWh?: Maybe<FloatNullableWithAggregatesFilter>;
    chargingStationTariffId?: Maybe<StringNullableWithAggregatesFilter>;
    chargingStationTransactionId?: Maybe<StringNullableWithAggregatesFilter>;
  };

export type ChargingStationTransactionAppliedTariffSumAggregate = {
  __typename?: 'ChargingStationTransactionAppliedTariffSumAggregate';
  price?: Maybe<Scalars['Float']>;
  guaranteeAmount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  amountPerMinute?: Maybe<Scalars['Float']>;
  usedkWh?: Maybe<Scalars['Float']>;
};

export type ChargingStationTransactionAppliedTariffSumOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  guaranteeAmount?: Maybe<SortOrder>;
  percentage?: Maybe<SortOrder>;
  amountPerMinute?: Maybe<SortOrder>;
  usedkWh?: Maybe<SortOrder>;
};

export type ChargingStationTransactionAppliedTariffUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  percentage?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  usedkWh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  chargingStationTariff?: Maybe<ChargingStationTariffUpdateOneWithoutChargingStationTransactionAppliedTariffsInput>;
  chargingStationTransaction?: Maybe<ChargingStationTransactionUpdateOneWithoutChargingStationTransactionAppliedTariffsInput>;
};

export type ChargingStationTransactionAppliedTariffUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  percentage?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  usedkWh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type ChargingStationTransactionAppliedTariffUpdateManyWithWhereWithoutChargingStationTariffInput =
  {
    where: ChargingStationTransactionAppliedTariffScalarWhereInput;
    data: ChargingStationTransactionAppliedTariffUpdateManyMutationInput;
  };

export type ChargingStationTransactionAppliedTariffUpdateManyWithWhereWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionAppliedTariffScalarWhereInput;
    data: ChargingStationTransactionAppliedTariffUpdateManyMutationInput;
  };

export type ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTariffInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTariffInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateOrConnectWithoutChargingStationTariffInput>
    >;
    upsert?: Maybe<
      Array<ChargingStationTransactionAppliedTariffUpsertWithWhereUniqueWithoutChargingStationTariffInput>
    >;
    createMany?: Maybe<ChargingStationTransactionAppliedTariffCreateManyChargingStationTariffInputEnvelope>;
    set?: Maybe<Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>>;
    disconnect?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
    delete?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
    connect?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
    update?: Maybe<
      Array<ChargingStationTransactionAppliedTariffUpdateWithWhereUniqueWithoutChargingStationTariffInput>
    >;
    updateMany?: Maybe<
      Array<ChargingStationTransactionAppliedTariffUpdateManyWithWhereWithoutChargingStationTariffInput>
    >;
    deleteMany?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarWhereInput>
    >;
  };

export type ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTransactionInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTransactionInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionAppliedTariffCreateOrConnectWithoutChargingStationTransactionInput>
    >;
    upsert?: Maybe<
      Array<ChargingStationTransactionAppliedTariffUpsertWithWhereUniqueWithoutChargingStationTransactionInput>
    >;
    createMany?: Maybe<ChargingStationTransactionAppliedTariffCreateManyChargingStationTransactionInputEnvelope>;
    set?: Maybe<Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>>;
    disconnect?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
    delete?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
    connect?: Maybe<
      Array<ChargingStationTransactionAppliedTariffWhereUniqueInput>
    >;
    update?: Maybe<
      Array<ChargingStationTransactionAppliedTariffUpdateWithWhereUniqueWithoutChargingStationTransactionInput>
    >;
    updateMany?: Maybe<
      Array<ChargingStationTransactionAppliedTariffUpdateManyWithWhereWithoutChargingStationTransactionInput>
    >;
    deleteMany?: Maybe<
      Array<ChargingStationTransactionAppliedTariffScalarWhereInput>
    >;
  };

export type ChargingStationTransactionAppliedTariffUpdateWithWhereUniqueWithoutChargingStationTariffInput =
  {
    where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
    data: ChargingStationTransactionAppliedTariffUpdateWithoutChargingStationTariffInput;
  };

export type ChargingStationTransactionAppliedTariffUpdateWithWhereUniqueWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
    data: ChargingStationTransactionAppliedTariffUpdateWithoutChargingStationTransactionInput;
  };

export type ChargingStationTransactionAppliedTariffUpdateWithoutChargingStationTariffInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    price?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    percentage?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    usedkWh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    chargingStationTransaction?: Maybe<ChargingStationTransactionUpdateOneWithoutChargingStationTransactionAppliedTariffsInput>;
  };

export type ChargingStationTransactionAppliedTariffUpdateWithoutChargingStationTransactionInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    price?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    guaranteeAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    percentage?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    amountPerMinute?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    usedkWh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    chargingStationTariff?: Maybe<ChargingStationTariffUpdateOneWithoutChargingStationTransactionAppliedTariffsInput>;
  };

export type ChargingStationTransactionAppliedTariffUpsertWithWhereUniqueWithoutChargingStationTariffInput =
  {
    where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
    update: ChargingStationTransactionAppliedTariffUpdateWithoutChargingStationTariffInput;
    create: ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTariffInput;
  };

export type ChargingStationTransactionAppliedTariffUpsertWithWhereUniqueWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
    update: ChargingStationTransactionAppliedTariffUpdateWithoutChargingStationTransactionInput;
    create: ChargingStationTransactionAppliedTariffCreateWithoutChargingStationTransactionInput;
  };

export type ChargingStationTransactionAppliedTariffWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionAppliedTariffWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionAppliedTariffWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionAppliedTariffWhereInput>>;
  id?: Maybe<StringFilter>;
  price?: Maybe<FloatNullableFilter>;
  guaranteeAmount?: Maybe<FloatNullableFilter>;
  startDate?: Maybe<DateTimeNullableFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  percentage?: Maybe<FloatNullableFilter>;
  amountPerMinute?: Maybe<FloatNullableFilter>;
  usedkWh?: Maybe<FloatNullableFilter>;
  chargingStationTariff?: Maybe<ChargingStationTariffRelationFilter>;
  chargingStationTariffId?: Maybe<StringNullableFilter>;
  chargingStationTransaction?: Maybe<ChargingStationTransactionRelationFilter>;
  chargingStationTransactionId?: Maybe<StringNullableFilter>;
};

export type ChargingStationTransactionAppliedTariffWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ChargingStationTransactionAuthType {
  Rfid = 'RFID',
  Qr = 'QR',
  Emv = 'EMV',
  Ecarup = 'ECARUP',
}

export type ChargingStationTransactionAvgAggregate = {
  __typename?: 'ChargingStationTransactionAvgAggregate';
  vat?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
};

export type ChargingStationTransactionAvgOrderByAggregateInput = {
  vat?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
};

export type ChargingStationTransactionCount = {
  __typename?: 'ChargingStationTransactionCount';
  chargingStationTransactionAppliedTariffs: Scalars['Int'];
  chargingStationTransactionUsages: Scalars['Int'];
};

export type ChargingStationTransactionCountAggregate = {
  __typename?: 'ChargingStationTransactionCountAggregate';
  id: Scalars['Int'];
  vat: Scalars['Int'];
  transactionId: Scalars['Int'];
  auth: Scalars['Int'];
  installationId: Scalars['Int'];
  commission: Scalars['Int'];
  kwh: Scalars['Int'];
  lastStatus: Scalars['Int'];
  createdAt: Scalars['Int'];
  startDate: Scalars['Int'];
  finishAt: Scalars['Int'];
  endDate: Scalars['Int'];
  paymentHash: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  stripeInvoiceConfirmedAt: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripePaymentIntentConfirmedAt: Scalars['Int'];
  userId: Scalars['Int'];
  chargingStationRfidId: Scalars['Int'];
  chargingStationId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationTransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  auth?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  lastStatus?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  finishAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  paymentHash?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeInvoiceConfirmedAt?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePaymentIntentConfirmedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStationRfid?: Maybe<ChargingStationRfidCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStation?: Maybe<ChargingStationCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageCreateNestedManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionCreateManyChargingStationInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionCreateManyChargingStationInputEnvelope = {
  data: Array<ChargingStationTransactionCreateManyChargingStationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChargingStationTransactionCreateManyChargingStationRfidInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  chargingStationId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionCreateManyChargingStationRfidInputEnvelope =
  {
    data: Array<ChargingStationTransactionCreateManyChargingStationRfidInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type ChargingStationTransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
  chargingStationId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
  chargingStationId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionCreateManyUserInputEnvelope = {
  data: Array<ChargingStationTransactionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChargingStationTransactionCreateNestedManyWithoutChargingStationInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionCreateWithoutChargingStationInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionCreateOrConnectWithoutChargingStationInput>
    >;
    createMany?: Maybe<ChargingStationTransactionCreateManyChargingStationInputEnvelope>;
    connect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  };

export type ChargingStationTransactionCreateNestedManyWithoutChargingStationRfidInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionCreateWithoutChargingStationRfidInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionCreateOrConnectWithoutChargingStationRfidInput>
    >;
    createMany?: Maybe<ChargingStationTransactionCreateManyChargingStationRfidInputEnvelope>;
    connect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  };

export type ChargingStationTransactionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ChargingStationTransactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTransactionCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<ChargingStationTransactionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
};

export type ChargingStationTransactionCreateNestedOneWithoutChargingStationTransactionAppliedTariffsInput =
  {
    create?: Maybe<ChargingStationTransactionCreateWithoutChargingStationTransactionAppliedTariffsInput>;
    connectOrCreate?: Maybe<ChargingStationTransactionCreateOrConnectWithoutChargingStationTransactionAppliedTariffsInput>;
    connect?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  };

export type ChargingStationTransactionCreateNestedOneWithoutChargingStationTransactionUsagesInput =
  {
    create?: Maybe<ChargingStationTransactionCreateWithoutChargingStationTransactionUsagesInput>;
    connectOrCreate?: Maybe<ChargingStationTransactionCreateOrConnectWithoutChargingStationTransactionUsagesInput>;
    connect?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  };

export type ChargingStationTransactionCreateOrConnectWithoutChargingStationInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    create: ChargingStationTransactionCreateWithoutChargingStationInput;
  };

export type ChargingStationTransactionCreateOrConnectWithoutChargingStationRfidInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    create: ChargingStationTransactionCreateWithoutChargingStationRfidInput;
  };

export type ChargingStationTransactionCreateOrConnectWithoutChargingStationTransactionAppliedTariffsInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    create: ChargingStationTransactionCreateWithoutChargingStationTransactionAppliedTariffsInput;
  };

export type ChargingStationTransactionCreateOrConnectWithoutChargingStationTransactionUsagesInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    create: ChargingStationTransactionCreateWithoutChargingStationTransactionUsagesInput;
  };

export type ChargingStationTransactionCreateOrConnectWithoutUserInput = {
  where: ChargingStationTransactionWhereUniqueInput;
  create: ChargingStationTransactionCreateWithoutUserInput;
};

export type ChargingStationTransactionCreateWithoutChargingStationInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStationRfid?: Maybe<ChargingStationRfidCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageCreateNestedManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionCreateWithoutChargingStationRfidInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStation?: Maybe<ChargingStationCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageCreateNestedManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionCreateWithoutChargingStationTransactionAppliedTariffsInput =
  {
    id?: Maybe<Scalars['String']>;
    vat?: Maybe<Scalars['Float']>;
    transactionId?: Maybe<Scalars['String']>;
    auth?: Maybe<ChargingStationTransactionAuthType>;
    installationId: Scalars['String'];
    commission?: Maybe<Scalars['Float']>;
    kwh?: Maybe<Scalars['Float']>;
    lastStatus?: Maybe<ChargingStationTransactionStatus>;
    createdAt?: Maybe<Scalars['DateTime']>;
    startDate?: Maybe<Scalars['DateTime']>;
    finishAt?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    paymentHash?: Maybe<Scalars['String']>;
    stripeInvoiceId?: Maybe<Scalars['String']>;
    stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
    stripePaymentIntentId?: Maybe<Scalars['String']>;
    stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
    user?: Maybe<UserCreateNestedOneWithoutChargingStationTransactionsInput>;
    chargingStationRfid?: Maybe<ChargingStationRfidCreateNestedOneWithoutChargingStationTransactionsInput>;
    chargingStation?: Maybe<ChargingStationCreateNestedOneWithoutChargingStationTransactionsInput>;
    chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageCreateNestedManyWithoutChargingStationTransactionInput>;
  };

export type ChargingStationTransactionCreateWithoutChargingStationTransactionUsagesInput =
  {
    id?: Maybe<Scalars['String']>;
    vat?: Maybe<Scalars['Float']>;
    transactionId?: Maybe<Scalars['String']>;
    auth?: Maybe<ChargingStationTransactionAuthType>;
    installationId: Scalars['String'];
    commission?: Maybe<Scalars['Float']>;
    kwh?: Maybe<Scalars['Float']>;
    lastStatus?: Maybe<ChargingStationTransactionStatus>;
    createdAt?: Maybe<Scalars['DateTime']>;
    startDate?: Maybe<Scalars['DateTime']>;
    finishAt?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    paymentHash?: Maybe<Scalars['String']>;
    stripeInvoiceId?: Maybe<Scalars['String']>;
    stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
    stripePaymentIntentId?: Maybe<Scalars['String']>;
    stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
    user?: Maybe<UserCreateNestedOneWithoutChargingStationTransactionsInput>;
    chargingStationRfid?: Maybe<ChargingStationRfidCreateNestedOneWithoutChargingStationTransactionsInput>;
    chargingStation?: Maybe<ChargingStationCreateNestedOneWithoutChargingStationTransactionsInput>;
    chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTransactionInput>;
  };

export type ChargingStationTransactionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  chargingStationRfid?: Maybe<ChargingStationRfidCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStation?: Maybe<ChargingStationCreateNestedOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffCreateNestedManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageCreateNestedManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionGroupBy = {
  __typename?: 'ChargingStationTransactionGroupBy';
  id: Scalars['String'];
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt: Scalars['DateTime'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
  chargingStationId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationTransactionCountAggregate>;
  _avg?: Maybe<ChargingStationTransactionAvgAggregate>;
  _sum?: Maybe<ChargingStationTransactionSumAggregate>;
  _min?: Maybe<ChargingStationTransactionMinAggregate>;
  _max?: Maybe<ChargingStationTransactionMaxAggregate>;
};

export type ChargingStationTransactionListRelationFilter = {
  every?: Maybe<ChargingStationTransactionWhereInput>;
  some?: Maybe<ChargingStationTransactionWhereInput>;
  none?: Maybe<ChargingStationTransactionWhereInput>;
};

export type ChargingStationTransactionMaxAggregate = {
  __typename?: 'ChargingStationTransactionMaxAggregate';
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
  chargingStationId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  auth?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  lastStatus?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  finishAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  paymentHash?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeInvoiceConfirmedAt?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePaymentIntentConfirmedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionMinAggregate = {
  __typename?: 'ChargingStationTransactionMinAggregate';
  id?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  auth?: Maybe<ChargingStationTransactionAuthType>;
  installationId?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
  lastStatus?: Maybe<ChargingStationTransactionStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  paymentHash?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentConfirmedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  chargingStationRfidId?: Maybe<Scalars['String']>;
  chargingStationId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  auth?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  lastStatus?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  finishAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  paymentHash?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeInvoiceConfirmedAt?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePaymentIntentConfirmedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationTransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  auth?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  lastStatus?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  finishAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  paymentHash?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeInvoiceConfirmedAt?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePaymentIntentConfirmedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  chargingStationRfidId?: Maybe<SortOrder>;
  chargingStationId?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationTransactionCountOrderByAggregateInput>;
  _avg?: Maybe<ChargingStationTransactionAvgOrderByAggregateInput>;
  _max?: Maybe<ChargingStationTransactionMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationTransactionMinOrderByAggregateInput>;
  _sum?: Maybe<ChargingStationTransactionSumOrderByAggregateInput>;
};

export type ChargingStationTransactionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  auth?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  lastStatus?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  finishAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  paymentHash?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeInvoiceConfirmedAt?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePaymentIntentConfirmedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  chargingStationRfid?: Maybe<ChargingStationRfidOrderByWithRelationInput>;
  chargingStationRfidId?: Maybe<SortOrder>;
  chargingStation?: Maybe<ChargingStationOrderByWithRelationInput>;
  chargingStationId?: Maybe<SortOrder>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffOrderByRelationAggregateInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageOrderByRelationAggregateInput>;
};

export type ChargingStationTransactionRelationFilter = {
  is?: Maybe<ChargingStationTransactionWhereInput>;
  isNot?: Maybe<ChargingStationTransactionWhereInput>;
};

export type ChargingStationTransactionReport = {
  __typename?: 'ChargingStationTransactionReport';
  id: Scalars['String'];
  reportHash: Scalars['String'];
  createdAt: Scalars['DateTime'];
  billingAccountId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  billingAccount?: Maybe<BillingAccount>;
  company?: Maybe<Company>;
};

export type ChargingStationTransactionReportCountAggregate = {
  __typename?: 'ChargingStationTransactionReportCountAggregate';
  id: Scalars['Int'];
  reportHash: Scalars['Int'];
  createdAt: Scalars['Int'];
  billingAccountId: Scalars['Int'];
  companyId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationTransactionReportCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  reportHash?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionReportCreateInput = {
  id?: Maybe<Scalars['String']>;
  reportHash: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  billingAccount?: Maybe<BillingAccountCreateNestedOneWithoutChargingStationTransactionReportsInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutChargingStationTransactionReportsInput>;
};

export type ChargingStationTransactionReportCreateManyBillingAccountInput = {
  id?: Maybe<Scalars['String']>;
  reportHash: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionReportCreateManyBillingAccountInputEnvelope =
  {
    data: Array<ChargingStationTransactionReportCreateManyBillingAccountInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type ChargingStationTransactionReportCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  reportHash: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  billingAccountId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionReportCreateManyCompanyInputEnvelope = {
  data: Array<ChargingStationTransactionReportCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChargingStationTransactionReportCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  reportHash: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  billingAccountId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionReportCreateNestedManyWithoutBillingAccountInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionReportCreateWithoutBillingAccountInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionReportCreateOrConnectWithoutBillingAccountInput>
    >;
    createMany?: Maybe<ChargingStationTransactionReportCreateManyBillingAccountInputEnvelope>;
    connect?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
  };

export type ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionReportCreateWithoutCompanyInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionReportCreateOrConnectWithoutCompanyInput>
    >;
    createMany?: Maybe<ChargingStationTransactionReportCreateManyCompanyInputEnvelope>;
    connect?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
  };

export type ChargingStationTransactionReportCreateOrConnectWithoutBillingAccountInput =
  {
    where: ChargingStationTransactionReportWhereUniqueInput;
    create: ChargingStationTransactionReportCreateWithoutBillingAccountInput;
  };

export type ChargingStationTransactionReportCreateOrConnectWithoutCompanyInput =
  {
    where: ChargingStationTransactionReportWhereUniqueInput;
    create: ChargingStationTransactionReportCreateWithoutCompanyInput;
  };

export type ChargingStationTransactionReportCreateWithoutBillingAccountInput = {
  id?: Maybe<Scalars['String']>;
  reportHash: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<CompanyCreateNestedOneWithoutChargingStationTransactionReportsInput>;
};

export type ChargingStationTransactionReportCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  reportHash: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  billingAccount?: Maybe<BillingAccountCreateNestedOneWithoutChargingStationTransactionReportsInput>;
};

export type ChargingStationTransactionReportGroupBy = {
  __typename?: 'ChargingStationTransactionReportGroupBy';
  id: Scalars['String'];
  reportHash: Scalars['String'];
  createdAt: Scalars['DateTime'];
  billingAccountId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationTransactionReportCountAggregate>;
  _min?: Maybe<ChargingStationTransactionReportMinAggregate>;
  _max?: Maybe<ChargingStationTransactionReportMaxAggregate>;
};

export type ChargingStationTransactionReportListRelationFilter = {
  every?: Maybe<ChargingStationTransactionReportWhereInput>;
  some?: Maybe<ChargingStationTransactionReportWhereInput>;
  none?: Maybe<ChargingStationTransactionReportWhereInput>;
};

export type ChargingStationTransactionReportMaxAggregate = {
  __typename?: 'ChargingStationTransactionReportMaxAggregate';
  id?: Maybe<Scalars['String']>;
  reportHash?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  billingAccountId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionReportMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  reportHash?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionReportMinAggregate = {
  __typename?: 'ChargingStationTransactionReportMinAggregate';
  id?: Maybe<Scalars['String']>;
  reportHash?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  billingAccountId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionReportMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  reportHash?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionReportOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationTransactionReportOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  reportHash?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  billingAccountId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationTransactionReportCountOrderByAggregateInput>;
  _max?: Maybe<ChargingStationTransactionReportMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationTransactionReportMinOrderByAggregateInput>;
};

export type ChargingStationTransactionReportOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  reportHash?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  billingAccount?: Maybe<BillingAccountOrderByWithRelationInput>;
  billingAccountId?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
};

export enum ChargingStationTransactionReportScalarFieldEnum {
  Id = 'id',
  ReportHash = 'reportHash',
  CreatedAt = 'createdAt',
  BillingAccountId = 'billingAccountId',
  CompanyId = 'companyId',
}

export type ChargingStationTransactionReportScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionReportScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionReportScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionReportScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  reportHash?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  billingAccountId?: Maybe<StringNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type ChargingStationTransactionReportScalarWhereWithAggregatesInput = {
  AND?: Maybe<
    Array<ChargingStationTransactionReportScalarWhereWithAggregatesInput>
  >;
  OR?: Maybe<
    Array<ChargingStationTransactionReportScalarWhereWithAggregatesInput>
  >;
  NOT?: Maybe<
    Array<ChargingStationTransactionReportScalarWhereWithAggregatesInput>
  >;
  id?: Maybe<StringWithAggregatesFilter>;
  reportHash?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  billingAccountId?: Maybe<StringNullableWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChargingStationTransactionReportUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportHash?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutChargingStationTransactionReportsInput>;
  company?: Maybe<CompanyUpdateOneWithoutChargingStationTransactionReportsInput>;
};

export type ChargingStationTransactionReportUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportHash?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChargingStationTransactionReportUpdateManyWithWhereWithoutBillingAccountInput =
  {
    where: ChargingStationTransactionReportScalarWhereInput;
    data: ChargingStationTransactionReportUpdateManyMutationInput;
  };

export type ChargingStationTransactionReportUpdateManyWithWhereWithoutCompanyInput =
  {
    where: ChargingStationTransactionReportScalarWhereInput;
    data: ChargingStationTransactionReportUpdateManyMutationInput;
  };

export type ChargingStationTransactionReportUpdateManyWithoutBillingAccountInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionReportCreateWithoutBillingAccountInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionReportCreateOrConnectWithoutBillingAccountInput>
    >;
    upsert?: Maybe<
      Array<ChargingStationTransactionReportUpsertWithWhereUniqueWithoutBillingAccountInput>
    >;
    createMany?: Maybe<ChargingStationTransactionReportCreateManyBillingAccountInputEnvelope>;
    set?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
    disconnect?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
    delete?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
    connect?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
    update?: Maybe<
      Array<ChargingStationTransactionReportUpdateWithWhereUniqueWithoutBillingAccountInput>
    >;
    updateMany?: Maybe<
      Array<ChargingStationTransactionReportUpdateManyWithWhereWithoutBillingAccountInput>
    >;
    deleteMany?: Maybe<Array<ChargingStationTransactionReportScalarWhereInput>>;
  };

export type ChargingStationTransactionReportUpdateManyWithoutCompanyInput = {
  create?: Maybe<
    Array<ChargingStationTransactionReportCreateWithoutCompanyInput>
  >;
  connectOrCreate?: Maybe<
    Array<ChargingStationTransactionReportCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationTransactionReportUpsertWithWhereUniqueWithoutCompanyInput>
  >;
  createMany?: Maybe<ChargingStationTransactionReportCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationTransactionReportWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationTransactionReportUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationTransactionReportUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationTransactionReportScalarWhereInput>>;
};

export type ChargingStationTransactionReportUpdateWithWhereUniqueWithoutBillingAccountInput =
  {
    where: ChargingStationTransactionReportWhereUniqueInput;
    data: ChargingStationTransactionReportUpdateWithoutBillingAccountInput;
  };

export type ChargingStationTransactionReportUpdateWithWhereUniqueWithoutCompanyInput =
  {
    where: ChargingStationTransactionReportWhereUniqueInput;
    data: ChargingStationTransactionReportUpdateWithoutCompanyInput;
  };

export type ChargingStationTransactionReportUpdateWithoutBillingAccountInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportHash?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutChargingStationTransactionReportsInput>;
};

export type ChargingStationTransactionReportUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportHash?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  billingAccount?: Maybe<BillingAccountUpdateOneWithoutChargingStationTransactionReportsInput>;
};

export type ChargingStationTransactionReportUpsertWithWhereUniqueWithoutBillingAccountInput =
  {
    where: ChargingStationTransactionReportWhereUniqueInput;
    update: ChargingStationTransactionReportUpdateWithoutBillingAccountInput;
    create: ChargingStationTransactionReportCreateWithoutBillingAccountInput;
  };

export type ChargingStationTransactionReportUpsertWithWhereUniqueWithoutCompanyInput =
  {
    where: ChargingStationTransactionReportWhereUniqueInput;
    update: ChargingStationTransactionReportUpdateWithoutCompanyInput;
    create: ChargingStationTransactionReportCreateWithoutCompanyInput;
  };

export type ChargingStationTransactionReportWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionReportWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionReportWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionReportWhereInput>>;
  id?: Maybe<StringFilter>;
  reportHash?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  billingAccount?: Maybe<BillingAccountRelationFilter>;
  billingAccountId?: Maybe<StringNullableFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type ChargingStationTransactionReportWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ChargingStationTransactionScalarFieldEnum {
  Id = 'id',
  Vat = 'vat',
  TransactionId = 'transactionId',
  Auth = 'auth',
  InstallationId = 'installationId',
  Commission = 'commission',
  Kwh = 'kwh',
  LastStatus = 'lastStatus',
  CreatedAt = 'createdAt',
  StartDate = 'startDate',
  FinishAt = 'finishAt',
  EndDate = 'endDate',
  PaymentHash = 'paymentHash',
  StripeInvoiceId = 'stripeInvoiceId',
  StripeInvoiceConfirmedAt = 'stripeInvoiceConfirmedAt',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePaymentIntentConfirmedAt = 'stripePaymentIntentConfirmedAt',
  UserId = 'userId',
  ChargingStationRfidId = 'chargingStationRfidId',
  ChargingStationId = 'chargingStationId',
}

export type ChargingStationTransactionScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  vat?: Maybe<FloatNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  auth?: Maybe<EnumChargingStationTransactionAuthTypeNullableFilter>;
  installationId?: Maybe<StringFilter>;
  commission?: Maybe<FloatNullableFilter>;
  kwh?: Maybe<FloatNullableFilter>;
  lastStatus?: Maybe<EnumChargingStationTransactionStatusNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeNullableFilter>;
  finishAt?: Maybe<DateTimeNullableFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  paymentHash?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripeInvoiceConfirmedAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePaymentIntentConfirmedAt?: Maybe<DateTimeNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  chargingStationRfidId?: Maybe<StringNullableFilter>;
  chargingStationId?: Maybe<StringNullableFilter>;
};

export type ChargingStationTransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChargingStationTransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChargingStationTransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  vat?: Maybe<FloatNullableWithAggregatesFilter>;
  transactionId?: Maybe<StringNullableWithAggregatesFilter>;
  auth?: Maybe<EnumChargingStationTransactionAuthTypeNullableWithAggregatesFilter>;
  installationId?: Maybe<StringWithAggregatesFilter>;
  commission?: Maybe<FloatNullableWithAggregatesFilter>;
  kwh?: Maybe<FloatNullableWithAggregatesFilter>;
  lastStatus?: Maybe<EnumChargingStationTransactionStatusNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  startDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  finishAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  endDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  paymentHash?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentIntentConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  chargingStationRfidId?: Maybe<StringNullableWithAggregatesFilter>;
  chargingStationId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum ChargingStationTransactionStatus {
  ChargeStarted = 'CHARGE_STARTED',
  ChargeTerminated = 'CHARGE_TERMINATED',
}

export type ChargingStationTransactionSumAggregate = {
  __typename?: 'ChargingStationTransactionSumAggregate';
  vat?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  kwh?: Maybe<Scalars['Float']>;
};

export type ChargingStationTransactionSumOrderByAggregateInput = {
  vat?: Maybe<SortOrder>;
  commission?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
};

export type ChargingStationTransactionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  transactionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth?: Maybe<NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput>;
  installationId?: Maybe<StringFieldUpdateOperationsInput>;
  commission?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  kwh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lastStatus?: Maybe<NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  finishAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentIntentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStationRfid?: Maybe<ChargingStationRfidUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStation?: Maybe<ChargingStationUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageUpdateManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  transactionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth?: Maybe<NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput>;
  installationId?: Maybe<StringFieldUpdateOperationsInput>;
  commission?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  kwh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lastStatus?: Maybe<NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  finishAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentIntentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ChargingStationTransactionUpdateManyWithWhereWithoutChargingStationInput =
  {
    where: ChargingStationTransactionScalarWhereInput;
    data: ChargingStationTransactionUpdateManyMutationInput;
  };

export type ChargingStationTransactionUpdateManyWithWhereWithoutChargingStationRfidInput =
  {
    where: ChargingStationTransactionScalarWhereInput;
    data: ChargingStationTransactionUpdateManyMutationInput;
  };

export type ChargingStationTransactionUpdateManyWithWhereWithoutUserInput = {
  where: ChargingStationTransactionScalarWhereInput;
  data: ChargingStationTransactionUpdateManyMutationInput;
};

export type ChargingStationTransactionUpdateManyWithoutChargingStationInput = {
  create?: Maybe<
    Array<ChargingStationTransactionCreateWithoutChargingStationInput>
  >;
  connectOrCreate?: Maybe<
    Array<ChargingStationTransactionCreateOrConnectWithoutChargingStationInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationTransactionUpsertWithWhereUniqueWithoutChargingStationInput>
  >;
  createMany?: Maybe<ChargingStationTransactionCreateManyChargingStationInputEnvelope>;
  set?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationTransactionUpdateWithWhereUniqueWithoutChargingStationInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationTransactionUpdateManyWithWhereWithoutChargingStationInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationTransactionScalarWhereInput>>;
};

export type ChargingStationTransactionUpdateManyWithoutChargingStationRfidInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionCreateWithoutChargingStationRfidInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionCreateOrConnectWithoutChargingStationRfidInput>
    >;
    upsert?: Maybe<
      Array<ChargingStationTransactionUpsertWithWhereUniqueWithoutChargingStationRfidInput>
    >;
    createMany?: Maybe<ChargingStationTransactionCreateManyChargingStationRfidInputEnvelope>;
    set?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
    disconnect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
    delete?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
    connect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
    update?: Maybe<
      Array<ChargingStationTransactionUpdateWithWhereUniqueWithoutChargingStationRfidInput>
    >;
    updateMany?: Maybe<
      Array<ChargingStationTransactionUpdateManyWithWhereWithoutChargingStationRfidInput>
    >;
    deleteMany?: Maybe<Array<ChargingStationTransactionScalarWhereInput>>;
  };

export type ChargingStationTransactionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<ChargingStationTransactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<ChargingStationTransactionCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationTransactionUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<ChargingStationTransactionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationTransactionUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationTransactionUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationTransactionScalarWhereInput>>;
};

export type ChargingStationTransactionUpdateOneWithoutChargingStationTransactionAppliedTariffsInput =
  {
    create?: Maybe<ChargingStationTransactionCreateWithoutChargingStationTransactionAppliedTariffsInput>;
    connectOrCreate?: Maybe<ChargingStationTransactionCreateOrConnectWithoutChargingStationTransactionAppliedTariffsInput>;
    upsert?: Maybe<ChargingStationTransactionUpsertWithoutChargingStationTransactionAppliedTariffsInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<ChargingStationTransactionWhereUniqueInput>;
    update?: Maybe<ChargingStationTransactionUpdateWithoutChargingStationTransactionAppliedTariffsInput>;
  };

export type ChargingStationTransactionUpdateOneWithoutChargingStationTransactionUsagesInput =
  {
    create?: Maybe<ChargingStationTransactionCreateWithoutChargingStationTransactionUsagesInput>;
    connectOrCreate?: Maybe<ChargingStationTransactionCreateOrConnectWithoutChargingStationTransactionUsagesInput>;
    upsert?: Maybe<ChargingStationTransactionUpsertWithoutChargingStationTransactionUsagesInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<ChargingStationTransactionWhereUniqueInput>;
    update?: Maybe<ChargingStationTransactionUpdateWithoutChargingStationTransactionUsagesInput>;
  };

export type ChargingStationTransactionUpdateWithWhereUniqueWithoutChargingStationInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    data: ChargingStationTransactionUpdateWithoutChargingStationInput;
  };

export type ChargingStationTransactionUpdateWithWhereUniqueWithoutChargingStationRfidInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    data: ChargingStationTransactionUpdateWithoutChargingStationRfidInput;
  };

export type ChargingStationTransactionUpdateWithWhereUniqueWithoutUserInput = {
  where: ChargingStationTransactionWhereUniqueInput;
  data: ChargingStationTransactionUpdateWithoutUserInput;
};

export type ChargingStationTransactionUpdateWithoutChargingStationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  transactionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth?: Maybe<NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput>;
  installationId?: Maybe<StringFieldUpdateOperationsInput>;
  commission?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  kwh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lastStatus?: Maybe<NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  finishAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentIntentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStationRfid?: Maybe<ChargingStationRfidUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageUpdateManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionUpdateWithoutChargingStationRfidInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  transactionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth?: Maybe<NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput>;
  installationId?: Maybe<StringFieldUpdateOperationsInput>;
  commission?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  kwh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lastStatus?: Maybe<NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  finishAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentIntentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStation?: Maybe<ChargingStationUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageUpdateManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionUpdateWithoutChargingStationTransactionAppliedTariffsInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    transactionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    auth?: Maybe<NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput>;
    installationId?: Maybe<StringFieldUpdateOperationsInput>;
    commission?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    kwh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    lastStatus?: Maybe<NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    finishAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    paymentHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripeInvoiceId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripeInvoiceConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    stripePaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripePaymentIntentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    user?: Maybe<UserUpdateOneWithoutChargingStationTransactionsInput>;
    chargingStationRfid?: Maybe<ChargingStationRfidUpdateOneWithoutChargingStationTransactionsInput>;
    chargingStation?: Maybe<ChargingStationUpdateOneWithoutChargingStationTransactionsInput>;
    chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageUpdateManyWithoutChargingStationTransactionInput>;
  };

export type ChargingStationTransactionUpdateWithoutChargingStationTransactionUsagesInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    transactionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    auth?: Maybe<NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput>;
    installationId?: Maybe<StringFieldUpdateOperationsInput>;
    commission?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    kwh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
    lastStatus?: Maybe<NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    finishAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    paymentHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripeInvoiceId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripeInvoiceConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    stripePaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
    stripePaymentIntentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    user?: Maybe<UserUpdateOneWithoutChargingStationTransactionsInput>;
    chargingStationRfid?: Maybe<ChargingStationRfidUpdateOneWithoutChargingStationTransactionsInput>;
    chargingStation?: Maybe<ChargingStationUpdateOneWithoutChargingStationTransactionsInput>;
    chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTransactionInput>;
  };

export type ChargingStationTransactionUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  transactionId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth?: Maybe<NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput>;
  installationId?: Maybe<StringFieldUpdateOperationsInput>;
  commission?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  kwh?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lastStatus?: Maybe<NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  finishAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentIntentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentIntentConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  chargingStationRfid?: Maybe<ChargingStationRfidUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStation?: Maybe<ChargingStationUpdateOneWithoutChargingStationTransactionsInput>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffUpdateManyWithoutChargingStationTransactionInput>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageUpdateManyWithoutChargingStationTransactionInput>;
};

export type ChargingStationTransactionUpsertWithWhereUniqueWithoutChargingStationInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    update: ChargingStationTransactionUpdateWithoutChargingStationInput;
    create: ChargingStationTransactionCreateWithoutChargingStationInput;
  };

export type ChargingStationTransactionUpsertWithWhereUniqueWithoutChargingStationRfidInput =
  {
    where: ChargingStationTransactionWhereUniqueInput;
    update: ChargingStationTransactionUpdateWithoutChargingStationRfidInput;
    create: ChargingStationTransactionCreateWithoutChargingStationRfidInput;
  };

export type ChargingStationTransactionUpsertWithWhereUniqueWithoutUserInput = {
  where: ChargingStationTransactionWhereUniqueInput;
  update: ChargingStationTransactionUpdateWithoutUserInput;
  create: ChargingStationTransactionCreateWithoutUserInput;
};

export type ChargingStationTransactionUpsertWithoutChargingStationTransactionAppliedTariffsInput =
  {
    update: ChargingStationTransactionUpdateWithoutChargingStationTransactionAppliedTariffsInput;
    create: ChargingStationTransactionCreateWithoutChargingStationTransactionAppliedTariffsInput;
  };

export type ChargingStationTransactionUpsertWithoutChargingStationTransactionUsagesInput =
  {
    update: ChargingStationTransactionUpdateWithoutChargingStationTransactionUsagesInput;
    create: ChargingStationTransactionCreateWithoutChargingStationTransactionUsagesInput;
  };

export type ChargingStationTransactionUsage = {
  __typename?: 'ChargingStationTransactionUsage';
  id: Scalars['String'];
  kwh: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  chargingStationTransactionId?: Maybe<Scalars['String']>;
  chargingStationTransaction?: Maybe<ChargingStationTransaction>;
};

export type ChargingStationTransactionUsageAvgAggregate = {
  __typename?: 'ChargingStationTransactionUsageAvgAggregate';
  kwh?: Maybe<Scalars['Float']>;
};

export type ChargingStationTransactionUsageAvgOrderByAggregateInput = {
  kwh?: Maybe<SortOrder>;
};

export type ChargingStationTransactionUsageCountAggregate = {
  __typename?: 'ChargingStationTransactionUsageCountAggregate';
  id: Scalars['Int'];
  kwh: Scalars['Int'];
  createdAt: Scalars['Int'];
  chargingStationTransactionId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChargingStationTransactionUsageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  chargingStationTransactionId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionUsageCreateInput = {
  id?: Maybe<Scalars['String']>;
  kwh: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  chargingStationTransaction?: Maybe<ChargingStationTransactionCreateNestedOneWithoutChargingStationTransactionUsagesInput>;
};

export type ChargingStationTransactionUsageCreateManyChargingStationTransactionInput =
  {
    id?: Maybe<Scalars['String']>;
    kwh: Scalars['Float'];
    createdAt: Scalars['DateTime'];
  };

export type ChargingStationTransactionUsageCreateManyChargingStationTransactionInputEnvelope =
  {
    data: Array<ChargingStationTransactionUsageCreateManyChargingStationTransactionInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type ChargingStationTransactionUsageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  kwh: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  chargingStationTransactionId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionUsageCreateNestedManyWithoutChargingStationTransactionInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionUsageCreateWithoutChargingStationTransactionInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionUsageCreateOrConnectWithoutChargingStationTransactionInput>
    >;
    createMany?: Maybe<ChargingStationTransactionUsageCreateManyChargingStationTransactionInputEnvelope>;
    connect?: Maybe<Array<ChargingStationTransactionUsageWhereUniqueInput>>;
  };

export type ChargingStationTransactionUsageCreateOrConnectWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionUsageWhereUniqueInput;
    create: ChargingStationTransactionUsageCreateWithoutChargingStationTransactionInput;
  };

export type ChargingStationTransactionUsageCreateWithoutChargingStationTransactionInput =
  {
    id?: Maybe<Scalars['String']>;
    kwh: Scalars['Float'];
    createdAt: Scalars['DateTime'];
  };

export type ChargingStationTransactionUsageGroupBy = {
  __typename?: 'ChargingStationTransactionUsageGroupBy';
  id: Scalars['String'];
  kwh: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  chargingStationTransactionId?: Maybe<Scalars['String']>;
  _count?: Maybe<ChargingStationTransactionUsageCountAggregate>;
  _avg?: Maybe<ChargingStationTransactionUsageAvgAggregate>;
  _sum?: Maybe<ChargingStationTransactionUsageSumAggregate>;
  _min?: Maybe<ChargingStationTransactionUsageMinAggregate>;
  _max?: Maybe<ChargingStationTransactionUsageMaxAggregate>;
};

export type ChargingStationTransactionUsageListRelationFilter = {
  every?: Maybe<ChargingStationTransactionUsageWhereInput>;
  some?: Maybe<ChargingStationTransactionUsageWhereInput>;
  none?: Maybe<ChargingStationTransactionUsageWhereInput>;
};

export type ChargingStationTransactionUsageMaxAggregate = {
  __typename?: 'ChargingStationTransactionUsageMaxAggregate';
  id?: Maybe<Scalars['String']>;
  kwh?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  chargingStationTransactionId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionUsageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  chargingStationTransactionId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionUsageMinAggregate = {
  __typename?: 'ChargingStationTransactionUsageMinAggregate';
  id?: Maybe<Scalars['String']>;
  kwh?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  chargingStationTransactionId?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionUsageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  chargingStationTransactionId?: Maybe<SortOrder>;
};

export type ChargingStationTransactionUsageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChargingStationTransactionUsageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  chargingStationTransactionId?: Maybe<SortOrder>;
  _count?: Maybe<ChargingStationTransactionUsageCountOrderByAggregateInput>;
  _avg?: Maybe<ChargingStationTransactionUsageAvgOrderByAggregateInput>;
  _max?: Maybe<ChargingStationTransactionUsageMaxOrderByAggregateInput>;
  _min?: Maybe<ChargingStationTransactionUsageMinOrderByAggregateInput>;
  _sum?: Maybe<ChargingStationTransactionUsageSumOrderByAggregateInput>;
};

export type ChargingStationTransactionUsageOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  kwh?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  chargingStationTransaction?: Maybe<ChargingStationTransactionOrderByWithRelationInput>;
  chargingStationTransactionId?: Maybe<SortOrder>;
};

export enum ChargingStationTransactionUsageScalarFieldEnum {
  Id = 'id',
  Kwh = 'kwh',
  CreatedAt = 'createdAt',
  ChargingStationTransactionId = 'chargingStationTransactionId',
}

export type ChargingStationTransactionUsageScalarWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionUsageScalarWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionUsageScalarWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionUsageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  kwh?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  chargingStationTransactionId?: Maybe<StringNullableFilter>;
};

export type ChargingStationTransactionUsageScalarWhereWithAggregatesInput = {
  AND?: Maybe<
    Array<ChargingStationTransactionUsageScalarWhereWithAggregatesInput>
  >;
  OR?: Maybe<
    Array<ChargingStationTransactionUsageScalarWhereWithAggregatesInput>
  >;
  NOT?: Maybe<
    Array<ChargingStationTransactionUsageScalarWhereWithAggregatesInput>
  >;
  id?: Maybe<StringWithAggregatesFilter>;
  kwh?: Maybe<FloatWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  chargingStationTransactionId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChargingStationTransactionUsageSumAggregate = {
  __typename?: 'ChargingStationTransactionUsageSumAggregate';
  kwh?: Maybe<Scalars['Float']>;
};

export type ChargingStationTransactionUsageSumOrderByAggregateInput = {
  kwh?: Maybe<SortOrder>;
};

export type ChargingStationTransactionUsageUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kwh?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  chargingStationTransaction?: Maybe<ChargingStationTransactionUpdateOneWithoutChargingStationTransactionUsagesInput>;
};

export type ChargingStationTransactionUsageUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kwh?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ChargingStationTransactionUsageUpdateManyWithWhereWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionUsageScalarWhereInput;
    data: ChargingStationTransactionUsageUpdateManyMutationInput;
  };

export type ChargingStationTransactionUsageUpdateManyWithoutChargingStationTransactionInput =
  {
    create?: Maybe<
      Array<ChargingStationTransactionUsageCreateWithoutChargingStationTransactionInput>
    >;
    connectOrCreate?: Maybe<
      Array<ChargingStationTransactionUsageCreateOrConnectWithoutChargingStationTransactionInput>
    >;
    upsert?: Maybe<
      Array<ChargingStationTransactionUsageUpsertWithWhereUniqueWithoutChargingStationTransactionInput>
    >;
    createMany?: Maybe<ChargingStationTransactionUsageCreateManyChargingStationTransactionInputEnvelope>;
    set?: Maybe<Array<ChargingStationTransactionUsageWhereUniqueInput>>;
    disconnect?: Maybe<Array<ChargingStationTransactionUsageWhereUniqueInput>>;
    delete?: Maybe<Array<ChargingStationTransactionUsageWhereUniqueInput>>;
    connect?: Maybe<Array<ChargingStationTransactionUsageWhereUniqueInput>>;
    update?: Maybe<
      Array<ChargingStationTransactionUsageUpdateWithWhereUniqueWithoutChargingStationTransactionInput>
    >;
    updateMany?: Maybe<
      Array<ChargingStationTransactionUsageUpdateManyWithWhereWithoutChargingStationTransactionInput>
    >;
    deleteMany?: Maybe<Array<ChargingStationTransactionUsageScalarWhereInput>>;
  };

export type ChargingStationTransactionUsageUpdateWithWhereUniqueWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionUsageWhereUniqueInput;
    data: ChargingStationTransactionUsageUpdateWithoutChargingStationTransactionInput;
  };

export type ChargingStationTransactionUsageUpdateWithoutChargingStationTransactionInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    kwh?: Maybe<FloatFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  };

export type ChargingStationTransactionUsageUpsertWithWhereUniqueWithoutChargingStationTransactionInput =
  {
    where: ChargingStationTransactionUsageWhereUniqueInput;
    update: ChargingStationTransactionUsageUpdateWithoutChargingStationTransactionInput;
    create: ChargingStationTransactionUsageCreateWithoutChargingStationTransactionInput;
  };

export type ChargingStationTransactionUsageWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionUsageWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionUsageWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionUsageWhereInput>>;
  id?: Maybe<StringFilter>;
  kwh?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  chargingStationTransaction?: Maybe<ChargingStationTransactionRelationFilter>;
  chargingStationTransactionId?: Maybe<StringNullableFilter>;
};

export type ChargingStationTransactionUsageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChargingStationTransactionWhereInput = {
  AND?: Maybe<Array<ChargingStationTransactionWhereInput>>;
  OR?: Maybe<Array<ChargingStationTransactionWhereInput>>;
  NOT?: Maybe<Array<ChargingStationTransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  vat?: Maybe<FloatNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  auth?: Maybe<EnumChargingStationTransactionAuthTypeNullableFilter>;
  installationId?: Maybe<StringFilter>;
  commission?: Maybe<FloatNullableFilter>;
  kwh?: Maybe<FloatNullableFilter>;
  lastStatus?: Maybe<EnumChargingStationTransactionStatusNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  startDate?: Maybe<DateTimeNullableFilter>;
  finishAt?: Maybe<DateTimeNullableFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  paymentHash?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripeInvoiceConfirmedAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePaymentIntentConfirmedAt?: Maybe<DateTimeNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  chargingStationRfid?: Maybe<ChargingStationRfidRelationFilter>;
  chargingStationRfidId?: Maybe<StringNullableFilter>;
  chargingStation?: Maybe<ChargingStationRelationFilter>;
  chargingStationId?: Maybe<StringNullableFilter>;
  chargingStationTransactionAppliedTariffs?: Maybe<ChargingStationTransactionAppliedTariffListRelationFilter>;
  chargingStationTransactionUsages?: Maybe<ChargingStationTransactionUsageListRelationFilter>;
};

export type ChargingStationTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChargingStationUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  evesId?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutChargingStationInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutChargingStationInput>;
  chargingStationCluster?: Maybe<ChargingStationClusterUpdateOneWithoutChargingStationsInput>;
};

export type ChargingStationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  evesId?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ChargingStationUpdateManyWithWhereWithoutChargingStationClusterInput =
  {
    where: ChargingStationScalarWhereInput;
    data: ChargingStationUpdateManyMutationInput;
  };

export type ChargingStationUpdateManyWithoutChargingStationClusterInput = {
  create?: Maybe<
    Array<ChargingStationCreateWithoutChargingStationClusterInput>
  >;
  connectOrCreate?: Maybe<
    Array<ChargingStationCreateOrConnectWithoutChargingStationClusterInput>
  >;
  upsert?: Maybe<
    Array<ChargingStationUpsertWithWhereUniqueWithoutChargingStationClusterInput>
  >;
  createMany?: Maybe<ChargingStationCreateManyChargingStationClusterInputEnvelope>;
  set?: Maybe<Array<ChargingStationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChargingStationWhereUniqueInput>>;
  delete?: Maybe<Array<ChargingStationWhereUniqueInput>>;
  connect?: Maybe<Array<ChargingStationWhereUniqueInput>>;
  update?: Maybe<
    Array<ChargingStationUpdateWithWhereUniqueWithoutChargingStationClusterInput>
  >;
  updateMany?: Maybe<
    Array<ChargingStationUpdateManyWithWhereWithoutChargingStationClusterInput>
  >;
  deleteMany?: Maybe<Array<ChargingStationScalarWhereInput>>;
};

export type ChargingStationUpdateOneRequiredWithoutChargingStationTariffsInput =
  {
    create?: Maybe<ChargingStationCreateWithoutChargingStationTariffsInput>;
    connectOrCreate?: Maybe<ChargingStationCreateOrConnectWithoutChargingStationTariffsInput>;
    upsert?: Maybe<ChargingStationUpsertWithoutChargingStationTariffsInput>;
    connect?: Maybe<ChargingStationWhereUniqueInput>;
    update?: Maybe<ChargingStationUpdateWithoutChargingStationTariffsInput>;
  };

export type ChargingStationUpdateOneWithoutChargingStationTransactionsInput = {
  create?: Maybe<ChargingStationCreateWithoutChargingStationTransactionsInput>;
  connectOrCreate?: Maybe<ChargingStationCreateOrConnectWithoutChargingStationTransactionsInput>;
  upsert?: Maybe<ChargingStationUpsertWithoutChargingStationTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ChargingStationWhereUniqueInput>;
  update?: Maybe<ChargingStationUpdateWithoutChargingStationTransactionsInput>;
};

export type ChargingStationUpdateWithWhereUniqueWithoutChargingStationClusterInput =
  {
    where: ChargingStationWhereUniqueInput;
    data: ChargingStationUpdateWithoutChargingStationClusterInput;
  };

export type ChargingStationUpdateWithoutChargingStationClusterInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  evesId?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutChargingStationInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutChargingStationInput>;
};

export type ChargingStationUpdateWithoutChargingStationTariffsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  evesId?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutChargingStationInput>;
  chargingStationCluster?: Maybe<ChargingStationClusterUpdateOneWithoutChargingStationsInput>;
};

export type ChargingStationUpdateWithoutChargingStationTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  evesId?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutChargingStationInput>;
  chargingStationCluster?: Maybe<ChargingStationClusterUpdateOneWithoutChargingStationsInput>;
};

export type ChargingStationUpsertWithWhereUniqueWithoutChargingStationClusterInput =
  {
    where: ChargingStationWhereUniqueInput;
    update: ChargingStationUpdateWithoutChargingStationClusterInput;
    create: ChargingStationCreateWithoutChargingStationClusterInput;
  };

export type ChargingStationUpsertWithoutChargingStationTariffsInput = {
  update: ChargingStationUpdateWithoutChargingStationTariffsInput;
  create: ChargingStationCreateWithoutChargingStationTariffsInput;
};

export type ChargingStationUpsertWithoutChargingStationTransactionsInput = {
  update: ChargingStationUpdateWithoutChargingStationTransactionsInput;
  create: ChargingStationCreateWithoutChargingStationTransactionsInput;
};

export type ChargingStationWhereInput = {
  AND?: Maybe<Array<ChargingStationWhereInput>>;
  OR?: Maybe<Array<ChargingStationWhereInput>>;
  NOT?: Maybe<Array<ChargingStationWhereInput>>;
  id?: Maybe<StringFilter>;
  evesId?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  operatorCompanyName?: Maybe<StringNullableFilter>;
  operatorCompanyPerson?: Maybe<StringNullableFilter>;
  operatorCompanyPhone?: Maybe<StringNullableFilter>;
  operatorCompanyEmail?: Maybe<StringNullableFilter>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionListRelationFilter>;
  chargingStationTariffs?: Maybe<ChargingStationTariffListRelationFilter>;
  chargingStationCluster?: Maybe<ChargingStationClusterRelationFilter>;
  chargingStationClusterId?: Maybe<StringNullableFilter>;
};

export type ChargingStationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChildSeatType = {
  __typename?: 'ChildSeatType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ChildSeatTypeCount>;
  vehicle: Array<Vehicle>;
};

export type ChildSeatTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type ChildSeatTypeCount = {
  __typename?: 'ChildSeatTypeCount';
  vehicle: Scalars['Int'];
};

export type ChildSeatTypeCountAggregate = {
  __typename?: 'ChildSeatTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ChildSeatTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ChildSeatTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutChildSeatTypesInput>;
};

export type ChildSeatTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ChildSeatTypeCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<ChildSeatTypeCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<ChildSeatTypeCreateOrConnectWithoutVehicleInput>
  >;
  connect?: Maybe<Array<ChildSeatTypeWhereUniqueInput>>;
};

export type ChildSeatTypeCreateOrConnectWithoutVehicleInput = {
  where: ChildSeatTypeWhereUniqueInput;
  create: ChildSeatTypeCreateWithoutVehicleInput;
};

export type ChildSeatTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ChildSeatTypeGroupBy = {
  __typename?: 'ChildSeatTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ChildSeatTypeCountAggregate>;
  _min?: Maybe<ChildSeatTypeMinAggregate>;
  _max?: Maybe<ChildSeatTypeMaxAggregate>;
};

export type ChildSeatTypeListRelationFilter = {
  every?: Maybe<ChildSeatTypeWhereInput>;
  some?: Maybe<ChildSeatTypeWhereInput>;
  none?: Maybe<ChildSeatTypeWhereInput>;
};

export type ChildSeatTypeMaxAggregate = {
  __typename?: 'ChildSeatTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ChildSeatTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ChildSeatTypeMinAggregate = {
  __typename?: 'ChildSeatTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ChildSeatTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ChildSeatTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ChildSeatTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<ChildSeatTypeCountOrderByAggregateInput>;
  _max?: Maybe<ChildSeatTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ChildSeatTypeMinOrderByAggregateInput>;
};

export type ChildSeatTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export enum ChildSeatTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type ChildSeatTypeScalarWhereInput = {
  AND?: Maybe<Array<ChildSeatTypeScalarWhereInput>>;
  OR?: Maybe<Array<ChildSeatTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ChildSeatTypeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
};

export type ChildSeatTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChildSeatTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChildSeatTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChildSeatTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChildSeatTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutChildSeatTypesInput>;
};

export type ChildSeatTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ChildSeatTypeUpdateManyWithWhereWithoutVehicleInput = {
  where: ChildSeatTypeScalarWhereInput;
  data: ChildSeatTypeUpdateManyMutationInput;
};

export type ChildSeatTypeUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<ChildSeatTypeCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<ChildSeatTypeCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<Array<ChildSeatTypeUpsertWithWhereUniqueWithoutVehicleInput>>;
  set?: Maybe<Array<ChildSeatTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChildSeatTypeWhereUniqueInput>>;
  delete?: Maybe<Array<ChildSeatTypeWhereUniqueInput>>;
  connect?: Maybe<Array<ChildSeatTypeWhereUniqueInput>>;
  update?: Maybe<Array<ChildSeatTypeUpdateWithWhereUniqueWithoutVehicleInput>>;
  updateMany?: Maybe<
    Array<ChildSeatTypeUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<ChildSeatTypeScalarWhereInput>>;
};

export type ChildSeatTypeUpdateWithWhereUniqueWithoutVehicleInput = {
  where: ChildSeatTypeWhereUniqueInput;
  data: ChildSeatTypeUpdateWithoutVehicleInput;
};

export type ChildSeatTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ChildSeatTypeUpsertWithWhereUniqueWithoutVehicleInput = {
  where: ChildSeatTypeWhereUniqueInput;
  update: ChildSeatTypeUpdateWithoutVehicleInput;
  create: ChildSeatTypeCreateWithoutVehicleInput;
};

export type ChildSeatTypeWhereInput = {
  AND?: Maybe<Array<ChildSeatTypeWhereInput>>;
  OR?: Maybe<Array<ChildSeatTypeWhereInput>>;
  NOT?: Maybe<Array<ChildSeatTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type ChildSeatTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ColorType = {
  __typename?: 'ColorType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ColorTypeCount>;
  vehicle: Array<Vehicle>;
};

export type ColorTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type ColorTypeCount = {
  __typename?: 'ColorTypeCount';
  vehicle: Scalars['Int'];
};

export type ColorTypeCountAggregate = {
  __typename?: 'ColorTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ColorTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ColorTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutColorTypeInput>;
};

export type ColorTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ColorTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<ColorTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ColorTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<ColorTypeWhereUniqueInput>;
};

export type ColorTypeCreateOrConnectWithoutVehicleInput = {
  where: ColorTypeWhereUniqueInput;
  create: ColorTypeCreateWithoutVehicleInput;
};

export type ColorTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ColorTypeGroupBy = {
  __typename?: 'ColorTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ColorTypeCountAggregate>;
  _min?: Maybe<ColorTypeMinAggregate>;
  _max?: Maybe<ColorTypeMaxAggregate>;
};

export type ColorTypeMaxAggregate = {
  __typename?: 'ColorTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ColorTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ColorTypeMinAggregate = {
  __typename?: 'ColorTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ColorTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ColorTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<ColorTypeCountOrderByAggregateInput>;
  _max?: Maybe<ColorTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ColorTypeMinOrderByAggregateInput>;
};

export type ColorTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type ColorTypeRelationFilter = {
  is?: Maybe<ColorTypeWhereInput>;
  isNot?: Maybe<ColorTypeWhereInput>;
};

export enum ColorTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type ColorTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ColorTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ColorTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ColorTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ColorTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutColorTypeInput>;
};

export type ColorTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ColorTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<ColorTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ColorTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<ColorTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ColorTypeWhereUniqueInput>;
  update?: Maybe<ColorTypeUpdateWithoutVehicleInput>;
};

export type ColorTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ColorTypeUpsertWithoutVehicleInput = {
  update: ColorTypeUpdateWithoutVehicleInput;
  create: ColorTypeCreateWithoutVehicleInput;
};

export type ColorTypeWhereInput = {
  AND?: Maybe<Array<ColorTypeWhereInput>>;
  OR?: Maybe<Array<ColorTypeWhereInput>>;
  NOT?: Maybe<Array<ColorTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type ColorTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['String'];
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters: Scalars['Float'];
  commissionBookings: Scalars['Float'];
  commissionChargingStations: Scalars['Float'];
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  defaultSharedVehicleGroupId?: Maybe<Scalars['String']>;
  _count?: Maybe<CompanyCount>;
  user: Array<User>;
  vehicle: Array<Vehicle>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  sharedVehicleGroupRelCompany: Array<SharedVehicleGroupRelCompany>;
  billingAccounts: Array<BillingAccount>;
  chargingStationClusters: Array<ChargingStationCluster>;
  chargingStationTransactionReports: Array<ChargingStationTransactionReport>;
  documents: Array<CompanyDocument>;
  gateControllers: Array<GateController>;
  invitedEmails: Array<UserInvitedEmail>;
};

export type CompanyUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type CompanyVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type CompanySharedVehicleGroupRelCompanyArgs = {
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelCompanyOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelCompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelCompanyScalarFieldEnum>>;
};

export type CompanyBillingAccountsArgs = {
  where?: Maybe<BillingAccountWhereInput>;
  orderBy?: Maybe<Array<BillingAccountOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BillingAccountScalarFieldEnum>>;
};

export type CompanyChargingStationClustersArgs = {
  where?: Maybe<ChargingStationClusterWhereInput>;
  orderBy?: Maybe<Array<ChargingStationClusterOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationClusterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationClusterScalarFieldEnum>>;
};

export type CompanyChargingStationTransactionReportsArgs = {
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionReportOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionReportScalarFieldEnum>>;
};

export type CompanyDocumentsArgs = {
  where?: Maybe<CompanyDocumentWhereInput>;
  orderBy?: Maybe<Array<CompanyDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CompanyDocumentScalarFieldEnum>>;
};

export type CompanyGateControllersArgs = {
  where?: Maybe<GateControllerWhereInput>;
  orderBy?: Maybe<Array<GateControllerOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerScalarFieldEnum>>;
};

export type CompanyInvitedEmailsArgs = {
  where?: Maybe<UserInvitedEmailWhereInput>;
  orderBy?: Maybe<Array<UserInvitedEmailOrderByWithRelationInput>>;
  cursor?: Maybe<UserInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserInvitedEmailScalarFieldEnum>>;
};

export type CompanyAvgAggregate = {
  __typename?: 'CompanyAvgAggregate';
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
};

export type CompanyAvgOrderByAggregateInput = {
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
};

export type CompanyCount = {
  __typename?: 'CompanyCount';
  user: Scalars['Int'];
  vehicle: Scalars['Int'];
  sharedVehicleGroupRelCompany: Scalars['Int'];
  billingAccounts: Scalars['Int'];
  chargingStationClusters: Scalars['Int'];
  chargingStationTransactionReports: Scalars['Int'];
  documents: Scalars['Int'];
  gateControllers: Scalars['Int'];
  invitedEmails: Scalars['Int'];
};

export type CompanyCountAggregate = {
  __typename?: 'CompanyCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  identifier: Scalars['Int'];
  address: Scalars['Int'];
  nationality: Scalars['Int'];
  houseNumber: Scalars['Int'];
  postCode: Scalars['Int'];
  city: Scalars['Int'];
  country: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  email: Scalars['Int'];
  uidNumber: Scalars['Int'];
  commissionScooters: Scalars['Int'];
  commissionBookings: Scalars['Int'];
  commissionChargingStations: Scalars['Int'];
  enabledChargingSessions: Scalars['Int'];
  enabledScooters: Scalars['Int'];
  enabledBookings: Scalars['Int'];
  contactPersonFullname: Scalars['Int'];
  contactPersonPhoneNumber: Scalars['Int'];
  contactPersonEmail: Scalars['Int'];
  logo: Scalars['Int'];
  stripeAccountId: Scalars['Int'];
  stripeAccountLink: Scalars['Int'];
  stripeAccountConfirmedAt: Scalars['Int'];
  defaultSharedVehicleGroupId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CompanyCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  enabledChargingSessions?: Maybe<SortOrder>;
  enabledScooters?: Maybe<SortOrder>;
  enabledBookings?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  defaultSharedVehicleGroupId?: Maybe<SortOrder>;
};

export type CompanyCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateManyDefaultSharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyCreateManyDefaultSharedVehicleGroupInputEnvelope = {
  data: Array<CompanyCreateManyDefaultSharedVehicleGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CompanyCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  defaultSharedVehicleGroupId?: Maybe<Scalars['String']>;
};

export type CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput = {
  create?: Maybe<Array<CompanyCreateWithoutDefaultSharedVehicleGroupInput>>;
  connectOrCreate?: Maybe<
    Array<CompanyCreateOrConnectWithoutDefaultSharedVehicleGroupInput>
  >;
  createMany?: Maybe<CompanyCreateManyDefaultSharedVehicleGroupInputEnvelope>;
  connect?: Maybe<Array<CompanyWhereUniqueInput>>;
};

export type CompanyCreateNestedOneWithoutBillingAccountsInput = {
  create?: Maybe<CompanyCreateWithoutBillingAccountsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutBillingAccountsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutChargingStationClustersInput = {
  create?: Maybe<CompanyCreateWithoutChargingStationClustersInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutChargingStationClustersInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutChargingStationTransactionReportsInput =
  {
    create?: Maybe<CompanyCreateWithoutChargingStationTransactionReportsInput>;
    connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutChargingStationTransactionReportsInput>;
    connect?: Maybe<CompanyWhereUniqueInput>;
  };

export type CompanyCreateNestedOneWithoutDocumentsInput = {
  create?: Maybe<CompanyCreateWithoutDocumentsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutDocumentsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutGateControllersInput = {
  create?: Maybe<CompanyCreateWithoutGateControllersInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutGateControllersInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutInvitedEmailsInput = {
  create?: Maybe<CompanyCreateWithoutInvitedEmailsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutInvitedEmailsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutSharedVehicleGroupRelCompanyInput = {
  create?: Maybe<CompanyCreateWithoutSharedVehicleGroupRelCompanyInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutSharedVehicleGroupRelCompanyInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutUserInput = {
  create?: Maybe<CompanyCreateWithoutUserInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutUserInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<CompanyCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateOrConnectWithoutBillingAccountsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutBillingAccountsInput;
};

export type CompanyCreateOrConnectWithoutChargingStationClustersInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutChargingStationClustersInput;
};

export type CompanyCreateOrConnectWithoutChargingStationTransactionReportsInput =
  {
    where: CompanyWhereUniqueInput;
    create: CompanyCreateWithoutChargingStationTransactionReportsInput;
  };

export type CompanyCreateOrConnectWithoutDefaultSharedVehicleGroupInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutDefaultSharedVehicleGroupInput;
};

export type CompanyCreateOrConnectWithoutDocumentsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutDocumentsInput;
};

export type CompanyCreateOrConnectWithoutGateControllersInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutGateControllersInput;
};

export type CompanyCreateOrConnectWithoutInvitedEmailsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutInvitedEmailsInput;
};

export type CompanyCreateOrConnectWithoutSharedVehicleGroupRelCompanyInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutSharedVehicleGroupRelCompanyInput;
};

export type CompanyCreateOrConnectWithoutUserInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutUserInput;
};

export type CompanyCreateOrConnectWithoutVehicleInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutVehicleInput;
};

export type CompanyCreateWithoutBillingAccountsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutChargingStationClustersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutChargingStationTransactionReportsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutDefaultSharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutDocumentsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutGateControllersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutInvitedEmailsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutSharedVehicleGroupRelCompanyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountCreateNestedManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterCreateNestedManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportCreateNestedManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentCreateNestedManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailCreateNestedManyWithoutCompanyInput>;
};

export type CompanyDocument = {
  __typename?: 'CompanyDocument';
  id: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type CompanyDocumentCountAggregate = {
  __typename?: 'CompanyDocumentCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  originalName: Scalars['Int'];
  companyId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CompanyDocumentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type CompanyDocumentCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
  company?: Maybe<CompanyCreateNestedOneWithoutDocumentsInput>;
};

export type CompanyDocumentCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
};

export type CompanyDocumentCreateManyCompanyInputEnvelope = {
  data: Array<CompanyDocumentCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CompanyDocumentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
};

export type CompanyDocumentCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<CompanyDocumentCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<CompanyDocumentCreateOrConnectWithoutCompanyInput>
  >;
  createMany?: Maybe<CompanyDocumentCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<CompanyDocumentWhereUniqueInput>>;
};

export type CompanyDocumentCreateOrConnectWithoutCompanyInput = {
  where: CompanyDocumentWhereUniqueInput;
  create: CompanyDocumentCreateWithoutCompanyInput;
};

export type CompanyDocumentCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
};

export type CompanyDocumentGroupBy = {
  __typename?: 'CompanyDocumentGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<CompanyDocumentCountAggregate>;
  _min?: Maybe<CompanyDocumentMinAggregate>;
  _max?: Maybe<CompanyDocumentMaxAggregate>;
};

export type CompanyDocumentListRelationFilter = {
  every?: Maybe<CompanyDocumentWhereInput>;
  some?: Maybe<CompanyDocumentWhereInput>;
  none?: Maybe<CompanyDocumentWhereInput>;
};

export type CompanyDocumentMaxAggregate = {
  __typename?: 'CompanyDocumentMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type CompanyDocumentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type CompanyDocumentMinAggregate = {
  __typename?: 'CompanyDocumentMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type CompanyDocumentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type CompanyDocumentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CompanyDocumentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  _count?: Maybe<CompanyDocumentCountOrderByAggregateInput>;
  _max?: Maybe<CompanyDocumentMaxOrderByAggregateInput>;
  _min?: Maybe<CompanyDocumentMinOrderByAggregateInput>;
};

export type CompanyDocumentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
};

export enum CompanyDocumentScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  OriginalName = 'originalName',
  CompanyId = 'companyId',
}

export type CompanyDocumentScalarWhereInput = {
  AND?: Maybe<Array<CompanyDocumentScalarWhereInput>>;
  OR?: Maybe<Array<CompanyDocumentScalarWhereInput>>;
  NOT?: Maybe<Array<CompanyDocumentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  originalName?: Maybe<StringFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type CompanyDocumentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CompanyDocumentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CompanyDocumentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CompanyDocumentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  originalName?: Maybe<StringWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CompanyDocumentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutDocumentsInput>;
};

export type CompanyDocumentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CompanyDocumentUpdateManyWithWhereWithoutCompanyInput = {
  where: CompanyDocumentScalarWhereInput;
  data: CompanyDocumentUpdateManyMutationInput;
};

export type CompanyDocumentUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<CompanyDocumentCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<CompanyDocumentCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<
    Array<CompanyDocumentUpsertWithWhereUniqueWithoutCompanyInput>
  >;
  createMany?: Maybe<CompanyDocumentCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<CompanyDocumentWhereUniqueInput>>;
  disconnect?: Maybe<Array<CompanyDocumentWhereUniqueInput>>;
  delete?: Maybe<Array<CompanyDocumentWhereUniqueInput>>;
  connect?: Maybe<Array<CompanyDocumentWhereUniqueInput>>;
  update?: Maybe<
    Array<CompanyDocumentUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: Maybe<
    Array<CompanyDocumentUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<CompanyDocumentScalarWhereInput>>;
};

export type CompanyDocumentUpdateWithWhereUniqueWithoutCompanyInput = {
  where: CompanyDocumentWhereUniqueInput;
  data: CompanyDocumentUpdateWithoutCompanyInput;
};

export type CompanyDocumentUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CompanyDocumentUpsertWithWhereUniqueWithoutCompanyInput = {
  where: CompanyDocumentWhereUniqueInput;
  update: CompanyDocumentUpdateWithoutCompanyInput;
  create: CompanyDocumentCreateWithoutCompanyInput;
};

export type CompanyDocumentWhereInput = {
  AND?: Maybe<Array<CompanyDocumentWhereInput>>;
  OR?: Maybe<Array<CompanyDocumentWhereInput>>;
  NOT?: Maybe<Array<CompanyDocumentWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  originalName?: Maybe<StringFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type CompanyDocumentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CompanyGroupBy = {
  __typename?: 'CompanyGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  identifier: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters: Scalars['Float'];
  commissionBookings: Scalars['Float'];
  commissionChargingStations: Scalars['Float'];
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  defaultSharedVehicleGroupId?: Maybe<Scalars['String']>;
  _count?: Maybe<CompanyCountAggregate>;
  _avg?: Maybe<CompanyAvgAggregate>;
  _sum?: Maybe<CompanySumAggregate>;
  _min?: Maybe<CompanyMinAggregate>;
  _max?: Maybe<CompanyMaxAggregate>;
};

export type CompanyListRelationFilter = {
  every?: Maybe<CompanyWhereInput>;
  some?: Maybe<CompanyWhereInput>;
  none?: Maybe<CompanyWhereInput>;
};

export type CompanyMaxAggregate = {
  __typename?: 'CompanyMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  defaultSharedVehicleGroupId?: Maybe<Scalars['String']>;
};

export type CompanyMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  enabledChargingSessions?: Maybe<SortOrder>;
  enabledScooters?: Maybe<SortOrder>;
  enabledBookings?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  defaultSharedVehicleGroupId?: Maybe<SortOrder>;
};

export type CompanyMinAggregate = {
  __typename?: 'CompanyMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uidNumber?: Maybe<Scalars['String']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  enabledChargingSessions?: Maybe<Scalars['Boolean']>;
  enabledScooters?: Maybe<Scalars['Boolean']>;
  enabledBookings?: Maybe<Scalars['Boolean']>;
  contactPersonFullname?: Maybe<Scalars['String']>;
  contactPersonPhoneNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  defaultSharedVehicleGroupId?: Maybe<Scalars['String']>;
};

export type CompanyMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  enabledChargingSessions?: Maybe<SortOrder>;
  enabledScooters?: Maybe<SortOrder>;
  enabledBookings?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  defaultSharedVehicleGroupId?: Maybe<SortOrder>;
};

export type CompanyOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CompanyOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  enabledChargingSessions?: Maybe<SortOrder>;
  enabledScooters?: Maybe<SortOrder>;
  enabledBookings?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  defaultSharedVehicleGroupId?: Maybe<SortOrder>;
  _count?: Maybe<CompanyCountOrderByAggregateInput>;
  _avg?: Maybe<CompanyAvgOrderByAggregateInput>;
  _max?: Maybe<CompanyMaxOrderByAggregateInput>;
  _min?: Maybe<CompanyMinOrderByAggregateInput>;
  _sum?: Maybe<CompanySumOrderByAggregateInput>;
};

export type CompanyOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  uidNumber?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  enabledChargingSessions?: Maybe<SortOrder>;
  enabledScooters?: Maybe<SortOrder>;
  enabledBookings?: Maybe<SortOrder>;
  contactPersonFullname?: Maybe<SortOrder>;
  contactPersonPhoneNumber?: Maybe<SortOrder>;
  contactPersonEmail?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByRelationAggregateInput>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByWithRelationInput>;
  defaultSharedVehicleGroupId?: Maybe<SortOrder>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyOrderByRelationAggregateInput>;
  billingAccounts?: Maybe<BillingAccountOrderByRelationAggregateInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterOrderByRelationAggregateInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportOrderByRelationAggregateInput>;
  documents?: Maybe<CompanyDocumentOrderByRelationAggregateInput>;
  gateControllers?: Maybe<GateControllerOrderByRelationAggregateInput>;
  invitedEmails?: Maybe<UserInvitedEmailOrderByRelationAggregateInput>;
};

export type CompanyRelationFilter = {
  is?: Maybe<CompanyWhereInput>;
  isNot?: Maybe<CompanyWhereInput>;
};

export enum CompanyScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Identifier = 'identifier',
  Address = 'address',
  Nationality = 'nationality',
  HouseNumber = 'houseNumber',
  PostCode = 'postCode',
  City = 'city',
  Country = 'country',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  UidNumber = 'uidNumber',
  CommissionScooters = 'commissionScooters',
  CommissionBookings = 'commissionBookings',
  CommissionChargingStations = 'commissionChargingStations',
  EnabledChargingSessions = 'enabledChargingSessions',
  EnabledScooters = 'enabledScooters',
  EnabledBookings = 'enabledBookings',
  ContactPersonFullname = 'contactPersonFullname',
  ContactPersonPhoneNumber = 'contactPersonPhoneNumber',
  ContactPersonEmail = 'contactPersonEmail',
  Logo = 'logo',
  StripeAccountId = 'stripeAccountId',
  StripeAccountLink = 'stripeAccountLink',
  StripeAccountConfirmedAt = 'stripeAccountConfirmedAt',
  DefaultSharedVehicleGroupId = 'defaultSharedVehicleGroupId',
}

export type CompanyScalarWhereInput = {
  AND?: Maybe<Array<CompanyScalarWhereInput>>;
  OR?: Maybe<Array<CompanyScalarWhereInput>>;
  NOT?: Maybe<Array<CompanyScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  identifier?: Maybe<StringFilter>;
  address?: Maybe<StringNullableFilter>;
  nationality?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringNullableFilter>;
  postCode?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  uidNumber?: Maybe<StringNullableFilter>;
  commissionScooters?: Maybe<FloatFilter>;
  commissionBookings?: Maybe<FloatFilter>;
  commissionChargingStations?: Maybe<FloatFilter>;
  enabledChargingSessions?: Maybe<BoolNullableFilter>;
  enabledScooters?: Maybe<BoolNullableFilter>;
  enabledBookings?: Maybe<BoolNullableFilter>;
  contactPersonFullname?: Maybe<StringNullableFilter>;
  contactPersonPhoneNumber?: Maybe<StringNullableFilter>;
  contactPersonEmail?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  stripeAccountId?: Maybe<StringNullableFilter>;
  stripeAccountLink?: Maybe<StringNullableFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableFilter>;
  defaultSharedVehicleGroupId?: Maybe<StringNullableFilter>;
};

export type CompanyScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CompanyScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  identifier?: Maybe<StringWithAggregatesFilter>;
  address?: Maybe<StringNullableWithAggregatesFilter>;
  nationality?: Maybe<StringNullableWithAggregatesFilter>;
  houseNumber?: Maybe<StringNullableWithAggregatesFilter>;
  postCode?: Maybe<StringNullableWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  country?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  uidNumber?: Maybe<StringNullableWithAggregatesFilter>;
  commissionScooters?: Maybe<FloatWithAggregatesFilter>;
  commissionBookings?: Maybe<FloatWithAggregatesFilter>;
  commissionChargingStations?: Maybe<FloatWithAggregatesFilter>;
  enabledChargingSessions?: Maybe<BoolNullableWithAggregatesFilter>;
  enabledScooters?: Maybe<BoolNullableWithAggregatesFilter>;
  enabledBookings?: Maybe<BoolNullableWithAggregatesFilter>;
  contactPersonFullname?: Maybe<StringNullableWithAggregatesFilter>;
  contactPersonPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  contactPersonEmail?: Maybe<StringNullableWithAggregatesFilter>;
  logo?: Maybe<StringNullableWithAggregatesFilter>;
  stripeAccountId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeAccountLink?: Maybe<StringNullableWithAggregatesFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  defaultSharedVehicleGroupId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CompanySumAggregate = {
  __typename?: 'CompanySumAggregate';
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
};

export type CompanySumOrderByAggregateInput = {
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
};

export type CompanyUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CompanyUpdateManyWithWhereWithoutDefaultSharedVehicleGroupInput = {
  where: CompanyScalarWhereInput;
  data: CompanyUpdateManyMutationInput;
};

export type CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput = {
  create?: Maybe<Array<CompanyCreateWithoutDefaultSharedVehicleGroupInput>>;
  connectOrCreate?: Maybe<
    Array<CompanyCreateOrConnectWithoutDefaultSharedVehicleGroupInput>
  >;
  upsert?: Maybe<
    Array<CompanyUpsertWithWhereUniqueWithoutDefaultSharedVehicleGroupInput>
  >;
  createMany?: Maybe<CompanyCreateManyDefaultSharedVehicleGroupInputEnvelope>;
  set?: Maybe<Array<CompanyWhereUniqueInput>>;
  disconnect?: Maybe<Array<CompanyWhereUniqueInput>>;
  delete?: Maybe<Array<CompanyWhereUniqueInput>>;
  connect?: Maybe<Array<CompanyWhereUniqueInput>>;
  update?: Maybe<
    Array<CompanyUpdateWithWhereUniqueWithoutDefaultSharedVehicleGroupInput>
  >;
  updateMany?: Maybe<
    Array<CompanyUpdateManyWithWhereWithoutDefaultSharedVehicleGroupInput>
  >;
  deleteMany?: Maybe<Array<CompanyScalarWhereInput>>;
};

export type CompanyUpdateOneWithoutBillingAccountsInput = {
  create?: Maybe<CompanyCreateWithoutBillingAccountsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutBillingAccountsInput>;
  upsert?: Maybe<CompanyUpsertWithoutBillingAccountsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutBillingAccountsInput>;
};

export type CompanyUpdateOneWithoutChargingStationClustersInput = {
  create?: Maybe<CompanyCreateWithoutChargingStationClustersInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutChargingStationClustersInput>;
  upsert?: Maybe<CompanyUpsertWithoutChargingStationClustersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutChargingStationClustersInput>;
};

export type CompanyUpdateOneWithoutChargingStationTransactionReportsInput = {
  create?: Maybe<CompanyCreateWithoutChargingStationTransactionReportsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutChargingStationTransactionReportsInput>;
  upsert?: Maybe<CompanyUpsertWithoutChargingStationTransactionReportsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutChargingStationTransactionReportsInput>;
};

export type CompanyUpdateOneWithoutDocumentsInput = {
  create?: Maybe<CompanyCreateWithoutDocumentsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutDocumentsInput>;
  upsert?: Maybe<CompanyUpsertWithoutDocumentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutDocumentsInput>;
};

export type CompanyUpdateOneWithoutGateControllersInput = {
  create?: Maybe<CompanyCreateWithoutGateControllersInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutGateControllersInput>;
  upsert?: Maybe<CompanyUpsertWithoutGateControllersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutGateControllersInput>;
};

export type CompanyUpdateOneWithoutInvitedEmailsInput = {
  create?: Maybe<CompanyCreateWithoutInvitedEmailsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutInvitedEmailsInput>;
  upsert?: Maybe<CompanyUpsertWithoutInvitedEmailsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutInvitedEmailsInput>;
};

export type CompanyUpdateOneWithoutSharedVehicleGroupRelCompanyInput = {
  create?: Maybe<CompanyCreateWithoutSharedVehicleGroupRelCompanyInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutSharedVehicleGroupRelCompanyInput>;
  upsert?: Maybe<CompanyUpsertWithoutSharedVehicleGroupRelCompanyInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutSharedVehicleGroupRelCompanyInput>;
};

export type CompanyUpdateOneWithoutUserInput = {
  create?: Maybe<CompanyCreateWithoutUserInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<CompanyUpsertWithoutUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutUserInput>;
};

export type CompanyUpdateOneWithoutVehicleInput = {
  create?: Maybe<CompanyCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<CompanyUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutVehicleInput>;
};

export type CompanyUpdateWithWhereUniqueWithoutDefaultSharedVehicleGroupInput =
  {
    where: CompanyWhereUniqueInput;
    data: CompanyUpdateWithoutDefaultSharedVehicleGroupInput;
  };

export type CompanyUpdateWithoutBillingAccountsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutChargingStationClustersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutChargingStationTransactionReportsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutDefaultSharedVehicleGroupInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutDocumentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutGateControllersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutInvitedEmailsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutSharedVehicleGroupRelCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  identifier?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  uidNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<FloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<FloatFieldUpdateOperationsInput>;
  enabledChargingSessions?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledScooters?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  enabledBookings?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  contactPersonFullname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonPhoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contactPersonEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutCompanyInput>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput>;
  billingAccounts?: Maybe<BillingAccountUpdateManyWithoutCompanyInput>;
  chargingStationClusters?: Maybe<ChargingStationClusterUpdateManyWithoutCompanyInput>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportUpdateManyWithoutCompanyInput>;
  documents?: Maybe<CompanyDocumentUpdateManyWithoutCompanyInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutCompanyInput>;
  invitedEmails?: Maybe<UserInvitedEmailUpdateManyWithoutCompanyInput>;
};

export type CompanyUpsertWithWhereUniqueWithoutDefaultSharedVehicleGroupInput =
  {
    where: CompanyWhereUniqueInput;
    update: CompanyUpdateWithoutDefaultSharedVehicleGroupInput;
    create: CompanyCreateWithoutDefaultSharedVehicleGroupInput;
  };

export type CompanyUpsertWithoutBillingAccountsInput = {
  update: CompanyUpdateWithoutBillingAccountsInput;
  create: CompanyCreateWithoutBillingAccountsInput;
};

export type CompanyUpsertWithoutChargingStationClustersInput = {
  update: CompanyUpdateWithoutChargingStationClustersInput;
  create: CompanyCreateWithoutChargingStationClustersInput;
};

export type CompanyUpsertWithoutChargingStationTransactionReportsInput = {
  update: CompanyUpdateWithoutChargingStationTransactionReportsInput;
  create: CompanyCreateWithoutChargingStationTransactionReportsInput;
};

export type CompanyUpsertWithoutDocumentsInput = {
  update: CompanyUpdateWithoutDocumentsInput;
  create: CompanyCreateWithoutDocumentsInput;
};

export type CompanyUpsertWithoutGateControllersInput = {
  update: CompanyUpdateWithoutGateControllersInput;
  create: CompanyCreateWithoutGateControllersInput;
};

export type CompanyUpsertWithoutInvitedEmailsInput = {
  update: CompanyUpdateWithoutInvitedEmailsInput;
  create: CompanyCreateWithoutInvitedEmailsInput;
};

export type CompanyUpsertWithoutSharedVehicleGroupRelCompanyInput = {
  update: CompanyUpdateWithoutSharedVehicleGroupRelCompanyInput;
  create: CompanyCreateWithoutSharedVehicleGroupRelCompanyInput;
};

export type CompanyUpsertWithoutUserInput = {
  update: CompanyUpdateWithoutUserInput;
  create: CompanyCreateWithoutUserInput;
};

export type CompanyUpsertWithoutVehicleInput = {
  update: CompanyUpdateWithoutVehicleInput;
  create: CompanyCreateWithoutVehicleInput;
};

export type CompanyWhereInput = {
  AND?: Maybe<Array<CompanyWhereInput>>;
  OR?: Maybe<Array<CompanyWhereInput>>;
  NOT?: Maybe<Array<CompanyWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  identifier?: Maybe<StringFilter>;
  address?: Maybe<StringNullableFilter>;
  nationality?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringNullableFilter>;
  postCode?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  uidNumber?: Maybe<StringNullableFilter>;
  commissionScooters?: Maybe<FloatFilter>;
  commissionBookings?: Maybe<FloatFilter>;
  commissionChargingStations?: Maybe<FloatFilter>;
  enabledChargingSessions?: Maybe<BoolNullableFilter>;
  enabledScooters?: Maybe<BoolNullableFilter>;
  enabledBookings?: Maybe<BoolNullableFilter>;
  contactPersonFullname?: Maybe<StringNullableFilter>;
  contactPersonPhoneNumber?: Maybe<StringNullableFilter>;
  contactPersonEmail?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  stripeAccountId?: Maybe<StringNullableFilter>;
  stripeAccountLink?: Maybe<StringNullableFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableFilter>;
  user?: Maybe<UserListRelationFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
  defaultSharedVehicleGroup?: Maybe<SharedVehicleGroupRelationFilter>;
  defaultSharedVehicleGroupId?: Maybe<StringNullableFilter>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompanyListRelationFilter>;
  billingAccounts?: Maybe<BillingAccountListRelationFilter>;
  chargingStationClusters?: Maybe<ChargingStationClusterListRelationFilter>;
  chargingStationTransactionReports?: Maybe<ChargingStationTransactionReportListRelationFilter>;
  documents?: Maybe<CompanyDocumentListRelationFilter>;
  gateControllers?: Maybe<GateControllerListRelationFilter>;
  invitedEmails?: Maybe<UserInvitedEmailListRelationFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConsumptionType = {
  __typename?: 'ConsumptionType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ConsumptionTypeCount>;
  vehicle: Array<Vehicle>;
};

export type ConsumptionTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type ConsumptionTypeCount = {
  __typename?: 'ConsumptionTypeCount';
  vehicle: Scalars['Int'];
};

export type ConsumptionTypeCountAggregate = {
  __typename?: 'ConsumptionTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ConsumptionTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ConsumptionTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutConsumptionTypeInput>;
};

export type ConsumptionTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ConsumptionTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<ConsumptionTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ConsumptionTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<ConsumptionTypeWhereUniqueInput>;
};

export type ConsumptionTypeCreateOrConnectWithoutVehicleInput = {
  where: ConsumptionTypeWhereUniqueInput;
  create: ConsumptionTypeCreateWithoutVehicleInput;
};

export type ConsumptionTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ConsumptionTypeGroupBy = {
  __typename?: 'ConsumptionTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ConsumptionTypeCountAggregate>;
  _min?: Maybe<ConsumptionTypeMinAggregate>;
  _max?: Maybe<ConsumptionTypeMaxAggregate>;
};

export type ConsumptionTypeMaxAggregate = {
  __typename?: 'ConsumptionTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ConsumptionTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ConsumptionTypeMinAggregate = {
  __typename?: 'ConsumptionTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ConsumptionTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ConsumptionTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<ConsumptionTypeCountOrderByAggregateInput>;
  _max?: Maybe<ConsumptionTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ConsumptionTypeMinOrderByAggregateInput>;
};

export type ConsumptionTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type ConsumptionTypeRelationFilter = {
  is?: Maybe<ConsumptionTypeWhereInput>;
  isNot?: Maybe<ConsumptionTypeWhereInput>;
};

export enum ConsumptionTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type ConsumptionTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsumptionTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsumptionTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsumptionTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ConsumptionTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutConsumptionTypeInput>;
};

export type ConsumptionTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ConsumptionTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<ConsumptionTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ConsumptionTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<ConsumptionTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ConsumptionTypeWhereUniqueInput>;
  update?: Maybe<ConsumptionTypeUpdateWithoutVehicleInput>;
};

export type ConsumptionTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ConsumptionTypeUpsertWithoutVehicleInput = {
  update: ConsumptionTypeUpdateWithoutVehicleInput;
  create: ConsumptionTypeCreateWithoutVehicleInput;
};

export type ConsumptionTypeWhereInput = {
  AND?: Maybe<Array<ConsumptionTypeWhereInput>>;
  OR?: Maybe<Array<ConsumptionTypeWhereInput>>;
  NOT?: Maybe<Array<ConsumptionTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type ConsumptionTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateUserInputDto = {
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  residencePermit?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  userRole?: Maybe<UserRole>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type DoorType = {
  __typename?: 'DoorType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<DoorTypeCount>;
  vehicle: Array<Vehicle>;
};

export type DoorTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type DoorTypeCount = {
  __typename?: 'DoorTypeCount';
  vehicle: Scalars['Int'];
};

export type DoorTypeCountAggregate = {
  __typename?: 'DoorTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DoorTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type DoorTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutDoorTypeInput>;
};

export type DoorTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type DoorTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<DoorTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<DoorTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<DoorTypeWhereUniqueInput>;
};

export type DoorTypeCreateOrConnectWithoutVehicleInput = {
  where: DoorTypeWhereUniqueInput;
  create: DoorTypeCreateWithoutVehicleInput;
};

export type DoorTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type DoorTypeGroupBy = {
  __typename?: 'DoorTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<DoorTypeCountAggregate>;
  _min?: Maybe<DoorTypeMinAggregate>;
  _max?: Maybe<DoorTypeMaxAggregate>;
};

export type DoorTypeMaxAggregate = {
  __typename?: 'DoorTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type DoorTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type DoorTypeMinAggregate = {
  __typename?: 'DoorTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type DoorTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type DoorTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<DoorTypeCountOrderByAggregateInput>;
  _max?: Maybe<DoorTypeMaxOrderByAggregateInput>;
  _min?: Maybe<DoorTypeMinOrderByAggregateInput>;
};

export type DoorTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type DoorTypeRelationFilter = {
  is?: Maybe<DoorTypeWhereInput>;
  isNot?: Maybe<DoorTypeWhereInput>;
};

export enum DoorTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type DoorTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DoorTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DoorTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DoorTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type DoorTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutDoorTypeInput>;
};

export type DoorTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DoorTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<DoorTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<DoorTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<DoorTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DoorTypeWhereUniqueInput>;
  update?: Maybe<DoorTypeUpdateWithoutVehicleInput>;
};

export type DoorTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DoorTypeUpsertWithoutVehicleInput = {
  update: DoorTypeUpdateWithoutVehicleInput;
  create: DoorTypeCreateWithoutVehicleInput;
};

export type DoorTypeWhereInput = {
  AND?: Maybe<Array<DoorTypeWhereInput>>;
  OR?: Maybe<Array<DoorTypeWhereInput>>;
  NOT?: Maybe<Array<DoorTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type DoorTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DriveType = {
  __typename?: 'DriveType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<DriveTypeCount>;
  vehicle: Array<Vehicle>;
};

export type DriveTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type DriveTypeCount = {
  __typename?: 'DriveTypeCount';
  vehicle: Scalars['Int'];
};

export type DriveTypeCountAggregate = {
  __typename?: 'DriveTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type DriveTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type DriveTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutDriveTypeInput>;
};

export type DriveTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type DriveTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<DriveTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<DriveTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<DriveTypeWhereUniqueInput>;
};

export type DriveTypeCreateOrConnectWithoutVehicleInput = {
  where: DriveTypeWhereUniqueInput;
  create: DriveTypeCreateWithoutVehicleInput;
};

export type DriveTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type DriveTypeGroupBy = {
  __typename?: 'DriveTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<DriveTypeCountAggregate>;
  _min?: Maybe<DriveTypeMinAggregate>;
  _max?: Maybe<DriveTypeMaxAggregate>;
};

export type DriveTypeMaxAggregate = {
  __typename?: 'DriveTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type DriveTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type DriveTypeMinAggregate = {
  __typename?: 'DriveTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type DriveTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type DriveTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<DriveTypeCountOrderByAggregateInput>;
  _max?: Maybe<DriveTypeMaxOrderByAggregateInput>;
  _min?: Maybe<DriveTypeMinOrderByAggregateInput>;
};

export type DriveTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type DriveTypeRelationFilter = {
  is?: Maybe<DriveTypeWhereInput>;
  isNot?: Maybe<DriveTypeWhereInput>;
};

export enum DriveTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type DriveTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DriveTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DriveTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DriveTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type DriveTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutDriveTypeInput>;
};

export type DriveTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DriveTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<DriveTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<DriveTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<DriveTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DriveTypeWhereUniqueInput>;
  update?: Maybe<DriveTypeUpdateWithoutVehicleInput>;
};

export type DriveTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DriveTypeUpsertWithoutVehicleInput = {
  update: DriveTypeUpdateWithoutVehicleInput;
  create: DriveTypeCreateWithoutVehicleInput;
};

export type DriveTypeWhereInput = {
  AND?: Maybe<Array<DriveTypeWhereInput>>;
  OR?: Maybe<Array<DriveTypeWhereInput>>;
  NOT?: Maybe<Array<DriveTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type DriveTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EmissionCodeType = {
  __typename?: 'EmissionCodeType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<EmissionCodeTypeCount>;
  vehicle: Array<Vehicle>;
};

export type EmissionCodeTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type EmissionCodeTypeCount = {
  __typename?: 'EmissionCodeTypeCount';
  vehicle: Scalars['Int'];
};

export type EmissionCodeTypeCountAggregate = {
  __typename?: 'EmissionCodeTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EmissionCodeTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EmissionCodeTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutEmissionCodeTypeInput>;
};

export type EmissionCodeTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type EmissionCodeTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<EmissionCodeTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<EmissionCodeTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<EmissionCodeTypeWhereUniqueInput>;
};

export type EmissionCodeTypeCreateOrConnectWithoutVehicleInput = {
  where: EmissionCodeTypeWhereUniqueInput;
  create: EmissionCodeTypeCreateWithoutVehicleInput;
};

export type EmissionCodeTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type EmissionCodeTypeGroupBy = {
  __typename?: 'EmissionCodeTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<EmissionCodeTypeCountAggregate>;
  _min?: Maybe<EmissionCodeTypeMinAggregate>;
  _max?: Maybe<EmissionCodeTypeMaxAggregate>;
};

export type EmissionCodeTypeMaxAggregate = {
  __typename?: 'EmissionCodeTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type EmissionCodeTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EmissionCodeTypeMinAggregate = {
  __typename?: 'EmissionCodeTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type EmissionCodeTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EmissionCodeTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<EmissionCodeTypeCountOrderByAggregateInput>;
  _max?: Maybe<EmissionCodeTypeMaxOrderByAggregateInput>;
  _min?: Maybe<EmissionCodeTypeMinOrderByAggregateInput>;
};

export type EmissionCodeTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type EmissionCodeTypeRelationFilter = {
  is?: Maybe<EmissionCodeTypeWhereInput>;
  isNot?: Maybe<EmissionCodeTypeWhereInput>;
};

export enum EmissionCodeTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type EmissionCodeTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmissionCodeTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmissionCodeTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmissionCodeTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type EmissionCodeTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutEmissionCodeTypeInput>;
};

export type EmissionCodeTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EmissionCodeTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<EmissionCodeTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<EmissionCodeTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<EmissionCodeTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EmissionCodeTypeWhereUniqueInput>;
  update?: Maybe<EmissionCodeTypeUpdateWithoutVehicleInput>;
};

export type EmissionCodeTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EmissionCodeTypeUpsertWithoutVehicleInput = {
  update: EmissionCodeTypeUpdateWithoutVehicleInput;
  create: EmissionCodeTypeCreateWithoutVehicleInput;
};

export type EmissionCodeTypeWhereInput = {
  AND?: Maybe<Array<EmissionCodeTypeWhereInput>>;
  OR?: Maybe<Array<EmissionCodeTypeWhereInput>>;
  NOT?: Maybe<Array<EmissionCodeTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type EmissionCodeTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EnergyEfficiencyType = {
  __typename?: 'EnergyEfficiencyType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<EnergyEfficiencyTypeCount>;
  vehicle: Array<Vehicle>;
};

export type EnergyEfficiencyTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type EnergyEfficiencyTypeCount = {
  __typename?: 'EnergyEfficiencyTypeCount';
  vehicle: Scalars['Int'];
};

export type EnergyEfficiencyTypeCountAggregate = {
  __typename?: 'EnergyEfficiencyTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EnergyEfficiencyTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EnergyEfficiencyTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutEnergyEfficiencyTypeInput>;
};

export type EnergyEfficiencyTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<EnergyEfficiencyTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<EnergyEfficiencyTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<EnergyEfficiencyTypeWhereUniqueInput>;
};

export type EnergyEfficiencyTypeCreateOrConnectWithoutVehicleInput = {
  where: EnergyEfficiencyTypeWhereUniqueInput;
  create: EnergyEfficiencyTypeCreateWithoutVehicleInput;
};

export type EnergyEfficiencyTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type EnergyEfficiencyTypeGroupBy = {
  __typename?: 'EnergyEfficiencyTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<EnergyEfficiencyTypeCountAggregate>;
  _min?: Maybe<EnergyEfficiencyTypeMinAggregate>;
  _max?: Maybe<EnergyEfficiencyTypeMaxAggregate>;
};

export type EnergyEfficiencyTypeMaxAggregate = {
  __typename?: 'EnergyEfficiencyTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type EnergyEfficiencyTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EnergyEfficiencyTypeMinAggregate = {
  __typename?: 'EnergyEfficiencyTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type EnergyEfficiencyTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EnergyEfficiencyTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<EnergyEfficiencyTypeCountOrderByAggregateInput>;
  _max?: Maybe<EnergyEfficiencyTypeMaxOrderByAggregateInput>;
  _min?: Maybe<EnergyEfficiencyTypeMinOrderByAggregateInput>;
};

export type EnergyEfficiencyTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type EnergyEfficiencyTypeRelationFilter = {
  is?: Maybe<EnergyEfficiencyTypeWhereInput>;
  isNot?: Maybe<EnergyEfficiencyTypeWhereInput>;
};

export enum EnergyEfficiencyTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type EnergyEfficiencyTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EnergyEfficiencyTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EnergyEfficiencyTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EnergyEfficiencyTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type EnergyEfficiencyTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutEnergyEfficiencyTypeInput>;
};

export type EnergyEfficiencyTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EnergyEfficiencyTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<EnergyEfficiencyTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<EnergyEfficiencyTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<EnergyEfficiencyTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EnergyEfficiencyTypeWhereUniqueInput>;
  update?: Maybe<EnergyEfficiencyTypeUpdateWithoutVehicleInput>;
};

export type EnergyEfficiencyTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EnergyEfficiencyTypeUpsertWithoutVehicleInput = {
  update: EnergyEfficiencyTypeUpdateWithoutVehicleInput;
  create: EnergyEfficiencyTypeCreateWithoutVehicleInput;
};

export type EnergyEfficiencyTypeWhereInput = {
  AND?: Maybe<Array<EnergyEfficiencyTypeWhereInput>>;
  OR?: Maybe<Array<EnergyEfficiencyTypeWhereInput>>;
  NOT?: Maybe<Array<EnergyEfficiencyTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type EnergyEfficiencyTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EnumAvailabilityTypeFieldUpdateOperationsInput = {
  set?: Maybe<AvailabilityType>;
};

export type EnumAvailabilityTypeFilter = {
  equals?: Maybe<AvailabilityType>;
  in?: Maybe<Array<AvailabilityType>>;
  notIn?: Maybe<Array<AvailabilityType>>;
  not?: Maybe<NestedEnumAvailabilityTypeFilter>;
};

export type EnumAvailabilityTypeWithAggregatesFilter = {
  equals?: Maybe<AvailabilityType>;
  in?: Maybe<Array<AvailabilityType>>;
  notIn?: Maybe<Array<AvailabilityType>>;
  not?: Maybe<NestedEnumAvailabilityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAvailabilityTypeFilter>;
  _max?: Maybe<NestedEnumAvailabilityTypeFilter>;
};

export type EnumBillingAccountPaymentFrequencyFieldUpdateOperationsInput = {
  set?: Maybe<BillingAccountPaymentFrequency>;
};

export type EnumBillingAccountPaymentFrequencyFilter = {
  equals?: Maybe<BillingAccountPaymentFrequency>;
  in?: Maybe<Array<BillingAccountPaymentFrequency>>;
  notIn?: Maybe<Array<BillingAccountPaymentFrequency>>;
  not?: Maybe<NestedEnumBillingAccountPaymentFrequencyFilter>;
};

export type EnumBillingAccountPaymentFrequencyWithAggregatesFilter = {
  equals?: Maybe<BillingAccountPaymentFrequency>;
  in?: Maybe<Array<BillingAccountPaymentFrequency>>;
  notIn?: Maybe<Array<BillingAccountPaymentFrequency>>;
  not?: Maybe<NestedEnumBillingAccountPaymentFrequencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBillingAccountPaymentFrequencyFilter>;
  _max?: Maybe<NestedEnumBillingAccountPaymentFrequencyFilter>;
};

export type EnumBookingQuestionKindFieldUpdateOperationsInput = {
  set?: Maybe<BookingQuestionKind>;
};

export type EnumBookingQuestionKindFilter = {
  equals?: Maybe<BookingQuestionKind>;
  in?: Maybe<Array<BookingQuestionKind>>;
  notIn?: Maybe<Array<BookingQuestionKind>>;
  not?: Maybe<NestedEnumBookingQuestionKindFilter>;
};

export type EnumBookingQuestionKindWithAggregatesFilter = {
  equals?: Maybe<BookingQuestionKind>;
  in?: Maybe<Array<BookingQuestionKind>>;
  notIn?: Maybe<Array<BookingQuestionKind>>;
  not?: Maybe<NestedEnumBookingQuestionKindWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBookingQuestionKindFilter>;
  _max?: Maybe<NestedEnumBookingQuestionKindFilter>;
};

export type EnumBookingQuestionTypeFieldUpdateOperationsInput = {
  set?: Maybe<BookingQuestionType>;
};

export type EnumBookingQuestionTypeFilter = {
  equals?: Maybe<BookingQuestionType>;
  in?: Maybe<Array<BookingQuestionType>>;
  notIn?: Maybe<Array<BookingQuestionType>>;
  not?: Maybe<NestedEnumBookingQuestionTypeFilter>;
};

export type EnumBookingQuestionTypeWithAggregatesFilter = {
  equals?: Maybe<BookingQuestionType>;
  in?: Maybe<Array<BookingQuestionType>>;
  notIn?: Maybe<Array<BookingQuestionType>>;
  not?: Maybe<NestedEnumBookingQuestionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBookingQuestionTypeFilter>;
  _max?: Maybe<NestedEnumBookingQuestionTypeFilter>;
};

export type EnumChargingStationRulePropertyFieldUpdateOperationsInput = {
  set?: Maybe<ChargingStationRuleProperty>;
};

export type EnumChargingStationRulePropertyFilter = {
  equals?: Maybe<ChargingStationRuleProperty>;
  in?: Maybe<Array<ChargingStationRuleProperty>>;
  notIn?: Maybe<Array<ChargingStationRuleProperty>>;
  not?: Maybe<NestedEnumChargingStationRulePropertyFilter>;
};

export type EnumChargingStationRulePropertyWithAggregatesFilter = {
  equals?: Maybe<ChargingStationRuleProperty>;
  in?: Maybe<Array<ChargingStationRuleProperty>>;
  notIn?: Maybe<Array<ChargingStationRuleProperty>>;
  not?: Maybe<NestedEnumChargingStationRulePropertyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargingStationRulePropertyFilter>;
  _max?: Maybe<NestedEnumChargingStationRulePropertyFilter>;
};

export type EnumChargingStationTariffTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChargingStationTariffType>;
};

export type EnumChargingStationTariffTypeFilter = {
  equals?: Maybe<ChargingStationTariffType>;
  in?: Maybe<Array<ChargingStationTariffType>>;
  notIn?: Maybe<Array<ChargingStationTariffType>>;
  not?: Maybe<NestedEnumChargingStationTariffTypeFilter>;
};

export type EnumChargingStationTariffTypeWithAggregatesFilter = {
  equals?: Maybe<ChargingStationTariffType>;
  in?: Maybe<Array<ChargingStationTariffType>>;
  notIn?: Maybe<Array<ChargingStationTariffType>>;
  not?: Maybe<NestedEnumChargingStationTariffTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargingStationTariffTypeFilter>;
  _max?: Maybe<NestedEnumChargingStationTariffTypeFilter>;
};

export type EnumChargingStationTransactionAuthTypeNullableFilter = {
  equals?: Maybe<ChargingStationTransactionAuthType>;
  in?: Maybe<Array<ChargingStationTransactionAuthType>>;
  notIn?: Maybe<Array<ChargingStationTransactionAuthType>>;
  not?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableFilter>;
};

export type EnumChargingStationTransactionAuthTypeNullableWithAggregatesFilter =
  {
    equals?: Maybe<ChargingStationTransactionAuthType>;
    in?: Maybe<Array<ChargingStationTransactionAuthType>>;
    notIn?: Maybe<Array<ChargingStationTransactionAuthType>>;
    not?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableWithAggregatesFilter>;
    _count?: Maybe<NestedIntNullableFilter>;
    _min?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableFilter>;
    _max?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableFilter>;
  };

export type EnumChargingStationTransactionStatusNullableFilter = {
  equals?: Maybe<ChargingStationTransactionStatus>;
  in?: Maybe<Array<ChargingStationTransactionStatus>>;
  notIn?: Maybe<Array<ChargingStationTransactionStatus>>;
  not?: Maybe<NestedEnumChargingStationTransactionStatusNullableFilter>;
};

export type EnumChargingStationTransactionStatusNullableWithAggregatesFilter = {
  equals?: Maybe<ChargingStationTransactionStatus>;
  in?: Maybe<Array<ChargingStationTransactionStatus>>;
  notIn?: Maybe<Array<ChargingStationTransactionStatus>>;
  not?: Maybe<NestedEnumChargingStationTransactionStatusNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumChargingStationTransactionStatusNullableFilter>;
  _max?: Maybe<NestedEnumChargingStationTransactionStatusNullableFilter>;
};

export type EnumGateControllerRulePropertyFieldUpdateOperationsInput = {
  set?: Maybe<GateControllerRuleProperty>;
};

export type EnumGateControllerRulePropertyFilter = {
  equals?: Maybe<GateControllerRuleProperty>;
  in?: Maybe<Array<GateControllerRuleProperty>>;
  notIn?: Maybe<Array<GateControllerRuleProperty>>;
  not?: Maybe<NestedEnumGateControllerRulePropertyFilter>;
};

export type EnumGateControllerRulePropertyWithAggregatesFilter = {
  equals?: Maybe<GateControllerRuleProperty>;
  in?: Maybe<Array<GateControllerRuleProperty>>;
  notIn?: Maybe<Array<GateControllerRuleProperty>>;
  not?: Maybe<NestedEnumGateControllerRulePropertyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGateControllerRulePropertyFilter>;
  _max?: Maybe<NestedEnumGateControllerRulePropertyFilter>;
};

export type EnumGateControllerRuleSetTypeFieldUpdateOperationsInput = {
  set?: Maybe<GateControllerRuleSetType>;
};

export type EnumGateControllerRuleSetTypeFilter = {
  equals?: Maybe<GateControllerRuleSetType>;
  in?: Maybe<Array<GateControllerRuleSetType>>;
  notIn?: Maybe<Array<GateControllerRuleSetType>>;
  not?: Maybe<NestedEnumGateControllerRuleSetTypeFilter>;
};

export type EnumGateControllerRuleSetTypeWithAggregatesFilter = {
  equals?: Maybe<GateControllerRuleSetType>;
  in?: Maybe<Array<GateControllerRuleSetType>>;
  notIn?: Maybe<Array<GateControllerRuleSetType>>;
  not?: Maybe<NestedEnumGateControllerRuleSetTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGateControllerRuleSetTypeFilter>;
  _max?: Maybe<NestedEnumGateControllerRuleSetTypeFilter>;
};

export type EnumGenderTypeNullableFilter = {
  equals?: Maybe<GenderType>;
  in?: Maybe<Array<GenderType>>;
  notIn?: Maybe<Array<GenderType>>;
  not?: Maybe<NestedEnumGenderTypeNullableFilter>;
};

export type EnumGenderTypeNullableWithAggregatesFilter = {
  equals?: Maybe<GenderType>;
  in?: Maybe<Array<GenderType>>;
  notIn?: Maybe<Array<GenderType>>;
  not?: Maybe<NestedEnumGenderTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumGenderTypeNullableFilter>;
  _max?: Maybe<NestedEnumGenderTypeNullableFilter>;
};

export type EnumInsuranceReportingFieldUpdateOperationsInput = {
  set?: Maybe<InsuranceReporting>;
};

export type EnumInsuranceReportingFilter = {
  equals?: Maybe<InsuranceReporting>;
  in?: Maybe<Array<InsuranceReporting>>;
  notIn?: Maybe<Array<InsuranceReporting>>;
  not?: Maybe<NestedEnumInsuranceReportingFilter>;
};

export type EnumInsuranceReportingWithAggregatesFilter = {
  equals?: Maybe<InsuranceReporting>;
  in?: Maybe<Array<InsuranceReporting>>;
  notIn?: Maybe<Array<InsuranceReporting>>;
  not?: Maybe<NestedEnumInsuranceReportingWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInsuranceReportingFilter>;
  _max?: Maybe<NestedEnumInsuranceReportingFilter>;
};

export type EnumMessageTypeFieldUpdateOperationsInput = {
  set?: Maybe<MessageType>;
};

export type EnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
};

export type EnumMessageTypeWithAggregatesFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
};

export type EnumOwnershipFieldUpdateOperationsInput = {
  set?: Maybe<Ownership>;
};

export type EnumOwnershipFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipFilter>;
};

export type EnumOwnershipNullableFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipNullableFilter>;
};

export type EnumOwnershipNullableWithAggregatesFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumOwnershipNullableFilter>;
  _max?: Maybe<NestedEnumOwnershipNullableFilter>;
};

export type EnumOwnershipWithAggregatesFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumOwnershipFilter>;
  _max?: Maybe<NestedEnumOwnershipFilter>;
};

export type EnumPxlVisionVerificationTypeFieldUpdateOperationsInput = {
  set?: Maybe<PxlVisionVerificationType>;
};

export type EnumPxlVisionVerificationTypeFilter = {
  equals?: Maybe<PxlVisionVerificationType>;
  in?: Maybe<Array<PxlVisionVerificationType>>;
  notIn?: Maybe<Array<PxlVisionVerificationType>>;
  not?: Maybe<NestedEnumPxlVisionVerificationTypeFilter>;
};

export type EnumPxlVisionVerificationTypeWithAggregatesFilter = {
  equals?: Maybe<PxlVisionVerificationType>;
  in?: Maybe<Array<PxlVisionVerificationType>>;
  notIn?: Maybe<Array<PxlVisionVerificationType>>;
  not?: Maybe<NestedEnumPxlVisionVerificationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPxlVisionVerificationTypeFilter>;
  _max?: Maybe<NestedEnumPxlVisionVerificationTypeFilter>;
};

export type EnumRuleCompareOperatorFieldUpdateOperationsInput = {
  set?: Maybe<RuleCompareOperator>;
};

export type EnumRuleCompareOperatorFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorFilter>;
};

export type EnumRuleCompareOperatorNullableFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorNullableFilter>;
};

export type EnumRuleCompareOperatorNullableWithAggregatesFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumRuleCompareOperatorNullableFilter>;
  _max?: Maybe<NestedEnumRuleCompareOperatorNullableFilter>;
};

export type EnumRuleCompareOperatorWithAggregatesFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRuleCompareOperatorFilter>;
  _max?: Maybe<NestedEnumRuleCompareOperatorFilter>;
};

export type EnumRulePropertyFieldUpdateOperationsInput = {
  set?: Maybe<RuleProperty>;
};

export type EnumRulePropertyFilter = {
  equals?: Maybe<RuleProperty>;
  in?: Maybe<Array<RuleProperty>>;
  notIn?: Maybe<Array<RuleProperty>>;
  not?: Maybe<NestedEnumRulePropertyFilter>;
};

export type EnumRulePropertyWithAggregatesFilter = {
  equals?: Maybe<RuleProperty>;
  in?: Maybe<Array<RuleProperty>>;
  notIn?: Maybe<Array<RuleProperty>>;
  not?: Maybe<NestedEnumRulePropertyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRulePropertyFilter>;
  _max?: Maybe<NestedEnumRulePropertyFilter>;
};

export type EnumStripePaymentMethodTypeNullableFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeNullableFilter>;
};

export type EnumStripePaymentMethodTypeNullableWithAggregatesFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeNullableFilter>;
};

export type EnumUserRoleNullableFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  notIn?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleNullableFilter>;
};

export type EnumUserRoleNullableWithAggregatesFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  notIn?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumUserRoleNullableFilter>;
  _max?: Maybe<NestedEnumUserRoleNullableFilter>;
};

export type EnumVehicleConnectionTypeFieldUpdateOperationsInput = {
  set?: Maybe<VehicleConnectionType>;
};

export type EnumVehicleConnectionTypeFilter = {
  equals?: Maybe<VehicleConnectionType>;
  in?: Maybe<Array<VehicleConnectionType>>;
  notIn?: Maybe<Array<VehicleConnectionType>>;
  not?: Maybe<NestedEnumVehicleConnectionTypeFilter>;
};

export type EnumVehicleConnectionTypeWithAggregatesFilter = {
  equals?: Maybe<VehicleConnectionType>;
  in?: Maybe<Array<VehicleConnectionType>>;
  notIn?: Maybe<Array<VehicleConnectionType>>;
  not?: Maybe<NestedEnumVehicleConnectionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVehicleConnectionTypeFilter>;
  _max?: Maybe<NestedEnumVehicleConnectionTypeFilter>;
};

export type EnumVehicleDamageKindNullableFilter = {
  equals?: Maybe<VehicleDamageKind>;
  in?: Maybe<Array<VehicleDamageKind>>;
  notIn?: Maybe<Array<VehicleDamageKind>>;
  not?: Maybe<NestedEnumVehicleDamageKindNullableFilter>;
};

export type EnumVehicleDamageKindNullableWithAggregatesFilter = {
  equals?: Maybe<VehicleDamageKind>;
  in?: Maybe<Array<VehicleDamageKind>>;
  notIn?: Maybe<Array<VehicleDamageKind>>;
  not?: Maybe<NestedEnumVehicleDamageKindNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumVehicleDamageKindNullableFilter>;
  _max?: Maybe<NestedEnumVehicleDamageKindNullableFilter>;
};

export type EnumVehicleDamageTimeNullableFilter = {
  equals?: Maybe<VehicleDamageTime>;
  in?: Maybe<Array<VehicleDamageTime>>;
  notIn?: Maybe<Array<VehicleDamageTime>>;
  not?: Maybe<NestedEnumVehicleDamageTimeNullableFilter>;
};

export type EnumVehicleDamageTimeNullableWithAggregatesFilter = {
  equals?: Maybe<VehicleDamageTime>;
  in?: Maybe<Array<VehicleDamageTime>>;
  notIn?: Maybe<Array<VehicleDamageTime>>;
  not?: Maybe<NestedEnumVehicleDamageTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumVehicleDamageTimeNullableFilter>;
  _max?: Maybe<NestedEnumVehicleDamageTimeNullableFilter>;
};

export type EnumVehicleDamageTypeFieldUpdateOperationsInput = {
  set?: Maybe<VehicleDamageType>;
};

export type EnumVehicleDamageTypeFilter = {
  equals?: Maybe<VehicleDamageType>;
  in?: Maybe<Array<VehicleDamageType>>;
  notIn?: Maybe<Array<VehicleDamageType>>;
  not?: Maybe<NestedEnumVehicleDamageTypeFilter>;
};

export type EnumVehicleDamageTypeWithAggregatesFilter = {
  equals?: Maybe<VehicleDamageType>;
  in?: Maybe<Array<VehicleDamageType>>;
  notIn?: Maybe<Array<VehicleDamageType>>;
  not?: Maybe<NestedEnumVehicleDamageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVehicleDamageTypeFilter>;
  _max?: Maybe<NestedEnumVehicleDamageTypeFilter>;
};

export type EnumVehiclePhotoTypeFieldUpdateOperationsInput = {
  set?: Maybe<VehiclePhotoType>;
};

export type EnumVehiclePhotoTypeFilter = {
  equals?: Maybe<VehiclePhotoType>;
  in?: Maybe<Array<VehiclePhotoType>>;
  notIn?: Maybe<Array<VehiclePhotoType>>;
  not?: Maybe<NestedEnumVehiclePhotoTypeFilter>;
};

export type EnumVehiclePhotoTypeWithAggregatesFilter = {
  equals?: Maybe<VehiclePhotoType>;
  in?: Maybe<Array<VehiclePhotoType>>;
  notIn?: Maybe<Array<VehiclePhotoType>>;
  not?: Maybe<NestedEnumVehiclePhotoTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVehiclePhotoTypeFilter>;
  _max?: Maybe<NestedEnumVehiclePhotoTypeFilter>;
};

export type EquipmentType = {
  __typename?: 'EquipmentType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<EquipmentTypeCount>;
  vehicleEquipment: Array<VehicleEquipment>;
};

export type EquipmentTypeVehicleEquipmentArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
  orderBy?: Maybe<Array<VehicleEquipmentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleEquipmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleEquipmentScalarFieldEnum>>;
};

export type EquipmentTypeCount = {
  __typename?: 'EquipmentTypeCount';
  vehicleEquipment: Scalars['Int'];
};

export type EquipmentTypeCountAggregate = {
  __typename?: 'EquipmentTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type EquipmentTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EquipmentTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutEquipmentTypeInput>;
};

export type EquipmentTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type EquipmentTypeCreateNestedOneWithoutVehicleEquipmentInput = {
  create?: Maybe<EquipmentTypeCreateWithoutVehicleEquipmentInput>;
  connectOrCreate?: Maybe<EquipmentTypeCreateOrConnectWithoutVehicleEquipmentInput>;
  connect?: Maybe<EquipmentTypeWhereUniqueInput>;
};

export type EquipmentTypeCreateOrConnectWithoutVehicleEquipmentInput = {
  where: EquipmentTypeWhereUniqueInput;
  create: EquipmentTypeCreateWithoutVehicleEquipmentInput;
};

export type EquipmentTypeCreateWithoutVehicleEquipmentInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type EquipmentTypeGroupBy = {
  __typename?: 'EquipmentTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<EquipmentTypeCountAggregate>;
  _min?: Maybe<EquipmentTypeMinAggregate>;
  _max?: Maybe<EquipmentTypeMaxAggregate>;
};

export type EquipmentTypeMaxAggregate = {
  __typename?: 'EquipmentTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type EquipmentTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EquipmentTypeMinAggregate = {
  __typename?: 'EquipmentTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type EquipmentTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type EquipmentTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<EquipmentTypeCountOrderByAggregateInput>;
  _max?: Maybe<EquipmentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<EquipmentTypeMinOrderByAggregateInput>;
};

export type EquipmentTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicleEquipment?: Maybe<VehicleEquipmentOrderByRelationAggregateInput>;
};

export type EquipmentTypeRelationFilter = {
  is?: Maybe<EquipmentTypeWhereInput>;
  isNot?: Maybe<EquipmentTypeWhereInput>;
};

export enum EquipmentTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type EquipmentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EquipmentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EquipmentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EquipmentTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type EquipmentTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutEquipmentTypeInput>;
};

export type EquipmentTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EquipmentTypeUpdateOneRequiredWithoutVehicleEquipmentInput = {
  create?: Maybe<EquipmentTypeCreateWithoutVehicleEquipmentInput>;
  connectOrCreate?: Maybe<EquipmentTypeCreateOrConnectWithoutVehicleEquipmentInput>;
  upsert?: Maybe<EquipmentTypeUpsertWithoutVehicleEquipmentInput>;
  connect?: Maybe<EquipmentTypeWhereUniqueInput>;
  update?: Maybe<EquipmentTypeUpdateWithoutVehicleEquipmentInput>;
};

export type EquipmentTypeUpdateWithoutVehicleEquipmentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EquipmentTypeUpsertWithoutVehicleEquipmentInput = {
  update: EquipmentTypeUpdateWithoutVehicleEquipmentInput;
  create: EquipmentTypeCreateWithoutVehicleEquipmentInput;
};

export type EquipmentTypeWhereInput = {
  AND?: Maybe<Array<EquipmentTypeWhereInput>>;
  OR?: Maybe<Array<EquipmentTypeWhereInput>>;
  NOT?: Maybe<Array<EquipmentTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicleEquipment?: Maybe<VehicleEquipmentListRelationFilter>;
};

export type EquipmentTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type FloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type FloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type FuelType = {
  __typename?: 'FuelType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<FuelTypeCount>;
  vehicle: Array<Vehicle>;
};

export type FuelTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type FuelTypeCount = {
  __typename?: 'FuelTypeCount';
  vehicle: Scalars['Int'];
};

export type FuelTypeCountAggregate = {
  __typename?: 'FuelTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type FuelTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type FuelTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutFuelTypeInput>;
};

export type FuelTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type FuelTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<FuelTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<FuelTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<FuelTypeWhereUniqueInput>;
};

export type FuelTypeCreateOrConnectWithoutVehicleInput = {
  where: FuelTypeWhereUniqueInput;
  create: FuelTypeCreateWithoutVehicleInput;
};

export type FuelTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type FuelTypeGroupBy = {
  __typename?: 'FuelTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<FuelTypeCountAggregate>;
  _min?: Maybe<FuelTypeMinAggregate>;
  _max?: Maybe<FuelTypeMaxAggregate>;
};

export type FuelTypeMaxAggregate = {
  __typename?: 'FuelTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type FuelTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type FuelTypeMinAggregate = {
  __typename?: 'FuelTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type FuelTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type FuelTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<FuelTypeCountOrderByAggregateInput>;
  _max?: Maybe<FuelTypeMaxOrderByAggregateInput>;
  _min?: Maybe<FuelTypeMinOrderByAggregateInput>;
};

export type FuelTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type FuelTypeRelationFilter = {
  is?: Maybe<FuelTypeWhereInput>;
  isNot?: Maybe<FuelTypeWhereInput>;
};

export enum FuelTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type FuelTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FuelTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FuelTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FuelTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type FuelTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutFuelTypeInput>;
};

export type FuelTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type FuelTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<FuelTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<FuelTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<FuelTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FuelTypeWhereUniqueInput>;
  update?: Maybe<FuelTypeUpdateWithoutVehicleInput>;
};

export type FuelTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type FuelTypeUpsertWithoutVehicleInput = {
  update: FuelTypeUpdateWithoutVehicleInput;
  create: FuelTypeCreateWithoutVehicleInput;
};

export type FuelTypeWhereInput = {
  AND?: Maybe<Array<FuelTypeWhereInput>>;
  OR?: Maybe<Array<FuelTypeWhereInput>>;
  NOT?: Maybe<Array<FuelTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type FuelTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GateController = {
  __typename?: 'GateController';
  id: Scalars['String'];
  gateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateTypeId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerCount>;
  gateType?: Maybe<GateControllerType>;
  company?: Maybe<Company>;
  documents: Array<GateControllerDocument>;
  ruleSets: Array<GateControllerRuleSet>;
  transactions: Array<GateControllerTransaction>;
};

export type GateControllerDocumentsArgs = {
  where?: Maybe<GateControllerDocumentWhereInput>;
  orderBy?: Maybe<Array<GateControllerDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerDocumentScalarFieldEnum>>;
};

export type GateControllerRuleSetsArgs = {
  where?: Maybe<GateControllerRuleSetWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRuleSetScalarFieldEnum>>;
};

export type GateControllerTransactionsArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTransactionScalarFieldEnum>>;
};

export type GateControllerAvgAggregate = {
  __typename?: 'GateControllerAvgAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GateControllerAvgOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
};

export type GateControllerCount = {
  __typename?: 'GateControllerCount';
  documents: Scalars['Int'];
  ruleSets: Scalars['Int'];
  transactions: Scalars['Int'];
};

export type GateControllerCountAggregate = {
  __typename?: 'GateControllerCountAggregate';
  id: Scalars['Int'];
  gateId: Scalars['Int'];
  createdAt: Scalars['Int'];
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  contentBefore: Scalars['Int'];
  contentAfter: Scalars['Int'];
  operatorCompanyName: Scalars['Int'];
  operatorCompanyPerson: Scalars['Int'];
  operatorCompanyPhone: Scalars['Int'];
  operatorCompanyEmail: Scalars['Int'];
  gateTypeId: Scalars['Int'];
  companyId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  contentBefore?: Maybe<SortOrder>;
  contentAfter?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  gateTypeId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type GateControllerCreateInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateType?: Maybe<GateControllerTypeCreateNestedOneWithoutGateControllersInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentCreateNestedManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetCreateNestedManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutGateControllerInput>;
};

export type GateControllerCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateTypeId?: Maybe<Scalars['String']>;
};

export type GateControllerCreateManyCompanyInputEnvelope = {
  data: Array<GateControllerCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerCreateManyGateTypeInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type GateControllerCreateManyGateTypeInputEnvelope = {
  data: Array<GateControllerCreateManyGateTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateTypeId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type GateControllerCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<GateControllerCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerCreateOrConnectWithoutCompanyInput>
  >;
  createMany?: Maybe<GateControllerCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<GateControllerWhereUniqueInput>>;
};

export type GateControllerCreateNestedManyWithoutGateTypeInput = {
  create?: Maybe<Array<GateControllerCreateWithoutGateTypeInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerCreateOrConnectWithoutGateTypeInput>
  >;
  createMany?: Maybe<GateControllerCreateManyGateTypeInputEnvelope>;
  connect?: Maybe<Array<GateControllerWhereUniqueInput>>;
};

export type GateControllerCreateNestedOneWithoutDocumentsInput = {
  create?: Maybe<GateControllerCreateWithoutDocumentsInput>;
  connectOrCreate?: Maybe<GateControllerCreateOrConnectWithoutDocumentsInput>;
  connect?: Maybe<GateControllerWhereUniqueInput>;
};

export type GateControllerCreateNestedOneWithoutRuleSetsInput = {
  create?: Maybe<GateControllerCreateWithoutRuleSetsInput>;
  connectOrCreate?: Maybe<GateControllerCreateOrConnectWithoutRuleSetsInput>;
  connect?: Maybe<GateControllerWhereUniqueInput>;
};

export type GateControllerCreateNestedOneWithoutTransactionsInput = {
  create?: Maybe<GateControllerCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<GateControllerCreateOrConnectWithoutTransactionsInput>;
  connect?: Maybe<GateControllerWhereUniqueInput>;
};

export type GateControllerCreateOrConnectWithoutCompanyInput = {
  where: GateControllerWhereUniqueInput;
  create: GateControllerCreateWithoutCompanyInput;
};

export type GateControllerCreateOrConnectWithoutDocumentsInput = {
  where: GateControllerWhereUniqueInput;
  create: GateControllerCreateWithoutDocumentsInput;
};

export type GateControllerCreateOrConnectWithoutGateTypeInput = {
  where: GateControllerWhereUniqueInput;
  create: GateControllerCreateWithoutGateTypeInput;
};

export type GateControllerCreateOrConnectWithoutRuleSetsInput = {
  where: GateControllerWhereUniqueInput;
  create: GateControllerCreateWithoutRuleSetsInput;
};

export type GateControllerCreateOrConnectWithoutTransactionsInput = {
  where: GateControllerWhereUniqueInput;
  create: GateControllerCreateWithoutTransactionsInput;
};

export type GateControllerCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateType?: Maybe<GateControllerTypeCreateNestedOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentCreateNestedManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetCreateNestedManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutGateControllerInput>;
};

export type GateControllerCreateWithoutDocumentsInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateType?: Maybe<GateControllerTypeCreateNestedOneWithoutGateControllersInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutGateControllersInput>;
  ruleSets?: Maybe<GateControllerRuleSetCreateNestedManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutGateControllerInput>;
};

export type GateControllerCreateWithoutGateTypeInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyCreateNestedOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentCreateNestedManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetCreateNestedManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutGateControllerInput>;
};

export type GateControllerCreateWithoutRuleSetsInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateType?: Maybe<GateControllerTypeCreateNestedOneWithoutGateControllersInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentCreateNestedManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutGateControllerInput>;
};

export type GateControllerCreateWithoutTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  gateId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateType?: Maybe<GateControllerTypeCreateNestedOneWithoutGateControllersInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentCreateNestedManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetCreateNestedManyWithoutGateControllerInput>;
};

export type GateControllerDocument = {
  __typename?: 'GateControllerDocument';
  id: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  gateControllerId?: Maybe<Scalars['String']>;
  gateController?: Maybe<GateController>;
};

export type GateControllerDocumentCountAggregate = {
  __typename?: 'GateControllerDocumentCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  originalName: Scalars['Int'];
  gateControllerId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerDocumentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
};

export type GateControllerDocumentCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
  gateController?: Maybe<GateControllerCreateNestedOneWithoutDocumentsInput>;
};

export type GateControllerDocumentCreateManyGateControllerInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
};

export type GateControllerDocumentCreateManyGateControllerInputEnvelope = {
  data: Array<GateControllerDocumentCreateManyGateControllerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerDocumentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
  gateControllerId?: Maybe<Scalars['String']>;
};

export type GateControllerDocumentCreateNestedManyWithoutGateControllerInput = {
  create?: Maybe<Array<GateControllerDocumentCreateWithoutGateControllerInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerDocumentCreateOrConnectWithoutGateControllerInput>
  >;
  createMany?: Maybe<GateControllerDocumentCreateManyGateControllerInputEnvelope>;
  connect?: Maybe<Array<GateControllerDocumentWhereUniqueInput>>;
};

export type GateControllerDocumentCreateOrConnectWithoutGateControllerInput = {
  where: GateControllerDocumentWhereUniqueInput;
  create: GateControllerDocumentCreateWithoutGateControllerInput;
};

export type GateControllerDocumentCreateWithoutGateControllerInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalName: Scalars['String'];
};

export type GateControllerDocumentGroupBy = {
  __typename?: 'GateControllerDocumentGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  gateControllerId?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerDocumentCountAggregate>;
  _min?: Maybe<GateControllerDocumentMinAggregate>;
  _max?: Maybe<GateControllerDocumentMaxAggregate>;
};

export type GateControllerDocumentListRelationFilter = {
  every?: Maybe<GateControllerDocumentWhereInput>;
  some?: Maybe<GateControllerDocumentWhereInput>;
  none?: Maybe<GateControllerDocumentWhereInput>;
};

export type GateControllerDocumentMaxAggregate = {
  __typename?: 'GateControllerDocumentMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
};

export type GateControllerDocumentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
};

export type GateControllerDocumentMinAggregate = {
  __typename?: 'GateControllerDocumentMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
};

export type GateControllerDocumentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
};

export type GateControllerDocumentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type GateControllerDocumentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerDocumentCountOrderByAggregateInput>;
  _max?: Maybe<GateControllerDocumentMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerDocumentMinOrderByAggregateInput>;
};

export type GateControllerDocumentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  originalName?: Maybe<SortOrder>;
  gateController?: Maybe<GateControllerOrderByWithRelationInput>;
  gateControllerId?: Maybe<SortOrder>;
};

export enum GateControllerDocumentScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  OriginalName = 'originalName',
  GateControllerId = 'gateControllerId',
}

export type GateControllerDocumentScalarWhereInput = {
  AND?: Maybe<Array<GateControllerDocumentScalarWhereInput>>;
  OR?: Maybe<Array<GateControllerDocumentScalarWhereInput>>;
  NOT?: Maybe<Array<GateControllerDocumentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  originalName?: Maybe<StringFilter>;
  gateControllerId?: Maybe<StringNullableFilter>;
};

export type GateControllerDocumentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerDocumentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerDocumentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerDocumentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  originalName?: Maybe<StringWithAggregatesFilter>;
  gateControllerId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type GateControllerDocumentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutDocumentsInput>;
};

export type GateControllerDocumentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type GateControllerDocumentUpdateManyWithWhereWithoutGateControllerInput =
  {
    where: GateControllerDocumentScalarWhereInput;
    data: GateControllerDocumentUpdateManyMutationInput;
  };

export type GateControllerDocumentUpdateManyWithoutGateControllerInput = {
  create?: Maybe<Array<GateControllerDocumentCreateWithoutGateControllerInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerDocumentCreateOrConnectWithoutGateControllerInput>
  >;
  upsert?: Maybe<
    Array<GateControllerDocumentUpsertWithWhereUniqueWithoutGateControllerInput>
  >;
  createMany?: Maybe<GateControllerDocumentCreateManyGateControllerInputEnvelope>;
  set?: Maybe<Array<GateControllerDocumentWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerDocumentWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerDocumentWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerDocumentWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerDocumentUpdateWithWhereUniqueWithoutGateControllerInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerDocumentUpdateManyWithWhereWithoutGateControllerInput>
  >;
  deleteMany?: Maybe<Array<GateControllerDocumentScalarWhereInput>>;
};

export type GateControllerDocumentUpdateWithWhereUniqueWithoutGateControllerInput =
  {
    where: GateControllerDocumentWhereUniqueInput;
    data: GateControllerDocumentUpdateWithoutGateControllerInput;
  };

export type GateControllerDocumentUpdateWithoutGateControllerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type GateControllerDocumentUpsertWithWhereUniqueWithoutGateControllerInput =
  {
    where: GateControllerDocumentWhereUniqueInput;
    update: GateControllerDocumentUpdateWithoutGateControllerInput;
    create: GateControllerDocumentCreateWithoutGateControllerInput;
  };

export type GateControllerDocumentWhereInput = {
  AND?: Maybe<Array<GateControllerDocumentWhereInput>>;
  OR?: Maybe<Array<GateControllerDocumentWhereInput>>;
  NOT?: Maybe<Array<GateControllerDocumentWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  originalName?: Maybe<StringFilter>;
  gateController?: Maybe<GateControllerRelationFilter>;
  gateControllerId?: Maybe<StringNullableFilter>;
};

export type GateControllerDocumentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GateControllerGroupBy = {
  __typename?: 'GateControllerGroupBy';
  id: Scalars['String'];
  gateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateTypeId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerCountAggregate>;
  _avg?: Maybe<GateControllerAvgAggregate>;
  _sum?: Maybe<GateControllerSumAggregate>;
  _min?: Maybe<GateControllerMinAggregate>;
  _max?: Maybe<GateControllerMaxAggregate>;
};

export type GateControllerListRelationFilter = {
  every?: Maybe<GateControllerWhereInput>;
  some?: Maybe<GateControllerWhereInput>;
  none?: Maybe<GateControllerWhereInput>;
};

export type GateControllerMaxAggregate = {
  __typename?: 'GateControllerMaxAggregate';
  id?: Maybe<Scalars['String']>;
  gateId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateTypeId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type GateControllerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  contentBefore?: Maybe<SortOrder>;
  contentAfter?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  gateTypeId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type GateControllerMinAggregate = {
  __typename?: 'GateControllerMinAggregate';
  id?: Maybe<Scalars['String']>;
  gateId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  contentBefore?: Maybe<Scalars['String']>;
  contentAfter?: Maybe<Scalars['String']>;
  operatorCompanyName?: Maybe<Scalars['String']>;
  operatorCompanyPerson?: Maybe<Scalars['String']>;
  operatorCompanyPhone?: Maybe<Scalars['String']>;
  operatorCompanyEmail?: Maybe<Scalars['String']>;
  gateTypeId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type GateControllerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  contentBefore?: Maybe<SortOrder>;
  contentAfter?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  gateTypeId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type GateControllerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type GateControllerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  gateId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  contentBefore?: Maybe<SortOrder>;
  contentAfter?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  gateTypeId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerCountOrderByAggregateInput>;
  _avg?: Maybe<GateControllerAvgOrderByAggregateInput>;
  _max?: Maybe<GateControllerMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerMinOrderByAggregateInput>;
  _sum?: Maybe<GateControllerSumOrderByAggregateInput>;
};

export type GateControllerOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  gateId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  contentBefore?: Maybe<SortOrder>;
  contentAfter?: Maybe<SortOrder>;
  operatorCompanyName?: Maybe<SortOrder>;
  operatorCompanyPerson?: Maybe<SortOrder>;
  operatorCompanyPhone?: Maybe<SortOrder>;
  operatorCompanyEmail?: Maybe<SortOrder>;
  gateType?: Maybe<GateControllerTypeOrderByWithRelationInput>;
  gateTypeId?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
  documents?: Maybe<GateControllerDocumentOrderByRelationAggregateInput>;
  ruleSets?: Maybe<GateControllerRuleSetOrderByRelationAggregateInput>;
  transactions?: Maybe<GateControllerTransactionOrderByRelationAggregateInput>;
};

export type GateControllerRelUser = {
  __typename?: 'GateControllerRelUser';
  id: Scalars['String'];
  gateControllerRuleSetId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  gateControllerRuleSet: GateControllerRuleSet;
  user?: Maybe<User>;
};

export type GateControllerRelUserCountAggregate = {
  __typename?: 'GateControllerRelUserCountAggregate';
  id: Scalars['Int'];
  gateControllerRuleSetId: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerRelUserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type GateControllerRelUserCreateInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSet: GateControllerRuleSetCreateNestedOneWithoutUsersInput;
  user?: Maybe<UserCreateNestedOneWithoutGateControllerRelUsersInput>;
};

export type GateControllerRelUserCreateManyGateControllerRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type GateControllerRelUserCreateManyGateControllerRuleSetInputEnvelope =
  {
    data: Array<GateControllerRelUserCreateManyGateControllerRuleSetInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type GateControllerRelUserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type GateControllerRelUserCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId: Scalars['String'];
};

export type GateControllerRelUserCreateManyUserInputEnvelope = {
  data: Array<GateControllerRelUserCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerRelUserCreateNestedManyWithoutGateControllerRuleSetInput =
  {
    create?: Maybe<
      Array<GateControllerRelUserCreateWithoutGateControllerRuleSetInput>
    >;
    connectOrCreate?: Maybe<
      Array<GateControllerRelUserCreateOrConnectWithoutGateControllerRuleSetInput>
    >;
    createMany?: Maybe<GateControllerRelUserCreateManyGateControllerRuleSetInputEnvelope>;
    connect?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  };

export type GateControllerRelUserCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<GateControllerRelUserCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerRelUserCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<GateControllerRelUserCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
};

export type GateControllerRelUserCreateOrConnectWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelUserWhereUniqueInput;
    create: GateControllerRelUserCreateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRelUserCreateOrConnectWithoutUserInput = {
  where: GateControllerRelUserWhereUniqueInput;
  create: GateControllerRelUserCreateWithoutUserInput;
};

export type GateControllerRelUserCreateWithoutGateControllerRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutGateControllerRelUsersInput>;
};

export type GateControllerRelUserCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSet: GateControllerRuleSetCreateNestedOneWithoutUsersInput;
};

export type GateControllerRelUserGroupBy = {
  __typename?: 'GateControllerRelUserGroupBy';
  id: Scalars['String'];
  gateControllerRuleSetId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerRelUserCountAggregate>;
  _min?: Maybe<GateControllerRelUserMinAggregate>;
  _max?: Maybe<GateControllerRelUserMaxAggregate>;
};

export type GateControllerRelUserListRelationFilter = {
  every?: Maybe<GateControllerRelUserWhereInput>;
  some?: Maybe<GateControllerRelUserWhereInput>;
  none?: Maybe<GateControllerRelUserWhereInput>;
};

export type GateControllerRelUserMaxAggregate = {
  __typename?: 'GateControllerRelUserMaxAggregate';
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type GateControllerRelUserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type GateControllerRelUserMinAggregate = {
  __typename?: 'GateControllerRelUserMinAggregate';
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type GateControllerRelUserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type GateControllerRelUserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type GateControllerRelUserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerRelUserCountOrderByAggregateInput>;
  _max?: Maybe<GateControllerRelUserMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerRelUserMinOrderByAggregateInput>;
};

export type GateControllerRelUserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetOrderByWithRelationInput>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum GateControllerRelUserScalarFieldEnum {
  Id = 'id',
  GateControllerRuleSetId = 'gateControllerRuleSetId',
  UserId = 'userId',
}

export type GateControllerRelUserScalarWhereInput = {
  AND?: Maybe<Array<GateControllerRelUserScalarWhereInput>>;
  OR?: Maybe<Array<GateControllerRelUserScalarWhereInput>>;
  NOT?: Maybe<Array<GateControllerRelUserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  gateControllerRuleSetId?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type GateControllerRelUserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerRelUserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerRelUserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerRelUserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  gateControllerRuleSetId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type GateControllerRelUserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetUpdateOneRequiredWithoutUsersInput>;
  user?: Maybe<UserUpdateOneWithoutGateControllerRelUsersInput>;
};

export type GateControllerRelUserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type GateControllerRelUserUpdateManyWithWhereWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelUserScalarWhereInput;
    data: GateControllerRelUserUpdateManyMutationInput;
  };

export type GateControllerRelUserUpdateManyWithWhereWithoutUserInput = {
  where: GateControllerRelUserScalarWhereInput;
  data: GateControllerRelUserUpdateManyMutationInput;
};

export type GateControllerRelUserUpdateManyWithoutGateControllerRuleSetInput = {
  create?: Maybe<
    Array<GateControllerRelUserCreateWithoutGateControllerRuleSetInput>
  >;
  connectOrCreate?: Maybe<
    Array<GateControllerRelUserCreateOrConnectWithoutGateControllerRuleSetInput>
  >;
  upsert?: Maybe<
    Array<GateControllerRelUserUpsertWithWhereUniqueWithoutGateControllerRuleSetInput>
  >;
  createMany?: Maybe<GateControllerRelUserCreateManyGateControllerRuleSetInputEnvelope>;
  set?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerRelUserUpdateWithWhereUniqueWithoutGateControllerRuleSetInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerRelUserUpdateManyWithWhereWithoutGateControllerRuleSetInput>
  >;
  deleteMany?: Maybe<Array<GateControllerRelUserScalarWhereInput>>;
};

export type GateControllerRelUserUpdateManyWithoutUserInput = {
  create?: Maybe<Array<GateControllerRelUserCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerRelUserCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<GateControllerRelUserUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<GateControllerRelUserCreateManyUserInputEnvelope>;
  set?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerRelUserWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerRelUserUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerRelUserUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<GateControllerRelUserScalarWhereInput>>;
};

export type GateControllerRelUserUpdateWithWhereUniqueWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelUserWhereUniqueInput;
    data: GateControllerRelUserUpdateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRelUserUpdateWithWhereUniqueWithoutUserInput = {
  where: GateControllerRelUserWhereUniqueInput;
  data: GateControllerRelUserUpdateWithoutUserInput;
};

export type GateControllerRelUserUpdateWithoutGateControllerRuleSetInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutGateControllerRelUsersInput>;
};

export type GateControllerRelUserUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetUpdateOneRequiredWithoutUsersInput>;
};

export type GateControllerRelUserUpsertWithWhereUniqueWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelUserWhereUniqueInput;
    update: GateControllerRelUserUpdateWithoutGateControllerRuleSetInput;
    create: GateControllerRelUserCreateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRelUserUpsertWithWhereUniqueWithoutUserInput = {
  where: GateControllerRelUserWhereUniqueInput;
  update: GateControllerRelUserUpdateWithoutUserInput;
  create: GateControllerRelUserCreateWithoutUserInput;
};

export type GateControllerRelUserWhereInput = {
  AND?: Maybe<Array<GateControllerRelUserWhereInput>>;
  OR?: Maybe<Array<GateControllerRelUserWhereInput>>;
  NOT?: Maybe<Array<GateControllerRelUserWhereInput>>;
  id?: Maybe<StringFilter>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetRelationFilter>;
  gateControllerRuleSetId?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type GateControllerRelUserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GateControllerRelVehicle = {
  __typename?: 'GateControllerRelVehicle';
  id: Scalars['String'];
  gateControllerRuleSetId: Scalars['String'];
  vehicleId?: Maybe<Scalars['String']>;
  gateControllerRuleSet: GateControllerRuleSet;
  vehicle?: Maybe<Vehicle>;
};

export type GateControllerRelVehicleCountAggregate = {
  __typename?: 'GateControllerRelVehicleCountAggregate';
  id: Scalars['Int'];
  gateControllerRuleSetId: Scalars['Int'];
  vehicleId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerRelVehicleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type GateControllerRelVehicleCreateInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSet: GateControllerRuleSetCreateNestedOneWithoutVehiclesInput;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutGateControllerRelVehiclesInput>;
};

export type GateControllerRelVehicleCreateManyGateControllerRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type GateControllerRelVehicleCreateManyGateControllerRuleSetInputEnvelope =
  {
    data: Array<GateControllerRelVehicleCreateManyGateControllerRuleSetInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type GateControllerRelVehicleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId: Scalars['String'];
  vehicleId?: Maybe<Scalars['String']>;
};

export type GateControllerRelVehicleCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId: Scalars['String'];
};

export type GateControllerRelVehicleCreateManyVehicleInputEnvelope = {
  data: Array<GateControllerRelVehicleCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerRelVehicleCreateNestedManyWithoutGateControllerRuleSetInput =
  {
    create?: Maybe<
      Array<GateControllerRelVehicleCreateWithoutGateControllerRuleSetInput>
    >;
    connectOrCreate?: Maybe<
      Array<GateControllerRelVehicleCreateOrConnectWithoutGateControllerRuleSetInput>
    >;
    createMany?: Maybe<GateControllerRelVehicleCreateManyGateControllerRuleSetInputEnvelope>;
    connect?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
  };

export type GateControllerRelVehicleCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<GateControllerRelVehicleCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerRelVehicleCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<GateControllerRelVehicleCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
};

export type GateControllerRelVehicleCreateOrConnectWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelVehicleWhereUniqueInput;
    create: GateControllerRelVehicleCreateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRelVehicleCreateOrConnectWithoutVehicleInput = {
  where: GateControllerRelVehicleWhereUniqueInput;
  create: GateControllerRelVehicleCreateWithoutVehicleInput;
};

export type GateControllerRelVehicleCreateWithoutGateControllerRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutGateControllerRelVehiclesInput>;
};

export type GateControllerRelVehicleCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSet: GateControllerRuleSetCreateNestedOneWithoutVehiclesInput;
};

export type GateControllerRelVehicleGroupBy = {
  __typename?: 'GateControllerRelVehicleGroupBy';
  id: Scalars['String'];
  gateControllerRuleSetId: Scalars['String'];
  vehicleId?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerRelVehicleCountAggregate>;
  _min?: Maybe<GateControllerRelVehicleMinAggregate>;
  _max?: Maybe<GateControllerRelVehicleMaxAggregate>;
};

export type GateControllerRelVehicleListRelationFilter = {
  every?: Maybe<GateControllerRelVehicleWhereInput>;
  some?: Maybe<GateControllerRelVehicleWhereInput>;
  none?: Maybe<GateControllerRelVehicleWhereInput>;
};

export type GateControllerRelVehicleMaxAggregate = {
  __typename?: 'GateControllerRelVehicleMaxAggregate';
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type GateControllerRelVehicleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type GateControllerRelVehicleMinAggregate = {
  __typename?: 'GateControllerRelVehicleMinAggregate';
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type GateControllerRelVehicleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type GateControllerRelVehicleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type GateControllerRelVehicleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerRelVehicleCountOrderByAggregateInput>;
  _max?: Maybe<GateControllerRelVehicleMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerRelVehicleMinOrderByAggregateInput>;
};

export type GateControllerRelVehicleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetOrderByWithRelationInput>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
};

export enum GateControllerRelVehicleScalarFieldEnum {
  Id = 'id',
  GateControllerRuleSetId = 'gateControllerRuleSetId',
  VehicleId = 'vehicleId',
}

export type GateControllerRelVehicleScalarWhereInput = {
  AND?: Maybe<Array<GateControllerRelVehicleScalarWhereInput>>;
  OR?: Maybe<Array<GateControllerRelVehicleScalarWhereInput>>;
  NOT?: Maybe<Array<GateControllerRelVehicleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  gateControllerRuleSetId?: Maybe<StringFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
};

export type GateControllerRelVehicleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerRelVehicleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerRelVehicleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerRelVehicleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  gateControllerRuleSetId?: Maybe<StringWithAggregatesFilter>;
  vehicleId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type GateControllerRelVehicleUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetUpdateOneRequiredWithoutVehiclesInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutGateControllerRelVehiclesInput>;
};

export type GateControllerRelVehicleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type GateControllerRelVehicleUpdateManyWithWhereWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelVehicleScalarWhereInput;
    data: GateControllerRelVehicleUpdateManyMutationInput;
  };

export type GateControllerRelVehicleUpdateManyWithWhereWithoutVehicleInput = {
  where: GateControllerRelVehicleScalarWhereInput;
  data: GateControllerRelVehicleUpdateManyMutationInput;
};

export type GateControllerRelVehicleUpdateManyWithoutGateControllerRuleSetInput =
  {
    create?: Maybe<
      Array<GateControllerRelVehicleCreateWithoutGateControllerRuleSetInput>
    >;
    connectOrCreate?: Maybe<
      Array<GateControllerRelVehicleCreateOrConnectWithoutGateControllerRuleSetInput>
    >;
    upsert?: Maybe<
      Array<GateControllerRelVehicleUpsertWithWhereUniqueWithoutGateControllerRuleSetInput>
    >;
    createMany?: Maybe<GateControllerRelVehicleCreateManyGateControllerRuleSetInputEnvelope>;
    set?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
    disconnect?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
    delete?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
    connect?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
    update?: Maybe<
      Array<GateControllerRelVehicleUpdateWithWhereUniqueWithoutGateControllerRuleSetInput>
    >;
    updateMany?: Maybe<
      Array<GateControllerRelVehicleUpdateManyWithWhereWithoutGateControllerRuleSetInput>
    >;
    deleteMany?: Maybe<Array<GateControllerRelVehicleScalarWhereInput>>;
  };

export type GateControllerRelVehicleUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<GateControllerRelVehicleCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerRelVehicleCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<GateControllerRelVehicleUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<GateControllerRelVehicleCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerRelVehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerRelVehicleUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerRelVehicleUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<GateControllerRelVehicleScalarWhereInput>>;
};

export type GateControllerRelVehicleUpdateWithWhereUniqueWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelVehicleWhereUniqueInput;
    data: GateControllerRelVehicleUpdateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRelVehicleUpdateWithWhereUniqueWithoutVehicleInput = {
  where: GateControllerRelVehicleWhereUniqueInput;
  data: GateControllerRelVehicleUpdateWithoutVehicleInput;
};

export type GateControllerRelVehicleUpdateWithoutGateControllerRuleSetInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutGateControllerRelVehiclesInput>;
};

export type GateControllerRelVehicleUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetUpdateOneRequiredWithoutVehiclesInput>;
};

export type GateControllerRelVehicleUpsertWithWhereUniqueWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRelVehicleWhereUniqueInput;
    update: GateControllerRelVehicleUpdateWithoutGateControllerRuleSetInput;
    create: GateControllerRelVehicleCreateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRelVehicleUpsertWithWhereUniqueWithoutVehicleInput = {
  where: GateControllerRelVehicleWhereUniqueInput;
  update: GateControllerRelVehicleUpdateWithoutVehicleInput;
  create: GateControllerRelVehicleCreateWithoutVehicleInput;
};

export type GateControllerRelVehicleWhereInput = {
  AND?: Maybe<Array<GateControllerRelVehicleWhereInput>>;
  OR?: Maybe<Array<GateControllerRelVehicleWhereInput>>;
  NOT?: Maybe<Array<GateControllerRelVehicleWhereInput>>;
  id?: Maybe<StringFilter>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetRelationFilter>;
  gateControllerRuleSetId?: Maybe<StringFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
};

export type GateControllerRelVehicleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GateControllerRelationFilter = {
  is?: Maybe<GateControllerWhereInput>;
  isNot?: Maybe<GateControllerWhereInput>;
};

export type GateControllerRule = {
  __typename?: 'GateControllerRule';
  id: Scalars['String'];
  gateControllerRuleSetId: Scalars['String'];
  property: GateControllerRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
  gateControllerRuleSet: GateControllerRuleSet;
};

export type GateControllerRuleAvgAggregate = {
  __typename?: 'GateControllerRuleAvgAggregate';
  fromValue?: Maybe<Scalars['Float']>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleAvgOrderByAggregateInput = {
  fromValue?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type GateControllerRuleCountAggregate = {
  __typename?: 'GateControllerRuleCountAggregate';
  id: Scalars['Int'];
  gateControllerRuleSetId: Scalars['Int'];
  property: Scalars['Int'];
  fromCompareOperator: Scalars['Int'];
  fromValue: Scalars['Int'];
  toCompareOperator: Scalars['Int'];
  toValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerRuleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type GateControllerRuleCreateInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSet: GateControllerRuleSetCreateNestedOneWithoutRulesInput;
  property: GateControllerRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleCreateManyGateControllerRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  property: GateControllerRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleCreateManyGateControllerRuleSetInputEnvelope = {
  data: Array<GateControllerRuleCreateManyGateControllerRuleSetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerRuleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId: Scalars['String'];
  property: GateControllerRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleCreateNestedManyWithoutGateControllerRuleSetInput =
  {
    create?: Maybe<
      Array<GateControllerRuleCreateWithoutGateControllerRuleSetInput>
    >;
    connectOrCreate?: Maybe<
      Array<GateControllerRuleCreateOrConnectWithoutGateControllerRuleSetInput>
    >;
    createMany?: Maybe<GateControllerRuleCreateManyGateControllerRuleSetInputEnvelope>;
    connect?: Maybe<Array<GateControllerRuleWhereUniqueInput>>;
  };

export type GateControllerRuleCreateOrConnectWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRuleWhereUniqueInput;
    create: GateControllerRuleCreateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRuleCreateWithoutGateControllerRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  property: GateControllerRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleGroupBy = {
  __typename?: 'GateControllerRuleGroupBy';
  id: Scalars['String'];
  gateControllerRuleSetId: Scalars['String'];
  property: GateControllerRuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
  _count?: Maybe<GateControllerRuleCountAggregate>;
  _avg?: Maybe<GateControllerRuleAvgAggregate>;
  _sum?: Maybe<GateControllerRuleSumAggregate>;
  _min?: Maybe<GateControllerRuleMinAggregate>;
  _max?: Maybe<GateControllerRuleMaxAggregate>;
};

export type GateControllerRuleListRelationFilter = {
  every?: Maybe<GateControllerRuleWhereInput>;
  some?: Maybe<GateControllerRuleWhereInput>;
  none?: Maybe<GateControllerRuleWhereInput>;
};

export type GateControllerRuleMaxAggregate = {
  __typename?: 'GateControllerRuleMaxAggregate';
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId?: Maybe<Scalars['String']>;
  property?: Maybe<GateControllerRuleProperty>;
  fromCompareOperator?: Maybe<RuleCompareOperator>;
  fromValue?: Maybe<Scalars['Float']>;
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type GateControllerRuleMinAggregate = {
  __typename?: 'GateControllerRuleMinAggregate';
  id?: Maybe<Scalars['String']>;
  gateControllerRuleSetId?: Maybe<Scalars['String']>;
  property?: Maybe<GateControllerRuleProperty>;
  fromCompareOperator?: Maybe<RuleCompareOperator>;
  fromValue?: Maybe<Scalars['Float']>;
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type GateControllerRuleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type GateControllerRuleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerRuleCountOrderByAggregateInput>;
  _avg?: Maybe<GateControllerRuleAvgOrderByAggregateInput>;
  _max?: Maybe<GateControllerRuleMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerRuleMinOrderByAggregateInput>;
  _sum?: Maybe<GateControllerRuleSumOrderByAggregateInput>;
};

export type GateControllerRuleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetOrderByWithRelationInput>;
  gateControllerRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export enum GateControllerRuleProperty {
  TimeRange = 'TIME_RANGE',
  BeforeBooking = 'BEFORE_BOOKING',
  AfterBooking = 'AFTER_BOOKING',
}

export enum GateControllerRuleScalarFieldEnum {
  Id = 'id',
  GateControllerRuleSetId = 'gateControllerRuleSetId',
  Property = 'property',
  FromCompareOperator = 'fromCompareOperator',
  FromValue = 'fromValue',
  ToCompareOperator = 'toCompareOperator',
  ToValue = 'toValue',
}

export type GateControllerRuleScalarWhereInput = {
  AND?: Maybe<Array<GateControllerRuleScalarWhereInput>>;
  OR?: Maybe<Array<GateControllerRuleScalarWhereInput>>;
  NOT?: Maybe<Array<GateControllerRuleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  gateControllerRuleSetId?: Maybe<StringFilter>;
  property?: Maybe<EnumGateControllerRulePropertyFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFilter>;
  fromValue?: Maybe<FloatFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableFilter>;
  toValue?: Maybe<FloatNullableFilter>;
};

export type GateControllerRuleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerRuleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerRuleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerRuleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  gateControllerRuleSetId?: Maybe<StringWithAggregatesFilter>;
  property?: Maybe<EnumGateControllerRulePropertyWithAggregatesFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorWithAggregatesFilter>;
  fromValue?: Maybe<FloatWithAggregatesFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableWithAggregatesFilter>;
  toValue?: Maybe<FloatNullableWithAggregatesFilter>;
};

export type GateControllerRuleSet = {
  __typename?: 'GateControllerRuleSet';
  id: Scalars['String'];
  ruleSetType: GateControllerRuleSetType;
  gateControllerId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  _count?: Maybe<GateControllerRuleSetCount>;
  gateController?: Maybe<GateController>;
  users: Array<GateControllerRelUser>;
  vehicles: Array<GateControllerRelVehicle>;
  rules: Array<GateControllerRule>;
};

export type GateControllerRuleSetUsersArgs = {
  where?: Maybe<GateControllerRelUserWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelUserScalarFieldEnum>>;
};

export type GateControllerRuleSetVehiclesArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelVehicleScalarFieldEnum>>;
};

export type GateControllerRuleSetRulesArgs = {
  where?: Maybe<GateControllerRuleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRuleScalarFieldEnum>>;
};

export type GateControllerRuleSetCount = {
  __typename?: 'GateControllerRuleSetCount';
  users: Scalars['Int'];
  vehicles: Scalars['Int'];
  rules: Scalars['Int'];
};

export type GateControllerRuleSetCountAggregate = {
  __typename?: 'GateControllerRuleSetCountAggregate';
  id: Scalars['Int'];
  ruleSetType: Scalars['Int'];
  gateControllerId: Scalars['Int'];
  deletedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerRuleSetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  ruleSetType?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type GateControllerRuleSetCreateInput = {
  id?: Maybe<Scalars['String']>;
  ruleSetType: GateControllerRuleSetType;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<GateControllerRelUserCreateNestedManyWithoutGateControllerRuleSetInput>;
  vehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleCreateNestedManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetCreateManyGateControllerInput = {
  id?: Maybe<Scalars['String']>;
  ruleSetType: GateControllerRuleSetType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GateControllerRuleSetCreateManyGateControllerInputEnvelope = {
  data: Array<GateControllerRuleSetCreateManyGateControllerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerRuleSetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  ruleSetType: GateControllerRuleSetType;
  gateControllerId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GateControllerRuleSetCreateNestedManyWithoutGateControllerInput = {
  create?: Maybe<Array<GateControllerRuleSetCreateWithoutGateControllerInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerRuleSetCreateOrConnectWithoutGateControllerInput>
  >;
  createMany?: Maybe<GateControllerRuleSetCreateManyGateControllerInputEnvelope>;
  connect?: Maybe<Array<GateControllerRuleSetWhereUniqueInput>>;
};

export type GateControllerRuleSetCreateNestedOneWithoutRulesInput = {
  create?: Maybe<GateControllerRuleSetCreateWithoutRulesInput>;
  connectOrCreate?: Maybe<GateControllerRuleSetCreateOrConnectWithoutRulesInput>;
  connect?: Maybe<GateControllerRuleSetWhereUniqueInput>;
};

export type GateControllerRuleSetCreateNestedOneWithoutUsersInput = {
  create?: Maybe<GateControllerRuleSetCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<GateControllerRuleSetCreateOrConnectWithoutUsersInput>;
  connect?: Maybe<GateControllerRuleSetWhereUniqueInput>;
};

export type GateControllerRuleSetCreateNestedOneWithoutVehiclesInput = {
  create?: Maybe<GateControllerRuleSetCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<GateControllerRuleSetCreateOrConnectWithoutVehiclesInput>;
  connect?: Maybe<GateControllerRuleSetWhereUniqueInput>;
};

export type GateControllerRuleSetCreateOrConnectWithoutGateControllerInput = {
  where: GateControllerRuleSetWhereUniqueInput;
  create: GateControllerRuleSetCreateWithoutGateControllerInput;
};

export type GateControllerRuleSetCreateOrConnectWithoutRulesInput = {
  where: GateControllerRuleSetWhereUniqueInput;
  create: GateControllerRuleSetCreateWithoutRulesInput;
};

export type GateControllerRuleSetCreateOrConnectWithoutUsersInput = {
  where: GateControllerRuleSetWhereUniqueInput;
  create: GateControllerRuleSetCreateWithoutUsersInput;
};

export type GateControllerRuleSetCreateOrConnectWithoutVehiclesInput = {
  where: GateControllerRuleSetWhereUniqueInput;
  create: GateControllerRuleSetCreateWithoutVehiclesInput;
};

export type GateControllerRuleSetCreateWithoutGateControllerInput = {
  id?: Maybe<Scalars['String']>;
  ruleSetType: GateControllerRuleSetType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<GateControllerRelUserCreateNestedManyWithoutGateControllerRuleSetInput>;
  vehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleCreateNestedManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetCreateWithoutRulesInput = {
  id?: Maybe<Scalars['String']>;
  ruleSetType: GateControllerRuleSetType;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<GateControllerRelUserCreateNestedManyWithoutGateControllerRuleSetInput>;
  vehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  ruleSetType: GateControllerRuleSetType;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleCreateNestedManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetCreateWithoutVehiclesInput = {
  id?: Maybe<Scalars['String']>;
  ruleSetType: GateControllerRuleSetType;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<GateControllerRelUserCreateNestedManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleCreateNestedManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetGroupBy = {
  __typename?: 'GateControllerRuleSetGroupBy';
  id: Scalars['String'];
  ruleSetType: GateControllerRuleSetType;
  gateControllerId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  _count?: Maybe<GateControllerRuleSetCountAggregate>;
  _min?: Maybe<GateControllerRuleSetMinAggregate>;
  _max?: Maybe<GateControllerRuleSetMaxAggregate>;
};

export type GateControllerRuleSetListRelationFilter = {
  every?: Maybe<GateControllerRuleSetWhereInput>;
  some?: Maybe<GateControllerRuleSetWhereInput>;
  none?: Maybe<GateControllerRuleSetWhereInput>;
};

export type GateControllerRuleSetMaxAggregate = {
  __typename?: 'GateControllerRuleSetMaxAggregate';
  id?: Maybe<Scalars['String']>;
  ruleSetType?: Maybe<GateControllerRuleSetType>;
  gateControllerId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GateControllerRuleSetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  ruleSetType?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type GateControllerRuleSetMinAggregate = {
  __typename?: 'GateControllerRuleSetMinAggregate';
  id?: Maybe<Scalars['String']>;
  ruleSetType?: Maybe<GateControllerRuleSetType>;
  gateControllerId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GateControllerRuleSetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  ruleSetType?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type GateControllerRuleSetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type GateControllerRuleSetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  ruleSetType?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerRuleSetCountOrderByAggregateInput>;
  _max?: Maybe<GateControllerRuleSetMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerRuleSetMinOrderByAggregateInput>;
};

export type GateControllerRuleSetOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  ruleSetType?: Maybe<SortOrder>;
  gateController?: Maybe<GateControllerOrderByWithRelationInput>;
  gateControllerId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  users?: Maybe<GateControllerRelUserOrderByRelationAggregateInput>;
  vehicles?: Maybe<GateControllerRelVehicleOrderByRelationAggregateInput>;
  rules?: Maybe<GateControllerRuleOrderByRelationAggregateInput>;
};

export type GateControllerRuleSetRelationFilter = {
  is?: Maybe<GateControllerRuleSetWhereInput>;
  isNot?: Maybe<GateControllerRuleSetWhereInput>;
};

export enum GateControllerRuleSetScalarFieldEnum {
  Id = 'id',
  RuleSetType = 'ruleSetType',
  GateControllerId = 'gateControllerId',
  DeletedAt = 'deletedAt',
  CreatedAt = 'createdAt',
}

export type GateControllerRuleSetScalarWhereInput = {
  AND?: Maybe<Array<GateControllerRuleSetScalarWhereInput>>;
  OR?: Maybe<Array<GateControllerRuleSetScalarWhereInput>>;
  NOT?: Maybe<Array<GateControllerRuleSetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFilter>;
  gateControllerId?: Maybe<StringNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type GateControllerRuleSetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerRuleSetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerRuleSetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerRuleSetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeWithAggregatesFilter>;
  gateControllerId?: Maybe<StringNullableWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum GateControllerRuleSetType {
  Booking = 'BOOKING',
  UserAccess = 'USER_ACCESS',
}

export type GateControllerRuleSetUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<GateControllerRelUserUpdateManyWithoutGateControllerRuleSetInput>;
  vehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleUpdateManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GateControllerRuleSetUpdateManyWithWhereWithoutGateControllerInput =
  {
    where: GateControllerRuleSetScalarWhereInput;
    data: GateControllerRuleSetUpdateManyMutationInput;
  };

export type GateControllerRuleSetUpdateManyWithoutGateControllerInput = {
  create?: Maybe<Array<GateControllerRuleSetCreateWithoutGateControllerInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerRuleSetCreateOrConnectWithoutGateControllerInput>
  >;
  upsert?: Maybe<
    Array<GateControllerRuleSetUpsertWithWhereUniqueWithoutGateControllerInput>
  >;
  createMany?: Maybe<GateControllerRuleSetCreateManyGateControllerInputEnvelope>;
  set?: Maybe<Array<GateControllerRuleSetWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerRuleSetWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerRuleSetWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerRuleSetWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerRuleSetUpdateWithWhereUniqueWithoutGateControllerInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerRuleSetUpdateManyWithWhereWithoutGateControllerInput>
  >;
  deleteMany?: Maybe<Array<GateControllerRuleSetScalarWhereInput>>;
};

export type GateControllerRuleSetUpdateOneRequiredWithoutRulesInput = {
  create?: Maybe<GateControllerRuleSetCreateWithoutRulesInput>;
  connectOrCreate?: Maybe<GateControllerRuleSetCreateOrConnectWithoutRulesInput>;
  upsert?: Maybe<GateControllerRuleSetUpsertWithoutRulesInput>;
  connect?: Maybe<GateControllerRuleSetWhereUniqueInput>;
  update?: Maybe<GateControllerRuleSetUpdateWithoutRulesInput>;
};

export type GateControllerRuleSetUpdateOneRequiredWithoutUsersInput = {
  create?: Maybe<GateControllerRuleSetCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<GateControllerRuleSetCreateOrConnectWithoutUsersInput>;
  upsert?: Maybe<GateControllerRuleSetUpsertWithoutUsersInput>;
  connect?: Maybe<GateControllerRuleSetWhereUniqueInput>;
  update?: Maybe<GateControllerRuleSetUpdateWithoutUsersInput>;
};

export type GateControllerRuleSetUpdateOneRequiredWithoutVehiclesInput = {
  create?: Maybe<GateControllerRuleSetCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<GateControllerRuleSetCreateOrConnectWithoutVehiclesInput>;
  upsert?: Maybe<GateControllerRuleSetUpsertWithoutVehiclesInput>;
  connect?: Maybe<GateControllerRuleSetWhereUniqueInput>;
  update?: Maybe<GateControllerRuleSetUpdateWithoutVehiclesInput>;
};

export type GateControllerRuleSetUpdateWithWhereUniqueWithoutGateControllerInput =
  {
    where: GateControllerRuleSetWhereUniqueInput;
    data: GateControllerRuleSetUpdateWithoutGateControllerInput;
  };

export type GateControllerRuleSetUpdateWithoutGateControllerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<GateControllerRelUserUpdateManyWithoutGateControllerRuleSetInput>;
  vehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleUpdateManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetUpdateWithoutRulesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<GateControllerRelUserUpdateManyWithoutGateControllerRuleSetInput>;
  vehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetUpdateWithoutUsersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleUpdateManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetUpdateWithoutVehiclesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutRuleSetsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<GateControllerRelUserUpdateManyWithoutGateControllerRuleSetInput>;
  rules?: Maybe<GateControllerRuleUpdateManyWithoutGateControllerRuleSetInput>;
};

export type GateControllerRuleSetUpsertWithWhereUniqueWithoutGateControllerInput =
  {
    where: GateControllerRuleSetWhereUniqueInput;
    update: GateControllerRuleSetUpdateWithoutGateControllerInput;
    create: GateControllerRuleSetCreateWithoutGateControllerInput;
  };

export type GateControllerRuleSetUpsertWithoutRulesInput = {
  update: GateControllerRuleSetUpdateWithoutRulesInput;
  create: GateControllerRuleSetCreateWithoutRulesInput;
};

export type GateControllerRuleSetUpsertWithoutUsersInput = {
  update: GateControllerRuleSetUpdateWithoutUsersInput;
  create: GateControllerRuleSetCreateWithoutUsersInput;
};

export type GateControllerRuleSetUpsertWithoutVehiclesInput = {
  update: GateControllerRuleSetUpdateWithoutVehiclesInput;
  create: GateControllerRuleSetCreateWithoutVehiclesInput;
};

export type GateControllerRuleSetWhereInput = {
  AND?: Maybe<Array<GateControllerRuleSetWhereInput>>;
  OR?: Maybe<Array<GateControllerRuleSetWhereInput>>;
  NOT?: Maybe<Array<GateControllerRuleSetWhereInput>>;
  id?: Maybe<StringFilter>;
  ruleSetType?: Maybe<EnumGateControllerRuleSetTypeFilter>;
  gateController?: Maybe<GateControllerRelationFilter>;
  gateControllerId?: Maybe<StringNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  users?: Maybe<GateControllerRelUserListRelationFilter>;
  vehicles?: Maybe<GateControllerRelVehicleListRelationFilter>;
  rules?: Maybe<GateControllerRuleListRelationFilter>;
};

export type GateControllerRuleSetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GateControllerRuleSumAggregate = {
  __typename?: 'GateControllerRuleSumAggregate';
  fromValue?: Maybe<Scalars['Float']>;
  toValue?: Maybe<Scalars['Float']>;
};

export type GateControllerRuleSumOrderByAggregateInput = {
  fromValue?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type GateControllerRuleUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetUpdateOneRequiredWithoutRulesInput>;
  property?: Maybe<EnumGateControllerRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type GateControllerRuleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<EnumGateControllerRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type GateControllerRuleUpdateManyWithWhereWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRuleScalarWhereInput;
    data: GateControllerRuleUpdateManyMutationInput;
  };

export type GateControllerRuleUpdateManyWithoutGateControllerRuleSetInput = {
  create?: Maybe<
    Array<GateControllerRuleCreateWithoutGateControllerRuleSetInput>
  >;
  connectOrCreate?: Maybe<
    Array<GateControllerRuleCreateOrConnectWithoutGateControllerRuleSetInput>
  >;
  upsert?: Maybe<
    Array<GateControllerRuleUpsertWithWhereUniqueWithoutGateControllerRuleSetInput>
  >;
  createMany?: Maybe<GateControllerRuleCreateManyGateControllerRuleSetInputEnvelope>;
  set?: Maybe<Array<GateControllerRuleWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerRuleWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerRuleWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerRuleWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerRuleUpdateWithWhereUniqueWithoutGateControllerRuleSetInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerRuleUpdateManyWithWhereWithoutGateControllerRuleSetInput>
  >;
  deleteMany?: Maybe<Array<GateControllerRuleScalarWhereInput>>;
};

export type GateControllerRuleUpdateWithWhereUniqueWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRuleWhereUniqueInput;
    data: GateControllerRuleUpdateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRuleUpdateWithoutGateControllerRuleSetInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<EnumGateControllerRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type GateControllerRuleUpsertWithWhereUniqueWithoutGateControllerRuleSetInput =
  {
    where: GateControllerRuleWhereUniqueInput;
    update: GateControllerRuleUpdateWithoutGateControllerRuleSetInput;
    create: GateControllerRuleCreateWithoutGateControllerRuleSetInput;
  };

export type GateControllerRuleWhereInput = {
  AND?: Maybe<Array<GateControllerRuleWhereInput>>;
  OR?: Maybe<Array<GateControllerRuleWhereInput>>;
  NOT?: Maybe<Array<GateControllerRuleWhereInput>>;
  id?: Maybe<StringFilter>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSetRelationFilter>;
  gateControllerRuleSetId?: Maybe<StringFilter>;
  property?: Maybe<EnumGateControllerRulePropertyFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFilter>;
  fromValue?: Maybe<FloatFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableFilter>;
  toValue?: Maybe<FloatNullableFilter>;
};

export type GateControllerRuleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum GateControllerScalarFieldEnum {
  Id = 'id',
  GateId = 'gateId',
  CreatedAt = 'createdAt',
  Latitude = 'latitude',
  Longitude = 'longitude',
  ContentBefore = 'contentBefore',
  ContentAfter = 'contentAfter',
  OperatorCompanyName = 'operatorCompanyName',
  OperatorCompanyPerson = 'operatorCompanyPerson',
  OperatorCompanyPhone = 'operatorCompanyPhone',
  OperatorCompanyEmail = 'operatorCompanyEmail',
  GateTypeId = 'gateTypeId',
  CompanyId = 'companyId',
}

export type GateControllerScalarWhereInput = {
  AND?: Maybe<Array<GateControllerScalarWhereInput>>;
  OR?: Maybe<Array<GateControllerScalarWhereInput>>;
  NOT?: Maybe<Array<GateControllerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  gateId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  contentBefore?: Maybe<StringNullableFilter>;
  contentAfter?: Maybe<StringNullableFilter>;
  operatorCompanyName?: Maybe<StringNullableFilter>;
  operatorCompanyPerson?: Maybe<StringNullableFilter>;
  operatorCompanyPhone?: Maybe<StringNullableFilter>;
  operatorCompanyEmail?: Maybe<StringNullableFilter>;
  gateTypeId?: Maybe<StringNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type GateControllerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  gateId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  latitude?: Maybe<FloatNullableWithAggregatesFilter>;
  longitude?: Maybe<FloatNullableWithAggregatesFilter>;
  contentBefore?: Maybe<StringNullableWithAggregatesFilter>;
  contentAfter?: Maybe<StringNullableWithAggregatesFilter>;
  operatorCompanyName?: Maybe<StringNullableWithAggregatesFilter>;
  operatorCompanyPerson?: Maybe<StringNullableWithAggregatesFilter>;
  operatorCompanyPhone?: Maybe<StringNullableWithAggregatesFilter>;
  operatorCompanyEmail?: Maybe<StringNullableWithAggregatesFilter>;
  gateTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type GateControllerSumAggregate = {
  __typename?: 'GateControllerSumAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GateControllerSumOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
};

export type GateControllerTransaction = {
  __typename?: 'GateControllerTransaction';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  gateController?: Maybe<GateController>;
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
  booking?: Maybe<Booking>;
};

export type GateControllerTransactionCountAggregate = {
  __typename?: 'GateControllerTransactionCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  comment: Scalars['Int'];
  gateControllerId: Scalars['Int'];
  userId: Scalars['Int'];
  vehicleId: Scalars['Int'];
  bookingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerTransactionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type GateControllerTransactionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutTransactionsInput>;
  user?: Maybe<UserCreateNestedOneWithoutGateControllerTransactionsInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type GateControllerTransactionCreateManyBookingInputEnvelope = {
  data: Array<GateControllerTransactionCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerTransactionCreateManyGateControllerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type GateControllerTransactionCreateManyGateControllerInputEnvelope = {
  data: Array<GateControllerTransactionCreateManyGateControllerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerTransactionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type GateControllerTransactionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type GateControllerTransactionCreateManyUserInputEnvelope = {
  data: Array<GateControllerTransactionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerTransactionCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type GateControllerTransactionCreateManyVehicleInputEnvelope = {
  data: Array<GateControllerTransactionCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GateControllerTransactionCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<GateControllerTransactionCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerTransactionCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<GateControllerTransactionCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
};

export type GateControllerTransactionCreateNestedManyWithoutGateControllerInput =
  {
    create?: Maybe<
      Array<GateControllerTransactionCreateWithoutGateControllerInput>
    >;
    connectOrCreate?: Maybe<
      Array<GateControllerTransactionCreateOrConnectWithoutGateControllerInput>
    >;
    createMany?: Maybe<GateControllerTransactionCreateManyGateControllerInputEnvelope>;
    connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  };

export type GateControllerTransactionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<GateControllerTransactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerTransactionCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<GateControllerTransactionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
};

export type GateControllerTransactionCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<GateControllerTransactionCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerTransactionCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<GateControllerTransactionCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
};

export type GateControllerTransactionCreateOrConnectWithoutBookingInput = {
  where: GateControllerTransactionWhereUniqueInput;
  create: GateControllerTransactionCreateWithoutBookingInput;
};

export type GateControllerTransactionCreateOrConnectWithoutGateControllerInput =
  {
    where: GateControllerTransactionWhereUniqueInput;
    create: GateControllerTransactionCreateWithoutGateControllerInput;
  };

export type GateControllerTransactionCreateOrConnectWithoutUserInput = {
  where: GateControllerTransactionWhereUniqueInput;
  create: GateControllerTransactionCreateWithoutUserInput;
};

export type GateControllerTransactionCreateOrConnectWithoutVehicleInput = {
  where: GateControllerTransactionWhereUniqueInput;
  create: GateControllerTransactionCreateWithoutVehicleInput;
};

export type GateControllerTransactionCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutTransactionsInput>;
  user?: Maybe<UserCreateNestedOneWithoutGateControllerTransactionsInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionCreateWithoutGateControllerInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutGateControllerTransactionsInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutTransactionsInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateController?: Maybe<GateControllerCreateNestedOneWithoutTransactionsInput>;
  user?: Maybe<UserCreateNestedOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionGroupBy = {
  __typename?: 'GateControllerTransactionGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerTransactionCountAggregate>;
  _min?: Maybe<GateControllerTransactionMinAggregate>;
  _max?: Maybe<GateControllerTransactionMaxAggregate>;
};

export type GateControllerTransactionListRelationFilter = {
  every?: Maybe<GateControllerTransactionWhereInput>;
  some?: Maybe<GateControllerTransactionWhereInput>;
  none?: Maybe<GateControllerTransactionWhereInput>;
};

export type GateControllerTransactionMaxAggregate = {
  __typename?: 'GateControllerTransactionMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type GateControllerTransactionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type GateControllerTransactionMinAggregate = {
  __typename?: 'GateControllerTransactionMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  gateControllerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type GateControllerTransactionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type GateControllerTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type GateControllerTransactionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  gateControllerId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerTransactionCountOrderByAggregateInput>;
  _max?: Maybe<GateControllerTransactionMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerTransactionMinOrderByAggregateInput>;
};

export type GateControllerTransactionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  gateController?: Maybe<GateControllerOrderByWithRelationInput>;
  gateControllerId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
};

export enum GateControllerTransactionScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Comment = 'comment',
  GateControllerId = 'gateControllerId',
  UserId = 'userId',
  VehicleId = 'vehicleId',
  BookingId = 'bookingId',
}

export type GateControllerTransactionScalarWhereInput = {
  AND?: Maybe<Array<GateControllerTransactionScalarWhereInput>>;
  OR?: Maybe<Array<GateControllerTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<GateControllerTransactionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  comment?: Maybe<StringNullableFilter>;
  gateControllerId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type GateControllerTransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerTransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerTransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerTransactionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  comment?: Maybe<StringNullableWithAggregatesFilter>;
  gateControllerId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleId?: Maybe<StringNullableWithAggregatesFilter>;
  bookingId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type GateControllerTransactionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutTransactionsInput>;
  user?: Maybe<UserUpdateOneWithoutGateControllerTransactionsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingUpdateOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type GateControllerTransactionUpdateManyWithWhereWithoutBookingInput = {
  where: GateControllerTransactionScalarWhereInput;
  data: GateControllerTransactionUpdateManyMutationInput;
};

export type GateControllerTransactionUpdateManyWithWhereWithoutGateControllerInput =
  {
    where: GateControllerTransactionScalarWhereInput;
    data: GateControllerTransactionUpdateManyMutationInput;
  };

export type GateControllerTransactionUpdateManyWithWhereWithoutUserInput = {
  where: GateControllerTransactionScalarWhereInput;
  data: GateControllerTransactionUpdateManyMutationInput;
};

export type GateControllerTransactionUpdateManyWithWhereWithoutVehicleInput = {
  where: GateControllerTransactionScalarWhereInput;
  data: GateControllerTransactionUpdateManyMutationInput;
};

export type GateControllerTransactionUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<GateControllerTransactionCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerTransactionCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<
    Array<GateControllerTransactionUpsertWithWhereUniqueWithoutBookingInput>
  >;
  createMany?: Maybe<GateControllerTransactionCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerTransactionUpdateWithWhereUniqueWithoutBookingInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerTransactionUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<GateControllerTransactionScalarWhereInput>>;
};

export type GateControllerTransactionUpdateManyWithoutGateControllerInput = {
  create?: Maybe<
    Array<GateControllerTransactionCreateWithoutGateControllerInput>
  >;
  connectOrCreate?: Maybe<
    Array<GateControllerTransactionCreateOrConnectWithoutGateControllerInput>
  >;
  upsert?: Maybe<
    Array<GateControllerTransactionUpsertWithWhereUniqueWithoutGateControllerInput>
  >;
  createMany?: Maybe<GateControllerTransactionCreateManyGateControllerInputEnvelope>;
  set?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerTransactionUpdateWithWhereUniqueWithoutGateControllerInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerTransactionUpdateManyWithWhereWithoutGateControllerInput>
  >;
  deleteMany?: Maybe<Array<GateControllerTransactionScalarWhereInput>>;
};

export type GateControllerTransactionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<GateControllerTransactionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerTransactionCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<GateControllerTransactionUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<GateControllerTransactionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerTransactionUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerTransactionUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<GateControllerTransactionScalarWhereInput>>;
};

export type GateControllerTransactionUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<GateControllerTransactionCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerTransactionCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<GateControllerTransactionUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<GateControllerTransactionCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerTransactionUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerTransactionUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<GateControllerTransactionScalarWhereInput>>;
};

export type GateControllerTransactionUpdateWithWhereUniqueWithoutBookingInput =
  {
    where: GateControllerTransactionWhereUniqueInput;
    data: GateControllerTransactionUpdateWithoutBookingInput;
  };

export type GateControllerTransactionUpdateWithWhereUniqueWithoutGateControllerInput =
  {
    where: GateControllerTransactionWhereUniqueInput;
    data: GateControllerTransactionUpdateWithoutGateControllerInput;
  };

export type GateControllerTransactionUpdateWithWhereUniqueWithoutUserInput = {
  where: GateControllerTransactionWhereUniqueInput;
  data: GateControllerTransactionUpdateWithoutUserInput;
};

export type GateControllerTransactionUpdateWithWhereUniqueWithoutVehicleInput =
  {
    where: GateControllerTransactionWhereUniqueInput;
    data: GateControllerTransactionUpdateWithoutVehicleInput;
  };

export type GateControllerTransactionUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutTransactionsInput>;
  user?: Maybe<UserUpdateOneWithoutGateControllerTransactionsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionUpdateWithoutGateControllerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutGateControllerTransactionsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingUpdateOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutTransactionsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingUpdateOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateController?: Maybe<GateControllerUpdateOneWithoutTransactionsInput>;
  user?: Maybe<UserUpdateOneWithoutGateControllerTransactionsInput>;
  booking?: Maybe<BookingUpdateOneWithoutGateControllerTransactionsInput>;
};

export type GateControllerTransactionUpsertWithWhereUniqueWithoutBookingInput =
  {
    where: GateControllerTransactionWhereUniqueInput;
    update: GateControllerTransactionUpdateWithoutBookingInput;
    create: GateControllerTransactionCreateWithoutBookingInput;
  };

export type GateControllerTransactionUpsertWithWhereUniqueWithoutGateControllerInput =
  {
    where: GateControllerTransactionWhereUniqueInput;
    update: GateControllerTransactionUpdateWithoutGateControllerInput;
    create: GateControllerTransactionCreateWithoutGateControllerInput;
  };

export type GateControllerTransactionUpsertWithWhereUniqueWithoutUserInput = {
  where: GateControllerTransactionWhereUniqueInput;
  update: GateControllerTransactionUpdateWithoutUserInput;
  create: GateControllerTransactionCreateWithoutUserInput;
};

export type GateControllerTransactionUpsertWithWhereUniqueWithoutVehicleInput =
  {
    where: GateControllerTransactionWhereUniqueInput;
    update: GateControllerTransactionUpdateWithoutVehicleInput;
    create: GateControllerTransactionCreateWithoutVehicleInput;
  };

export type GateControllerTransactionWhereInput = {
  AND?: Maybe<Array<GateControllerTransactionWhereInput>>;
  OR?: Maybe<Array<GateControllerTransactionWhereInput>>;
  NOT?: Maybe<Array<GateControllerTransactionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  comment?: Maybe<StringNullableFilter>;
  gateController?: Maybe<GateControllerRelationFilter>;
  gateControllerId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type GateControllerTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GateControllerType = {
  __typename?: 'GateControllerType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerTypeCount>;
  gateControllers: Array<GateController>;
};

export type GateControllerTypeGateControllersArgs = {
  where?: Maybe<GateControllerWhereInput>;
  orderBy?: Maybe<Array<GateControllerOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerScalarFieldEnum>>;
};

export type GateControllerTypeCount = {
  __typename?: 'GateControllerTypeCount';
  gateControllers: Scalars['Int'];
};

export type GateControllerTypeCountAggregate = {
  __typename?: 'GateControllerTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type GateControllerTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type GateControllerTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  gateControllers?: Maybe<GateControllerCreateNestedManyWithoutGateTypeInput>;
};

export type GateControllerTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type GateControllerTypeCreateNestedOneWithoutGateControllersInput = {
  create?: Maybe<GateControllerTypeCreateWithoutGateControllersInput>;
  connectOrCreate?: Maybe<GateControllerTypeCreateOrConnectWithoutGateControllersInput>;
  connect?: Maybe<GateControllerTypeWhereUniqueInput>;
};

export type GateControllerTypeCreateOrConnectWithoutGateControllersInput = {
  where: GateControllerTypeWhereUniqueInput;
  create: GateControllerTypeCreateWithoutGateControllersInput;
};

export type GateControllerTypeCreateWithoutGateControllersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type GateControllerTypeGroupBy = {
  __typename?: 'GateControllerTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<GateControllerTypeCountAggregate>;
  _min?: Maybe<GateControllerTypeMinAggregate>;
  _max?: Maybe<GateControllerTypeMaxAggregate>;
};

export type GateControllerTypeMaxAggregate = {
  __typename?: 'GateControllerTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type GateControllerTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type GateControllerTypeMinAggregate = {
  __typename?: 'GateControllerTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type GateControllerTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type GateControllerTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<GateControllerTypeCountOrderByAggregateInput>;
  _max?: Maybe<GateControllerTypeMaxOrderByAggregateInput>;
  _min?: Maybe<GateControllerTypeMinOrderByAggregateInput>;
};

export type GateControllerTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  gateControllers?: Maybe<GateControllerOrderByRelationAggregateInput>;
};

export type GateControllerTypeRelationFilter = {
  is?: Maybe<GateControllerTypeWhereInput>;
  isNot?: Maybe<GateControllerTypeWhereInput>;
};

export enum GateControllerTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type GateControllerTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GateControllerTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GateControllerTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GateControllerTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type GateControllerTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateControllers?: Maybe<GateControllerUpdateManyWithoutGateTypeInput>;
};

export type GateControllerTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type GateControllerTypeUpdateOneWithoutGateControllersInput = {
  create?: Maybe<GateControllerTypeCreateWithoutGateControllersInput>;
  connectOrCreate?: Maybe<GateControllerTypeCreateOrConnectWithoutGateControllersInput>;
  upsert?: Maybe<GateControllerTypeUpsertWithoutGateControllersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GateControllerTypeWhereUniqueInput>;
  update?: Maybe<GateControllerTypeUpdateWithoutGateControllersInput>;
};

export type GateControllerTypeUpdateWithoutGateControllersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type GateControllerTypeUpsertWithoutGateControllersInput = {
  update: GateControllerTypeUpdateWithoutGateControllersInput;
  create: GateControllerTypeCreateWithoutGateControllersInput;
};

export type GateControllerTypeWhereInput = {
  AND?: Maybe<Array<GateControllerTypeWhereInput>>;
  OR?: Maybe<Array<GateControllerTypeWhereInput>>;
  NOT?: Maybe<Array<GateControllerTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  gateControllers?: Maybe<GateControllerListRelationFilter>;
};

export type GateControllerTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GateControllerUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentBefore?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentAfter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateType?: Maybe<GateControllerTypeUpdateOneWithoutGateControllersInput>;
  company?: Maybe<CompanyUpdateOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentUpdateManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetUpdateManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionUpdateManyWithoutGateControllerInput>;
};

export type GateControllerUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentBefore?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentAfter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type GateControllerUpdateManyWithWhereWithoutCompanyInput = {
  where: GateControllerScalarWhereInput;
  data: GateControllerUpdateManyMutationInput;
};

export type GateControllerUpdateManyWithWhereWithoutGateTypeInput = {
  where: GateControllerScalarWhereInput;
  data: GateControllerUpdateManyMutationInput;
};

export type GateControllerUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<GateControllerCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<Array<GateControllerUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<GateControllerCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<GateControllerWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerWhereUniqueInput>>;
  update?: Maybe<Array<GateControllerUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<
    Array<GateControllerUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<GateControllerScalarWhereInput>>;
};

export type GateControllerUpdateManyWithoutGateTypeInput = {
  create?: Maybe<Array<GateControllerCreateWithoutGateTypeInput>>;
  connectOrCreate?: Maybe<
    Array<GateControllerCreateOrConnectWithoutGateTypeInput>
  >;
  upsert?: Maybe<
    Array<GateControllerUpsertWithWhereUniqueWithoutGateTypeInput>
  >;
  createMany?: Maybe<GateControllerCreateManyGateTypeInputEnvelope>;
  set?: Maybe<Array<GateControllerWhereUniqueInput>>;
  disconnect?: Maybe<Array<GateControllerWhereUniqueInput>>;
  delete?: Maybe<Array<GateControllerWhereUniqueInput>>;
  connect?: Maybe<Array<GateControllerWhereUniqueInput>>;
  update?: Maybe<
    Array<GateControllerUpdateWithWhereUniqueWithoutGateTypeInput>
  >;
  updateMany?: Maybe<
    Array<GateControllerUpdateManyWithWhereWithoutGateTypeInput>
  >;
  deleteMany?: Maybe<Array<GateControllerScalarWhereInput>>;
};

export type GateControllerUpdateOneWithoutDocumentsInput = {
  create?: Maybe<GateControllerCreateWithoutDocumentsInput>;
  connectOrCreate?: Maybe<GateControllerCreateOrConnectWithoutDocumentsInput>;
  upsert?: Maybe<GateControllerUpsertWithoutDocumentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GateControllerWhereUniqueInput>;
  update?: Maybe<GateControllerUpdateWithoutDocumentsInput>;
};

export type GateControllerUpdateOneWithoutRuleSetsInput = {
  create?: Maybe<GateControllerCreateWithoutRuleSetsInput>;
  connectOrCreate?: Maybe<GateControllerCreateOrConnectWithoutRuleSetsInput>;
  upsert?: Maybe<GateControllerUpsertWithoutRuleSetsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GateControllerWhereUniqueInput>;
  update?: Maybe<GateControllerUpdateWithoutRuleSetsInput>;
};

export type GateControllerUpdateOneWithoutTransactionsInput = {
  create?: Maybe<GateControllerCreateWithoutTransactionsInput>;
  connectOrCreate?: Maybe<GateControllerCreateOrConnectWithoutTransactionsInput>;
  upsert?: Maybe<GateControllerUpsertWithoutTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GateControllerWhereUniqueInput>;
  update?: Maybe<GateControllerUpdateWithoutTransactionsInput>;
};

export type GateControllerUpdateWithWhereUniqueWithoutCompanyInput = {
  where: GateControllerWhereUniqueInput;
  data: GateControllerUpdateWithoutCompanyInput;
};

export type GateControllerUpdateWithWhereUniqueWithoutGateTypeInput = {
  where: GateControllerWhereUniqueInput;
  data: GateControllerUpdateWithoutGateTypeInput;
};

export type GateControllerUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentBefore?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentAfter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateType?: Maybe<GateControllerTypeUpdateOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentUpdateManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetUpdateManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionUpdateManyWithoutGateControllerInput>;
};

export type GateControllerUpdateWithoutDocumentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentBefore?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentAfter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateType?: Maybe<GateControllerTypeUpdateOneWithoutGateControllersInput>;
  company?: Maybe<CompanyUpdateOneWithoutGateControllersInput>;
  ruleSets?: Maybe<GateControllerRuleSetUpdateManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionUpdateManyWithoutGateControllerInput>;
};

export type GateControllerUpdateWithoutGateTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentBefore?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentAfter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentUpdateManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetUpdateManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionUpdateManyWithoutGateControllerInput>;
};

export type GateControllerUpdateWithoutRuleSetsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentBefore?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentAfter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateType?: Maybe<GateControllerTypeUpdateOneWithoutGateControllersInput>;
  company?: Maybe<CompanyUpdateOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentUpdateManyWithoutGateControllerInput>;
  transactions?: Maybe<GateControllerTransactionUpdateManyWithoutGateControllerInput>;
};

export type GateControllerUpdateWithoutTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  gateId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  contentBefore?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentAfter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPerson?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operatorCompanyEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gateType?: Maybe<GateControllerTypeUpdateOneWithoutGateControllersInput>;
  company?: Maybe<CompanyUpdateOneWithoutGateControllersInput>;
  documents?: Maybe<GateControllerDocumentUpdateManyWithoutGateControllerInput>;
  ruleSets?: Maybe<GateControllerRuleSetUpdateManyWithoutGateControllerInput>;
};

export type GateControllerUpsertWithWhereUniqueWithoutCompanyInput = {
  where: GateControllerWhereUniqueInput;
  update: GateControllerUpdateWithoutCompanyInput;
  create: GateControllerCreateWithoutCompanyInput;
};

export type GateControllerUpsertWithWhereUniqueWithoutGateTypeInput = {
  where: GateControllerWhereUniqueInput;
  update: GateControllerUpdateWithoutGateTypeInput;
  create: GateControllerCreateWithoutGateTypeInput;
};

export type GateControllerUpsertWithoutDocumentsInput = {
  update: GateControllerUpdateWithoutDocumentsInput;
  create: GateControllerCreateWithoutDocumentsInput;
};

export type GateControllerUpsertWithoutRuleSetsInput = {
  update: GateControllerUpdateWithoutRuleSetsInput;
  create: GateControllerCreateWithoutRuleSetsInput;
};

export type GateControllerUpsertWithoutTransactionsInput = {
  update: GateControllerUpdateWithoutTransactionsInput;
  create: GateControllerCreateWithoutTransactionsInput;
};

export type GateControllerWhereInput = {
  AND?: Maybe<Array<GateControllerWhereInput>>;
  OR?: Maybe<Array<GateControllerWhereInput>>;
  NOT?: Maybe<Array<GateControllerWhereInput>>;
  id?: Maybe<StringFilter>;
  gateId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  contentBefore?: Maybe<StringNullableFilter>;
  contentAfter?: Maybe<StringNullableFilter>;
  operatorCompanyName?: Maybe<StringNullableFilter>;
  operatorCompanyPerson?: Maybe<StringNullableFilter>;
  operatorCompanyPhone?: Maybe<StringNullableFilter>;
  operatorCompanyEmail?: Maybe<StringNullableFilter>;
  gateType?: Maybe<GateControllerTypeRelationFilter>;
  gateTypeId?: Maybe<StringNullableFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
  documents?: Maybe<GateControllerDocumentListRelationFilter>;
  ruleSets?: Maybe<GateControllerRuleSetListRelationFilter>;
  transactions?: Maybe<GateControllerTransactionListRelationFilter>;
};

export type GateControllerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum GenderType {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum InsuranceReporting {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type IntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
  access_token: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['String'];
  type: MessageType;
  subject: Scalars['String'];
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type MessageCountAggregate = {
  __typename?: 'MessageCountAggregate';
  id: Scalars['Int'];
  type: Scalars['Int'];
  subject: Scalars['Int'];
  body: Scalars['Int'];
  createdAt: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type MessageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type MessageCreateInput = {
  id?: Maybe<Scalars['String']>;
  type: MessageType;
  subject: Scalars['String'];
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutMessageInput>;
};

export type MessageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  type: MessageType;
  subject: Scalars['String'];
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  type: MessageType;
  subject: Scalars['String'];
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MessageCreateManyUserInputEnvelope = {
  data: Array<MessageCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessageCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<MessageCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<MessageCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<MessageCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<MessageWhereUniqueInput>>;
};

export type MessageCreateOrConnectWithoutUserInput = {
  where: MessageWhereUniqueInput;
  create: MessageCreateWithoutUserInput;
};

export type MessageCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  type: MessageType;
  subject: Scalars['String'];
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MessageGroupBy = {
  __typename?: 'MessageGroupBy';
  id: Scalars['String'];
  type: MessageType;
  subject: Scalars['String'];
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  _count?: Maybe<MessageCountAggregate>;
  _min?: Maybe<MessageMinAggregate>;
  _max?: Maybe<MessageMaxAggregate>;
};

export type MessageListRelationFilter = {
  every?: Maybe<MessageWhereInput>;
  some?: Maybe<MessageWhereInput>;
  none?: Maybe<MessageWhereInput>;
};

export type MessageMaxAggregate = {
  __typename?: 'MessageMaxAggregate';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<MessageType>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type MessageMinAggregate = {
  __typename?: 'MessageMinAggregate';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<MessageType>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type MessageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type MessageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type MessageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<MessageCountOrderByAggregateInput>;
  _max?: Maybe<MessageMaxOrderByAggregateInput>;
  _min?: Maybe<MessageMinOrderByAggregateInput>;
};

export type MessageOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum MessageScalarFieldEnum {
  Id = 'id',
  Type = 'type',
  Subject = 'subject',
  Body = 'body',
  CreatedAt = 'createdAt',
  UserId = 'userId',
}

export type MessageScalarWhereInput = {
  AND?: Maybe<Array<MessageScalarWhereInput>>;
  OR?: Maybe<Array<MessageScalarWhereInput>>;
  NOT?: Maybe<Array<MessageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumMessageTypeFilter>;
  subject?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type MessageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumMessageTypeWithAggregatesFilter>;
  subject?: Maybe<StringWithAggregatesFilter>;
  body?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum MessageType {
  Sms = 'SMS',
  Email = 'EMAIL',
  Notification = 'NOTIFICATION',
}

export type MessageUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumMessageTypeFieldUpdateOperationsInput>;
  subject?: Maybe<StringFieldUpdateOperationsInput>;
  body?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutMessageInput>;
};

export type MessageUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumMessageTypeFieldUpdateOperationsInput>;
  subject?: Maybe<StringFieldUpdateOperationsInput>;
  body?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MessageUpdateManyWithWhereWithoutUserInput = {
  where: MessageScalarWhereInput;
  data: MessageUpdateManyMutationInput;
};

export type MessageUpdateManyWithoutUserInput = {
  create?: Maybe<Array<MessageCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<MessageCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<MessageUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<MessageCreateManyUserInputEnvelope>;
  set?: Maybe<Array<MessageWhereUniqueInput>>;
  disconnect?: Maybe<Array<MessageWhereUniqueInput>>;
  delete?: Maybe<Array<MessageWhereUniqueInput>>;
  connect?: Maybe<Array<MessageWhereUniqueInput>>;
  update?: Maybe<Array<MessageUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<MessageUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<MessageScalarWhereInput>>;
};

export type MessageUpdateWithWhereUniqueWithoutUserInput = {
  where: MessageWhereUniqueInput;
  data: MessageUpdateWithoutUserInput;
};

export type MessageUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumMessageTypeFieldUpdateOperationsInput>;
  subject?: Maybe<StringFieldUpdateOperationsInput>;
  body?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MessageUpsertWithWhereUniqueWithoutUserInput = {
  where: MessageWhereUniqueInput;
  update: MessageUpdateWithoutUserInput;
  create: MessageCreateWithoutUserInput;
};

export type MessageWhereInput = {
  AND?: Maybe<Array<MessageWhereInput>>;
  OR?: Maybe<Array<MessageWhereInput>>;
  NOT?: Maybe<Array<MessageWhereInput>>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumMessageTypeFilter>;
  subject?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type MessageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ModelType = {
  __typename?: 'ModelType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ModelTypeCount>;
  vehicle: Array<Vehicle>;
};

export type ModelTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type ModelTypeCount = {
  __typename?: 'ModelTypeCount';
  vehicle: Scalars['Int'];
};

export type ModelTypeCountAggregate = {
  __typename?: 'ModelTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ModelTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ModelTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutModelTypeInput>;
};

export type ModelTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ModelTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<ModelTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ModelTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<ModelTypeWhereUniqueInput>;
};

export type ModelTypeCreateOrConnectWithoutVehicleInput = {
  where: ModelTypeWhereUniqueInput;
  create: ModelTypeCreateWithoutVehicleInput;
};

export type ModelTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ModelTypeGroupBy = {
  __typename?: 'ModelTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ModelTypeCountAggregate>;
  _min?: Maybe<ModelTypeMinAggregate>;
  _max?: Maybe<ModelTypeMaxAggregate>;
};

export type ModelTypeMaxAggregate = {
  __typename?: 'ModelTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ModelTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ModelTypeMinAggregate = {
  __typename?: 'ModelTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ModelTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ModelTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<ModelTypeCountOrderByAggregateInput>;
  _max?: Maybe<ModelTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ModelTypeMinOrderByAggregateInput>;
};

export type ModelTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type ModelTypeRelationFilter = {
  is?: Maybe<ModelTypeWhereInput>;
  isNot?: Maybe<ModelTypeWhereInput>;
};

export enum ModelTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type ModelTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ModelTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ModelTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ModelTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ModelTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutModelTypeInput>;
};

export type ModelTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<ModelTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ModelTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<ModelTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ModelTypeWhereUniqueInput>;
  update?: Maybe<ModelTypeUpdateWithoutVehicleInput>;
};

export type ModelTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ModelTypeUpsertWithoutVehicleInput = {
  update: ModelTypeUpdateWithoutVehicleInput;
  create: ModelTypeCreateWithoutVehicleInput;
};

export type ModelTypeWhereInput = {
  AND?: Maybe<Array<ModelTypeWhereInput>>;
  OR?: Maybe<Array<ModelTypeWhereInput>>;
  NOT?: Maybe<Array<ModelTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type ModelTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAvailability: Availability;
  createManyAvailability: AffectedRowsOutput;
  deleteAvailability?: Maybe<Availability>;
  updateAvailability?: Maybe<Availability>;
  deleteManyAvailability: AffectedRowsOutput;
  updateManyAvailability: AffectedRowsOutput;
  upsertAvailability: Availability;
  createBillingAccount: BillingAccount;
  createManyBillingAccount: AffectedRowsOutput;
  deleteBillingAccount?: Maybe<BillingAccount>;
  updateBillingAccount?: Maybe<BillingAccount>;
  deleteManyBillingAccount: AffectedRowsOutput;
  updateManyBillingAccount: AffectedRowsOutput;
  upsertBillingAccount: BillingAccount;
  createBillingAccountDocument: BillingAccountDocument;
  createManyBillingAccountDocument: AffectedRowsOutput;
  deleteBillingAccountDocument: Scalars['Boolean'];
  updateBillingAccountDocument?: Maybe<BillingAccountDocument>;
  deleteManyBillingAccountDocument: AffectedRowsOutput;
  updateManyBillingAccountDocument: AffectedRowsOutput;
  upsertBillingAccountDocument: BillingAccountDocument;
  createBiometricToken: BiometricToken;
  createManyBiometricToken: AffectedRowsOutput;
  deleteBiometricToken?: Maybe<BiometricToken>;
  updateBiometricToken?: Maybe<BiometricToken>;
  deleteManyBiometricToken: AffectedRowsOutput;
  updateManyBiometricToken: AffectedRowsOutput;
  upsertBiometricToken: BiometricToken;
  createBodyType: BodyType;
  createManyBodyType: AffectedRowsOutput;
  deleteBodyType?: Maybe<BodyType>;
  updateBodyType?: Maybe<BodyType>;
  deleteManyBodyType: AffectedRowsOutput;
  updateManyBodyType: AffectedRowsOutput;
  upsertBodyType: BodyType;
  createBooking: Booking;
  createManyBooking: AffectedRowsOutput;
  deleteBooking?: Maybe<Booking>;
  updateBooking: Booking;
  deleteManyBooking: AffectedRowsOutput;
  updateManyBooking: AffectedRowsOutput;
  upsertBooking: Booking;
  createBookingAdditionalDriver: BookingAdditionalDriver;
  createManyBookingAdditionalDriver: AffectedRowsOutput;
  deleteBookingAdditionalDriver?: Maybe<BookingAdditionalDriver>;
  updateBookingAdditionalDriver?: Maybe<BookingAdditionalDriver>;
  deleteManyBookingAdditionalDriver: AffectedRowsOutput;
  updateManyBookingAdditionalDriver: AffectedRowsOutput;
  upsertBookingAdditionalDriver: BookingAdditionalDriver;
  createBookingAnswer: BookingAnswer;
  createManyBookingAnswer: AffectedRowsOutput;
  deleteBookingAnswer?: Maybe<BookingAnswer>;
  updateBookingAnswer?: Maybe<BookingAnswer>;
  deleteManyBookingAnswer: AffectedRowsOutput;
  updateManyBookingAnswer: AffectedRowsOutput;
  upsertBookingAnswer: BookingAnswer;
  createBookingAvailability: BookingAvailability;
  createManyBookingAvailability: AffectedRowsOutput;
  deleteBookingAvailability?: Maybe<BookingAvailability>;
  updateBookingAvailability?: Maybe<BookingAvailability>;
  deleteManyBookingAvailability: AffectedRowsOutput;
  updateManyBookingAvailability: AffectedRowsOutput;
  upsertBookingAvailability: BookingAvailability;
  createBookingChangeHistory: BookingChangeHistory;
  createManyBookingChangeHistory: AffectedRowsOutput;
  deleteBookingChangeHistory?: Maybe<BookingChangeHistory>;
  updateBookingChangeHistory?: Maybe<BookingChangeHistory>;
  deleteManyBookingChangeHistory: AffectedRowsOutput;
  updateManyBookingChangeHistory: AffectedRowsOutput;
  upsertBookingChangeHistory: BookingChangeHistory;
  createBookingFuelPassword: BookingFuelPassword;
  createManyBookingFuelPassword: AffectedRowsOutput;
  deleteBookingFuelPassword?: Maybe<BookingFuelPassword>;
  updateBookingFuelPassword?: Maybe<BookingFuelPassword>;
  deleteManyBookingFuelPassword: AffectedRowsOutput;
  updateManyBookingFuelPassword: AffectedRowsOutput;
  upsertBookingFuelPassword: BookingFuelPassword;
  createBookingQuestion: BookingQuestion;
  createManyBookingQuestion: AffectedRowsOutput;
  deleteBookingQuestion?: Maybe<BookingQuestion>;
  updateBookingQuestion: Scalars['Boolean'];
  deleteManyBookingQuestion: AffectedRowsOutput;
  updateManyBookingQuestion: AffectedRowsOutput;
  upsertBookingQuestion: BookingQuestion;
  createBookingRefunding: BookingRefunding;
  createManyBookingRefunding: AffectedRowsOutput;
  deleteBookingRefunding?: Maybe<BookingRefunding>;
  updateBookingRefunding?: Maybe<BookingRefunding>;
  deleteManyBookingRefunding: AffectedRowsOutput;
  updateManyBookingRefunding: AffectedRowsOutput;
  upsertBookingRefunding: BookingRefunding;
  createBookingVehicleDamages: BookingVehicleDamages;
  createManyBookingVehicleDamages: AffectedRowsOutput;
  deleteBookingVehicleDamages?: Maybe<BookingVehicleDamages>;
  updateBookingVehicleDamages?: Maybe<BookingVehicleDamages>;
  deleteManyBookingVehicleDamages: AffectedRowsOutput;
  updateManyBookingVehicleDamages: AffectedRowsOutput;
  upsertBookingVehicleDamages: BookingVehicleDamages;
  createBrandType: BrandType;
  createManyBrandType: AffectedRowsOutput;
  deleteBrandType?: Maybe<BrandType>;
  updateBrandType?: Maybe<BrandType>;
  deleteManyBrandType: AffectedRowsOutput;
  updateManyBrandType: AffectedRowsOutput;
  upsertBrandType: BrandType;
  createChargingStation: ChargingStation;
  createManyChargingStation: AffectedRowsOutput;
  deleteChargingStation?: Maybe<ChargingStation>;
  updateChargingStation?: Maybe<ChargingStation>;
  deleteManyChargingStation: AffectedRowsOutput;
  updateManyChargingStation: AffectedRowsOutput;
  upsertChargingStation: ChargingStation;
  createChargingStationCluster: ChargingStationCluster;
  createManyChargingStationCluster: AffectedRowsOutput;
  deleteChargingStationCluster?: Maybe<ChargingStationCluster>;
  updateChargingStationCluster?: Maybe<ChargingStationCluster>;
  deleteManyChargingStationCluster: AffectedRowsOutput;
  updateManyChargingStationCluster: AffectedRowsOutput;
  upsertChargingStationCluster: ChargingStationCluster;
  createChargingStationRfid: ChargingStationRfid;
  createManyChargingStationRfid: AffectedRowsOutput;
  deleteChargingStationRfid?: Maybe<ChargingStationRfid>;
  updateChargingStationRfid?: Maybe<ChargingStationRfid>;
  deleteManyChargingStationRfid: AffectedRowsOutput;
  updateManyChargingStationRfid: AffectedRowsOutput;
  upsertChargingStationRfid: ChargingStationRfid;
  createChargingStationRfidAlias: ChargingStationRfidAlias;
  createManyChargingStationRfidAlias: AffectedRowsOutput;
  deleteChargingStationRfidAlias?: Maybe<ChargingStationRfidAlias>;
  updateChargingStationRfidAlias?: Maybe<ChargingStationRfidAlias>;
  deleteManyChargingStationRfidAlias: AffectedRowsOutput;
  updateManyChargingStationRfidAlias: AffectedRowsOutput;
  upsertChargingStationRfidAlias: ChargingStationRfidAlias;
  createChargingStationTariff: ChargingStationTariff;
  createManyChargingStationTariff: AffectedRowsOutput;
  deleteChargingStationTariff?: Maybe<ChargingStationTariff>;
  updateChargingStationTariff?: Maybe<ChargingStationTariff>;
  deleteManyChargingStationTariff: AffectedRowsOutput;
  updateManyChargingStationTariff: AffectedRowsOutput;
  upsertChargingStationTariff: ChargingStationTariff;
  createChargingStationTariffRule: ChargingStationTariffRule;
  createManyChargingStationTariffRule: AffectedRowsOutput;
  deleteChargingStationTariffRule?: Maybe<ChargingStationTariffRule>;
  updateChargingStationTariffRule?: Maybe<ChargingStationTariffRule>;
  deleteManyChargingStationTariffRule: AffectedRowsOutput;
  updateManyChargingStationTariffRule: AffectedRowsOutput;
  upsertChargingStationTariffRule: ChargingStationTariffRule;
  createChargingStationTransaction: ChargingStationTransaction;
  createManyChargingStationTransaction: AffectedRowsOutput;
  deleteChargingStationTransaction?: Maybe<ChargingStationTransaction>;
  updateChargingStationTransaction?: Maybe<ChargingStationTransaction>;
  deleteManyChargingStationTransaction: AffectedRowsOutput;
  updateManyChargingStationTransaction: AffectedRowsOutput;
  upsertChargingStationTransaction: ChargingStationTransaction;
  createChargingStationTransactionAppliedTariff: ChargingStationTransactionAppliedTariff;
  createManyChargingStationTransactionAppliedTariff: AffectedRowsOutput;
  deleteChargingStationTransactionAppliedTariff?: Maybe<ChargingStationTransactionAppliedTariff>;
  updateChargingStationTransactionAppliedTariff?: Maybe<ChargingStationTransactionAppliedTariff>;
  deleteManyChargingStationTransactionAppliedTariff: AffectedRowsOutput;
  updateManyChargingStationTransactionAppliedTariff: AffectedRowsOutput;
  upsertChargingStationTransactionAppliedTariff: ChargingStationTransactionAppliedTariff;
  createChargingStationTransactionReport: ChargingStationTransactionReport;
  createManyChargingStationTransactionReport: AffectedRowsOutput;
  deleteChargingStationTransactionReport?: Maybe<ChargingStationTransactionReport>;
  updateChargingStationTransactionReport?: Maybe<ChargingStationTransactionReport>;
  deleteManyChargingStationTransactionReport: AffectedRowsOutput;
  updateManyChargingStationTransactionReport: AffectedRowsOutput;
  upsertChargingStationTransactionReport: ChargingStationTransactionReport;
  createChargingStationTransactionUsage: ChargingStationTransactionUsage;
  createManyChargingStationTransactionUsage: AffectedRowsOutput;
  deleteChargingStationTransactionUsage?: Maybe<ChargingStationTransactionUsage>;
  updateChargingStationTransactionUsage?: Maybe<ChargingStationTransactionUsage>;
  deleteManyChargingStationTransactionUsage: AffectedRowsOutput;
  updateManyChargingStationTransactionUsage: AffectedRowsOutput;
  upsertChargingStationTransactionUsage: ChargingStationTransactionUsage;
  createChildSeatType: ChildSeatType;
  createManyChildSeatType: AffectedRowsOutput;
  deleteChildSeatType?: Maybe<ChildSeatType>;
  updateChildSeatType?: Maybe<ChildSeatType>;
  deleteManyChildSeatType: AffectedRowsOutput;
  updateManyChildSeatType: AffectedRowsOutput;
  upsertChildSeatType: ChildSeatType;
  createColorType: ColorType;
  createManyColorType: AffectedRowsOutput;
  deleteColorType?: Maybe<ColorType>;
  updateColorType?: Maybe<ColorType>;
  deleteManyColorType: AffectedRowsOutput;
  updateManyColorType: AffectedRowsOutput;
  upsertColorType: ColorType;
  createCompany: Company;
  createManyCompany: AffectedRowsOutput;
  deleteCompany?: Maybe<Company>;
  updateCompany?: Maybe<Company>;
  deleteManyCompany: AffectedRowsOutput;
  updateManyCompany: AffectedRowsOutput;
  upsertCompany: Company;
  createCompanyDocument: CompanyDocument;
  createManyCompanyDocument: AffectedRowsOutput;
  deleteCompanyDocument?: Maybe<CompanyDocument>;
  updateCompanyDocument?: Maybe<CompanyDocument>;
  deleteManyCompanyDocument: AffectedRowsOutput;
  updateManyCompanyDocument: AffectedRowsOutput;
  upsertCompanyDocument: CompanyDocument;
  createConsumptionType: ConsumptionType;
  createManyConsumptionType: AffectedRowsOutput;
  deleteConsumptionType?: Maybe<ConsumptionType>;
  updateConsumptionType?: Maybe<ConsumptionType>;
  deleteManyConsumptionType: AffectedRowsOutput;
  updateManyConsumptionType: AffectedRowsOutput;
  upsertConsumptionType: ConsumptionType;
  createDoorType: DoorType;
  createManyDoorType: AffectedRowsOutput;
  deleteDoorType?: Maybe<DoorType>;
  updateDoorType?: Maybe<DoorType>;
  deleteManyDoorType: AffectedRowsOutput;
  updateManyDoorType: AffectedRowsOutput;
  upsertDoorType: DoorType;
  createDriveType: DriveType;
  createManyDriveType: AffectedRowsOutput;
  deleteDriveType?: Maybe<DriveType>;
  updateDriveType?: Maybe<DriveType>;
  deleteManyDriveType: AffectedRowsOutput;
  updateManyDriveType: AffectedRowsOutput;
  upsertDriveType: DriveType;
  createEmissionCodeType: EmissionCodeType;
  createManyEmissionCodeType: AffectedRowsOutput;
  deleteEmissionCodeType?: Maybe<EmissionCodeType>;
  updateEmissionCodeType?: Maybe<EmissionCodeType>;
  deleteManyEmissionCodeType: AffectedRowsOutput;
  updateManyEmissionCodeType: AffectedRowsOutput;
  upsertEmissionCodeType: EmissionCodeType;
  createEnergyEfficiencyType: EnergyEfficiencyType;
  createManyEnergyEfficiencyType: AffectedRowsOutput;
  deleteEnergyEfficiencyType?: Maybe<EnergyEfficiencyType>;
  updateEnergyEfficiencyType?: Maybe<EnergyEfficiencyType>;
  deleteManyEnergyEfficiencyType: AffectedRowsOutput;
  updateManyEnergyEfficiencyType: AffectedRowsOutput;
  upsertEnergyEfficiencyType: EnergyEfficiencyType;
  createEquipmentType: EquipmentType;
  createManyEquipmentType: AffectedRowsOutput;
  deleteEquipmentType?: Maybe<EquipmentType>;
  updateEquipmentType?: Maybe<EquipmentType>;
  deleteManyEquipmentType: AffectedRowsOutput;
  updateManyEquipmentType: AffectedRowsOutput;
  upsertEquipmentType: EquipmentType;
  createFuelType: FuelType;
  createManyFuelType: AffectedRowsOutput;
  deleteFuelType?: Maybe<FuelType>;
  updateFuelType?: Maybe<FuelType>;
  deleteManyFuelType: AffectedRowsOutput;
  updateManyFuelType: AffectedRowsOutput;
  upsertFuelType: FuelType;
  createGateController: GateController;
  createManyGateController: AffectedRowsOutput;
  deleteGateController?: Maybe<GateController>;
  updateGateController?: Maybe<GateController>;
  deleteManyGateController: AffectedRowsOutput;
  updateManyGateController: AffectedRowsOutput;
  upsertGateController: GateController;
  createGateControllerDocument: GateControllerDocument;
  createManyGateControllerDocument: AffectedRowsOutput;
  deleteGateControllerDocument?: Maybe<GateControllerDocument>;
  updateGateControllerDocument?: Maybe<GateControllerDocument>;
  deleteManyGateControllerDocument: AffectedRowsOutput;
  updateManyGateControllerDocument: AffectedRowsOutput;
  upsertGateControllerDocument: GateControllerDocument;
  createGateControllerRelUser: GateControllerRelUser;
  createManyGateControllerRelUser: AffectedRowsOutput;
  deleteGateControllerRelUser?: Maybe<GateControllerRelUser>;
  updateGateControllerRelUser?: Maybe<GateControllerRelUser>;
  deleteManyGateControllerRelUser: AffectedRowsOutput;
  updateManyGateControllerRelUser: AffectedRowsOutput;
  upsertGateControllerRelUser: GateControllerRelUser;
  createGateControllerRelVehicle: GateControllerRelVehicle;
  createManyGateControllerRelVehicle: AffectedRowsOutput;
  deleteGateControllerRelVehicle?: Maybe<GateControllerRelVehicle>;
  updateGateControllerRelVehicle?: Maybe<GateControllerRelVehicle>;
  deleteManyGateControllerRelVehicle: AffectedRowsOutput;
  updateManyGateControllerRelVehicle: AffectedRowsOutput;
  upsertGateControllerRelVehicle: GateControllerRelVehicle;
  createGateControllerRule: GateControllerRule;
  createManyGateControllerRule: AffectedRowsOutput;
  deleteGateControllerRule?: Maybe<GateControllerRule>;
  updateGateControllerRule?: Maybe<GateControllerRule>;
  deleteManyGateControllerRule: AffectedRowsOutput;
  updateManyGateControllerRule: AffectedRowsOutput;
  upsertGateControllerRule: GateControllerRule;
  createGateControllerRuleSet?: Maybe<GateControllerRuleSet>;
  createManyGateControllerRuleSet: AffectedRowsOutput;
  deleteGateControllerRuleSet?: Maybe<GateControllerRuleSet>;
  updateGateControllerRuleSet?: Maybe<GateControllerRuleSet>;
  deleteManyGateControllerRuleSet: AffectedRowsOutput;
  updateManyGateControllerRuleSet: AffectedRowsOutput;
  upsertGateControllerRuleSet: GateControllerRuleSet;
  createGateControllerTransaction: GateControllerTransaction;
  createManyGateControllerTransaction: AffectedRowsOutput;
  deleteGateControllerTransaction?: Maybe<GateControllerTransaction>;
  updateGateControllerTransaction?: Maybe<GateControllerTransaction>;
  deleteManyGateControllerTransaction: AffectedRowsOutput;
  updateManyGateControllerTransaction: AffectedRowsOutput;
  upsertGateControllerTransaction: GateControllerTransaction;
  createGateControllerType: GateControllerType;
  createManyGateControllerType: AffectedRowsOutput;
  deleteGateControllerType?: Maybe<GateControllerType>;
  updateGateControllerType?: Maybe<GateControllerType>;
  deleteManyGateControllerType: AffectedRowsOutput;
  updateManyGateControllerType: AffectedRowsOutput;
  upsertGateControllerType: GateControllerType;
  createMessage: Message;
  createManyMessage: AffectedRowsOutput;
  deleteMessage?: Maybe<Message>;
  updateMessage?: Maybe<Message>;
  deleteManyMessage: AffectedRowsOutput;
  updateManyMessage: AffectedRowsOutput;
  upsertMessage: Message;
  createModelType: ModelType;
  createManyModelType: AffectedRowsOutput;
  deleteModelType?: Maybe<ModelType>;
  updateModelType?: Maybe<ModelType>;
  deleteManyModelType: AffectedRowsOutput;
  updateManyModelType: AffectedRowsOutput;
  upsertModelType: ModelType;
  createNotificationDevices: NotificationDevices;
  createManyNotificationDevices: AffectedRowsOutput;
  deleteNotificationDevices?: Maybe<NotificationDevices>;
  updateNotificationDevices?: Maybe<NotificationDevices>;
  deleteManyNotificationDevices: AffectedRowsOutput;
  updateManyNotificationDevices: AffectedRowsOutput;
  upsertNotificationDevices: NotificationDevices;
  createParticularUseType: ParticularUseType;
  createManyParticularUseType: AffectedRowsOutput;
  deleteParticularUseType?: Maybe<ParticularUseType>;
  updateParticularUseType?: Maybe<ParticularUseType>;
  deleteManyParticularUseType: AffectedRowsOutput;
  updateManyParticularUseType: AffectedRowsOutput;
  upsertParticularUseType: ParticularUseType;
  createPxlVisionManualVerification: PxlVisionManualVerification;
  createManyPxlVisionManualVerification: AffectedRowsOutput;
  deletePxlVisionManualVerification?: Maybe<PxlVisionManualVerification>;
  updatePxlVisionManualVerification?: Maybe<PxlVisionManualVerification>;
  deleteManyPxlVisionManualVerification: AffectedRowsOutput;
  updateManyPxlVisionManualVerification: AffectedRowsOutput;
  upsertPxlVisionManualVerification: PxlVisionManualVerification;
  createRevokeReasonType: RevokeReasonType;
  createManyRevokeReasonType: AffectedRowsOutput;
  deleteRevokeReasonType?: Maybe<RevokeReasonType>;
  updateRevokeReasonType?: Maybe<RevokeReasonType>;
  deleteManyRevokeReasonType: AffectedRowsOutput;
  updateManyRevokeReasonType: AffectedRowsOutput;
  upsertRevokeReasonType: RevokeReasonType;
  createSeatFrontType: SeatFrontType;
  createManySeatFrontType: AffectedRowsOutput;
  deleteSeatFrontType?: Maybe<SeatFrontType>;
  updateSeatFrontType?: Maybe<SeatFrontType>;
  deleteManySeatFrontType: AffectedRowsOutput;
  updateManySeatFrontType: AffectedRowsOutput;
  upsertSeatFrontType: SeatFrontType;
  createSeatType: SeatType;
  createManySeatType: AffectedRowsOutput;
  deleteSeatType?: Maybe<SeatType>;
  updateSeatType?: Maybe<SeatType>;
  deleteManySeatType: AffectedRowsOutput;
  updateManySeatType: AffectedRowsOutput;
  upsertSeatType: SeatType;
  createSharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  createManySharedVehicleGroup: AffectedRowsOutput;
  deleteSharedVehicleGroup: SharedVehicleGroup;
  updateSharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  deleteManySharedVehicleGroup: AffectedRowsOutput;
  updateManySharedVehicleGroup: AffectedRowsOutput;
  upsertSharedVehicleGroup: SharedVehicleGroup;
  createSharedVehicleGroupInvitedEmail: SharedVehicleGroupInvitedEmail;
  createManySharedVehicleGroupInvitedEmail: AffectedRowsOutput;
  deleteSharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmail>;
  updateSharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmail>;
  deleteManySharedVehicleGroupInvitedEmail: AffectedRowsOutput;
  updateManySharedVehicleGroupInvitedEmail: AffectedRowsOutput;
  upsertSharedVehicleGroupInvitedEmail: SharedVehicleGroupInvitedEmail;
  createSharedVehicleGroupRelAvailability: SharedVehicleGroupRelAvailability;
  createManySharedVehicleGroupRelAvailability: AffectedRowsOutput;
  deleteSharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailability>;
  updateSharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailability>;
  deleteManySharedVehicleGroupRelAvailability: AffectedRowsOutput;
  updateManySharedVehicleGroupRelAvailability: AffectedRowsOutput;
  upsertSharedVehicleGroupRelAvailability: SharedVehicleGroupRelAvailability;
  createSharedVehicleGroupRelCompany: SharedVehicleGroupRelCompany;
  createManySharedVehicleGroupRelCompany: AffectedRowsOutput;
  deleteSharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompany>;
  updateSharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompany>;
  deleteManySharedVehicleGroupRelCompany: AffectedRowsOutput;
  updateManySharedVehicleGroupRelCompany: AffectedRowsOutput;
  upsertSharedVehicleGroupRelCompany: SharedVehicleGroupRelCompany;
  createSharedVehicleGroupRelUser: SharedVehicleGroupRelUser;
  createManySharedVehicleGroupRelUser: AffectedRowsOutput;
  deleteSharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUser>;
  updateSharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUser>;
  deleteManySharedVehicleGroupRelUser: AffectedRowsOutput;
  updateManySharedVehicleGroupRelUser: AffectedRowsOutput;
  upsertSharedVehicleGroupRelUser: SharedVehicleGroupRelUser;
  createSharedVehicleGroupRelVehicle: SharedVehicleGroupRelVehicle;
  createManySharedVehicleGroupRelVehicle: AffectedRowsOutput;
  deleteSharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicle>;
  updateSharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicle>;
  deleteManySharedVehicleGroupRelVehicle: AffectedRowsOutput;
  updateManySharedVehicleGroupRelVehicle: AffectedRowsOutput;
  upsertSharedVehicleGroupRelVehicle: SharedVehicleGroupRelVehicle;
  createShieldColorType: ShieldColorType;
  createManyShieldColorType: AffectedRowsOutput;
  deleteShieldColorType?: Maybe<ShieldColorType>;
  updateShieldColorType?: Maybe<ShieldColorType>;
  deleteManyShieldColorType: AffectedRowsOutput;
  updateManyShieldColorType: AffectedRowsOutput;
  upsertShieldColorType: ShieldColorType;
  createSmartcarAuth: SmartcarAuth;
  createManySmartcarAuth: AffectedRowsOutput;
  deleteSmartcarAuth?: Maybe<SmartcarAuth>;
  updateSmartcarAuth?: Maybe<SmartcarAuth>;
  deleteManySmartcarAuth: AffectedRowsOutput;
  updateManySmartcarAuth: AffectedRowsOutput;
  upsertSmartcarAuth: SmartcarAuth;
  createTransmissionType: TransmissionType;
  createManyTransmissionType: AffectedRowsOutput;
  deleteTransmissionType?: Maybe<TransmissionType>;
  updateTransmissionType?: Maybe<TransmissionType>;
  deleteManyTransmissionType: AffectedRowsOutput;
  updateManyTransmissionType: AffectedRowsOutput;
  upsertTransmissionType: TransmissionType;
  createUnpublishReasonType: UnpublishReasonType;
  createManyUnpublishReasonType: AffectedRowsOutput;
  deleteUnpublishReasonType?: Maybe<UnpublishReasonType>;
  updateUnpublishReasonType?: Maybe<UnpublishReasonType>;
  deleteManyUnpublishReasonType: AffectedRowsOutput;
  updateManyUnpublishReasonType: AffectedRowsOutput;
  upsertUnpublishReasonType: UnpublishReasonType;
  createUser: User;
  createManyUser: AffectedRowsOutput;
  deleteUser: User;
  updateUser: User;
  deleteManyUser: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  upsertUser: User;
  createUserInvitedEmail: UserInvitedEmail;
  createManyUserInvitedEmail: AffectedRowsOutput;
  deleteUserInvitedEmail?: Maybe<UserInvitedEmail>;
  updateUserInvitedEmail?: Maybe<UserInvitedEmail>;
  deleteManyUserInvitedEmail: AffectedRowsOutput;
  updateManyUserInvitedEmail: AffectedRowsOutput;
  upsertUserInvitedEmail: UserInvitedEmail;
  createVehicle: Vehicle;
  createManyVehicle: AffectedRowsOutput;
  deleteVehicle: Vehicle;
  updateVehicle?: Maybe<Vehicle>;
  deleteManyVehicle: AffectedRowsOutput;
  updateManyVehicle: AffectedRowsOutput;
  upsertVehicle: Vehicle;
  createVehicleCloudBoxxLog: VehicleCloudBoxxLog;
  createManyVehicleCloudBoxxLog: AffectedRowsOutput;
  deleteVehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLog>;
  updateVehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLog>;
  deleteManyVehicleCloudBoxxLog: AffectedRowsOutput;
  updateManyVehicleCloudBoxxLog: AffectedRowsOutput;
  upsertVehicleCloudBoxxLog: VehicleCloudBoxxLog;
  createVehicleCloudBoxxStatusLog: VehicleCloudBoxxStatusLog;
  createManyVehicleCloudBoxxStatusLog: AffectedRowsOutput;
  deleteVehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLog>;
  updateVehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLog>;
  deleteManyVehicleCloudBoxxStatusLog: AffectedRowsOutput;
  updateManyVehicleCloudBoxxStatusLog: AffectedRowsOutput;
  upsertVehicleCloudBoxxStatusLog: VehicleCloudBoxxStatusLog;
  createVehicleConnection: VehicleConnection;
  createManyVehicleConnection: AffectedRowsOutput;
  deleteVehicleConnection?: Maybe<VehicleConnection>;
  updateVehicleConnection?: Maybe<VehicleConnection>;
  deleteManyVehicleConnection: AffectedRowsOutput;
  updateManyVehicleConnection: AffectedRowsOutput;
  upsertVehicleConnection: VehicleConnection;
  createVehicleDamage: VehicleDamage;
  createManyVehicleDamage: AffectedRowsOutput;
  deleteVehicleDamage?: Maybe<VehicleDamage>;
  updateVehicleDamage?: Maybe<VehicleDamage>;
  deleteManyVehicleDamage: AffectedRowsOutput;
  updateManyVehicleDamage: AffectedRowsOutput;
  upsertVehicleDamage: VehicleDamage;
  createVehicleDamageComment: VehicleDamageComment;
  createManyVehicleDamageComment: AffectedRowsOutput;
  deleteVehicleDamageComment?: Maybe<VehicleDamageComment>;
  updateVehicleDamageComment?: Maybe<VehicleDamageComment>;
  deleteManyVehicleDamageComment: AffectedRowsOutput;
  updateManyVehicleDamageComment: AffectedRowsOutput;
  upsertVehicleDamageComment: VehicleDamageComment;
  createVehicleEquipment: VehicleEquipment;
  createManyVehicleEquipment: AffectedRowsOutput;
  deleteVehicleEquipment?: Maybe<VehicleEquipment>;
  updateVehicleEquipment?: Maybe<VehicleEquipment>;
  deleteManyVehicleEquipment: AffectedRowsOutput;
  updateManyVehicleEquipment: AffectedRowsOutput;
  upsertVehicleEquipment: VehicleEquipment;
  createVehicleInsuranceDocument: VehicleInsuranceDocument;
  createManyVehicleInsuranceDocument: AffectedRowsOutput;
  deleteVehicleInsuranceDocument?: Maybe<VehicleInsuranceDocument>;
  updateVehicleInsuranceDocument?: Maybe<VehicleInsuranceDocument>;
  deleteManyVehicleInsuranceDocument: AffectedRowsOutput;
  updateManyVehicleInsuranceDocument: AffectedRowsOutput;
  upsertVehicleInsuranceDocument: VehicleInsuranceDocument;
  createVehicleInsuranceRule: VehicleInsuranceRule;
  createManyVehicleInsuranceRule: AffectedRowsOutput;
  deleteVehicleInsuranceRule?: Maybe<VehicleInsuranceRule>;
  updateVehicleInsuranceRule?: Maybe<VehicleInsuranceRule>;
  deleteManyVehicleInsuranceRule: AffectedRowsOutput;
  updateManyVehicleInsuranceRule: AffectedRowsOutput;
  upsertVehicleInsuranceRule: VehicleInsuranceRule;
  createVehicleInsuranceRuleSet: VehicleInsuranceRuleSet;
  createManyVehicleInsuranceRuleSet: AffectedRowsOutput;
  deleteVehicleInsuranceRuleSet?: Maybe<VehicleInsuranceRuleSet>;
  updateVehicleInsuranceRuleSet?: Maybe<VehicleInsuranceRuleSet>;
  deleteManyVehicleInsuranceRuleSet: AffectedRowsOutput;
  updateManyVehicleInsuranceRuleSet: AffectedRowsOutput;
  upsertVehicleInsuranceRuleSet: VehicleInsuranceRuleSet;
  createVehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  createManyVehicleInsuranceType: AffectedRowsOutput;
  deleteVehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  updateVehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  deleteManyVehicleInsuranceType: AffectedRowsOutput;
  updateManyVehicleInsuranceType: AffectedRowsOutput;
  upsertVehicleInsuranceType: VehicleInsuranceType;
  createVehicleKindType: VehicleKindType;
  createManyVehicleKindType: AffectedRowsOutput;
  deleteVehicleKindType?: Maybe<VehicleKindType>;
  updateVehicleKindType?: Maybe<VehicleKindType>;
  deleteManyVehicleKindType: AffectedRowsOutput;
  updateManyVehicleKindType: AffectedRowsOutput;
  upsertVehicleKindType: VehicleKindType;
  createVehicleLocation: VehicleLocation;
  createManyVehicleLocation: AffectedRowsOutput;
  deleteVehicleLocation?: Maybe<VehicleLocation>;
  updateVehicleLocation?: Maybe<VehicleLocation>;
  deleteManyVehicleLocation: AffectedRowsOutput;
  updateManyVehicleLocation: AffectedRowsOutput;
  upsertVehicleLocation: VehicleLocation;
  createVehiclePhoto: VehiclePhoto;
  createManyVehiclePhoto: AffectedRowsOutput;
  deleteVehiclePhoto?: Maybe<VehiclePhoto>;
  updateVehiclePhoto?: Maybe<VehiclePhoto>;
  deleteManyVehiclePhoto: AffectedRowsOutput;
  updateManyVehiclePhoto: AffectedRowsOutput;
  upsertVehiclePhoto: VehiclePhoto;
  createVehicleType: VehicleType;
  createManyVehicleType: AffectedRowsOutput;
  deleteVehicleType?: Maybe<VehicleType>;
  updateVehicleType?: Maybe<VehicleType>;
  deleteManyVehicleType: AffectedRowsOutput;
  updateManyVehicleType: AffectedRowsOutput;
  upsertVehicleType: VehicleType;
  login: LoginResponse;
  MAINTENANCE_deleteVehicle: Scalars['Boolean'];
  revokeAffectedBookings: Scalars['Boolean'];
  addVehicleAvailability: Scalars['Boolean'];
  updateVehicleAvailability: Scalars['Boolean'];
  deleteVehicleAvailability: Scalars['Boolean'];
  revokeBooking: Scalars['Boolean'];
  createNewBooking: Booking;
  startChargingStation: Scalars['Boolean'];
  stopChargingStation: Scalars['Boolean'];
  updateChargingStationRfidInvisia?: Maybe<ChargingStationRfidInvisia>;
  startChargingStationTransactionSession: Scalars['Boolean'];
  stopChargingStationTransactionSession: Scalars['Boolean'];
  finishChargingStationTransactionSession: Scalars['Boolean'];
  uploadBillingAccountDocuments: Array<BillingAccountDocument>;
  generateCompanyOnboardingStripeLink: Scalars['Boolean'];
  uploadCompanyLogo: Scalars['String'];
  openGateController?: Maybe<Scalars['Boolean']>;
  uploadDocuments: Array<VehicleInsuranceDocument>;
  deleteDocument: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  saveOneTimePassword: Scalars['Boolean'];
  generateUserOnboardingStripeLink: Scalars['Boolean'];
  resendEmailVerificationLink: Scalars['Boolean'];
  sendMessage: Scalars['Boolean'];
  uploadVerificationDocument: Scalars['String'];
  vehicleCloudBoxxConnect: VehicleCloudBoxxConnectResponse;
  vehicleCloudBoxxDisconnect: Scalars['Boolean'];
  vehicleCloudBoxxActivate: Scalars['Boolean'];
  changeDeviceStatus: Scalars['Boolean'];
  createVehicleReport: VehicleReport;
  updateVehicleReport: VehicleReport;
  deleteVehicleReport: VehicleReport;
  uploadImages: Array<VehiclePhoto>;
  uploadDamageImages: Array<VehiclePhoto>;
  deleteImage: Scalars['Boolean'];
};

export type MutationCreateAvailabilityArgs = {
  data: AvailabilityCreateInput;
};

export type MutationCreateManyAvailabilityArgs = {
  data: Array<AvailabilityCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteAvailabilityArgs = {
  where: AvailabilityWhereUniqueInput;
};

export type MutationUpdateAvailabilityArgs = {
  data: AvailabilityUpdateInput;
  where: AvailabilityWhereUniqueInput;
};

export type MutationDeleteManyAvailabilityArgs = {
  where?: Maybe<AvailabilityWhereInput>;
};

export type MutationUpdateManyAvailabilityArgs = {
  data: AvailabilityUpdateManyMutationInput;
  where?: Maybe<AvailabilityWhereInput>;
};

export type MutationUpsertAvailabilityArgs = {
  where: AvailabilityWhereUniqueInput;
  create: AvailabilityCreateInput;
  update: AvailabilityUpdateInput;
};

export type MutationCreateBillingAccountArgs = {
  data: BillingAccountCreateInput;
};

export type MutationCreateManyBillingAccountArgs = {
  data: Array<BillingAccountCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBillingAccountArgs = {
  where: BillingAccountWhereUniqueInput;
};

export type MutationUpdateBillingAccountArgs = {
  data: BillingAccountUpdateInput;
  where: BillingAccountWhereUniqueInput;
};

export type MutationDeleteManyBillingAccountArgs = {
  where?: Maybe<BillingAccountWhereInput>;
};

export type MutationUpdateManyBillingAccountArgs = {
  data: BillingAccountUpdateManyMutationInput;
  where?: Maybe<BillingAccountWhereInput>;
};

export type MutationUpsertBillingAccountArgs = {
  where: BillingAccountWhereUniqueInput;
  create: BillingAccountCreateInput;
  update: BillingAccountUpdateInput;
};

export type MutationCreateBillingAccountDocumentArgs = {
  data: BillingAccountDocumentCreateInput;
};

export type MutationCreateManyBillingAccountDocumentArgs = {
  data: Array<BillingAccountDocumentCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBillingAccountDocumentArgs = {
  documentId: Scalars['String'];
  billingAccountId?: Maybe<Scalars['String']>;
};

export type MutationUpdateBillingAccountDocumentArgs = {
  data: BillingAccountDocumentUpdateInput;
  where: BillingAccountDocumentWhereUniqueInput;
};

export type MutationDeleteManyBillingAccountDocumentArgs = {
  where?: Maybe<BillingAccountDocumentWhereInput>;
};

export type MutationUpdateManyBillingAccountDocumentArgs = {
  data: BillingAccountDocumentUpdateManyMutationInput;
  where?: Maybe<BillingAccountDocumentWhereInput>;
};

export type MutationUpsertBillingAccountDocumentArgs = {
  where: BillingAccountDocumentWhereUniqueInput;
  create: BillingAccountDocumentCreateInput;
  update: BillingAccountDocumentUpdateInput;
};

export type MutationCreateBiometricTokenArgs = {
  data: BiometricTokenCreateInput;
};

export type MutationCreateManyBiometricTokenArgs = {
  data: Array<BiometricTokenCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBiometricTokenArgs = {
  where: BiometricTokenWhereUniqueInput;
};

export type MutationUpdateBiometricTokenArgs = {
  data: BiometricTokenUpdateInput;
  where: BiometricTokenWhereUniqueInput;
};

export type MutationDeleteManyBiometricTokenArgs = {
  where?: Maybe<BiometricTokenWhereInput>;
};

export type MutationUpdateManyBiometricTokenArgs = {
  data: BiometricTokenUpdateManyMutationInput;
  where?: Maybe<BiometricTokenWhereInput>;
};

export type MutationUpsertBiometricTokenArgs = {
  where: BiometricTokenWhereUniqueInput;
  create: BiometricTokenCreateInput;
  update: BiometricTokenUpdateInput;
};

export type MutationCreateBodyTypeArgs = {
  data: BodyTypeCreateInput;
};

export type MutationCreateManyBodyTypeArgs = {
  data: Array<BodyTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBodyTypeArgs = {
  where: BodyTypeWhereUniqueInput;
};

export type MutationUpdateBodyTypeArgs = {
  data: BodyTypeUpdateInput;
  where: BodyTypeWhereUniqueInput;
};

export type MutationDeleteManyBodyTypeArgs = {
  where?: Maybe<BodyTypeWhereInput>;
};

export type MutationUpdateManyBodyTypeArgs = {
  data: BodyTypeUpdateManyMutationInput;
  where?: Maybe<BodyTypeWhereInput>;
};

export type MutationUpsertBodyTypeArgs = {
  where: BodyTypeWhereUniqueInput;
  create: BodyTypeCreateInput;
  update: BodyTypeUpdateInput;
};

export type MutationCreateBookingArgs = {
  data: BookingCreateInput;
};

export type MutationCreateManyBookingArgs = {
  data: Array<BookingCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingArgs = {
  where: BookingWhereUniqueInput;
};

export type MutationUpdateBookingArgs = {
  data: BookingUpdateInput;
  where: BookingWhereUniqueInput;
};

export type MutationDeleteManyBookingArgs = {
  where?: Maybe<BookingWhereInput>;
};

export type MutationUpdateManyBookingArgs = {
  data: BookingUpdateManyMutationInput;
  where?: Maybe<BookingWhereInput>;
};

export type MutationUpsertBookingArgs = {
  where: BookingWhereUniqueInput;
  create: BookingCreateInput;
  update: BookingUpdateInput;
};

export type MutationCreateBookingAdditionalDriverArgs = {
  data: BookingAdditionalDriverCreateInput;
};

export type MutationCreateManyBookingAdditionalDriverArgs = {
  data: Array<BookingAdditionalDriverCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingAdditionalDriverArgs = {
  where: BookingAdditionalDriverWhereUniqueInput;
};

export type MutationUpdateBookingAdditionalDriverArgs = {
  data: BookingAdditionalDriverUpdateInput;
  where: BookingAdditionalDriverWhereUniqueInput;
};

export type MutationDeleteManyBookingAdditionalDriverArgs = {
  where?: Maybe<BookingAdditionalDriverWhereInput>;
};

export type MutationUpdateManyBookingAdditionalDriverArgs = {
  data: BookingAdditionalDriverUpdateManyMutationInput;
  where?: Maybe<BookingAdditionalDriverWhereInput>;
};

export type MutationUpsertBookingAdditionalDriverArgs = {
  where: BookingAdditionalDriverWhereUniqueInput;
  create: BookingAdditionalDriverCreateInput;
  update: BookingAdditionalDriverUpdateInput;
};

export type MutationCreateBookingAnswerArgs = {
  data: BookingAnswerCreateInput;
};

export type MutationCreateManyBookingAnswerArgs = {
  data: Array<BookingAnswerCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingAnswerArgs = {
  where: BookingAnswerWhereUniqueInput;
};

export type MutationUpdateBookingAnswerArgs = {
  data: BookingAnswerUpdateInput;
  where: BookingAnswerWhereUniqueInput;
};

export type MutationDeleteManyBookingAnswerArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
};

export type MutationUpdateManyBookingAnswerArgs = {
  data: BookingAnswerUpdateManyMutationInput;
  where?: Maybe<BookingAnswerWhereInput>;
};

export type MutationUpsertBookingAnswerArgs = {
  where: BookingAnswerWhereUniqueInput;
  create: BookingAnswerCreateInput;
  update: BookingAnswerUpdateInput;
};

export type MutationCreateBookingAvailabilityArgs = {
  data: BookingAvailabilityCreateInput;
};

export type MutationCreateManyBookingAvailabilityArgs = {
  data: Array<BookingAvailabilityCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingAvailabilityArgs = {
  where: BookingAvailabilityWhereUniqueInput;
};

export type MutationUpdateBookingAvailabilityArgs = {
  data: BookingAvailabilityUpdateInput;
  where: BookingAvailabilityWhereUniqueInput;
};

export type MutationDeleteManyBookingAvailabilityArgs = {
  where?: Maybe<BookingAvailabilityWhereInput>;
};

export type MutationUpdateManyBookingAvailabilityArgs = {
  data: BookingAvailabilityUpdateManyMutationInput;
  where?: Maybe<BookingAvailabilityWhereInput>;
};

export type MutationUpsertBookingAvailabilityArgs = {
  where: BookingAvailabilityWhereUniqueInput;
  create: BookingAvailabilityCreateInput;
  update: BookingAvailabilityUpdateInput;
};

export type MutationCreateBookingChangeHistoryArgs = {
  data: BookingChangeHistoryCreateInput;
};

export type MutationCreateManyBookingChangeHistoryArgs = {
  data: Array<BookingChangeHistoryCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingChangeHistoryArgs = {
  where: BookingChangeHistoryWhereUniqueInput;
};

export type MutationUpdateBookingChangeHistoryArgs = {
  data: BookingChangeHistoryUpdateInput;
  where: BookingChangeHistoryWhereUniqueInput;
};

export type MutationDeleteManyBookingChangeHistoryArgs = {
  where?: Maybe<BookingChangeHistoryWhereInput>;
};

export type MutationUpdateManyBookingChangeHistoryArgs = {
  data: BookingChangeHistoryUpdateManyMutationInput;
  where?: Maybe<BookingChangeHistoryWhereInput>;
};

export type MutationUpsertBookingChangeHistoryArgs = {
  where: BookingChangeHistoryWhereUniqueInput;
  create: BookingChangeHistoryCreateInput;
  update: BookingChangeHistoryUpdateInput;
};

export type MutationCreateBookingFuelPasswordArgs = {
  data: BookingFuelPasswordCreateInput;
};

export type MutationCreateManyBookingFuelPasswordArgs = {
  data: Array<BookingFuelPasswordCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingFuelPasswordArgs = {
  where: BookingFuelPasswordWhereUniqueInput;
};

export type MutationUpdateBookingFuelPasswordArgs = {
  data: BookingFuelPasswordUpdateInput;
  where: BookingFuelPasswordWhereUniqueInput;
};

export type MutationDeleteManyBookingFuelPasswordArgs = {
  where?: Maybe<BookingFuelPasswordWhereInput>;
};

export type MutationUpdateManyBookingFuelPasswordArgs = {
  data: BookingFuelPasswordUpdateManyMutationInput;
  where?: Maybe<BookingFuelPasswordWhereInput>;
};

export type MutationUpsertBookingFuelPasswordArgs = {
  where: BookingFuelPasswordWhereUniqueInput;
  create: BookingFuelPasswordCreateInput;
  update: BookingFuelPasswordUpdateInput;
};

export type MutationCreateBookingQuestionArgs = {
  data: BookingQuestionCreateInput;
};

export type MutationCreateManyBookingQuestionArgs = {
  data: Array<BookingQuestionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingQuestionArgs = {
  where: BookingQuestionWhereUniqueInput;
};

export type MutationUpdateBookingQuestionArgs = {
  userId: Scalars['String'];
  bookingQuestionId: Scalars['String'];
  vehicleId?: Maybe<Scalars['String']>;
  data: BookingQuestionUpdateDataInput;
};

export type MutationDeleteManyBookingQuestionArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
};

export type MutationUpdateManyBookingQuestionArgs = {
  data: BookingQuestionUpdateManyMutationInput;
  where?: Maybe<BookingQuestionWhereInput>;
};

export type MutationUpsertBookingQuestionArgs = {
  where: BookingQuestionWhereUniqueInput;
  create: BookingQuestionCreateInput;
  update: BookingQuestionUpdateInput;
};

export type MutationCreateBookingRefundingArgs = {
  data: BookingRefundingCreateInput;
};

export type MutationCreateManyBookingRefundingArgs = {
  data: Array<BookingRefundingCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingRefundingArgs = {
  where: BookingRefundingWhereUniqueInput;
};

export type MutationUpdateBookingRefundingArgs = {
  data: BookingRefundingUpdateInput;
  where: BookingRefundingWhereUniqueInput;
};

export type MutationDeleteManyBookingRefundingArgs = {
  where?: Maybe<BookingRefundingWhereInput>;
};

export type MutationUpdateManyBookingRefundingArgs = {
  data: BookingRefundingUpdateManyMutationInput;
  where?: Maybe<BookingRefundingWhereInput>;
};

export type MutationUpsertBookingRefundingArgs = {
  where: BookingRefundingWhereUniqueInput;
  create: BookingRefundingCreateInput;
  update: BookingRefundingUpdateInput;
};

export type MutationCreateBookingVehicleDamagesArgs = {
  data: BookingVehicleDamagesCreateInput;
};

export type MutationCreateManyBookingVehicleDamagesArgs = {
  data: Array<BookingVehicleDamagesCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBookingVehicleDamagesArgs = {
  where: BookingVehicleDamagesWhereUniqueInput;
};

export type MutationUpdateBookingVehicleDamagesArgs = {
  data: BookingVehicleDamagesUpdateInput;
  where: BookingVehicleDamagesWhereUniqueInput;
};

export type MutationDeleteManyBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
};

export type MutationUpdateManyBookingVehicleDamagesArgs = {
  data: BookingVehicleDamagesUpdateManyMutationInput;
  where?: Maybe<BookingVehicleDamagesWhereInput>;
};

export type MutationUpsertBookingVehicleDamagesArgs = {
  where: BookingVehicleDamagesWhereUniqueInput;
  create: BookingVehicleDamagesCreateInput;
  update: BookingVehicleDamagesUpdateInput;
};

export type MutationCreateBrandTypeArgs = {
  data: BrandTypeCreateInput;
};

export type MutationCreateManyBrandTypeArgs = {
  data: Array<BrandTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteBrandTypeArgs = {
  where: BrandTypeWhereUniqueInput;
};

export type MutationUpdateBrandTypeArgs = {
  data: BrandTypeUpdateInput;
  where: BrandTypeWhereUniqueInput;
};

export type MutationDeleteManyBrandTypeArgs = {
  where?: Maybe<BrandTypeWhereInput>;
};

export type MutationUpdateManyBrandTypeArgs = {
  data: BrandTypeUpdateManyMutationInput;
  where?: Maybe<BrandTypeWhereInput>;
};

export type MutationUpsertBrandTypeArgs = {
  where: BrandTypeWhereUniqueInput;
  create: BrandTypeCreateInput;
  update: BrandTypeUpdateInput;
};

export type MutationCreateChargingStationArgs = {
  data: ChargingStationCreateInput;
};

export type MutationCreateManyChargingStationArgs = {
  data: Array<ChargingStationCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationArgs = {
  where: ChargingStationWhereUniqueInput;
};

export type MutationUpdateChargingStationArgs = {
  data: ChargingStationUpdateInput;
  where: ChargingStationWhereUniqueInput;
};

export type MutationDeleteManyChargingStationArgs = {
  where?: Maybe<ChargingStationWhereInput>;
};

export type MutationUpdateManyChargingStationArgs = {
  data: ChargingStationUpdateManyMutationInput;
  where?: Maybe<ChargingStationWhereInput>;
};

export type MutationUpsertChargingStationArgs = {
  where: ChargingStationWhereUniqueInput;
  create: ChargingStationCreateInput;
  update: ChargingStationUpdateInput;
};

export type MutationCreateChargingStationClusterArgs = {
  data: ChargingStationClusterCreateInput;
};

export type MutationCreateManyChargingStationClusterArgs = {
  data: Array<ChargingStationClusterCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationClusterArgs = {
  where: ChargingStationClusterWhereUniqueInput;
};

export type MutationUpdateChargingStationClusterArgs = {
  data: ChargingStationClusterUpdateInput;
  where: ChargingStationClusterWhereUniqueInput;
};

export type MutationDeleteManyChargingStationClusterArgs = {
  where?: Maybe<ChargingStationClusterWhereInput>;
};

export type MutationUpdateManyChargingStationClusterArgs = {
  data: ChargingStationClusterUpdateManyMutationInput;
  where?: Maybe<ChargingStationClusterWhereInput>;
};

export type MutationUpsertChargingStationClusterArgs = {
  where: ChargingStationClusterWhereUniqueInput;
  create: ChargingStationClusterCreateInput;
  update: ChargingStationClusterUpdateInput;
};

export type MutationCreateChargingStationRfidArgs = {
  data: ChargingStationRfidCreateInput;
};

export type MutationCreateManyChargingStationRfidArgs = {
  data: Array<ChargingStationRfidCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationRfidArgs = {
  where: ChargingStationRfidWhereUniqueInput;
};

export type MutationUpdateChargingStationRfidArgs = {
  data: ChargingStationRfidUpdateInput;
  where: ChargingStationRfidWhereUniqueInput;
};

export type MutationDeleteManyChargingStationRfidArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
};

export type MutationUpdateManyChargingStationRfidArgs = {
  data: ChargingStationRfidUpdateManyMutationInput;
  where?: Maybe<ChargingStationRfidWhereInput>;
};

export type MutationUpsertChargingStationRfidArgs = {
  where: ChargingStationRfidWhereUniqueInput;
  create: ChargingStationRfidCreateInput;
  update: ChargingStationRfidUpdateInput;
};

export type MutationCreateChargingStationRfidAliasArgs = {
  data: ChargingStationRfidAliasCreateInput;
};

export type MutationCreateManyChargingStationRfidAliasArgs = {
  data: Array<ChargingStationRfidAliasCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationRfidAliasArgs = {
  where: ChargingStationRfidAliasWhereUniqueInput;
};

export type MutationUpdateChargingStationRfidAliasArgs = {
  data: ChargingStationRfidAliasUpdateInput;
  where: ChargingStationRfidAliasWhereUniqueInput;
};

export type MutationDeleteManyChargingStationRfidAliasArgs = {
  where?: Maybe<ChargingStationRfidAliasWhereInput>;
};

export type MutationUpdateManyChargingStationRfidAliasArgs = {
  data: ChargingStationRfidAliasUpdateManyMutationInput;
  where?: Maybe<ChargingStationRfidAliasWhereInput>;
};

export type MutationUpsertChargingStationRfidAliasArgs = {
  where: ChargingStationRfidAliasWhereUniqueInput;
  create: ChargingStationRfidAliasCreateInput;
  update: ChargingStationRfidAliasUpdateInput;
};

export type MutationCreateChargingStationTariffArgs = {
  data: ChargingStationTariffCreateInput;
};

export type MutationCreateManyChargingStationTariffArgs = {
  data: Array<ChargingStationTariffCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationTariffArgs = {
  where: ChargingStationTariffWhereUniqueInput;
};

export type MutationUpdateChargingStationTariffArgs = {
  data: ChargingStationTariffUpdateInput;
  where: ChargingStationTariffWhereUniqueInput;
};

export type MutationDeleteManyChargingStationTariffArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
};

export type MutationUpdateManyChargingStationTariffArgs = {
  data: ChargingStationTariffUpdateManyMutationInput;
  where?: Maybe<ChargingStationTariffWhereInput>;
};

export type MutationUpsertChargingStationTariffArgs = {
  where: ChargingStationTariffWhereUniqueInput;
  create: ChargingStationTariffCreateInput;
  update: ChargingStationTariffUpdateInput;
};

export type MutationCreateChargingStationTariffRuleArgs = {
  data: ChargingStationTariffRuleCreateInput;
};

export type MutationCreateManyChargingStationTariffRuleArgs = {
  data: Array<ChargingStationTariffRuleCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationTariffRuleArgs = {
  where: ChargingStationTariffRuleWhereUniqueInput;
};

export type MutationUpdateChargingStationTariffRuleArgs = {
  data: ChargingStationTariffRuleUpdateInput;
  where: ChargingStationTariffRuleWhereUniqueInput;
};

export type MutationDeleteManyChargingStationTariffRuleArgs = {
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
};

export type MutationUpdateManyChargingStationTariffRuleArgs = {
  data: ChargingStationTariffRuleUpdateManyMutationInput;
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
};

export type MutationUpsertChargingStationTariffRuleArgs = {
  where: ChargingStationTariffRuleWhereUniqueInput;
  create: ChargingStationTariffRuleCreateInput;
  update: ChargingStationTariffRuleUpdateInput;
};

export type MutationCreateChargingStationTransactionArgs = {
  data: ChargingStationTransactionCreateInput;
};

export type MutationCreateManyChargingStationTransactionArgs = {
  data: Array<ChargingStationTransactionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationTransactionArgs = {
  where: ChargingStationTransactionWhereUniqueInput;
};

export type MutationUpdateChargingStationTransactionArgs = {
  data: ChargingStationTransactionUpdateInput;
  where: ChargingStationTransactionWhereUniqueInput;
};

export type MutationDeleteManyChargingStationTransactionArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
};

export type MutationUpdateManyChargingStationTransactionArgs = {
  data: ChargingStationTransactionUpdateManyMutationInput;
  where?: Maybe<ChargingStationTransactionWhereInput>;
};

export type MutationUpsertChargingStationTransactionArgs = {
  where: ChargingStationTransactionWhereUniqueInput;
  create: ChargingStationTransactionCreateInput;
  update: ChargingStationTransactionUpdateInput;
};

export type MutationCreateChargingStationTransactionAppliedTariffArgs = {
  data: ChargingStationTransactionAppliedTariffCreateInput;
};

export type MutationCreateManyChargingStationTransactionAppliedTariffArgs = {
  data: Array<ChargingStationTransactionAppliedTariffCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationTransactionAppliedTariffArgs = {
  where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
};

export type MutationUpdateChargingStationTransactionAppliedTariffArgs = {
  data: ChargingStationTransactionAppliedTariffUpdateInput;
  where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
};

export type MutationDeleteManyChargingStationTransactionAppliedTariffArgs = {
  where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
};

export type MutationUpdateManyChargingStationTransactionAppliedTariffArgs = {
  data: ChargingStationTransactionAppliedTariffUpdateManyMutationInput;
  where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
};

export type MutationUpsertChargingStationTransactionAppliedTariffArgs = {
  where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
  create: ChargingStationTransactionAppliedTariffCreateInput;
  update: ChargingStationTransactionAppliedTariffUpdateInput;
};

export type MutationCreateChargingStationTransactionReportArgs = {
  data: ChargingStationTransactionReportCreateInput;
};

export type MutationCreateManyChargingStationTransactionReportArgs = {
  data: Array<ChargingStationTransactionReportCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationTransactionReportArgs = {
  where: ChargingStationTransactionReportWhereUniqueInput;
};

export type MutationUpdateChargingStationTransactionReportArgs = {
  data: ChargingStationTransactionReportUpdateInput;
  where: ChargingStationTransactionReportWhereUniqueInput;
};

export type MutationDeleteManyChargingStationTransactionReportArgs = {
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
};

export type MutationUpdateManyChargingStationTransactionReportArgs = {
  data: ChargingStationTransactionReportUpdateManyMutationInput;
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
};

export type MutationUpsertChargingStationTransactionReportArgs = {
  where: ChargingStationTransactionReportWhereUniqueInput;
  create: ChargingStationTransactionReportCreateInput;
  update: ChargingStationTransactionReportUpdateInput;
};

export type MutationCreateChargingStationTransactionUsageArgs = {
  data: ChargingStationTransactionUsageCreateInput;
};

export type MutationCreateManyChargingStationTransactionUsageArgs = {
  data: Array<ChargingStationTransactionUsageCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChargingStationTransactionUsageArgs = {
  where: ChargingStationTransactionUsageWhereUniqueInput;
};

export type MutationUpdateChargingStationTransactionUsageArgs = {
  data: ChargingStationTransactionUsageUpdateInput;
  where: ChargingStationTransactionUsageWhereUniqueInput;
};

export type MutationDeleteManyChargingStationTransactionUsageArgs = {
  where?: Maybe<ChargingStationTransactionUsageWhereInput>;
};

export type MutationUpdateManyChargingStationTransactionUsageArgs = {
  data: ChargingStationTransactionUsageUpdateManyMutationInput;
  where?: Maybe<ChargingStationTransactionUsageWhereInput>;
};

export type MutationUpsertChargingStationTransactionUsageArgs = {
  where: ChargingStationTransactionUsageWhereUniqueInput;
  create: ChargingStationTransactionUsageCreateInput;
  update: ChargingStationTransactionUsageUpdateInput;
};

export type MutationCreateChildSeatTypeArgs = {
  data: ChildSeatTypeCreateInput;
};

export type MutationCreateManyChildSeatTypeArgs = {
  data: Array<ChildSeatTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteChildSeatTypeArgs = {
  where: ChildSeatTypeWhereUniqueInput;
};

export type MutationUpdateChildSeatTypeArgs = {
  data: ChildSeatTypeUpdateInput;
  where: ChildSeatTypeWhereUniqueInput;
};

export type MutationDeleteManyChildSeatTypeArgs = {
  where?: Maybe<ChildSeatTypeWhereInput>;
};

export type MutationUpdateManyChildSeatTypeArgs = {
  data: ChildSeatTypeUpdateManyMutationInput;
  where?: Maybe<ChildSeatTypeWhereInput>;
};

export type MutationUpsertChildSeatTypeArgs = {
  where: ChildSeatTypeWhereUniqueInput;
  create: ChildSeatTypeCreateInput;
  update: ChildSeatTypeUpdateInput;
};

export type MutationCreateColorTypeArgs = {
  data: ColorTypeCreateInput;
};

export type MutationCreateManyColorTypeArgs = {
  data: Array<ColorTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteColorTypeArgs = {
  where: ColorTypeWhereUniqueInput;
};

export type MutationUpdateColorTypeArgs = {
  data: ColorTypeUpdateInput;
  where: ColorTypeWhereUniqueInput;
};

export type MutationDeleteManyColorTypeArgs = {
  where?: Maybe<ColorTypeWhereInput>;
};

export type MutationUpdateManyColorTypeArgs = {
  data: ColorTypeUpdateManyMutationInput;
  where?: Maybe<ColorTypeWhereInput>;
};

export type MutationUpsertColorTypeArgs = {
  where: ColorTypeWhereUniqueInput;
  create: ColorTypeCreateInput;
  update: ColorTypeUpdateInput;
};

export type MutationCreateCompanyArgs = {
  data: CompanyCreateInput;
};

export type MutationCreateManyCompanyArgs = {
  data: Array<CompanyCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteCompanyArgs = {
  where: CompanyWhereUniqueInput;
};

export type MutationUpdateCompanyArgs = {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};

export type MutationDeleteManyCompanyArgs = {
  where?: Maybe<CompanyWhereInput>;
};

export type MutationUpdateManyCompanyArgs = {
  data: CompanyUpdateManyMutationInput;
  where?: Maybe<CompanyWhereInput>;
};

export type MutationUpsertCompanyArgs = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateInput;
  update: CompanyUpdateInput;
};

export type MutationCreateCompanyDocumentArgs = {
  data: CompanyDocumentCreateInput;
};

export type MutationCreateManyCompanyDocumentArgs = {
  data: Array<CompanyDocumentCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteCompanyDocumentArgs = {
  where: CompanyDocumentWhereUniqueInput;
};

export type MutationUpdateCompanyDocumentArgs = {
  data: CompanyDocumentUpdateInput;
  where: CompanyDocumentWhereUniqueInput;
};

export type MutationDeleteManyCompanyDocumentArgs = {
  where?: Maybe<CompanyDocumentWhereInput>;
};

export type MutationUpdateManyCompanyDocumentArgs = {
  data: CompanyDocumentUpdateManyMutationInput;
  where?: Maybe<CompanyDocumentWhereInput>;
};

export type MutationUpsertCompanyDocumentArgs = {
  where: CompanyDocumentWhereUniqueInput;
  create: CompanyDocumentCreateInput;
  update: CompanyDocumentUpdateInput;
};

export type MutationCreateConsumptionTypeArgs = {
  data: ConsumptionTypeCreateInput;
};

export type MutationCreateManyConsumptionTypeArgs = {
  data: Array<ConsumptionTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteConsumptionTypeArgs = {
  where: ConsumptionTypeWhereUniqueInput;
};

export type MutationUpdateConsumptionTypeArgs = {
  data: ConsumptionTypeUpdateInput;
  where: ConsumptionTypeWhereUniqueInput;
};

export type MutationDeleteManyConsumptionTypeArgs = {
  where?: Maybe<ConsumptionTypeWhereInput>;
};

export type MutationUpdateManyConsumptionTypeArgs = {
  data: ConsumptionTypeUpdateManyMutationInput;
  where?: Maybe<ConsumptionTypeWhereInput>;
};

export type MutationUpsertConsumptionTypeArgs = {
  where: ConsumptionTypeWhereUniqueInput;
  create: ConsumptionTypeCreateInput;
  update: ConsumptionTypeUpdateInput;
};

export type MutationCreateDoorTypeArgs = {
  data: DoorTypeCreateInput;
};

export type MutationCreateManyDoorTypeArgs = {
  data: Array<DoorTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteDoorTypeArgs = {
  where: DoorTypeWhereUniqueInput;
};

export type MutationUpdateDoorTypeArgs = {
  data: DoorTypeUpdateInput;
  where: DoorTypeWhereUniqueInput;
};

export type MutationDeleteManyDoorTypeArgs = {
  where?: Maybe<DoorTypeWhereInput>;
};

export type MutationUpdateManyDoorTypeArgs = {
  data: DoorTypeUpdateManyMutationInput;
  where?: Maybe<DoorTypeWhereInput>;
};

export type MutationUpsertDoorTypeArgs = {
  where: DoorTypeWhereUniqueInput;
  create: DoorTypeCreateInput;
  update: DoorTypeUpdateInput;
};

export type MutationCreateDriveTypeArgs = {
  data: DriveTypeCreateInput;
};

export type MutationCreateManyDriveTypeArgs = {
  data: Array<DriveTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteDriveTypeArgs = {
  where: DriveTypeWhereUniqueInput;
};

export type MutationUpdateDriveTypeArgs = {
  data: DriveTypeUpdateInput;
  where: DriveTypeWhereUniqueInput;
};

export type MutationDeleteManyDriveTypeArgs = {
  where?: Maybe<DriveTypeWhereInput>;
};

export type MutationUpdateManyDriveTypeArgs = {
  data: DriveTypeUpdateManyMutationInput;
  where?: Maybe<DriveTypeWhereInput>;
};

export type MutationUpsertDriveTypeArgs = {
  where: DriveTypeWhereUniqueInput;
  create: DriveTypeCreateInput;
  update: DriveTypeUpdateInput;
};

export type MutationCreateEmissionCodeTypeArgs = {
  data: EmissionCodeTypeCreateInput;
};

export type MutationCreateManyEmissionCodeTypeArgs = {
  data: Array<EmissionCodeTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteEmissionCodeTypeArgs = {
  where: EmissionCodeTypeWhereUniqueInput;
};

export type MutationUpdateEmissionCodeTypeArgs = {
  data: EmissionCodeTypeUpdateInput;
  where: EmissionCodeTypeWhereUniqueInput;
};

export type MutationDeleteManyEmissionCodeTypeArgs = {
  where?: Maybe<EmissionCodeTypeWhereInput>;
};

export type MutationUpdateManyEmissionCodeTypeArgs = {
  data: EmissionCodeTypeUpdateManyMutationInput;
  where?: Maybe<EmissionCodeTypeWhereInput>;
};

export type MutationUpsertEmissionCodeTypeArgs = {
  where: EmissionCodeTypeWhereUniqueInput;
  create: EmissionCodeTypeCreateInput;
  update: EmissionCodeTypeUpdateInput;
};

export type MutationCreateEnergyEfficiencyTypeArgs = {
  data: EnergyEfficiencyTypeCreateInput;
};

export type MutationCreateManyEnergyEfficiencyTypeArgs = {
  data: Array<EnergyEfficiencyTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteEnergyEfficiencyTypeArgs = {
  where: EnergyEfficiencyTypeWhereUniqueInput;
};

export type MutationUpdateEnergyEfficiencyTypeArgs = {
  data: EnergyEfficiencyTypeUpdateInput;
  where: EnergyEfficiencyTypeWhereUniqueInput;
};

export type MutationDeleteManyEnergyEfficiencyTypeArgs = {
  where?: Maybe<EnergyEfficiencyTypeWhereInput>;
};

export type MutationUpdateManyEnergyEfficiencyTypeArgs = {
  data: EnergyEfficiencyTypeUpdateManyMutationInput;
  where?: Maybe<EnergyEfficiencyTypeWhereInput>;
};

export type MutationUpsertEnergyEfficiencyTypeArgs = {
  where: EnergyEfficiencyTypeWhereUniqueInput;
  create: EnergyEfficiencyTypeCreateInput;
  update: EnergyEfficiencyTypeUpdateInput;
};

export type MutationCreateEquipmentTypeArgs = {
  data: EquipmentTypeCreateInput;
};

export type MutationCreateManyEquipmentTypeArgs = {
  data: Array<EquipmentTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteEquipmentTypeArgs = {
  where: EquipmentTypeWhereUniqueInput;
};

export type MutationUpdateEquipmentTypeArgs = {
  data: EquipmentTypeUpdateInput;
  where: EquipmentTypeWhereUniqueInput;
};

export type MutationDeleteManyEquipmentTypeArgs = {
  where?: Maybe<EquipmentTypeWhereInput>;
};

export type MutationUpdateManyEquipmentTypeArgs = {
  data: EquipmentTypeUpdateManyMutationInput;
  where?: Maybe<EquipmentTypeWhereInput>;
};

export type MutationUpsertEquipmentTypeArgs = {
  where: EquipmentTypeWhereUniqueInput;
  create: EquipmentTypeCreateInput;
  update: EquipmentTypeUpdateInput;
};

export type MutationCreateFuelTypeArgs = {
  data: FuelTypeCreateInput;
};

export type MutationCreateManyFuelTypeArgs = {
  data: Array<FuelTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteFuelTypeArgs = {
  where: FuelTypeWhereUniqueInput;
};

export type MutationUpdateFuelTypeArgs = {
  data: FuelTypeUpdateInput;
  where: FuelTypeWhereUniqueInput;
};

export type MutationDeleteManyFuelTypeArgs = {
  where?: Maybe<FuelTypeWhereInput>;
};

export type MutationUpdateManyFuelTypeArgs = {
  data: FuelTypeUpdateManyMutationInput;
  where?: Maybe<FuelTypeWhereInput>;
};

export type MutationUpsertFuelTypeArgs = {
  where: FuelTypeWhereUniqueInput;
  create: FuelTypeCreateInput;
  update: FuelTypeUpdateInput;
};

export type MutationCreateGateControllerArgs = {
  data: GateControllerCreateInput;
};

export type MutationCreateManyGateControllerArgs = {
  data: Array<GateControllerCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerArgs = {
  where: GateControllerWhereUniqueInput;
};

export type MutationUpdateGateControllerArgs = {
  data: GateControllerUpdateInput;
  where: GateControllerWhereUniqueInput;
};

export type MutationDeleteManyGateControllerArgs = {
  where?: Maybe<GateControllerWhereInput>;
};

export type MutationUpdateManyGateControllerArgs = {
  data: GateControllerUpdateManyMutationInput;
  where?: Maybe<GateControllerWhereInput>;
};

export type MutationUpsertGateControllerArgs = {
  where: GateControllerWhereUniqueInput;
  create: GateControllerCreateInput;
  update: GateControllerUpdateInput;
};

export type MutationCreateGateControllerDocumentArgs = {
  data: GateControllerDocumentCreateInput;
};

export type MutationCreateManyGateControllerDocumentArgs = {
  data: Array<GateControllerDocumentCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerDocumentArgs = {
  where: GateControllerDocumentWhereUniqueInput;
};

export type MutationUpdateGateControllerDocumentArgs = {
  data: GateControllerDocumentUpdateInput;
  where: GateControllerDocumentWhereUniqueInput;
};

export type MutationDeleteManyGateControllerDocumentArgs = {
  where?: Maybe<GateControllerDocumentWhereInput>;
};

export type MutationUpdateManyGateControllerDocumentArgs = {
  data: GateControllerDocumentUpdateManyMutationInput;
  where?: Maybe<GateControllerDocumentWhereInput>;
};

export type MutationUpsertGateControllerDocumentArgs = {
  where: GateControllerDocumentWhereUniqueInput;
  create: GateControllerDocumentCreateInput;
  update: GateControllerDocumentUpdateInput;
};

export type MutationCreateGateControllerRelUserArgs = {
  data: GateControllerRelUserCreateInput;
};

export type MutationCreateManyGateControllerRelUserArgs = {
  data: Array<GateControllerRelUserCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerRelUserArgs = {
  where: GateControllerRelUserWhereUniqueInput;
};

export type MutationUpdateGateControllerRelUserArgs = {
  data: GateControllerRelUserUpdateInput;
  where: GateControllerRelUserWhereUniqueInput;
};

export type MutationDeleteManyGateControllerRelUserArgs = {
  where?: Maybe<GateControllerRelUserWhereInput>;
};

export type MutationUpdateManyGateControllerRelUserArgs = {
  data: GateControllerRelUserUpdateManyMutationInput;
  where?: Maybe<GateControllerRelUserWhereInput>;
};

export type MutationUpsertGateControllerRelUserArgs = {
  where: GateControllerRelUserWhereUniqueInput;
  create: GateControllerRelUserCreateInput;
  update: GateControllerRelUserUpdateInput;
};

export type MutationCreateGateControllerRelVehicleArgs = {
  data: GateControllerRelVehicleCreateInput;
};

export type MutationCreateManyGateControllerRelVehicleArgs = {
  data: Array<GateControllerRelVehicleCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerRelVehicleArgs = {
  where: GateControllerRelVehicleWhereUniqueInput;
};

export type MutationUpdateGateControllerRelVehicleArgs = {
  data: GateControllerRelVehicleUpdateInput;
  where: GateControllerRelVehicleWhereUniqueInput;
};

export type MutationDeleteManyGateControllerRelVehicleArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
};

export type MutationUpdateManyGateControllerRelVehicleArgs = {
  data: GateControllerRelVehicleUpdateManyMutationInput;
  where?: Maybe<GateControllerRelVehicleWhereInput>;
};

export type MutationUpsertGateControllerRelVehicleArgs = {
  where: GateControllerRelVehicleWhereUniqueInput;
  create: GateControllerRelVehicleCreateInput;
  update: GateControllerRelVehicleUpdateInput;
};

export type MutationCreateGateControllerRuleArgs = {
  data: GateControllerRuleCreateInput;
};

export type MutationCreateManyGateControllerRuleArgs = {
  data: Array<GateControllerRuleCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerRuleArgs = {
  where: GateControllerRuleWhereUniqueInput;
};

export type MutationUpdateGateControllerRuleArgs = {
  data: GateControllerRuleUpdateInput;
  where: GateControllerRuleWhereUniqueInput;
};

export type MutationDeleteManyGateControllerRuleArgs = {
  where?: Maybe<GateControllerRuleWhereInput>;
};

export type MutationUpdateManyGateControllerRuleArgs = {
  data: GateControllerRuleUpdateManyMutationInput;
  where?: Maybe<GateControllerRuleWhereInput>;
};

export type MutationUpsertGateControllerRuleArgs = {
  where: GateControllerRuleWhereUniqueInput;
  create: GateControllerRuleCreateInput;
  update: GateControllerRuleUpdateInput;
};

export type MutationCreateGateControllerRuleSetArgs = {
  data: GateControllerRuleSetCreateInput;
};

export type MutationCreateManyGateControllerRuleSetArgs = {
  data: Array<GateControllerRuleSetCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerRuleSetArgs = {
  where: GateControllerRuleSetWhereUniqueInput;
};

export type MutationUpdateGateControllerRuleSetArgs = {
  data: GateControllerRuleSetUpdateInput;
  where: GateControllerRuleSetWhereUniqueInput;
};

export type MutationDeleteManyGateControllerRuleSetArgs = {
  where?: Maybe<GateControllerRuleSetWhereInput>;
};

export type MutationUpdateManyGateControllerRuleSetArgs = {
  data: GateControllerRuleSetUpdateManyMutationInput;
  where?: Maybe<GateControllerRuleSetWhereInput>;
};

export type MutationUpsertGateControllerRuleSetArgs = {
  where: GateControllerRuleSetWhereUniqueInput;
  create: GateControllerRuleSetCreateInput;
  update: GateControllerRuleSetUpdateInput;
};

export type MutationCreateGateControllerTransactionArgs = {
  data: GateControllerTransactionCreateInput;
};

export type MutationCreateManyGateControllerTransactionArgs = {
  data: Array<GateControllerTransactionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerTransactionArgs = {
  where: GateControllerTransactionWhereUniqueInput;
};

export type MutationUpdateGateControllerTransactionArgs = {
  data: GateControllerTransactionUpdateInput;
  where: GateControllerTransactionWhereUniqueInput;
};

export type MutationDeleteManyGateControllerTransactionArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
};

export type MutationUpdateManyGateControllerTransactionArgs = {
  data: GateControllerTransactionUpdateManyMutationInput;
  where?: Maybe<GateControllerTransactionWhereInput>;
};

export type MutationUpsertGateControllerTransactionArgs = {
  where: GateControllerTransactionWhereUniqueInput;
  create: GateControllerTransactionCreateInput;
  update: GateControllerTransactionUpdateInput;
};

export type MutationCreateGateControllerTypeArgs = {
  data: GateControllerTypeCreateInput;
};

export type MutationCreateManyGateControllerTypeArgs = {
  data: Array<GateControllerTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteGateControllerTypeArgs = {
  where: GateControllerTypeWhereUniqueInput;
};

export type MutationUpdateGateControllerTypeArgs = {
  data: GateControllerTypeUpdateInput;
  where: GateControllerTypeWhereUniqueInput;
};

export type MutationDeleteManyGateControllerTypeArgs = {
  where?: Maybe<GateControllerTypeWhereInput>;
};

export type MutationUpdateManyGateControllerTypeArgs = {
  data: GateControllerTypeUpdateManyMutationInput;
  where?: Maybe<GateControllerTypeWhereInput>;
};

export type MutationUpsertGateControllerTypeArgs = {
  where: GateControllerTypeWhereUniqueInput;
  create: GateControllerTypeCreateInput;
  update: GateControllerTypeUpdateInput;
};

export type MutationCreateMessageArgs = {
  data: MessageCreateInput;
};

export type MutationCreateManyMessageArgs = {
  data: Array<MessageCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteMessageArgs = {
  where: MessageWhereUniqueInput;
};

export type MutationUpdateMessageArgs = {
  data: MessageUpdateInput;
  where: MessageWhereUniqueInput;
};

export type MutationDeleteManyMessageArgs = {
  where?: Maybe<MessageWhereInput>;
};

export type MutationUpdateManyMessageArgs = {
  data: MessageUpdateManyMutationInput;
  where?: Maybe<MessageWhereInput>;
};

export type MutationUpsertMessageArgs = {
  where: MessageWhereUniqueInput;
  create: MessageCreateInput;
  update: MessageUpdateInput;
};

export type MutationCreateModelTypeArgs = {
  data: ModelTypeCreateInput;
};

export type MutationCreateManyModelTypeArgs = {
  data: Array<ModelTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteModelTypeArgs = {
  where: ModelTypeWhereUniqueInput;
};

export type MutationUpdateModelTypeArgs = {
  data: ModelTypeUpdateInput;
  where: ModelTypeWhereUniqueInput;
};

export type MutationDeleteManyModelTypeArgs = {
  where?: Maybe<ModelTypeWhereInput>;
};

export type MutationUpdateManyModelTypeArgs = {
  data: ModelTypeUpdateManyMutationInput;
  where?: Maybe<ModelTypeWhereInput>;
};

export type MutationUpsertModelTypeArgs = {
  where: ModelTypeWhereUniqueInput;
  create: ModelTypeCreateInput;
  update: ModelTypeUpdateInput;
};

export type MutationCreateNotificationDevicesArgs = {
  data: NotificationDevicesCreateInput;
};

export type MutationCreateManyNotificationDevicesArgs = {
  data: Array<NotificationDevicesCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteNotificationDevicesArgs = {
  where: NotificationDevicesWhereUniqueInput;
};

export type MutationUpdateNotificationDevicesArgs = {
  data: NotificationDevicesUpdateInput;
  where: NotificationDevicesWhereUniqueInput;
};

export type MutationDeleteManyNotificationDevicesArgs = {
  where?: Maybe<NotificationDevicesWhereInput>;
};

export type MutationUpdateManyNotificationDevicesArgs = {
  data: NotificationDevicesUpdateManyMutationInput;
  where?: Maybe<NotificationDevicesWhereInput>;
};

export type MutationUpsertNotificationDevicesArgs = {
  where: NotificationDevicesWhereUniqueInput;
  create: NotificationDevicesCreateInput;
  update: NotificationDevicesUpdateInput;
};

export type MutationCreateParticularUseTypeArgs = {
  data: ParticularUseTypeCreateInput;
};

export type MutationCreateManyParticularUseTypeArgs = {
  data: Array<ParticularUseTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteParticularUseTypeArgs = {
  where: ParticularUseTypeWhereUniqueInput;
};

export type MutationUpdateParticularUseTypeArgs = {
  data: ParticularUseTypeUpdateInput;
  where: ParticularUseTypeWhereUniqueInput;
};

export type MutationDeleteManyParticularUseTypeArgs = {
  where?: Maybe<ParticularUseTypeWhereInput>;
};

export type MutationUpdateManyParticularUseTypeArgs = {
  data: ParticularUseTypeUpdateManyMutationInput;
  where?: Maybe<ParticularUseTypeWhereInput>;
};

export type MutationUpsertParticularUseTypeArgs = {
  where: ParticularUseTypeWhereUniqueInput;
  create: ParticularUseTypeCreateInput;
  update: ParticularUseTypeUpdateInput;
};

export type MutationCreatePxlVisionManualVerificationArgs = {
  data: PxlVisionManualVerificationCreateInput;
};

export type MutationCreateManyPxlVisionManualVerificationArgs = {
  data: Array<PxlVisionManualVerificationCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeletePxlVisionManualVerificationArgs = {
  where: PxlVisionManualVerificationWhereUniqueInput;
};

export type MutationUpdatePxlVisionManualVerificationArgs = {
  data: PxlVisionManualVerificationUpdateInput;
  where: PxlVisionManualVerificationWhereUniqueInput;
};

export type MutationDeleteManyPxlVisionManualVerificationArgs = {
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
};

export type MutationUpdateManyPxlVisionManualVerificationArgs = {
  data: PxlVisionManualVerificationUpdateManyMutationInput;
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
};

export type MutationUpsertPxlVisionManualVerificationArgs = {
  where: PxlVisionManualVerificationWhereUniqueInput;
  create: PxlVisionManualVerificationCreateInput;
  update: PxlVisionManualVerificationUpdateInput;
};

export type MutationCreateRevokeReasonTypeArgs = {
  data: RevokeReasonTypeCreateInput;
};

export type MutationCreateManyRevokeReasonTypeArgs = {
  data: Array<RevokeReasonTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteRevokeReasonTypeArgs = {
  where: RevokeReasonTypeWhereUniqueInput;
};

export type MutationUpdateRevokeReasonTypeArgs = {
  data: RevokeReasonTypeUpdateInput;
  where: RevokeReasonTypeWhereUniqueInput;
};

export type MutationDeleteManyRevokeReasonTypeArgs = {
  where?: Maybe<RevokeReasonTypeWhereInput>;
};

export type MutationUpdateManyRevokeReasonTypeArgs = {
  data: RevokeReasonTypeUpdateManyMutationInput;
  where?: Maybe<RevokeReasonTypeWhereInput>;
};

export type MutationUpsertRevokeReasonTypeArgs = {
  where: RevokeReasonTypeWhereUniqueInput;
  create: RevokeReasonTypeCreateInput;
  update: RevokeReasonTypeUpdateInput;
};

export type MutationCreateSeatFrontTypeArgs = {
  data: SeatFrontTypeCreateInput;
};

export type MutationCreateManySeatFrontTypeArgs = {
  data: Array<SeatFrontTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSeatFrontTypeArgs = {
  where: SeatFrontTypeWhereUniqueInput;
};

export type MutationUpdateSeatFrontTypeArgs = {
  data: SeatFrontTypeUpdateInput;
  where: SeatFrontTypeWhereUniqueInput;
};

export type MutationDeleteManySeatFrontTypeArgs = {
  where?: Maybe<SeatFrontTypeWhereInput>;
};

export type MutationUpdateManySeatFrontTypeArgs = {
  data: SeatFrontTypeUpdateManyMutationInput;
  where?: Maybe<SeatFrontTypeWhereInput>;
};

export type MutationUpsertSeatFrontTypeArgs = {
  where: SeatFrontTypeWhereUniqueInput;
  create: SeatFrontTypeCreateInput;
  update: SeatFrontTypeUpdateInput;
};

export type MutationCreateSeatTypeArgs = {
  data: SeatTypeCreateInput;
};

export type MutationCreateManySeatTypeArgs = {
  data: Array<SeatTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSeatTypeArgs = {
  where: SeatTypeWhereUniqueInput;
};

export type MutationUpdateSeatTypeArgs = {
  data: SeatTypeUpdateInput;
  where: SeatTypeWhereUniqueInput;
};

export type MutationDeleteManySeatTypeArgs = {
  where?: Maybe<SeatTypeWhereInput>;
};

export type MutationUpdateManySeatTypeArgs = {
  data: SeatTypeUpdateManyMutationInput;
  where?: Maybe<SeatTypeWhereInput>;
};

export type MutationUpsertSeatTypeArgs = {
  where: SeatTypeWhereUniqueInput;
  create: SeatTypeCreateInput;
  update: SeatTypeUpdateInput;
};

export type MutationCreateSharedVehicleGroupArgs = {
  data: SharedVehicleGroupCreateInput;
};

export type MutationCreateManySharedVehicleGroupArgs = {
  data: Array<SharedVehicleGroupCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSharedVehicleGroupArgs = {
  where: SharedVehicleGroupWhereUniqueInput;
};

export type MutationUpdateSharedVehicleGroupArgs = {
  data: SharedVehicleGroupUpdateInput;
  where: SharedVehicleGroupWhereUniqueInput;
};

export type MutationDeleteManySharedVehicleGroupArgs = {
  where?: Maybe<SharedVehicleGroupWhereInput>;
};

export type MutationUpdateManySharedVehicleGroupArgs = {
  data: SharedVehicleGroupUpdateManyMutationInput;
  where?: Maybe<SharedVehicleGroupWhereInput>;
};

export type MutationUpsertSharedVehicleGroupArgs = {
  where: SharedVehicleGroupWhereUniqueInput;
  create: SharedVehicleGroupCreateInput;
  update: SharedVehicleGroupUpdateInput;
};

export type MutationCreateSharedVehicleGroupInvitedEmailArgs = {
  data: SharedVehicleGroupInvitedEmailCreateInput;
};

export type MutationCreateManySharedVehicleGroupInvitedEmailArgs = {
  data: Array<SharedVehicleGroupInvitedEmailCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSharedVehicleGroupInvitedEmailArgs = {
  where: SharedVehicleGroupInvitedEmailWhereUniqueInput;
};

export type MutationUpdateSharedVehicleGroupInvitedEmailArgs = {
  data: SharedVehicleGroupInvitedEmailUpdateInput;
  where: SharedVehicleGroupInvitedEmailWhereUniqueInput;
};

export type MutationDeleteManySharedVehicleGroupInvitedEmailArgs = {
  where?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
};

export type MutationUpdateManySharedVehicleGroupInvitedEmailArgs = {
  data: SharedVehicleGroupInvitedEmailUpdateManyMutationInput;
  where?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
};

export type MutationUpsertSharedVehicleGroupInvitedEmailArgs = {
  where: SharedVehicleGroupInvitedEmailWhereUniqueInput;
  create: SharedVehicleGroupInvitedEmailCreateInput;
  update: SharedVehicleGroupInvitedEmailUpdateInput;
};

export type MutationCreateSharedVehicleGroupRelAvailabilityArgs = {
  data: SharedVehicleGroupRelAvailabilityCreateInput;
};

export type MutationCreateManySharedVehicleGroupRelAvailabilityArgs = {
  data: Array<SharedVehicleGroupRelAvailabilityCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSharedVehicleGroupRelAvailabilityArgs = {
  where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
};

export type MutationUpdateSharedVehicleGroupRelAvailabilityArgs = {
  data: SharedVehicleGroupRelAvailabilityUpdateInput;
  where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
};

export type MutationDeleteManySharedVehicleGroupRelAvailabilityArgs = {
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
};

export type MutationUpdateManySharedVehicleGroupRelAvailabilityArgs = {
  data: SharedVehicleGroupRelAvailabilityUpdateManyMutationInput;
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
};

export type MutationUpsertSharedVehicleGroupRelAvailabilityArgs = {
  where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
  create: SharedVehicleGroupRelAvailabilityCreateInput;
  update: SharedVehicleGroupRelAvailabilityUpdateInput;
};

export type MutationCreateSharedVehicleGroupRelCompanyArgs = {
  data: SharedVehicleGroupRelCompanyCreateInput;
};

export type MutationCreateManySharedVehicleGroupRelCompanyArgs = {
  data: Array<SharedVehicleGroupRelCompanyCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSharedVehicleGroupRelCompanyArgs = {
  where: SharedVehicleGroupRelCompanyWhereUniqueInput;
};

export type MutationUpdateSharedVehicleGroupRelCompanyArgs = {
  data: SharedVehicleGroupRelCompanyUpdateInput;
  where: SharedVehicleGroupRelCompanyWhereUniqueInput;
};

export type MutationDeleteManySharedVehicleGroupRelCompanyArgs = {
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
};

export type MutationUpdateManySharedVehicleGroupRelCompanyArgs = {
  data: SharedVehicleGroupRelCompanyUpdateManyMutationInput;
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
};

export type MutationUpsertSharedVehicleGroupRelCompanyArgs = {
  where: SharedVehicleGroupRelCompanyWhereUniqueInput;
  create: SharedVehicleGroupRelCompanyCreateInput;
  update: SharedVehicleGroupRelCompanyUpdateInput;
};

export type MutationCreateSharedVehicleGroupRelUserArgs = {
  data: SharedVehicleGroupRelUserCreateInput;
};

export type MutationCreateManySharedVehicleGroupRelUserArgs = {
  data: Array<SharedVehicleGroupRelUserCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSharedVehicleGroupRelUserArgs = {
  where: SharedVehicleGroupRelUserWhereUniqueInput;
};

export type MutationUpdateSharedVehicleGroupRelUserArgs = {
  data: SharedVehicleGroupRelUserUpdateInput;
  where: SharedVehicleGroupRelUserWhereUniqueInput;
};

export type MutationDeleteManySharedVehicleGroupRelUserArgs = {
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
};

export type MutationUpdateManySharedVehicleGroupRelUserArgs = {
  data: SharedVehicleGroupRelUserUpdateManyMutationInput;
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
};

export type MutationUpsertSharedVehicleGroupRelUserArgs = {
  where: SharedVehicleGroupRelUserWhereUniqueInput;
  create: SharedVehicleGroupRelUserCreateInput;
  update: SharedVehicleGroupRelUserUpdateInput;
};

export type MutationCreateSharedVehicleGroupRelVehicleArgs = {
  data: SharedVehicleGroupRelVehicleCreateInput;
};

export type MutationCreateManySharedVehicleGroupRelVehicleArgs = {
  data: Array<SharedVehicleGroupRelVehicleCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSharedVehicleGroupRelVehicleArgs = {
  where: SharedVehicleGroupRelVehicleWhereUniqueInput;
};

export type MutationUpdateSharedVehicleGroupRelVehicleArgs = {
  data: SharedVehicleGroupRelVehicleUpdateInput;
  where: SharedVehicleGroupRelVehicleWhereUniqueInput;
};

export type MutationDeleteManySharedVehicleGroupRelVehicleArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
};

export type MutationUpdateManySharedVehicleGroupRelVehicleArgs = {
  data: SharedVehicleGroupRelVehicleUpdateManyMutationInput;
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
};

export type MutationUpsertSharedVehicleGroupRelVehicleArgs = {
  where: SharedVehicleGroupRelVehicleWhereUniqueInput;
  create: SharedVehicleGroupRelVehicleCreateInput;
  update: SharedVehicleGroupRelVehicleUpdateInput;
};

export type MutationCreateShieldColorTypeArgs = {
  data: ShieldColorTypeCreateInput;
};

export type MutationCreateManyShieldColorTypeArgs = {
  data: Array<ShieldColorTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteShieldColorTypeArgs = {
  where: ShieldColorTypeWhereUniqueInput;
};

export type MutationUpdateShieldColorTypeArgs = {
  data: ShieldColorTypeUpdateInput;
  where: ShieldColorTypeWhereUniqueInput;
};

export type MutationDeleteManyShieldColorTypeArgs = {
  where?: Maybe<ShieldColorTypeWhereInput>;
};

export type MutationUpdateManyShieldColorTypeArgs = {
  data: ShieldColorTypeUpdateManyMutationInput;
  where?: Maybe<ShieldColorTypeWhereInput>;
};

export type MutationUpsertShieldColorTypeArgs = {
  where: ShieldColorTypeWhereUniqueInput;
  create: ShieldColorTypeCreateInput;
  update: ShieldColorTypeUpdateInput;
};

export type MutationCreateSmartcarAuthArgs = {
  data: SmartcarAuthCreateInput;
};

export type MutationCreateManySmartcarAuthArgs = {
  data: Array<SmartcarAuthCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteSmartcarAuthArgs = {
  where: SmartcarAuthWhereUniqueInput;
};

export type MutationUpdateSmartcarAuthArgs = {
  data: SmartcarAuthUpdateInput;
  where: SmartcarAuthWhereUniqueInput;
};

export type MutationDeleteManySmartcarAuthArgs = {
  where?: Maybe<SmartcarAuthWhereInput>;
};

export type MutationUpdateManySmartcarAuthArgs = {
  data: SmartcarAuthUpdateManyMutationInput;
  where?: Maybe<SmartcarAuthWhereInput>;
};

export type MutationUpsertSmartcarAuthArgs = {
  where: SmartcarAuthWhereUniqueInput;
  create: SmartcarAuthCreateInput;
  update: SmartcarAuthUpdateInput;
};

export type MutationCreateTransmissionTypeArgs = {
  data: TransmissionTypeCreateInput;
};

export type MutationCreateManyTransmissionTypeArgs = {
  data: Array<TransmissionTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteTransmissionTypeArgs = {
  where: TransmissionTypeWhereUniqueInput;
};

export type MutationUpdateTransmissionTypeArgs = {
  data: TransmissionTypeUpdateInput;
  where: TransmissionTypeWhereUniqueInput;
};

export type MutationDeleteManyTransmissionTypeArgs = {
  where?: Maybe<TransmissionTypeWhereInput>;
};

export type MutationUpdateManyTransmissionTypeArgs = {
  data: TransmissionTypeUpdateManyMutationInput;
  where?: Maybe<TransmissionTypeWhereInput>;
};

export type MutationUpsertTransmissionTypeArgs = {
  where: TransmissionTypeWhereUniqueInput;
  create: TransmissionTypeCreateInput;
  update: TransmissionTypeUpdateInput;
};

export type MutationCreateUnpublishReasonTypeArgs = {
  data: UnpublishReasonTypeCreateInput;
};

export type MutationCreateManyUnpublishReasonTypeArgs = {
  data: Array<UnpublishReasonTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteUnpublishReasonTypeArgs = {
  where: UnpublishReasonTypeWhereUniqueInput;
};

export type MutationUpdateUnpublishReasonTypeArgs = {
  data: UnpublishReasonTypeUpdateInput;
  where: UnpublishReasonTypeWhereUniqueInput;
};

export type MutationDeleteManyUnpublishReasonTypeArgs = {
  where?: Maybe<UnpublishReasonTypeWhereInput>;
};

export type MutationUpdateManyUnpublishReasonTypeArgs = {
  data: UnpublishReasonTypeUpdateManyMutationInput;
  where?: Maybe<UnpublishReasonTypeWhereInput>;
};

export type MutationUpsertUnpublishReasonTypeArgs = {
  where: UnpublishReasonTypeWhereUniqueInput;
  create: UnpublishReasonTypeCreateInput;
  update: UnpublishReasonTypeUpdateInput;
};

export type MutationCreateUserArgs = {
  data: CreateUserInputDto;
};

export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};

export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};

export type MutationUpsertUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};

export type MutationCreateUserInvitedEmailArgs = {
  data: UserInvitedEmailCreateInput;
};

export type MutationCreateManyUserInvitedEmailArgs = {
  data: Array<UserInvitedEmailCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteUserInvitedEmailArgs = {
  where: UserInvitedEmailWhereUniqueInput;
};

export type MutationUpdateUserInvitedEmailArgs = {
  data: UserInvitedEmailUpdateInput;
  where: UserInvitedEmailWhereUniqueInput;
};

export type MutationDeleteManyUserInvitedEmailArgs = {
  where?: Maybe<UserInvitedEmailWhereInput>;
};

export type MutationUpdateManyUserInvitedEmailArgs = {
  data: UserInvitedEmailUpdateManyMutationInput;
  where?: Maybe<UserInvitedEmailWhereInput>;
};

export type MutationUpsertUserInvitedEmailArgs = {
  where: UserInvitedEmailWhereUniqueInput;
  create: UserInvitedEmailCreateInput;
  update: UserInvitedEmailUpdateInput;
};

export type MutationCreateVehicleArgs = {
  data: VehicleCreateInput;
};

export type MutationCreateManyVehicleArgs = {
  data: Array<VehicleCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleArgs = {
  where: VehicleWhereUniqueInput;
};

export type MutationUpdateVehicleArgs = {
  data: VehicleUpdateInput;
  where: VehicleWhereUniqueInput;
};

export type MutationDeleteManyVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
};

export type MutationUpdateManyVehicleArgs = {
  data: VehicleUpdateManyMutationInput;
  where?: Maybe<VehicleWhereInput>;
};

export type MutationUpsertVehicleArgs = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateInput;
  update: VehicleUpdateInput;
};

export type MutationCreateVehicleCloudBoxxLogArgs = {
  data: VehicleCloudBoxxLogCreateInput;
};

export type MutationCreateManyVehicleCloudBoxxLogArgs = {
  data: Array<VehicleCloudBoxxLogCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleCloudBoxxLogArgs = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
};

export type MutationUpdateVehicleCloudBoxxLogArgs = {
  data: VehicleCloudBoxxLogUpdateInput;
  where: VehicleCloudBoxxLogWhereUniqueInput;
};

export type MutationDeleteManyVehicleCloudBoxxLogArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
};

export type MutationUpdateManyVehicleCloudBoxxLogArgs = {
  data: VehicleCloudBoxxLogUpdateManyMutationInput;
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
};

export type MutationUpsertVehicleCloudBoxxLogArgs = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
  create: VehicleCloudBoxxLogCreateInput;
  update: VehicleCloudBoxxLogUpdateInput;
};

export type MutationCreateVehicleCloudBoxxStatusLogArgs = {
  data: VehicleCloudBoxxStatusLogCreateInput;
};

export type MutationCreateManyVehicleCloudBoxxStatusLogArgs = {
  data: Array<VehicleCloudBoxxStatusLogCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleCloudBoxxStatusLogArgs = {
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
};

export type MutationUpdateVehicleCloudBoxxStatusLogArgs = {
  data: VehicleCloudBoxxStatusLogUpdateInput;
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
};

export type MutationDeleteManyVehicleCloudBoxxStatusLogArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
};

export type MutationUpdateManyVehicleCloudBoxxStatusLogArgs = {
  data: VehicleCloudBoxxStatusLogUpdateManyMutationInput;
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
};

export type MutationUpsertVehicleCloudBoxxStatusLogArgs = {
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
  create: VehicleCloudBoxxStatusLogCreateInput;
  update: VehicleCloudBoxxStatusLogUpdateInput;
};

export type MutationCreateVehicleConnectionArgs = {
  data: VehicleConnectionCreateInput;
};

export type MutationCreateManyVehicleConnectionArgs = {
  data: Array<VehicleConnectionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleConnectionArgs = {
  where: VehicleConnectionWhereUniqueInput;
};

export type MutationUpdateVehicleConnectionArgs = {
  data: VehicleConnectionUpdateInput;
  where: VehicleConnectionWhereUniqueInput;
};

export type MutationDeleteManyVehicleConnectionArgs = {
  where?: Maybe<VehicleConnectionWhereInput>;
};

export type MutationUpdateManyVehicleConnectionArgs = {
  data: VehicleConnectionUpdateManyMutationInput;
  where?: Maybe<VehicleConnectionWhereInput>;
};

export type MutationUpsertVehicleConnectionArgs = {
  where: VehicleConnectionWhereUniqueInput;
  create: VehicleConnectionCreateInput;
  update: VehicleConnectionUpdateInput;
};

export type MutationCreateVehicleDamageArgs = {
  data: VehicleDamageCreateInput;
};

export type MutationCreateManyVehicleDamageArgs = {
  data: Array<VehicleDamageCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleDamageArgs = {
  where: VehicleDamageWhereUniqueInput;
};

export type MutationUpdateVehicleDamageArgs = {
  data: VehicleDamageUpdateInput;
  where: VehicleDamageWhereUniqueInput;
};

export type MutationDeleteManyVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
};

export type MutationUpdateManyVehicleDamageArgs = {
  data: VehicleDamageUpdateManyMutationInput;
  where?: Maybe<VehicleDamageWhereInput>;
};

export type MutationUpsertVehicleDamageArgs = {
  where: VehicleDamageWhereUniqueInput;
  create: VehicleDamageCreateInput;
  update: VehicleDamageUpdateInput;
};

export type MutationCreateVehicleDamageCommentArgs = {
  data: VehicleDamageCommentCreateInput;
};

export type MutationCreateManyVehicleDamageCommentArgs = {
  data: Array<VehicleDamageCommentCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleDamageCommentArgs = {
  where: VehicleDamageCommentWhereUniqueInput;
};

export type MutationUpdateVehicleDamageCommentArgs = {
  data: VehicleDamageCommentUpdateInput;
  where: VehicleDamageCommentWhereUniqueInput;
};

export type MutationDeleteManyVehicleDamageCommentArgs = {
  where?: Maybe<VehicleDamageCommentWhereInput>;
};

export type MutationUpdateManyVehicleDamageCommentArgs = {
  data: VehicleDamageCommentUpdateManyMutationInput;
  where?: Maybe<VehicleDamageCommentWhereInput>;
};

export type MutationUpsertVehicleDamageCommentArgs = {
  where: VehicleDamageCommentWhereUniqueInput;
  create: VehicleDamageCommentCreateInput;
  update: VehicleDamageCommentUpdateInput;
};

export type MutationCreateVehicleEquipmentArgs = {
  data: VehicleEquipmentCreateInput;
};

export type MutationCreateManyVehicleEquipmentArgs = {
  data: Array<VehicleEquipmentCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleEquipmentArgs = {
  where: VehicleEquipmentWhereUniqueInput;
};

export type MutationUpdateVehicleEquipmentArgs = {
  data: VehicleEquipmentUpdateInput;
  where: VehicleEquipmentWhereUniqueInput;
};

export type MutationDeleteManyVehicleEquipmentArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
};

export type MutationUpdateManyVehicleEquipmentArgs = {
  data: VehicleEquipmentUpdateManyMutationInput;
  where?: Maybe<VehicleEquipmentWhereInput>;
};

export type MutationUpsertVehicleEquipmentArgs = {
  where: VehicleEquipmentWhereUniqueInput;
  create: VehicleEquipmentCreateInput;
  update: VehicleEquipmentUpdateInput;
};

export type MutationCreateVehicleInsuranceDocumentArgs = {
  data: VehicleInsuranceDocumentCreateInput;
};

export type MutationCreateManyVehicleInsuranceDocumentArgs = {
  data: Array<VehicleInsuranceDocumentCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleInsuranceDocumentArgs = {
  where: VehicleInsuranceDocumentWhereUniqueInput;
};

export type MutationUpdateVehicleInsuranceDocumentArgs = {
  data: VehicleInsuranceDocumentUpdateInput;
  where: VehicleInsuranceDocumentWhereUniqueInput;
};

export type MutationDeleteManyVehicleInsuranceDocumentArgs = {
  where?: Maybe<VehicleInsuranceDocumentWhereInput>;
};

export type MutationUpdateManyVehicleInsuranceDocumentArgs = {
  data: VehicleInsuranceDocumentUpdateManyMutationInput;
  where?: Maybe<VehicleInsuranceDocumentWhereInput>;
};

export type MutationUpsertVehicleInsuranceDocumentArgs = {
  where: VehicleInsuranceDocumentWhereUniqueInput;
  create: VehicleInsuranceDocumentCreateInput;
  update: VehicleInsuranceDocumentUpdateInput;
};

export type MutationCreateVehicleInsuranceRuleArgs = {
  data: VehicleInsuranceRuleCreateInput;
};

export type MutationCreateManyVehicleInsuranceRuleArgs = {
  data: Array<VehicleInsuranceRuleCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleInsuranceRuleArgs = {
  where: VehicleInsuranceRuleWhereUniqueInput;
};

export type MutationUpdateVehicleInsuranceRuleArgs = {
  data: VehicleInsuranceRuleUpdateInput;
  where: VehicleInsuranceRuleWhereUniqueInput;
};

export type MutationDeleteManyVehicleInsuranceRuleArgs = {
  where?: Maybe<VehicleInsuranceRuleWhereInput>;
};

export type MutationUpdateManyVehicleInsuranceRuleArgs = {
  data: VehicleInsuranceRuleUpdateManyMutationInput;
  where?: Maybe<VehicleInsuranceRuleWhereInput>;
};

export type MutationUpsertVehicleInsuranceRuleArgs = {
  where: VehicleInsuranceRuleWhereUniqueInput;
  create: VehicleInsuranceRuleCreateInput;
  update: VehicleInsuranceRuleUpdateInput;
};

export type MutationCreateVehicleInsuranceRuleSetArgs = {
  data: VehicleInsuranceRuleSetCreateInput;
};

export type MutationCreateManyVehicleInsuranceRuleSetArgs = {
  data: Array<VehicleInsuranceRuleSetCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleInsuranceRuleSetArgs = {
  where: VehicleInsuranceRuleSetWhereUniqueInput;
};

export type MutationUpdateVehicleInsuranceRuleSetArgs = {
  data: VehicleInsuranceRuleSetUpdateInput;
  where: VehicleInsuranceRuleSetWhereUniqueInput;
};

export type MutationDeleteManyVehicleInsuranceRuleSetArgs = {
  where?: Maybe<VehicleInsuranceRuleSetWhereInput>;
};

export type MutationUpdateManyVehicleInsuranceRuleSetArgs = {
  data: VehicleInsuranceRuleSetUpdateManyMutationInput;
  where?: Maybe<VehicleInsuranceRuleSetWhereInput>;
};

export type MutationUpsertVehicleInsuranceRuleSetArgs = {
  where: VehicleInsuranceRuleSetWhereUniqueInput;
  create: VehicleInsuranceRuleSetCreateInput;
  update: VehicleInsuranceRuleSetUpdateInput;
};

export type MutationCreateVehicleInsuranceTypeArgs = {
  data: VehicleInsuranceTypeCreateInput;
};

export type MutationCreateManyVehicleInsuranceTypeArgs = {
  data: Array<VehicleInsuranceTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleInsuranceTypeArgs = {
  where: VehicleInsuranceTypeWhereUniqueInput;
};

export type MutationUpdateVehicleInsuranceTypeArgs = {
  data: VehicleInsuranceTypeUpdateInput;
  where: VehicleInsuranceTypeWhereUniqueInput;
};

export type MutationDeleteManyVehicleInsuranceTypeArgs = {
  where?: Maybe<VehicleInsuranceTypeWhereInput>;
};

export type MutationUpdateManyVehicleInsuranceTypeArgs = {
  data: VehicleInsuranceTypeUpdateManyMutationInput;
  where?: Maybe<VehicleInsuranceTypeWhereInput>;
};

export type MutationUpsertVehicleInsuranceTypeArgs = {
  where: VehicleInsuranceTypeWhereUniqueInput;
  create: VehicleInsuranceTypeCreateInput;
  update: VehicleInsuranceTypeUpdateInput;
};

export type MutationCreateVehicleKindTypeArgs = {
  data: VehicleKindTypeCreateInput;
};

export type MutationCreateManyVehicleKindTypeArgs = {
  data: Array<VehicleKindTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleKindTypeArgs = {
  where: VehicleKindTypeWhereUniqueInput;
};

export type MutationUpdateVehicleKindTypeArgs = {
  data: VehicleKindTypeUpdateInput;
  where: VehicleKindTypeWhereUniqueInput;
};

export type MutationDeleteManyVehicleKindTypeArgs = {
  where?: Maybe<VehicleKindTypeWhereInput>;
};

export type MutationUpdateManyVehicleKindTypeArgs = {
  data: VehicleKindTypeUpdateManyMutationInput;
  where?: Maybe<VehicleKindTypeWhereInput>;
};

export type MutationUpsertVehicleKindTypeArgs = {
  where: VehicleKindTypeWhereUniqueInput;
  create: VehicleKindTypeCreateInput;
  update: VehicleKindTypeUpdateInput;
};

export type MutationCreateVehicleLocationArgs = {
  data: VehicleLocationCreateInput;
};

export type MutationCreateManyVehicleLocationArgs = {
  data: Array<VehicleLocationCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleLocationArgs = {
  where: VehicleLocationWhereUniqueInput;
};

export type MutationUpdateVehicleLocationArgs = {
  data: VehicleLocationUpdateInput;
  where: VehicleLocationWhereUniqueInput;
};

export type MutationDeleteManyVehicleLocationArgs = {
  where?: Maybe<VehicleLocationWhereInput>;
};

export type MutationUpdateManyVehicleLocationArgs = {
  data: VehicleLocationUpdateManyMutationInput;
  where?: Maybe<VehicleLocationWhereInput>;
};

export type MutationUpsertVehicleLocationArgs = {
  where: VehicleLocationWhereUniqueInput;
  create: VehicleLocationCreateInput;
  update: VehicleLocationUpdateInput;
};

export type MutationCreateVehiclePhotoArgs = {
  data: VehiclePhotoCreateInput;
};

export type MutationCreateManyVehiclePhotoArgs = {
  data: Array<VehiclePhotoCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehiclePhotoArgs = {
  where: VehiclePhotoWhereUniqueInput;
};

export type MutationUpdateVehiclePhotoArgs = {
  data: VehiclePhotoUpdateInput;
  where: VehiclePhotoWhereUniqueInput;
};

export type MutationDeleteManyVehiclePhotoArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
};

export type MutationUpdateManyVehiclePhotoArgs = {
  data: VehiclePhotoUpdateManyMutationInput;
  where?: Maybe<VehiclePhotoWhereInput>;
};

export type MutationUpsertVehiclePhotoArgs = {
  where: VehiclePhotoWhereUniqueInput;
  create: VehiclePhotoCreateInput;
  update: VehiclePhotoUpdateInput;
};

export type MutationCreateVehicleTypeArgs = {
  data: VehicleTypeCreateInput;
};

export type MutationCreateManyVehicleTypeArgs = {
  data: Array<VehicleTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteVehicleTypeArgs = {
  where: VehicleTypeWhereUniqueInput;
};

export type MutationUpdateVehicleTypeArgs = {
  data: VehicleTypeUpdateInput;
  where: VehicleTypeWhereUniqueInput;
};

export type MutationDeleteManyVehicleTypeArgs = {
  where?: Maybe<VehicleTypeWhereInput>;
};

export type MutationUpdateManyVehicleTypeArgs = {
  data: VehicleTypeUpdateManyMutationInput;
  where?: Maybe<VehicleTypeWhereInput>;
};

export type MutationUpsertVehicleTypeArgs = {
  where: VehicleTypeWhereUniqueInput;
  create: VehicleTypeCreateInput;
  update: VehicleTypeUpdateInput;
};

export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};

export type MutationMaintenance_DeleteVehicleArgs = {
  vehicleId: Scalars['String'];
};

export type MutationRevokeAffectedBookingsArgs = {
  bookings: Array<VehicleRevokeAffectedBookingArgs>;
};

export type MutationAddVehicleAvailabilityArgs = {
  vehicleId: Scalars['String'];
  availabilityId?: Maybe<Scalars['String']>;
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  isAvailable: Scalars['Boolean'];
  type: AvailabilityType;
  sharedVehicleGroups: Array<Scalars['String']>;
};

export type MutationUpdateVehicleAvailabilityArgs = {
  availabilityId: Scalars['String'];
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<AvailabilityType>;
  sharedVehicleGroups?: Maybe<Array<Scalars['String']>>;
};

export type MutationDeleteVehicleAvailabilityArgs = {
  availabilityId: Scalars['String'];
};

export type MutationRevokeBookingArgs = {
  bookingId: Scalars['String'];
  revokeReason: Scalars['String'];
  refunding?: Maybe<Scalars['Float']>;
  withInsurance?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateNewBookingArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  vehicleId: Scalars['String'];
  userId: Scalars['String'];
  bookingComment: Scalars['String'];
};

export type MutationStartChargingStationArgs = {
  evseId: Scalars['String'];
};

export type MutationStopChargingStationArgs = {
  evseId: Scalars['String'];
};

export type MutationUpdateChargingStationRfidInvisiaArgs = {
  data: ChargingStationRfidInvisiaUpdateInput;
  where?: Maybe<ChargingStationRfidInvisiaWhereUniqueInput>;
};

export type MutationStartChargingStationTransactionSessionArgs = {
  transactionId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type MutationStopChargingStationTransactionSessionArgs = {
  transactionId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type MutationFinishChargingStationTransactionSessionArgs = {
  transactionId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type MutationUploadBillingAccountDocumentsArgs = {
  files: Array<Scalars['Upload']>;
  billingAccountId?: Maybe<Scalars['String']>;
};

export type MutationGenerateCompanyOnboardingStripeLinkArgs = {
  companyId: Scalars['String'];
};

export type MutationUploadCompanyLogoArgs = {
  file: Scalars['Upload'];
  companyId: Scalars['String'];
};

export type MutationOpenGateControllerArgs = {
  gateId: Scalars['String'];
  comment: Scalars['String'];
};

export type MutationUploadDocumentsArgs = {
  files: Array<Scalars['Upload']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type MutationDeleteDocumentArgs = {
  vehicleDocumentId: Scalars['String'];
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type MutationResetPasswordArgs = {
  userId: Scalars['String'];
};

export type MutationSaveOneTimePasswordArgs = {
  userId: Scalars['String'];
  oneTimePassword: Scalars['String'];
};

export type MutationGenerateUserOnboardingStripeLinkArgs = {
  userId: Scalars['String'];
};

export type MutationResendEmailVerificationLinkArgs = {
  userId: Scalars['String'];
};

export type MutationSendMessageArgs = {
  bookingId: Scalars['String'];
  message: Scalars['String'];
  variant: SendMessageEnum;
};

export type MutationUploadVerificationDocumentArgs = {
  file: Scalars['Upload'];
  userId: Scalars['String'];
};

export type MutationVehicleCloudBoxxConnectArgs = {
  vehicleId?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
};

export type MutationVehicleCloudBoxxDisconnectArgs = {
  vehicleId: Scalars['String'];
};

export type MutationVehicleCloudBoxxActivateArgs = {
  vehicleId: Scalars['String'];
  activate: Scalars['Boolean'];
  qnr: Scalars['String'];
};

export type MutationChangeDeviceStatusArgs = {
  value: Scalars['String'];
  property: Scalars['String'];
  vehicleId: Scalars['String'];
};

export type MutationUploadImagesArgs = {
  files: Array<Scalars['Upload']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type MutationUploadDamageImagesArgs = {
  files: Array<Scalars['Upload']>;
  type?: Maybe<VehiclePhotoType>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type MutationDeleteImageArgs = {
  vehiclePhotoId: Scalars['String'];
  vehicleId?: Maybe<Scalars['String']>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type NestedEnumAvailabilityTypeFilter = {
  equals?: Maybe<AvailabilityType>;
  in?: Maybe<Array<AvailabilityType>>;
  notIn?: Maybe<Array<AvailabilityType>>;
  not?: Maybe<NestedEnumAvailabilityTypeFilter>;
};

export type NestedEnumAvailabilityTypeWithAggregatesFilter = {
  equals?: Maybe<AvailabilityType>;
  in?: Maybe<Array<AvailabilityType>>;
  notIn?: Maybe<Array<AvailabilityType>>;
  not?: Maybe<NestedEnumAvailabilityTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumAvailabilityTypeFilter>;
  _max?: Maybe<NestedEnumAvailabilityTypeFilter>;
};

export type NestedEnumBillingAccountPaymentFrequencyFilter = {
  equals?: Maybe<BillingAccountPaymentFrequency>;
  in?: Maybe<Array<BillingAccountPaymentFrequency>>;
  notIn?: Maybe<Array<BillingAccountPaymentFrequency>>;
  not?: Maybe<NestedEnumBillingAccountPaymentFrequencyFilter>;
};

export type NestedEnumBillingAccountPaymentFrequencyWithAggregatesFilter = {
  equals?: Maybe<BillingAccountPaymentFrequency>;
  in?: Maybe<Array<BillingAccountPaymentFrequency>>;
  notIn?: Maybe<Array<BillingAccountPaymentFrequency>>;
  not?: Maybe<NestedEnumBillingAccountPaymentFrequencyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBillingAccountPaymentFrequencyFilter>;
  _max?: Maybe<NestedEnumBillingAccountPaymentFrequencyFilter>;
};

export type NestedEnumBookingQuestionKindFilter = {
  equals?: Maybe<BookingQuestionKind>;
  in?: Maybe<Array<BookingQuestionKind>>;
  notIn?: Maybe<Array<BookingQuestionKind>>;
  not?: Maybe<NestedEnumBookingQuestionKindFilter>;
};

export type NestedEnumBookingQuestionKindWithAggregatesFilter = {
  equals?: Maybe<BookingQuestionKind>;
  in?: Maybe<Array<BookingQuestionKind>>;
  notIn?: Maybe<Array<BookingQuestionKind>>;
  not?: Maybe<NestedEnumBookingQuestionKindWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBookingQuestionKindFilter>;
  _max?: Maybe<NestedEnumBookingQuestionKindFilter>;
};

export type NestedEnumBookingQuestionTypeFilter = {
  equals?: Maybe<BookingQuestionType>;
  in?: Maybe<Array<BookingQuestionType>>;
  notIn?: Maybe<Array<BookingQuestionType>>;
  not?: Maybe<NestedEnumBookingQuestionTypeFilter>;
};

export type NestedEnumBookingQuestionTypeWithAggregatesFilter = {
  equals?: Maybe<BookingQuestionType>;
  in?: Maybe<Array<BookingQuestionType>>;
  notIn?: Maybe<Array<BookingQuestionType>>;
  not?: Maybe<NestedEnumBookingQuestionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumBookingQuestionTypeFilter>;
  _max?: Maybe<NestedEnumBookingQuestionTypeFilter>;
};

export type NestedEnumChargingStationRulePropertyFilter = {
  equals?: Maybe<ChargingStationRuleProperty>;
  in?: Maybe<Array<ChargingStationRuleProperty>>;
  notIn?: Maybe<Array<ChargingStationRuleProperty>>;
  not?: Maybe<NestedEnumChargingStationRulePropertyFilter>;
};

export type NestedEnumChargingStationRulePropertyWithAggregatesFilter = {
  equals?: Maybe<ChargingStationRuleProperty>;
  in?: Maybe<Array<ChargingStationRuleProperty>>;
  notIn?: Maybe<Array<ChargingStationRuleProperty>>;
  not?: Maybe<NestedEnumChargingStationRulePropertyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargingStationRulePropertyFilter>;
  _max?: Maybe<NestedEnumChargingStationRulePropertyFilter>;
};

export type NestedEnumChargingStationTariffTypeFilter = {
  equals?: Maybe<ChargingStationTariffType>;
  in?: Maybe<Array<ChargingStationTariffType>>;
  notIn?: Maybe<Array<ChargingStationTariffType>>;
  not?: Maybe<NestedEnumChargingStationTariffTypeFilter>;
};

export type NestedEnumChargingStationTariffTypeWithAggregatesFilter = {
  equals?: Maybe<ChargingStationTariffType>;
  in?: Maybe<Array<ChargingStationTariffType>>;
  notIn?: Maybe<Array<ChargingStationTariffType>>;
  not?: Maybe<NestedEnumChargingStationTariffTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumChargingStationTariffTypeFilter>;
  _max?: Maybe<NestedEnumChargingStationTariffTypeFilter>;
};

export type NestedEnumChargingStationTransactionAuthTypeNullableFilter = {
  equals?: Maybe<ChargingStationTransactionAuthType>;
  in?: Maybe<Array<ChargingStationTransactionAuthType>>;
  notIn?: Maybe<Array<ChargingStationTransactionAuthType>>;
  not?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableFilter>;
};

export type NestedEnumChargingStationTransactionAuthTypeNullableWithAggregatesFilter =
  {
    equals?: Maybe<ChargingStationTransactionAuthType>;
    in?: Maybe<Array<ChargingStationTransactionAuthType>>;
    notIn?: Maybe<Array<ChargingStationTransactionAuthType>>;
    not?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableWithAggregatesFilter>;
    _count?: Maybe<NestedIntNullableFilter>;
    _min?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableFilter>;
    _max?: Maybe<NestedEnumChargingStationTransactionAuthTypeNullableFilter>;
  };

export type NestedEnumChargingStationTransactionStatusNullableFilter = {
  equals?: Maybe<ChargingStationTransactionStatus>;
  in?: Maybe<Array<ChargingStationTransactionStatus>>;
  notIn?: Maybe<Array<ChargingStationTransactionStatus>>;
  not?: Maybe<NestedEnumChargingStationTransactionStatusNullableFilter>;
};

export type NestedEnumChargingStationTransactionStatusNullableWithAggregatesFilter =
  {
    equals?: Maybe<ChargingStationTransactionStatus>;
    in?: Maybe<Array<ChargingStationTransactionStatus>>;
    notIn?: Maybe<Array<ChargingStationTransactionStatus>>;
    not?: Maybe<NestedEnumChargingStationTransactionStatusNullableWithAggregatesFilter>;
    _count?: Maybe<NestedIntNullableFilter>;
    _min?: Maybe<NestedEnumChargingStationTransactionStatusNullableFilter>;
    _max?: Maybe<NestedEnumChargingStationTransactionStatusNullableFilter>;
  };

export type NestedEnumGateControllerRulePropertyFilter = {
  equals?: Maybe<GateControllerRuleProperty>;
  in?: Maybe<Array<GateControllerRuleProperty>>;
  notIn?: Maybe<Array<GateControllerRuleProperty>>;
  not?: Maybe<NestedEnumGateControllerRulePropertyFilter>;
};

export type NestedEnumGateControllerRulePropertyWithAggregatesFilter = {
  equals?: Maybe<GateControllerRuleProperty>;
  in?: Maybe<Array<GateControllerRuleProperty>>;
  notIn?: Maybe<Array<GateControllerRuleProperty>>;
  not?: Maybe<NestedEnumGateControllerRulePropertyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGateControllerRulePropertyFilter>;
  _max?: Maybe<NestedEnumGateControllerRulePropertyFilter>;
};

export type NestedEnumGateControllerRuleSetTypeFilter = {
  equals?: Maybe<GateControllerRuleSetType>;
  in?: Maybe<Array<GateControllerRuleSetType>>;
  notIn?: Maybe<Array<GateControllerRuleSetType>>;
  not?: Maybe<NestedEnumGateControllerRuleSetTypeFilter>;
};

export type NestedEnumGateControllerRuleSetTypeWithAggregatesFilter = {
  equals?: Maybe<GateControllerRuleSetType>;
  in?: Maybe<Array<GateControllerRuleSetType>>;
  notIn?: Maybe<Array<GateControllerRuleSetType>>;
  not?: Maybe<NestedEnumGateControllerRuleSetTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumGateControllerRuleSetTypeFilter>;
  _max?: Maybe<NestedEnumGateControllerRuleSetTypeFilter>;
};

export type NestedEnumGenderTypeNullableFilter = {
  equals?: Maybe<GenderType>;
  in?: Maybe<Array<GenderType>>;
  notIn?: Maybe<Array<GenderType>>;
  not?: Maybe<NestedEnumGenderTypeNullableFilter>;
};

export type NestedEnumGenderTypeNullableWithAggregatesFilter = {
  equals?: Maybe<GenderType>;
  in?: Maybe<Array<GenderType>>;
  notIn?: Maybe<Array<GenderType>>;
  not?: Maybe<NestedEnumGenderTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumGenderTypeNullableFilter>;
  _max?: Maybe<NestedEnumGenderTypeNullableFilter>;
};

export type NestedEnumInsuranceReportingFilter = {
  equals?: Maybe<InsuranceReporting>;
  in?: Maybe<Array<InsuranceReporting>>;
  notIn?: Maybe<Array<InsuranceReporting>>;
  not?: Maybe<NestedEnumInsuranceReportingFilter>;
};

export type NestedEnumInsuranceReportingWithAggregatesFilter = {
  equals?: Maybe<InsuranceReporting>;
  in?: Maybe<Array<InsuranceReporting>>;
  notIn?: Maybe<Array<InsuranceReporting>>;
  not?: Maybe<NestedEnumInsuranceReportingWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumInsuranceReportingFilter>;
  _max?: Maybe<NestedEnumInsuranceReportingFilter>;
};

export type NestedEnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
};

export type NestedEnumMessageTypeWithAggregatesFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  notIn?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
};

export type NestedEnumOwnershipFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipFilter>;
};

export type NestedEnumOwnershipNullableFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipNullableFilter>;
};

export type NestedEnumOwnershipNullableWithAggregatesFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumOwnershipNullableFilter>;
  _max?: Maybe<NestedEnumOwnershipNullableFilter>;
};

export type NestedEnumOwnershipWithAggregatesFilter = {
  equals?: Maybe<Ownership>;
  in?: Maybe<Array<Ownership>>;
  notIn?: Maybe<Array<Ownership>>;
  not?: Maybe<NestedEnumOwnershipWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumOwnershipFilter>;
  _max?: Maybe<NestedEnumOwnershipFilter>;
};

export type NestedEnumPxlVisionVerificationTypeFilter = {
  equals?: Maybe<PxlVisionVerificationType>;
  in?: Maybe<Array<PxlVisionVerificationType>>;
  notIn?: Maybe<Array<PxlVisionVerificationType>>;
  not?: Maybe<NestedEnumPxlVisionVerificationTypeFilter>;
};

export type NestedEnumPxlVisionVerificationTypeWithAggregatesFilter = {
  equals?: Maybe<PxlVisionVerificationType>;
  in?: Maybe<Array<PxlVisionVerificationType>>;
  notIn?: Maybe<Array<PxlVisionVerificationType>>;
  not?: Maybe<NestedEnumPxlVisionVerificationTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumPxlVisionVerificationTypeFilter>;
  _max?: Maybe<NestedEnumPxlVisionVerificationTypeFilter>;
};

export type NestedEnumRuleCompareOperatorFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorFilter>;
};

export type NestedEnumRuleCompareOperatorNullableFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorNullableFilter>;
};

export type NestedEnumRuleCompareOperatorNullableWithAggregatesFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumRuleCompareOperatorNullableFilter>;
  _max?: Maybe<NestedEnumRuleCompareOperatorNullableFilter>;
};

export type NestedEnumRuleCompareOperatorWithAggregatesFilter = {
  equals?: Maybe<RuleCompareOperator>;
  in?: Maybe<Array<RuleCompareOperator>>;
  notIn?: Maybe<Array<RuleCompareOperator>>;
  not?: Maybe<NestedEnumRuleCompareOperatorWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRuleCompareOperatorFilter>;
  _max?: Maybe<NestedEnumRuleCompareOperatorFilter>;
};

export type NestedEnumRulePropertyFilter = {
  equals?: Maybe<RuleProperty>;
  in?: Maybe<Array<RuleProperty>>;
  notIn?: Maybe<Array<RuleProperty>>;
  not?: Maybe<NestedEnumRulePropertyFilter>;
};

export type NestedEnumRulePropertyWithAggregatesFilter = {
  equals?: Maybe<RuleProperty>;
  in?: Maybe<Array<RuleProperty>>;
  notIn?: Maybe<Array<RuleProperty>>;
  not?: Maybe<NestedEnumRulePropertyWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumRulePropertyFilter>;
  _max?: Maybe<NestedEnumRulePropertyFilter>;
};

export type NestedEnumStripePaymentMethodTypeNullableFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeNullableFilter>;
};

export type NestedEnumStripePaymentMethodTypeNullableWithAggregatesFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeNullableFilter>;
};

export type NestedEnumUserRoleNullableFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  notIn?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleNullableFilter>;
};

export type NestedEnumUserRoleNullableWithAggregatesFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  notIn?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumUserRoleNullableFilter>;
  _max?: Maybe<NestedEnumUserRoleNullableFilter>;
};

export type NestedEnumVehicleConnectionTypeFilter = {
  equals?: Maybe<VehicleConnectionType>;
  in?: Maybe<Array<VehicleConnectionType>>;
  notIn?: Maybe<Array<VehicleConnectionType>>;
  not?: Maybe<NestedEnumVehicleConnectionTypeFilter>;
};

export type NestedEnumVehicleConnectionTypeWithAggregatesFilter = {
  equals?: Maybe<VehicleConnectionType>;
  in?: Maybe<Array<VehicleConnectionType>>;
  notIn?: Maybe<Array<VehicleConnectionType>>;
  not?: Maybe<NestedEnumVehicleConnectionTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVehicleConnectionTypeFilter>;
  _max?: Maybe<NestedEnumVehicleConnectionTypeFilter>;
};

export type NestedEnumVehicleDamageKindNullableFilter = {
  equals?: Maybe<VehicleDamageKind>;
  in?: Maybe<Array<VehicleDamageKind>>;
  notIn?: Maybe<Array<VehicleDamageKind>>;
  not?: Maybe<NestedEnumVehicleDamageKindNullableFilter>;
};

export type NestedEnumVehicleDamageKindNullableWithAggregatesFilter = {
  equals?: Maybe<VehicleDamageKind>;
  in?: Maybe<Array<VehicleDamageKind>>;
  notIn?: Maybe<Array<VehicleDamageKind>>;
  not?: Maybe<NestedEnumVehicleDamageKindNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumVehicleDamageKindNullableFilter>;
  _max?: Maybe<NestedEnumVehicleDamageKindNullableFilter>;
};

export type NestedEnumVehicleDamageTimeNullableFilter = {
  equals?: Maybe<VehicleDamageTime>;
  in?: Maybe<Array<VehicleDamageTime>>;
  notIn?: Maybe<Array<VehicleDamageTime>>;
  not?: Maybe<NestedEnumVehicleDamageTimeNullableFilter>;
};

export type NestedEnumVehicleDamageTimeNullableWithAggregatesFilter = {
  equals?: Maybe<VehicleDamageTime>;
  in?: Maybe<Array<VehicleDamageTime>>;
  notIn?: Maybe<Array<VehicleDamageTime>>;
  not?: Maybe<NestedEnumVehicleDamageTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedEnumVehicleDamageTimeNullableFilter>;
  _max?: Maybe<NestedEnumVehicleDamageTimeNullableFilter>;
};

export type NestedEnumVehicleDamageTypeFilter = {
  equals?: Maybe<VehicleDamageType>;
  in?: Maybe<Array<VehicleDamageType>>;
  notIn?: Maybe<Array<VehicleDamageType>>;
  not?: Maybe<NestedEnumVehicleDamageTypeFilter>;
};

export type NestedEnumVehicleDamageTypeWithAggregatesFilter = {
  equals?: Maybe<VehicleDamageType>;
  in?: Maybe<Array<VehicleDamageType>>;
  notIn?: Maybe<Array<VehicleDamageType>>;
  not?: Maybe<NestedEnumVehicleDamageTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVehicleDamageTypeFilter>;
  _max?: Maybe<NestedEnumVehicleDamageTypeFilter>;
};

export type NestedEnumVehiclePhotoTypeFilter = {
  equals?: Maybe<VehiclePhotoType>;
  in?: Maybe<Array<VehiclePhotoType>>;
  notIn?: Maybe<Array<VehiclePhotoType>>;
  not?: Maybe<NestedEnumVehiclePhotoTypeFilter>;
};

export type NestedEnumVehiclePhotoTypeWithAggregatesFilter = {
  equals?: Maybe<VehiclePhotoType>;
  in?: Maybe<Array<VehiclePhotoType>>;
  notIn?: Maybe<Array<VehiclePhotoType>>;
  not?: Maybe<NestedEnumVehiclePhotoTypeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumVehiclePhotoTypeFilter>;
  _max?: Maybe<NestedEnumVehiclePhotoTypeFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type NotificationDevices = {
  __typename?: 'NotificationDevices';
  id: Scalars['String'];
  deviceId: Scalars['String'];
  pushToken: Scalars['String'];
  platform: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type NotificationDevicesCountAggregate = {
  __typename?: 'NotificationDevicesCountAggregate';
  id: Scalars['Int'];
  deviceId: Scalars['Int'];
  pushToken: Scalars['Int'];
  platform: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type NotificationDevicesCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  pushToken?: Maybe<SortOrder>;
  platform?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type NotificationDevicesCreateInput = {
  id?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  pushToken: Scalars['String'];
  platform: Scalars['String'];
  user?: Maybe<UserCreateNestedOneWithoutNotificationDevicesInput>;
};

export type NotificationDevicesCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  pushToken: Scalars['String'];
  platform: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type NotificationDevicesCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  pushToken: Scalars['String'];
  platform: Scalars['String'];
};

export type NotificationDevicesCreateManyUserInputEnvelope = {
  data: Array<NotificationDevicesCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type NotificationDevicesCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<NotificationDevicesCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<NotificationDevicesCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<NotificationDevicesCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<NotificationDevicesWhereUniqueInput>>;
};

export type NotificationDevicesCreateOrConnectWithoutUserInput = {
  where: NotificationDevicesWhereUniqueInput;
  create: NotificationDevicesCreateWithoutUserInput;
};

export type NotificationDevicesCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  pushToken: Scalars['String'];
  platform: Scalars['String'];
};

export type NotificationDevicesGroupBy = {
  __typename?: 'NotificationDevicesGroupBy';
  id: Scalars['String'];
  deviceId: Scalars['String'];
  pushToken: Scalars['String'];
  platform: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  _count?: Maybe<NotificationDevicesCountAggregate>;
  _min?: Maybe<NotificationDevicesMinAggregate>;
  _max?: Maybe<NotificationDevicesMaxAggregate>;
};

export type NotificationDevicesListRelationFilter = {
  every?: Maybe<NotificationDevicesWhereInput>;
  some?: Maybe<NotificationDevicesWhereInput>;
  none?: Maybe<NotificationDevicesWhereInput>;
};

export type NotificationDevicesMaxAggregate = {
  __typename?: 'NotificationDevicesMaxAggregate';
  id?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type NotificationDevicesMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  pushToken?: Maybe<SortOrder>;
  platform?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type NotificationDevicesMinAggregate = {
  __typename?: 'NotificationDevicesMinAggregate';
  id?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type NotificationDevicesMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  pushToken?: Maybe<SortOrder>;
  platform?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type NotificationDevicesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type NotificationDevicesOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  pushToken?: Maybe<SortOrder>;
  platform?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<NotificationDevicesCountOrderByAggregateInput>;
  _max?: Maybe<NotificationDevicesMaxOrderByAggregateInput>;
  _min?: Maybe<NotificationDevicesMinOrderByAggregateInput>;
};

export type NotificationDevicesOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  deviceId?: Maybe<SortOrder>;
  pushToken?: Maybe<SortOrder>;
  platform?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum NotificationDevicesScalarFieldEnum {
  Id = 'id',
  DeviceId = 'deviceId',
  PushToken = 'pushToken',
  Platform = 'platform',
  UserId = 'userId',
}

export type NotificationDevicesScalarWhereInput = {
  AND?: Maybe<Array<NotificationDevicesScalarWhereInput>>;
  OR?: Maybe<Array<NotificationDevicesScalarWhereInput>>;
  NOT?: Maybe<Array<NotificationDevicesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  deviceId?: Maybe<StringFilter>;
  pushToken?: Maybe<StringFilter>;
  platform?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type NotificationDevicesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NotificationDevicesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NotificationDevicesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NotificationDevicesScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  deviceId?: Maybe<StringWithAggregatesFilter>;
  pushToken?: Maybe<StringWithAggregatesFilter>;
  platform?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type NotificationDevicesUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  deviceId?: Maybe<StringFieldUpdateOperationsInput>;
  pushToken?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutNotificationDevicesInput>;
};

export type NotificationDevicesUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  deviceId?: Maybe<StringFieldUpdateOperationsInput>;
  pushToken?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<StringFieldUpdateOperationsInput>;
};

export type NotificationDevicesUpdateManyWithWhereWithoutUserInput = {
  where: NotificationDevicesScalarWhereInput;
  data: NotificationDevicesUpdateManyMutationInput;
};

export type NotificationDevicesUpdateManyWithoutUserInput = {
  create?: Maybe<Array<NotificationDevicesCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<NotificationDevicesCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<NotificationDevicesUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<NotificationDevicesCreateManyUserInputEnvelope>;
  set?: Maybe<Array<NotificationDevicesWhereUniqueInput>>;
  disconnect?: Maybe<Array<NotificationDevicesWhereUniqueInput>>;
  delete?: Maybe<Array<NotificationDevicesWhereUniqueInput>>;
  connect?: Maybe<Array<NotificationDevicesWhereUniqueInput>>;
  update?: Maybe<
    Array<NotificationDevicesUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<NotificationDevicesUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<NotificationDevicesScalarWhereInput>>;
};

export type NotificationDevicesUpdateWithWhereUniqueWithoutUserInput = {
  where: NotificationDevicesWhereUniqueInput;
  data: NotificationDevicesUpdateWithoutUserInput;
};

export type NotificationDevicesUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  deviceId?: Maybe<StringFieldUpdateOperationsInput>;
  pushToken?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<StringFieldUpdateOperationsInput>;
};

export type NotificationDevicesUpsertWithWhereUniqueWithoutUserInput = {
  where: NotificationDevicesWhereUniqueInput;
  update: NotificationDevicesUpdateWithoutUserInput;
  create: NotificationDevicesCreateWithoutUserInput;
};

export type NotificationDevicesWhereInput = {
  AND?: Maybe<Array<NotificationDevicesWhereInput>>;
  OR?: Maybe<Array<NotificationDevicesWhereInput>>;
  NOT?: Maybe<Array<NotificationDevicesWhereInput>>;
  id?: Maybe<StringFilter>;
  deviceId?: Maybe<StringFilter>;
  pushToken?: Maybe<StringFilter>;
  platform?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type NotificationDevicesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumChargingStationTransactionAuthTypeFieldUpdateOperationsInput =
  {
    set?: Maybe<ChargingStationTransactionAuthType>;
  };

export type NullableEnumChargingStationTransactionStatusFieldUpdateOperationsInput =
  {
    set?: Maybe<ChargingStationTransactionStatus>;
  };

export type NullableEnumGenderTypeFieldUpdateOperationsInput = {
  set?: Maybe<GenderType>;
};

export type NullableEnumOwnershipFieldUpdateOperationsInput = {
  set?: Maybe<Ownership>;
};

export type NullableEnumRuleCompareOperatorFieldUpdateOperationsInput = {
  set?: Maybe<RuleCompareOperator>;
};

export type NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentMethodType>;
};

export type NullableEnumUserRoleFieldUpdateOperationsInput = {
  set?: Maybe<UserRole>;
};

export type NullableEnumVehicleDamageKindFieldUpdateOperationsInput = {
  set?: Maybe<VehicleDamageKind>;
};

export type NullableEnumVehicleDamageTimeFieldUpdateOperationsInput = {
  set?: Maybe<VehicleDamageTime>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export enum Ownership {
  Private = 'PRIVATE',
  Company = 'COMPANY',
  PrivateAndCompany = 'PRIVATE_AND_COMPANY',
}

export type ParticularUseType = {
  __typename?: 'ParticularUseType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ParticularUseTypeCount>;
  vehicle: Array<Vehicle>;
};

export type ParticularUseTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type ParticularUseTypeCount = {
  __typename?: 'ParticularUseTypeCount';
  vehicle: Scalars['Int'];
};

export type ParticularUseTypeCountAggregate = {
  __typename?: 'ParticularUseTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ParticularUseTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ParticularUseTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutParticularUseTypeInput>;
};

export type ParticularUseTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ParticularUseTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<ParticularUseTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ParticularUseTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<ParticularUseTypeWhereUniqueInput>;
};

export type ParticularUseTypeCreateOrConnectWithoutVehicleInput = {
  where: ParticularUseTypeWhereUniqueInput;
  create: ParticularUseTypeCreateWithoutVehicleInput;
};

export type ParticularUseTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ParticularUseTypeGroupBy = {
  __typename?: 'ParticularUseTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ParticularUseTypeCountAggregate>;
  _min?: Maybe<ParticularUseTypeMinAggregate>;
  _max?: Maybe<ParticularUseTypeMaxAggregate>;
};

export type ParticularUseTypeMaxAggregate = {
  __typename?: 'ParticularUseTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ParticularUseTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ParticularUseTypeMinAggregate = {
  __typename?: 'ParticularUseTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ParticularUseTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ParticularUseTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<ParticularUseTypeCountOrderByAggregateInput>;
  _max?: Maybe<ParticularUseTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ParticularUseTypeMinOrderByAggregateInput>;
};

export type ParticularUseTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type ParticularUseTypeRelationFilter = {
  is?: Maybe<ParticularUseTypeWhereInput>;
  isNot?: Maybe<ParticularUseTypeWhereInput>;
};

export enum ParticularUseTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type ParticularUseTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ParticularUseTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ParticularUseTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ParticularUseTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ParticularUseTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutParticularUseTypeInput>;
};

export type ParticularUseTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ParticularUseTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<ParticularUseTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ParticularUseTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<ParticularUseTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ParticularUseTypeWhereUniqueInput>;
  update?: Maybe<ParticularUseTypeUpdateWithoutVehicleInput>;
};

export type ParticularUseTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ParticularUseTypeUpsertWithoutVehicleInput = {
  update: ParticularUseTypeUpdateWithoutVehicleInput;
  create: ParticularUseTypeCreateWithoutVehicleInput;
};

export type ParticularUseTypeWhereInput = {
  AND?: Maybe<Array<ParticularUseTypeWhereInput>>;
  OR?: Maybe<Array<ParticularUseTypeWhereInput>>;
  NOT?: Maybe<Array<ParticularUseTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type ParticularUseTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PaymentCardDetails = {
  __typename?: 'PaymentCardDetails';
  id?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  last4: Scalars['String'];
};

export type PxlVisionManualVerification = {
  __typename?: 'PxlVisionManualVerification';
  id: Scalars['String'];
  userId: Scalars['String'];
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  user: User;
  verifiedByUser?: Maybe<User>;
};

export type PxlVisionManualVerificationCountAggregate = {
  __typename?: 'PxlVisionManualVerificationCountAggregate';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  transactionCode: Scalars['Int'];
  type: Scalars['Int'];
  verifiedAt: Scalars['Int'];
  verifiedByUserId: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PxlVisionManualVerificationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  transactionCode?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  verifiedAt?: Maybe<SortOrder>;
  verifiedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type PxlVisionManualVerificationCreateInput = {
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutPxlVisionManualVerificationsInput;
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUser?: Maybe<UserCreateNestedOneWithoutPxlVisionManualVerifiedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationCreateManyUserInputEnvelope = {
  data: Array<PxlVisionManualVerificationCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PxlVisionManualVerificationCreateManyVerifiedByUserInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationCreateManyVerifiedByUserInputEnvelope = {
  data: Array<PxlVisionManualVerificationCreateManyVerifiedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PxlVisionManualVerificationCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<PxlVisionManualVerificationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<PxlVisionManualVerificationCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<PxlVisionManualVerificationCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
};

export type PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput =
  {
    create?: Maybe<
      Array<PxlVisionManualVerificationCreateWithoutVerifiedByUserInput>
    >;
    connectOrCreate?: Maybe<
      Array<PxlVisionManualVerificationCreateOrConnectWithoutVerifiedByUserInput>
    >;
    createMany?: Maybe<PxlVisionManualVerificationCreateManyVerifiedByUserInputEnvelope>;
    connect?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  };

export type PxlVisionManualVerificationCreateOrConnectWithoutUserInput = {
  where: PxlVisionManualVerificationWhereUniqueInput;
  create: PxlVisionManualVerificationCreateWithoutUserInput;
};

export type PxlVisionManualVerificationCreateOrConnectWithoutVerifiedByUserInput =
  {
    where: PxlVisionManualVerificationWhereUniqueInput;
    create: PxlVisionManualVerificationCreateWithoutVerifiedByUserInput;
  };

export type PxlVisionManualVerificationCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUser?: Maybe<UserCreateNestedOneWithoutPxlVisionManualVerifiedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationCreateWithoutVerifiedByUserInput = {
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutPxlVisionManualVerificationsInput;
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationGroupBy = {
  __typename?: 'PxlVisionManualVerificationGroupBy';
  id: Scalars['String'];
  userId: Scalars['String'];
  transactionCode: Scalars['String'];
  type: PxlVisionVerificationType;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  _count?: Maybe<PxlVisionManualVerificationCountAggregate>;
  _min?: Maybe<PxlVisionManualVerificationMinAggregate>;
  _max?: Maybe<PxlVisionManualVerificationMaxAggregate>;
};

export type PxlVisionManualVerificationListRelationFilter = {
  every?: Maybe<PxlVisionManualVerificationWhereInput>;
  some?: Maybe<PxlVisionManualVerificationWhereInput>;
  none?: Maybe<PxlVisionManualVerificationWhereInput>;
};

export type PxlVisionManualVerificationMaxAggregate = {
  __typename?: 'PxlVisionManualVerificationMaxAggregate';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  transactionCode?: Maybe<Scalars['String']>;
  type?: Maybe<PxlVisionVerificationType>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  transactionCode?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  verifiedAt?: Maybe<SortOrder>;
  verifiedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type PxlVisionManualVerificationMinAggregate = {
  __typename?: 'PxlVisionManualVerificationMinAggregate';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  transactionCode?: Maybe<Scalars['String']>;
  type?: Maybe<PxlVisionVerificationType>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PxlVisionManualVerificationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  transactionCode?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  verifiedAt?: Maybe<SortOrder>;
  verifiedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type PxlVisionManualVerificationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PxlVisionManualVerificationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  transactionCode?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  verifiedAt?: Maybe<SortOrder>;
  verifiedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<PxlVisionManualVerificationCountOrderByAggregateInput>;
  _max?: Maybe<PxlVisionManualVerificationMaxOrderByAggregateInput>;
  _min?: Maybe<PxlVisionManualVerificationMinOrderByAggregateInput>;
};

export type PxlVisionManualVerificationOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  transactionCode?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  verifiedAt?: Maybe<SortOrder>;
  verifiedByUser?: Maybe<UserOrderByWithRelationInput>;
  verifiedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum PxlVisionManualVerificationScalarFieldEnum {
  Id = 'id',
  UserId = 'userId',
  TransactionCode = 'transactionCode',
  Type = 'type',
  VerifiedAt = 'verifiedAt',
  VerifiedByUserId = 'verifiedByUserId',
  CreatedAt = 'createdAt',
}

export type PxlVisionManualVerificationScalarWhereInput = {
  AND?: Maybe<Array<PxlVisionManualVerificationScalarWhereInput>>;
  OR?: Maybe<Array<PxlVisionManualVerificationScalarWhereInput>>;
  NOT?: Maybe<Array<PxlVisionManualVerificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  transactionCode?: Maybe<StringFilter>;
  type?: Maybe<EnumPxlVisionVerificationTypeFilter>;
  verifiedAt?: Maybe<DateTimeNullableFilter>;
  verifiedByUserId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type PxlVisionManualVerificationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PxlVisionManualVerificationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PxlVisionManualVerificationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PxlVisionManualVerificationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  transactionCode?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumPxlVisionVerificationTypeWithAggregatesFilter>;
  verifiedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  verifiedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PxlVisionManualVerificationUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutPxlVisionManualVerificationsInput>;
  transactionCode?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPxlVisionVerificationTypeFieldUpdateOperationsInput>;
  verifiedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  verifiedByUser?: Maybe<UserUpdateOneWithoutPxlVisionManualVerifiedInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PxlVisionManualVerificationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  transactionCode?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPxlVisionVerificationTypeFieldUpdateOperationsInput>;
  verifiedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PxlVisionManualVerificationUpdateManyWithWhereWithoutUserInput = {
  where: PxlVisionManualVerificationScalarWhereInput;
  data: PxlVisionManualVerificationUpdateManyMutationInput;
};

export type PxlVisionManualVerificationUpdateManyWithWhereWithoutVerifiedByUserInput =
  {
    where: PxlVisionManualVerificationScalarWhereInput;
    data: PxlVisionManualVerificationUpdateManyMutationInput;
  };

export type PxlVisionManualVerificationUpdateManyWithoutUserInput = {
  create?: Maybe<Array<PxlVisionManualVerificationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<PxlVisionManualVerificationCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<PxlVisionManualVerificationUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<PxlVisionManualVerificationCreateManyUserInputEnvelope>;
  set?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  delete?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  connect?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  update?: Maybe<
    Array<PxlVisionManualVerificationUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<PxlVisionManualVerificationUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<PxlVisionManualVerificationScalarWhereInput>>;
};

export type PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput = {
  create?: Maybe<
    Array<PxlVisionManualVerificationCreateWithoutVerifiedByUserInput>
  >;
  connectOrCreate?: Maybe<
    Array<PxlVisionManualVerificationCreateOrConnectWithoutVerifiedByUserInput>
  >;
  upsert?: Maybe<
    Array<PxlVisionManualVerificationUpsertWithWhereUniqueWithoutVerifiedByUserInput>
  >;
  createMany?: Maybe<PxlVisionManualVerificationCreateManyVerifiedByUserInputEnvelope>;
  set?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  delete?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  connect?: Maybe<Array<PxlVisionManualVerificationWhereUniqueInput>>;
  update?: Maybe<
    Array<PxlVisionManualVerificationUpdateWithWhereUniqueWithoutVerifiedByUserInput>
  >;
  updateMany?: Maybe<
    Array<PxlVisionManualVerificationUpdateManyWithWhereWithoutVerifiedByUserInput>
  >;
  deleteMany?: Maybe<Array<PxlVisionManualVerificationScalarWhereInput>>;
};

export type PxlVisionManualVerificationUpdateWithWhereUniqueWithoutUserInput = {
  where: PxlVisionManualVerificationWhereUniqueInput;
  data: PxlVisionManualVerificationUpdateWithoutUserInput;
};

export type PxlVisionManualVerificationUpdateWithWhereUniqueWithoutVerifiedByUserInput =
  {
    where: PxlVisionManualVerificationWhereUniqueInput;
    data: PxlVisionManualVerificationUpdateWithoutVerifiedByUserInput;
  };

export type PxlVisionManualVerificationUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  transactionCode?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPxlVisionVerificationTypeFieldUpdateOperationsInput>;
  verifiedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  verifiedByUser?: Maybe<UserUpdateOneWithoutPxlVisionManualVerifiedInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PxlVisionManualVerificationUpdateWithoutVerifiedByUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutPxlVisionManualVerificationsInput>;
  transactionCode?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPxlVisionVerificationTypeFieldUpdateOperationsInput>;
  verifiedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PxlVisionManualVerificationUpsertWithWhereUniqueWithoutUserInput = {
  where: PxlVisionManualVerificationWhereUniqueInput;
  update: PxlVisionManualVerificationUpdateWithoutUserInput;
  create: PxlVisionManualVerificationCreateWithoutUserInput;
};

export type PxlVisionManualVerificationUpsertWithWhereUniqueWithoutVerifiedByUserInput =
  {
    where: PxlVisionManualVerificationWhereUniqueInput;
    update: PxlVisionManualVerificationUpdateWithoutVerifiedByUserInput;
    create: PxlVisionManualVerificationCreateWithoutVerifiedByUserInput;
  };

export type PxlVisionManualVerificationWhereInput = {
  AND?: Maybe<Array<PxlVisionManualVerificationWhereInput>>;
  OR?: Maybe<Array<PxlVisionManualVerificationWhereInput>>;
  NOT?: Maybe<Array<PxlVisionManualVerificationWhereInput>>;
  id?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  transactionCode?: Maybe<StringFilter>;
  type?: Maybe<EnumPxlVisionVerificationTypeFilter>;
  verifiedAt?: Maybe<DateTimeNullableFilter>;
  verifiedByUser?: Maybe<UserRelationFilter>;
  verifiedByUserId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type PxlVisionManualVerificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PxlVisionVerificationType {
  Id = 'ID',
  DriverLicence = 'DRIVER_LICENCE',
}

export type Query = {
  __typename?: 'Query';
  availability?: Maybe<Availability>;
  findFirstAvailability?: Maybe<Availability>;
  availabilities: Array<Availability>;
  aggregateAvailability: AggregateAvailability;
  groupByAvailability: Array<AvailabilityGroupBy>;
  billingAccount?: Maybe<BillingAccount>;
  findFirstBillingAccount?: Maybe<BillingAccount>;
  billingAccounts: Array<BillingAccount>;
  aggregateBillingAccount: AggregateBillingAccount;
  groupByBillingAccount: Array<BillingAccountGroupBy>;
  billingAccountDocument?: Maybe<BillingAccountDocument>;
  findFirstBillingAccountDocument?: Maybe<BillingAccountDocument>;
  billingAccountDocuments: Array<BillingAccountDocument>;
  aggregateBillingAccountDocument: AggregateBillingAccountDocument;
  groupByBillingAccountDocument: Array<BillingAccountDocumentGroupBy>;
  biometricToken?: Maybe<BiometricToken>;
  findFirstBiometricToken?: Maybe<BiometricToken>;
  biometricTokens: Array<BiometricToken>;
  aggregateBiometricToken: AggregateBiometricToken;
  groupByBiometricToken: Array<BiometricTokenGroupBy>;
  bodyType?: Maybe<BodyType>;
  findFirstBodyType?: Maybe<BodyType>;
  bodyTypes: Array<BodyType>;
  aggregateBodyType: AggregateBodyType;
  groupByBodyType: Array<BodyTypeGroupBy>;
  booking?: Maybe<Booking>;
  findFirstBooking?: Maybe<Booking>;
  bookings: Array<Booking>;
  aggregateBooking: AggregateBooking;
  groupByBooking: Array<BookingGroupBy>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriver>;
  findFirstBookingAdditionalDriver?: Maybe<BookingAdditionalDriver>;
  bookingAdditionalDrivers: Array<BookingAdditionalDriver>;
  aggregateBookingAdditionalDriver: AggregateBookingAdditionalDriver;
  groupByBookingAdditionalDriver: Array<BookingAdditionalDriverGroupBy>;
  bookingAnswer?: Maybe<BookingAnswer>;
  findFirstBookingAnswer?: Maybe<BookingAnswer>;
  bookingAnswers: Array<BookingAnswer>;
  aggregateBookingAnswer: AggregateBookingAnswer;
  groupByBookingAnswer: Array<BookingAnswerGroupBy>;
  bookingAvailability?: Maybe<BookingAvailability>;
  findFirstBookingAvailability?: Maybe<BookingAvailability>;
  bookingAvailabilities: Array<BookingAvailability>;
  aggregateBookingAvailability: AggregateBookingAvailability;
  groupByBookingAvailability: Array<BookingAvailabilityGroupBy>;
  bookingChangeHistory?: Maybe<BookingChangeHistory>;
  findFirstBookingChangeHistory?: Maybe<BookingChangeHistory>;
  bookingChangeHistories: Array<BookingChangeHistory>;
  aggregateBookingChangeHistory: AggregateBookingChangeHistory;
  groupByBookingChangeHistory: Array<BookingChangeHistoryGroupBy>;
  bookingFuelPassword?: Maybe<BookingFuelPassword>;
  findFirstBookingFuelPassword?: Maybe<BookingFuelPassword>;
  bookingFuelPasswords: Array<BookingFuelPassword>;
  aggregateBookingFuelPassword: AggregateBookingFuelPassword;
  groupByBookingFuelPassword: Array<BookingFuelPasswordGroupBy>;
  bookingQuestion?: Maybe<BookingQuestion>;
  findFirstBookingQuestion?: Maybe<BookingQuestion>;
  bookingQuestions: Array<BookingQuestion>;
  aggregateBookingQuestion: AggregateBookingQuestion;
  groupByBookingQuestion: Array<BookingQuestionGroupBy>;
  bookingRefunding?: Maybe<BookingRefunding>;
  findFirstBookingRefunding?: Maybe<BookingRefunding>;
  bookingRefundings: Array<BookingRefunding>;
  aggregateBookingRefunding: AggregateBookingRefunding;
  groupByBookingRefunding: Array<BookingRefundingGroupBy>;
  findUniqueBookingVehicleDamages?: Maybe<BookingVehicleDamages>;
  findFirstBookingVehicleDamages?: Maybe<BookingVehicleDamages>;
  findManyBookingVehicleDamages: Array<BookingVehicleDamages>;
  aggregateBookingVehicleDamages: AggregateBookingVehicleDamages;
  groupByBookingVehicleDamages: Array<BookingVehicleDamagesGroupBy>;
  brandType?: Maybe<BrandType>;
  findFirstBrandType?: Maybe<BrandType>;
  brandTypes: Array<BrandType>;
  aggregateBrandType: AggregateBrandType;
  groupByBrandType: Array<BrandTypeGroupBy>;
  chargingStation?: Maybe<ChargingStation>;
  findFirstChargingStation?: Maybe<ChargingStation>;
  chargingStations: Array<ChargingStation>;
  aggregateChargingStation: AggregateChargingStation;
  groupByChargingStation: Array<ChargingStationGroupBy>;
  chargingStationCluster?: Maybe<ChargingStationCluster>;
  findFirstChargingStationCluster?: Maybe<ChargingStationCluster>;
  chargingStationClusters: Array<ChargingStationCluster>;
  aggregateChargingStationCluster: AggregateChargingStationCluster;
  groupByChargingStationCluster: Array<ChargingStationClusterGroupBy>;
  chargingStationRfid?: Maybe<ChargingStationRfid>;
  findFirstChargingStationRfid?: Maybe<ChargingStationRfid>;
  chargingStationRfids: Array<ChargingStationRfid>;
  aggregateChargingStationRfid: AggregateChargingStationRfid;
  groupByChargingStationRfid: Array<ChargingStationRfidGroupBy>;
  chargingStationRfidAlias?: Maybe<ChargingStationRfidAlias>;
  findFirstChargingStationRfidAlias?: Maybe<ChargingStationRfidAlias>;
  chargingStationRfidAliases: Array<ChargingStationRfidAlias>;
  aggregateChargingStationRfidAlias: AggregateChargingStationRfidAlias;
  groupByChargingStationRfidAlias: Array<ChargingStationRfidAliasGroupBy>;
  chargingStationTariff?: Maybe<ChargingStationTariff>;
  findFirstChargingStationTariff?: Maybe<ChargingStationTariff>;
  chargingStationTariffs: Array<ChargingStationTariff>;
  aggregateChargingStationTariff: AggregateChargingStationTariff;
  groupByChargingStationTariff: Array<ChargingStationTariffGroupBy>;
  chargingStationTariffRule?: Maybe<ChargingStationTariffRule>;
  findFirstChargingStationTariffRule?: Maybe<ChargingStationTariffRule>;
  chargingStationTariffRules: Array<ChargingStationTariffRule>;
  aggregateChargingStationTariffRule: AggregateChargingStationTariffRule;
  groupByChargingStationTariffRule: Array<ChargingStationTariffRuleGroupBy>;
  chargingStationTransaction?: Maybe<ChargingStationTransaction>;
  findFirstChargingStationTransaction?: Maybe<ChargingStationTransaction>;
  chargingStationTransactions: Array<ChargingStationTransaction>;
  aggregateChargingStationTransaction: AggregateChargingStationTransaction;
  groupByChargingStationTransaction: Array<ChargingStationTransactionGroupBy>;
  chargingStationTransactionAppliedTariff?: Maybe<ChargingStationTransactionAppliedTariff>;
  findFirstChargingStationTransactionAppliedTariff?: Maybe<ChargingStationTransactionAppliedTariff>;
  chargingStationTransactionAppliedTariffs: Array<ChargingStationTransactionAppliedTariff>;
  aggregateChargingStationTransactionAppliedTariff: AggregateChargingStationTransactionAppliedTariff;
  groupByChargingStationTransactionAppliedTariff: Array<ChargingStationTransactionAppliedTariffGroupBy>;
  chargingStationTransactionReport?: Maybe<ChargingStationTransactionReport>;
  findFirstChargingStationTransactionReport?: Maybe<ChargingStationTransactionReport>;
  chargingStationTransactionReports: Array<ChargingStationTransactionReport>;
  aggregateChargingStationTransactionReport: AggregateChargingStationTransactionReport;
  groupByChargingStationTransactionReport: Array<ChargingStationTransactionReportGroupBy>;
  chargingStationTransactionUsage?: Maybe<ChargingStationTransactionUsage>;
  findFirstChargingStationTransactionUsage?: Maybe<ChargingStationTransactionUsage>;
  chargingStationTransactionUsages: Array<ChargingStationTransactionUsage>;
  aggregateChargingStationTransactionUsage: AggregateChargingStationTransactionUsage;
  groupByChargingStationTransactionUsage: Array<ChargingStationTransactionUsageGroupBy>;
  childSeatType?: Maybe<ChildSeatType>;
  findFirstChildSeatType?: Maybe<ChildSeatType>;
  childSeatTypes: Array<ChildSeatType>;
  aggregateChildSeatType: AggregateChildSeatType;
  groupByChildSeatType: Array<ChildSeatTypeGroupBy>;
  colorType?: Maybe<ColorType>;
  findFirstColorType?: Maybe<ColorType>;
  colorTypes: Array<ColorType>;
  aggregateColorType: AggregateColorType;
  groupByColorType: Array<ColorTypeGroupBy>;
  company?: Maybe<Company>;
  findFirstCompany?: Maybe<Company>;
  companies: Array<Company>;
  aggregateCompany: AggregateCompany;
  groupByCompany: Array<CompanyGroupBy>;
  companyDocument?: Maybe<CompanyDocument>;
  findFirstCompanyDocument?: Maybe<CompanyDocument>;
  companyDocuments: Array<CompanyDocument>;
  aggregateCompanyDocument: AggregateCompanyDocument;
  groupByCompanyDocument: Array<CompanyDocumentGroupBy>;
  consumptionType?: Maybe<ConsumptionType>;
  findFirstConsumptionType?: Maybe<ConsumptionType>;
  consumptionTypes: Array<ConsumptionType>;
  aggregateConsumptionType: AggregateConsumptionType;
  groupByConsumptionType: Array<ConsumptionTypeGroupBy>;
  doorType?: Maybe<DoorType>;
  findFirstDoorType?: Maybe<DoorType>;
  doorTypes: Array<DoorType>;
  aggregateDoorType: AggregateDoorType;
  groupByDoorType: Array<DoorTypeGroupBy>;
  driveType?: Maybe<DriveType>;
  findFirstDriveType?: Maybe<DriveType>;
  driveTypes: Array<DriveType>;
  aggregateDriveType: AggregateDriveType;
  groupByDriveType: Array<DriveTypeGroupBy>;
  emissionCodeType?: Maybe<EmissionCodeType>;
  findFirstEmissionCodeType?: Maybe<EmissionCodeType>;
  emissionCodeTypes: Array<EmissionCodeType>;
  aggregateEmissionCodeType: AggregateEmissionCodeType;
  groupByEmissionCodeType: Array<EmissionCodeTypeGroupBy>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyType>;
  findFirstEnergyEfficiencyType?: Maybe<EnergyEfficiencyType>;
  energyEfficiencyTypes: Array<EnergyEfficiencyType>;
  aggregateEnergyEfficiencyType: AggregateEnergyEfficiencyType;
  groupByEnergyEfficiencyType: Array<EnergyEfficiencyTypeGroupBy>;
  equipmentType?: Maybe<EquipmentType>;
  findFirstEquipmentType?: Maybe<EquipmentType>;
  equipmentTypes: Array<EquipmentType>;
  aggregateEquipmentType: AggregateEquipmentType;
  groupByEquipmentType: Array<EquipmentTypeGroupBy>;
  fuelType?: Maybe<FuelType>;
  findFirstFuelType?: Maybe<FuelType>;
  fuelTypes: Array<FuelType>;
  aggregateFuelType: AggregateFuelType;
  groupByFuelType: Array<FuelTypeGroupBy>;
  gateController?: Maybe<GateController>;
  findFirstGateController?: Maybe<GateController>;
  gateControllers: Array<GateController>;
  aggregateGateController: AggregateGateController;
  groupByGateController: Array<GateControllerGroupBy>;
  gateControllerDocument?: Maybe<GateControllerDocument>;
  findFirstGateControllerDocument?: Maybe<GateControllerDocument>;
  gateControllerDocuments: Array<GateControllerDocument>;
  aggregateGateControllerDocument: AggregateGateControllerDocument;
  groupByGateControllerDocument: Array<GateControllerDocumentGroupBy>;
  gateControllerRelUser?: Maybe<GateControllerRelUser>;
  findFirstGateControllerRelUser?: Maybe<GateControllerRelUser>;
  gateControllerRelUsers: Array<GateControllerRelUser>;
  aggregateGateControllerRelUser: AggregateGateControllerRelUser;
  groupByGateControllerRelUser: Array<GateControllerRelUserGroupBy>;
  gateControllerRelVehicle?: Maybe<GateControllerRelVehicle>;
  findFirstGateControllerRelVehicle?: Maybe<GateControllerRelVehicle>;
  gateControllerRelVehicles: Array<GateControllerRelVehicle>;
  aggregateGateControllerRelVehicle: AggregateGateControllerRelVehicle;
  groupByGateControllerRelVehicle: Array<GateControllerRelVehicleGroupBy>;
  gateControllerRule?: Maybe<GateControllerRule>;
  findFirstGateControllerRule?: Maybe<GateControllerRule>;
  gateControllerRules: Array<GateControllerRule>;
  aggregateGateControllerRule: AggregateGateControllerRule;
  groupByGateControllerRule: Array<GateControllerRuleGroupBy>;
  gateControllerRuleSet?: Maybe<GateControllerRuleSet>;
  findFirstGateControllerRuleSet?: Maybe<GateControllerRuleSet>;
  gateControllerRuleSets: Array<GateControllerRuleSet>;
  aggregateGateControllerRuleSet: AggregateGateControllerRuleSet;
  groupByGateControllerRuleSet: Array<GateControllerRuleSetGroupBy>;
  gateControllerTransaction?: Maybe<GateControllerTransaction>;
  findFirstGateControllerTransaction?: Maybe<GateControllerTransaction>;
  gateControllerTransactions: Array<GateControllerTransaction>;
  aggregateGateControllerTransaction: AggregateGateControllerTransaction;
  groupByGateControllerTransaction: Array<GateControllerTransactionGroupBy>;
  gateControllerType?: Maybe<GateControllerType>;
  findFirstGateControllerType?: Maybe<GateControllerType>;
  gateControllerTypes: Array<GateControllerType>;
  aggregateGateControllerType: AggregateGateControllerType;
  groupByGateControllerType: Array<GateControllerTypeGroupBy>;
  message?: Maybe<Message>;
  findFirstMessage?: Maybe<Message>;
  messages: Array<Message>;
  aggregateMessage: AggregateMessage;
  groupByMessage: Array<MessageGroupBy>;
  modelType?: Maybe<ModelType>;
  findFirstModelType?: Maybe<ModelType>;
  modelTypes: Array<ModelType>;
  aggregateModelType: AggregateModelType;
  groupByModelType: Array<ModelTypeGroupBy>;
  findUniqueNotificationDevices?: Maybe<NotificationDevices>;
  findFirstNotificationDevices?: Maybe<NotificationDevices>;
  findManyNotificationDevices: Array<NotificationDevices>;
  aggregateNotificationDevices: AggregateNotificationDevices;
  groupByNotificationDevices: Array<NotificationDevicesGroupBy>;
  particularUseType?: Maybe<ParticularUseType>;
  findFirstParticularUseType?: Maybe<ParticularUseType>;
  particularUseTypes: Array<ParticularUseType>;
  aggregateParticularUseType: AggregateParticularUseType;
  groupByParticularUseType: Array<ParticularUseTypeGroupBy>;
  pxlVisionManualVerification?: Maybe<PxlVisionManualVerification>;
  findFirstPxlVisionManualVerification?: Maybe<PxlVisionManualVerification>;
  pxlVisionManualVerifications: Array<PxlVisionManualVerification>;
  aggregatePxlVisionManualVerification: AggregatePxlVisionManualVerification;
  groupByPxlVisionManualVerification: Array<PxlVisionManualVerificationGroupBy>;
  revokeReasonType?: Maybe<RevokeReasonType>;
  findFirstRevokeReasonType?: Maybe<RevokeReasonType>;
  revokeReasonTypes: Array<RevokeReasonType>;
  aggregateRevokeReasonType: AggregateRevokeReasonType;
  groupByRevokeReasonType: Array<RevokeReasonTypeGroupBy>;
  seatFrontType?: Maybe<SeatFrontType>;
  findFirstSeatFrontType?: Maybe<SeatFrontType>;
  seatFrontTypes: Array<SeatFrontType>;
  aggregateSeatFrontType: AggregateSeatFrontType;
  groupBySeatFrontType: Array<SeatFrontTypeGroupBy>;
  seatType?: Maybe<SeatType>;
  findFirstSeatType?: Maybe<SeatType>;
  seatTypes: Array<SeatType>;
  aggregateSeatType: AggregateSeatType;
  groupBySeatType: Array<SeatTypeGroupBy>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  findFirstSharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  sharedVehicleGroups: Array<SharedVehicleGroup>;
  aggregateSharedVehicleGroup: AggregateSharedVehicleGroup;
  groupBySharedVehicleGroup: Array<SharedVehicleGroupGroupBy>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmail>;
  findFirstSharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmail>;
  sharedVehicleGroupInvitedEmails: Array<SharedVehicleGroupInvitedEmail>;
  aggregateSharedVehicleGroupInvitedEmail: AggregateSharedVehicleGroupInvitedEmail;
  groupBySharedVehicleGroupInvitedEmail: Array<SharedVehicleGroupInvitedEmailGroupBy>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailability>;
  findFirstSharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailability>;
  sharedVehicleGroupRelAvailabilities: Array<SharedVehicleGroupRelAvailability>;
  aggregateSharedVehicleGroupRelAvailability: AggregateSharedVehicleGroupRelAvailability;
  groupBySharedVehicleGroupRelAvailability: Array<SharedVehicleGroupRelAvailabilityGroupBy>;
  sharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompany>;
  findFirstSharedVehicleGroupRelCompany?: Maybe<SharedVehicleGroupRelCompany>;
  sharedVehicleGroupRelCompanies: Array<SharedVehicleGroupRelCompany>;
  aggregateSharedVehicleGroupRelCompany: AggregateSharedVehicleGroupRelCompany;
  groupBySharedVehicleGroupRelCompany: Array<SharedVehicleGroupRelCompanyGroupBy>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUser>;
  findFirstSharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUser>;
  sharedVehicleGroupRelUsers: Array<SharedVehicleGroupRelUser>;
  aggregateSharedVehicleGroupRelUser: AggregateSharedVehicleGroupRelUser;
  groupBySharedVehicleGroupRelUser: Array<SharedVehicleGroupRelUserGroupBy>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicle>;
  findFirstSharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicle>;
  sharedVehicleGroupRelVehicles: Array<SharedVehicleGroupRelVehicle>;
  aggregateSharedVehicleGroupRelVehicle: AggregateSharedVehicleGroupRelVehicle;
  groupBySharedVehicleGroupRelVehicle: Array<SharedVehicleGroupRelVehicleGroupBy>;
  shieldColorType?: Maybe<ShieldColorType>;
  findFirstShieldColorType?: Maybe<ShieldColorType>;
  shieldColorTypes: Array<ShieldColorType>;
  aggregateShieldColorType: AggregateShieldColorType;
  groupByShieldColorType: Array<ShieldColorTypeGroupBy>;
  smartcarAuth?: Maybe<SmartcarAuth>;
  findFirstSmartcarAuth?: Maybe<SmartcarAuth>;
  smartcarAuths: Array<SmartcarAuth>;
  aggregateSmartcarAuth: AggregateSmartcarAuth;
  groupBySmartcarAuth: Array<SmartcarAuthGroupBy>;
  transmissionType?: Maybe<TransmissionType>;
  findFirstTransmissionType?: Maybe<TransmissionType>;
  transmissionTypes: Array<TransmissionType>;
  aggregateTransmissionType: AggregateTransmissionType;
  groupByTransmissionType: Array<TransmissionTypeGroupBy>;
  unpublishReasonType?: Maybe<UnpublishReasonType>;
  findFirstUnpublishReasonType?: Maybe<UnpublishReasonType>;
  unpublishReasonTypes: Array<UnpublishReasonType>;
  aggregateUnpublishReasonType: AggregateUnpublishReasonType;
  groupByUnpublishReasonType: Array<UnpublishReasonTypeGroupBy>;
  user?: Maybe<User>;
  findFirstUser?: Maybe<User>;
  users: Array<User>;
  aggregateUser: AggregateUser;
  groupByUser: Array<UserGroupBy>;
  userInvitedEmail?: Maybe<UserInvitedEmail>;
  findFirstUserInvitedEmail?: Maybe<UserInvitedEmail>;
  userInvitedEmails: Array<UserInvitedEmail>;
  aggregateUserInvitedEmail: AggregateUserInvitedEmail;
  groupByUserInvitedEmail: Array<UserInvitedEmailGroupBy>;
  vehicle?: Maybe<Vehicle>;
  findFirstVehicle?: Maybe<Vehicle>;
  vehicles: Array<Vehicle>;
  aggregateVehicle: AggregateVehicle;
  groupByVehicle: Array<VehicleGroupBy>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLog>;
  findFirstVehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLog>;
  vehicleCloudBoxxLogs: Array<VehicleCloudBoxxLog>;
  aggregateVehicleCloudBoxxLog: AggregateVehicleCloudBoxxLog;
  groupByVehicleCloudBoxxLog: Array<VehicleCloudBoxxLogGroupBy>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLog>;
  findFirstVehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLog>;
  vehicleCloudBoxxStatusLogs: Array<VehicleCloudBoxxStatusLog>;
  aggregateVehicleCloudBoxxStatusLog: AggregateVehicleCloudBoxxStatusLog;
  groupByVehicleCloudBoxxStatusLog: Array<VehicleCloudBoxxStatusLogGroupBy>;
  vehicleConnection?: Maybe<VehicleConnection>;
  findFirstVehicleConnection?: Maybe<VehicleConnection>;
  vehicleConnections: Array<VehicleConnection>;
  aggregateVehicleConnection: AggregateVehicleConnection;
  groupByVehicleConnection: Array<VehicleConnectionGroupBy>;
  vehicleDamage?: Maybe<VehicleDamage>;
  findFirstVehicleDamage?: Maybe<VehicleDamage>;
  vehicleDamages: Array<VehicleDamage>;
  aggregateVehicleDamage: AggregateVehicleDamage;
  groupByVehicleDamage: Array<VehicleDamageGroupBy>;
  vehicleDamageComment?: Maybe<VehicleDamageComment>;
  findFirstVehicleDamageComment?: Maybe<VehicleDamageComment>;
  vehicleDamageComments: Array<VehicleDamageComment>;
  aggregateVehicleDamageComment: AggregateVehicleDamageComment;
  groupByVehicleDamageComment: Array<VehicleDamageCommentGroupBy>;
  vehicleEquipment?: Maybe<VehicleEquipment>;
  findFirstVehicleEquipment?: Maybe<VehicleEquipment>;
  vehicleEquipments: Array<VehicleEquipment>;
  aggregateVehicleEquipment: AggregateVehicleEquipment;
  groupByVehicleEquipment: Array<VehicleEquipmentGroupBy>;
  vehicleInsuranceDocument?: Maybe<VehicleInsuranceDocument>;
  findFirstVehicleInsuranceDocument?: Maybe<VehicleInsuranceDocument>;
  vehicleInsuranceDocuments: Array<VehicleInsuranceDocument>;
  aggregateVehicleInsuranceDocument: AggregateVehicleInsuranceDocument;
  groupByVehicleInsuranceDocument: Array<VehicleInsuranceDocumentGroupBy>;
  vehicleInsuranceRule?: Maybe<VehicleInsuranceRule>;
  findFirstVehicleInsuranceRule?: Maybe<VehicleInsuranceRule>;
  vehicleInsuranceRules: Array<VehicleInsuranceRule>;
  aggregateVehicleInsuranceRule: AggregateVehicleInsuranceRule;
  groupByVehicleInsuranceRule: Array<VehicleInsuranceRuleGroupBy>;
  vehicleInsuranceRuleSet?: Maybe<VehicleInsuranceRuleSet>;
  findFirstVehicleInsuranceRuleSet?: Maybe<VehicleInsuranceRuleSet>;
  vehicleInsuranceRuleSets: Array<VehicleInsuranceRuleSet>;
  aggregateVehicleInsuranceRuleSet: AggregateVehicleInsuranceRuleSet;
  groupByVehicleInsuranceRuleSet: Array<VehicleInsuranceRuleSetGroupBy>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  findFirstVehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  vehicleInsuranceTypes: Array<VehicleInsuranceType>;
  aggregateVehicleInsuranceType: AggregateVehicleInsuranceType;
  groupByVehicleInsuranceType: Array<VehicleInsuranceTypeGroupBy>;
  vehicleKindType?: Maybe<VehicleKindType>;
  findFirstVehicleKindType?: Maybe<VehicleKindType>;
  vehicleKindTypes: Array<VehicleKindType>;
  aggregateVehicleKindType: AggregateVehicleKindType;
  groupByVehicleKindType: Array<VehicleKindTypeGroupBy>;
  vehicleLocation?: Maybe<VehicleLocation>;
  findFirstVehicleLocation?: Maybe<VehicleLocation>;
  vehicleLocations: Array<VehicleLocation>;
  aggregateVehicleLocation: AggregateVehicleLocation;
  groupByVehicleLocation: Array<VehicleLocationGroupBy>;
  vehiclePhoto?: Maybe<VehiclePhoto>;
  findFirstVehiclePhoto?: Maybe<VehiclePhoto>;
  vehiclePhotos: Array<VehiclePhoto>;
  aggregateVehiclePhoto: AggregateVehiclePhoto;
  groupByVehiclePhoto: Array<VehiclePhotoGroupBy>;
  vehicleType?: Maybe<VehicleType>;
  findFirstVehicleType?: Maybe<VehicleType>;
  vehicleTypes: Array<VehicleType>;
  aggregateVehicleType: AggregateVehicleType;
  groupByVehicleType: Array<VehicleTypeGroupBy>;
  vehicleAvailability: VehicleAvailabilityDto;
  getAffectedBookings: Array<Booking>;
  getBookingsForVehicleByDateRange: Array<Booking>;
  getBookingCalculation: BookingCalculationResponseDto;
  getBookingDetail: Booking;
  getCancellationFees: BookingCancellationFees;
  fetchInvisiaList: Array<ChargingStationInstallation>;
  chargingStationRfidInvisia: ChargingStationRfidInvisia;
  chargingStationRfidInvisias: Array<ChargingStationRfidInvisia>;
  aggregateChargingStationRfidInvisia: AggregateChargingStationRfidInvisia;
  getTransactionCalculation: TransactionCalculationTariff;
  cloudBoxxStatusLogs: Array<VehicleCloudBoxxStatusLog>;
  getVehicleStatus: VehicleStatus;
  vehicleCommentSuggestions: Array<Scalars['String']>;
  getAvailableVehicles: Array<VehicleExtended>;
  getBookingCheckAvailability: Array<Scalars['String']>;
  vehicleFilters?: Maybe<VehicleFilter>;
  vehicleReport: VehicleReport;
  vehicleReports: Array<VehicleReport>;
  aggregateVehicleReport: AggregateVehicleReport;
  updateVehicle?: Maybe<Vehicle>;
  createVehicle?: Maybe<Vehicle>;
  vehicleTypeList: VehicleTypesDto;
};

export type QueryAvailabilityArgs = {
  where: AvailabilityWhereUniqueInput;
};

export type QueryFindFirstAvailabilityArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<AvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AvailabilityScalarFieldEnum>>;
};

export type QueryAvailabilitiesArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<AvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AvailabilityScalarFieldEnum>>;
};

export type QueryAggregateAvailabilityArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<AvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByAvailabilityArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithAggregationInput>>;
  by: Array<AvailabilityScalarFieldEnum>;
  having?: Maybe<AvailabilityScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBillingAccountArgs = {
  where: BillingAccountWhereUniqueInput;
};

export type QueryFindFirstBillingAccountArgs = {
  where?: Maybe<BillingAccountWhereInput>;
  orderBy?: Maybe<Array<BillingAccountOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BillingAccountScalarFieldEnum>>;
};

export type QueryBillingAccountsArgs = {
  where?: Maybe<BillingAccountWhereInput>;
  orderBy?: Maybe<Array<BillingAccountOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BillingAccountScalarFieldEnum>>;
};

export type QueryAggregateBillingAccountArgs = {
  where?: Maybe<BillingAccountWhereInput>;
  orderBy?: Maybe<Array<BillingAccountOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBillingAccountArgs = {
  where?: Maybe<BillingAccountWhereInput>;
  orderBy?: Maybe<Array<BillingAccountOrderByWithAggregationInput>>;
  by: Array<BillingAccountScalarFieldEnum>;
  having?: Maybe<BillingAccountScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBillingAccountDocumentArgs = {
  where: BillingAccountDocumentWhereUniqueInput;
};

export type QueryFindFirstBillingAccountDocumentArgs = {
  where?: Maybe<BillingAccountDocumentWhereInput>;
  orderBy?: Maybe<Array<BillingAccountDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BillingAccountDocumentScalarFieldEnum>>;
};

export type QueryBillingAccountDocumentsArgs = {
  where?: Maybe<BillingAccountDocumentWhereInput>;
  orderBy?: Maybe<Array<BillingAccountDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BillingAccountDocumentScalarFieldEnum>>;
};

export type QueryAggregateBillingAccountDocumentArgs = {
  where?: Maybe<BillingAccountDocumentWhereInput>;
  orderBy?: Maybe<Array<BillingAccountDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<BillingAccountDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBillingAccountDocumentArgs = {
  where?: Maybe<BillingAccountDocumentWhereInput>;
  orderBy?: Maybe<Array<BillingAccountDocumentOrderByWithAggregationInput>>;
  by: Array<BillingAccountDocumentScalarFieldEnum>;
  having?: Maybe<BillingAccountDocumentScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBiometricTokenArgs = {
  where: BiometricTokenWhereUniqueInput;
};

export type QueryFindFirstBiometricTokenArgs = {
  where?: Maybe<BiometricTokenWhereInput>;
  orderBy?: Maybe<Array<BiometricTokenOrderByWithRelationInput>>;
  cursor?: Maybe<BiometricTokenWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BiometricTokenScalarFieldEnum>>;
};

export type QueryBiometricTokensArgs = {
  where?: Maybe<BiometricTokenWhereInput>;
  orderBy?: Maybe<Array<BiometricTokenOrderByWithRelationInput>>;
  cursor?: Maybe<BiometricTokenWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BiometricTokenScalarFieldEnum>>;
};

export type QueryAggregateBiometricTokenArgs = {
  where?: Maybe<BiometricTokenWhereInput>;
  orderBy?: Maybe<Array<BiometricTokenOrderByWithRelationInput>>;
  cursor?: Maybe<BiometricTokenWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBiometricTokenArgs = {
  where?: Maybe<BiometricTokenWhereInput>;
  orderBy?: Maybe<Array<BiometricTokenOrderByWithAggregationInput>>;
  by: Array<BiometricTokenScalarFieldEnum>;
  having?: Maybe<BiometricTokenScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBodyTypeArgs = {
  where: BodyTypeWhereUniqueInput;
};

export type QueryFindFirstBodyTypeArgs = {
  where?: Maybe<BodyTypeWhereInput>;
  orderBy?: Maybe<Array<BodyTypeOrderByWithRelationInput>>;
  cursor?: Maybe<BodyTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BodyTypeScalarFieldEnum>>;
};

export type QueryBodyTypesArgs = {
  where?: Maybe<BodyTypeWhereInput>;
  orderBy?: Maybe<Array<BodyTypeOrderByWithRelationInput>>;
  cursor?: Maybe<BodyTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BodyTypeScalarFieldEnum>>;
};

export type QueryAggregateBodyTypeArgs = {
  where?: Maybe<BodyTypeWhereInput>;
  orderBy?: Maybe<Array<BodyTypeOrderByWithRelationInput>>;
  cursor?: Maybe<BodyTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBodyTypeArgs = {
  where?: Maybe<BodyTypeWhereInput>;
  orderBy?: Maybe<Array<BodyTypeOrderByWithAggregationInput>>;
  by: Array<BodyTypeScalarFieldEnum>;
  having?: Maybe<BodyTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingArgs = {
  where: BookingWhereUniqueInput;
};

export type QueryFindFirstBookingArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type QueryBookingsArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
  bookingStatus?: Maybe<Scalars['String']>;
};

export type QueryAggregateBookingArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithAggregationInput>>;
  by: Array<BookingScalarFieldEnum>;
  having?: Maybe<BookingScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingAdditionalDriverArgs = {
  where: BookingAdditionalDriverWhereUniqueInput;
};

export type QueryFindFirstBookingAdditionalDriverArgs = {
  where?: Maybe<BookingAdditionalDriverWhereInput>;
  orderBy?: Maybe<Array<BookingAdditionalDriverOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAdditionalDriverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAdditionalDriverScalarFieldEnum>>;
};

export type QueryBookingAdditionalDriversArgs = {
  where?: Maybe<BookingAdditionalDriverWhereInput>;
  orderBy?: Maybe<Array<BookingAdditionalDriverOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAdditionalDriverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAdditionalDriverScalarFieldEnum>>;
};

export type QueryAggregateBookingAdditionalDriverArgs = {
  where?: Maybe<BookingAdditionalDriverWhereInput>;
  orderBy?: Maybe<Array<BookingAdditionalDriverOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAdditionalDriverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingAdditionalDriverArgs = {
  where?: Maybe<BookingAdditionalDriverWhereInput>;
  orderBy?: Maybe<Array<BookingAdditionalDriverOrderByWithAggregationInput>>;
  by: Array<BookingAdditionalDriverScalarFieldEnum>;
  having?: Maybe<BookingAdditionalDriverScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingAnswerArgs = {
  where: BookingAnswerWhereUniqueInput;
};

export type QueryFindFirstBookingAnswerArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
  orderBy?: Maybe<Array<BookingAnswerOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAnswerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAnswerScalarFieldEnum>>;
};

export type QueryBookingAnswersArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
  orderBy?: Maybe<Array<BookingAnswerOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAnswerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAnswerScalarFieldEnum>>;
};

export type QueryAggregateBookingAnswerArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
  orderBy?: Maybe<Array<BookingAnswerOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAnswerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingAnswerArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
  orderBy?: Maybe<Array<BookingAnswerOrderByWithAggregationInput>>;
  by: Array<BookingAnswerScalarFieldEnum>;
  having?: Maybe<BookingAnswerScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingAvailabilityArgs = {
  where: BookingAvailabilityWhereUniqueInput;
};

export type QueryFindFirstBookingAvailabilityArgs = {
  where?: Maybe<BookingAvailabilityWhereInput>;
  orderBy?: Maybe<Array<BookingAvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAvailabilityScalarFieldEnum>>;
};

export type QueryBookingAvailabilitiesArgs = {
  where?: Maybe<BookingAvailabilityWhereInput>;
  orderBy?: Maybe<Array<BookingAvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAvailabilityScalarFieldEnum>>;
};

export type QueryAggregateBookingAvailabilityArgs = {
  where?: Maybe<BookingAvailabilityWhereInput>;
  orderBy?: Maybe<Array<BookingAvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingAvailabilityArgs = {
  where?: Maybe<BookingAvailabilityWhereInput>;
  orderBy?: Maybe<Array<BookingAvailabilityOrderByWithAggregationInput>>;
  by: Array<BookingAvailabilityScalarFieldEnum>;
  having?: Maybe<BookingAvailabilityScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingChangeHistoryArgs = {
  where: BookingChangeHistoryWhereUniqueInput;
};

export type QueryFindFirstBookingChangeHistoryArgs = {
  where?: Maybe<BookingChangeHistoryWhereInput>;
  orderBy?: Maybe<Array<BookingChangeHistoryOrderByWithRelationInput>>;
  cursor?: Maybe<BookingChangeHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingChangeHistoryScalarFieldEnum>>;
};

export type QueryBookingChangeHistoriesArgs = {
  where?: Maybe<BookingChangeHistoryWhereInput>;
  orderBy?: Maybe<Array<BookingChangeHistoryOrderByWithRelationInput>>;
  cursor?: Maybe<BookingChangeHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingChangeHistoryScalarFieldEnum>>;
};

export type QueryAggregateBookingChangeHistoryArgs = {
  where?: Maybe<BookingChangeHistoryWhereInput>;
  orderBy?: Maybe<Array<BookingChangeHistoryOrderByWithRelationInput>>;
  cursor?: Maybe<BookingChangeHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingChangeHistoryArgs = {
  where?: Maybe<BookingChangeHistoryWhereInput>;
  orderBy?: Maybe<Array<BookingChangeHistoryOrderByWithAggregationInput>>;
  by: Array<BookingChangeHistoryScalarFieldEnum>;
  having?: Maybe<BookingChangeHistoryScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingFuelPasswordArgs = {
  where: BookingFuelPasswordWhereUniqueInput;
};

export type QueryFindFirstBookingFuelPasswordArgs = {
  where?: Maybe<BookingFuelPasswordWhereInput>;
  orderBy?: Maybe<Array<BookingFuelPasswordOrderByWithRelationInput>>;
  cursor?: Maybe<BookingFuelPasswordWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingFuelPasswordScalarFieldEnum>>;
};

export type QueryBookingFuelPasswordsArgs = {
  where?: Maybe<BookingFuelPasswordWhereInput>;
  orderBy?: Maybe<Array<BookingFuelPasswordOrderByWithRelationInput>>;
  cursor?: Maybe<BookingFuelPasswordWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingFuelPasswordScalarFieldEnum>>;
};

export type QueryAggregateBookingFuelPasswordArgs = {
  where?: Maybe<BookingFuelPasswordWhereInput>;
  orderBy?: Maybe<Array<BookingFuelPasswordOrderByWithRelationInput>>;
  cursor?: Maybe<BookingFuelPasswordWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingFuelPasswordArgs = {
  where?: Maybe<BookingFuelPasswordWhereInput>;
  orderBy?: Maybe<Array<BookingFuelPasswordOrderByWithAggregationInput>>;
  by: Array<BookingFuelPasswordScalarFieldEnum>;
  having?: Maybe<BookingFuelPasswordScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingQuestionArgs = {
  where: BookingQuestionWhereUniqueInput;
};

export type QueryFindFirstBookingQuestionArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
  orderBy?: Maybe<Array<BookingQuestionOrderByWithRelationInput>>;
  cursor?: Maybe<BookingQuestionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingQuestionScalarFieldEnum>>;
};

export type QueryBookingQuestionsArgs = {
  userId: Scalars['String'];
  vehicleId?: Maybe<Scalars['String']>;
};

export type QueryAggregateBookingQuestionArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
  orderBy?: Maybe<Array<BookingQuestionOrderByWithRelationInput>>;
  cursor?: Maybe<BookingQuestionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingQuestionArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
  orderBy?: Maybe<Array<BookingQuestionOrderByWithAggregationInput>>;
  by: Array<BookingQuestionScalarFieldEnum>;
  having?: Maybe<BookingQuestionScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBookingRefundingArgs = {
  where: BookingRefundingWhereUniqueInput;
};

export type QueryFindFirstBookingRefundingArgs = {
  where?: Maybe<BookingRefundingWhereInput>;
  orderBy?: Maybe<Array<BookingRefundingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingRefundingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingRefundingScalarFieldEnum>>;
};

export type QueryBookingRefundingsArgs = {
  where?: Maybe<BookingRefundingWhereInput>;
  orderBy?: Maybe<Array<BookingRefundingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingRefundingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingRefundingScalarFieldEnum>>;
};

export type QueryAggregateBookingRefundingArgs = {
  where?: Maybe<BookingRefundingWhereInput>;
  orderBy?: Maybe<Array<BookingRefundingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingRefundingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingRefundingArgs = {
  where?: Maybe<BookingRefundingWhereInput>;
  orderBy?: Maybe<Array<BookingRefundingOrderByWithAggregationInput>>;
  by: Array<BookingRefundingScalarFieldEnum>;
  having?: Maybe<BookingRefundingScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueBookingVehicleDamagesArgs = {
  where: BookingVehicleDamagesWhereUniqueInput;
};

export type QueryFindFirstBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
  orderBy?: Maybe<Array<BookingVehicleDamagesOrderByWithRelationInput>>;
  cursor?: Maybe<BookingVehicleDamagesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingVehicleDamagesScalarFieldEnum>>;
};

export type QueryFindManyBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
  orderBy?: Maybe<Array<BookingVehicleDamagesOrderByWithRelationInput>>;
  cursor?: Maybe<BookingVehicleDamagesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingVehicleDamagesScalarFieldEnum>>;
};

export type QueryAggregateBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
  orderBy?: Maybe<Array<BookingVehicleDamagesOrderByWithRelationInput>>;
  cursor?: Maybe<BookingVehicleDamagesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
  orderBy?: Maybe<Array<BookingVehicleDamagesOrderByWithAggregationInput>>;
  by: Array<BookingVehicleDamagesScalarFieldEnum>;
  having?: Maybe<BookingVehicleDamagesScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryBrandTypeArgs = {
  where: BrandTypeWhereUniqueInput;
};

export type QueryFindFirstBrandTypeArgs = {
  where?: Maybe<BrandTypeWhereInput>;
  orderBy?: Maybe<Array<BrandTypeOrderByWithRelationInput>>;
  cursor?: Maybe<BrandTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BrandTypeScalarFieldEnum>>;
};

export type QueryBrandTypesArgs = {
  where?: Maybe<BrandTypeWhereInput>;
  orderBy?: Maybe<Array<BrandTypeOrderByWithRelationInput>>;
  cursor?: Maybe<BrandTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BrandTypeScalarFieldEnum>>;
};

export type QueryAggregateBrandTypeArgs = {
  where?: Maybe<BrandTypeWhereInput>;
  orderBy?: Maybe<Array<BrandTypeOrderByWithRelationInput>>;
  cursor?: Maybe<BrandTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByBrandTypeArgs = {
  where?: Maybe<BrandTypeWhereInput>;
  orderBy?: Maybe<Array<BrandTypeOrderByWithAggregationInput>>;
  by: Array<BrandTypeScalarFieldEnum>;
  having?: Maybe<BrandTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationArgs = {
  where: ChargingStationWhereUniqueInput;
};

export type QueryFindFirstChargingStationArgs = {
  where?: Maybe<ChargingStationWhereInput>;
  orderBy?: Maybe<Array<ChargingStationOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationScalarFieldEnum>>;
};

export type QueryChargingStationsArgs = {
  where?: Maybe<ChargingStationWhereInput>;
  orderBy?: Maybe<Array<ChargingStationOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationScalarFieldEnum>>;
};

export type QueryAggregateChargingStationArgs = {
  where?: Maybe<ChargingStationWhereInput>;
  orderBy?: Maybe<Array<ChargingStationOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationArgs = {
  where?: Maybe<ChargingStationWhereInput>;
  orderBy?: Maybe<Array<ChargingStationOrderByWithAggregationInput>>;
  by: Array<ChargingStationScalarFieldEnum>;
  having?: Maybe<ChargingStationScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationClusterArgs = {
  where: ChargingStationClusterWhereUniqueInput;
};

export type QueryFindFirstChargingStationClusterArgs = {
  where?: Maybe<ChargingStationClusterWhereInput>;
  orderBy?: Maybe<Array<ChargingStationClusterOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationClusterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationClusterScalarFieldEnum>>;
};

export type QueryChargingStationClustersArgs = {
  where?: Maybe<ChargingStationClusterWhereInput>;
  orderBy?: Maybe<Array<ChargingStationClusterOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationClusterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationClusterScalarFieldEnum>>;
};

export type QueryAggregateChargingStationClusterArgs = {
  where?: Maybe<ChargingStationClusterWhereInput>;
  orderBy?: Maybe<Array<ChargingStationClusterOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationClusterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationClusterArgs = {
  where?: Maybe<ChargingStationClusterWhereInput>;
  orderBy?: Maybe<Array<ChargingStationClusterOrderByWithAggregationInput>>;
  by: Array<ChargingStationClusterScalarFieldEnum>;
  having?: Maybe<ChargingStationClusterScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationRfidArgs = {
  where: ChargingStationRfidWhereUniqueInput;
};

export type QueryFindFirstChargingStationRfidArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidScalarFieldEnum>>;
};

export type QueryChargingStationRfidsArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidScalarFieldEnum>>;
};

export type QueryAggregateChargingStationRfidArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationRfidArgs = {
  where?: Maybe<ChargingStationRfidWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidOrderByWithAggregationInput>>;
  by: Array<ChargingStationRfidScalarFieldEnum>;
  having?: Maybe<ChargingStationRfidScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationRfidAliasArgs = {
  where: ChargingStationRfidAliasWhereUniqueInput;
};

export type QueryFindFirstChargingStationRfidAliasArgs = {
  where?: Maybe<ChargingStationRfidAliasWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidAliasOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidAliasWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidAliasScalarFieldEnum>>;
};

export type QueryChargingStationRfidAliasesArgs = {
  where?: Maybe<ChargingStationRfidAliasWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidAliasOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidAliasWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationRfidAliasScalarFieldEnum>>;
};

export type QueryAggregateChargingStationRfidAliasArgs = {
  where?: Maybe<ChargingStationRfidAliasWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidAliasOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationRfidAliasWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationRfidAliasArgs = {
  where?: Maybe<ChargingStationRfidAliasWhereInput>;
  orderBy?: Maybe<Array<ChargingStationRfidAliasOrderByWithAggregationInput>>;
  by: Array<ChargingStationRfidAliasScalarFieldEnum>;
  having?: Maybe<ChargingStationRfidAliasScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationTariffArgs = {
  where: ChargingStationTariffWhereUniqueInput;
};

export type QueryFindFirstChargingStationTariffArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffScalarFieldEnum>>;
};

export type QueryChargingStationTariffsArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffScalarFieldEnum>>;
};

export type QueryAggregateChargingStationTariffArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationTariffArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffOrderByWithAggregationInput>>;
  by: Array<ChargingStationTariffScalarFieldEnum>;
  having?: Maybe<ChargingStationTariffScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationTariffRuleArgs = {
  where: ChargingStationTariffRuleWhereUniqueInput;
};

export type QueryFindFirstChargingStationTariffRuleArgs = {
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffRuleOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffRuleScalarFieldEnum>>;
};

export type QueryChargingStationTariffRulesArgs = {
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffRuleOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffRuleScalarFieldEnum>>;
};

export type QueryAggregateChargingStationTariffRuleArgs = {
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffRuleOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationTariffRuleArgs = {
  where?: Maybe<ChargingStationTariffRuleWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffRuleOrderByWithAggregationInput>>;
  by: Array<ChargingStationTariffRuleScalarFieldEnum>;
  having?: Maybe<ChargingStationTariffRuleScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationTransactionArgs = {
  where: ChargingStationTransactionWhereUniqueInput;
};

export type QueryFindFirstChargingStationTransactionArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionScalarFieldEnum>>;
};

export type QueryChargingStationTransactionsArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionScalarFieldEnum>>;
};

export type QueryAggregateChargingStationTransactionArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationTransactionArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTransactionOrderByWithAggregationInput>>;
  by: Array<ChargingStationTransactionScalarFieldEnum>;
  having?: Maybe<ChargingStationTransactionScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationTransactionAppliedTariffArgs = {
  where: ChargingStationTransactionAppliedTariffWhereUniqueInput;
};

export type QueryFindFirstChargingStationTransactionAppliedTariffArgs = {
  where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionAppliedTariffOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionAppliedTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<
    Array<ChargingStationTransactionAppliedTariffScalarFieldEnum>
  >;
};

export type QueryChargingStationTransactionAppliedTariffsArgs = {
  where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionAppliedTariffOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionAppliedTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<
    Array<ChargingStationTransactionAppliedTariffScalarFieldEnum>
  >;
};

export type QueryAggregateChargingStationTransactionAppliedTariffArgs = {
  where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionAppliedTariffOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionAppliedTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationTransactionAppliedTariffArgs = {
  where?: Maybe<ChargingStationTransactionAppliedTariffWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionAppliedTariffOrderByWithAggregationInput>
  >;
  by: Array<ChargingStationTransactionAppliedTariffScalarFieldEnum>;
  having?: Maybe<ChargingStationTransactionAppliedTariffScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationTransactionReportArgs = {
  where: ChargingStationTransactionReportWhereUniqueInput;
};

export type QueryFindFirstChargingStationTransactionReportArgs = {
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionReportOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionReportScalarFieldEnum>>;
};

export type QueryChargingStationTransactionReportsArgs = {
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionReportOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionReportScalarFieldEnum>>;
};

export type QueryAggregateChargingStationTransactionReportArgs = {
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionReportOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionReportWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationTransactionReportArgs = {
  where?: Maybe<ChargingStationTransactionReportWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionReportOrderByWithAggregationInput>
  >;
  by: Array<ChargingStationTransactionReportScalarFieldEnum>;
  having?: Maybe<ChargingStationTransactionReportScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChargingStationTransactionUsageArgs = {
  where: ChargingStationTransactionUsageWhereUniqueInput;
};

export type QueryFindFirstChargingStationTransactionUsageArgs = {
  where?: Maybe<ChargingStationTransactionUsageWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionUsageOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionUsageScalarFieldEnum>>;
};

export type QueryChargingStationTransactionUsagesArgs = {
  where?: Maybe<ChargingStationTransactionUsageWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionUsageOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionUsageScalarFieldEnum>>;
};

export type QueryAggregateChargingStationTransactionUsageArgs = {
  where?: Maybe<ChargingStationTransactionUsageWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionUsageOrderByWithRelationInput>
  >;
  cursor?: Maybe<ChargingStationTransactionUsageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChargingStationTransactionUsageArgs = {
  where?: Maybe<ChargingStationTransactionUsageWhereInput>;
  orderBy?: Maybe<
    Array<ChargingStationTransactionUsageOrderByWithAggregationInput>
  >;
  by: Array<ChargingStationTransactionUsageScalarFieldEnum>;
  having?: Maybe<ChargingStationTransactionUsageScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryChildSeatTypeArgs = {
  where: ChildSeatTypeWhereUniqueInput;
};

export type QueryFindFirstChildSeatTypeArgs = {
  where?: Maybe<ChildSeatTypeWhereInput>;
  orderBy?: Maybe<Array<ChildSeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ChildSeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChildSeatTypeScalarFieldEnum>>;
};

export type QueryChildSeatTypesArgs = {
  where?: Maybe<ChildSeatTypeWhereInput>;
  orderBy?: Maybe<Array<ChildSeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ChildSeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChildSeatTypeScalarFieldEnum>>;
};

export type QueryAggregateChildSeatTypeArgs = {
  where?: Maybe<ChildSeatTypeWhereInput>;
  orderBy?: Maybe<Array<ChildSeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ChildSeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByChildSeatTypeArgs = {
  where?: Maybe<ChildSeatTypeWhereInput>;
  orderBy?: Maybe<Array<ChildSeatTypeOrderByWithAggregationInput>>;
  by: Array<ChildSeatTypeScalarFieldEnum>;
  having?: Maybe<ChildSeatTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryColorTypeArgs = {
  where: ColorTypeWhereUniqueInput;
};

export type QueryFindFirstColorTypeArgs = {
  where?: Maybe<ColorTypeWhereInput>;
  orderBy?: Maybe<Array<ColorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ColorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ColorTypeScalarFieldEnum>>;
};

export type QueryColorTypesArgs = {
  where?: Maybe<ColorTypeWhereInput>;
  orderBy?: Maybe<Array<ColorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ColorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ColorTypeScalarFieldEnum>>;
};

export type QueryAggregateColorTypeArgs = {
  where?: Maybe<ColorTypeWhereInput>;
  orderBy?: Maybe<Array<ColorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ColorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByColorTypeArgs = {
  where?: Maybe<ColorTypeWhereInput>;
  orderBy?: Maybe<Array<ColorTypeOrderByWithAggregationInput>>;
  by: Array<ColorTypeScalarFieldEnum>;
  having?: Maybe<ColorTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryCompanyArgs = {
  where: CompanyWhereUniqueInput;
};

export type QueryFindFirstCompanyArgs = {
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<CompanyOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CompanyScalarFieldEnum>>;
};

export type QueryCompaniesArgs = {
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<CompanyOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CompanyScalarFieldEnum>>;
};

export type QueryAggregateCompanyArgs = {
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<CompanyOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByCompanyArgs = {
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<CompanyOrderByWithAggregationInput>>;
  by: Array<CompanyScalarFieldEnum>;
  having?: Maybe<CompanyScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryCompanyDocumentArgs = {
  where: CompanyDocumentWhereUniqueInput;
};

export type QueryFindFirstCompanyDocumentArgs = {
  where?: Maybe<CompanyDocumentWhereInput>;
  orderBy?: Maybe<Array<CompanyDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CompanyDocumentScalarFieldEnum>>;
};

export type QueryCompanyDocumentsArgs = {
  where?: Maybe<CompanyDocumentWhereInput>;
  orderBy?: Maybe<Array<CompanyDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CompanyDocumentScalarFieldEnum>>;
};

export type QueryAggregateCompanyDocumentArgs = {
  where?: Maybe<CompanyDocumentWhereInput>;
  orderBy?: Maybe<Array<CompanyDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByCompanyDocumentArgs = {
  where?: Maybe<CompanyDocumentWhereInput>;
  orderBy?: Maybe<Array<CompanyDocumentOrderByWithAggregationInput>>;
  by: Array<CompanyDocumentScalarFieldEnum>;
  having?: Maybe<CompanyDocumentScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryConsumptionTypeArgs = {
  where: ConsumptionTypeWhereUniqueInput;
};

export type QueryFindFirstConsumptionTypeArgs = {
  where?: Maybe<ConsumptionTypeWhereInput>;
  orderBy?: Maybe<Array<ConsumptionTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ConsumptionTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ConsumptionTypeScalarFieldEnum>>;
};

export type QueryConsumptionTypesArgs = {
  where?: Maybe<ConsumptionTypeWhereInput>;
  orderBy?: Maybe<Array<ConsumptionTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ConsumptionTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ConsumptionTypeScalarFieldEnum>>;
};

export type QueryAggregateConsumptionTypeArgs = {
  where?: Maybe<ConsumptionTypeWhereInput>;
  orderBy?: Maybe<Array<ConsumptionTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ConsumptionTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByConsumptionTypeArgs = {
  where?: Maybe<ConsumptionTypeWhereInput>;
  orderBy?: Maybe<Array<ConsumptionTypeOrderByWithAggregationInput>>;
  by: Array<ConsumptionTypeScalarFieldEnum>;
  having?: Maybe<ConsumptionTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryDoorTypeArgs = {
  where: DoorTypeWhereUniqueInput;
};

export type QueryFindFirstDoorTypeArgs = {
  where?: Maybe<DoorTypeWhereInput>;
  orderBy?: Maybe<Array<DoorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<DoorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DoorTypeScalarFieldEnum>>;
};

export type QueryDoorTypesArgs = {
  where?: Maybe<DoorTypeWhereInput>;
  orderBy?: Maybe<Array<DoorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<DoorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DoorTypeScalarFieldEnum>>;
};

export type QueryAggregateDoorTypeArgs = {
  where?: Maybe<DoorTypeWhereInput>;
  orderBy?: Maybe<Array<DoorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<DoorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByDoorTypeArgs = {
  where?: Maybe<DoorTypeWhereInput>;
  orderBy?: Maybe<Array<DoorTypeOrderByWithAggregationInput>>;
  by: Array<DoorTypeScalarFieldEnum>;
  having?: Maybe<DoorTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryDriveTypeArgs = {
  where: DriveTypeWhereUniqueInput;
};

export type QueryFindFirstDriveTypeArgs = {
  where?: Maybe<DriveTypeWhereInput>;
  orderBy?: Maybe<Array<DriveTypeOrderByWithRelationInput>>;
  cursor?: Maybe<DriveTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DriveTypeScalarFieldEnum>>;
};

export type QueryDriveTypesArgs = {
  where?: Maybe<DriveTypeWhereInput>;
  orderBy?: Maybe<Array<DriveTypeOrderByWithRelationInput>>;
  cursor?: Maybe<DriveTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<DriveTypeScalarFieldEnum>>;
};

export type QueryAggregateDriveTypeArgs = {
  where?: Maybe<DriveTypeWhereInput>;
  orderBy?: Maybe<Array<DriveTypeOrderByWithRelationInput>>;
  cursor?: Maybe<DriveTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByDriveTypeArgs = {
  where?: Maybe<DriveTypeWhereInput>;
  orderBy?: Maybe<Array<DriveTypeOrderByWithAggregationInput>>;
  by: Array<DriveTypeScalarFieldEnum>;
  having?: Maybe<DriveTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryEmissionCodeTypeArgs = {
  where: EmissionCodeTypeWhereUniqueInput;
};

export type QueryFindFirstEmissionCodeTypeArgs = {
  where?: Maybe<EmissionCodeTypeWhereInput>;
  orderBy?: Maybe<Array<EmissionCodeTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EmissionCodeTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EmissionCodeTypeScalarFieldEnum>>;
};

export type QueryEmissionCodeTypesArgs = {
  where?: Maybe<EmissionCodeTypeWhereInput>;
  orderBy?: Maybe<Array<EmissionCodeTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EmissionCodeTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EmissionCodeTypeScalarFieldEnum>>;
};

export type QueryAggregateEmissionCodeTypeArgs = {
  where?: Maybe<EmissionCodeTypeWhereInput>;
  orderBy?: Maybe<Array<EmissionCodeTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EmissionCodeTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByEmissionCodeTypeArgs = {
  where?: Maybe<EmissionCodeTypeWhereInput>;
  orderBy?: Maybe<Array<EmissionCodeTypeOrderByWithAggregationInput>>;
  by: Array<EmissionCodeTypeScalarFieldEnum>;
  having?: Maybe<EmissionCodeTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryEnergyEfficiencyTypeArgs = {
  where: EnergyEfficiencyTypeWhereUniqueInput;
};

export type QueryFindFirstEnergyEfficiencyTypeArgs = {
  where?: Maybe<EnergyEfficiencyTypeWhereInput>;
  orderBy?: Maybe<Array<EnergyEfficiencyTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EnergyEfficiencyTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EnergyEfficiencyTypeScalarFieldEnum>>;
};

export type QueryEnergyEfficiencyTypesArgs = {
  where?: Maybe<EnergyEfficiencyTypeWhereInput>;
  orderBy?: Maybe<Array<EnergyEfficiencyTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EnergyEfficiencyTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EnergyEfficiencyTypeScalarFieldEnum>>;
};

export type QueryAggregateEnergyEfficiencyTypeArgs = {
  where?: Maybe<EnergyEfficiencyTypeWhereInput>;
  orderBy?: Maybe<Array<EnergyEfficiencyTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EnergyEfficiencyTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByEnergyEfficiencyTypeArgs = {
  where?: Maybe<EnergyEfficiencyTypeWhereInput>;
  orderBy?: Maybe<Array<EnergyEfficiencyTypeOrderByWithAggregationInput>>;
  by: Array<EnergyEfficiencyTypeScalarFieldEnum>;
  having?: Maybe<EnergyEfficiencyTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryEquipmentTypeArgs = {
  where: EquipmentTypeWhereUniqueInput;
};

export type QueryFindFirstEquipmentTypeArgs = {
  where?: Maybe<EquipmentTypeWhereInput>;
  orderBy?: Maybe<Array<EquipmentTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EquipmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EquipmentTypeScalarFieldEnum>>;
};

export type QueryEquipmentTypesArgs = {
  where?: Maybe<EquipmentTypeWhereInput>;
  orderBy?: Maybe<Array<EquipmentTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EquipmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<EquipmentTypeScalarFieldEnum>>;
};

export type QueryAggregateEquipmentTypeArgs = {
  where?: Maybe<EquipmentTypeWhereInput>;
  orderBy?: Maybe<Array<EquipmentTypeOrderByWithRelationInput>>;
  cursor?: Maybe<EquipmentTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByEquipmentTypeArgs = {
  where?: Maybe<EquipmentTypeWhereInput>;
  orderBy?: Maybe<Array<EquipmentTypeOrderByWithAggregationInput>>;
  by: Array<EquipmentTypeScalarFieldEnum>;
  having?: Maybe<EquipmentTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFuelTypeArgs = {
  where: FuelTypeWhereUniqueInput;
};

export type QueryFindFirstFuelTypeArgs = {
  where?: Maybe<FuelTypeWhereInput>;
  orderBy?: Maybe<Array<FuelTypeOrderByWithRelationInput>>;
  cursor?: Maybe<FuelTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FuelTypeScalarFieldEnum>>;
};

export type QueryFuelTypesArgs = {
  where?: Maybe<FuelTypeWhereInput>;
  orderBy?: Maybe<Array<FuelTypeOrderByWithRelationInput>>;
  cursor?: Maybe<FuelTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FuelTypeScalarFieldEnum>>;
};

export type QueryAggregateFuelTypeArgs = {
  where?: Maybe<FuelTypeWhereInput>;
  orderBy?: Maybe<Array<FuelTypeOrderByWithRelationInput>>;
  cursor?: Maybe<FuelTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByFuelTypeArgs = {
  where?: Maybe<FuelTypeWhereInput>;
  orderBy?: Maybe<Array<FuelTypeOrderByWithAggregationInput>>;
  by: Array<FuelTypeScalarFieldEnum>;
  having?: Maybe<FuelTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerArgs = {
  where: GateControllerWhereUniqueInput;
};

export type QueryFindFirstGateControllerArgs = {
  where?: Maybe<GateControllerWhereInput>;
  orderBy?: Maybe<Array<GateControllerOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerScalarFieldEnum>>;
};

export type QueryGateControllersArgs = {
  where?: Maybe<GateControllerWhereInput>;
  orderBy?: Maybe<Array<GateControllerOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerScalarFieldEnum>>;
};

export type QueryAggregateGateControllerArgs = {
  where?: Maybe<GateControllerWhereInput>;
  orderBy?: Maybe<Array<GateControllerOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerArgs = {
  where?: Maybe<GateControllerWhereInput>;
  orderBy?: Maybe<Array<GateControllerOrderByWithAggregationInput>>;
  by: Array<GateControllerScalarFieldEnum>;
  having?: Maybe<GateControllerScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerDocumentArgs = {
  where: GateControllerDocumentWhereUniqueInput;
};

export type QueryFindFirstGateControllerDocumentArgs = {
  where?: Maybe<GateControllerDocumentWhereInput>;
  orderBy?: Maybe<Array<GateControllerDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerDocumentScalarFieldEnum>>;
};

export type QueryGateControllerDocumentsArgs = {
  where?: Maybe<GateControllerDocumentWhereInput>;
  orderBy?: Maybe<Array<GateControllerDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerDocumentScalarFieldEnum>>;
};

export type QueryAggregateGateControllerDocumentArgs = {
  where?: Maybe<GateControllerDocumentWhereInput>;
  orderBy?: Maybe<Array<GateControllerDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerDocumentArgs = {
  where?: Maybe<GateControllerDocumentWhereInput>;
  orderBy?: Maybe<Array<GateControllerDocumentOrderByWithAggregationInput>>;
  by: Array<GateControllerDocumentScalarFieldEnum>;
  having?: Maybe<GateControllerDocumentScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerRelUserArgs = {
  where: GateControllerRelUserWhereUniqueInput;
};

export type QueryFindFirstGateControllerRelUserArgs = {
  where?: Maybe<GateControllerRelUserWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelUserScalarFieldEnum>>;
};

export type QueryGateControllerRelUsersArgs = {
  where?: Maybe<GateControllerRelUserWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelUserScalarFieldEnum>>;
};

export type QueryAggregateGateControllerRelUserArgs = {
  where?: Maybe<GateControllerRelUserWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerRelUserArgs = {
  where?: Maybe<GateControllerRelUserWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelUserOrderByWithAggregationInput>>;
  by: Array<GateControllerRelUserScalarFieldEnum>;
  having?: Maybe<GateControllerRelUserScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerRelVehicleArgs = {
  where: GateControllerRelVehicleWhereUniqueInput;
};

export type QueryFindFirstGateControllerRelVehicleArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelVehicleScalarFieldEnum>>;
};

export type QueryGateControllerRelVehiclesArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelVehicleScalarFieldEnum>>;
};

export type QueryAggregateGateControllerRelVehicleArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerRelVehicleArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelVehicleOrderByWithAggregationInput>>;
  by: Array<GateControllerRelVehicleScalarFieldEnum>;
  having?: Maybe<GateControllerRelVehicleScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerRuleArgs = {
  where: GateControllerRuleWhereUniqueInput;
};

export type QueryFindFirstGateControllerRuleArgs = {
  where?: Maybe<GateControllerRuleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRuleScalarFieldEnum>>;
};

export type QueryGateControllerRulesArgs = {
  where?: Maybe<GateControllerRuleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRuleScalarFieldEnum>>;
};

export type QueryAggregateGateControllerRuleArgs = {
  where?: Maybe<GateControllerRuleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerRuleArgs = {
  where?: Maybe<GateControllerRuleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleOrderByWithAggregationInput>>;
  by: Array<GateControllerRuleScalarFieldEnum>;
  having?: Maybe<GateControllerRuleScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerRuleSetArgs = {
  where: GateControllerRuleSetWhereUniqueInput;
};

export type QueryFindFirstGateControllerRuleSetArgs = {
  where?: Maybe<GateControllerRuleSetWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRuleSetScalarFieldEnum>>;
};

export type QueryGateControllerRuleSetsArgs = {
  where?: Maybe<GateControllerRuleSetWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRuleSetScalarFieldEnum>>;
};

export type QueryAggregateGateControllerRuleSetArgs = {
  where?: Maybe<GateControllerRuleSetWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerRuleSetArgs = {
  where?: Maybe<GateControllerRuleSetWhereInput>;
  orderBy?: Maybe<Array<GateControllerRuleSetOrderByWithAggregationInput>>;
  by: Array<GateControllerRuleSetScalarFieldEnum>;
  having?: Maybe<GateControllerRuleSetScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerTransactionArgs = {
  where: GateControllerTransactionWhereUniqueInput;
};

export type QueryFindFirstGateControllerTransactionArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTransactionScalarFieldEnum>>;
};

export type QueryGateControllerTransactionsArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTransactionScalarFieldEnum>>;
};

export type QueryAggregateGateControllerTransactionArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerTransactionArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithAggregationInput>>;
  by: Array<GateControllerTransactionScalarFieldEnum>;
  having?: Maybe<GateControllerTransactionScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGateControllerTypeArgs = {
  where: GateControllerTypeWhereUniqueInput;
};

export type QueryFindFirstGateControllerTypeArgs = {
  where?: Maybe<GateControllerTypeWhereInput>;
  orderBy?: Maybe<Array<GateControllerTypeOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTypeScalarFieldEnum>>;
};

export type QueryGateControllerTypesArgs = {
  where?: Maybe<GateControllerTypeWhereInput>;
  orderBy?: Maybe<Array<GateControllerTypeOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTypeScalarFieldEnum>>;
};

export type QueryAggregateGateControllerTypeArgs = {
  where?: Maybe<GateControllerTypeWhereInput>;
  orderBy?: Maybe<Array<GateControllerTypeOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByGateControllerTypeArgs = {
  where?: Maybe<GateControllerTypeWhereInput>;
  orderBy?: Maybe<Array<GateControllerTypeOrderByWithAggregationInput>>;
  by: Array<GateControllerTypeScalarFieldEnum>;
  having?: Maybe<GateControllerTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryMessageArgs = {
  where: MessageWhereUniqueInput;
};

export type QueryFindFirstMessageArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<Array<MessageOrderByWithRelationInput>>;
  cursor?: Maybe<MessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<MessageScalarFieldEnum>>;
};

export type QueryMessagesArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<Array<MessageOrderByWithRelationInput>>;
  cursor?: Maybe<MessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<MessageScalarFieldEnum>>;
};

export type QueryAggregateMessageArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<Array<MessageOrderByWithRelationInput>>;
  cursor?: Maybe<MessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByMessageArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<Array<MessageOrderByWithAggregationInput>>;
  by: Array<MessageScalarFieldEnum>;
  having?: Maybe<MessageScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryModelTypeArgs = {
  where: ModelTypeWhereUniqueInput;
};

export type QueryFindFirstModelTypeArgs = {
  where?: Maybe<ModelTypeWhereInput>;
  orderBy?: Maybe<Array<ModelTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ModelTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ModelTypeScalarFieldEnum>>;
};

export type QueryModelTypesArgs = {
  where?: Maybe<ModelTypeWhereInput>;
  orderBy?: Maybe<Array<ModelTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ModelTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ModelTypeScalarFieldEnum>>;
};

export type QueryAggregateModelTypeArgs = {
  where?: Maybe<ModelTypeWhereInput>;
  orderBy?: Maybe<Array<ModelTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ModelTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByModelTypeArgs = {
  where?: Maybe<ModelTypeWhereInput>;
  orderBy?: Maybe<Array<ModelTypeOrderByWithAggregationInput>>;
  by: Array<ModelTypeScalarFieldEnum>;
  having?: Maybe<ModelTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindUniqueNotificationDevicesArgs = {
  where: NotificationDevicesWhereUniqueInput;
};

export type QueryFindFirstNotificationDevicesArgs = {
  where?: Maybe<NotificationDevicesWhereInput>;
  orderBy?: Maybe<Array<NotificationDevicesOrderByWithRelationInput>>;
  cursor?: Maybe<NotificationDevicesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<NotificationDevicesScalarFieldEnum>>;
};

export type QueryFindManyNotificationDevicesArgs = {
  where?: Maybe<NotificationDevicesWhereInput>;
  orderBy?: Maybe<Array<NotificationDevicesOrderByWithRelationInput>>;
  cursor?: Maybe<NotificationDevicesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<NotificationDevicesScalarFieldEnum>>;
};

export type QueryAggregateNotificationDevicesArgs = {
  where?: Maybe<NotificationDevicesWhereInput>;
  orderBy?: Maybe<Array<NotificationDevicesOrderByWithRelationInput>>;
  cursor?: Maybe<NotificationDevicesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByNotificationDevicesArgs = {
  where?: Maybe<NotificationDevicesWhereInput>;
  orderBy?: Maybe<Array<NotificationDevicesOrderByWithAggregationInput>>;
  by: Array<NotificationDevicesScalarFieldEnum>;
  having?: Maybe<NotificationDevicesScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryParticularUseTypeArgs = {
  where: ParticularUseTypeWhereUniqueInput;
};

export type QueryFindFirstParticularUseTypeArgs = {
  where?: Maybe<ParticularUseTypeWhereInput>;
  orderBy?: Maybe<Array<ParticularUseTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ParticularUseTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ParticularUseTypeScalarFieldEnum>>;
};

export type QueryParticularUseTypesArgs = {
  where?: Maybe<ParticularUseTypeWhereInput>;
  orderBy?: Maybe<Array<ParticularUseTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ParticularUseTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ParticularUseTypeScalarFieldEnum>>;
};

export type QueryAggregateParticularUseTypeArgs = {
  where?: Maybe<ParticularUseTypeWhereInput>;
  orderBy?: Maybe<Array<ParticularUseTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ParticularUseTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByParticularUseTypeArgs = {
  where?: Maybe<ParticularUseTypeWhereInput>;
  orderBy?: Maybe<Array<ParticularUseTypeOrderByWithAggregationInput>>;
  by: Array<ParticularUseTypeScalarFieldEnum>;
  having?: Maybe<ParticularUseTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryPxlVisionManualVerificationArgs = {
  where: PxlVisionManualVerificationWhereUniqueInput;
};

export type QueryFindFirstPxlVisionManualVerificationArgs = {
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
  orderBy?: Maybe<Array<PxlVisionManualVerificationOrderByWithRelationInput>>;
  cursor?: Maybe<PxlVisionManualVerificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PxlVisionManualVerificationScalarFieldEnum>>;
};

export type QueryPxlVisionManualVerificationsArgs = {
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
  orderBy?: Maybe<Array<PxlVisionManualVerificationOrderByWithRelationInput>>;
  cursor?: Maybe<PxlVisionManualVerificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PxlVisionManualVerificationScalarFieldEnum>>;
};

export type QueryAggregatePxlVisionManualVerificationArgs = {
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
  orderBy?: Maybe<Array<PxlVisionManualVerificationOrderByWithRelationInput>>;
  cursor?: Maybe<PxlVisionManualVerificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByPxlVisionManualVerificationArgs = {
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
  orderBy?: Maybe<
    Array<PxlVisionManualVerificationOrderByWithAggregationInput>
  >;
  by: Array<PxlVisionManualVerificationScalarFieldEnum>;
  having?: Maybe<PxlVisionManualVerificationScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryRevokeReasonTypeArgs = {
  where: RevokeReasonTypeWhereUniqueInput;
};

export type QueryFindFirstRevokeReasonTypeArgs = {
  where?: Maybe<RevokeReasonTypeWhereInput>;
  orderBy?: Maybe<Array<RevokeReasonTypeOrderByWithRelationInput>>;
  cursor?: Maybe<RevokeReasonTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<RevokeReasonTypeScalarFieldEnum>>;
};

export type QueryRevokeReasonTypesArgs = {
  where?: Maybe<RevokeReasonTypeWhereInput>;
  orderBy?: Maybe<Array<RevokeReasonTypeOrderByWithRelationInput>>;
  cursor?: Maybe<RevokeReasonTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<RevokeReasonTypeScalarFieldEnum>>;
};

export type QueryAggregateRevokeReasonTypeArgs = {
  where?: Maybe<RevokeReasonTypeWhereInput>;
  orderBy?: Maybe<Array<RevokeReasonTypeOrderByWithRelationInput>>;
  cursor?: Maybe<RevokeReasonTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByRevokeReasonTypeArgs = {
  where?: Maybe<RevokeReasonTypeWhereInput>;
  orderBy?: Maybe<Array<RevokeReasonTypeOrderByWithAggregationInput>>;
  by: Array<RevokeReasonTypeScalarFieldEnum>;
  having?: Maybe<RevokeReasonTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySeatFrontTypeArgs = {
  where: SeatFrontTypeWhereUniqueInput;
};

export type QueryFindFirstSeatFrontTypeArgs = {
  where?: Maybe<SeatFrontTypeWhereInput>;
  orderBy?: Maybe<Array<SeatFrontTypeOrderByWithRelationInput>>;
  cursor?: Maybe<SeatFrontTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SeatFrontTypeScalarFieldEnum>>;
};

export type QuerySeatFrontTypesArgs = {
  where?: Maybe<SeatFrontTypeWhereInput>;
  orderBy?: Maybe<Array<SeatFrontTypeOrderByWithRelationInput>>;
  cursor?: Maybe<SeatFrontTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SeatFrontTypeScalarFieldEnum>>;
};

export type QueryAggregateSeatFrontTypeArgs = {
  where?: Maybe<SeatFrontTypeWhereInput>;
  orderBy?: Maybe<Array<SeatFrontTypeOrderByWithRelationInput>>;
  cursor?: Maybe<SeatFrontTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySeatFrontTypeArgs = {
  where?: Maybe<SeatFrontTypeWhereInput>;
  orderBy?: Maybe<Array<SeatFrontTypeOrderByWithAggregationInput>>;
  by: Array<SeatFrontTypeScalarFieldEnum>;
  having?: Maybe<SeatFrontTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySeatTypeArgs = {
  where: SeatTypeWhereUniqueInput;
};

export type QueryFindFirstSeatTypeArgs = {
  where?: Maybe<SeatTypeWhereInput>;
  orderBy?: Maybe<Array<SeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<SeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SeatTypeScalarFieldEnum>>;
};

export type QuerySeatTypesArgs = {
  where?: Maybe<SeatTypeWhereInput>;
  orderBy?: Maybe<Array<SeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<SeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SeatTypeScalarFieldEnum>>;
};

export type QueryAggregateSeatTypeArgs = {
  where?: Maybe<SeatTypeWhereInput>;
  orderBy?: Maybe<Array<SeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<SeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySeatTypeArgs = {
  where?: Maybe<SeatTypeWhereInput>;
  orderBy?: Maybe<Array<SeatTypeOrderByWithAggregationInput>>;
  by: Array<SeatTypeScalarFieldEnum>;
  having?: Maybe<SeatTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySharedVehicleGroupArgs = {
  where: SharedVehicleGroupWhereUniqueInput;
};

export type QueryFindFirstSharedVehicleGroupArgs = {
  where?: Maybe<SharedVehicleGroupWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupScalarFieldEnum>>;
};

export type QuerySharedVehicleGroupsArgs = {
  where?: Maybe<SharedVehicleGroupWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupScalarFieldEnum>>;
};

export type QueryAggregateSharedVehicleGroupArgs = {
  where?: Maybe<SharedVehicleGroupWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySharedVehicleGroupArgs = {
  where?: Maybe<SharedVehicleGroupWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupOrderByWithAggregationInput>>;
  by: Array<SharedVehicleGroupScalarFieldEnum>;
  having?: Maybe<SharedVehicleGroupScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySharedVehicleGroupInvitedEmailArgs = {
  where: SharedVehicleGroupInvitedEmailWhereUniqueInput;
};

export type QueryFindFirstSharedVehicleGroupInvitedEmailArgs = {
  where?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupInvitedEmailOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupInvitedEmailScalarFieldEnum>>;
};

export type QuerySharedVehicleGroupInvitedEmailsArgs = {
  where?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupInvitedEmailOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupInvitedEmailScalarFieldEnum>>;
};

export type QueryAggregateSharedVehicleGroupInvitedEmailArgs = {
  where?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupInvitedEmailOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySharedVehicleGroupInvitedEmailArgs = {
  where?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupInvitedEmailOrderByWithAggregationInput>
  >;
  by: Array<SharedVehicleGroupInvitedEmailScalarFieldEnum>;
  having?: Maybe<SharedVehicleGroupInvitedEmailScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySharedVehicleGroupRelAvailabilityArgs = {
  where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
};

export type QueryFindFirstSharedVehicleGroupRelAvailabilityArgs = {
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupRelAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelAvailabilityScalarFieldEnum>>;
};

export type QuerySharedVehicleGroupRelAvailabilitiesArgs = {
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupRelAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelAvailabilityScalarFieldEnum>>;
};

export type QueryAggregateSharedVehicleGroupRelAvailabilityArgs = {
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupRelAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySharedVehicleGroupRelAvailabilityArgs = {
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityOrderByWithAggregationInput>
  >;
  by: Array<SharedVehicleGroupRelAvailabilityScalarFieldEnum>;
  having?: Maybe<SharedVehicleGroupRelAvailabilityScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySharedVehicleGroupRelCompanyArgs = {
  where: SharedVehicleGroupRelCompanyWhereUniqueInput;
};

export type QueryFindFirstSharedVehicleGroupRelCompanyArgs = {
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelCompanyOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelCompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelCompanyScalarFieldEnum>>;
};

export type QuerySharedVehicleGroupRelCompaniesArgs = {
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelCompanyOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelCompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelCompanyScalarFieldEnum>>;
};

export type QueryAggregateSharedVehicleGroupRelCompanyArgs = {
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelCompanyOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelCompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySharedVehicleGroupRelCompanyArgs = {
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelCompanyOrderByWithAggregationInput>
  >;
  by: Array<SharedVehicleGroupRelCompanyScalarFieldEnum>;
  having?: Maybe<SharedVehicleGroupRelCompanyScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySharedVehicleGroupRelUserArgs = {
  where: SharedVehicleGroupRelUserWhereUniqueInput;
};

export type QueryFindFirstSharedVehicleGroupRelUserArgs = {
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelUserScalarFieldEnum>>;
};

export type QuerySharedVehicleGroupRelUsersArgs = {
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelUserScalarFieldEnum>>;
};

export type QueryAggregateSharedVehicleGroupRelUserArgs = {
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySharedVehicleGroupRelUserArgs = {
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelUserOrderByWithAggregationInput>>;
  by: Array<SharedVehicleGroupRelUserScalarFieldEnum>;
  having?: Maybe<SharedVehicleGroupRelUserScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySharedVehicleGroupRelVehicleArgs = {
  where: SharedVehicleGroupRelVehicleWhereUniqueInput;
};

export type QueryFindFirstSharedVehicleGroupRelVehicleArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelVehicleScalarFieldEnum>>;
};

export type QuerySharedVehicleGroupRelVehiclesArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelVehicleScalarFieldEnum>>;
};

export type QueryAggregateSharedVehicleGroupRelVehicleArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySharedVehicleGroupRelVehicleArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelVehicleOrderByWithAggregationInput>
  >;
  by: Array<SharedVehicleGroupRelVehicleScalarFieldEnum>;
  having?: Maybe<SharedVehicleGroupRelVehicleScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryShieldColorTypeArgs = {
  where: ShieldColorTypeWhereUniqueInput;
};

export type QueryFindFirstShieldColorTypeArgs = {
  where?: Maybe<ShieldColorTypeWhereInput>;
  orderBy?: Maybe<Array<ShieldColorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ShieldColorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ShieldColorTypeScalarFieldEnum>>;
};

export type QueryShieldColorTypesArgs = {
  where?: Maybe<ShieldColorTypeWhereInput>;
  orderBy?: Maybe<Array<ShieldColorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ShieldColorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ShieldColorTypeScalarFieldEnum>>;
};

export type QueryAggregateShieldColorTypeArgs = {
  where?: Maybe<ShieldColorTypeWhereInput>;
  orderBy?: Maybe<Array<ShieldColorTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ShieldColorTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByShieldColorTypeArgs = {
  where?: Maybe<ShieldColorTypeWhereInput>;
  orderBy?: Maybe<Array<ShieldColorTypeOrderByWithAggregationInput>>;
  by: Array<ShieldColorTypeScalarFieldEnum>;
  having?: Maybe<ShieldColorTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySmartcarAuthArgs = {
  where: SmartcarAuthWhereUniqueInput;
};

export type QueryFindFirstSmartcarAuthArgs = {
  where?: Maybe<SmartcarAuthWhereInput>;
  orderBy?: Maybe<Array<SmartcarAuthOrderByWithRelationInput>>;
  cursor?: Maybe<SmartcarAuthWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SmartcarAuthScalarFieldEnum>>;
};

export type QuerySmartcarAuthsArgs = {
  where?: Maybe<SmartcarAuthWhereInput>;
  orderBy?: Maybe<Array<SmartcarAuthOrderByWithRelationInput>>;
  cursor?: Maybe<SmartcarAuthWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SmartcarAuthScalarFieldEnum>>;
};

export type QueryAggregateSmartcarAuthArgs = {
  where?: Maybe<SmartcarAuthWhereInput>;
  orderBy?: Maybe<Array<SmartcarAuthOrderByWithRelationInput>>;
  cursor?: Maybe<SmartcarAuthWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupBySmartcarAuthArgs = {
  where?: Maybe<SmartcarAuthWhereInput>;
  orderBy?: Maybe<Array<SmartcarAuthOrderByWithAggregationInput>>;
  by: Array<SmartcarAuthScalarFieldEnum>;
  having?: Maybe<SmartcarAuthScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryTransmissionTypeArgs = {
  where: TransmissionTypeWhereUniqueInput;
};

export type QueryFindFirstTransmissionTypeArgs = {
  where?: Maybe<TransmissionTypeWhereInput>;
  orderBy?: Maybe<Array<TransmissionTypeOrderByWithRelationInput>>;
  cursor?: Maybe<TransmissionTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TransmissionTypeScalarFieldEnum>>;
};

export type QueryTransmissionTypesArgs = {
  where?: Maybe<TransmissionTypeWhereInput>;
  orderBy?: Maybe<Array<TransmissionTypeOrderByWithRelationInput>>;
  cursor?: Maybe<TransmissionTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TransmissionTypeScalarFieldEnum>>;
};

export type QueryAggregateTransmissionTypeArgs = {
  where?: Maybe<TransmissionTypeWhereInput>;
  orderBy?: Maybe<Array<TransmissionTypeOrderByWithRelationInput>>;
  cursor?: Maybe<TransmissionTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByTransmissionTypeArgs = {
  where?: Maybe<TransmissionTypeWhereInput>;
  orderBy?: Maybe<Array<TransmissionTypeOrderByWithAggregationInput>>;
  by: Array<TransmissionTypeScalarFieldEnum>;
  having?: Maybe<TransmissionTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryUnpublishReasonTypeArgs = {
  where: UnpublishReasonTypeWhereUniqueInput;
};

export type QueryFindFirstUnpublishReasonTypeArgs = {
  where?: Maybe<UnpublishReasonTypeWhereInput>;
  orderBy?: Maybe<Array<UnpublishReasonTypeOrderByWithRelationInput>>;
  cursor?: Maybe<UnpublishReasonTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UnpublishReasonTypeScalarFieldEnum>>;
};

export type QueryUnpublishReasonTypesArgs = {
  where?: Maybe<UnpublishReasonTypeWhereInput>;
  orderBy?: Maybe<Array<UnpublishReasonTypeOrderByWithRelationInput>>;
  cursor?: Maybe<UnpublishReasonTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UnpublishReasonTypeScalarFieldEnum>>;
};

export type QueryAggregateUnpublishReasonTypeArgs = {
  where?: Maybe<UnpublishReasonTypeWhereInput>;
  orderBy?: Maybe<Array<UnpublishReasonTypeOrderByWithRelationInput>>;
  cursor?: Maybe<UnpublishReasonTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByUnpublishReasonTypeArgs = {
  where?: Maybe<UnpublishReasonTypeWhereInput>;
  orderBy?: Maybe<Array<UnpublishReasonTypeOrderByWithAggregationInput>>;
  by: Array<UnpublishReasonTypeScalarFieldEnum>;
  having?: Maybe<UnpublishReasonTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryFindFirstUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type QueryAggregateUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithAggregationInput>>;
  by: Array<UserScalarFieldEnum>;
  having?: Maybe<UserScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryUserInvitedEmailArgs = {
  where: UserInvitedEmailWhereUniqueInput;
};

export type QueryFindFirstUserInvitedEmailArgs = {
  where?: Maybe<UserInvitedEmailWhereInput>;
  orderBy?: Maybe<Array<UserInvitedEmailOrderByWithRelationInput>>;
  cursor?: Maybe<UserInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserInvitedEmailScalarFieldEnum>>;
};

export type QueryUserInvitedEmailsArgs = {
  where?: Maybe<UserInvitedEmailWhereInput>;
  orderBy?: Maybe<Array<UserInvitedEmailOrderByWithRelationInput>>;
  cursor?: Maybe<UserInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserInvitedEmailScalarFieldEnum>>;
};

export type QueryAggregateUserInvitedEmailArgs = {
  where?: Maybe<UserInvitedEmailWhereInput>;
  orderBy?: Maybe<Array<UserInvitedEmailOrderByWithRelationInput>>;
  cursor?: Maybe<UserInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByUserInvitedEmailArgs = {
  where?: Maybe<UserInvitedEmailWhereInput>;
  orderBy?: Maybe<Array<UserInvitedEmailOrderByWithAggregationInput>>;
  by: Array<UserInvitedEmailScalarFieldEnum>;
  having?: Maybe<UserInvitedEmailScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleArgs = {
  where: VehicleWhereUniqueInput;
};

export type QueryFindFirstVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type QueryVehiclesArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type QueryAggregateVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithAggregationInput>>;
  by: Array<VehicleScalarFieldEnum>;
  having?: Maybe<VehicleScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleCloudBoxxLogArgs = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
};

export type QueryFindFirstVehicleCloudBoxxLogArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxLogScalarFieldEnum>>;
};

export type QueryVehicleCloudBoxxLogsArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxLogScalarFieldEnum>>;
};

export type QueryAggregateVehicleCloudBoxxLogArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleCloudBoxxLogArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxLogOrderByWithAggregationInput>>;
  by: Array<VehicleCloudBoxxLogScalarFieldEnum>;
  having?: Maybe<VehicleCloudBoxxLogScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleCloudBoxxStatusLogArgs = {
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
};

export type QueryFindFirstVehicleCloudBoxxStatusLogArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxStatusLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxStatusLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxStatusLogScalarFieldEnum>>;
};

export type QueryVehicleCloudBoxxStatusLogsArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxStatusLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxStatusLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxStatusLogScalarFieldEnum>>;
};

export type QueryAggregateVehicleCloudBoxxStatusLogArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxStatusLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxStatusLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleCloudBoxxStatusLogArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxStatusLogOrderByWithAggregationInput>>;
  by: Array<VehicleCloudBoxxStatusLogScalarFieldEnum>;
  having?: Maybe<VehicleCloudBoxxStatusLogScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleConnectionArgs = {
  where: VehicleConnectionWhereUniqueInput;
};

export type QueryFindFirstVehicleConnectionArgs = {
  where?: Maybe<VehicleConnectionWhereInput>;
  orderBy?: Maybe<Array<VehicleConnectionOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleConnectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleConnectionScalarFieldEnum>>;
};

export type QueryVehicleConnectionsArgs = {
  where?: Maybe<VehicleConnectionWhereInput>;
  orderBy?: Maybe<Array<VehicleConnectionOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleConnectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleConnectionScalarFieldEnum>>;
};

export type QueryAggregateVehicleConnectionArgs = {
  where?: Maybe<VehicleConnectionWhereInput>;
  orderBy?: Maybe<Array<VehicleConnectionOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleConnectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleConnectionArgs = {
  where?: Maybe<VehicleConnectionWhereInput>;
  orderBy?: Maybe<Array<VehicleConnectionOrderByWithAggregationInput>>;
  by: Array<VehicleConnectionScalarFieldEnum>;
  having?: Maybe<VehicleConnectionScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleDamageArgs = {
  where: VehicleDamageWhereUniqueInput;
};

export type QueryFindFirstVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageScalarFieldEnum>>;
};

export type QueryVehicleDamagesArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageScalarFieldEnum>>;
};

export type QueryAggregateVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithAggregationInput>>;
  by: Array<VehicleDamageScalarFieldEnum>;
  having?: Maybe<VehicleDamageScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleDamageCommentArgs = {
  where: VehicleDamageCommentWhereUniqueInput;
};

export type QueryFindFirstVehicleDamageCommentArgs = {
  where?: Maybe<VehicleDamageCommentWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageCommentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageCommentScalarFieldEnum>>;
};

export type QueryVehicleDamageCommentsArgs = {
  where?: Maybe<VehicleDamageCommentWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageCommentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageCommentScalarFieldEnum>>;
};

export type QueryAggregateVehicleDamageCommentArgs = {
  where?: Maybe<VehicleDamageCommentWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageCommentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleDamageCommentArgs = {
  where?: Maybe<VehicleDamageCommentWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageCommentOrderByWithAggregationInput>>;
  by: Array<VehicleDamageCommentScalarFieldEnum>;
  having?: Maybe<VehicleDamageCommentScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleEquipmentArgs = {
  where: VehicleEquipmentWhereUniqueInput;
};

export type QueryFindFirstVehicleEquipmentArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
  orderBy?: Maybe<Array<VehicleEquipmentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleEquipmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleEquipmentScalarFieldEnum>>;
};

export type QueryVehicleEquipmentsArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
  orderBy?: Maybe<Array<VehicleEquipmentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleEquipmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleEquipmentScalarFieldEnum>>;
};

export type QueryAggregateVehicleEquipmentArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
  orderBy?: Maybe<Array<VehicleEquipmentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleEquipmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleEquipmentArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
  orderBy?: Maybe<Array<VehicleEquipmentOrderByWithAggregationInput>>;
  by: Array<VehicleEquipmentScalarFieldEnum>;
  having?: Maybe<VehicleEquipmentScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleInsuranceDocumentArgs = {
  where: VehicleInsuranceDocumentWhereUniqueInput;
};

export type QueryFindFirstVehicleInsuranceDocumentArgs = {
  where?: Maybe<VehicleInsuranceDocumentWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceDocumentScalarFieldEnum>>;
};

export type QueryVehicleInsuranceDocumentsArgs = {
  where?: Maybe<VehicleInsuranceDocumentWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceDocumentScalarFieldEnum>>;
};

export type QueryAggregateVehicleInsuranceDocumentArgs = {
  where?: Maybe<VehicleInsuranceDocumentWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleInsuranceDocumentArgs = {
  where?: Maybe<VehicleInsuranceDocumentWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceDocumentOrderByWithAggregationInput>>;
  by: Array<VehicleInsuranceDocumentScalarFieldEnum>;
  having?: Maybe<VehicleInsuranceDocumentScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleInsuranceRuleArgs = {
  where: VehicleInsuranceRuleWhereUniqueInput;
};

export type QueryFindFirstVehicleInsuranceRuleArgs = {
  where?: Maybe<VehicleInsuranceRuleWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceRuleScalarFieldEnum>>;
};

export type QueryVehicleInsuranceRulesArgs = {
  where?: Maybe<VehicleInsuranceRuleWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceRuleScalarFieldEnum>>;
};

export type QueryAggregateVehicleInsuranceRuleArgs = {
  where?: Maybe<VehicleInsuranceRuleWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleInsuranceRuleArgs = {
  where?: Maybe<VehicleInsuranceRuleWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleOrderByWithAggregationInput>>;
  by: Array<VehicleInsuranceRuleScalarFieldEnum>;
  having?: Maybe<VehicleInsuranceRuleScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleInsuranceRuleSetArgs = {
  where: VehicleInsuranceRuleSetWhereUniqueInput;
};

export type QueryFindFirstVehicleInsuranceRuleSetArgs = {
  where?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceRuleSetScalarFieldEnum>>;
};

export type QueryVehicleInsuranceRuleSetsArgs = {
  where?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceRuleSetScalarFieldEnum>>;
};

export type QueryAggregateVehicleInsuranceRuleSetArgs = {
  where?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleInsuranceRuleSetArgs = {
  where?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleSetOrderByWithAggregationInput>>;
  by: Array<VehicleInsuranceRuleSetScalarFieldEnum>;
  having?: Maybe<VehicleInsuranceRuleSetScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleInsuranceTypeArgs = {
  where: VehicleInsuranceTypeWhereUniqueInput;
};

export type QueryFindFirstVehicleInsuranceTypeArgs = {
  where?: Maybe<VehicleInsuranceTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceTypeScalarFieldEnum>>;
};

export type QueryVehicleInsuranceTypesArgs = {
  where?: Maybe<VehicleInsuranceTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceTypeScalarFieldEnum>>;
};

export type QueryAggregateVehicleInsuranceTypeArgs = {
  where?: Maybe<VehicleInsuranceTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleInsuranceTypeArgs = {
  where?: Maybe<VehicleInsuranceTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceTypeOrderByWithAggregationInput>>;
  by: Array<VehicleInsuranceTypeScalarFieldEnum>;
  having?: Maybe<VehicleInsuranceTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleKindTypeArgs = {
  where: VehicleKindTypeWhereUniqueInput;
};

export type QueryFindFirstVehicleKindTypeArgs = {
  where?: Maybe<VehicleKindTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleKindTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleKindTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleKindTypeScalarFieldEnum>>;
};

export type QueryVehicleKindTypesArgs = {
  where?: Maybe<VehicleKindTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleKindTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleKindTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleKindTypeScalarFieldEnum>>;
};

export type QueryAggregateVehicleKindTypeArgs = {
  where?: Maybe<VehicleKindTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleKindTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleKindTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleKindTypeArgs = {
  where?: Maybe<VehicleKindTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleKindTypeOrderByWithAggregationInput>>;
  by: Array<VehicleKindTypeScalarFieldEnum>;
  having?: Maybe<VehicleKindTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleLocationArgs = {
  where: VehicleLocationWhereUniqueInput;
};

export type QueryFindFirstVehicleLocationArgs = {
  where?: Maybe<VehicleLocationWhereInput>;
  orderBy?: Maybe<Array<VehicleLocationOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleLocationScalarFieldEnum>>;
};

export type QueryVehicleLocationsArgs = {
  where?: Maybe<VehicleLocationWhereInput>;
  orderBy?: Maybe<Array<VehicleLocationOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleLocationScalarFieldEnum>>;
};

export type QueryAggregateVehicleLocationArgs = {
  where?: Maybe<VehicleLocationWhereInput>;
  orderBy?: Maybe<Array<VehicleLocationOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleLocationArgs = {
  where?: Maybe<VehicleLocationWhereInput>;
  orderBy?: Maybe<Array<VehicleLocationOrderByWithAggregationInput>>;
  by: Array<VehicleLocationScalarFieldEnum>;
  having?: Maybe<VehicleLocationScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehiclePhotoArgs = {
  where: VehiclePhotoWhereUniqueInput;
};

export type QueryFindFirstVehiclePhotoArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
  orderBy?: Maybe<Array<VehiclePhotoOrderByWithRelationInput>>;
  cursor?: Maybe<VehiclePhotoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehiclePhotoScalarFieldEnum>>;
};

export type QueryVehiclePhotosArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
  orderBy?: Maybe<Array<VehiclePhotoOrderByWithRelationInput>>;
  cursor?: Maybe<VehiclePhotoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehiclePhotoScalarFieldEnum>>;
};

export type QueryAggregateVehiclePhotoArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
  orderBy?: Maybe<Array<VehiclePhotoOrderByWithRelationInput>>;
  cursor?: Maybe<VehiclePhotoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehiclePhotoArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
  orderBy?: Maybe<Array<VehiclePhotoOrderByWithAggregationInput>>;
  by: Array<VehiclePhotoScalarFieldEnum>;
  having?: Maybe<VehiclePhotoScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleTypeArgs = {
  where: VehicleTypeWhereUniqueInput;
};

export type QueryFindFirstVehicleTypeArgs = {
  where?: Maybe<VehicleTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleTypeScalarFieldEnum>>;
};

export type QueryVehicleTypesArgs = {
  where?: Maybe<VehicleTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleTypeScalarFieldEnum>>;
};

export type QueryAggregateVehicleTypeArgs = {
  where?: Maybe<VehicleTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleTypeOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryGroupByVehicleTypeArgs = {
  where?: Maybe<VehicleTypeWhereInput>;
  orderBy?: Maybe<Array<VehicleTypeOrderByWithAggregationInput>>;
  by: Array<VehicleTypeScalarFieldEnum>;
  having?: Maybe<VehicleTypeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryVehicleAvailabilityArgs = {
  vehicleId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type QueryGetAffectedBookingsArgs = {
  vehicleId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type QueryGetBookingsForVehicleByDateRangeArgs = {
  vehicleId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type QueryGetBookingCalculationArgs = {
  bookingId: Scalars['String'];
};

export type QueryGetBookingDetailArgs = {
  bookingId: Scalars['String'];
};

export type QueryGetCancellationFeesArgs = {
  bookingId: Scalars['String'];
};

export type QueryChargingStationRfidInvisiaArgs = {
  where: ChargingStationRfidInvisiaWhereUniqueInput;
};

export type QueryChargingStationRfidInvisiasArgs = {
  where: ChargingStationRfidInvisiaWhereInput;
};

export type QueryAggregateChargingStationRfidInvisiaArgs = {
  where: ChargingStationRfidInvisiaWhereInput;
};

export type QueryGetTransactionCalculationArgs = {
  transactionId: Scalars['String'];
};

export type QueryCloudBoxxStatusLogsArgs = {
  vehicleId: Scalars['String'];
};

export type QueryGetVehicleStatusArgs = {
  vehicleId: Scalars['String'];
};

export type QueryGetAvailableVehiclesArgs = {
  userId?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type QueryGetBookingCheckAvailabilityArgs = {
  userId: Scalars['String'];
  bookingId: Scalars['String'];
  startDateTime: Scalars['String'];
  endDateTime: Scalars['String'];
};

export type QueryVehicleReportArgs = {
  page?: Maybe<Scalars['Float']>;
  perPage?: Maybe<Scalars['Float']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  where?: Maybe<VehicleReportWhereInput>;
};

export type QueryVehicleReportsArgs = {
  page?: Maybe<Scalars['Float']>;
  perPage?: Maybe<Scalars['Float']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  where?: Maybe<VehicleReportWhereInput>;
};

export type QueryAggregateVehicleReportArgs = {
  page?: Maybe<Scalars['Float']>;
  perPage?: Maybe<Scalars['Float']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  where?: Maybe<VehicleReportWhereInput>;
};

export type QueryUpdateVehicleArgs = {
  data: VehicleUpdateInput;
  where: VehicleWhereUniqueInput;
};

export type QueryCreateVehicleArgs = {
  data: VehicleCreateInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type RevokeReasonType = {
  __typename?: 'RevokeReasonType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RevokeReasonTypeCountAggregate = {
  __typename?: 'RevokeReasonTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type RevokeReasonTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type RevokeReasonTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type RevokeReasonTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type RevokeReasonTypeGroupBy = {
  __typename?: 'RevokeReasonTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  _count?: Maybe<RevokeReasonTypeCountAggregate>;
  _min?: Maybe<RevokeReasonTypeMinAggregate>;
  _max?: Maybe<RevokeReasonTypeMaxAggregate>;
};

export type RevokeReasonTypeMaxAggregate = {
  __typename?: 'RevokeReasonTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RevokeReasonTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type RevokeReasonTypeMinAggregate = {
  __typename?: 'RevokeReasonTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RevokeReasonTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type RevokeReasonTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<RevokeReasonTypeCountOrderByAggregateInput>;
  _max?: Maybe<RevokeReasonTypeMaxOrderByAggregateInput>;
  _min?: Maybe<RevokeReasonTypeMinOrderByAggregateInput>;
};

export type RevokeReasonTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export enum RevokeReasonTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
}

export type RevokeReasonTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<RevokeReasonTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<RevokeReasonTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<RevokeReasonTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type RevokeReasonTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type RevokeReasonTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type RevokeReasonTypeWhereInput = {
  AND?: Maybe<Array<RevokeReasonTypeWhereInput>>;
  OR?: Maybe<Array<RevokeReasonTypeWhereInput>>;
  NOT?: Maybe<Array<RevokeReasonTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
};

export type RevokeReasonTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum RuleCompareOperator {
  EqualTo = 'EQUAL_TO',
  NotEqualTo = 'NOT_EQUAL_TO',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqualTo = 'GREATER_THAN_EQUAL_TO',
  LessThan = 'LESS_THAN',
  LessThanEqualTo = 'LESS_THAN_EQUAL_TO',
}

export enum RuleProperty {
  AgeVehicle = 'AGE_VEHICLE',
  AgeUser = 'AGE_USER',
  CatalogPrice = 'CATALOG_PRICE',
  BookingDuration = 'BOOKING_DURATION',
}

export type SeatFrontType = {
  __typename?: 'SeatFrontType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<SeatFrontTypeCount>;
  vehicle: Array<Vehicle>;
};

export type SeatFrontTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type SeatFrontTypeCount = {
  __typename?: 'SeatFrontTypeCount';
  vehicle: Scalars['Int'];
};

export type SeatFrontTypeCountAggregate = {
  __typename?: 'SeatFrontTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SeatFrontTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type SeatFrontTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutSeatFrontTypeInput>;
};

export type SeatFrontTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type SeatFrontTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<SeatFrontTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<SeatFrontTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<SeatFrontTypeWhereUniqueInput>;
};

export type SeatFrontTypeCreateOrConnectWithoutVehicleInput = {
  where: SeatFrontTypeWhereUniqueInput;
  create: SeatFrontTypeCreateWithoutVehicleInput;
};

export type SeatFrontTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type SeatFrontTypeGroupBy = {
  __typename?: 'SeatFrontTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<SeatFrontTypeCountAggregate>;
  _min?: Maybe<SeatFrontTypeMinAggregate>;
  _max?: Maybe<SeatFrontTypeMaxAggregate>;
};

export type SeatFrontTypeMaxAggregate = {
  __typename?: 'SeatFrontTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type SeatFrontTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type SeatFrontTypeMinAggregate = {
  __typename?: 'SeatFrontTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type SeatFrontTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type SeatFrontTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<SeatFrontTypeCountOrderByAggregateInput>;
  _max?: Maybe<SeatFrontTypeMaxOrderByAggregateInput>;
  _min?: Maybe<SeatFrontTypeMinOrderByAggregateInput>;
};

export type SeatFrontTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type SeatFrontTypeRelationFilter = {
  is?: Maybe<SeatFrontTypeWhereInput>;
  isNot?: Maybe<SeatFrontTypeWhereInput>;
};

export enum SeatFrontTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type SeatFrontTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SeatFrontTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SeatFrontTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SeatFrontTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SeatFrontTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutSeatFrontTypeInput>;
};

export type SeatFrontTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SeatFrontTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<SeatFrontTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<SeatFrontTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<SeatFrontTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SeatFrontTypeWhereUniqueInput>;
  update?: Maybe<SeatFrontTypeUpdateWithoutVehicleInput>;
};

export type SeatFrontTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SeatFrontTypeUpsertWithoutVehicleInput = {
  update: SeatFrontTypeUpdateWithoutVehicleInput;
  create: SeatFrontTypeCreateWithoutVehicleInput;
};

export type SeatFrontTypeWhereInput = {
  AND?: Maybe<Array<SeatFrontTypeWhereInput>>;
  OR?: Maybe<Array<SeatFrontTypeWhereInput>>;
  NOT?: Maybe<Array<SeatFrontTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type SeatFrontTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SeatType = {
  __typename?: 'SeatType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<SeatTypeCount>;
  vehicle: Array<Vehicle>;
};

export type SeatTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type SeatTypeCount = {
  __typename?: 'SeatTypeCount';
  vehicle: Scalars['Int'];
};

export type SeatTypeCountAggregate = {
  __typename?: 'SeatTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SeatTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type SeatTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutSeatTypeInput>;
};

export type SeatTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type SeatTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<SeatTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<SeatTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<SeatTypeWhereUniqueInput>;
};

export type SeatTypeCreateOrConnectWithoutVehicleInput = {
  where: SeatTypeWhereUniqueInput;
  create: SeatTypeCreateWithoutVehicleInput;
};

export type SeatTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type SeatTypeGroupBy = {
  __typename?: 'SeatTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<SeatTypeCountAggregate>;
  _min?: Maybe<SeatTypeMinAggregate>;
  _max?: Maybe<SeatTypeMaxAggregate>;
};

export type SeatTypeMaxAggregate = {
  __typename?: 'SeatTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type SeatTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type SeatTypeMinAggregate = {
  __typename?: 'SeatTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type SeatTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type SeatTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<SeatTypeCountOrderByAggregateInput>;
  _max?: Maybe<SeatTypeMaxOrderByAggregateInput>;
  _min?: Maybe<SeatTypeMinOrderByAggregateInput>;
};

export type SeatTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type SeatTypeRelationFilter = {
  is?: Maybe<SeatTypeWhereInput>;
  isNot?: Maybe<SeatTypeWhereInput>;
};

export enum SeatTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type SeatTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SeatTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SeatTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SeatTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SeatTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutSeatTypeInput>;
};

export type SeatTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SeatTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<SeatTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<SeatTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<SeatTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SeatTypeWhereUniqueInput>;
  update?: Maybe<SeatTypeUpdateWithoutVehicleInput>;
};

export type SeatTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SeatTypeUpsertWithoutVehicleInput = {
  update: SeatTypeUpdateWithoutVehicleInput;
  create: SeatTypeCreateWithoutVehicleInput;
};

export type SeatTypeWhereInput = {
  AND?: Maybe<Array<SeatTypeWhereInput>>;
  OR?: Maybe<Array<SeatTypeWhereInput>>;
  NOT?: Maybe<Array<SeatTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type SeatTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum SendMessageEnum {
  Notification = 'NOTIFICATION',
  Sms = 'SMS',
}

export type SharedVehicleGroup = {
  __typename?: 'SharedVehicleGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior: Scalars['Boolean'];
  discountIncludesInsurance: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  createdById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  _count?: Maybe<SharedVehicleGroupCount>;
  createdBy?: Maybe<User>;
  vehicles: Array<SharedVehicleGroupRelVehicle>;
  companies: Array<SharedVehicleGroupRelCompany>;
  users: Array<SharedVehicleGroupRelUser>;
  sharedVehicleGroupInvitedEmail: Array<SharedVehicleGroupInvitedEmail>;
  sharedVehicleGroupRelAvailability: Array<SharedVehicleGroupRelAvailability>;
  defaultCompany: Array<Company>;
  bookings: Array<Booking>;
};

export type SharedVehicleGroupVehiclesArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelVehicleScalarFieldEnum>>;
};

export type SharedVehicleGroupCompaniesArgs = {
  where?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelCompanyOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelCompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelCompanyScalarFieldEnum>>;
};

export type SharedVehicleGroupUsersArgs = {
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelUserScalarFieldEnum>>;
};

export type SharedVehicleGroupSharedVehicleGroupInvitedEmailArgs = {
  where?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupInvitedEmailOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupInvitedEmailWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupInvitedEmailScalarFieldEnum>>;
};

export type SharedVehicleGroupSharedVehicleGroupRelAvailabilityArgs = {
  where?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  orderBy?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityOrderByWithRelationInput>
  >;
  cursor?: Maybe<SharedVehicleGroupRelAvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelAvailabilityScalarFieldEnum>>;
};

export type SharedVehicleGroupDefaultCompanyArgs = {
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<CompanyOrderByWithRelationInput>>;
  cursor?: Maybe<CompanyWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CompanyScalarFieldEnum>>;
};

export type SharedVehicleGroupBookingsArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type SharedVehicleGroupAvgAggregate = {
  __typename?: 'SharedVehicleGroupAvgAggregate';
  discount?: Maybe<Scalars['Float']>;
};

export type SharedVehicleGroupAvgOrderByAggregateInput = {
  discount?: Maybe<SortOrder>;
};

export type SharedVehicleGroupCount = {
  __typename?: 'SharedVehicleGroupCount';
  vehicles: Scalars['Int'];
  companies: Scalars['Int'];
  users: Scalars['Int'];
  sharedVehicleGroupInvitedEmail: Scalars['Int'];
  sharedVehicleGroupRelAvailability: Scalars['Int'];
  defaultCompany: Scalars['Int'];
  bookings: Scalars['Int'];
};

export type SharedVehicleGroupCountAggregate = {
  __typename?: 'SharedVehicleGroupCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  discount: Scalars['Int'];
  ownerBehavior: Scalars['Int'];
  discountIncludesInsurance: Scalars['Int'];
  isPrivate: Scalars['Int'];
  createdById: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SharedVehicleGroupCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  ownerBehavior?: Maybe<SortOrder>;
  discountIncludesInsurance?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  createdById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type SharedVehicleGroupCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupCreateManyCreatedByInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupCreateManyCreatedByInputEnvelope = {
  data: Array<SharedVehicleGroupCreateManyCreatedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SharedVehicleGroupCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupCreateNestedManyWithoutCreatedByInput = {
  create?: Maybe<Array<SharedVehicleGroupCreateWithoutCreatedByInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupCreateOrConnectWithoutCreatedByInput>
  >;
  createMany?: Maybe<SharedVehicleGroupCreateManyCreatedByInputEnvelope>;
  connect?: Maybe<Array<SharedVehicleGroupWhereUniqueInput>>;
};

export type SharedVehicleGroupCreateNestedOneWithoutBookingsInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutBookingsInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutBookingsInput>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
};

export type SharedVehicleGroupCreateNestedOneWithoutCompaniesInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutCompaniesInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutCompaniesInput>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
};

export type SharedVehicleGroupCreateNestedOneWithoutDefaultCompanyInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutDefaultCompanyInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutDefaultCompanyInput>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
};

export type SharedVehicleGroupCreateNestedOneWithoutSharedVehicleGroupInvitedEmailInput =
  {
    create?: Maybe<SharedVehicleGroupCreateWithoutSharedVehicleGroupInvitedEmailInput>;
    connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutSharedVehicleGroupInvitedEmailInput>;
    connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  };

export type SharedVehicleGroupCreateNestedOneWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    create?: Maybe<SharedVehicleGroupCreateWithoutSharedVehicleGroupRelAvailabilityInput>;
    connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutSharedVehicleGroupRelAvailabilityInput>;
    connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  };

export type SharedVehicleGroupCreateNestedOneWithoutUsersInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutUsersInput>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
};

export type SharedVehicleGroupCreateNestedOneWithoutVehiclesInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutVehiclesInput>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
};

export type SharedVehicleGroupCreateOrConnectWithoutBookingsInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  create: SharedVehicleGroupCreateWithoutBookingsInput;
};

export type SharedVehicleGroupCreateOrConnectWithoutCompaniesInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  create: SharedVehicleGroupCreateWithoutCompaniesInput;
};

export type SharedVehicleGroupCreateOrConnectWithoutCreatedByInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  create: SharedVehicleGroupCreateWithoutCreatedByInput;
};

export type SharedVehicleGroupCreateOrConnectWithoutDefaultCompanyInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  create: SharedVehicleGroupCreateWithoutDefaultCompanyInput;
};

export type SharedVehicleGroupCreateOrConnectWithoutSharedVehicleGroupInvitedEmailInput =
  {
    where: SharedVehicleGroupWhereUniqueInput;
    create: SharedVehicleGroupCreateWithoutSharedVehicleGroupInvitedEmailInput;
  };

export type SharedVehicleGroupCreateOrConnectWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    where: SharedVehicleGroupWhereUniqueInput;
    create: SharedVehicleGroupCreateWithoutSharedVehicleGroupRelAvailabilityInput;
  };

export type SharedVehicleGroupCreateOrConnectWithoutUsersInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  create: SharedVehicleGroupCreateWithoutUsersInput;
};

export type SharedVehicleGroupCreateOrConnectWithoutVehiclesInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  create: SharedVehicleGroupCreateWithoutVehiclesInput;
};

export type SharedVehicleGroupCreateWithoutBookingsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
};

export type SharedVehicleGroupCreateWithoutCompaniesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupCreateWithoutCreatedByInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupCreateWithoutDefaultCompanyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupCreateWithoutSharedVehicleGroupInvitedEmailInput =
  {
    id?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    discount: Scalars['Int'];
    ownerBehavior?: Maybe<Scalars['Boolean']>;
    discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
    isPrivate?: Maybe<Scalars['Boolean']>;
    createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
    createdAt?: Maybe<Scalars['DateTime']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
    companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
    users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
    sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
    defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
    bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
  };

export type SharedVehicleGroupCreateWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    id?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    discount: Scalars['Int'];
    ownerBehavior?: Maybe<Scalars['Boolean']>;
    discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
    isPrivate?: Maybe<Scalars['Boolean']>;
    createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
    createdAt?: Maybe<Scalars['DateTime']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
    companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
    users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
    sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
    defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
    bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
  };

export type SharedVehicleGroupCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupCreateWithoutVehiclesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  companies?: Maybe<SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyCreateNestedManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupGroupBy = {
  __typename?: 'SharedVehicleGroupGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  discount: Scalars['Int'];
  ownerBehavior: Scalars['Boolean'];
  discountIncludesInsurance: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  createdById?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  _count?: Maybe<SharedVehicleGroupCountAggregate>;
  _avg?: Maybe<SharedVehicleGroupAvgAggregate>;
  _sum?: Maybe<SharedVehicleGroupSumAggregate>;
  _min?: Maybe<SharedVehicleGroupMinAggregate>;
  _max?: Maybe<SharedVehicleGroupMaxAggregate>;
};

export type SharedVehicleGroupInvitedEmail = {
  __typename?: 'SharedVehicleGroupInvitedEmail';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  sharedVehicleGroup?: Maybe<SharedVehicleGroup>;
};

export type SharedVehicleGroupInvitedEmailCountAggregate = {
  __typename?: 'SharedVehicleGroupInvitedEmailCountAggregate';
  id: Scalars['Int'];
  sharedVehicleGroupId: Scalars['Int'];
  email: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SharedVehicleGroupInvitedEmailCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type SharedVehicleGroupInvitedEmailCreateInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutSharedVehicleGroupInvitedEmailInput>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupInvitedEmailCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupInvitedEmailCreateManySharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupInvitedEmailCreateManySharedVehicleGroupInputEnvelope =
  {
    data: Array<SharedVehicleGroupInvitedEmailCreateManySharedVehicleGroupInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type SharedVehicleGroupInvitedEmailCreateNestedManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupInvitedEmailCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupInvitedEmailCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupInvitedEmailCreateManySharedVehicleGroupInputEnvelope>;
    connect?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereUniqueInput>>;
  };

export type SharedVehicleGroupInvitedEmailCreateOrConnectWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupInvitedEmailWhereUniqueInput;
    create: SharedVehicleGroupInvitedEmailCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupInvitedEmailCreateWithoutSharedVehicleGroupInput =
  {
    id?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
  };

export type SharedVehicleGroupInvitedEmailGroupBy = {
  __typename?: 'SharedVehicleGroupInvitedEmailGroupBy';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  _count?: Maybe<SharedVehicleGroupInvitedEmailCountAggregate>;
  _min?: Maybe<SharedVehicleGroupInvitedEmailMinAggregate>;
  _max?: Maybe<SharedVehicleGroupInvitedEmailMaxAggregate>;
};

export type SharedVehicleGroupInvitedEmailListRelationFilter = {
  every?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
  some?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
  none?: Maybe<SharedVehicleGroupInvitedEmailWhereInput>;
};

export type SharedVehicleGroupInvitedEmailMaxAggregate = {
  __typename?: 'SharedVehicleGroupInvitedEmailMaxAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupInvitedEmailMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type SharedVehicleGroupInvitedEmailMinAggregate = {
  __typename?: 'SharedVehicleGroupInvitedEmailMinAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupInvitedEmailMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type SharedVehicleGroupInvitedEmailOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SharedVehicleGroupInvitedEmailOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<SharedVehicleGroupInvitedEmailCountOrderByAggregateInput>;
  _max?: Maybe<SharedVehicleGroupInvitedEmailMaxOrderByAggregateInput>;
  _min?: Maybe<SharedVehicleGroupInvitedEmailMinOrderByAggregateInput>;
};

export type SharedVehicleGroupInvitedEmailOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByWithRelationInput>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum SharedVehicleGroupInvitedEmailScalarFieldEnum {
  Id = 'id',
  SharedVehicleGroupId = 'sharedVehicleGroupId',
  Email = 'email',
  CreatedAt = 'createdAt',
}

export type SharedVehicleGroupInvitedEmailScalarWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupInvitedEmailScalarWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupInvitedEmailScalarWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupInvitedEmailScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type SharedVehicleGroupInvitedEmailScalarWhereWithAggregatesInput = {
  AND?: Maybe<
    Array<SharedVehicleGroupInvitedEmailScalarWhereWithAggregatesInput>
  >;
  OR?: Maybe<
    Array<SharedVehicleGroupInvitedEmailScalarWhereWithAggregatesInput>
  >;
  NOT?: Maybe<
    Array<SharedVehicleGroupInvitedEmailScalarWhereWithAggregatesInput>
  >;
  id?: Maybe<StringWithAggregatesFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SharedVehicleGroupInvitedEmailUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutSharedVehicleGroupInvitedEmailInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SharedVehicleGroupInvitedEmailUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SharedVehicleGroupInvitedEmailUpdateManyWithWhereWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupInvitedEmailScalarWhereInput;
    data: SharedVehicleGroupInvitedEmailUpdateManyMutationInput;
  };

export type SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupInvitedEmailCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupInvitedEmailCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    upsert?: Maybe<
      Array<SharedVehicleGroupInvitedEmailUpsertWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupInvitedEmailCreateManySharedVehicleGroupInputEnvelope>;
    set?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereUniqueInput>>;
    disconnect?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereUniqueInput>>;
    delete?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereUniqueInput>>;
    connect?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereUniqueInput>>;
    update?: Maybe<
      Array<SharedVehicleGroupInvitedEmailUpdateWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    updateMany?: Maybe<
      Array<SharedVehicleGroupInvitedEmailUpdateManyWithWhereWithoutSharedVehicleGroupInput>
    >;
    deleteMany?: Maybe<Array<SharedVehicleGroupInvitedEmailScalarWhereInput>>;
  };

export type SharedVehicleGroupInvitedEmailUpdateWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupInvitedEmailWhereUniqueInput;
    data: SharedVehicleGroupInvitedEmailUpdateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupInvitedEmailUpdateWithoutSharedVehicleGroupInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    email?: Maybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  };

export type SharedVehicleGroupInvitedEmailUpsertWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupInvitedEmailWhereUniqueInput;
    update: SharedVehicleGroupInvitedEmailUpdateWithoutSharedVehicleGroupInput;
    create: SharedVehicleGroupInvitedEmailCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupInvitedEmailWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupInvitedEmailWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupRelationFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type SharedVehicleGroupInvitedEmailWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupListRelationFilter = {
  every?: Maybe<SharedVehicleGroupWhereInput>;
  some?: Maybe<SharedVehicleGroupWhereInput>;
  none?: Maybe<SharedVehicleGroupWhereInput>;
};

export type SharedVehicleGroupMaxAggregate = {
  __typename?: 'SharedVehicleGroupMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  ownerBehavior?: Maybe<SortOrder>;
  discountIncludesInsurance?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  createdById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type SharedVehicleGroupMinAggregate = {
  __typename?: 'SharedVehicleGroupMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  ownerBehavior?: Maybe<Scalars['Boolean']>;
  discountIncludesInsurance?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdById?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type SharedVehicleGroupMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  ownerBehavior?: Maybe<SortOrder>;
  discountIncludesInsurance?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  createdById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type SharedVehicleGroupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SharedVehicleGroupOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  ownerBehavior?: Maybe<SortOrder>;
  discountIncludesInsurance?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  createdById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  _count?: Maybe<SharedVehicleGroupCountOrderByAggregateInput>;
  _avg?: Maybe<SharedVehicleGroupAvgOrderByAggregateInput>;
  _max?: Maybe<SharedVehicleGroupMaxOrderByAggregateInput>;
  _min?: Maybe<SharedVehicleGroupMinOrderByAggregateInput>;
  _sum?: Maybe<SharedVehicleGroupSumOrderByAggregateInput>;
};

export type SharedVehicleGroupOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  ownerBehavior?: Maybe<SortOrder>;
  discountIncludesInsurance?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  createdBy?: Maybe<UserOrderByWithRelationInput>;
  createdById?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleOrderByRelationAggregateInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyOrderByRelationAggregateInput>;
  users?: Maybe<SharedVehicleGroupRelUserOrderByRelationAggregateInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailOrderByRelationAggregateInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityOrderByRelationAggregateInput>;
  defaultCompany?: Maybe<CompanyOrderByRelationAggregateInput>;
  bookings?: Maybe<BookingOrderByRelationAggregateInput>;
};

export type SharedVehicleGroupRelAvailability = {
  __typename?: 'SharedVehicleGroupRelAvailability';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  availability?: Maybe<Availability>;
};

export type SharedVehicleGroupRelAvailabilityCountAggregate = {
  __typename?: 'SharedVehicleGroupRelAvailabilityCountAggregate';
  id: Scalars['Int'];
  sharedVehicleGroupId: Scalars['Int'];
  availabilityId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SharedVehicleGroupRelAvailabilityCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelAvailabilityCreateInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutSharedVehicleGroupRelAvailabilityInput>;
  availability?: Maybe<AvailabilityCreateNestedOneWithoutSharedVehicleGroupRelAvailabilityInput>;
};

export type SharedVehicleGroupRelAvailabilityCreateManyAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelAvailabilityCreateManyAvailabilityInputEnvelope =
  {
    data: Array<SharedVehicleGroupRelAvailabilityCreateManyAvailabilityInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type SharedVehicleGroupRelAvailabilityCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelAvailabilityCreateManySharedVehicleGroupInput =
  {
    id?: Maybe<Scalars['String']>;
    availabilityId?: Maybe<Scalars['String']>;
  };

export type SharedVehicleGroupRelAvailabilityCreateManySharedVehicleGroupInputEnvelope =
  {
    data: Array<SharedVehicleGroupRelAvailabilityCreateManySharedVehicleGroupInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutAvailabilityInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateWithoutAvailabilityInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateOrConnectWithoutAvailabilityInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelAvailabilityCreateManyAvailabilityInputEnvelope>;
    connect?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
  };

export type SharedVehicleGroupRelAvailabilityCreateNestedManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelAvailabilityCreateManySharedVehicleGroupInputEnvelope>;
    connect?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
  };

export type SharedVehicleGroupRelAvailabilityCreateOrConnectWithoutAvailabilityInput =
  {
    where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
    create: SharedVehicleGroupRelAvailabilityCreateWithoutAvailabilityInput;
  };

export type SharedVehicleGroupRelAvailabilityCreateOrConnectWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
    create: SharedVehicleGroupRelAvailabilityCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelAvailabilityCreateWithoutAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutSharedVehicleGroupRelAvailabilityInput>;
};

export type SharedVehicleGroupRelAvailabilityCreateWithoutSharedVehicleGroupInput =
  {
    id?: Maybe<Scalars['String']>;
    availability?: Maybe<AvailabilityCreateNestedOneWithoutSharedVehicleGroupRelAvailabilityInput>;
  };

export type SharedVehicleGroupRelAvailabilityGroupBy = {
  __typename?: 'SharedVehicleGroupRelAvailabilityGroupBy';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
  _count?: Maybe<SharedVehicleGroupRelAvailabilityCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelAvailabilityMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelAvailabilityMaxAggregate>;
};

export type SharedVehicleGroupRelAvailabilityListRelationFilter = {
  every?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  some?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
  none?: Maybe<SharedVehicleGroupRelAvailabilityWhereInput>;
};

export type SharedVehicleGroupRelAvailabilityMaxAggregate = {
  __typename?: 'SharedVehicleGroupRelAvailabilityMaxAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelAvailabilityMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelAvailabilityMinAggregate = {
  __typename?: 'SharedVehicleGroupRelAvailabilityMinAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelAvailabilityMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelAvailabilityOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  availabilityId?: Maybe<SortOrder>;
  _count?: Maybe<SharedVehicleGroupRelAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<SharedVehicleGroupRelAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<SharedVehicleGroupRelAvailabilityMinOrderByAggregateInput>;
};

export type SharedVehicleGroupRelAvailabilityOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByWithRelationInput>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  availability?: Maybe<AvailabilityOrderByWithRelationInput>;
  availabilityId?: Maybe<SortOrder>;
};

export enum SharedVehicleGroupRelAvailabilityScalarFieldEnum {
  Id = 'id',
  SharedVehicleGroupId = 'sharedVehicleGroupId',
  AvailabilityId = 'availabilityId',
}

export type SharedVehicleGroupRelAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelAvailabilityScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  availabilityId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityScalarWhereWithAggregatesInput>
  >;
  OR?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityScalarWhereWithAggregatesInput>
  >;
  NOT?: Maybe<
    Array<SharedVehicleGroupRelAvailabilityScalarWhereWithAggregatesInput>
  >;
  id?: Maybe<StringWithAggregatesFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  availabilityId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SharedVehicleGroupRelAvailabilityUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutSharedVehicleGroupRelAvailabilityInput>;
  availability?: Maybe<AvailabilityUpdateOneWithoutSharedVehicleGroupRelAvailabilityInput>;
};

export type SharedVehicleGroupRelAvailabilityUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SharedVehicleGroupRelAvailabilityUpdateManyWithWhereWithoutAvailabilityInput =
  {
    where: SharedVehicleGroupRelAvailabilityScalarWhereInput;
    data: SharedVehicleGroupRelAvailabilityUpdateManyMutationInput;
  };

export type SharedVehicleGroupRelAvailabilityUpdateManyWithWhereWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelAvailabilityScalarWhereInput;
    data: SharedVehicleGroupRelAvailabilityUpdateManyMutationInput;
  };

export type SharedVehicleGroupRelAvailabilityUpdateManyWithoutAvailabilityInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateWithoutAvailabilityInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateOrConnectWithoutAvailabilityInput>
    >;
    upsert?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelAvailabilityCreateManyAvailabilityInputEnvelope>;
    set?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
    disconnect?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>
    >;
    delete?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
    connect?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
    update?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput>
    >;
    updateMany?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityUpdateManyWithWhereWithoutAvailabilityInput>
    >;
    deleteMany?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityScalarWhereInput>
    >;
  };

export type SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    upsert?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityUpsertWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelAvailabilityCreateManySharedVehicleGroupInputEnvelope>;
    set?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
    disconnect?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>
    >;
    delete?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
    connect?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereUniqueInput>>;
    update?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityUpdateWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    updateMany?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityUpdateManyWithWhereWithoutSharedVehicleGroupInput>
    >;
    deleteMany?: Maybe<
      Array<SharedVehicleGroupRelAvailabilityScalarWhereInput>
    >;
  };

export type SharedVehicleGroupRelAvailabilityUpdateWithWhereUniqueWithoutAvailabilityInput =
  {
    where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
    data: SharedVehicleGroupRelAvailabilityUpdateWithoutAvailabilityInput;
  };

export type SharedVehicleGroupRelAvailabilityUpdateWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
    data: SharedVehicleGroupRelAvailabilityUpdateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelAvailabilityUpdateWithoutAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutSharedVehicleGroupRelAvailabilityInput>;
};

export type SharedVehicleGroupRelAvailabilityUpdateWithoutSharedVehicleGroupInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    availability?: Maybe<AvailabilityUpdateOneWithoutSharedVehicleGroupRelAvailabilityInput>;
  };

export type SharedVehicleGroupRelAvailabilityUpsertWithWhereUniqueWithoutAvailabilityInput =
  {
    where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
    update: SharedVehicleGroupRelAvailabilityUpdateWithoutAvailabilityInput;
    create: SharedVehicleGroupRelAvailabilityCreateWithoutAvailabilityInput;
  };

export type SharedVehicleGroupRelAvailabilityUpsertWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelAvailabilityWhereUniqueInput;
    update: SharedVehicleGroupRelAvailabilityUpdateWithoutSharedVehicleGroupInput;
    create: SharedVehicleGroupRelAvailabilityCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelAvailabilityWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelAvailabilityWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupRelationFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  availability?: Maybe<AvailabilityRelationFilter>;
  availabilityId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelCompany = {
  __typename?: 'SharedVehicleGroupRelCompany';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  company?: Maybe<Company>;
};

export type SharedVehicleGroupRelCompanyCountAggregate = {
  __typename?: 'SharedVehicleGroupRelCompanyCountAggregate';
  id: Scalars['Int'];
  sharedVehicleGroupId: Scalars['Int'];
  companyId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SharedVehicleGroupRelCompanyCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelCompanyCreateInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutCompaniesInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutSharedVehicleGroupRelCompanyInput>;
};

export type SharedVehicleGroupRelCompanyCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelCompanyCreateManyCompanyInputEnvelope = {
  data: Array<SharedVehicleGroupRelCompanyCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SharedVehicleGroupRelCompanyCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelCompanyCreateManySharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelCompanyCreateManySharedVehicleGroupInputEnvelope =
  {
    data: Array<SharedVehicleGroupRelCompanyCreateManySharedVehicleGroupInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type SharedVehicleGroupRelCompanyCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<SharedVehicleGroupRelCompanyCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupRelCompanyCreateOrConnectWithoutCompanyInput>
  >;
  createMany?: Maybe<SharedVehicleGroupRelCompanyCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
};

export type SharedVehicleGroupRelCompanyCreateNestedManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelCompanyCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelCompanyCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelCompanyCreateManySharedVehicleGroupInputEnvelope>;
    connect?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
  };

export type SharedVehicleGroupRelCompanyCreateOrConnectWithoutCompanyInput = {
  where: SharedVehicleGroupRelCompanyWhereUniqueInput;
  create: SharedVehicleGroupRelCompanyCreateWithoutCompanyInput;
};

export type SharedVehicleGroupRelCompanyCreateOrConnectWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelCompanyWhereUniqueInput;
    create: SharedVehicleGroupRelCompanyCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelCompanyCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutCompaniesInput>;
};

export type SharedVehicleGroupRelCompanyCreateWithoutSharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyCreateNestedOneWithoutSharedVehicleGroupRelCompanyInput>;
};

export type SharedVehicleGroupRelCompanyGroupBy = {
  __typename?: 'SharedVehicleGroupRelCompanyGroupBy';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<SharedVehicleGroupRelCompanyCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelCompanyMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelCompanyMaxAggregate>;
};

export type SharedVehicleGroupRelCompanyListRelationFilter = {
  every?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  some?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
  none?: Maybe<SharedVehicleGroupRelCompanyWhereInput>;
};

export type SharedVehicleGroupRelCompanyMaxAggregate = {
  __typename?: 'SharedVehicleGroupRelCompanyMaxAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelCompanyMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelCompanyMinAggregate = {
  __typename?: 'SharedVehicleGroupRelCompanyMinAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelCompanyMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelCompanyOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelCompanyOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  _count?: Maybe<SharedVehicleGroupRelCompanyCountOrderByAggregateInput>;
  _max?: Maybe<SharedVehicleGroupRelCompanyMaxOrderByAggregateInput>;
  _min?: Maybe<SharedVehicleGroupRelCompanyMinOrderByAggregateInput>;
};

export type SharedVehicleGroupRelCompanyOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByWithRelationInput>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
};

export enum SharedVehicleGroupRelCompanyScalarFieldEnum {
  Id = 'id',
  SharedVehicleGroupId = 'sharedVehicleGroupId',
  CompanyId = 'companyId',
}

export type SharedVehicleGroupRelCompanyScalarWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelCompanyScalarWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelCompanyScalarWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelCompanyScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelCompanyScalarWhereWithAggregatesInput = {
  AND?: Maybe<
    Array<SharedVehicleGroupRelCompanyScalarWhereWithAggregatesInput>
  >;
  OR?: Maybe<Array<SharedVehicleGroupRelCompanyScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<
    Array<SharedVehicleGroupRelCompanyScalarWhereWithAggregatesInput>
  >;
  id?: Maybe<StringWithAggregatesFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SharedVehicleGroupRelCompanyUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutCompaniesInput>;
  company?: Maybe<CompanyUpdateOneWithoutSharedVehicleGroupRelCompanyInput>;
};

export type SharedVehicleGroupRelCompanyUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SharedVehicleGroupRelCompanyUpdateManyWithWhereWithoutCompanyInput =
  {
    where: SharedVehicleGroupRelCompanyScalarWhereInput;
    data: SharedVehicleGroupRelCompanyUpdateManyMutationInput;
  };

export type SharedVehicleGroupRelCompanyUpdateManyWithWhereWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelCompanyScalarWhereInput;
    data: SharedVehicleGroupRelCompanyUpdateManyMutationInput;
  };

export type SharedVehicleGroupRelCompanyUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<SharedVehicleGroupRelCompanyCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupRelCompanyCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<
    Array<SharedVehicleGroupRelCompanyUpsertWithWhereUniqueWithoutCompanyInput>
  >;
  createMany?: Maybe<SharedVehicleGroupRelCompanyCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
  disconnect?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
  delete?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
  connect?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
  update?: Maybe<
    Array<SharedVehicleGroupRelCompanyUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: Maybe<
    Array<SharedVehicleGroupRelCompanyUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<SharedVehicleGroupRelCompanyScalarWhereInput>>;
};

export type SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelCompanyCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelCompanyCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    upsert?: Maybe<
      Array<SharedVehicleGroupRelCompanyUpsertWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelCompanyCreateManySharedVehicleGroupInputEnvelope>;
    set?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
    disconnect?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
    delete?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
    connect?: Maybe<Array<SharedVehicleGroupRelCompanyWhereUniqueInput>>;
    update?: Maybe<
      Array<SharedVehicleGroupRelCompanyUpdateWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    updateMany?: Maybe<
      Array<SharedVehicleGroupRelCompanyUpdateManyWithWhereWithoutSharedVehicleGroupInput>
    >;
    deleteMany?: Maybe<Array<SharedVehicleGroupRelCompanyScalarWhereInput>>;
  };

export type SharedVehicleGroupRelCompanyUpdateWithWhereUniqueWithoutCompanyInput =
  {
    where: SharedVehicleGroupRelCompanyWhereUniqueInput;
    data: SharedVehicleGroupRelCompanyUpdateWithoutCompanyInput;
  };

export type SharedVehicleGroupRelCompanyUpdateWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelCompanyWhereUniqueInput;
    data: SharedVehicleGroupRelCompanyUpdateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelCompanyUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutCompaniesInput>;
};

export type SharedVehicleGroupRelCompanyUpdateWithoutSharedVehicleGroupInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutSharedVehicleGroupRelCompanyInput>;
};

export type SharedVehicleGroupRelCompanyUpsertWithWhereUniqueWithoutCompanyInput =
  {
    where: SharedVehicleGroupRelCompanyWhereUniqueInput;
    update: SharedVehicleGroupRelCompanyUpdateWithoutCompanyInput;
    create: SharedVehicleGroupRelCompanyCreateWithoutCompanyInput;
  };

export type SharedVehicleGroupRelCompanyUpsertWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelCompanyWhereUniqueInput;
    update: SharedVehicleGroupRelCompanyUpdateWithoutSharedVehicleGroupInput;
    create: SharedVehicleGroupRelCompanyCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelCompanyWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelCompanyWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelCompanyWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelCompanyWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupRelationFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelCompanyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelUser = {
  __typename?: 'SharedVehicleGroupRelUser';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  user?: Maybe<User>;
};

export type SharedVehicleGroupRelUserCountAggregate = {
  __typename?: 'SharedVehicleGroupRelUserCountAggregate';
  id: Scalars['Int'];
  sharedVehicleGroupId: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SharedVehicleGroupRelUserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelUserCreateInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutUsersInput>;
  user?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupRelUserInput>;
};

export type SharedVehicleGroupRelUserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelUserCreateManySharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelUserCreateManySharedVehicleGroupInputEnvelope =
  {
    data: Array<SharedVehicleGroupRelUserCreateManySharedVehicleGroupInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type SharedVehicleGroupRelUserCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelUserCreateManyUserInputEnvelope = {
  data: Array<SharedVehicleGroupRelUserCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SharedVehicleGroupRelUserCreateNestedManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelUserCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelUserCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelUserCreateManySharedVehicleGroupInputEnvelope>;
    connect?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
  };

export type SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<SharedVehicleGroupRelUserCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupRelUserCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<SharedVehicleGroupRelUserCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
};

export type SharedVehicleGroupRelUserCreateOrConnectWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelUserWhereUniqueInput;
    create: SharedVehicleGroupRelUserCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelUserCreateOrConnectWithoutUserInput = {
  where: SharedVehicleGroupRelUserWhereUniqueInput;
  create: SharedVehicleGroupRelUserCreateWithoutUserInput;
};

export type SharedVehicleGroupRelUserCreateWithoutSharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutSharedVehicleGroupRelUserInput>;
};

export type SharedVehicleGroupRelUserCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutUsersInput>;
};

export type SharedVehicleGroupRelUserGroupBy = {
  __typename?: 'SharedVehicleGroupRelUserGroupBy';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  _count?: Maybe<SharedVehicleGroupRelUserCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelUserMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelUserMaxAggregate>;
};

export type SharedVehicleGroupRelUserListRelationFilter = {
  every?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  some?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  none?: Maybe<SharedVehicleGroupRelUserWhereInput>;
};

export type SharedVehicleGroupRelUserMaxAggregate = {
  __typename?: 'SharedVehicleGroupRelUserMaxAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelUserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelUserMinAggregate = {
  __typename?: 'SharedVehicleGroupRelUserMinAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelUserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelUserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelUserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<SharedVehicleGroupRelUserCountOrderByAggregateInput>;
  _max?: Maybe<SharedVehicleGroupRelUserMaxOrderByAggregateInput>;
  _min?: Maybe<SharedVehicleGroupRelUserMinOrderByAggregateInput>;
};

export type SharedVehicleGroupRelUserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByWithRelationInput>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum SharedVehicleGroupRelUserScalarFieldEnum {
  Id = 'id',
  SharedVehicleGroupId = 'sharedVehicleGroupId',
  UserId = 'userId',
}

export type SharedVehicleGroupRelUserScalarWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelUserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SharedVehicleGroupRelUserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutUsersInput>;
  user?: Maybe<UserUpdateOneWithoutSharedVehicleGroupRelUserInput>;
};

export type SharedVehicleGroupRelUserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SharedVehicleGroupRelUserUpdateManyWithWhereWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelUserScalarWhereInput;
    data: SharedVehicleGroupRelUserUpdateManyMutationInput;
  };

export type SharedVehicleGroupRelUserUpdateManyWithWhereWithoutUserInput = {
  where: SharedVehicleGroupRelUserScalarWhereInput;
  data: SharedVehicleGroupRelUserUpdateManyMutationInput;
};

export type SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelUserCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelUserCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    upsert?: Maybe<
      Array<SharedVehicleGroupRelUserUpsertWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelUserCreateManySharedVehicleGroupInputEnvelope>;
    set?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
    disconnect?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
    delete?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
    connect?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
    update?: Maybe<
      Array<SharedVehicleGroupRelUserUpdateWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    updateMany?: Maybe<
      Array<SharedVehicleGroupRelUserUpdateManyWithWhereWithoutSharedVehicleGroupInput>
    >;
    deleteMany?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereInput>>;
  };

export type SharedVehicleGroupRelUserUpdateManyWithoutUserInput = {
  create?: Maybe<Array<SharedVehicleGroupRelUserCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupRelUserCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<SharedVehicleGroupRelUserUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<SharedVehicleGroupRelUserCreateManyUserInputEnvelope>;
  set?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
  disconnect?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
  delete?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
  connect?: Maybe<Array<SharedVehicleGroupRelUserWhereUniqueInput>>;
  update?: Maybe<
    Array<SharedVehicleGroupRelUserUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<SharedVehicleGroupRelUserUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<SharedVehicleGroupRelUserScalarWhereInput>>;
};

export type SharedVehicleGroupRelUserUpdateWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelUserWhereUniqueInput;
    data: SharedVehicleGroupRelUserUpdateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelUserUpdateWithWhereUniqueWithoutUserInput = {
  where: SharedVehicleGroupRelUserWhereUniqueInput;
  data: SharedVehicleGroupRelUserUpdateWithoutUserInput;
};

export type SharedVehicleGroupRelUserUpdateWithoutSharedVehicleGroupInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutSharedVehicleGroupRelUserInput>;
};

export type SharedVehicleGroupRelUserUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutUsersInput>;
};

export type SharedVehicleGroupRelUserUpsertWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelUserWhereUniqueInput;
    update: SharedVehicleGroupRelUserUpdateWithoutSharedVehicleGroupInput;
    create: SharedVehicleGroupRelUserCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelUserUpsertWithWhereUniqueWithoutUserInput = {
  where: SharedVehicleGroupRelUserWhereUniqueInput;
  update: SharedVehicleGroupRelUserUpdateWithoutUserInput;
  create: SharedVehicleGroupRelUserCreateWithoutUserInput;
};

export type SharedVehicleGroupRelUserWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelUserWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelUserWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelUserWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupRelationFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelUserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelVehicle = {
  __typename?: 'SharedVehicleGroupRelVehicle';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroup>;
  vehicle?: Maybe<Vehicle>;
};

export type SharedVehicleGroupRelVehicleCountAggregate = {
  __typename?: 'SharedVehicleGroupRelVehicleCountAggregate';
  id: Scalars['Int'];
  sharedVehicleGroupId: Scalars['Int'];
  vehicleId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SharedVehicleGroupRelVehicleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelVehicleCreateInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutSharedVehicleGroupRelVehicleInput>;
};

export type SharedVehicleGroupRelVehicleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelVehicleCreateManySharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelVehicleCreateManySharedVehicleGroupInputEnvelope =
  {
    data: Array<SharedVehicleGroupRelVehicleCreateManySharedVehicleGroupInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type SharedVehicleGroupRelVehicleCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelVehicleCreateManyVehicleInputEnvelope = {
  data: Array<SharedVehicleGroupRelVehicleCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SharedVehicleGroupRelVehicleCreateNestedManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelVehicleCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelVehicleCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelVehicleCreateManySharedVehicleGroupInputEnvelope>;
    connect?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
  };

export type SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<SharedVehicleGroupRelVehicleCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupRelVehicleCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<SharedVehicleGroupRelVehicleCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
};

export type SharedVehicleGroupRelVehicleCreateOrConnectWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelVehicleWhereUniqueInput;
    create: SharedVehicleGroupRelVehicleCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelVehicleCreateOrConnectWithoutVehicleInput = {
  where: SharedVehicleGroupRelVehicleWhereUniqueInput;
  create: SharedVehicleGroupRelVehicleCreateWithoutVehicleInput;
};

export type SharedVehicleGroupRelVehicleCreateWithoutSharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutSharedVehicleGroupRelVehicleInput>;
};

export type SharedVehicleGroupRelVehicleCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedOneWithoutVehiclesInput>;
};

export type SharedVehicleGroupRelVehicleGroupBy = {
  __typename?: 'SharedVehicleGroupRelVehicleGroupBy';
  id: Scalars['String'];
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  _count?: Maybe<SharedVehicleGroupRelVehicleCountAggregate>;
  _min?: Maybe<SharedVehicleGroupRelVehicleMinAggregate>;
  _max?: Maybe<SharedVehicleGroupRelVehicleMaxAggregate>;
};

export type SharedVehicleGroupRelVehicleListRelationFilter = {
  every?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  some?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  none?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
};

export type SharedVehicleGroupRelVehicleMaxAggregate = {
  __typename?: 'SharedVehicleGroupRelVehicleMaxAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelVehicleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelVehicleMinAggregate = {
  __typename?: 'SharedVehicleGroupRelVehicleMinAggregate';
  id?: Maybe<Scalars['String']>;
  sharedVehicleGroupId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelVehicleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelVehicleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SharedVehicleGroupRelVehicleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  _count?: Maybe<SharedVehicleGroupRelVehicleCountOrderByAggregateInput>;
  _max?: Maybe<SharedVehicleGroupRelVehicleMaxOrderByAggregateInput>;
  _min?: Maybe<SharedVehicleGroupRelVehicleMinOrderByAggregateInput>;
};

export type SharedVehicleGroupRelVehicleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByWithRelationInput>;
  sharedVehicleGroupId?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
};

export enum SharedVehicleGroupRelVehicleScalarFieldEnum {
  Id = 'id',
  SharedVehicleGroupId = 'sharedVehicleGroupId',
  VehicleId = 'vehicleId',
}

export type SharedVehicleGroupRelVehicleScalarWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelVehicleScalarWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelVehicleScalarWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelVehicleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelVehicleScalarWhereWithAggregatesInput = {
  AND?: Maybe<
    Array<SharedVehicleGroupRelVehicleScalarWhereWithAggregatesInput>
  >;
  OR?: Maybe<Array<SharedVehicleGroupRelVehicleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<
    Array<SharedVehicleGroupRelVehicleScalarWhereWithAggregatesInput>
  >;
  id?: Maybe<StringWithAggregatesFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type SharedVehicleGroupRelVehicleUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutVehiclesInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutSharedVehicleGroupRelVehicleInput>;
};

export type SharedVehicleGroupRelVehicleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SharedVehicleGroupRelVehicleUpdateManyWithWhereWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelVehicleScalarWhereInput;
    data: SharedVehicleGroupRelVehicleUpdateManyMutationInput;
  };

export type SharedVehicleGroupRelVehicleUpdateManyWithWhereWithoutVehicleInput =
  {
    where: SharedVehicleGroupRelVehicleScalarWhereInput;
    data: SharedVehicleGroupRelVehicleUpdateManyMutationInput;
  };

export type SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput =
  {
    create?: Maybe<
      Array<SharedVehicleGroupRelVehicleCreateWithoutSharedVehicleGroupInput>
    >;
    connectOrCreate?: Maybe<
      Array<SharedVehicleGroupRelVehicleCreateOrConnectWithoutSharedVehicleGroupInput>
    >;
    upsert?: Maybe<
      Array<SharedVehicleGroupRelVehicleUpsertWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    createMany?: Maybe<SharedVehicleGroupRelVehicleCreateManySharedVehicleGroupInputEnvelope>;
    set?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
    disconnect?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
    delete?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
    connect?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
    update?: Maybe<
      Array<SharedVehicleGroupRelVehicleUpdateWithWhereUniqueWithoutSharedVehicleGroupInput>
    >;
    updateMany?: Maybe<
      Array<SharedVehicleGroupRelVehicleUpdateManyWithWhereWithoutSharedVehicleGroupInput>
    >;
    deleteMany?: Maybe<Array<SharedVehicleGroupRelVehicleScalarWhereInput>>;
  };

export type SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<SharedVehicleGroupRelVehicleCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupRelVehicleCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<SharedVehicleGroupRelVehicleUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<SharedVehicleGroupRelVehicleCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
  delete?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
  connect?: Maybe<Array<SharedVehicleGroupRelVehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<SharedVehicleGroupRelVehicleUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<SharedVehicleGroupRelVehicleUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<SharedVehicleGroupRelVehicleScalarWhereInput>>;
};

export type SharedVehicleGroupRelVehicleUpdateWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelVehicleWhereUniqueInput;
    data: SharedVehicleGroupRelVehicleUpdateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelVehicleUpdateWithWhereUniqueWithoutVehicleInput =
  {
    where: SharedVehicleGroupRelVehicleWhereUniqueInput;
    data: SharedVehicleGroupRelVehicleUpdateWithoutVehicleInput;
  };

export type SharedVehicleGroupRelVehicleUpdateWithoutSharedVehicleGroupInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutSharedVehicleGroupRelVehicleInput>;
};

export type SharedVehicleGroupRelVehicleUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateOneWithoutVehiclesInput>;
};

export type SharedVehicleGroupRelVehicleUpsertWithWhereUniqueWithoutSharedVehicleGroupInput =
  {
    where: SharedVehicleGroupRelVehicleWhereUniqueInput;
    update: SharedVehicleGroupRelVehicleUpdateWithoutSharedVehicleGroupInput;
    create: SharedVehicleGroupRelVehicleCreateWithoutSharedVehicleGroupInput;
  };

export type SharedVehicleGroupRelVehicleUpsertWithWhereUniqueWithoutVehicleInput =
  {
    where: SharedVehicleGroupRelVehicleWhereUniqueInput;
    update: SharedVehicleGroupRelVehicleUpdateWithoutVehicleInput;
    create: SharedVehicleGroupRelVehicleCreateWithoutVehicleInput;
  };

export type SharedVehicleGroupRelVehicleWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupRelVehicleWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupRelVehicleWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupRelVehicleWhereInput>>;
  id?: Maybe<StringFilter>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupRelationFilter>;
  sharedVehicleGroupId?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
};

export type SharedVehicleGroupRelVehicleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SharedVehicleGroupRelationFilter = {
  is?: Maybe<SharedVehicleGroupWhereInput>;
  isNot?: Maybe<SharedVehicleGroupWhereInput>;
};

export enum SharedVehicleGroupScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Discount = 'discount',
  OwnerBehavior = 'ownerBehavior',
  DiscountIncludesInsurance = 'discountIncludesInsurance',
  IsPrivate = 'isPrivate',
  CreatedById = 'createdById',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
}

export type SharedVehicleGroupScalarWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupScalarWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupScalarWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  discount?: Maybe<IntFilter>;
  ownerBehavior?: Maybe<BoolFilter>;
  discountIncludesInsurance?: Maybe<BoolFilter>;
  isPrivate?: Maybe<BoolFilter>;
  createdById?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
};

export type SharedVehicleGroupScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SharedVehicleGroupScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SharedVehicleGroupScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  discount?: Maybe<IntWithAggregatesFilter>;
  ownerBehavior?: Maybe<BoolWithAggregatesFilter>;
  discountIncludesInsurance?: Maybe<BoolWithAggregatesFilter>;
  isPrivate?: Maybe<BoolWithAggregatesFilter>;
  createdById?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
};

export type SharedVehicleGroupSumAggregate = {
  __typename?: 'SharedVehicleGroupSumAggregate';
  discount?: Maybe<Scalars['Int']>;
};

export type SharedVehicleGroupSumOrderByAggregateInput = {
  discount?: Maybe<SortOrder>;
};

export type SharedVehicleGroupUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type SharedVehicleGroupUpdateManyWithWhereWithoutCreatedByInput = {
  where: SharedVehicleGroupScalarWhereInput;
  data: SharedVehicleGroupUpdateManyMutationInput;
};

export type SharedVehicleGroupUpdateManyWithoutCreatedByInput = {
  create?: Maybe<Array<SharedVehicleGroupCreateWithoutCreatedByInput>>;
  connectOrCreate?: Maybe<
    Array<SharedVehicleGroupCreateOrConnectWithoutCreatedByInput>
  >;
  upsert?: Maybe<
    Array<SharedVehicleGroupUpsertWithWhereUniqueWithoutCreatedByInput>
  >;
  createMany?: Maybe<SharedVehicleGroupCreateManyCreatedByInputEnvelope>;
  set?: Maybe<Array<SharedVehicleGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<SharedVehicleGroupWhereUniqueInput>>;
  delete?: Maybe<Array<SharedVehicleGroupWhereUniqueInput>>;
  connect?: Maybe<Array<SharedVehicleGroupWhereUniqueInput>>;
  update?: Maybe<
    Array<SharedVehicleGroupUpdateWithWhereUniqueWithoutCreatedByInput>
  >;
  updateMany?: Maybe<
    Array<SharedVehicleGroupUpdateManyWithWhereWithoutCreatedByInput>
  >;
  deleteMany?: Maybe<Array<SharedVehicleGroupScalarWhereInput>>;
};

export type SharedVehicleGroupUpdateOneWithoutBookingsInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutBookingsInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutBookingsInput>;
  upsert?: Maybe<SharedVehicleGroupUpsertWithoutBookingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  update?: Maybe<SharedVehicleGroupUpdateWithoutBookingsInput>;
};

export type SharedVehicleGroupUpdateOneWithoutCompaniesInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutCompaniesInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutCompaniesInput>;
  upsert?: Maybe<SharedVehicleGroupUpsertWithoutCompaniesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  update?: Maybe<SharedVehicleGroupUpdateWithoutCompaniesInput>;
};

export type SharedVehicleGroupUpdateOneWithoutDefaultCompanyInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutDefaultCompanyInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutDefaultCompanyInput>;
  upsert?: Maybe<SharedVehicleGroupUpsertWithoutDefaultCompanyInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  update?: Maybe<SharedVehicleGroupUpdateWithoutDefaultCompanyInput>;
};

export type SharedVehicleGroupUpdateOneWithoutSharedVehicleGroupInvitedEmailInput =
  {
    create?: Maybe<SharedVehicleGroupCreateWithoutSharedVehicleGroupInvitedEmailInput>;
    connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutSharedVehicleGroupInvitedEmailInput>;
    upsert?: Maybe<SharedVehicleGroupUpsertWithoutSharedVehicleGroupInvitedEmailInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
    update?: Maybe<SharedVehicleGroupUpdateWithoutSharedVehicleGroupInvitedEmailInput>;
  };

export type SharedVehicleGroupUpdateOneWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    create?: Maybe<SharedVehicleGroupCreateWithoutSharedVehicleGroupRelAvailabilityInput>;
    connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutSharedVehicleGroupRelAvailabilityInput>;
    upsert?: Maybe<SharedVehicleGroupUpsertWithoutSharedVehicleGroupRelAvailabilityInput>;
    disconnect?: Maybe<Scalars['Boolean']>;
    delete?: Maybe<Scalars['Boolean']>;
    connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
    update?: Maybe<SharedVehicleGroupUpdateWithoutSharedVehicleGroupRelAvailabilityInput>;
  };

export type SharedVehicleGroupUpdateOneWithoutUsersInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutUsersInput>;
  upsert?: Maybe<SharedVehicleGroupUpsertWithoutUsersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  update?: Maybe<SharedVehicleGroupUpdateWithoutUsersInput>;
};

export type SharedVehicleGroupUpdateOneWithoutVehiclesInput = {
  create?: Maybe<SharedVehicleGroupCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<SharedVehicleGroupCreateOrConnectWithoutVehiclesInput>;
  upsert?: Maybe<SharedVehicleGroupUpsertWithoutVehiclesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  update?: Maybe<SharedVehicleGroupUpdateWithoutVehiclesInput>;
};

export type SharedVehicleGroupUpdateWithWhereUniqueWithoutCreatedByInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  data: SharedVehicleGroupUpdateWithoutCreatedByInput;
};

export type SharedVehicleGroupUpdateWithoutBookingsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
};

export type SharedVehicleGroupUpdateWithoutCompaniesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupUpdateWithoutCreatedByInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupUpdateWithoutDefaultCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
  bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupUpdateWithoutSharedVehicleGroupInvitedEmailInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    discount?: Maybe<IntFieldUpdateOperationsInput>;
    ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
    discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
    isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
    createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
    companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
    users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
    sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
    defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
    bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
  };

export type SharedVehicleGroupUpdateWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    id?: Maybe<StringFieldUpdateOperationsInput>;
    name?: Maybe<StringFieldUpdateOperationsInput>;
    discount?: Maybe<IntFieldUpdateOperationsInput>;
    ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
    discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
    isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
    createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
    createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
    deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
    vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
    companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
    users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
    sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
    defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
    bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
  };

export type SharedVehicleGroupUpdateWithoutUsersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutSharedVehicleGroupInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupUpdateWithoutVehiclesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<IntFieldUpdateOperationsInput>;
  ownerBehavior?: Maybe<BoolFieldUpdateOperationsInput>;
  discountIncludesInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<BoolFieldUpdateOperationsInput>;
  createdBy?: Maybe<UserUpdateOneWithoutSharedVehicleGroupInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  companies?: Maybe<SharedVehicleGroupRelCompanyUpdateManyWithoutSharedVehicleGroupInput>;
  users?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailUpdateManyWithoutSharedVehicleGroupInput>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityUpdateManyWithoutSharedVehicleGroupInput>;
  defaultCompany?: Maybe<CompanyUpdateManyWithoutDefaultSharedVehicleGroupInput>;
  bookings?: Maybe<BookingUpdateManyWithoutSharedVehicleGroupInput>;
};

export type SharedVehicleGroupUpsertWithWhereUniqueWithoutCreatedByInput = {
  where: SharedVehicleGroupWhereUniqueInput;
  update: SharedVehicleGroupUpdateWithoutCreatedByInput;
  create: SharedVehicleGroupCreateWithoutCreatedByInput;
};

export type SharedVehicleGroupUpsertWithoutBookingsInput = {
  update: SharedVehicleGroupUpdateWithoutBookingsInput;
  create: SharedVehicleGroupCreateWithoutBookingsInput;
};

export type SharedVehicleGroupUpsertWithoutCompaniesInput = {
  update: SharedVehicleGroupUpdateWithoutCompaniesInput;
  create: SharedVehicleGroupCreateWithoutCompaniesInput;
};

export type SharedVehicleGroupUpsertWithoutDefaultCompanyInput = {
  update: SharedVehicleGroupUpdateWithoutDefaultCompanyInput;
  create: SharedVehicleGroupCreateWithoutDefaultCompanyInput;
};

export type SharedVehicleGroupUpsertWithoutSharedVehicleGroupInvitedEmailInput =
  {
    update: SharedVehicleGroupUpdateWithoutSharedVehicleGroupInvitedEmailInput;
    create: SharedVehicleGroupCreateWithoutSharedVehicleGroupInvitedEmailInput;
  };

export type SharedVehicleGroupUpsertWithoutSharedVehicleGroupRelAvailabilityInput =
  {
    update: SharedVehicleGroupUpdateWithoutSharedVehicleGroupRelAvailabilityInput;
    create: SharedVehicleGroupCreateWithoutSharedVehicleGroupRelAvailabilityInput;
  };

export type SharedVehicleGroupUpsertWithoutUsersInput = {
  update: SharedVehicleGroupUpdateWithoutUsersInput;
  create: SharedVehicleGroupCreateWithoutUsersInput;
};

export type SharedVehicleGroupUpsertWithoutVehiclesInput = {
  update: SharedVehicleGroupUpdateWithoutVehiclesInput;
  create: SharedVehicleGroupCreateWithoutVehiclesInput;
};

export type SharedVehicleGroupWhereInput = {
  AND?: Maybe<Array<SharedVehicleGroupWhereInput>>;
  OR?: Maybe<Array<SharedVehicleGroupWhereInput>>;
  NOT?: Maybe<Array<SharedVehicleGroupWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  discount?: Maybe<IntFilter>;
  ownerBehavior?: Maybe<BoolFilter>;
  discountIncludesInsurance?: Maybe<BoolFilter>;
  isPrivate?: Maybe<BoolFilter>;
  createdBy?: Maybe<UserRelationFilter>;
  createdById?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  vehicles?: Maybe<SharedVehicleGroupRelVehicleListRelationFilter>;
  companies?: Maybe<SharedVehicleGroupRelCompanyListRelationFilter>;
  users?: Maybe<SharedVehicleGroupRelUserListRelationFilter>;
  sharedVehicleGroupInvitedEmail?: Maybe<SharedVehicleGroupInvitedEmailListRelationFilter>;
  sharedVehicleGroupRelAvailability?: Maybe<SharedVehicleGroupRelAvailabilityListRelationFilter>;
  defaultCompany?: Maybe<CompanyListRelationFilter>;
  bookings?: Maybe<BookingListRelationFilter>;
};

export type SharedVehicleGroupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ShieldColorType = {
  __typename?: 'ShieldColorType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ShieldColorTypeCount>;
  vehicle: Array<Vehicle>;
};

export type ShieldColorTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type ShieldColorTypeCount = {
  __typename?: 'ShieldColorTypeCount';
  vehicle: Scalars['Int'];
};

export type ShieldColorTypeCountAggregate = {
  __typename?: 'ShieldColorTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ShieldColorTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ShieldColorTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutShieldColorTypeInput>;
};

export type ShieldColorTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ShieldColorTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<ShieldColorTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ShieldColorTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<ShieldColorTypeWhereUniqueInput>;
};

export type ShieldColorTypeCreateOrConnectWithoutVehicleInput = {
  where: ShieldColorTypeWhereUniqueInput;
  create: ShieldColorTypeCreateWithoutVehicleInput;
};

export type ShieldColorTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type ShieldColorTypeGroupBy = {
  __typename?: 'ShieldColorTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<ShieldColorTypeCountAggregate>;
  _min?: Maybe<ShieldColorTypeMinAggregate>;
  _max?: Maybe<ShieldColorTypeMaxAggregate>;
};

export type ShieldColorTypeMaxAggregate = {
  __typename?: 'ShieldColorTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ShieldColorTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ShieldColorTypeMinAggregate = {
  __typename?: 'ShieldColorTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type ShieldColorTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type ShieldColorTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<ShieldColorTypeCountOrderByAggregateInput>;
  _max?: Maybe<ShieldColorTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ShieldColorTypeMinOrderByAggregateInput>;
};

export type ShieldColorTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type ShieldColorTypeRelationFilter = {
  is?: Maybe<ShieldColorTypeWhereInput>;
  isNot?: Maybe<ShieldColorTypeWhereInput>;
};

export enum ShieldColorTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type ShieldColorTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ShieldColorTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ShieldColorTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ShieldColorTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ShieldColorTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutShieldColorTypeInput>;
};

export type ShieldColorTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ShieldColorTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<ShieldColorTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<ShieldColorTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<ShieldColorTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ShieldColorTypeWhereUniqueInput>;
  update?: Maybe<ShieldColorTypeUpdateWithoutVehicleInput>;
};

export type ShieldColorTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ShieldColorTypeUpsertWithoutVehicleInput = {
  update: ShieldColorTypeUpdateWithoutVehicleInput;
  create: ShieldColorTypeCreateWithoutVehicleInput;
};

export type ShieldColorTypeWhereInput = {
  AND?: Maybe<Array<ShieldColorTypeWhereInput>>;
  OR?: Maybe<Array<ShieldColorTypeWhereInput>>;
  NOT?: Maybe<Array<ShieldColorTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type ShieldColorTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SmartcarAuth = {
  __typename?: 'SmartcarAuth';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  vehicleId: Scalars['String'];
  authToken: Scalars['String'];
  refreshToken: Scalars['String'];
  vehicle: Vehicle;
};

export type SmartcarAuthCountAggregate = {
  __typename?: 'SmartcarAuthCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  vehicleId: Scalars['Int'];
  authToken: Scalars['Int'];
  refreshToken: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SmartcarAuthCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
};

export type SmartcarAuthCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vehicle: VehicleCreateNestedOneWithoutSmartcarAuthInput;
  authToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type SmartcarAuthCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vehicleId: Scalars['String'];
  authToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type SmartcarAuthCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type SmartcarAuthCreateManyVehicleInputEnvelope = {
  data: Array<SmartcarAuthCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SmartcarAuthCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<SmartcarAuthCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<SmartcarAuthCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<SmartcarAuthCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<SmartcarAuthWhereUniqueInput>>;
};

export type SmartcarAuthCreateOrConnectWithoutVehicleInput = {
  where: SmartcarAuthWhereUniqueInput;
  create: SmartcarAuthCreateWithoutVehicleInput;
};

export type SmartcarAuthCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  authToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type SmartcarAuthGroupBy = {
  __typename?: 'SmartcarAuthGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  vehicleId: Scalars['String'];
  authToken: Scalars['String'];
  refreshToken: Scalars['String'];
  _count?: Maybe<SmartcarAuthCountAggregate>;
  _min?: Maybe<SmartcarAuthMinAggregate>;
  _max?: Maybe<SmartcarAuthMaxAggregate>;
};

export type SmartcarAuthListRelationFilter = {
  every?: Maybe<SmartcarAuthWhereInput>;
  some?: Maybe<SmartcarAuthWhereInput>;
  none?: Maybe<SmartcarAuthWhereInput>;
};

export type SmartcarAuthMaxAggregate = {
  __typename?: 'SmartcarAuthMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  authToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type SmartcarAuthMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
};

export type SmartcarAuthMinAggregate = {
  __typename?: 'SmartcarAuthMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  authToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type SmartcarAuthMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
};

export type SmartcarAuthOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SmartcarAuthOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
  _count?: Maybe<SmartcarAuthCountOrderByAggregateInput>;
  _max?: Maybe<SmartcarAuthMaxOrderByAggregateInput>;
  _min?: Maybe<SmartcarAuthMinOrderByAggregateInput>;
};

export type SmartcarAuthOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  authToken?: Maybe<SortOrder>;
  refreshToken?: Maybe<SortOrder>;
};

export enum SmartcarAuthScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  VehicleId = 'vehicleId',
  AuthToken = 'authToken',
  RefreshToken = 'refreshToken',
}

export type SmartcarAuthScalarWhereInput = {
  AND?: Maybe<Array<SmartcarAuthScalarWhereInput>>;
  OR?: Maybe<Array<SmartcarAuthScalarWhereInput>>;
  NOT?: Maybe<Array<SmartcarAuthScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  vehicleId?: Maybe<StringFilter>;
  authToken?: Maybe<StringFilter>;
  refreshToken?: Maybe<StringFilter>;
};

export type SmartcarAuthScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SmartcarAuthScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SmartcarAuthScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SmartcarAuthScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  authToken?: Maybe<StringWithAggregatesFilter>;
  refreshToken?: Maybe<StringWithAggregatesFilter>;
};

export type SmartcarAuthUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutSmartcarAuthInput>;
  authToken?: Maybe<StringFieldUpdateOperationsInput>;
  refreshToken?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SmartcarAuthUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  authToken?: Maybe<StringFieldUpdateOperationsInput>;
  refreshToken?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SmartcarAuthUpdateManyWithWhereWithoutVehicleInput = {
  where: SmartcarAuthScalarWhereInput;
  data: SmartcarAuthUpdateManyMutationInput;
};

export type SmartcarAuthUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<SmartcarAuthCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<SmartcarAuthCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<Array<SmartcarAuthUpsertWithWhereUniqueWithoutVehicleInput>>;
  createMany?: Maybe<SmartcarAuthCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<SmartcarAuthWhereUniqueInput>>;
  disconnect?: Maybe<Array<SmartcarAuthWhereUniqueInput>>;
  delete?: Maybe<Array<SmartcarAuthWhereUniqueInput>>;
  connect?: Maybe<Array<SmartcarAuthWhereUniqueInput>>;
  update?: Maybe<Array<SmartcarAuthUpdateWithWhereUniqueWithoutVehicleInput>>;
  updateMany?: Maybe<Array<SmartcarAuthUpdateManyWithWhereWithoutVehicleInput>>;
  deleteMany?: Maybe<Array<SmartcarAuthScalarWhereInput>>;
};

export type SmartcarAuthUpdateWithWhereUniqueWithoutVehicleInput = {
  where: SmartcarAuthWhereUniqueInput;
  data: SmartcarAuthUpdateWithoutVehicleInput;
};

export type SmartcarAuthUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  authToken?: Maybe<StringFieldUpdateOperationsInput>;
  refreshToken?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SmartcarAuthUpsertWithWhereUniqueWithoutVehicleInput = {
  where: SmartcarAuthWhereUniqueInput;
  update: SmartcarAuthUpdateWithoutVehicleInput;
  create: SmartcarAuthCreateWithoutVehicleInput;
};

export type SmartcarAuthWhereInput = {
  AND?: Maybe<Array<SmartcarAuthWhereInput>>;
  OR?: Maybe<Array<SmartcarAuthWhereInput>>;
  NOT?: Maybe<Array<SmartcarAuthWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  authToken?: Maybe<StringFilter>;
  refreshToken?: Maybe<StringFilter>;
};

export type SmartcarAuthWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type StringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export enum StripePaymentMethodType {
  Card = 'CARD',
  Twint = 'TWINT',
}

export type TransactionCalculationTariff = {
  __typename?: 'TransactionCalculationTariff';
  tariffs: Array<CalculationTariffItem>;
  totalCost: Scalars['Float'];
  totalkWh: Scalars['Float'];
  totalMinutes: Scalars['Float'];
};

export type TransmissionType = {
  __typename?: 'TransmissionType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<TransmissionTypeCount>;
  vehicle: Array<Vehicle>;
};

export type TransmissionTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type TransmissionTypeCount = {
  __typename?: 'TransmissionTypeCount';
  vehicle: Scalars['Int'];
};

export type TransmissionTypeCountAggregate = {
  __typename?: 'TransmissionTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TransmissionTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type TransmissionTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutTransmissionTypeInput>;
};

export type TransmissionTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type TransmissionTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<TransmissionTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<TransmissionTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<TransmissionTypeWhereUniqueInput>;
};

export type TransmissionTypeCreateOrConnectWithoutVehicleInput = {
  where: TransmissionTypeWhereUniqueInput;
  create: TransmissionTypeCreateWithoutVehicleInput;
};

export type TransmissionTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type TransmissionTypeGroupBy = {
  __typename?: 'TransmissionTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<TransmissionTypeCountAggregate>;
  _min?: Maybe<TransmissionTypeMinAggregate>;
  _max?: Maybe<TransmissionTypeMaxAggregate>;
};

export type TransmissionTypeMaxAggregate = {
  __typename?: 'TransmissionTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type TransmissionTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type TransmissionTypeMinAggregate = {
  __typename?: 'TransmissionTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type TransmissionTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type TransmissionTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<TransmissionTypeCountOrderByAggregateInput>;
  _max?: Maybe<TransmissionTypeMaxOrderByAggregateInput>;
  _min?: Maybe<TransmissionTypeMinOrderByAggregateInput>;
};

export type TransmissionTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type TransmissionTypeRelationFilter = {
  is?: Maybe<TransmissionTypeWhereInput>;
  isNot?: Maybe<TransmissionTypeWhereInput>;
};

export enum TransmissionTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type TransmissionTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TransmissionTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TransmissionTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TransmissionTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type TransmissionTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutTransmissionTypeInput>;
};

export type TransmissionTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type TransmissionTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<TransmissionTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<TransmissionTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<TransmissionTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TransmissionTypeWhereUniqueInput>;
  update?: Maybe<TransmissionTypeUpdateWithoutVehicleInput>;
};

export type TransmissionTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type TransmissionTypeUpsertWithoutVehicleInput = {
  update: TransmissionTypeUpdateWithoutVehicleInput;
  create: TransmissionTypeCreateWithoutVehicleInput;
};

export type TransmissionTypeWhereInput = {
  AND?: Maybe<Array<TransmissionTypeWhereInput>>;
  OR?: Maybe<Array<TransmissionTypeWhereInput>>;
  NOT?: Maybe<Array<TransmissionTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type TransmissionTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UnpublishReasonType = {
  __typename?: 'UnpublishReasonType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UnpublishReasonTypeCountAggregate = {
  __typename?: 'UnpublishReasonTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UnpublishReasonTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type UnpublishReasonTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UnpublishReasonTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UnpublishReasonTypeGroupBy = {
  __typename?: 'UnpublishReasonTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  _count?: Maybe<UnpublishReasonTypeCountAggregate>;
  _min?: Maybe<UnpublishReasonTypeMinAggregate>;
  _max?: Maybe<UnpublishReasonTypeMaxAggregate>;
};

export type UnpublishReasonTypeMaxAggregate = {
  __typename?: 'UnpublishReasonTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UnpublishReasonTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type UnpublishReasonTypeMinAggregate = {
  __typename?: 'UnpublishReasonTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UnpublishReasonTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type UnpublishReasonTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<UnpublishReasonTypeCountOrderByAggregateInput>;
  _max?: Maybe<UnpublishReasonTypeMaxOrderByAggregateInput>;
  _min?: Maybe<UnpublishReasonTypeMinOrderByAggregateInput>;
};

export type UnpublishReasonTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export enum UnpublishReasonTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
}

export type UnpublishReasonTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UnpublishReasonTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UnpublishReasonTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UnpublishReasonTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type UnpublishReasonTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UnpublishReasonTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UnpublishReasonTypeWhereInput = {
  AND?: Maybe<Array<UnpublishReasonTypeWhereInput>>;
  OR?: Maybe<Array<UnpublishReasonTypeWhereInput>>;
  NOT?: Maybe<Array<UnpublishReasonTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
};

export type UnpublishReasonTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts: Scalars['Int'];
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush: Scalars['Boolean'];
  notificationsIsEnabledEmail: Scalars['Boolean'];
  notificationsIsEnabledSms: Scalars['Boolean'];
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<UserCount>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<User>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<User>;
  vehicles: Array<Vehicle>;
  vehicleDamage: Array<VehicleDamage>;
  bookingVehicleDamages: Array<BookingVehicleDamages>;
  vehicleDamageComment: Array<VehicleDamageComment>;
  vehicleCloudBoxxStatusLog: Array<VehicleCloudBoxxStatusLog>;
  availability: Array<Availability>;
  biometricToken: Array<BiometricToken>;
  bookingAdditionalDriver: Array<BookingAdditionalDriver>;
  bookingRevokedByUser: Array<Booking>;
  booking: Array<Booking>;
  notificationDevices: Array<NotificationDevices>;
  bookingQuestion: Array<BookingQuestion>;
  bookingAnswer: Array<BookingAnswer>;
  sharedVehicleGroup: Array<SharedVehicleGroup>;
  sharedVehicleGroupRelUser: Array<SharedVehicleGroupRelUser>;
  message: Array<Message>;
  company?: Maybe<Company>;
  pxlVisionManualVerifications: Array<PxlVisionManualVerification>;
  pxlVisionManualVerified: Array<PxlVisionManualVerification>;
  chargingStationTransactions: Array<ChargingStationTransaction>;
  gateControllerRelUsers: Array<GateControllerRelUser>;
  gateControllerTransactions: Array<GateControllerTransaction>;
  chargingStationTariffs: Array<ChargingStationTariff>;
  bookingChangeHistory: Array<BookingChangeHistory>;
  pxlVisionIdManuallyVerified: Array<User>;
  pxlVisionDriverLicenseManuallyVerified: Array<User>;
  isStripeOnboarded?: Maybe<Scalars['Boolean']>;
  paymentCards: Array<PaymentCardDetails>;
  userLevel?: Maybe<UserLevelDto>;
};

export type UserVehiclesArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type UserVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageScalarFieldEnum>>;
};

export type UserBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
  orderBy?: Maybe<Array<BookingVehicleDamagesOrderByWithRelationInput>>;
  cursor?: Maybe<BookingVehicleDamagesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingVehicleDamagesScalarFieldEnum>>;
};

export type UserVehicleDamageCommentArgs = {
  where?: Maybe<VehicleDamageCommentWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageCommentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageCommentScalarFieldEnum>>;
};

export type UserVehicleCloudBoxxStatusLogArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxStatusLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxStatusLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxStatusLogScalarFieldEnum>>;
};

export type UserAvailabilityArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<AvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AvailabilityScalarFieldEnum>>;
};

export type UserBiometricTokenArgs = {
  where?: Maybe<BiometricTokenWhereInput>;
  orderBy?: Maybe<Array<BiometricTokenOrderByWithRelationInput>>;
  cursor?: Maybe<BiometricTokenWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BiometricTokenScalarFieldEnum>>;
};

export type UserBookingAdditionalDriverArgs = {
  where?: Maybe<BookingAdditionalDriverWhereInput>;
  orderBy?: Maybe<Array<BookingAdditionalDriverOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAdditionalDriverWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAdditionalDriverScalarFieldEnum>>;
};

export type UserBookingRevokedByUserArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type UserBookingArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type UserNotificationDevicesArgs = {
  where?: Maybe<NotificationDevicesWhereInput>;
  orderBy?: Maybe<Array<NotificationDevicesOrderByWithRelationInput>>;
  cursor?: Maybe<NotificationDevicesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<NotificationDevicesScalarFieldEnum>>;
};

export type UserBookingQuestionArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
  orderBy?: Maybe<Array<BookingQuestionOrderByWithRelationInput>>;
  cursor?: Maybe<BookingQuestionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingQuestionScalarFieldEnum>>;
};

export type UserBookingAnswerArgs = {
  where?: Maybe<BookingAnswerWhereInput>;
  orderBy?: Maybe<Array<BookingAnswerOrderByWithRelationInput>>;
  cursor?: Maybe<BookingAnswerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingAnswerScalarFieldEnum>>;
};

export type UserSharedVehicleGroupArgs = {
  where?: Maybe<SharedVehicleGroupWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupScalarFieldEnum>>;
};

export type UserSharedVehicleGroupRelUserArgs = {
  where?: Maybe<SharedVehicleGroupRelUserWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelUserScalarFieldEnum>>;
};

export type UserMessageArgs = {
  where?: Maybe<MessageWhereInput>;
  orderBy?: Maybe<Array<MessageOrderByWithRelationInput>>;
  cursor?: Maybe<MessageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<MessageScalarFieldEnum>>;
};

export type UserPxlVisionManualVerificationsArgs = {
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
  orderBy?: Maybe<Array<PxlVisionManualVerificationOrderByWithRelationInput>>;
  cursor?: Maybe<PxlVisionManualVerificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PxlVisionManualVerificationScalarFieldEnum>>;
};

export type UserPxlVisionManualVerifiedArgs = {
  where?: Maybe<PxlVisionManualVerificationWhereInput>;
  orderBy?: Maybe<Array<PxlVisionManualVerificationOrderByWithRelationInput>>;
  cursor?: Maybe<PxlVisionManualVerificationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PxlVisionManualVerificationScalarFieldEnum>>;
};

export type UserChargingStationTransactionsArgs = {
  where?: Maybe<ChargingStationTransactionWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTransactionScalarFieldEnum>>;
};

export type UserGateControllerRelUsersArgs = {
  where?: Maybe<GateControllerRelUserWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelUserOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelUserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelUserScalarFieldEnum>>;
};

export type UserGateControllerTransactionsArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTransactionScalarFieldEnum>>;
};

export type UserChargingStationTariffsArgs = {
  where?: Maybe<ChargingStationTariffWhereInput>;
  orderBy?: Maybe<Array<ChargingStationTariffOrderByWithRelationInput>>;
  cursor?: Maybe<ChargingStationTariffWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChargingStationTariffScalarFieldEnum>>;
};

export type UserBookingChangeHistoryArgs = {
  where?: Maybe<BookingChangeHistoryWhereInput>;
  orderBy?: Maybe<Array<BookingChangeHistoryOrderByWithRelationInput>>;
  cursor?: Maybe<BookingChangeHistoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingChangeHistoryScalarFieldEnum>>;
};

export type UserPxlVisionIdManuallyVerifiedArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type UserPxlVisionDriverLicenseManuallyVerifiedArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  otpPassword?: Maybe<Scalars['Float']>;
  failedLoginAttempts?: Maybe<Scalars['Float']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
};

export type UserAvgOrderByAggregateInput = {
  otpPassword?: Maybe<SortOrder>;
  failedLoginAttempts?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
};

export type UserCount = {
  __typename?: 'UserCount';
  vehicles: Scalars['Int'];
  vehicleDamage: Scalars['Int'];
  bookingVehicleDamages: Scalars['Int'];
  vehicleDamageComment: Scalars['Int'];
  vehicleCloudBoxxStatusLog: Scalars['Int'];
  availability: Scalars['Int'];
  biometricToken: Scalars['Int'];
  bookingAdditionalDriver: Scalars['Int'];
  bookingRevokedByUser: Scalars['Int'];
  booking: Scalars['Int'];
  notificationDevices: Scalars['Int'];
  bookingQuestion: Scalars['Int'];
  bookingAnswer: Scalars['Int'];
  sharedVehicleGroup: Scalars['Int'];
  sharedVehicleGroupRelUser: Scalars['Int'];
  message: Scalars['Int'];
  pxlVisionManualVerifications: Scalars['Int'];
  pxlVisionManualVerified: Scalars['Int'];
  chargingStationTransactions: Scalars['Int'];
  gateControllerRelUsers: Scalars['Int'];
  gateControllerTransactions: Scalars['Int'];
  chargingStationTariffs: Scalars['Int'];
  bookingChangeHistory: Scalars['Int'];
  pxlVisionIdManuallyVerified: Scalars['Int'];
  pxlVisionDriverLicenseManuallyVerified: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id: Scalars['Int'];
  password: Scalars['Int'];
  tokenSalt: Scalars['Int'];
  firstName: Scalars['Int'];
  middleName: Scalars['Int'];
  lastName: Scalars['Int'];
  email: Scalars['Int'];
  emailConfirmedAt: Scalars['Int'];
  preDeletedAt: Scalars['Int'];
  emailVerificationHash: Scalars['Int'];
  phone: Scalars['Int'];
  birthdate: Scalars['Int'];
  address: Scalars['Int'];
  houseNumber: Scalars['Int'];
  postCode: Scalars['Int'];
  gender: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  residencePermit: Scalars['Int'];
  pxlVisionIdCode: Scalars['Int'];
  pxlVisionIdAt: Scalars['Int'];
  pxlVisionIdManuallyVerifiedById: Scalars['Int'];
  pxlVisionDriverLicenseCode: Scalars['Int'];
  pxlVisionDriverLicenseManuallyVerifiedById: Scalars['Int'];
  pxlVisionDriverLicenseAt: Scalars['Int'];
  language: Scalars['Int'];
  nationality: Scalars['Int'];
  city: Scalars['Int'];
  driverFirstName: Scalars['Int'];
  driverMiddleName: Scalars['Int'];
  driverLastName: Scalars['Int'];
  driverBirthdate: Scalars['Int'];
  driverCountry: Scalars['Int'];
  driverLicenceNumber: Scalars['Int'];
  driverExpirationAt: Scalars['Int'];
  driverDateOfIssueAt: Scalars['Int'];
  phoneForUpdate: Scalars['Int'];
  otpPassword: Scalars['Int'];
  forgottenPasswordAt: Scalars['Int'];
  forgottenPasswordHash: Scalars['Int'];
  loginBlockedAt: Scalars['Int'];
  stripeClientSecret: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripeConfirmed: Scalars['Int'];
  stripeTwintConfimedAt: Scalars['Int'];
  stripeAccountId: Scalars['Int'];
  stripeAccountLink: Scalars['Int'];
  stripeAccountConfirmedAt: Scalars['Int'];
  stripePaymentMethod: Scalars['Int'];
  stripePaymentMethodType: Scalars['Int'];
  presetPassword: Scalars['Int'];
  failedLoginAttempts: Scalars['Int'];
  phoneConfirmedAt: Scalars['Int'];
  userRole: Scalars['Int'];
  commissionScooters: Scalars['Int'];
  commissionBookings: Scalars['Int'];
  commissionChargingStations: Scalars['Int'];
  notificationsIsEnabledPush: Scalars['Int'];
  notificationsIsEnabledEmail: Scalars['Int'];
  notificationsIsEnabledSms: Scalars['Int'];
  companyId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  middleName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailConfirmedAt?: Maybe<SortOrder>;
  preDeletedAt?: Maybe<SortOrder>;
  emailVerificationHash?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  residencePermit?: Maybe<SortOrder>;
  pxlVisionIdCode?: Maybe<SortOrder>;
  pxlVisionIdAt?: Maybe<SortOrder>;
  pxlVisionIdManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseCode?: Maybe<SortOrder>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseAt?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  driverFirstName?: Maybe<SortOrder>;
  driverMiddleName?: Maybe<SortOrder>;
  driverLastName?: Maybe<SortOrder>;
  driverBirthdate?: Maybe<SortOrder>;
  driverCountry?: Maybe<SortOrder>;
  driverLicenceNumber?: Maybe<SortOrder>;
  driverExpirationAt?: Maybe<SortOrder>;
  driverDateOfIssueAt?: Maybe<SortOrder>;
  phoneForUpdate?: Maybe<SortOrder>;
  otpPassword?: Maybe<SortOrder>;
  forgottenPasswordAt?: Maybe<SortOrder>;
  forgottenPasswordHash?: Maybe<SortOrder>;
  loginBlockedAt?: Maybe<SortOrder>;
  stripeClientSecret?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeConfirmed?: Maybe<SortOrder>;
  stripeTwintConfimedAt?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<SortOrder>;
  stripePaymentMethodType?: Maybe<SortOrder>;
  presetPassword?: Maybe<SortOrder>;
  failedLoginAttempts?: Maybe<SortOrder>;
  phoneConfirmedAt?: Maybe<SortOrder>;
  userRole?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  notificationsIsEnabledPush?: Maybe<SortOrder>;
  notificationsIsEnabledEmail?: Maybe<SortOrder>;
  notificationsIsEnabledSms?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyCompanyInputEnvelope = {
  data: Array<UserCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
};

export type UserCreateManyPxlVisionDriverLicenseManuallyVerifiedByInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
};

export type UserCreateManyPxlVisionDriverLicenseManuallyVerifiedByInputEnvelope =
  {
    data: Array<UserCreateManyPxlVisionDriverLicenseManuallyVerifiedByInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type UserCreateManyPxlVisionIdManuallyVerifiedByInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
};

export type UserCreateManyPxlVisionIdManuallyVerifiedByInputEnvelope = {
  data: Array<UserCreateManyPxlVisionIdManuallyVerifiedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutChargingStationTariffsInput = {
  create?: Maybe<Array<UserCreateWithoutChargingStationTariffsInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutChargingStationTariffsInput>
  >;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<UserCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  createMany?: Maybe<UserCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput =
  {
    create?: Maybe<
      Array<UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>
    >;
    connectOrCreate?: Maybe<
      Array<UserCreateOrConnectWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>
    >;
    createMany?: Maybe<UserCreateManyPxlVisionDriverLicenseManuallyVerifiedByInputEnvelope>;
    connect?: Maybe<Array<UserWhereUniqueInput>>;
  };

export type UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput = {
  create?: Maybe<Array<UserCreateWithoutPxlVisionIdManuallyVerifiedByInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutPxlVisionIdManuallyVerifiedByInput>
  >;
  createMany?: Maybe<UserCreateManyPxlVisionIdManuallyVerifiedByInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedOneWithoutAvailabilityInput = {
  create?: Maybe<UserCreateWithoutAvailabilityInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAvailabilityInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBiometricTokenInput = {
  create?: Maybe<UserCreateWithoutBiometricTokenInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBiometricTokenInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBookingAdditionalDriverInput = {
  create?: Maybe<UserCreateWithoutBookingAdditionalDriverInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingAdditionalDriverInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBookingAnswerInput = {
  create?: Maybe<UserCreateWithoutBookingAnswerInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingAnswerInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBookingChangeHistoryInput = {
  create?: Maybe<UserCreateWithoutBookingChangeHistoryInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingChangeHistoryInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBookingInput = {
  create?: Maybe<UserCreateWithoutBookingInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBookingQuestionInput = {
  create?: Maybe<UserCreateWithoutBookingQuestionInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingQuestionInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBookingRevokedByUserInput = {
  create?: Maybe<UserCreateWithoutBookingRevokedByUserInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingRevokedByUserInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutBookingVehicleDamagesInput = {
  create?: Maybe<UserCreateWithoutBookingVehicleDamagesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingVehicleDamagesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutChargingStationTransactionsInput = {
  create?: Maybe<UserCreateWithoutChargingStationTransactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChargingStationTransactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutGateControllerRelUsersInput = {
  create?: Maybe<UserCreateWithoutGateControllerRelUsersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGateControllerRelUsersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutGateControllerTransactionsInput = {
  create?: Maybe<UserCreateWithoutGateControllerTransactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGateControllerTransactionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMessageInput = {
  create?: Maybe<UserCreateWithoutMessageInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutNotificationDevicesInput = {
  create?: Maybe<UserCreateWithoutNotificationDevicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutNotificationDevicesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput =
  {
    create?: Maybe<UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
    connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
    connect?: Maybe<UserWhereUniqueInput>;
  };

export type UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput = {
  create?: Maybe<UserCreateWithoutPxlVisionIdManuallyVerifiedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionIdManuallyVerifiedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutPxlVisionManualVerificationsInput = {
  create?: Maybe<UserCreateWithoutPxlVisionManualVerificationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionManualVerificationsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutPxlVisionManualVerifiedInput = {
  create?: Maybe<UserCreateWithoutPxlVisionManualVerifiedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionManualVerifiedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSharedVehicleGroupInput = {
  create?: Maybe<UserCreateWithoutSharedVehicleGroupInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSharedVehicleGroupInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSharedVehicleGroupRelUserInput = {
  create?: Maybe<UserCreateWithoutSharedVehicleGroupRelUserInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSharedVehicleGroupRelUserInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutVehicleCloudBoxxStatusLogInput = {
  create?: Maybe<UserCreateWithoutVehicleCloudBoxxStatusLogInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehicleCloudBoxxStatusLogInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutVehicleDamageCommentInput = {
  create?: Maybe<UserCreateWithoutVehicleDamageCommentInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehicleDamageCommentInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutVehicleDamageInput = {
  create?: Maybe<UserCreateWithoutVehicleDamageInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehicleDamageInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutVehiclesInput = {
  create?: Maybe<UserCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehiclesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutAvailabilityInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutAvailabilityInput;
};

export type UserCreateOrConnectWithoutBiometricTokenInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBiometricTokenInput;
};

export type UserCreateOrConnectWithoutBookingAdditionalDriverInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBookingAdditionalDriverInput;
};

export type UserCreateOrConnectWithoutBookingAnswerInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBookingAnswerInput;
};

export type UserCreateOrConnectWithoutBookingChangeHistoryInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBookingChangeHistoryInput;
};

export type UserCreateOrConnectWithoutBookingInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBookingInput;
};

export type UserCreateOrConnectWithoutBookingQuestionInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBookingQuestionInput;
};

export type UserCreateOrConnectWithoutBookingRevokedByUserInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBookingRevokedByUserInput;
};

export type UserCreateOrConnectWithoutBookingVehicleDamagesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBookingVehicleDamagesInput;
};

export type UserCreateOrConnectWithoutChargingStationTariffsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutChargingStationTariffsInput;
};

export type UserCreateOrConnectWithoutChargingStationTransactionsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutChargingStationTransactionsInput;
};

export type UserCreateOrConnectWithoutCompanyInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCompanyInput;
};

export type UserCreateOrConnectWithoutGateControllerRelUsersInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutGateControllerRelUsersInput;
};

export type UserCreateOrConnectWithoutGateControllerTransactionsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutGateControllerTransactionsInput;
};

export type UserCreateOrConnectWithoutMessageInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMessageInput;
};

export type UserCreateOrConnectWithoutNotificationDevicesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutNotificationDevicesInput;
};

export type UserCreateOrConnectWithoutPxlVisionDriverLicenseManuallyVerifiedByInput =
  {
    where: UserWhereUniqueInput;
    create: UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput;
  };

export type UserCreateOrConnectWithoutPxlVisionDriverLicenseManuallyVerifiedInput =
  {
    where: UserWhereUniqueInput;
    create: UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedInput;
  };

export type UserCreateOrConnectWithoutPxlVisionIdManuallyVerifiedByInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPxlVisionIdManuallyVerifiedByInput;
};

export type UserCreateOrConnectWithoutPxlVisionIdManuallyVerifiedInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPxlVisionIdManuallyVerifiedInput;
};

export type UserCreateOrConnectWithoutPxlVisionManualVerificationsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPxlVisionManualVerificationsInput;
};

export type UserCreateOrConnectWithoutPxlVisionManualVerifiedInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPxlVisionManualVerifiedInput;
};

export type UserCreateOrConnectWithoutSharedVehicleGroupInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSharedVehicleGroupInput;
};

export type UserCreateOrConnectWithoutSharedVehicleGroupRelUserInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSharedVehicleGroupRelUserInput;
};

export type UserCreateOrConnectWithoutVehicleCloudBoxxStatusLogInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutVehicleCloudBoxxStatusLogInput;
};

export type UserCreateOrConnectWithoutVehicleDamageCommentInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutVehicleDamageCommentInput;
};

export type UserCreateOrConnectWithoutVehicleDamageInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutVehicleDamageInput;
};

export type UserCreateOrConnectWithoutVehiclesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutVehiclesInput;
};

export type UserCreateWithoutAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBiometricTokenInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBookingAdditionalDriverInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBookingAnswerInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBookingChangeHistoryInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBookingQuestionInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBookingRevokedByUserInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutBookingVehicleDamagesInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutChargingStationTariffsInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutChargingStationTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutGateControllerRelUsersInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutGateControllerTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutMessageInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutNotificationDevicesInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
};

export type UserCreateWithoutPxlVisionIdManuallyVerifiedByInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutPxlVisionIdManuallyVerifiedInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutPxlVisionManualVerificationsInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutPxlVisionManualVerifiedInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutSharedVehicleGroupInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutSharedVehicleGroupRelUserInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutVehicleCloudBoxxStatusLogInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutVehicleDamageCommentInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutVehicleDamageInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserCreateWithoutVehiclesInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserCreateNestedOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenCreateNestedManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverCreateNestedManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingCreateNestedManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingCreateNestedManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesCreateNestedManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerCreateNestedManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupCreateNestedManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserCreateNestedManyWithoutUserInput>;
  message?: Maybe<MessageCreateNestedManyWithoutUserInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationCreateNestedManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionCreateNestedManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserCreateNestedManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffCreateNestedManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryCreateNestedManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserCreateNestedManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  id: Scalars['String'];
  password: Scalars['String'];
  tokenSalt: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts: Scalars['Int'];
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush: Scalars['Boolean'];
  notificationsIsEnabledEmail: Scalars['Boolean'];
  notificationsIsEnabledSms: Scalars['Boolean'];
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<UserCountAggregate>;
  _avg?: Maybe<UserAvgAggregate>;
  _sum?: Maybe<UserSumAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _max?: Maybe<UserMaxAggregate>;
};

export type UserInvitedEmail = {
  __typename?: 'UserInvitedEmail';
  id: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  company?: Maybe<Company>;
};

export type UserInvitedEmailCountAggregate = {
  __typename?: 'UserInvitedEmailCountAggregate';
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  email: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserInvitedEmailCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type UserInvitedEmailCreateInput = {
  id?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyCreateNestedOneWithoutInvitedEmailsInput>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserInvitedEmailCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserInvitedEmailCreateManyCompanyInputEnvelope = {
  data: Array<UserInvitedEmailCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserInvitedEmailCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserInvitedEmailCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<UserInvitedEmailCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<UserInvitedEmailCreateOrConnectWithoutCompanyInput>
  >;
  createMany?: Maybe<UserInvitedEmailCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<UserInvitedEmailWhereUniqueInput>>;
};

export type UserInvitedEmailCreateOrConnectWithoutCompanyInput = {
  where: UserInvitedEmailWhereUniqueInput;
  create: UserInvitedEmailCreateWithoutCompanyInput;
};

export type UserInvitedEmailCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserInvitedEmailGroupBy = {
  __typename?: 'UserInvitedEmailGroupBy';
  id: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  _count?: Maybe<UserInvitedEmailCountAggregate>;
  _min?: Maybe<UserInvitedEmailMinAggregate>;
  _max?: Maybe<UserInvitedEmailMaxAggregate>;
};

export type UserInvitedEmailListRelationFilter = {
  every?: Maybe<UserInvitedEmailWhereInput>;
  some?: Maybe<UserInvitedEmailWhereInput>;
  none?: Maybe<UserInvitedEmailWhereInput>;
};

export type UserInvitedEmailMaxAggregate = {
  __typename?: 'UserInvitedEmailMaxAggregate';
  id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserInvitedEmailMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type UserInvitedEmailMinAggregate = {
  __typename?: 'UserInvitedEmailMinAggregate';
  id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserInvitedEmailMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type UserInvitedEmailOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type UserInvitedEmailOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<UserInvitedEmailCountOrderByAggregateInput>;
  _max?: Maybe<UserInvitedEmailMaxOrderByAggregateInput>;
  _min?: Maybe<UserInvitedEmailMinOrderByAggregateInput>;
};

export type UserInvitedEmailOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum UserInvitedEmailScalarFieldEnum {
  Id = 'id',
  CompanyId = 'companyId',
  Email = 'email',
  CreatedAt = 'createdAt',
}

export type UserInvitedEmailScalarWhereInput = {
  AND?: Maybe<Array<UserInvitedEmailScalarWhereInput>>;
  OR?: Maybe<Array<UserInvitedEmailScalarWhereInput>>;
  NOT?: Maybe<Array<UserInvitedEmailScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  companyId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type UserInvitedEmailScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserInvitedEmailScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserInvitedEmailScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserInvitedEmailScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type UserInvitedEmailUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutInvitedEmailsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserInvitedEmailUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserInvitedEmailUpdateManyWithWhereWithoutCompanyInput = {
  where: UserInvitedEmailScalarWhereInput;
  data: UserInvitedEmailUpdateManyMutationInput;
};

export type UserInvitedEmailUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<UserInvitedEmailCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<UserInvitedEmailCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<
    Array<UserInvitedEmailUpsertWithWhereUniqueWithoutCompanyInput>
  >;
  createMany?: Maybe<UserInvitedEmailCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<UserInvitedEmailWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserInvitedEmailWhereUniqueInput>>;
  delete?: Maybe<Array<UserInvitedEmailWhereUniqueInput>>;
  connect?: Maybe<Array<UserInvitedEmailWhereUniqueInput>>;
  update?: Maybe<
    Array<UserInvitedEmailUpdateWithWhereUniqueWithoutCompanyInput>
  >;
  updateMany?: Maybe<
    Array<UserInvitedEmailUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<UserInvitedEmailScalarWhereInput>>;
};

export type UserInvitedEmailUpdateWithWhereUniqueWithoutCompanyInput = {
  where: UserInvitedEmailWhereUniqueInput;
  data: UserInvitedEmailUpdateWithoutCompanyInput;
};

export type UserInvitedEmailUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserInvitedEmailUpsertWithWhereUniqueWithoutCompanyInput = {
  where: UserInvitedEmailWhereUniqueInput;
  update: UserInvitedEmailUpdateWithoutCompanyInput;
  create: UserInvitedEmailCreateWithoutCompanyInput;
};

export type UserInvitedEmailWhereInput = {
  AND?: Maybe<Array<UserInvitedEmailWhereInput>>;
  OR?: Maybe<Array<UserInvitedEmailWhereInput>>;
  NOT?: Maybe<Array<UserInvitedEmailWhereInput>>;
  id?: Maybe<StringFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type UserInvitedEmailWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserLevelDto = {
  __typename?: 'UserLevelDto';
  level?: Maybe<Scalars['Float']>;
  steps?: Maybe<UserLevelSteps>;
};

export type UserLevelSteps = {
  __typename?: 'UserLevelSteps';
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  identificationDocumentVerified?: Maybe<Scalars['Boolean']>;
  drivingLicenseVerified?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<Scalars['Boolean']>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  middleName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailConfirmedAt?: Maybe<SortOrder>;
  preDeletedAt?: Maybe<SortOrder>;
  emailVerificationHash?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  residencePermit?: Maybe<SortOrder>;
  pxlVisionIdCode?: Maybe<SortOrder>;
  pxlVisionIdAt?: Maybe<SortOrder>;
  pxlVisionIdManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseCode?: Maybe<SortOrder>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseAt?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  driverFirstName?: Maybe<SortOrder>;
  driverMiddleName?: Maybe<SortOrder>;
  driverLastName?: Maybe<SortOrder>;
  driverBirthdate?: Maybe<SortOrder>;
  driverCountry?: Maybe<SortOrder>;
  driverLicenceNumber?: Maybe<SortOrder>;
  driverExpirationAt?: Maybe<SortOrder>;
  driverDateOfIssueAt?: Maybe<SortOrder>;
  phoneForUpdate?: Maybe<SortOrder>;
  otpPassword?: Maybe<SortOrder>;
  forgottenPasswordAt?: Maybe<SortOrder>;
  forgottenPasswordHash?: Maybe<SortOrder>;
  loginBlockedAt?: Maybe<SortOrder>;
  stripeClientSecret?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeConfirmed?: Maybe<SortOrder>;
  stripeTwintConfimedAt?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<SortOrder>;
  stripePaymentMethodType?: Maybe<SortOrder>;
  presetPassword?: Maybe<SortOrder>;
  failedLoginAttempts?: Maybe<SortOrder>;
  phoneConfirmedAt?: Maybe<SortOrder>;
  userRole?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  notificationsIsEnabledPush?: Maybe<SortOrder>;
  notificationsIsEnabledEmail?: Maybe<SortOrder>;
  notificationsIsEnabledSms?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tokenSalt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmedAt?: Maybe<Scalars['DateTime']>;
  preDeletedAt?: Maybe<Scalars['DateTime']>;
  emailVerificationHash?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  residencePermit?: Maybe<Scalars['String']>;
  pxlVisionIdCode?: Maybe<Scalars['String']>;
  pxlVisionIdAt?: Maybe<Scalars['DateTime']>;
  pxlVisionIdManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseCode?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<Scalars['String']>;
  pxlVisionDriverLicenseAt?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverMiddleName?: Maybe<Scalars['String']>;
  driverLastName?: Maybe<Scalars['String']>;
  driverBirthdate?: Maybe<Scalars['DateTime']>;
  driverCountry?: Maybe<Scalars['String']>;
  driverLicenceNumber?: Maybe<Scalars['String']>;
  driverExpirationAt?: Maybe<Scalars['DateTime']>;
  driverDateOfIssueAt?: Maybe<Scalars['DateTime']>;
  phoneForUpdate?: Maybe<Scalars['String']>;
  otpPassword?: Maybe<Scalars['Int']>;
  forgottenPasswordAt?: Maybe<Scalars['DateTime']>;
  forgottenPasswordHash?: Maybe<Scalars['String']>;
  loginBlockedAt?: Maybe<Scalars['DateTime']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeConfirmed?: Maybe<Scalars['Boolean']>;
  stripeTwintConfimedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountLink?: Maybe<Scalars['String']>;
  stripeAccountConfirmedAt?: Maybe<Scalars['DateTime']>;
  stripePaymentMethod?: Maybe<Scalars['String']>;
  stripePaymentMethodType?: Maybe<StripePaymentMethodType>;
  presetPassword?: Maybe<Scalars['Boolean']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  phoneConfirmedAt?: Maybe<Scalars['DateTime']>;
  userRole?: Maybe<UserRole>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
  notificationsIsEnabledPush?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledEmail?: Maybe<Scalars['Boolean']>;
  notificationsIsEnabledSms?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  middleName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailConfirmedAt?: Maybe<SortOrder>;
  preDeletedAt?: Maybe<SortOrder>;
  emailVerificationHash?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  residencePermit?: Maybe<SortOrder>;
  pxlVisionIdCode?: Maybe<SortOrder>;
  pxlVisionIdAt?: Maybe<SortOrder>;
  pxlVisionIdManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseCode?: Maybe<SortOrder>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseAt?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  driverFirstName?: Maybe<SortOrder>;
  driverMiddleName?: Maybe<SortOrder>;
  driverLastName?: Maybe<SortOrder>;
  driverBirthdate?: Maybe<SortOrder>;
  driverCountry?: Maybe<SortOrder>;
  driverLicenceNumber?: Maybe<SortOrder>;
  driverExpirationAt?: Maybe<SortOrder>;
  driverDateOfIssueAt?: Maybe<SortOrder>;
  phoneForUpdate?: Maybe<SortOrder>;
  otpPassword?: Maybe<SortOrder>;
  forgottenPasswordAt?: Maybe<SortOrder>;
  forgottenPasswordHash?: Maybe<SortOrder>;
  loginBlockedAt?: Maybe<SortOrder>;
  stripeClientSecret?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeConfirmed?: Maybe<SortOrder>;
  stripeTwintConfimedAt?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<SortOrder>;
  stripePaymentMethodType?: Maybe<SortOrder>;
  presetPassword?: Maybe<SortOrder>;
  failedLoginAttempts?: Maybe<SortOrder>;
  phoneConfirmedAt?: Maybe<SortOrder>;
  userRole?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  notificationsIsEnabledPush?: Maybe<SortOrder>;
  notificationsIsEnabledEmail?: Maybe<SortOrder>;
  notificationsIsEnabledSms?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  middleName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailConfirmedAt?: Maybe<SortOrder>;
  preDeletedAt?: Maybe<SortOrder>;
  emailVerificationHash?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  residencePermit?: Maybe<SortOrder>;
  pxlVisionIdCode?: Maybe<SortOrder>;
  pxlVisionIdAt?: Maybe<SortOrder>;
  pxlVisionIdManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseCode?: Maybe<SortOrder>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseAt?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  driverFirstName?: Maybe<SortOrder>;
  driverMiddleName?: Maybe<SortOrder>;
  driverLastName?: Maybe<SortOrder>;
  driverBirthdate?: Maybe<SortOrder>;
  driverCountry?: Maybe<SortOrder>;
  driverLicenceNumber?: Maybe<SortOrder>;
  driverExpirationAt?: Maybe<SortOrder>;
  driverDateOfIssueAt?: Maybe<SortOrder>;
  phoneForUpdate?: Maybe<SortOrder>;
  otpPassword?: Maybe<SortOrder>;
  forgottenPasswordAt?: Maybe<SortOrder>;
  forgottenPasswordHash?: Maybe<SortOrder>;
  loginBlockedAt?: Maybe<SortOrder>;
  stripeClientSecret?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeConfirmed?: Maybe<SortOrder>;
  stripeTwintConfimedAt?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<SortOrder>;
  stripePaymentMethodType?: Maybe<SortOrder>;
  presetPassword?: Maybe<SortOrder>;
  failedLoginAttempts?: Maybe<SortOrder>;
  phoneConfirmedAt?: Maybe<SortOrder>;
  userRole?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  notificationsIsEnabledPush?: Maybe<SortOrder>;
  notificationsIsEnabledEmail?: Maybe<SortOrder>;
  notificationsIsEnabledSms?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  _count?: Maybe<UserCountOrderByAggregateInput>;
  _avg?: Maybe<UserAvgOrderByAggregateInput>;
  _max?: Maybe<UserMaxOrderByAggregateInput>;
  _min?: Maybe<UserMinOrderByAggregateInput>;
  _sum?: Maybe<UserSumOrderByAggregateInput>;
};

export type UserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  middleName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailConfirmedAt?: Maybe<SortOrder>;
  preDeletedAt?: Maybe<SortOrder>;
  emailVerificationHash?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  residencePermit?: Maybe<SortOrder>;
  pxlVisionIdCode?: Maybe<SortOrder>;
  pxlVisionIdAt?: Maybe<SortOrder>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserOrderByWithRelationInput>;
  pxlVisionIdManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseCode?: Maybe<SortOrder>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserOrderByWithRelationInput>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<SortOrder>;
  pxlVisionDriverLicenseAt?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  nationality?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  driverFirstName?: Maybe<SortOrder>;
  driverMiddleName?: Maybe<SortOrder>;
  driverLastName?: Maybe<SortOrder>;
  driverBirthdate?: Maybe<SortOrder>;
  driverCountry?: Maybe<SortOrder>;
  driverLicenceNumber?: Maybe<SortOrder>;
  driverExpirationAt?: Maybe<SortOrder>;
  driverDateOfIssueAt?: Maybe<SortOrder>;
  phoneForUpdate?: Maybe<SortOrder>;
  otpPassword?: Maybe<SortOrder>;
  forgottenPasswordAt?: Maybe<SortOrder>;
  forgottenPasswordHash?: Maybe<SortOrder>;
  loginBlockedAt?: Maybe<SortOrder>;
  stripeClientSecret?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeConfirmed?: Maybe<SortOrder>;
  stripeTwintConfimedAt?: Maybe<SortOrder>;
  stripeAccountId?: Maybe<SortOrder>;
  stripeAccountLink?: Maybe<SortOrder>;
  stripeAccountConfirmedAt?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<SortOrder>;
  stripePaymentMethodType?: Maybe<SortOrder>;
  presetPassword?: Maybe<SortOrder>;
  failedLoginAttempts?: Maybe<SortOrder>;
  phoneConfirmedAt?: Maybe<SortOrder>;
  userRole?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
  vehicles?: Maybe<VehicleOrderByRelationAggregateInput>;
  vehicleDamage?: Maybe<VehicleDamageOrderByRelationAggregateInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesOrderByRelationAggregateInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentOrderByRelationAggregateInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogOrderByRelationAggregateInput>;
  availability?: Maybe<AvailabilityOrderByRelationAggregateInput>;
  biometricToken?: Maybe<BiometricTokenOrderByRelationAggregateInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverOrderByRelationAggregateInput>;
  bookingRevokedByUser?: Maybe<BookingOrderByRelationAggregateInput>;
  booking?: Maybe<BookingOrderByRelationAggregateInput>;
  notificationDevices?: Maybe<NotificationDevicesOrderByRelationAggregateInput>;
  notificationsIsEnabledPush?: Maybe<SortOrder>;
  notificationsIsEnabledEmail?: Maybe<SortOrder>;
  notificationsIsEnabledSms?: Maybe<SortOrder>;
  bookingQuestion?: Maybe<BookingQuestionOrderByRelationAggregateInput>;
  bookingAnswer?: Maybe<BookingAnswerOrderByRelationAggregateInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupOrderByRelationAggregateInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserOrderByRelationAggregateInput>;
  message?: Maybe<MessageOrderByRelationAggregateInput>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationOrderByRelationAggregateInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationOrderByRelationAggregateInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionOrderByRelationAggregateInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserOrderByRelationAggregateInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionOrderByRelationAggregateInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffOrderByRelationAggregateInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryOrderByRelationAggregateInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserOrderByRelationAggregateInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  CompanyAdmin = 'COMPANY_ADMIN',
  User = 'USER',
}

export enum UserScalarFieldEnum {
  Id = 'id',
  Password = 'password',
  TokenSalt = 'tokenSalt',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Email = 'email',
  EmailConfirmedAt = 'emailConfirmedAt',
  PreDeletedAt = 'preDeletedAt',
  EmailVerificationHash = 'emailVerificationHash',
  Phone = 'phone',
  Birthdate = 'birthdate',
  Address = 'address',
  HouseNumber = 'houseNumber',
  PostCode = 'postCode',
  Gender = 'gender',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ResidencePermit = 'residencePermit',
  PxlVisionIdCode = 'pxlVisionIdCode',
  PxlVisionIdAt = 'pxlVisionIdAt',
  PxlVisionIdManuallyVerifiedById = 'pxlVisionIdManuallyVerifiedById',
  PxlVisionDriverLicenseCode = 'pxlVisionDriverLicenseCode',
  PxlVisionDriverLicenseManuallyVerifiedById = 'pxlVisionDriverLicenseManuallyVerifiedById',
  PxlVisionDriverLicenseAt = 'pxlVisionDriverLicenseAt',
  Language = 'language',
  Nationality = 'nationality',
  City = 'city',
  DriverFirstName = 'driverFirstName',
  DriverMiddleName = 'driverMiddleName',
  DriverLastName = 'driverLastName',
  DriverBirthdate = 'driverBirthdate',
  DriverCountry = 'driverCountry',
  DriverLicenceNumber = 'driverLicenceNumber',
  DriverExpirationAt = 'driverExpirationAt',
  DriverDateOfIssueAt = 'driverDateOfIssueAt',
  PhoneForUpdate = 'phoneForUpdate',
  OtpPassword = 'otpPassword',
  ForgottenPasswordAt = 'forgottenPasswordAt',
  ForgottenPasswordHash = 'forgottenPasswordHash',
  LoginBlockedAt = 'loginBlockedAt',
  StripeClientSecret = 'stripeClientSecret',
  StripeCustomerId = 'stripeCustomerId',
  StripeConfirmed = 'stripeConfirmed',
  StripeTwintConfimedAt = 'stripeTwintConfimedAt',
  StripeAccountId = 'stripeAccountId',
  StripeAccountLink = 'stripeAccountLink',
  StripeAccountConfirmedAt = 'stripeAccountConfirmedAt',
  StripePaymentMethod = 'stripePaymentMethod',
  StripePaymentMethodType = 'stripePaymentMethodType',
  PresetPassword = 'presetPassword',
  FailedLoginAttempts = 'failedLoginAttempts',
  PhoneConfirmedAt = 'phoneConfirmedAt',
  UserRole = 'userRole',
  CommissionScooters = 'commissionScooters',
  CommissionBookings = 'commissionBookings',
  CommissionChargingStations = 'commissionChargingStations',
  NotificationsIsEnabledPush = 'notificationsIsEnabledPush',
  NotificationsIsEnabledEmail = 'notificationsIsEnabledEmail',
  NotificationsIsEnabledSms = 'notificationsIsEnabledSms',
  CompanyId = 'companyId',
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  tokenSalt?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  middleName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  email?: Maybe<StringFilter>;
  emailConfirmedAt?: Maybe<DateTimeNullableFilter>;
  preDeletedAt?: Maybe<DateTimeNullableFilter>;
  emailVerificationHash?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  birthdate?: Maybe<DateTimeNullableFilter>;
  address?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringNullableFilter>;
  postCode?: Maybe<StringNullableFilter>;
  gender?: Maybe<EnumGenderTypeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  residencePermit?: Maybe<StringNullableFilter>;
  pxlVisionIdCode?: Maybe<StringNullableFilter>;
  pxlVisionIdAt?: Maybe<DateTimeNullableFilter>;
  pxlVisionIdManuallyVerifiedById?: Maybe<StringNullableFilter>;
  pxlVisionDriverLicenseCode?: Maybe<StringNullableFilter>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<StringNullableFilter>;
  pxlVisionDriverLicenseAt?: Maybe<DateTimeNullableFilter>;
  language?: Maybe<StringNullableFilter>;
  nationality?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  driverFirstName?: Maybe<StringNullableFilter>;
  driverMiddleName?: Maybe<StringNullableFilter>;
  driverLastName?: Maybe<StringNullableFilter>;
  driverBirthdate?: Maybe<DateTimeNullableFilter>;
  driverCountry?: Maybe<StringNullableFilter>;
  driverLicenceNumber?: Maybe<StringNullableFilter>;
  driverExpirationAt?: Maybe<DateTimeNullableFilter>;
  driverDateOfIssueAt?: Maybe<DateTimeNullableFilter>;
  phoneForUpdate?: Maybe<StringNullableFilter>;
  otpPassword?: Maybe<IntNullableFilter>;
  forgottenPasswordAt?: Maybe<DateTimeNullableFilter>;
  forgottenPasswordHash?: Maybe<StringNullableFilter>;
  loginBlockedAt?: Maybe<DateTimeNullableFilter>;
  stripeClientSecret?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeConfirmed?: Maybe<BoolNullableFilter>;
  stripeTwintConfimedAt?: Maybe<DateTimeNullableFilter>;
  stripeAccountId?: Maybe<StringNullableFilter>;
  stripeAccountLink?: Maybe<StringNullableFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentMethod?: Maybe<StringNullableFilter>;
  stripePaymentMethodType?: Maybe<EnumStripePaymentMethodTypeNullableFilter>;
  presetPassword?: Maybe<BoolNullableFilter>;
  failedLoginAttempts?: Maybe<IntFilter>;
  phoneConfirmedAt?: Maybe<DateTimeNullableFilter>;
  userRole?: Maybe<EnumUserRoleNullableFilter>;
  commissionScooters?: Maybe<FloatNullableFilter>;
  commissionBookings?: Maybe<FloatNullableFilter>;
  commissionChargingStations?: Maybe<FloatNullableFilter>;
  notificationsIsEnabledPush?: Maybe<BoolFilter>;
  notificationsIsEnabledEmail?: Maybe<BoolFilter>;
  notificationsIsEnabledSms?: Maybe<BoolFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  tokenSalt?: Maybe<StringWithAggregatesFilter>;
  firstName?: Maybe<StringNullableWithAggregatesFilter>;
  middleName?: Maybe<StringNullableWithAggregatesFilter>;
  lastName?: Maybe<StringNullableWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  emailConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  preDeletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  emailVerificationHash?: Maybe<StringNullableWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  birthdate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  address?: Maybe<StringNullableWithAggregatesFilter>;
  houseNumber?: Maybe<StringNullableWithAggregatesFilter>;
  postCode?: Maybe<StringNullableWithAggregatesFilter>;
  gender?: Maybe<EnumGenderTypeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  residencePermit?: Maybe<StringNullableWithAggregatesFilter>;
  pxlVisionIdCode?: Maybe<StringNullableWithAggregatesFilter>;
  pxlVisionIdAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pxlVisionIdManuallyVerifiedById?: Maybe<StringNullableWithAggregatesFilter>;
  pxlVisionDriverLicenseCode?: Maybe<StringNullableWithAggregatesFilter>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<StringNullableWithAggregatesFilter>;
  pxlVisionDriverLicenseAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  language?: Maybe<StringNullableWithAggregatesFilter>;
  nationality?: Maybe<StringNullableWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  driverFirstName?: Maybe<StringNullableWithAggregatesFilter>;
  driverMiddleName?: Maybe<StringNullableWithAggregatesFilter>;
  driverLastName?: Maybe<StringNullableWithAggregatesFilter>;
  driverBirthdate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  driverCountry?: Maybe<StringNullableWithAggregatesFilter>;
  driverLicenceNumber?: Maybe<StringNullableWithAggregatesFilter>;
  driverExpirationAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  driverDateOfIssueAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  phoneForUpdate?: Maybe<StringNullableWithAggregatesFilter>;
  otpPassword?: Maybe<IntNullableWithAggregatesFilter>;
  forgottenPasswordAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  forgottenPasswordHash?: Maybe<StringNullableWithAggregatesFilter>;
  loginBlockedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripeClientSecret?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeConfirmed?: Maybe<BoolNullableWithAggregatesFilter>;
  stripeTwintConfimedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripeAccountId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeAccountLink?: Maybe<StringNullableWithAggregatesFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripePaymentMethod?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentMethodType?: Maybe<EnumStripePaymentMethodTypeNullableWithAggregatesFilter>;
  presetPassword?: Maybe<BoolNullableWithAggregatesFilter>;
  failedLoginAttempts?: Maybe<IntWithAggregatesFilter>;
  phoneConfirmedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  userRole?: Maybe<EnumUserRoleNullableWithAggregatesFilter>;
  commissionScooters?: Maybe<FloatNullableWithAggregatesFilter>;
  commissionBookings?: Maybe<FloatNullableWithAggregatesFilter>;
  commissionChargingStations?: Maybe<FloatNullableWithAggregatesFilter>;
  notificationsIsEnabledPush?: Maybe<BoolWithAggregatesFilter>;
  notificationsIsEnabledEmail?: Maybe<BoolWithAggregatesFilter>;
  notificationsIsEnabledSms?: Maybe<BoolWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  otpPassword?: Maybe<Scalars['Int']>;
  failedLoginAttempts?: Maybe<Scalars['Int']>;
  commissionScooters?: Maybe<Scalars['Float']>;
  commissionBookings?: Maybe<Scalars['Float']>;
  commissionChargingStations?: Maybe<Scalars['Float']>;
};

export type UserSumOrderByAggregateInput = {
  otpPassword?: Maybe<SortOrder>;
  failedLoginAttempts?: Maybe<SortOrder>;
  commissionScooters?: Maybe<SortOrder>;
  commissionBookings?: Maybe<SortOrder>;
  commissionChargingStations?: Maybe<SortOrder>;
};

export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutChargingStationTariffsInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithWhereWithoutCompanyInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithWhereWithoutPxlVisionDriverLicenseManuallyVerifiedByInput =
  {
    where: UserScalarWhereInput;
    data: UserUpdateManyMutationInput;
  };

export type UserUpdateManyWithWhereWithoutPxlVisionIdManuallyVerifiedByInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithoutChargingStationTariffsInput = {
  create?: Maybe<Array<UserCreateWithoutChargingStationTariffsInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutChargingStationTariffsInput>
  >;
  upsert?: Maybe<
    Array<UserUpsertWithWhereUniqueWithoutChargingStationTariffsInput>
  >;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<
    Array<UserUpdateWithWhereUniqueWithoutChargingStationTariffsInput>
  >;
  updateMany?: Maybe<
    Array<UserUpdateManyWithWhereWithoutChargingStationTariffsInput>
  >;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<UserCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<UserCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutCompanyInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput =
  {
    create?: Maybe<
      Array<UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>
    >;
    connectOrCreate?: Maybe<
      Array<UserCreateOrConnectWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>
    >;
    upsert?: Maybe<
      Array<UserUpsertWithWhereUniqueWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>
    >;
    createMany?: Maybe<UserCreateManyPxlVisionDriverLicenseManuallyVerifiedByInputEnvelope>;
    set?: Maybe<Array<UserWhereUniqueInput>>;
    disconnect?: Maybe<Array<UserWhereUniqueInput>>;
    delete?: Maybe<Array<UserWhereUniqueInput>>;
    connect?: Maybe<Array<UserWhereUniqueInput>>;
    update?: Maybe<
      Array<UserUpdateWithWhereUniqueWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>
    >;
    updateMany?: Maybe<
      Array<UserUpdateManyWithWhereWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>
    >;
    deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  };

export type UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput = {
  create?: Maybe<Array<UserCreateWithoutPxlVisionIdManuallyVerifiedByInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutPxlVisionIdManuallyVerifiedByInput>
  >;
  upsert?: Maybe<
    Array<UserUpsertWithWhereUniqueWithoutPxlVisionIdManuallyVerifiedByInput>
  >;
  createMany?: Maybe<UserCreateManyPxlVisionIdManuallyVerifiedByInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<
    Array<UserUpdateWithWhereUniqueWithoutPxlVisionIdManuallyVerifiedByInput>
  >;
  updateMany?: Maybe<
    Array<UserUpdateManyWithWhereWithoutPxlVisionIdManuallyVerifiedByInput>
  >;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateOneRequiredWithoutAvailabilityInput = {
  create?: Maybe<UserCreateWithoutAvailabilityInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAvailabilityInput>;
  upsert?: Maybe<UserUpsertWithoutAvailabilityInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutAvailabilityInput>;
};

export type UserUpdateOneRequiredWithoutBiometricTokenInput = {
  create?: Maybe<UserCreateWithoutBiometricTokenInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBiometricTokenInput>;
  upsert?: Maybe<UserUpsertWithoutBiometricTokenInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBiometricTokenInput>;
};

export type UserUpdateOneRequiredWithoutBookingChangeHistoryInput = {
  create?: Maybe<UserCreateWithoutBookingChangeHistoryInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingChangeHistoryInput>;
  upsert?: Maybe<UserUpsertWithoutBookingChangeHistoryInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBookingChangeHistoryInput>;
};

export type UserUpdateOneRequiredWithoutBookingVehicleDamagesInput = {
  create?: Maybe<UserCreateWithoutBookingVehicleDamagesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingVehicleDamagesInput>;
  upsert?: Maybe<UserUpsertWithoutBookingVehicleDamagesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBookingVehicleDamagesInput>;
};

export type UserUpdateOneRequiredWithoutPxlVisionManualVerificationsInput = {
  create?: Maybe<UserCreateWithoutPxlVisionManualVerificationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionManualVerificationsInput>;
  upsert?: Maybe<UserUpsertWithoutPxlVisionManualVerificationsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutPxlVisionManualVerificationsInput>;
};

export type UserUpdateOneRequiredWithoutVehicleDamageCommentInput = {
  create?: Maybe<UserCreateWithoutVehicleDamageCommentInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehicleDamageCommentInput>;
  upsert?: Maybe<UserUpsertWithoutVehicleDamageCommentInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutVehicleDamageCommentInput>;
};

export type UserUpdateOneWithoutBookingAdditionalDriverInput = {
  create?: Maybe<UserCreateWithoutBookingAdditionalDriverInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingAdditionalDriverInput>;
  upsert?: Maybe<UserUpsertWithoutBookingAdditionalDriverInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBookingAdditionalDriverInput>;
};

export type UserUpdateOneWithoutBookingAnswerInput = {
  create?: Maybe<UserCreateWithoutBookingAnswerInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingAnswerInput>;
  upsert?: Maybe<UserUpsertWithoutBookingAnswerInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBookingAnswerInput>;
};

export type UserUpdateOneWithoutBookingInput = {
  create?: Maybe<UserCreateWithoutBookingInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingInput>;
  upsert?: Maybe<UserUpsertWithoutBookingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBookingInput>;
};

export type UserUpdateOneWithoutBookingQuestionInput = {
  create?: Maybe<UserCreateWithoutBookingQuestionInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingQuestionInput>;
  upsert?: Maybe<UserUpsertWithoutBookingQuestionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBookingQuestionInput>;
};

export type UserUpdateOneWithoutBookingRevokedByUserInput = {
  create?: Maybe<UserCreateWithoutBookingRevokedByUserInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBookingRevokedByUserInput>;
  upsert?: Maybe<UserUpsertWithoutBookingRevokedByUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutBookingRevokedByUserInput>;
};

export type UserUpdateOneWithoutChargingStationTransactionsInput = {
  create?: Maybe<UserCreateWithoutChargingStationTransactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChargingStationTransactionsInput>;
  upsert?: Maybe<UserUpsertWithoutChargingStationTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutChargingStationTransactionsInput>;
};

export type UserUpdateOneWithoutGateControllerRelUsersInput = {
  create?: Maybe<UserCreateWithoutGateControllerRelUsersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGateControllerRelUsersInput>;
  upsert?: Maybe<UserUpsertWithoutGateControllerRelUsersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutGateControllerRelUsersInput>;
};

export type UserUpdateOneWithoutGateControllerTransactionsInput = {
  create?: Maybe<UserCreateWithoutGateControllerTransactionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGateControllerTransactionsInput>;
  upsert?: Maybe<UserUpsertWithoutGateControllerTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutGateControllerTransactionsInput>;
};

export type UserUpdateOneWithoutMessageInput = {
  create?: Maybe<UserCreateWithoutMessageInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageInput>;
  upsert?: Maybe<UserUpsertWithoutMessageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutMessageInput>;
};

export type UserUpdateOneWithoutNotificationDevicesInput = {
  create?: Maybe<UserCreateWithoutNotificationDevicesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutNotificationDevicesInput>;
  upsert?: Maybe<UserUpsertWithoutNotificationDevicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutNotificationDevicesInput>;
};

export type UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput = {
  create?: Maybe<UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  upsert?: Maybe<UserUpsertWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
};

export type UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput = {
  create?: Maybe<UserCreateWithoutPxlVisionIdManuallyVerifiedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionIdManuallyVerifiedInput>;
  upsert?: Maybe<UserUpsertWithoutPxlVisionIdManuallyVerifiedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutPxlVisionIdManuallyVerifiedInput>;
};

export type UserUpdateOneWithoutPxlVisionManualVerifiedInput = {
  create?: Maybe<UserCreateWithoutPxlVisionManualVerifiedInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPxlVisionManualVerifiedInput>;
  upsert?: Maybe<UserUpsertWithoutPxlVisionManualVerifiedInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutPxlVisionManualVerifiedInput>;
};

export type UserUpdateOneWithoutSharedVehicleGroupInput = {
  create?: Maybe<UserCreateWithoutSharedVehicleGroupInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSharedVehicleGroupInput>;
  upsert?: Maybe<UserUpsertWithoutSharedVehicleGroupInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutSharedVehicleGroupInput>;
};

export type UserUpdateOneWithoutSharedVehicleGroupRelUserInput = {
  create?: Maybe<UserCreateWithoutSharedVehicleGroupRelUserInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSharedVehicleGroupRelUserInput>;
  upsert?: Maybe<UserUpsertWithoutSharedVehicleGroupRelUserInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutSharedVehicleGroupRelUserInput>;
};

export type UserUpdateOneWithoutVehicleCloudBoxxStatusLogInput = {
  create?: Maybe<UserCreateWithoutVehicleCloudBoxxStatusLogInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehicleCloudBoxxStatusLogInput>;
  upsert?: Maybe<UserUpsertWithoutVehicleCloudBoxxStatusLogInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutVehicleCloudBoxxStatusLogInput>;
};

export type UserUpdateOneWithoutVehicleDamageInput = {
  create?: Maybe<UserCreateWithoutVehicleDamageInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehicleDamageInput>;
  upsert?: Maybe<UserUpsertWithoutVehicleDamageInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutVehicleDamageInput>;
};

export type UserUpdateOneWithoutVehiclesInput = {
  create?: Maybe<UserCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVehiclesInput>;
  upsert?: Maybe<UserUpsertWithoutVehiclesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutVehiclesInput>;
};

export type UserUpdateWithWhereUniqueWithoutChargingStationTariffsInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutChargingStationTariffsInput;
};

export type UserUpdateWithWhereUniqueWithoutCompanyInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutCompanyInput;
};

export type UserUpdateWithWhereUniqueWithoutPxlVisionDriverLicenseManuallyVerifiedByInput =
  {
    where: UserWhereUniqueInput;
    data: UserUpdateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput;
  };

export type UserUpdateWithWhereUniqueWithoutPxlVisionIdManuallyVerifiedByInput =
  {
    where: UserWhereUniqueInput;
    data: UserUpdateWithoutPxlVisionIdManuallyVerifiedByInput;
  };

export type UserUpdateWithoutAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBiometricTokenInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBookingAdditionalDriverInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBookingAnswerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBookingChangeHistoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBookingQuestionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBookingRevokedByUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutBookingVehicleDamagesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutChargingStationTariffsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutChargingStationTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutGateControllerRelUsersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutGateControllerTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutMessageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutNotificationDevicesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutPxlVisionDriverLicenseManuallyVerifiedInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
};

export type UserUpdateWithoutPxlVisionIdManuallyVerifiedByInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutPxlVisionIdManuallyVerifiedInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutPxlVisionManualVerificationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutPxlVisionManualVerifiedInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutSharedVehicleGroupInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutSharedVehicleGroupRelUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutVehicleCloudBoxxStatusLogInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutVehicleDamageCommentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutVehicleDamageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpdateWithoutVehiclesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  emailConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preDeletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerificationHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  residencePermit?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionIdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionIdManuallyVerifiedInput>;
  pxlVisionDriverLicenseCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserUpdateOneWithoutPxlVisionDriverLicenseManuallyVerifiedInput>;
  pxlVisionDriverLicenseAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nationality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverFirstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverMiddleName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverBirthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverCountry?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverLicenceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverExpirationAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  driverDateOfIssueAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  phoneForUpdate?: Maybe<NullableStringFieldUpdateOperationsInput>;
  otpPassword?: Maybe<NullableIntFieldUpdateOperationsInput>;
  forgottenPasswordAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  forgottenPasswordHash?: Maybe<NullableStringFieldUpdateOperationsInput>;
  loginBlockedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeClientSecret?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeConfirmed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stripeTwintConfimedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripeAccountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountLink?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripeAccountConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  stripePaymentMethod?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stripePaymentMethodType?: Maybe<NullableEnumStripePaymentMethodTypeFieldUpdateOperationsInput>;
  presetPassword?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  failedLoginAttempts?: Maybe<IntFieldUpdateOperationsInput>;
  phoneConfirmedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  userRole?: Maybe<NullableEnumUserRoleFieldUpdateOperationsInput>;
  commissionScooters?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionBookings?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  commissionChargingStations?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutUserInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutUserInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutUserInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutUserInput>;
  biometricToken?: Maybe<BiometricTokenUpdateManyWithoutUserInput>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverUpdateManyWithoutUserInput>;
  bookingRevokedByUser?: Maybe<BookingUpdateManyWithoutRevokedByUserInput>;
  booking?: Maybe<BookingUpdateManyWithoutUserInput>;
  notificationDevices?: Maybe<NotificationDevicesUpdateManyWithoutUserInput>;
  notificationsIsEnabledPush?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledEmail?: Maybe<BoolFieldUpdateOperationsInput>;
  notificationsIsEnabledSms?: Maybe<BoolFieldUpdateOperationsInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutUserInput>;
  bookingAnswer?: Maybe<BookingAnswerUpdateManyWithoutUserInput>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupUpdateManyWithoutCreatedByInput>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserUpdateManyWithoutUserInput>;
  message?: Maybe<MessageUpdateManyWithoutUserInput>;
  company?: Maybe<CompanyUpdateOneWithoutUserInput>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationUpdateManyWithoutUserInput>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationUpdateManyWithoutVerifiedByUserInput>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionUpdateManyWithoutUserInput>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserUpdateManyWithoutUserInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutUserInput>;
  chargingStationTariffs?: Maybe<ChargingStationTariffUpdateManyWithoutUsersInput>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryUpdateManyWithoutCreatedByUserInput>;
  pxlVisionIdManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionIdManuallyVerifiedByInput>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserUpdateManyWithoutPxlVisionDriverLicenseManuallyVerifiedByInput>;
};

export type UserUpsertWithWhereUniqueWithoutChargingStationTariffsInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutChargingStationTariffsInput;
  create: UserCreateWithoutChargingStationTariffsInput;
};

export type UserUpsertWithWhereUniqueWithoutCompanyInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutCompanyInput;
  create: UserCreateWithoutCompanyInput;
};

export type UserUpsertWithWhereUniqueWithoutPxlVisionDriverLicenseManuallyVerifiedByInput =
  {
    where: UserWhereUniqueInput;
    update: UserUpdateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput;
    create: UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedByInput;
  };

export type UserUpsertWithWhereUniqueWithoutPxlVisionIdManuallyVerifiedByInput =
  {
    where: UserWhereUniqueInput;
    update: UserUpdateWithoutPxlVisionIdManuallyVerifiedByInput;
    create: UserCreateWithoutPxlVisionIdManuallyVerifiedByInput;
  };

export type UserUpsertWithoutAvailabilityInput = {
  update: UserUpdateWithoutAvailabilityInput;
  create: UserCreateWithoutAvailabilityInput;
};

export type UserUpsertWithoutBiometricTokenInput = {
  update: UserUpdateWithoutBiometricTokenInput;
  create: UserCreateWithoutBiometricTokenInput;
};

export type UserUpsertWithoutBookingAdditionalDriverInput = {
  update: UserUpdateWithoutBookingAdditionalDriverInput;
  create: UserCreateWithoutBookingAdditionalDriverInput;
};

export type UserUpsertWithoutBookingAnswerInput = {
  update: UserUpdateWithoutBookingAnswerInput;
  create: UserCreateWithoutBookingAnswerInput;
};

export type UserUpsertWithoutBookingChangeHistoryInput = {
  update: UserUpdateWithoutBookingChangeHistoryInput;
  create: UserCreateWithoutBookingChangeHistoryInput;
};

export type UserUpsertWithoutBookingInput = {
  update: UserUpdateWithoutBookingInput;
  create: UserCreateWithoutBookingInput;
};

export type UserUpsertWithoutBookingQuestionInput = {
  update: UserUpdateWithoutBookingQuestionInput;
  create: UserCreateWithoutBookingQuestionInput;
};

export type UserUpsertWithoutBookingRevokedByUserInput = {
  update: UserUpdateWithoutBookingRevokedByUserInput;
  create: UserCreateWithoutBookingRevokedByUserInput;
};

export type UserUpsertWithoutBookingVehicleDamagesInput = {
  update: UserUpdateWithoutBookingVehicleDamagesInput;
  create: UserCreateWithoutBookingVehicleDamagesInput;
};

export type UserUpsertWithoutChargingStationTransactionsInput = {
  update: UserUpdateWithoutChargingStationTransactionsInput;
  create: UserCreateWithoutChargingStationTransactionsInput;
};

export type UserUpsertWithoutGateControllerRelUsersInput = {
  update: UserUpdateWithoutGateControllerRelUsersInput;
  create: UserCreateWithoutGateControllerRelUsersInput;
};

export type UserUpsertWithoutGateControllerTransactionsInput = {
  update: UserUpdateWithoutGateControllerTransactionsInput;
  create: UserCreateWithoutGateControllerTransactionsInput;
};

export type UserUpsertWithoutMessageInput = {
  update: UserUpdateWithoutMessageInput;
  create: UserCreateWithoutMessageInput;
};

export type UserUpsertWithoutNotificationDevicesInput = {
  update: UserUpdateWithoutNotificationDevicesInput;
  create: UserCreateWithoutNotificationDevicesInput;
};

export type UserUpsertWithoutPxlVisionDriverLicenseManuallyVerifiedInput = {
  update: UserUpdateWithoutPxlVisionDriverLicenseManuallyVerifiedInput;
  create: UserCreateWithoutPxlVisionDriverLicenseManuallyVerifiedInput;
};

export type UserUpsertWithoutPxlVisionIdManuallyVerifiedInput = {
  update: UserUpdateWithoutPxlVisionIdManuallyVerifiedInput;
  create: UserCreateWithoutPxlVisionIdManuallyVerifiedInput;
};

export type UserUpsertWithoutPxlVisionManualVerificationsInput = {
  update: UserUpdateWithoutPxlVisionManualVerificationsInput;
  create: UserCreateWithoutPxlVisionManualVerificationsInput;
};

export type UserUpsertWithoutPxlVisionManualVerifiedInput = {
  update: UserUpdateWithoutPxlVisionManualVerifiedInput;
  create: UserCreateWithoutPxlVisionManualVerifiedInput;
};

export type UserUpsertWithoutSharedVehicleGroupInput = {
  update: UserUpdateWithoutSharedVehicleGroupInput;
  create: UserCreateWithoutSharedVehicleGroupInput;
};

export type UserUpsertWithoutSharedVehicleGroupRelUserInput = {
  update: UserUpdateWithoutSharedVehicleGroupRelUserInput;
  create: UserCreateWithoutSharedVehicleGroupRelUserInput;
};

export type UserUpsertWithoutVehicleCloudBoxxStatusLogInput = {
  update: UserUpdateWithoutVehicleCloudBoxxStatusLogInput;
  create: UserCreateWithoutVehicleCloudBoxxStatusLogInput;
};

export type UserUpsertWithoutVehicleDamageCommentInput = {
  update: UserUpdateWithoutVehicleDamageCommentInput;
  create: UserCreateWithoutVehicleDamageCommentInput;
};

export type UserUpsertWithoutVehicleDamageInput = {
  update: UserUpdateWithoutVehicleDamageInput;
  create: UserCreateWithoutVehicleDamageInput;
};

export type UserUpsertWithoutVehiclesInput = {
  update: UserUpdateWithoutVehiclesInput;
  create: UserCreateWithoutVehiclesInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  tokenSalt?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  middleName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  email?: Maybe<StringFilter>;
  emailConfirmedAt?: Maybe<DateTimeNullableFilter>;
  preDeletedAt?: Maybe<DateTimeNullableFilter>;
  emailVerificationHash?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  birthdate?: Maybe<DateTimeNullableFilter>;
  address?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringNullableFilter>;
  postCode?: Maybe<StringNullableFilter>;
  gender?: Maybe<EnumGenderTypeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  residencePermit?: Maybe<StringNullableFilter>;
  pxlVisionIdCode?: Maybe<StringNullableFilter>;
  pxlVisionIdAt?: Maybe<DateTimeNullableFilter>;
  pxlVisionIdManuallyVerifiedBy?: Maybe<UserRelationFilter>;
  pxlVisionIdManuallyVerifiedById?: Maybe<StringNullableFilter>;
  pxlVisionDriverLicenseCode?: Maybe<StringNullableFilter>;
  pxlVisionDriverLicenseManuallyVerifiedBy?: Maybe<UserRelationFilter>;
  pxlVisionDriverLicenseManuallyVerifiedById?: Maybe<StringNullableFilter>;
  pxlVisionDriverLicenseAt?: Maybe<DateTimeNullableFilter>;
  language?: Maybe<StringNullableFilter>;
  nationality?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  driverFirstName?: Maybe<StringNullableFilter>;
  driverMiddleName?: Maybe<StringNullableFilter>;
  driverLastName?: Maybe<StringNullableFilter>;
  driverBirthdate?: Maybe<DateTimeNullableFilter>;
  driverCountry?: Maybe<StringNullableFilter>;
  driverLicenceNumber?: Maybe<StringNullableFilter>;
  driverExpirationAt?: Maybe<DateTimeNullableFilter>;
  driverDateOfIssueAt?: Maybe<DateTimeNullableFilter>;
  phoneForUpdate?: Maybe<StringNullableFilter>;
  otpPassword?: Maybe<IntNullableFilter>;
  forgottenPasswordAt?: Maybe<DateTimeNullableFilter>;
  forgottenPasswordHash?: Maybe<StringNullableFilter>;
  loginBlockedAt?: Maybe<DateTimeNullableFilter>;
  stripeClientSecret?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeConfirmed?: Maybe<BoolNullableFilter>;
  stripeTwintConfimedAt?: Maybe<DateTimeNullableFilter>;
  stripeAccountId?: Maybe<StringNullableFilter>;
  stripeAccountLink?: Maybe<StringNullableFilter>;
  stripeAccountConfirmedAt?: Maybe<DateTimeNullableFilter>;
  stripePaymentMethod?: Maybe<StringNullableFilter>;
  stripePaymentMethodType?: Maybe<EnumStripePaymentMethodTypeNullableFilter>;
  presetPassword?: Maybe<BoolNullableFilter>;
  failedLoginAttempts?: Maybe<IntFilter>;
  phoneConfirmedAt?: Maybe<DateTimeNullableFilter>;
  userRole?: Maybe<EnumUserRoleNullableFilter>;
  commissionScooters?: Maybe<FloatNullableFilter>;
  commissionBookings?: Maybe<FloatNullableFilter>;
  commissionChargingStations?: Maybe<FloatNullableFilter>;
  vehicles?: Maybe<VehicleListRelationFilter>;
  vehicleDamage?: Maybe<VehicleDamageListRelationFilter>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesListRelationFilter>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentListRelationFilter>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogListRelationFilter>;
  availability?: Maybe<AvailabilityListRelationFilter>;
  biometricToken?: Maybe<BiometricTokenListRelationFilter>;
  bookingAdditionalDriver?: Maybe<BookingAdditionalDriverListRelationFilter>;
  bookingRevokedByUser?: Maybe<BookingListRelationFilter>;
  booking?: Maybe<BookingListRelationFilter>;
  notificationDevices?: Maybe<NotificationDevicesListRelationFilter>;
  notificationsIsEnabledPush?: Maybe<BoolFilter>;
  notificationsIsEnabledEmail?: Maybe<BoolFilter>;
  notificationsIsEnabledSms?: Maybe<BoolFilter>;
  bookingQuestion?: Maybe<BookingQuestionListRelationFilter>;
  bookingAnswer?: Maybe<BookingAnswerListRelationFilter>;
  sharedVehicleGroup?: Maybe<SharedVehicleGroupListRelationFilter>;
  sharedVehicleGroupRelUser?: Maybe<SharedVehicleGroupRelUserListRelationFilter>;
  message?: Maybe<MessageListRelationFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
  pxlVisionManualVerifications?: Maybe<PxlVisionManualVerificationListRelationFilter>;
  pxlVisionManualVerified?: Maybe<PxlVisionManualVerificationListRelationFilter>;
  chargingStationTransactions?: Maybe<ChargingStationTransactionListRelationFilter>;
  gateControllerRelUsers?: Maybe<GateControllerRelUserListRelationFilter>;
  gateControllerTransactions?: Maybe<GateControllerTransactionListRelationFilter>;
  chargingStationTariffs?: Maybe<ChargingStationTariffListRelationFilter>;
  bookingChangeHistory?: Maybe<BookingChangeHistoryListRelationFilter>;
  pxlVisionIdManuallyVerified?: Maybe<UserListRelationFilter>;
  pxlVisionDriverLicenseManuallyVerified?: Maybe<UserListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  id: Scalars['String'];
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts: Scalars['Boolean'];
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  ownInsurance: Scalars['Boolean'];
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType: VehicleConnectionType;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleCount>;
  user?: Maybe<User>;
  vehicleDamage: Array<VehicleDamage>;
  fuelType?: Maybe<FuelType>;
  doorType?: Maybe<DoorType>;
  vehicleType?: Maybe<VehicleType>;
  vehicleKindType?: Maybe<VehicleKindType>;
  transmissionType?: Maybe<TransmissionType>;
  driveType?: Maybe<DriveType>;
  seatType?: Maybe<SeatType>;
  seatFrontType?: Maybe<SeatFrontType>;
  childSeatTypes: Array<ChildSeatType>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyType>;
  emissionCodeType?: Maybe<EmissionCodeType>;
  consumptionType?: Maybe<ConsumptionType>;
  shieldColorType?: Maybe<ShieldColorType>;
  particularUseType?: Maybe<ParticularUseType>;
  brandType?: Maybe<BrandType>;
  modelType?: Maybe<ModelType>;
  bodyType?: Maybe<BodyType>;
  colorType?: Maybe<ColorType>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  company?: Maybe<Company>;
  vehiclePhotos: Array<VehiclePhoto>;
  bookings: Array<Booking>;
  bookingVehicleDamages: Array<BookingVehicleDamages>;
  vehicleEquipment: Array<VehicleEquipment>;
  vehicleCloudBoxxLog: Array<VehicleCloudBoxxLog>;
  vehicleLocation: Array<VehicleLocation>;
  vehicleCloudBoxxStatusLog: Array<VehicleCloudBoxxStatusLog>;
  availability: Array<Availability>;
  bookingQuestion: Array<BookingQuestion>;
  sharedVehicleGroupRelVehicle: Array<SharedVehicleGroupRelVehicle>;
  gateControllerRelVehicles: Array<GateControllerRelVehicle>;
  gateControllerTransactions: Array<GateControllerTransaction>;
  smartcarAuth: Array<SmartcarAuth>;
  vehicleConnection: Array<VehicleConnection>;
  defaultVehicleImage?: Maybe<Scalars['String']>;
  smartcarConnectAuthUrl?: Maybe<Scalars['String']>;
  isOwnerStripeOnboarded?: Maybe<Scalars['Boolean']>;
};

export type VehicleVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageScalarFieldEnum>>;
};

export type VehicleChildSeatTypesArgs = {
  where?: Maybe<ChildSeatTypeWhereInput>;
  orderBy?: Maybe<Array<ChildSeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ChildSeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChildSeatTypeScalarFieldEnum>>;
};

export type VehicleVehiclePhotosArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
  orderBy?: Maybe<Array<VehiclePhotoOrderByWithRelationInput>>;
  cursor?: Maybe<VehiclePhotoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehiclePhotoScalarFieldEnum>>;
};

export type VehicleBookingsArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type VehicleBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
  orderBy?: Maybe<Array<BookingVehicleDamagesOrderByWithRelationInput>>;
  cursor?: Maybe<BookingVehicleDamagesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingVehicleDamagesScalarFieldEnum>>;
};

export type VehicleVehicleEquipmentArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
  orderBy?: Maybe<Array<VehicleEquipmentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleEquipmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleEquipmentScalarFieldEnum>>;
};

export type VehicleVehicleCloudBoxxLogArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxLogScalarFieldEnum>>;
};

export type VehicleVehicleLocationArgs = {
  where?: Maybe<VehicleLocationWhereInput>;
  orderBy?: Maybe<Array<VehicleLocationOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleLocationScalarFieldEnum>>;
};

export type VehicleVehicleCloudBoxxStatusLogArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxStatusLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxStatusLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxStatusLogScalarFieldEnum>>;
};

export type VehicleAvailabilityArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<AvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AvailabilityScalarFieldEnum>>;
};

export type VehicleBookingQuestionArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
  orderBy?: Maybe<Array<BookingQuestionOrderByWithRelationInput>>;
  cursor?: Maybe<BookingQuestionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingQuestionScalarFieldEnum>>;
};

export type VehicleSharedVehicleGroupRelVehicleArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelVehicleScalarFieldEnum>>;
};

export type VehicleGateControllerRelVehiclesArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelVehicleScalarFieldEnum>>;
};

export type VehicleGateControllerTransactionsArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTransactionScalarFieldEnum>>;
};

export type VehicleSmartcarAuthArgs = {
  where?: Maybe<SmartcarAuthWhereInput>;
  orderBy?: Maybe<Array<SmartcarAuthOrderByWithRelationInput>>;
  cursor?: Maybe<SmartcarAuthWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SmartcarAuthScalarFieldEnum>>;
};

export type VehicleVehicleConnectionArgs = {
  where?: Maybe<VehicleConnectionWhereInput>;
  orderBy?: Maybe<Array<VehicleConnectionOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleConnectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleConnectionScalarFieldEnum>>;
};

export type VehicleAvailabilityDto = {
  __typename?: 'VehicleAvailabilityDto';
  availability: Array<Availability>;
  bookings: Array<Booking>;
};

export type VehicleAvgAggregate = {
  __typename?: 'VehicleAvgAggregate';
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
};

export type VehicleAvgOrderByAggregateInput = {
  capacity?: Maybe<SortOrder>;
  power?: Maybe<SortOrder>;
  curbWeight?: Maybe<SortOrder>;
  capacityPerKg?: Maybe<SortOrder>;
  payloadWeight?: Maybe<SortOrder>;
  grossWeight?: Maybe<SortOrder>;
  trainWeight?: Maybe<SortOrder>;
  towingWeight?: Maybe<SortOrder>;
  roofLoadWeight?: Maybe<SortOrder>;
  catalogPrice?: Maybe<SortOrder>;
  initialOdometer?: Maybe<SortOrder>;
  valueOfAccessories?: Maybe<SortOrder>;
  currentLong?: Maybe<SortOrder>;
  currentLat?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  preBookingInterval?: Maybe<SortOrder>;
  postBookingInterval?: Maybe<SortOrder>;
  earlyOpenInterval?: Maybe<SortOrder>;
};

export type VehicleCalculation = {
  __typename?: 'VehicleCalculation';
  totalCost?: Maybe<Scalars['Float']>;
  totalCostWithoutInsurance?: Maybe<Scalars['Float']>;
  costForDuration?: Maybe<Scalars['Float']>;
  costForDistance?: Maybe<Scalars['Float']>;
  includedDistance_km?: Maybe<Scalars['Float']>;
  insuranceCost?: Maybe<Scalars['Float']>;
  totalHourlyCost?: Maybe<Scalars['Float']>;
  totalDailyCost?: Maybe<Scalars['Float']>;
  totalWeeklyCost?: Maybe<Scalars['Float']>;
  totalHours?: Maybe<Scalars['Float']>;
  totalDays?: Maybe<Scalars['Float']>;
  totalWeeks?: Maybe<Scalars['Float']>;
  deductibleReduction?: Maybe<Scalars['Float']>;
  excessKm?: Maybe<Scalars['Float']>;
  last4Digits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxConnectResponse = {
  __typename?: 'VehicleCloudBoxxConnectResponse';
  isActivated: Scalars['Boolean'];
  qnr: Scalars['String'];
};

export type VehicleCloudBoxxLog = {
  __typename?: 'VehicleCloudBoxxLog';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  content: Scalars['String'];
  vehicleId: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
  vehicle: Vehicle;
  booking?: Maybe<Booking>;
};

export type VehicleCloudBoxxLogCountAggregate = {
  __typename?: 'VehicleCloudBoxxLogCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  content: Scalars['Int'];
  vehicleId: Scalars['Int'];
  bookingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleCloudBoxxLogCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxLogCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  vehicle: VehicleCreateNestedOneWithoutVehicleCloudBoxxLogInput;
  booking?: Maybe<BookingCreateNestedOneWithoutVehicleCloudBoxxLogInput>;
};

export type VehicleCloudBoxxLogCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  vehicleId: Scalars['String'];
};

export type VehicleCloudBoxxLogCreateManyBookingInputEnvelope = {
  data: Array<VehicleCloudBoxxLogCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCloudBoxxLogCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  vehicleId: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxLogCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxLogCreateManyVehicleInputEnvelope = {
  data: Array<VehicleCloudBoxxLogCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCloudBoxxLogCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<VehicleCloudBoxxLogCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxLogCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxLogCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
};

export type VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleCloudBoxxLogCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxLogCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxLogCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
};

export type VehicleCloudBoxxLogCreateOrConnectWithoutBookingInput = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
  create: VehicleCloudBoxxLogCreateWithoutBookingInput;
};

export type VehicleCloudBoxxLogCreateOrConnectWithoutVehicleInput = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
  create: VehicleCloudBoxxLogCreateWithoutVehicleInput;
};

export type VehicleCloudBoxxLogCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  vehicle: VehicleCreateNestedOneWithoutVehicleCloudBoxxLogInput;
};

export type VehicleCloudBoxxLogCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content: Scalars['String'];
  booking?: Maybe<BookingCreateNestedOneWithoutVehicleCloudBoxxLogInput>;
};

export type VehicleCloudBoxxLogGroupBy = {
  __typename?: 'VehicleCloudBoxxLogGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  content: Scalars['String'];
  vehicleId: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleCloudBoxxLogCountAggregate>;
  _min?: Maybe<VehicleCloudBoxxLogMinAggregate>;
  _max?: Maybe<VehicleCloudBoxxLogMaxAggregate>;
};

export type VehicleCloudBoxxLogListRelationFilter = {
  every?: Maybe<VehicleCloudBoxxLogWhereInput>;
  some?: Maybe<VehicleCloudBoxxLogWhereInput>;
  none?: Maybe<VehicleCloudBoxxLogWhereInput>;
};

export type VehicleCloudBoxxLogMaxAggregate = {
  __typename?: 'VehicleCloudBoxxLogMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxLogMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxLogMinAggregate = {
  __typename?: 'VehicleCloudBoxxLogMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxLogMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxLogOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  _count?: Maybe<VehicleCloudBoxxLogCountOrderByAggregateInput>;
  _max?: Maybe<VehicleCloudBoxxLogMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleCloudBoxxLogMinOrderByAggregateInput>;
};

export type VehicleCloudBoxxLogOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
};

export enum VehicleCloudBoxxLogScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Content = 'content',
  VehicleId = 'vehicleId',
  BookingId = 'bookingId',
}

export type VehicleCloudBoxxLogScalarWhereInput = {
  AND?: Maybe<Array<VehicleCloudBoxxLogScalarWhereInput>>;
  OR?: Maybe<Array<VehicleCloudBoxxLogScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleCloudBoxxLogScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  content?: Maybe<StringFilter>;
  vehicleId?: Maybe<StringFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type VehicleCloudBoxxLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleCloudBoxxLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleCloudBoxxLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleCloudBoxxLogScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  content?: Maybe<StringWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  bookingId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VehicleCloudBoxxLogUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleCloudBoxxLogInput>;
  booking?: Maybe<BookingUpdateOneWithoutVehicleCloudBoxxLogInput>;
};

export type VehicleCloudBoxxLogUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
};

export type VehicleCloudBoxxLogUpdateManyWithWhereWithoutBookingInput = {
  where: VehicleCloudBoxxLogScalarWhereInput;
  data: VehicleCloudBoxxLogUpdateManyMutationInput;
};

export type VehicleCloudBoxxLogUpdateManyWithWhereWithoutVehicleInput = {
  where: VehicleCloudBoxxLogScalarWhereInput;
  data: VehicleCloudBoxxLogUpdateManyMutationInput;
};

export type VehicleCloudBoxxLogUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<VehicleCloudBoxxLogCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxLogCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<
    Array<VehicleCloudBoxxLogUpsertWithWhereUniqueWithoutBookingInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxLogCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleCloudBoxxLogUpdateWithWhereUniqueWithoutBookingInput>
  >;
  updateMany?: Maybe<
    Array<VehicleCloudBoxxLogUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<VehicleCloudBoxxLogScalarWhereInput>>;
};

export type VehicleCloudBoxxLogUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleCloudBoxxLogCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxLogCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<VehicleCloudBoxxLogUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxLogCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleCloudBoxxLogWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleCloudBoxxLogUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<VehicleCloudBoxxLogUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<VehicleCloudBoxxLogScalarWhereInput>>;
};

export type VehicleCloudBoxxLogUpdateWithWhereUniqueWithoutBookingInput = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
  data: VehicleCloudBoxxLogUpdateWithoutBookingInput;
};

export type VehicleCloudBoxxLogUpdateWithWhereUniqueWithoutVehicleInput = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
  data: VehicleCloudBoxxLogUpdateWithoutVehicleInput;
};

export type VehicleCloudBoxxLogUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleCloudBoxxLogInput>;
};

export type VehicleCloudBoxxLogUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateOneWithoutVehicleCloudBoxxLogInput>;
};

export type VehicleCloudBoxxLogUpsertWithWhereUniqueWithoutBookingInput = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
  update: VehicleCloudBoxxLogUpdateWithoutBookingInput;
  create: VehicleCloudBoxxLogCreateWithoutBookingInput;
};

export type VehicleCloudBoxxLogUpsertWithWhereUniqueWithoutVehicleInput = {
  where: VehicleCloudBoxxLogWhereUniqueInput;
  update: VehicleCloudBoxxLogUpdateWithoutVehicleInput;
  create: VehicleCloudBoxxLogCreateWithoutVehicleInput;
};

export type VehicleCloudBoxxLogWhereInput = {
  AND?: Maybe<Array<VehicleCloudBoxxLogWhereInput>>;
  OR?: Maybe<Array<VehicleCloudBoxxLogWhereInput>>;
  NOT?: Maybe<Array<VehicleCloudBoxxLogWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  content?: Maybe<StringFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type VehicleCloudBoxxLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxStatusLog = {
  __typename?: 'VehicleCloudBoxxStatusLog';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicleId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  vehicle: Vehicle;
  user?: Maybe<User>;
};

export type VehicleCloudBoxxStatusLogCountAggregate = {
  __typename?: 'VehicleCloudBoxxStatusLogCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  status: Scalars['Int'];
  connected: Scalars['Int'];
  vehicleId: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleCloudBoxxStatusLogCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  connected?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxStatusLogCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleCloudBoxxStatusLogInput;
  user?: Maybe<UserCreateNestedOneWithoutVehicleCloudBoxxStatusLogInput>;
};

export type VehicleCloudBoxxStatusLogCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicleId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxStatusLogCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicleId: Scalars['String'];
};

export type VehicleCloudBoxxStatusLogCreateManyUserInputEnvelope = {
  data: Array<VehicleCloudBoxxStatusLogCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCloudBoxxStatusLogCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxStatusLogCreateManyVehicleInputEnvelope = {
  data: Array<VehicleCloudBoxxStatusLogCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCloudBoxxStatusLogCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<VehicleCloudBoxxStatusLogCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxStatusLogCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxStatusLogCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
};

export type VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleCloudBoxxStatusLogCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxStatusLogCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxStatusLogCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
};

export type VehicleCloudBoxxStatusLogCreateOrConnectWithoutUserInput = {
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
  create: VehicleCloudBoxxStatusLogCreateWithoutUserInput;
};

export type VehicleCloudBoxxStatusLogCreateOrConnectWithoutVehicleInput = {
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
  create: VehicleCloudBoxxStatusLogCreateWithoutVehicleInput;
};

export type VehicleCloudBoxxStatusLogCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleCloudBoxxStatusLogInput;
};

export type VehicleCloudBoxxStatusLogCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserCreateNestedOneWithoutVehicleCloudBoxxStatusLogInput>;
};

export type VehicleCloudBoxxStatusLogGroupBy = {
  __typename?: 'VehicleCloudBoxxStatusLogGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicleId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleCloudBoxxStatusLogCountAggregate>;
  _min?: Maybe<VehicleCloudBoxxStatusLogMinAggregate>;
  _max?: Maybe<VehicleCloudBoxxStatusLogMaxAggregate>;
};

export type VehicleCloudBoxxStatusLogListRelationFilter = {
  every?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  some?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  none?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
};

export type VehicleCloudBoxxStatusLogMaxAggregate = {
  __typename?: 'VehicleCloudBoxxStatusLogMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicleId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxStatusLogMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  connected?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxStatusLogMinAggregate = {
  __typename?: 'VehicleCloudBoxxStatusLogMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  connected?: Maybe<Scalars['Boolean']>;
  vehicleId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type VehicleCloudBoxxStatusLogMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  connected?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxStatusLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleCloudBoxxStatusLogOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  connected?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<VehicleCloudBoxxStatusLogCountOrderByAggregateInput>;
  _max?: Maybe<VehicleCloudBoxxStatusLogMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleCloudBoxxStatusLogMinOrderByAggregateInput>;
};

export type VehicleCloudBoxxStatusLogOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  connected?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
};

export enum VehicleCloudBoxxStatusLogScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Status = 'status',
  Connected = 'connected',
  VehicleId = 'vehicleId',
  UserId = 'userId',
}

export type VehicleCloudBoxxStatusLogScalarWhereInput = {
  AND?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereInput>>;
  OR?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  status?: Maybe<BoolNullableFilter>;
  connected?: Maybe<BoolNullableFilter>;
  vehicleId?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type VehicleCloudBoxxStatusLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<BoolNullableWithAggregatesFilter>;
  connected?: Maybe<BoolNullableWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VehicleCloudBoxxStatusLogUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  connected?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleCloudBoxxStatusLogInput>;
  user?: Maybe<UserUpdateOneWithoutVehicleCloudBoxxStatusLogInput>;
};

export type VehicleCloudBoxxStatusLogUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  connected?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type VehicleCloudBoxxStatusLogUpdateManyWithWhereWithoutUserInput = {
  where: VehicleCloudBoxxStatusLogScalarWhereInput;
  data: VehicleCloudBoxxStatusLogUpdateManyMutationInput;
};

export type VehicleCloudBoxxStatusLogUpdateManyWithWhereWithoutVehicleInput = {
  where: VehicleCloudBoxxStatusLogScalarWhereInput;
  data: VehicleCloudBoxxStatusLogUpdateManyMutationInput;
};

export type VehicleCloudBoxxStatusLogUpdateManyWithoutUserInput = {
  create?: Maybe<Array<VehicleCloudBoxxStatusLogCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxStatusLogCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<VehicleCloudBoxxStatusLogUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxStatusLogCreateManyUserInputEnvelope>;
  set?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleCloudBoxxStatusLogUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<VehicleCloudBoxxStatusLogUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereInput>>;
};

export type VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleCloudBoxxStatusLogCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCloudBoxxStatusLogCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<VehicleCloudBoxxStatusLogUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleCloudBoxxStatusLogCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleCloudBoxxStatusLogWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleCloudBoxxStatusLogUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<VehicleCloudBoxxStatusLogUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<VehicleCloudBoxxStatusLogScalarWhereInput>>;
};

export type VehicleCloudBoxxStatusLogUpdateWithWhereUniqueWithoutUserInput = {
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
  data: VehicleCloudBoxxStatusLogUpdateWithoutUserInput;
};

export type VehicleCloudBoxxStatusLogUpdateWithWhereUniqueWithoutVehicleInput =
  {
    where: VehicleCloudBoxxStatusLogWhereUniqueInput;
    data: VehicleCloudBoxxStatusLogUpdateWithoutVehicleInput;
  };

export type VehicleCloudBoxxStatusLogUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  connected?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleCloudBoxxStatusLogInput>;
};

export type VehicleCloudBoxxStatusLogUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  connected?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehicleCloudBoxxStatusLogInput>;
};

export type VehicleCloudBoxxStatusLogUpsertWithWhereUniqueWithoutUserInput = {
  where: VehicleCloudBoxxStatusLogWhereUniqueInput;
  update: VehicleCloudBoxxStatusLogUpdateWithoutUserInput;
  create: VehicleCloudBoxxStatusLogCreateWithoutUserInput;
};

export type VehicleCloudBoxxStatusLogUpsertWithWhereUniqueWithoutVehicleInput =
  {
    where: VehicleCloudBoxxStatusLogWhereUniqueInput;
    update: VehicleCloudBoxxStatusLogUpdateWithoutVehicleInput;
    create: VehicleCloudBoxxStatusLogCreateWithoutVehicleInput;
  };

export type VehicleCloudBoxxStatusLogWhereInput = {
  AND?: Maybe<Array<VehicleCloudBoxxStatusLogWhereInput>>;
  OR?: Maybe<Array<VehicleCloudBoxxStatusLogWhereInput>>;
  NOT?: Maybe<Array<VehicleCloudBoxxStatusLogWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  status?: Maybe<BoolNullableFilter>;
  connected?: Maybe<BoolNullableFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type VehicleCloudBoxxStatusLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleConnection = {
  __typename?: 'VehicleConnection';
  id: Scalars['String'];
  vehicleId: Scalars['String'];
  vehicleConnectionType: VehicleConnectionType;
  property: Scalars['String'];
  value: Scalars['String'];
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

export type VehicleConnectionCountAggregate = {
  __typename?: 'VehicleConnectionCountAggregate';
  id: Scalars['Int'];
  vehicleId: Scalars['Int'];
  vehicleConnectionType: Scalars['Int'];
  property: Scalars['Int'];
  value: Scalars['Int'];
  permissionError: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleConnectionCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleConnectionType?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  permissionError?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VehicleConnectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleConnectionInput;
  vehicleConnectionType: VehicleConnectionType;
  property: Scalars['String'];
  value: Scalars['String'];
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleConnectionCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  vehicleConnectionType: VehicleConnectionType;
  property: Scalars['String'];
  value: Scalars['String'];
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleConnectionCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  vehicleConnectionType: VehicleConnectionType;
  property: Scalars['String'];
  value: Scalars['String'];
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleConnectionCreateManyVehicleInputEnvelope = {
  data: Array<VehicleConnectionCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleConnectionCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleConnectionCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleConnectionCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleConnectionCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<VehicleConnectionWhereUniqueInput>>;
};

export type VehicleConnectionCreateOrConnectWithoutVehicleInput = {
  where: VehicleConnectionWhereUniqueInput;
  create: VehicleConnectionCreateWithoutVehicleInput;
};

export type VehicleConnectionCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  vehicleConnectionType: VehicleConnectionType;
  property: Scalars['String'];
  value: Scalars['String'];
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleConnectionGroupBy = {
  __typename?: 'VehicleConnectionGroupBy';
  id: Scalars['String'];
  vehicleId: Scalars['String'];
  vehicleConnectionType: VehicleConnectionType;
  property: Scalars['String'];
  value: Scalars['String'];
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<VehicleConnectionCountAggregate>;
  _min?: Maybe<VehicleConnectionMinAggregate>;
  _max?: Maybe<VehicleConnectionMaxAggregate>;
};

export type VehicleConnectionListRelationFilter = {
  every?: Maybe<VehicleConnectionWhereInput>;
  some?: Maybe<VehicleConnectionWhereInput>;
  none?: Maybe<VehicleConnectionWhereInput>;
};

export type VehicleConnectionMaxAggregate = {
  __typename?: 'VehicleConnectionMaxAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleConnectionType?: Maybe<VehicleConnectionType>;
  property?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleConnectionMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleConnectionType?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  permissionError?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VehicleConnectionMinAggregate = {
  __typename?: 'VehicleConnectionMinAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleConnectionType?: Maybe<VehicleConnectionType>;
  property?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  permissionError?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleConnectionMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleConnectionType?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  permissionError?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VehicleConnectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleConnectionOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleConnectionType?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  permissionError?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<VehicleConnectionCountOrderByAggregateInput>;
  _max?: Maybe<VehicleConnectionMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleConnectionMinOrderByAggregateInput>;
};

export type VehicleConnectionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  vehicleConnectionType?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  permissionError?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum VehicleConnectionScalarFieldEnum {
  Id = 'id',
  VehicleId = 'vehicleId',
  VehicleConnectionType = 'vehicleConnectionType',
  Property = 'property',
  Value = 'value',
  PermissionError = 'permissionError',
  UpdatedAt = 'updatedAt',
}

export type VehicleConnectionScalarWhereInput = {
  AND?: Maybe<Array<VehicleConnectionScalarWhereInput>>;
  OR?: Maybe<Array<VehicleConnectionScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleConnectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicleId?: Maybe<StringFilter>;
  vehicleConnectionType?: Maybe<EnumVehicleConnectionTypeFilter>;
  property?: Maybe<StringFilter>;
  value?: Maybe<StringFilter>;
  permissionError?: Maybe<BoolNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VehicleConnectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleConnectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleConnectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleConnectionScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  vehicleConnectionType?: Maybe<EnumVehicleConnectionTypeWithAggregatesFilter>;
  property?: Maybe<StringWithAggregatesFilter>;
  value?: Maybe<StringWithAggregatesFilter>;
  permissionError?: Maybe<BoolNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum VehicleConnectionType {
  Cloudboxx = 'CLOUDBOXX',
  Smartcar = 'SMARTCAR',
  Convadis = 'CONVADIS',
}

export type VehicleConnectionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleConnectionInput>;
  vehicleConnectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  property?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
  permissionError?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleConnectionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicleConnectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  property?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
  permissionError?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleConnectionUpdateManyWithWhereWithoutVehicleInput = {
  where: VehicleConnectionScalarWhereInput;
  data: VehicleConnectionUpdateManyMutationInput;
};

export type VehicleConnectionUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleConnectionCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleConnectionCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<VehicleConnectionUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleConnectionCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<VehicleConnectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleConnectionWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleConnectionWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleConnectionWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleConnectionUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<VehicleConnectionUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<VehicleConnectionScalarWhereInput>>;
};

export type VehicleConnectionUpdateWithWhereUniqueWithoutVehicleInput = {
  where: VehicleConnectionWhereUniqueInput;
  data: VehicleConnectionUpdateWithoutVehicleInput;
};

export type VehicleConnectionUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicleConnectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  property?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
  permissionError?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleConnectionUpsertWithWhereUniqueWithoutVehicleInput = {
  where: VehicleConnectionWhereUniqueInput;
  update: VehicleConnectionUpdateWithoutVehicleInput;
  create: VehicleConnectionCreateWithoutVehicleInput;
};

export type VehicleConnectionWhereInput = {
  AND?: Maybe<Array<VehicleConnectionWhereInput>>;
  OR?: Maybe<Array<VehicleConnectionWhereInput>>;
  NOT?: Maybe<Array<VehicleConnectionWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  vehicleConnectionType?: Maybe<EnumVehicleConnectionTypeFilter>;
  property?: Maybe<StringFilter>;
  value?: Maybe<StringFilter>;
  permissionError?: Maybe<BoolNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VehicleConnectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleCount = {
  __typename?: 'VehicleCount';
  vehicleDamage: Scalars['Int'];
  childSeatTypes: Scalars['Int'];
  vehiclePhotos: Scalars['Int'];
  bookings: Scalars['Int'];
  bookingVehicleDamages: Scalars['Int'];
  vehicleEquipment: Scalars['Int'];
  vehicleCloudBoxxLog: Scalars['Int'];
  vehicleLocation: Scalars['Int'];
  vehicleCloudBoxxStatusLog: Scalars['Int'];
  availability: Scalars['Int'];
  bookingQuestion: Scalars['Int'];
  sharedVehicleGroupRelVehicle: Scalars['Int'];
  gateControllerRelVehicles: Scalars['Int'];
  gateControllerTransactions: Scalars['Int'];
  smartcarAuth: Scalars['Int'];
  vehicleConnection: Scalars['Int'];
};

export type VehicleCountAggregate = {
  __typename?: 'VehicleCountAggregate';
  id: Scalars['Int'];
  vin: Scalars['Int'];
  rootNumber: Scalars['Int'];
  convadisVehicle: Scalars['Int'];
  qnr: Scalars['Int'];
  firstName: Scalars['Int'];
  lastName: Scalars['Int'];
  address: Scalars['Int'];
  houseNumber: Scalars['Int'];
  postCode: Scalars['Int'];
  city: Scalars['Int'];
  country: Scalars['Int'];
  birthdate: Scalars['Int'];
  cantonalNotes: Scalars['Int'];
  insurance: Scalars['Int'];
  decreesAuthority: Scalars['Int'];
  departmentEmissionCode: Scalars['Int'];
  enabledSmsAlerts: Scalars['Int'];
  capacity: Scalars['Int'];
  power: Scalars['Int'];
  curbWeight: Scalars['Int'];
  capacityPerKg: Scalars['Int'];
  payloadWeight: Scalars['Int'];
  grossWeight: Scalars['Int'];
  trainWeight: Scalars['Int'];
  towingWeight: Scalars['Int'];
  roofLoadWeight: Scalars['Int'];
  catalogPrice: Scalars['Int'];
  initialOdometer: Scalars['Int'];
  valueOfAccessories: Scalars['Int'];
  vehicleCode: Scalars['Int'];
  plateNumber: Scalars['Int'];
  trunkDimension: Scalars['Int'];
  approvalType: Scalars['Int'];
  fuelPassword: Scalars['Int'];
  rules: Scalars['Int'];
  currentLong: Scalars['Int'];
  currentLat: Scalars['Int'];
  bookingInformation: Scalars['Int'];
  disabled: Scalars['Int'];
  ownInsurance: Scalars['Int'];
  priceHour: Scalars['Int'];
  priceDay: Scalars['Int'];
  priceKm: Scalars['Int'];
  priceWeek: Scalars['Int'];
  includedKmHour: Scalars['Int'];
  includedKmDay: Scalars['Int'];
  includedKmWeek: Scalars['Int'];
  preBookingInterval: Scalars['Int'];
  postBookingInterval: Scalars['Int'];
  earlyOpenInterval: Scalars['Int'];
  userId: Scalars['Int'];
  vehicleDamageId: Scalars['Int'];
  unpublishReason: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  commissioningAt: Scalars['Int'];
  testedAt: Scalars['Int'];
  cloudBoxxActivatedAt: Scalars['Int'];
  connectionType: Scalars['Int'];
  smartcarVehicle: Scalars['Int'];
  fuelTypeId: Scalars['Int'];
  doorTypeId: Scalars['Int'];
  vehicleTypeId: Scalars['Int'];
  vehicleKindTypeId: Scalars['Int'];
  transmissionTypeId: Scalars['Int'];
  driveTypeId: Scalars['Int'];
  seatTypeId: Scalars['Int'];
  seatFrontTypeId: Scalars['Int'];
  energyEfficiencyTypeId: Scalars['Int'];
  emissionCodeTypeId: Scalars['Int'];
  consumptionTypeId: Scalars['Int'];
  shieldColorTypeId: Scalars['Int'];
  particularUseTypeId: Scalars['Int'];
  brandTypeId: Scalars['Int'];
  modelTypeId: Scalars['Int'];
  bodyTypeId: Scalars['Int'];
  colorTypeId: Scalars['Int'];
  vehicleInsuranceTypeId: Scalars['Int'];
  ownership: Scalars['Int'];
  companyId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vin?: Maybe<SortOrder>;
  rootNumber?: Maybe<SortOrder>;
  convadisVehicle?: Maybe<SortOrder>;
  qnr?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  cantonalNotes?: Maybe<SortOrder>;
  insurance?: Maybe<SortOrder>;
  decreesAuthority?: Maybe<SortOrder>;
  departmentEmissionCode?: Maybe<SortOrder>;
  enabledSmsAlerts?: Maybe<SortOrder>;
  capacity?: Maybe<SortOrder>;
  power?: Maybe<SortOrder>;
  curbWeight?: Maybe<SortOrder>;
  capacityPerKg?: Maybe<SortOrder>;
  payloadWeight?: Maybe<SortOrder>;
  grossWeight?: Maybe<SortOrder>;
  trainWeight?: Maybe<SortOrder>;
  towingWeight?: Maybe<SortOrder>;
  roofLoadWeight?: Maybe<SortOrder>;
  catalogPrice?: Maybe<SortOrder>;
  initialOdometer?: Maybe<SortOrder>;
  valueOfAccessories?: Maybe<SortOrder>;
  vehicleCode?: Maybe<SortOrder>;
  plateNumber?: Maybe<SortOrder>;
  trunkDimension?: Maybe<SortOrder>;
  approvalType?: Maybe<SortOrder>;
  fuelPassword?: Maybe<SortOrder>;
  rules?: Maybe<SortOrder>;
  currentLong?: Maybe<SortOrder>;
  currentLat?: Maybe<SortOrder>;
  bookingInformation?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  ownInsurance?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  preBookingInterval?: Maybe<SortOrder>;
  postBookingInterval?: Maybe<SortOrder>;
  earlyOpenInterval?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  unpublishReason?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  commissioningAt?: Maybe<SortOrder>;
  testedAt?: Maybe<SortOrder>;
  cloudBoxxActivatedAt?: Maybe<SortOrder>;
  connectionType?: Maybe<SortOrder>;
  smartcarVehicle?: Maybe<SortOrder>;
  fuelTypeId?: Maybe<SortOrder>;
  doorTypeId?: Maybe<SortOrder>;
  vehicleTypeId?: Maybe<SortOrder>;
  vehicleKindTypeId?: Maybe<SortOrder>;
  transmissionTypeId?: Maybe<SortOrder>;
  driveTypeId?: Maybe<SortOrder>;
  seatTypeId?: Maybe<SortOrder>;
  seatFrontTypeId?: Maybe<SortOrder>;
  energyEfficiencyTypeId?: Maybe<SortOrder>;
  emissionCodeTypeId?: Maybe<SortOrder>;
  consumptionTypeId?: Maybe<SortOrder>;
  shieldColorTypeId?: Maybe<SortOrder>;
  particularUseTypeId?: Maybe<SortOrder>;
  brandTypeId?: Maybe<SortOrder>;
  modelTypeId?: Maybe<SortOrder>;
  bodyTypeId?: Maybe<SortOrder>;
  colorTypeId?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type VehicleCreateInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateManyBodyTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyBodyTypeInputEnvelope = {
  data: Array<VehicleCreateManyBodyTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyBrandTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyBrandTypeInputEnvelope = {
  data: Array<VehicleCreateManyBrandTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyColorTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyColorTypeInputEnvelope = {
  data: Array<VehicleCreateManyColorTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
};

export type VehicleCreateManyCompanyInputEnvelope = {
  data: Array<VehicleCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyConsumptionTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyConsumptionTypeInputEnvelope = {
  data: Array<VehicleCreateManyConsumptionTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyDoorTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyDoorTypeInputEnvelope = {
  data: Array<VehicleCreateManyDoorTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyDriveTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyDriveTypeInputEnvelope = {
  data: Array<VehicleCreateManyDriveTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyEmissionCodeTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyEmissionCodeTypeInputEnvelope = {
  data: Array<VehicleCreateManyEmissionCodeTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyEnergyEfficiencyTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyEnergyEfficiencyTypeInputEnvelope = {
  data: Array<VehicleCreateManyEnergyEfficiencyTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyFuelTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyFuelTypeInputEnvelope = {
  data: Array<VehicleCreateManyFuelTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyModelTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyModelTypeInputEnvelope = {
  data: Array<VehicleCreateManyModelTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyParticularUseTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyParticularUseTypeInputEnvelope = {
  data: Array<VehicleCreateManyParticularUseTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManySeatFrontTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManySeatFrontTypeInputEnvelope = {
  data: Array<VehicleCreateManySeatFrontTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManySeatTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManySeatTypeInputEnvelope = {
  data: Array<VehicleCreateManySeatTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyShieldColorTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyShieldColorTypeInputEnvelope = {
  data: Array<VehicleCreateManyShieldColorTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyTransmissionTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyTransmissionTypeInputEnvelope = {
  data: Array<VehicleCreateManyTransmissionTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyUserInputEnvelope = {
  data: Array<VehicleCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyVehicleInsuranceTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyVehicleInsuranceTypeInputEnvelope = {
  data: Array<VehicleCreateManyVehicleInsuranceTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyVehicleKindTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyVehicleKindTypeInputEnvelope = {
  data: Array<VehicleCreateManyVehicleKindTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateManyVehicleTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleCreateManyVehicleTypeInputEnvelope = {
  data: Array<VehicleCreateManyVehicleTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleCreateNestedManyWithoutBodyTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutBodyTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutBodyTypeInput>>;
  createMany?: Maybe<VehicleCreateManyBodyTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutBrandTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutBrandTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutBrandTypeInput>>;
  createMany?: Maybe<VehicleCreateManyBrandTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutChildSeatTypesInput = {
  create?: Maybe<Array<VehicleCreateWithoutChildSeatTypesInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutChildSeatTypesInput>
  >;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutColorTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutColorTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutColorTypeInput>>;
  createMany?: Maybe<VehicleCreateManyColorTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<VehicleCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutCompanyInput>>;
  createMany?: Maybe<VehicleCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutConsumptionTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutConsumptionTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutConsumptionTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyConsumptionTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutDoorTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutDoorTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutDoorTypeInput>>;
  createMany?: Maybe<VehicleCreateManyDoorTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutDriveTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutDriveTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutDriveTypeInput>>;
  createMany?: Maybe<VehicleCreateManyDriveTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutEmissionCodeTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutEmissionCodeTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutEmissionCodeTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyEmissionCodeTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutEnergyEfficiencyTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutEnergyEfficiencyTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutEnergyEfficiencyTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyEnergyEfficiencyTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutFuelTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutFuelTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutFuelTypeInput>>;
  createMany?: Maybe<VehicleCreateManyFuelTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutModelTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutModelTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutModelTypeInput>>;
  createMany?: Maybe<VehicleCreateManyModelTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutParticularUseTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutParticularUseTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutParticularUseTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyParticularUseTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutSeatFrontTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutSeatFrontTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutSeatFrontTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManySeatFrontTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutSeatTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutSeatTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutSeatTypeInput>>;
  createMany?: Maybe<VehicleCreateManySeatTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutShieldColorTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutShieldColorTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutShieldColorTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyShieldColorTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutTransmissionTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutTransmissionTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutTransmissionTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyTransmissionTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<VehicleCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<VehicleCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutVehicleInsuranceTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutVehicleInsuranceTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutVehicleInsuranceTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyVehicleInsuranceTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutVehicleKindTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutVehicleKindTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutVehicleKindTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyVehicleKindTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedManyWithoutVehicleTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutVehicleTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutVehicleTypeInput>>;
  createMany?: Maybe<VehicleCreateManyVehicleTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
};

export type VehicleCreateNestedOneWithoutAvailabilityInput = {
  create?: Maybe<VehicleCreateWithoutAvailabilityInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutAvailabilityInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutBookingQuestionInput = {
  create?: Maybe<VehicleCreateWithoutBookingQuestionInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutBookingQuestionInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutBookingVehicleDamagesInput = {
  create?: Maybe<VehicleCreateWithoutBookingVehicleDamagesInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutBookingVehicleDamagesInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutBookingsInput = {
  create?: Maybe<VehicleCreateWithoutBookingsInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutBookingsInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutGateControllerRelVehiclesInput = {
  create?: Maybe<VehicleCreateWithoutGateControllerRelVehiclesInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutGateControllerRelVehiclesInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutGateControllerTransactionsInput = {
  create?: Maybe<VehicleCreateWithoutGateControllerTransactionsInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutGateControllerTransactionsInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutSharedVehicleGroupRelVehicleInput = {
  create?: Maybe<VehicleCreateWithoutSharedVehicleGroupRelVehicleInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutSharedVehicleGroupRelVehicleInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutSmartcarAuthInput = {
  create?: Maybe<VehicleCreateWithoutSmartcarAuthInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutSmartcarAuthInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutVehicleCloudBoxxLogInput = {
  create?: Maybe<VehicleCreateWithoutVehicleCloudBoxxLogInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleCloudBoxxLogInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutVehicleCloudBoxxStatusLogInput = {
  create?: Maybe<VehicleCreateWithoutVehicleCloudBoxxStatusLogInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleCloudBoxxStatusLogInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutVehicleConnectionInput = {
  create?: Maybe<VehicleCreateWithoutVehicleConnectionInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleConnectionInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutVehicleDamageInput = {
  create?: Maybe<VehicleCreateWithoutVehicleDamageInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleDamageInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutVehicleEquipmentInput = {
  create?: Maybe<VehicleCreateWithoutVehicleEquipmentInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleEquipmentInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutVehicleLocationInput = {
  create?: Maybe<VehicleCreateWithoutVehicleLocationInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleLocationInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateNestedOneWithoutVehiclePhotosInput = {
  create?: Maybe<VehicleCreateWithoutVehiclePhotosInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehiclePhotosInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
};

export type VehicleCreateOrConnectWithoutAvailabilityInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutAvailabilityInput;
};

export type VehicleCreateOrConnectWithoutBodyTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutBodyTypeInput;
};

export type VehicleCreateOrConnectWithoutBookingQuestionInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutBookingQuestionInput;
};

export type VehicleCreateOrConnectWithoutBookingVehicleDamagesInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutBookingVehicleDamagesInput;
};

export type VehicleCreateOrConnectWithoutBookingsInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutBookingsInput;
};

export type VehicleCreateOrConnectWithoutBrandTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutBrandTypeInput;
};

export type VehicleCreateOrConnectWithoutChildSeatTypesInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutChildSeatTypesInput;
};

export type VehicleCreateOrConnectWithoutColorTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutColorTypeInput;
};

export type VehicleCreateOrConnectWithoutCompanyInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutCompanyInput;
};

export type VehicleCreateOrConnectWithoutConsumptionTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutConsumptionTypeInput;
};

export type VehicleCreateOrConnectWithoutDoorTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutDoorTypeInput;
};

export type VehicleCreateOrConnectWithoutDriveTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutDriveTypeInput;
};

export type VehicleCreateOrConnectWithoutEmissionCodeTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutEmissionCodeTypeInput;
};

export type VehicleCreateOrConnectWithoutEnergyEfficiencyTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutEnergyEfficiencyTypeInput;
};

export type VehicleCreateOrConnectWithoutFuelTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutFuelTypeInput;
};

export type VehicleCreateOrConnectWithoutGateControllerRelVehiclesInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutGateControllerRelVehiclesInput;
};

export type VehicleCreateOrConnectWithoutGateControllerTransactionsInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutGateControllerTransactionsInput;
};

export type VehicleCreateOrConnectWithoutModelTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutModelTypeInput;
};

export type VehicleCreateOrConnectWithoutParticularUseTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutParticularUseTypeInput;
};

export type VehicleCreateOrConnectWithoutSeatFrontTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutSeatFrontTypeInput;
};

export type VehicleCreateOrConnectWithoutSeatTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutSeatTypeInput;
};

export type VehicleCreateOrConnectWithoutSharedVehicleGroupRelVehicleInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutSharedVehicleGroupRelVehicleInput;
};

export type VehicleCreateOrConnectWithoutShieldColorTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutShieldColorTypeInput;
};

export type VehicleCreateOrConnectWithoutSmartcarAuthInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutSmartcarAuthInput;
};

export type VehicleCreateOrConnectWithoutTransmissionTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutTransmissionTypeInput;
};

export type VehicleCreateOrConnectWithoutUserInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutUserInput;
};

export type VehicleCreateOrConnectWithoutVehicleCloudBoxxLogInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleCloudBoxxLogInput;
};

export type VehicleCreateOrConnectWithoutVehicleCloudBoxxStatusLogInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleCloudBoxxStatusLogInput;
};

export type VehicleCreateOrConnectWithoutVehicleConnectionInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleConnectionInput;
};

export type VehicleCreateOrConnectWithoutVehicleDamageInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleDamageInput;
};

export type VehicleCreateOrConnectWithoutVehicleEquipmentInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleEquipmentInput;
};

export type VehicleCreateOrConnectWithoutVehicleInsuranceTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleInsuranceTypeInput;
};

export type VehicleCreateOrConnectWithoutVehicleKindTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleKindTypeInput;
};

export type VehicleCreateOrConnectWithoutVehicleLocationInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleLocationInput;
};

export type VehicleCreateOrConnectWithoutVehiclePhotosInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehiclePhotosInput;
};

export type VehicleCreateOrConnectWithoutVehicleTypeInput = {
  where: VehicleWhereUniqueInput;
  create: VehicleCreateWithoutVehicleTypeInput;
};

export type VehicleCreateWithoutAvailabilityInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutBodyTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutBookingQuestionInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutBookingVehicleDamagesInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutBookingsInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutBrandTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutChildSeatTypesInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutColorTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutConsumptionTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutDoorTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutDriveTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutEmissionCodeTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutEnergyEfficiencyTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutFuelTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutGateControllerRelVehiclesInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutGateControllerTransactionsInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutModelTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutParticularUseTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutSeatFrontTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutSeatTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutSharedVehicleGroupRelVehicleInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutShieldColorTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutSmartcarAuthInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutTransmissionTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleCloudBoxxLogInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleCloudBoxxStatusLogInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleConnectionInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleDamageInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleEquipmentInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleInsuranceTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleKindTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleLocationInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehiclePhotosInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleCreateWithoutVehicleTypeInput = {
  id?: Maybe<Scalars['String']>;
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  user?: Maybe<UserCreateNestedOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelTypeCreateNestedOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeCreateNestedOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeCreateNestedOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeCreateNestedOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeCreateNestedOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeCreateNestedOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeCreateNestedManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeCreateNestedOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeCreateNestedOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeCreateNestedOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeCreateNestedOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeCreateNestedOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeCreateNestedOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeCreateNestedOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeCreateNestedOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeCreateNestedOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput>;
  ownership?: Maybe<Ownership>;
  company?: Maybe<CompanyCreateNestedOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesCreateNestedManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogCreateNestedManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationCreateNestedManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogCreateNestedManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityCreateNestedManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionCreateNestedManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleCreateNestedManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionCreateNestedManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthCreateNestedManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionCreateNestedManyWithoutVehicleInput>;
};

export type VehicleDamage = {
  __typename?: 'VehicleDamage';
  id: Scalars['String'];
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic: Scalars['Boolean'];
  isResolved: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleDamageCount>;
  user?: Maybe<User>;
  vehicle: Vehicle;
  booking?: Maybe<Booking>;
  vehiclePhotos: Array<VehiclePhoto>;
  vehicleDamageComment: Array<VehicleDamageComment>;
};

export type VehicleDamageVehiclePhotosArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
  orderBy?: Maybe<Array<VehiclePhotoOrderByWithRelationInput>>;
  cursor?: Maybe<VehiclePhotoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehiclePhotoScalarFieldEnum>>;
};

export type VehicleDamageVehicleDamageCommentArgs = {
  where?: Maybe<VehicleDamageCommentWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageCommentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageCommentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageCommentScalarFieldEnum>>;
};

export type VehicleDamageAvgAggregate = {
  __typename?: 'VehicleDamageAvgAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type VehicleDamageAvgOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
};

export type VehicleDamageComment = {
  __typename?: 'VehicleDamageComment';
  id: Scalars['String'];
  userId: Scalars['String'];
  vehicleDamageId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  user: User;
  vehicleDamage: VehicleDamage;
};

export type VehicleDamageCommentCountAggregate = {
  __typename?: 'VehicleDamageCommentCountAggregate';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  vehicleDamageId: Scalars['Int'];
  content: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleDamageCommentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type VehicleDamageCommentCreateInput = {
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutVehicleDamageCommentInput;
  vehicleDamage: VehicleDamageCreateNestedOneWithoutVehicleDamageCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  vehicleDamageId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  vehicleDamageId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentCreateManyUserInputEnvelope = {
  data: Array<VehicleDamageCommentCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleDamageCommentCreateManyVehicleDamageInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentCreateManyVehicleDamageInputEnvelope = {
  data: Array<VehicleDamageCommentCreateManyVehicleDamageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleDamageCommentCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<VehicleDamageCommentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCommentCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<VehicleDamageCommentCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
};

export type VehicleDamageCommentCreateNestedManyWithoutVehicleDamageInput = {
  create?: Maybe<Array<VehicleDamageCommentCreateWithoutVehicleDamageInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCommentCreateOrConnectWithoutVehicleDamageInput>
  >;
  createMany?: Maybe<VehicleDamageCommentCreateManyVehicleDamageInputEnvelope>;
  connect?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
};

export type VehicleDamageCommentCreateOrConnectWithoutUserInput = {
  where: VehicleDamageCommentWhereUniqueInput;
  create: VehicleDamageCommentCreateWithoutUserInput;
};

export type VehicleDamageCommentCreateOrConnectWithoutVehicleDamageInput = {
  where: VehicleDamageCommentWhereUniqueInput;
  create: VehicleDamageCommentCreateWithoutVehicleDamageInput;
};

export type VehicleDamageCommentCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  vehicleDamage: VehicleDamageCreateNestedOneWithoutVehicleDamageCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentCreateWithoutVehicleDamageInput = {
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutVehicleDamageCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentGroupBy = {
  __typename?: 'VehicleDamageCommentGroupBy';
  id: Scalars['String'];
  userId: Scalars['String'];
  vehicleDamageId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  _count?: Maybe<VehicleDamageCommentCountAggregate>;
  _min?: Maybe<VehicleDamageCommentMinAggregate>;
  _max?: Maybe<VehicleDamageCommentMaxAggregate>;
};

export type VehicleDamageCommentListRelationFilter = {
  every?: Maybe<VehicleDamageCommentWhereInput>;
  some?: Maybe<VehicleDamageCommentWhereInput>;
  none?: Maybe<VehicleDamageCommentWhereInput>;
};

export type VehicleDamageCommentMaxAggregate = {
  __typename?: 'VehicleDamageCommentMaxAggregate';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type VehicleDamageCommentMinAggregate = {
  __typename?: 'VehicleDamageCommentMinAggregate';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleDamageCommentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type VehicleDamageCommentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleDamageCommentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<VehicleDamageCommentCountOrderByAggregateInput>;
  _max?: Maybe<VehicleDamageCommentMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleDamageCommentMinOrderByAggregateInput>;
};

export type VehicleDamageCommentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  vehicleDamage?: Maybe<VehicleDamageOrderByWithRelationInput>;
  vehicleDamageId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum VehicleDamageCommentScalarFieldEnum {
  Id = 'id',
  UserId = 'userId',
  VehicleDamageId = 'vehicleDamageId',
  Content = 'content',
  CreatedAt = 'createdAt',
}

export type VehicleDamageCommentScalarWhereInput = {
  AND?: Maybe<Array<VehicleDamageCommentScalarWhereInput>>;
  OR?: Maybe<Array<VehicleDamageCommentScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleDamageCommentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  vehicleDamageId?: Maybe<StringFilter>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type VehicleDamageCommentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleDamageCommentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleDamageCommentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleDamageCommentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  vehicleDamageId?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VehicleDamageCommentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutVehicleDamageCommentInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateOneRequiredWithoutVehicleDamageCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleDamageCommentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleDamageCommentUpdateManyWithWhereWithoutUserInput = {
  where: VehicleDamageCommentScalarWhereInput;
  data: VehicleDamageCommentUpdateManyMutationInput;
};

export type VehicleDamageCommentUpdateManyWithWhereWithoutVehicleDamageInput = {
  where: VehicleDamageCommentScalarWhereInput;
  data: VehicleDamageCommentUpdateManyMutationInput;
};

export type VehicleDamageCommentUpdateManyWithoutUserInput = {
  create?: Maybe<Array<VehicleDamageCommentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCommentCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<VehicleDamageCommentUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<VehicleDamageCommentCreateManyUserInputEnvelope>;
  set?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleDamageCommentUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<VehicleDamageCommentUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<VehicleDamageCommentScalarWhereInput>>;
};

export type VehicleDamageCommentUpdateManyWithoutVehicleDamageInput = {
  create?: Maybe<Array<VehicleDamageCommentCreateWithoutVehicleDamageInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCommentCreateOrConnectWithoutVehicleDamageInput>
  >;
  upsert?: Maybe<
    Array<VehicleDamageCommentUpsertWithWhereUniqueWithoutVehicleDamageInput>
  >;
  createMany?: Maybe<VehicleDamageCommentCreateManyVehicleDamageInputEnvelope>;
  set?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleDamageCommentWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleDamageCommentUpdateWithWhereUniqueWithoutVehicleDamageInput>
  >;
  updateMany?: Maybe<
    Array<VehicleDamageCommentUpdateManyWithWhereWithoutVehicleDamageInput>
  >;
  deleteMany?: Maybe<Array<VehicleDamageCommentScalarWhereInput>>;
};

export type VehicleDamageCommentUpdateWithWhereUniqueWithoutUserInput = {
  where: VehicleDamageCommentWhereUniqueInput;
  data: VehicleDamageCommentUpdateWithoutUserInput;
};

export type VehicleDamageCommentUpdateWithWhereUniqueWithoutVehicleDamageInput =
  {
    where: VehicleDamageCommentWhereUniqueInput;
    data: VehicleDamageCommentUpdateWithoutVehicleDamageInput;
  };

export type VehicleDamageCommentUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateOneRequiredWithoutVehicleDamageCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleDamageCommentUpdateWithoutVehicleDamageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutVehicleDamageCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleDamageCommentUpsertWithWhereUniqueWithoutUserInput = {
  where: VehicleDamageCommentWhereUniqueInput;
  update: VehicleDamageCommentUpdateWithoutUserInput;
  create: VehicleDamageCommentCreateWithoutUserInput;
};

export type VehicleDamageCommentUpsertWithWhereUniqueWithoutVehicleDamageInput =
  {
    where: VehicleDamageCommentWhereUniqueInput;
    update: VehicleDamageCommentUpdateWithoutVehicleDamageInput;
    create: VehicleDamageCommentCreateWithoutVehicleDamageInput;
  };

export type VehicleDamageCommentWhereInput = {
  AND?: Maybe<Array<VehicleDamageCommentWhereInput>>;
  OR?: Maybe<Array<VehicleDamageCommentWhereInput>>;
  NOT?: Maybe<Array<VehicleDamageCommentWhereInput>>;
  id?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  vehicleDamage?: Maybe<VehicleDamageRelationFilter>;
  vehicleDamageId?: Maybe<StringFilter>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type VehicleDamageCommentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleDamageCount = {
  __typename?: 'VehicleDamageCount';
  vehiclePhotos: Scalars['Int'];
  vehicleDamageComment: Scalars['Int'];
};

export type VehicleDamageCountAggregate = {
  __typename?: 'VehicleDamageCountAggregate';
  id: Scalars['Int'];
  time: Scalars['Int'];
  type: Scalars['Int'];
  kind: Scalars['Int'];
  description: Scalars['Int'];
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  isPublic: Scalars['Int'];
  isResolved: Scalars['Int'];
  createdAt: Scalars['Int'];
  userId: Scalars['Int'];
  vehicleId: Scalars['Int'];
  bookingId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleDamageCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  isPublic?: Maybe<SortOrder>;
  isResolved?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type VehicleDamageCreateInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVehicleDamageInput>;
  vehicle: VehicleCreateNestedOneWithoutVehicleDamageInput;
  booking?: Maybe<BookingCreateNestedOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutVehicleDamageInput>;
};

export type VehicleDamageCreateManyBookingInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId: Scalars['String'];
};

export type VehicleDamageCreateManyBookingInputEnvelope = {
  data: Array<VehicleDamageCreateManyBookingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleDamageCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleDamageCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vehicleId: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleDamageCreateManyUserInputEnvelope = {
  data: Array<VehicleDamageCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleDamageCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleDamageCreateManyVehicleInputEnvelope = {
  data: Array<VehicleDamageCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleDamageCreateNestedManyWithoutBookingInput = {
  create?: Maybe<Array<VehicleDamageCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCreateOrConnectWithoutBookingInput>
  >;
  createMany?: Maybe<VehicleDamageCreateManyBookingInputEnvelope>;
  connect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
};

export type VehicleDamageCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<VehicleDamageCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<VehicleDamageCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<VehicleDamageCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
};

export type VehicleDamageCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleDamageCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleDamageCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
};

export type VehicleDamageCreateNestedOneWithoutVehicleDamageCommentInput = {
  create?: Maybe<VehicleDamageCreateWithoutVehicleDamageCommentInput>;
  connectOrCreate?: Maybe<VehicleDamageCreateOrConnectWithoutVehicleDamageCommentInput>;
  connect?: Maybe<VehicleDamageWhereUniqueInput>;
};

export type VehicleDamageCreateNestedOneWithoutVehiclePhotosInput = {
  create?: Maybe<VehicleDamageCreateWithoutVehiclePhotosInput>;
  connectOrCreate?: Maybe<VehicleDamageCreateOrConnectWithoutVehiclePhotosInput>;
  connect?: Maybe<VehicleDamageWhereUniqueInput>;
};

export type VehicleDamageCreateOrConnectWithoutBookingInput = {
  where: VehicleDamageWhereUniqueInput;
  create: VehicleDamageCreateWithoutBookingInput;
};

export type VehicleDamageCreateOrConnectWithoutUserInput = {
  where: VehicleDamageWhereUniqueInput;
  create: VehicleDamageCreateWithoutUserInput;
};

export type VehicleDamageCreateOrConnectWithoutVehicleDamageCommentInput = {
  where: VehicleDamageWhereUniqueInput;
  create: VehicleDamageCreateWithoutVehicleDamageCommentInput;
};

export type VehicleDamageCreateOrConnectWithoutVehicleInput = {
  where: VehicleDamageWhereUniqueInput;
  create: VehicleDamageCreateWithoutVehicleInput;
};

export type VehicleDamageCreateOrConnectWithoutVehiclePhotosInput = {
  where: VehicleDamageWhereUniqueInput;
  create: VehicleDamageCreateWithoutVehiclePhotosInput;
};

export type VehicleDamageCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVehicleDamageInput>;
  vehicle: VehicleCreateNestedOneWithoutVehicleDamageInput;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutVehicleDamageInput>;
};

export type VehicleDamageCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleDamageInput;
  booking?: Maybe<BookingCreateNestedOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutVehicleDamageInput>;
};

export type VehicleDamageCreateWithoutVehicleDamageCommentInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVehicleDamageInput>;
  vehicle: VehicleCreateNestedOneWithoutVehicleDamageInput;
  booking?: Maybe<BookingCreateNestedOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleDamageInput>;
};

export type VehicleDamageCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVehicleDamageInput>;
  booking?: Maybe<BookingCreateNestedOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoCreateNestedManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutVehicleDamageInput>;
};

export type VehicleDamageCreateWithoutVehiclePhotosInput = {
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVehicleDamageInput>;
  vehicle: VehicleCreateNestedOneWithoutVehicleDamageInput;
  booking?: Maybe<BookingCreateNestedOneWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentCreateNestedManyWithoutVehicleDamageInput>;
};

export type VehicleDamageGroupBy = {
  __typename?: 'VehicleDamageGroupBy';
  id: Scalars['String'];
  time?: Maybe<VehicleDamageTime>;
  type: VehicleDamageType;
  kind?: Maybe<VehicleDamageKind>;
  description: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic: Scalars['Boolean'];
  isResolved: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  bookingId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleDamageCountAggregate>;
  _avg?: Maybe<VehicleDamageAvgAggregate>;
  _sum?: Maybe<VehicleDamageSumAggregate>;
  _min?: Maybe<VehicleDamageMinAggregate>;
  _max?: Maybe<VehicleDamageMaxAggregate>;
};

export enum VehicleDamageKind {
  Cloudboxx = 'CLOUDBOXX',
  Scratch = 'SCRATCH',
  Dent = 'DENT',
  Crack = 'CRACK',
  MissingPiece = 'MISSING_PIECE',
  Puncture = 'PUNCTURE',
  Others = 'OTHERS',
}

export type VehicleDamageListRelationFilter = {
  every?: Maybe<VehicleDamageWhereInput>;
  some?: Maybe<VehicleDamageWhereInput>;
  none?: Maybe<VehicleDamageWhereInput>;
};

export type VehicleDamageMaxAggregate = {
  __typename?: 'VehicleDamageMaxAggregate';
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type?: Maybe<VehicleDamageType>;
  kind?: Maybe<VehicleDamageKind>;
  description?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleDamageMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  isPublic?: Maybe<SortOrder>;
  isResolved?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type VehicleDamageMinAggregate = {
  __typename?: 'VehicleDamageMinAggregate';
  id?: Maybe<Scalars['String']>;
  time?: Maybe<VehicleDamageTime>;
  type?: Maybe<VehicleDamageType>;
  kind?: Maybe<VehicleDamageKind>;
  description?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
};

export type VehicleDamageMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  isPublic?: Maybe<SortOrder>;
  isResolved?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
};

export type VehicleDamageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleDamageOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  isPublic?: Maybe<SortOrder>;
  isResolved?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  bookingId?: Maybe<SortOrder>;
  _count?: Maybe<VehicleDamageCountOrderByAggregateInput>;
  _avg?: Maybe<VehicleDamageAvgOrderByAggregateInput>;
  _max?: Maybe<VehicleDamageMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleDamageMinOrderByAggregateInput>;
  _sum?: Maybe<VehicleDamageSumOrderByAggregateInput>;
};

export type VehicleDamageOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  isPublic?: Maybe<SortOrder>;
  isResolved?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByWithRelationInput>;
  bookingId?: Maybe<SortOrder>;
  vehiclePhotos?: Maybe<VehiclePhotoOrderByRelationAggregateInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentOrderByRelationAggregateInput>;
};

export type VehicleDamageRelationFilter = {
  is?: Maybe<VehicleDamageWhereInput>;
  isNot?: Maybe<VehicleDamageWhereInput>;
};

export enum VehicleDamageScalarFieldEnum {
  Id = 'id',
  Time = 'time',
  Type = 'type',
  Kind = 'kind',
  Description = 'description',
  Latitude = 'latitude',
  Longitude = 'longitude',
  IsPublic = 'isPublic',
  IsResolved = 'isResolved',
  CreatedAt = 'createdAt',
  UserId = 'userId',
  VehicleId = 'vehicleId',
  BookingId = 'bookingId',
}

export type VehicleDamageScalarWhereInput = {
  AND?: Maybe<Array<VehicleDamageScalarWhereInput>>;
  OR?: Maybe<Array<VehicleDamageScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleDamageScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  time?: Maybe<EnumVehicleDamageTimeNullableFilter>;
  type?: Maybe<EnumVehicleDamageTypeFilter>;
  kind?: Maybe<EnumVehicleDamageKindNullableFilter>;
  description?: Maybe<StringFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  isPublic?: Maybe<BoolFilter>;
  isResolved?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicleId?: Maybe<StringFilter>;
  bookingId?: Maybe<StringNullableFilter>;
};

export type VehicleDamageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleDamageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleDamageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleDamageScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  time?: Maybe<EnumVehicleDamageTimeNullableWithAggregatesFilter>;
  type?: Maybe<EnumVehicleDamageTypeWithAggregatesFilter>;
  kind?: Maybe<EnumVehicleDamageKindNullableWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  latitude?: Maybe<FloatNullableWithAggregatesFilter>;
  longitude?: Maybe<FloatNullableWithAggregatesFilter>;
  isPublic?: Maybe<BoolWithAggregatesFilter>;
  isResolved?: Maybe<BoolWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  bookingId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VehicleDamageSumAggregate = {
  __typename?: 'VehicleDamageSumAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type VehicleDamageSumOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
};

export enum VehicleDamageTime {
  NewReport = 'NEW_REPORT',
  NotReported = 'NOT_REPORTED',
}

export enum VehicleDamageType {
  DamageFrontLeft = 'DAMAGE_FRONT_LEFT',
  DamageFrontRight = 'DAMAGE_FRONT_RIGHT',
  DamageBackLeft = 'DAMAGE_BACK_LEFT',
  DamageBackRight = 'DAMAGE_BACK_RIGHT',
  CrashDetected = 'CRASH_DETECTED',
}

export type VehicleDamageUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableEnumVehicleDamageTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehicleDamageTypeFieldUpdateOperationsInput>;
  kind?: Maybe<NullableEnumVehicleDamageKindFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  isResolved?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehicleDamageInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleDamageInput>;
  booking?: Maybe<BookingUpdateOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutVehicleDamageInput>;
};

export type VehicleDamageUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableEnumVehicleDamageTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehicleDamageTypeFieldUpdateOperationsInput>;
  kind?: Maybe<NullableEnumVehicleDamageKindFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  isResolved?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleDamageUpdateManyWithWhereWithoutBookingInput = {
  where: VehicleDamageScalarWhereInput;
  data: VehicleDamageUpdateManyMutationInput;
};

export type VehicleDamageUpdateManyWithWhereWithoutUserInput = {
  where: VehicleDamageScalarWhereInput;
  data: VehicleDamageUpdateManyMutationInput;
};

export type VehicleDamageUpdateManyWithWhereWithoutVehicleInput = {
  where: VehicleDamageScalarWhereInput;
  data: VehicleDamageUpdateManyMutationInput;
};

export type VehicleDamageUpdateManyWithoutBookingInput = {
  create?: Maybe<Array<VehicleDamageCreateWithoutBookingInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCreateOrConnectWithoutBookingInput>
  >;
  upsert?: Maybe<Array<VehicleDamageUpsertWithWhereUniqueWithoutBookingInput>>;
  createMany?: Maybe<VehicleDamageCreateManyBookingInputEnvelope>;
  set?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  update?: Maybe<Array<VehicleDamageUpdateWithWhereUniqueWithoutBookingInput>>;
  updateMany?: Maybe<
    Array<VehicleDamageUpdateManyWithWhereWithoutBookingInput>
  >;
  deleteMany?: Maybe<Array<VehicleDamageScalarWhereInput>>;
};

export type VehicleDamageUpdateManyWithoutUserInput = {
  create?: Maybe<Array<VehicleDamageCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<VehicleDamageCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<VehicleDamageUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<VehicleDamageCreateManyUserInputEnvelope>;
  set?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  update?: Maybe<Array<VehicleDamageUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<VehicleDamageUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<VehicleDamageScalarWhereInput>>;
};

export type VehicleDamageUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleDamageCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleDamageCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<Array<VehicleDamageUpsertWithWhereUniqueWithoutVehicleInput>>;
  createMany?: Maybe<VehicleDamageCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleDamageWhereUniqueInput>>;
  update?: Maybe<Array<VehicleDamageUpdateWithWhereUniqueWithoutVehicleInput>>;
  updateMany?: Maybe<
    Array<VehicleDamageUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<VehicleDamageScalarWhereInput>>;
};

export type VehicleDamageUpdateOneRequiredWithoutVehicleDamageCommentInput = {
  create?: Maybe<VehicleDamageCreateWithoutVehicleDamageCommentInput>;
  connectOrCreate?: Maybe<VehicleDamageCreateOrConnectWithoutVehicleDamageCommentInput>;
  upsert?: Maybe<VehicleDamageUpsertWithoutVehicleDamageCommentInput>;
  connect?: Maybe<VehicleDamageWhereUniqueInput>;
  update?: Maybe<VehicleDamageUpdateWithoutVehicleDamageCommentInput>;
};

export type VehicleDamageUpdateOneWithoutVehiclePhotosInput = {
  create?: Maybe<VehicleDamageCreateWithoutVehiclePhotosInput>;
  connectOrCreate?: Maybe<VehicleDamageCreateOrConnectWithoutVehiclePhotosInput>;
  upsert?: Maybe<VehicleDamageUpsertWithoutVehiclePhotosInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleDamageWhereUniqueInput>;
  update?: Maybe<VehicleDamageUpdateWithoutVehiclePhotosInput>;
};

export type VehicleDamageUpdateWithWhereUniqueWithoutBookingInput = {
  where: VehicleDamageWhereUniqueInput;
  data: VehicleDamageUpdateWithoutBookingInput;
};

export type VehicleDamageUpdateWithWhereUniqueWithoutUserInput = {
  where: VehicleDamageWhereUniqueInput;
  data: VehicleDamageUpdateWithoutUserInput;
};

export type VehicleDamageUpdateWithWhereUniqueWithoutVehicleInput = {
  where: VehicleDamageWhereUniqueInput;
  data: VehicleDamageUpdateWithoutVehicleInput;
};

export type VehicleDamageUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableEnumVehicleDamageTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehicleDamageTypeFieldUpdateOperationsInput>;
  kind?: Maybe<NullableEnumVehicleDamageKindFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  isResolved?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehicleDamageInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutVehicleDamageInput>;
};

export type VehicleDamageUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableEnumVehicleDamageTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehicleDamageTypeFieldUpdateOperationsInput>;
  kind?: Maybe<NullableEnumVehicleDamageKindFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  isResolved?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleDamageInput>;
  booking?: Maybe<BookingUpdateOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutVehicleDamageInput>;
};

export type VehicleDamageUpdateWithoutVehicleDamageCommentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableEnumVehicleDamageTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehicleDamageTypeFieldUpdateOperationsInput>;
  kind?: Maybe<NullableEnumVehicleDamageKindFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  isResolved?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehicleDamageInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleDamageInput>;
  booking?: Maybe<BookingUpdateOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleDamageInput>;
};

export type VehicleDamageUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableEnumVehicleDamageTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehicleDamageTypeFieldUpdateOperationsInput>;
  kind?: Maybe<NullableEnumVehicleDamageKindFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  isResolved?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehicleDamageInput>;
  booking?: Maybe<BookingUpdateOneWithoutVehicleDamageInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutVehicleDamageInput>;
};

export type VehicleDamageUpdateWithoutVehiclePhotosInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableEnumVehicleDamageTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehicleDamageTypeFieldUpdateOperationsInput>;
  kind?: Maybe<NullableEnumVehicleDamageKindFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  isPublic?: Maybe<BoolFieldUpdateOperationsInput>;
  isResolved?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehicleDamageInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleDamageInput>;
  booking?: Maybe<BookingUpdateOneWithoutVehicleDamageInput>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentUpdateManyWithoutVehicleDamageInput>;
};

export type VehicleDamageUpsertWithWhereUniqueWithoutBookingInput = {
  where: VehicleDamageWhereUniqueInput;
  update: VehicleDamageUpdateWithoutBookingInput;
  create: VehicleDamageCreateWithoutBookingInput;
};

export type VehicleDamageUpsertWithWhereUniqueWithoutUserInput = {
  where: VehicleDamageWhereUniqueInput;
  update: VehicleDamageUpdateWithoutUserInput;
  create: VehicleDamageCreateWithoutUserInput;
};

export type VehicleDamageUpsertWithWhereUniqueWithoutVehicleInput = {
  where: VehicleDamageWhereUniqueInput;
  update: VehicleDamageUpdateWithoutVehicleInput;
  create: VehicleDamageCreateWithoutVehicleInput;
};

export type VehicleDamageUpsertWithoutVehicleDamageCommentInput = {
  update: VehicleDamageUpdateWithoutVehicleDamageCommentInput;
  create: VehicleDamageCreateWithoutVehicleDamageCommentInput;
};

export type VehicleDamageUpsertWithoutVehiclePhotosInput = {
  update: VehicleDamageUpdateWithoutVehiclePhotosInput;
  create: VehicleDamageCreateWithoutVehiclePhotosInput;
};

export type VehicleDamageWhereInput = {
  AND?: Maybe<Array<VehicleDamageWhereInput>>;
  OR?: Maybe<Array<VehicleDamageWhereInput>>;
  NOT?: Maybe<Array<VehicleDamageWhereInput>>;
  id?: Maybe<StringFilter>;
  time?: Maybe<EnumVehicleDamageTimeNullableFilter>;
  type?: Maybe<EnumVehicleDamageTypeFilter>;
  kind?: Maybe<EnumVehicleDamageKindNullableFilter>;
  description?: Maybe<StringFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  isPublic?: Maybe<BoolFilter>;
  isResolved?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  booking?: Maybe<BookingRelationFilter>;
  bookingId?: Maybe<StringNullableFilter>;
  vehiclePhotos?: Maybe<VehiclePhotoListRelationFilter>;
  vehicleDamageComment?: Maybe<VehicleDamageCommentListRelationFilter>;
};

export type VehicleDamageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleEquipment = {
  __typename?: 'VehicleEquipment';
  id: Scalars['String'];
  vehicleId: Scalars['String'];
  equipmentTypeId: Scalars['String'];
  vehicle: Vehicle;
  equipmentType: EquipmentType;
};

export type VehicleEquipmentCountAggregate = {
  __typename?: 'VehicleEquipmentCountAggregate';
  id: Scalars['Int'];
  vehicleId: Scalars['Int'];
  equipmentTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleEquipmentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  equipmentTypeId?: Maybe<SortOrder>;
};

export type VehicleEquipmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleEquipmentInput;
  equipmentType: EquipmentTypeCreateNestedOneWithoutVehicleEquipmentInput;
};

export type VehicleEquipmentCreateManyEquipmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  vehicleId: Scalars['String'];
};

export type VehicleEquipmentCreateManyEquipmentTypeInputEnvelope = {
  data: Array<VehicleEquipmentCreateManyEquipmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleEquipmentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  equipmentTypeId: Scalars['String'];
};

export type VehicleEquipmentCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  equipmentTypeId: Scalars['String'];
};

export type VehicleEquipmentCreateManyVehicleInputEnvelope = {
  data: Array<VehicleEquipmentCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleEquipmentCreateNestedManyWithoutEquipmentTypeInput = {
  create?: Maybe<Array<VehicleEquipmentCreateWithoutEquipmentTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleEquipmentCreateOrConnectWithoutEquipmentTypeInput>
  >;
  createMany?: Maybe<VehicleEquipmentCreateManyEquipmentTypeInputEnvelope>;
  connect?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
};

export type VehicleEquipmentCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleEquipmentCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleEquipmentCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleEquipmentCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
};

export type VehicleEquipmentCreateOrConnectWithoutEquipmentTypeInput = {
  where: VehicleEquipmentWhereUniqueInput;
  create: VehicleEquipmentCreateWithoutEquipmentTypeInput;
};

export type VehicleEquipmentCreateOrConnectWithoutVehicleInput = {
  where: VehicleEquipmentWhereUniqueInput;
  create: VehicleEquipmentCreateWithoutVehicleInput;
};

export type VehicleEquipmentCreateWithoutEquipmentTypeInput = {
  id?: Maybe<Scalars['String']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleEquipmentInput;
};

export type VehicleEquipmentCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  equipmentType: EquipmentTypeCreateNestedOneWithoutVehicleEquipmentInput;
};

export type VehicleEquipmentGroupBy = {
  __typename?: 'VehicleEquipmentGroupBy';
  id: Scalars['String'];
  vehicleId: Scalars['String'];
  equipmentTypeId: Scalars['String'];
  _count?: Maybe<VehicleEquipmentCountAggregate>;
  _min?: Maybe<VehicleEquipmentMinAggregate>;
  _max?: Maybe<VehicleEquipmentMaxAggregate>;
};

export type VehicleEquipmentListRelationFilter = {
  every?: Maybe<VehicleEquipmentWhereInput>;
  some?: Maybe<VehicleEquipmentWhereInput>;
  none?: Maybe<VehicleEquipmentWhereInput>;
};

export type VehicleEquipmentMaxAggregate = {
  __typename?: 'VehicleEquipmentMaxAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  equipmentTypeId?: Maybe<Scalars['String']>;
};

export type VehicleEquipmentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  equipmentTypeId?: Maybe<SortOrder>;
};

export type VehicleEquipmentMinAggregate = {
  __typename?: 'VehicleEquipmentMinAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  equipmentTypeId?: Maybe<Scalars['String']>;
};

export type VehicleEquipmentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  equipmentTypeId?: Maybe<SortOrder>;
};

export type VehicleEquipmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleEquipmentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  equipmentTypeId?: Maybe<SortOrder>;
  _count?: Maybe<VehicleEquipmentCountOrderByAggregateInput>;
  _max?: Maybe<VehicleEquipmentMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleEquipmentMinOrderByAggregateInput>;
};

export type VehicleEquipmentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  equipmentType?: Maybe<EquipmentTypeOrderByWithRelationInput>;
  equipmentTypeId?: Maybe<SortOrder>;
};

export enum VehicleEquipmentScalarFieldEnum {
  Id = 'id',
  VehicleId = 'vehicleId',
  EquipmentTypeId = 'equipmentTypeId',
}

export type VehicleEquipmentScalarWhereInput = {
  AND?: Maybe<Array<VehicleEquipmentScalarWhereInput>>;
  OR?: Maybe<Array<VehicleEquipmentScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleEquipmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicleId?: Maybe<StringFilter>;
  equipmentTypeId?: Maybe<StringFilter>;
};

export type VehicleEquipmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleEquipmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleEquipmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleEquipmentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
  equipmentTypeId?: Maybe<StringWithAggregatesFilter>;
};

export type VehicleEquipmentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleEquipmentInput>;
  equipmentType?: Maybe<EquipmentTypeUpdateOneRequiredWithoutVehicleEquipmentInput>;
};

export type VehicleEquipmentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type VehicleEquipmentUpdateManyWithWhereWithoutEquipmentTypeInput = {
  where: VehicleEquipmentScalarWhereInput;
  data: VehicleEquipmentUpdateManyMutationInput;
};

export type VehicleEquipmentUpdateManyWithWhereWithoutVehicleInput = {
  where: VehicleEquipmentScalarWhereInput;
  data: VehicleEquipmentUpdateManyMutationInput;
};

export type VehicleEquipmentUpdateManyWithoutEquipmentTypeInput = {
  create?: Maybe<Array<VehicleEquipmentCreateWithoutEquipmentTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleEquipmentCreateOrConnectWithoutEquipmentTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleEquipmentUpsertWithWhereUniqueWithoutEquipmentTypeInput>
  >;
  createMany?: Maybe<VehicleEquipmentCreateManyEquipmentTypeInputEnvelope>;
  set?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleEquipmentUpdateWithWhereUniqueWithoutEquipmentTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleEquipmentUpdateManyWithWhereWithoutEquipmentTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleEquipmentScalarWhereInput>>;
};

export type VehicleEquipmentUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleEquipmentCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleEquipmentCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<VehicleEquipmentUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleEquipmentCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleEquipmentWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleEquipmentUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<VehicleEquipmentUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<VehicleEquipmentScalarWhereInput>>;
};

export type VehicleEquipmentUpdateWithWhereUniqueWithoutEquipmentTypeInput = {
  where: VehicleEquipmentWhereUniqueInput;
  data: VehicleEquipmentUpdateWithoutEquipmentTypeInput;
};

export type VehicleEquipmentUpdateWithWhereUniqueWithoutVehicleInput = {
  where: VehicleEquipmentWhereUniqueInput;
  data: VehicleEquipmentUpdateWithoutVehicleInput;
};

export type VehicleEquipmentUpdateWithoutEquipmentTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleEquipmentInput>;
};

export type VehicleEquipmentUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  equipmentType?: Maybe<EquipmentTypeUpdateOneRequiredWithoutVehicleEquipmentInput>;
};

export type VehicleEquipmentUpsertWithWhereUniqueWithoutEquipmentTypeInput = {
  where: VehicleEquipmentWhereUniqueInput;
  update: VehicleEquipmentUpdateWithoutEquipmentTypeInput;
  create: VehicleEquipmentCreateWithoutEquipmentTypeInput;
};

export type VehicleEquipmentUpsertWithWhereUniqueWithoutVehicleInput = {
  where: VehicleEquipmentWhereUniqueInput;
  update: VehicleEquipmentUpdateWithoutVehicleInput;
  create: VehicleEquipmentCreateWithoutVehicleInput;
};

export type VehicleEquipmentWhereInput = {
  AND?: Maybe<Array<VehicleEquipmentWhereInput>>;
  OR?: Maybe<Array<VehicleEquipmentWhereInput>>;
  NOT?: Maybe<Array<VehicleEquipmentWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  equipmentType?: Maybe<EquipmentTypeRelationFilter>;
  equipmentTypeId?: Maybe<StringFilter>;
};

export type VehicleEquipmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleExtended = {
  __typename?: 'VehicleExtended';
  id: Scalars['String'];
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts: Scalars['Boolean'];
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  ownInsurance: Scalars['Boolean'];
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType: VehicleConnectionType;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleCount>;
  user?: Maybe<User>;
  vehicleDamage: Array<VehicleDamage>;
  fuelType?: Maybe<FuelType>;
  doorType?: Maybe<DoorType>;
  vehicleType?: Maybe<VehicleType>;
  vehicleKindType?: Maybe<VehicleKindType>;
  transmissionType?: Maybe<TransmissionType>;
  driveType?: Maybe<DriveType>;
  seatType?: Maybe<SeatType>;
  seatFrontType?: Maybe<SeatFrontType>;
  childSeatTypes: Array<ChildSeatType>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyType>;
  emissionCodeType?: Maybe<EmissionCodeType>;
  consumptionType?: Maybe<ConsumptionType>;
  shieldColorType?: Maybe<ShieldColorType>;
  particularUseType?: Maybe<ParticularUseType>;
  brandType?: Maybe<BrandType>;
  modelType?: Maybe<ModelType>;
  bodyType?: Maybe<BodyType>;
  colorType?: Maybe<ColorType>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceType>;
  company?: Maybe<Company>;
  vehiclePhotos: Array<VehiclePhoto>;
  bookings: Array<Booking>;
  bookingVehicleDamages: Array<BookingVehicleDamages>;
  vehicleEquipment: Array<VehicleEquipment>;
  vehicleCloudBoxxLog: Array<VehicleCloudBoxxLog>;
  vehicleLocation: Array<VehicleLocation>;
  vehicleCloudBoxxStatusLog: Array<VehicleCloudBoxxStatusLog>;
  availability: Array<Availability>;
  bookingQuestion: Array<BookingQuestion>;
  sharedVehicleGroupRelVehicle: Array<SharedVehicleGroupRelVehicle>;
  gateControllerRelVehicles: Array<GateControllerRelVehicle>;
  gateControllerTransactions: Array<GateControllerTransaction>;
  smartcarAuth: Array<SmartcarAuth>;
  vehicleConnection: Array<VehicleConnection>;
  defaultVehicleImage?: Maybe<Scalars['String']>;
  smartcarConnectAuthUrl?: Maybe<Scalars['String']>;
  isOwnerStripeOnboarded?: Maybe<Scalars['Boolean']>;
  distance?: Maybe<Scalars['Float']>;
  defaultVehicleLocation?: Maybe<VehicleLocation>;
  price?: Maybe<VehicleCalculation>;
};

export type VehicleExtendedVehicleDamageArgs = {
  where?: Maybe<VehicleDamageWhereInput>;
  orderBy?: Maybe<Array<VehicleDamageOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleDamageWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleDamageScalarFieldEnum>>;
};

export type VehicleExtendedChildSeatTypesArgs = {
  where?: Maybe<ChildSeatTypeWhereInput>;
  orderBy?: Maybe<Array<ChildSeatTypeOrderByWithRelationInput>>;
  cursor?: Maybe<ChildSeatTypeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ChildSeatTypeScalarFieldEnum>>;
};

export type VehicleExtendedVehiclePhotosArgs = {
  where?: Maybe<VehiclePhotoWhereInput>;
  orderBy?: Maybe<Array<VehiclePhotoOrderByWithRelationInput>>;
  cursor?: Maybe<VehiclePhotoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehiclePhotoScalarFieldEnum>>;
};

export type VehicleExtendedBookingsArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type VehicleExtendedBookingVehicleDamagesArgs = {
  where?: Maybe<BookingVehicleDamagesWhereInput>;
  orderBy?: Maybe<Array<BookingVehicleDamagesOrderByWithRelationInput>>;
  cursor?: Maybe<BookingVehicleDamagesWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingVehicleDamagesScalarFieldEnum>>;
};

export type VehicleExtendedVehicleEquipmentArgs = {
  where?: Maybe<VehicleEquipmentWhereInput>;
  orderBy?: Maybe<Array<VehicleEquipmentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleEquipmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleEquipmentScalarFieldEnum>>;
};

export type VehicleExtendedVehicleCloudBoxxLogArgs = {
  where?: Maybe<VehicleCloudBoxxLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxLogScalarFieldEnum>>;
};

export type VehicleExtendedVehicleLocationArgs = {
  where?: Maybe<VehicleLocationWhereInput>;
  orderBy?: Maybe<Array<VehicleLocationOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleLocationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleLocationScalarFieldEnum>>;
};

export type VehicleExtendedVehicleCloudBoxxStatusLogArgs = {
  where?: Maybe<VehicleCloudBoxxStatusLogWhereInput>;
  orderBy?: Maybe<Array<VehicleCloudBoxxStatusLogOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleCloudBoxxStatusLogWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleCloudBoxxStatusLogScalarFieldEnum>>;
};

export type VehicleExtendedAvailabilityArgs = {
  where?: Maybe<AvailabilityWhereInput>;
  orderBy?: Maybe<Array<AvailabilityOrderByWithRelationInput>>;
  cursor?: Maybe<AvailabilityWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AvailabilityScalarFieldEnum>>;
};

export type VehicleExtendedBookingQuestionArgs = {
  where?: Maybe<BookingQuestionWhereInput>;
  orderBy?: Maybe<Array<BookingQuestionOrderByWithRelationInput>>;
  cursor?: Maybe<BookingQuestionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingQuestionScalarFieldEnum>>;
};

export type VehicleExtendedSharedVehicleGroupRelVehicleArgs = {
  where?: Maybe<SharedVehicleGroupRelVehicleWhereInput>;
  orderBy?: Maybe<Array<SharedVehicleGroupRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<SharedVehicleGroupRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SharedVehicleGroupRelVehicleScalarFieldEnum>>;
};

export type VehicleExtendedGateControllerRelVehiclesArgs = {
  where?: Maybe<GateControllerRelVehicleWhereInput>;
  orderBy?: Maybe<Array<GateControllerRelVehicleOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerRelVehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerRelVehicleScalarFieldEnum>>;
};

export type VehicleExtendedGateControllerTransactionsArgs = {
  where?: Maybe<GateControllerTransactionWhereInput>;
  orderBy?: Maybe<Array<GateControllerTransactionOrderByWithRelationInput>>;
  cursor?: Maybe<GateControllerTransactionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<GateControllerTransactionScalarFieldEnum>>;
};

export type VehicleExtendedSmartcarAuthArgs = {
  where?: Maybe<SmartcarAuthWhereInput>;
  orderBy?: Maybe<Array<SmartcarAuthOrderByWithRelationInput>>;
  cursor?: Maybe<SmartcarAuthWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SmartcarAuthScalarFieldEnum>>;
};

export type VehicleExtendedVehicleConnectionArgs = {
  where?: Maybe<VehicleConnectionWhereInput>;
  orderBy?: Maybe<Array<VehicleConnectionOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleConnectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleConnectionScalarFieldEnum>>;
};

export type VehicleFilter = {
  __typename?: 'VehicleFilter';
  vehicleTypes: Array<VehicleType>;
  transmissionTypes: Array<TransmissionType>;
  fuelTypes: Array<FuelType>;
  driveTypes: Array<DriveType>;
  doorTypes: Array<DoorType>;
  seatTypes: Array<SeatType>;
  minPrice: Scalars['Float'];
  maxPrice: Scalars['Float'];
};

export type VehicleGroupBy = {
  __typename?: 'VehicleGroupBy';
  id: Scalars['String'];
  vin: Scalars['String'];
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts: Scalars['Boolean'];
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  ownInsurance: Scalars['Boolean'];
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType: VehicleConnectionType;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleCountAggregate>;
  _avg?: Maybe<VehicleAvgAggregate>;
  _sum?: Maybe<VehicleSumAggregate>;
  _min?: Maybe<VehicleMinAggregate>;
  _max?: Maybe<VehicleMaxAggregate>;
};

export type VehicleInsuranceDocument = {
  __typename?: 'VehicleInsuranceDocument';
  id: Scalars['String'];
  name: Scalars['String'];
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceType>;
};

export type VehicleInsuranceDocumentCountAggregate = {
  __typename?: 'VehicleInsuranceDocumentCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  vehicleInsuranceTypeId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleInsuranceDocumentCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
};

export type VehicleInsuranceDocumentCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeCreateNestedOneWithoutDocumentsInput>;
};

export type VehicleInsuranceDocumentCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type VehicleInsuranceDocumentCreateManyVehicleInsuranceTypeInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type VehicleInsuranceDocumentCreateManyVehicleInsuranceTypeInputEnvelope =
  {
    data: Array<VehicleInsuranceDocumentCreateManyVehicleInsuranceTypeInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type VehicleInsuranceDocumentCreateNestedManyWithoutVehicleInsuranceTypeInput =
  {
    create?: Maybe<
      Array<VehicleInsuranceDocumentCreateWithoutVehicleInsuranceTypeInput>
    >;
    connectOrCreate?: Maybe<
      Array<VehicleInsuranceDocumentCreateOrConnectWithoutVehicleInsuranceTypeInput>
    >;
    createMany?: Maybe<VehicleInsuranceDocumentCreateManyVehicleInsuranceTypeInputEnvelope>;
    connect?: Maybe<Array<VehicleInsuranceDocumentWhereUniqueInput>>;
  };

export type VehicleInsuranceDocumentCreateOrConnectWithoutVehicleInsuranceTypeInput =
  {
    where: VehicleInsuranceDocumentWhereUniqueInput;
    create: VehicleInsuranceDocumentCreateWithoutVehicleInsuranceTypeInput;
  };

export type VehicleInsuranceDocumentCreateWithoutVehicleInsuranceTypeInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type VehicleInsuranceDocumentGroupBy = {
  __typename?: 'VehicleInsuranceDocumentGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleInsuranceDocumentCountAggregate>;
  _min?: Maybe<VehicleInsuranceDocumentMinAggregate>;
  _max?: Maybe<VehicleInsuranceDocumentMaxAggregate>;
};

export type VehicleInsuranceDocumentListRelationFilter = {
  every?: Maybe<VehicleInsuranceDocumentWhereInput>;
  some?: Maybe<VehicleInsuranceDocumentWhereInput>;
  none?: Maybe<VehicleInsuranceDocumentWhereInput>;
};

export type VehicleInsuranceDocumentMaxAggregate = {
  __typename?: 'VehicleInsuranceDocumentMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type VehicleInsuranceDocumentMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
};

export type VehicleInsuranceDocumentMinAggregate = {
  __typename?: 'VehicleInsuranceDocumentMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
};

export type VehicleInsuranceDocumentMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
};

export type VehicleInsuranceDocumentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleInsuranceDocumentOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  _count?: Maybe<VehicleInsuranceDocumentCountOrderByAggregateInput>;
  _max?: Maybe<VehicleInsuranceDocumentMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleInsuranceDocumentMinOrderByAggregateInput>;
};

export type VehicleInsuranceDocumentOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeOrderByWithRelationInput>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
};

export enum VehicleInsuranceDocumentScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  VehicleInsuranceTypeId = 'vehicleInsuranceTypeId',
}

export type VehicleInsuranceDocumentScalarWhereInput = {
  AND?: Maybe<Array<VehicleInsuranceDocumentScalarWhereInput>>;
  OR?: Maybe<Array<VehicleInsuranceDocumentScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleInsuranceDocumentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableFilter>;
};

export type VehicleInsuranceDocumentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleInsuranceDocumentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleInsuranceDocumentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleInsuranceDocumentScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VehicleInsuranceDocumentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutDocumentsInput>;
};

export type VehicleInsuranceDocumentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type VehicleInsuranceDocumentUpdateManyWithWhereWithoutVehicleInsuranceTypeInput =
  {
    where: VehicleInsuranceDocumentScalarWhereInput;
    data: VehicleInsuranceDocumentUpdateManyMutationInput;
  };

export type VehicleInsuranceDocumentUpdateManyWithoutVehicleInsuranceTypeInput =
  {
    create?: Maybe<
      Array<VehicleInsuranceDocumentCreateWithoutVehicleInsuranceTypeInput>
    >;
    connectOrCreate?: Maybe<
      Array<VehicleInsuranceDocumentCreateOrConnectWithoutVehicleInsuranceTypeInput>
    >;
    upsert?: Maybe<
      Array<VehicleInsuranceDocumentUpsertWithWhereUniqueWithoutVehicleInsuranceTypeInput>
    >;
    createMany?: Maybe<VehicleInsuranceDocumentCreateManyVehicleInsuranceTypeInputEnvelope>;
    set?: Maybe<Array<VehicleInsuranceDocumentWhereUniqueInput>>;
    disconnect?: Maybe<Array<VehicleInsuranceDocumentWhereUniqueInput>>;
    delete?: Maybe<Array<VehicleInsuranceDocumentWhereUniqueInput>>;
    connect?: Maybe<Array<VehicleInsuranceDocumentWhereUniqueInput>>;
    update?: Maybe<
      Array<VehicleInsuranceDocumentUpdateWithWhereUniqueWithoutVehicleInsuranceTypeInput>
    >;
    updateMany?: Maybe<
      Array<VehicleInsuranceDocumentUpdateManyWithWhereWithoutVehicleInsuranceTypeInput>
    >;
    deleteMany?: Maybe<Array<VehicleInsuranceDocumentScalarWhereInput>>;
  };

export type VehicleInsuranceDocumentUpdateWithWhereUniqueWithoutVehicleInsuranceTypeInput =
  {
    where: VehicleInsuranceDocumentWhereUniqueInput;
    data: VehicleInsuranceDocumentUpdateWithoutVehicleInsuranceTypeInput;
  };

export type VehicleInsuranceDocumentUpdateWithoutVehicleInsuranceTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type VehicleInsuranceDocumentUpsertWithWhereUniqueWithoutVehicleInsuranceTypeInput =
  {
    where: VehicleInsuranceDocumentWhereUniqueInput;
    update: VehicleInsuranceDocumentUpdateWithoutVehicleInsuranceTypeInput;
    create: VehicleInsuranceDocumentCreateWithoutVehicleInsuranceTypeInput;
  };

export type VehicleInsuranceDocumentWhereInput = {
  AND?: Maybe<Array<VehicleInsuranceDocumentWhereInput>>;
  OR?: Maybe<Array<VehicleInsuranceDocumentWhereInput>>;
  NOT?: Maybe<Array<VehicleInsuranceDocumentWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeRelationFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableFilter>;
};

export type VehicleInsuranceDocumentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleInsuranceRule = {
  __typename?: 'VehicleInsuranceRule';
  id: Scalars['String'];
  vehicleInsuranceRuleSetId: Scalars['String'];
  property: RuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
  vehicleInsuranceRuleSet: VehicleInsuranceRuleSet;
};

export type VehicleInsuranceRuleAvgAggregate = {
  __typename?: 'VehicleInsuranceRuleAvgAggregate';
  fromValue?: Maybe<Scalars['Float']>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleAvgOrderByAggregateInput = {
  fromValue?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleCountAggregate = {
  __typename?: 'VehicleInsuranceRuleCountAggregate';
  id: Scalars['Int'];
  vehicleInsuranceRuleSetId: Scalars['Int'];
  property: Scalars['Int'];
  fromCompareOperator: Scalars['Int'];
  fromValue: Scalars['Int'];
  toCompareOperator: Scalars['Int'];
  toValue: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleInsuranceRuleCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleCreateInput = {
  id?: Maybe<Scalars['String']>;
  vehicleInsuranceRuleSet: VehicleInsuranceRuleSetCreateNestedOneWithoutVehicleInsuranceRulesInput;
  property: RuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  vehicleInsuranceRuleSetId: Scalars['String'];
  property: RuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleCreateManyVehicleInsuranceRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  property: RuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleCreateManyVehicleInsuranceRuleSetInputEnvelope =
  {
    data: Array<VehicleInsuranceRuleCreateManyVehicleInsuranceRuleSetInput>;
    skipDuplicates?: Maybe<Scalars['Boolean']>;
  };

export type VehicleInsuranceRuleCreateNestedManyWithoutVehicleInsuranceRuleSetInput =
  {
    create?: Maybe<
      Array<VehicleInsuranceRuleCreateWithoutVehicleInsuranceRuleSetInput>
    >;
    connectOrCreate?: Maybe<
      Array<VehicleInsuranceRuleCreateOrConnectWithoutVehicleInsuranceRuleSetInput>
    >;
    createMany?: Maybe<VehicleInsuranceRuleCreateManyVehicleInsuranceRuleSetInputEnvelope>;
    connect?: Maybe<Array<VehicleInsuranceRuleWhereUniqueInput>>;
  };

export type VehicleInsuranceRuleCreateOrConnectWithoutVehicleInsuranceRuleSetInput =
  {
    where: VehicleInsuranceRuleWhereUniqueInput;
    create: VehicleInsuranceRuleCreateWithoutVehicleInsuranceRuleSetInput;
  };

export type VehicleInsuranceRuleCreateWithoutVehicleInsuranceRuleSetInput = {
  id?: Maybe<Scalars['String']>;
  property: RuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleGroupBy = {
  __typename?: 'VehicleInsuranceRuleGroupBy';
  id: Scalars['String'];
  vehicleInsuranceRuleSetId: Scalars['String'];
  property: RuleProperty;
  fromCompareOperator: RuleCompareOperator;
  fromValue: Scalars['Float'];
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
  _count?: Maybe<VehicleInsuranceRuleCountAggregate>;
  _avg?: Maybe<VehicleInsuranceRuleAvgAggregate>;
  _sum?: Maybe<VehicleInsuranceRuleSumAggregate>;
  _min?: Maybe<VehicleInsuranceRuleMinAggregate>;
  _max?: Maybe<VehicleInsuranceRuleMaxAggregate>;
};

export type VehicleInsuranceRuleListRelationFilter = {
  every?: Maybe<VehicleInsuranceRuleWhereInput>;
  some?: Maybe<VehicleInsuranceRuleWhereInput>;
  none?: Maybe<VehicleInsuranceRuleWhereInput>;
};

export type VehicleInsuranceRuleMaxAggregate = {
  __typename?: 'VehicleInsuranceRuleMaxAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleInsuranceRuleSetId?: Maybe<Scalars['String']>;
  property?: Maybe<RuleProperty>;
  fromCompareOperator?: Maybe<RuleCompareOperator>;
  fromValue?: Maybe<Scalars['Float']>;
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleMinAggregate = {
  __typename?: 'VehicleInsuranceRuleMinAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleInsuranceRuleSetId?: Maybe<Scalars['String']>;
  property?: Maybe<RuleProperty>;
  fromCompareOperator?: Maybe<RuleCompareOperator>;
  fromValue?: Maybe<Scalars['Float']>;
  toCompareOperator?: Maybe<RuleCompareOperator>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
  _count?: Maybe<VehicleInsuranceRuleCountOrderByAggregateInput>;
  _avg?: Maybe<VehicleInsuranceRuleAvgOrderByAggregateInput>;
  _max?: Maybe<VehicleInsuranceRuleMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleInsuranceRuleMinOrderByAggregateInput>;
  _sum?: Maybe<VehicleInsuranceRuleSumOrderByAggregateInput>;
};

export type VehicleInsuranceRuleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceRuleSet?: Maybe<VehicleInsuranceRuleSetOrderByWithRelationInput>;
  vehicleInsuranceRuleSetId?: Maybe<SortOrder>;
  property?: Maybe<SortOrder>;
  fromCompareOperator?: Maybe<SortOrder>;
  fromValue?: Maybe<SortOrder>;
  toCompareOperator?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export enum VehicleInsuranceRuleScalarFieldEnum {
  Id = 'id',
  VehicleInsuranceRuleSetId = 'vehicleInsuranceRuleSetId',
  Property = 'property',
  FromCompareOperator = 'fromCompareOperator',
  FromValue = 'fromValue',
  ToCompareOperator = 'toCompareOperator',
  ToValue = 'toValue',
}

export type VehicleInsuranceRuleScalarWhereInput = {
  AND?: Maybe<Array<VehicleInsuranceRuleScalarWhereInput>>;
  OR?: Maybe<Array<VehicleInsuranceRuleScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleInsuranceRuleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicleInsuranceRuleSetId?: Maybe<StringFilter>;
  property?: Maybe<EnumRulePropertyFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFilter>;
  fromValue?: Maybe<FloatFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableFilter>;
  toValue?: Maybe<FloatNullableFilter>;
};

export type VehicleInsuranceRuleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleInsuranceRuleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleInsuranceRuleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleInsuranceRuleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  vehicleInsuranceRuleSetId?: Maybe<StringWithAggregatesFilter>;
  property?: Maybe<EnumRulePropertyWithAggregatesFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorWithAggregatesFilter>;
  fromValue?: Maybe<FloatWithAggregatesFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableWithAggregatesFilter>;
  toValue?: Maybe<FloatNullableWithAggregatesFilter>;
};

export type VehicleInsuranceRuleSet = {
  __typename?: 'VehicleInsuranceRuleSet';
  id: Scalars['String'];
  vehicleInsuranceId: Scalars['String'];
  ownership: Ownership;
  deductiblePerCollision: Scalars['Float'];
  deductiblePerPartialCover: Scalars['Float'];
  amount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<VehicleInsuranceRuleSetCount>;
  vehicleInsurance: VehicleInsuranceType;
  vehicleInsuranceRules: Array<VehicleInsuranceRule>;
};

export type VehicleInsuranceRuleSetVehicleInsuranceRulesArgs = {
  where?: Maybe<VehicleInsuranceRuleWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceRuleScalarFieldEnum>>;
};

export type VehicleInsuranceRuleSetAvgAggregate = {
  __typename?: 'VehicleInsuranceRuleSetAvgAggregate';
  deductiblePerCollision?: Maybe<Scalars['Float']>;
  deductiblePerPartialCover?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleSetAvgOrderByAggregateInput = {
  deductiblePerCollision?: Maybe<SortOrder>;
  deductiblePerPartialCover?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleSetCount = {
  __typename?: 'VehicleInsuranceRuleSetCount';
  vehicleInsuranceRules: Scalars['Int'];
};

export type VehicleInsuranceRuleSetCountAggregate = {
  __typename?: 'VehicleInsuranceRuleSetCountAggregate';
  id: Scalars['Int'];
  vehicleInsuranceId: Scalars['Int'];
  ownership: Scalars['Int'];
  deductiblePerCollision: Scalars['Int'];
  deductiblePerPartialCover: Scalars['Int'];
  amount: Scalars['Int'];
  updatedAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleInsuranceRuleSetCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  deductiblePerCollision?: Maybe<SortOrder>;
  deductiblePerPartialCover?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleSetCreateInput = {
  id?: Maybe<Scalars['String']>;
  vehicleInsurance: VehicleInsuranceTypeCreateNestedOneWithoutVehicleInsuranceRuleSetsInput;
  ownership: Ownership;
  deductiblePerCollision: Scalars['Float'];
  deductiblePerPartialCover: Scalars['Float'];
  amount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vehicleInsuranceRules?: Maybe<VehicleInsuranceRuleCreateNestedManyWithoutVehicleInsuranceRuleSetInput>;
};

export type VehicleInsuranceRuleSetCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  vehicleInsuranceId: Scalars['String'];
  ownership: Ownership;
  deductiblePerCollision: Scalars['Float'];
  deductiblePerPartialCover: Scalars['Float'];
  amount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleInsuranceRuleSetCreateManyVehicleInsuranceInput = {
  id?: Maybe<Scalars['String']>;
  ownership: Ownership;
  deductiblePerCollision: Scalars['Float'];
  deductiblePerPartialCover: Scalars['Float'];
  amount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleInsuranceRuleSetCreateManyVehicleInsuranceInputEnvelope = {
  data: Array<VehicleInsuranceRuleSetCreateManyVehicleInsuranceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleInsuranceRuleSetCreateNestedManyWithoutVehicleInsuranceInput =
  {
    create?: Maybe<
      Array<VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceInput>
    >;
    connectOrCreate?: Maybe<
      Array<VehicleInsuranceRuleSetCreateOrConnectWithoutVehicleInsuranceInput>
    >;
    createMany?: Maybe<VehicleInsuranceRuleSetCreateManyVehicleInsuranceInputEnvelope>;
    connect?: Maybe<Array<VehicleInsuranceRuleSetWhereUniqueInput>>;
  };

export type VehicleInsuranceRuleSetCreateNestedOneWithoutVehicleInsuranceRulesInput =
  {
    create?: Maybe<VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceRulesInput>;
    connectOrCreate?: Maybe<VehicleInsuranceRuleSetCreateOrConnectWithoutVehicleInsuranceRulesInput>;
    connect?: Maybe<VehicleInsuranceRuleSetWhereUniqueInput>;
  };

export type VehicleInsuranceRuleSetCreateOrConnectWithoutVehicleInsuranceInput =
  {
    where: VehicleInsuranceRuleSetWhereUniqueInput;
    create: VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceInput;
  };

export type VehicleInsuranceRuleSetCreateOrConnectWithoutVehicleInsuranceRulesInput =
  {
    where: VehicleInsuranceRuleSetWhereUniqueInput;
    create: VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceRulesInput;
  };

export type VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceInput = {
  id?: Maybe<Scalars['String']>;
  ownership: Ownership;
  deductiblePerCollision: Scalars['Float'];
  deductiblePerPartialCover: Scalars['Float'];
  amount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vehicleInsuranceRules?: Maybe<VehicleInsuranceRuleCreateNestedManyWithoutVehicleInsuranceRuleSetInput>;
};

export type VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceRulesInput = {
  id?: Maybe<Scalars['String']>;
  vehicleInsurance: VehicleInsuranceTypeCreateNestedOneWithoutVehicleInsuranceRuleSetsInput;
  ownership: Ownership;
  deductiblePerCollision: Scalars['Float'];
  deductiblePerPartialCover: Scalars['Float'];
  amount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleInsuranceRuleSetGroupBy = {
  __typename?: 'VehicleInsuranceRuleSetGroupBy';
  id: Scalars['String'];
  vehicleInsuranceId: Scalars['String'];
  ownership: Ownership;
  deductiblePerCollision: Scalars['Float'];
  deductiblePerPartialCover: Scalars['Float'];
  amount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  _count?: Maybe<VehicleInsuranceRuleSetCountAggregate>;
  _avg?: Maybe<VehicleInsuranceRuleSetAvgAggregate>;
  _sum?: Maybe<VehicleInsuranceRuleSetSumAggregate>;
  _min?: Maybe<VehicleInsuranceRuleSetMinAggregate>;
  _max?: Maybe<VehicleInsuranceRuleSetMaxAggregate>;
};

export type VehicleInsuranceRuleSetListRelationFilter = {
  every?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  some?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  none?: Maybe<VehicleInsuranceRuleSetWhereInput>;
};

export type VehicleInsuranceRuleSetMaxAggregate = {
  __typename?: 'VehicleInsuranceRuleSetMaxAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleInsuranceId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  deductiblePerCollision?: Maybe<Scalars['Float']>;
  deductiblePerPartialCover?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleInsuranceRuleSetMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  deductiblePerCollision?: Maybe<SortOrder>;
  deductiblePerPartialCover?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleSetMinAggregate = {
  __typename?: 'VehicleInsuranceRuleSetMinAggregate';
  id?: Maybe<Scalars['String']>;
  vehicleInsuranceId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  deductiblePerCollision?: Maybe<Scalars['Float']>;
  deductiblePerPartialCover?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VehicleInsuranceRuleSetMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  deductiblePerCollision?: Maybe<SortOrder>;
  deductiblePerPartialCover?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleSetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleSetOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  vehicleInsuranceId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  deductiblePerCollision?: Maybe<SortOrder>;
  deductiblePerPartialCover?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  _count?: Maybe<VehicleInsuranceRuleSetCountOrderByAggregateInput>;
  _avg?: Maybe<VehicleInsuranceRuleSetAvgOrderByAggregateInput>;
  _max?: Maybe<VehicleInsuranceRuleSetMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleInsuranceRuleSetMinOrderByAggregateInput>;
  _sum?: Maybe<VehicleInsuranceRuleSetSumOrderByAggregateInput>;
};

export type VehicleInsuranceRuleSetOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  vehicleInsurance?: Maybe<VehicleInsuranceTypeOrderByWithRelationInput>;
  vehicleInsuranceId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  deductiblePerCollision?: Maybe<SortOrder>;
  deductiblePerPartialCover?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vehicleInsuranceRules?: Maybe<VehicleInsuranceRuleOrderByRelationAggregateInput>;
};

export type VehicleInsuranceRuleSetRelationFilter = {
  is?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  isNot?: Maybe<VehicleInsuranceRuleSetWhereInput>;
};

export enum VehicleInsuranceRuleSetScalarFieldEnum {
  Id = 'id',
  VehicleInsuranceId = 'vehicleInsuranceId',
  Ownership = 'ownership',
  DeductiblePerCollision = 'deductiblePerCollision',
  DeductiblePerPartialCover = 'deductiblePerPartialCover',
  Amount = 'amount',
  UpdatedAt = 'updatedAt',
}

export type VehicleInsuranceRuleSetScalarWhereInput = {
  AND?: Maybe<Array<VehicleInsuranceRuleSetScalarWhereInput>>;
  OR?: Maybe<Array<VehicleInsuranceRuleSetScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleInsuranceRuleSetScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicleInsuranceId?: Maybe<StringFilter>;
  ownership?: Maybe<EnumOwnershipFilter>;
  deductiblePerCollision?: Maybe<FloatFilter>;
  deductiblePerPartialCover?: Maybe<FloatFilter>;
  amount?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VehicleInsuranceRuleSetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleInsuranceRuleSetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleInsuranceRuleSetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleInsuranceRuleSetScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  vehicleInsuranceId?: Maybe<StringWithAggregatesFilter>;
  ownership?: Maybe<EnumOwnershipWithAggregatesFilter>;
  deductiblePerCollision?: Maybe<FloatWithAggregatesFilter>;
  deductiblePerPartialCover?: Maybe<FloatWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VehicleInsuranceRuleSetSumAggregate = {
  __typename?: 'VehicleInsuranceRuleSetSumAggregate';
  deductiblePerCollision?: Maybe<Scalars['Float']>;
  deductiblePerPartialCover?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleSetSumOrderByAggregateInput = {
  deductiblePerCollision?: Maybe<SortOrder>;
  deductiblePerPartialCover?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleSetUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicleInsurance?: Maybe<VehicleInsuranceTypeUpdateOneRequiredWithoutVehicleInsuranceRuleSetsInput>;
  ownership?: Maybe<EnumOwnershipFieldUpdateOperationsInput>;
  deductiblePerCollision?: Maybe<FloatFieldUpdateOperationsInput>;
  deductiblePerPartialCover?: Maybe<FloatFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vehicleInsuranceRules?: Maybe<VehicleInsuranceRuleUpdateManyWithoutVehicleInsuranceRuleSetInput>;
};

export type VehicleInsuranceRuleSetUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ownership?: Maybe<EnumOwnershipFieldUpdateOperationsInput>;
  deductiblePerCollision?: Maybe<FloatFieldUpdateOperationsInput>;
  deductiblePerPartialCover?: Maybe<FloatFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleInsuranceRuleSetUpdateManyWithWhereWithoutVehicleInsuranceInput =
  {
    where: VehicleInsuranceRuleSetScalarWhereInput;
    data: VehicleInsuranceRuleSetUpdateManyMutationInput;
  };

export type VehicleInsuranceRuleSetUpdateManyWithoutVehicleInsuranceInput = {
  create?: Maybe<
    Array<VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceInput>
  >;
  connectOrCreate?: Maybe<
    Array<VehicleInsuranceRuleSetCreateOrConnectWithoutVehicleInsuranceInput>
  >;
  upsert?: Maybe<
    Array<VehicleInsuranceRuleSetUpsertWithWhereUniqueWithoutVehicleInsuranceInput>
  >;
  createMany?: Maybe<VehicleInsuranceRuleSetCreateManyVehicleInsuranceInputEnvelope>;
  set?: Maybe<Array<VehicleInsuranceRuleSetWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleInsuranceRuleSetWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleInsuranceRuleSetWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleInsuranceRuleSetWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleInsuranceRuleSetUpdateWithWhereUniqueWithoutVehicleInsuranceInput>
  >;
  updateMany?: Maybe<
    Array<VehicleInsuranceRuleSetUpdateManyWithWhereWithoutVehicleInsuranceInput>
  >;
  deleteMany?: Maybe<Array<VehicleInsuranceRuleSetScalarWhereInput>>;
};

export type VehicleInsuranceRuleSetUpdateOneRequiredWithoutVehicleInsuranceRulesInput =
  {
    create?: Maybe<VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceRulesInput>;
    connectOrCreate?: Maybe<VehicleInsuranceRuleSetCreateOrConnectWithoutVehicleInsuranceRulesInput>;
    upsert?: Maybe<VehicleInsuranceRuleSetUpsertWithoutVehicleInsuranceRulesInput>;
    connect?: Maybe<VehicleInsuranceRuleSetWhereUniqueInput>;
    update?: Maybe<VehicleInsuranceRuleSetUpdateWithoutVehicleInsuranceRulesInput>;
  };

export type VehicleInsuranceRuleSetUpdateWithWhereUniqueWithoutVehicleInsuranceInput =
  {
    where: VehicleInsuranceRuleSetWhereUniqueInput;
    data: VehicleInsuranceRuleSetUpdateWithoutVehicleInsuranceInput;
  };

export type VehicleInsuranceRuleSetUpdateWithoutVehicleInsuranceInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ownership?: Maybe<EnumOwnershipFieldUpdateOperationsInput>;
  deductiblePerCollision?: Maybe<FloatFieldUpdateOperationsInput>;
  deductiblePerPartialCover?: Maybe<FloatFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vehicleInsuranceRules?: Maybe<VehicleInsuranceRuleUpdateManyWithoutVehicleInsuranceRuleSetInput>;
};

export type VehicleInsuranceRuleSetUpdateWithoutVehicleInsuranceRulesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicleInsurance?: Maybe<VehicleInsuranceTypeUpdateOneRequiredWithoutVehicleInsuranceRuleSetsInput>;
  ownership?: Maybe<EnumOwnershipFieldUpdateOperationsInput>;
  deductiblePerCollision?: Maybe<FloatFieldUpdateOperationsInput>;
  deductiblePerPartialCover?: Maybe<FloatFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehicleInsuranceRuleSetUpsertWithWhereUniqueWithoutVehicleInsuranceInput =
  {
    where: VehicleInsuranceRuleSetWhereUniqueInput;
    update: VehicleInsuranceRuleSetUpdateWithoutVehicleInsuranceInput;
    create: VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceInput;
  };

export type VehicleInsuranceRuleSetUpsertWithoutVehicleInsuranceRulesInput = {
  update: VehicleInsuranceRuleSetUpdateWithoutVehicleInsuranceRulesInput;
  create: VehicleInsuranceRuleSetCreateWithoutVehicleInsuranceRulesInput;
};

export type VehicleInsuranceRuleSetWhereInput = {
  AND?: Maybe<Array<VehicleInsuranceRuleSetWhereInput>>;
  OR?: Maybe<Array<VehicleInsuranceRuleSetWhereInput>>;
  NOT?: Maybe<Array<VehicleInsuranceRuleSetWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicleInsurance?: Maybe<VehicleInsuranceTypeRelationFilter>;
  vehicleInsuranceId?: Maybe<StringFilter>;
  ownership?: Maybe<EnumOwnershipFilter>;
  deductiblePerCollision?: Maybe<FloatFilter>;
  deductiblePerPartialCover?: Maybe<FloatFilter>;
  amount?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vehicleInsuranceRules?: Maybe<VehicleInsuranceRuleListRelationFilter>;
};

export type VehicleInsuranceRuleSetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleInsuranceRuleSumAggregate = {
  __typename?: 'VehicleInsuranceRuleSumAggregate';
  fromValue?: Maybe<Scalars['Float']>;
  toValue?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceRuleSumOrderByAggregateInput = {
  fromValue?: Maybe<SortOrder>;
  toValue?: Maybe<SortOrder>;
};

export type VehicleInsuranceRuleUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vehicleInsuranceRuleSet?: Maybe<VehicleInsuranceRuleSetUpdateOneRequiredWithoutVehicleInsuranceRulesInput>;
  property?: Maybe<EnumRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type VehicleInsuranceRuleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<EnumRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type VehicleInsuranceRuleUpdateManyWithWhereWithoutVehicleInsuranceRuleSetInput =
  {
    where: VehicleInsuranceRuleScalarWhereInput;
    data: VehicleInsuranceRuleUpdateManyMutationInput;
  };

export type VehicleInsuranceRuleUpdateManyWithoutVehicleInsuranceRuleSetInput =
  {
    create?: Maybe<
      Array<VehicleInsuranceRuleCreateWithoutVehicleInsuranceRuleSetInput>
    >;
    connectOrCreate?: Maybe<
      Array<VehicleInsuranceRuleCreateOrConnectWithoutVehicleInsuranceRuleSetInput>
    >;
    upsert?: Maybe<
      Array<VehicleInsuranceRuleUpsertWithWhereUniqueWithoutVehicleInsuranceRuleSetInput>
    >;
    createMany?: Maybe<VehicleInsuranceRuleCreateManyVehicleInsuranceRuleSetInputEnvelope>;
    set?: Maybe<Array<VehicleInsuranceRuleWhereUniqueInput>>;
    disconnect?: Maybe<Array<VehicleInsuranceRuleWhereUniqueInput>>;
    delete?: Maybe<Array<VehicleInsuranceRuleWhereUniqueInput>>;
    connect?: Maybe<Array<VehicleInsuranceRuleWhereUniqueInput>>;
    update?: Maybe<
      Array<VehicleInsuranceRuleUpdateWithWhereUniqueWithoutVehicleInsuranceRuleSetInput>
    >;
    updateMany?: Maybe<
      Array<VehicleInsuranceRuleUpdateManyWithWhereWithoutVehicleInsuranceRuleSetInput>
    >;
    deleteMany?: Maybe<Array<VehicleInsuranceRuleScalarWhereInput>>;
  };

export type VehicleInsuranceRuleUpdateWithWhereUniqueWithoutVehicleInsuranceRuleSetInput =
  {
    where: VehicleInsuranceRuleWhereUniqueInput;
    data: VehicleInsuranceRuleUpdateWithoutVehicleInsuranceRuleSetInput;
  };

export type VehicleInsuranceRuleUpdateWithoutVehicleInsuranceRuleSetInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<EnumRulePropertyFieldUpdateOperationsInput>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFieldUpdateOperationsInput>;
  fromValue?: Maybe<FloatFieldUpdateOperationsInput>;
  toCompareOperator?: Maybe<NullableEnumRuleCompareOperatorFieldUpdateOperationsInput>;
  toValue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type VehicleInsuranceRuleUpsertWithWhereUniqueWithoutVehicleInsuranceRuleSetInput =
  {
    where: VehicleInsuranceRuleWhereUniqueInput;
    update: VehicleInsuranceRuleUpdateWithoutVehicleInsuranceRuleSetInput;
    create: VehicleInsuranceRuleCreateWithoutVehicleInsuranceRuleSetInput;
  };

export type VehicleInsuranceRuleWhereInput = {
  AND?: Maybe<Array<VehicleInsuranceRuleWhereInput>>;
  OR?: Maybe<Array<VehicleInsuranceRuleWhereInput>>;
  NOT?: Maybe<Array<VehicleInsuranceRuleWhereInput>>;
  id?: Maybe<StringFilter>;
  vehicleInsuranceRuleSet?: Maybe<VehicleInsuranceRuleSetRelationFilter>;
  vehicleInsuranceRuleSetId?: Maybe<StringFilter>;
  property?: Maybe<EnumRulePropertyFilter>;
  fromCompareOperator?: Maybe<EnumRuleCompareOperatorFilter>;
  fromValue?: Maybe<FloatFilter>;
  toCompareOperator?: Maybe<EnumRuleCompareOperatorNullableFilter>;
  toValue?: Maybe<FloatNullableFilter>;
};

export type VehicleInsuranceRuleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleInsuranceType = {
  __typename?: 'VehicleInsuranceType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting: Scalars['Boolean'];
  reportingInterval: InsuranceReporting;
  reportingDay: Scalars['Int'];
  isDefault?: Maybe<Scalars['Boolean']>;
  _count?: Maybe<VehicleInsuranceTypeCount>;
  vehicles: Array<Vehicle>;
  documents: Array<VehicleInsuranceDocument>;
  vehicleInsuranceRuleSets: Array<VehicleInsuranceRuleSet>;
  bookings: Array<Booking>;
};

export type VehicleInsuranceTypeVehiclesArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type VehicleInsuranceTypeDocumentsArgs = {
  where?: Maybe<VehicleInsuranceDocumentWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceDocumentOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceDocumentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceDocumentScalarFieldEnum>>;
};

export type VehicleInsuranceTypeVehicleInsuranceRuleSetsArgs = {
  where?: Maybe<VehicleInsuranceRuleSetWhereInput>;
  orderBy?: Maybe<Array<VehicleInsuranceRuleSetOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleInsuranceRuleSetWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleInsuranceRuleSetScalarFieldEnum>>;
};

export type VehicleInsuranceTypeBookingsArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type VehicleInsuranceTypeAvgAggregate = {
  __typename?: 'VehicleInsuranceTypeAvgAggregate';
  reportingDay?: Maybe<Scalars['Float']>;
};

export type VehicleInsuranceTypeAvgOrderByAggregateInput = {
  reportingDay?: Maybe<SortOrder>;
};

export type VehicleInsuranceTypeCount = {
  __typename?: 'VehicleInsuranceTypeCount';
  vehicles: Scalars['Int'];
  documents: Scalars['Int'];
  vehicleInsuranceRuleSets: Scalars['Int'];
  bookings: Scalars['Int'];
};

export type VehicleInsuranceTypeCountAggregate = {
  __typename?: 'VehicleInsuranceTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  contentVAG: Scalars['Int'];
  contentLinks: Scalars['Int'];
  showVAG: Scalars['Int'];
  isPrivate: Scalars['Int'];
  policyNumber: Scalars['Int'];
  policyHolder: Scalars['Int'];
  postalAddress: Scalars['Int'];
  billingAddress: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  emailAddress: Scalars['Int'];
  enabledReporting: Scalars['Int'];
  reportingInterval: Scalars['Int'];
  reportingDay: Scalars['Int'];
  isDefault: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleInsuranceTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  contentVAG?: Maybe<SortOrder>;
  contentLinks?: Maybe<SortOrder>;
  showVAG?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  policyNumber?: Maybe<SortOrder>;
  policyHolder?: Maybe<SortOrder>;
  postalAddress?: Maybe<SortOrder>;
  billingAddress?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  emailAddress?: Maybe<SortOrder>;
  enabledReporting?: Maybe<SortOrder>;
  reportingInterval?: Maybe<SortOrder>;
  reportingDay?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
};

export type VehicleInsuranceTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  documents?: Maybe<VehicleInsuranceDocumentCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetCreateNestedManyWithoutVehicleInsuranceInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type VehicleInsuranceTypeCreateNestedOneWithoutBookingsInput = {
  create?: Maybe<VehicleInsuranceTypeCreateWithoutBookingsInput>;
  connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutBookingsInput>;
  connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
};

export type VehicleInsuranceTypeCreateNestedOneWithoutDocumentsInput = {
  create?: Maybe<VehicleInsuranceTypeCreateWithoutDocumentsInput>;
  connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutDocumentsInput>;
  connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
};

export type VehicleInsuranceTypeCreateNestedOneWithoutVehicleInsuranceRuleSetsInput =
  {
    create?: Maybe<VehicleInsuranceTypeCreateWithoutVehicleInsuranceRuleSetsInput>;
    connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutVehicleInsuranceRuleSetsInput>;
    connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
  };

export type VehicleInsuranceTypeCreateNestedOneWithoutVehiclesInput = {
  create?: Maybe<VehicleInsuranceTypeCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutVehiclesInput>;
  connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
};

export type VehicleInsuranceTypeCreateOrConnectWithoutBookingsInput = {
  where: VehicleInsuranceTypeWhereUniqueInput;
  create: VehicleInsuranceTypeCreateWithoutBookingsInput;
};

export type VehicleInsuranceTypeCreateOrConnectWithoutDocumentsInput = {
  where: VehicleInsuranceTypeWhereUniqueInput;
  create: VehicleInsuranceTypeCreateWithoutDocumentsInput;
};

export type VehicleInsuranceTypeCreateOrConnectWithoutVehicleInsuranceRuleSetsInput =
  {
    where: VehicleInsuranceTypeWhereUniqueInput;
    create: VehicleInsuranceTypeCreateWithoutVehicleInsuranceRuleSetsInput;
  };

export type VehicleInsuranceTypeCreateOrConnectWithoutVehiclesInput = {
  where: VehicleInsuranceTypeWhereUniqueInput;
  create: VehicleInsuranceTypeCreateWithoutVehiclesInput;
};

export type VehicleInsuranceTypeCreateWithoutBookingsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  documents?: Maybe<VehicleInsuranceDocumentCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetCreateNestedManyWithoutVehicleInsuranceInput>;
};

export type VehicleInsuranceTypeCreateWithoutDocumentsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetCreateNestedManyWithoutVehicleInsuranceInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeCreateWithoutVehicleInsuranceRuleSetsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  vehicles?: Maybe<VehicleCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  documents?: Maybe<VehicleInsuranceDocumentCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeCreateWithoutVehiclesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<VehicleInsuranceDocumentCreateNestedManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetCreateNestedManyWithoutVehicleInsuranceInput>;
  bookings?: Maybe<BookingCreateNestedManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeGroupBy = {
  __typename?: 'VehicleInsuranceTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting: Scalars['Boolean'];
  reportingInterval: InsuranceReporting;
  reportingDay: Scalars['Int'];
  isDefault?: Maybe<Scalars['Boolean']>;
  _count?: Maybe<VehicleInsuranceTypeCountAggregate>;
  _avg?: Maybe<VehicleInsuranceTypeAvgAggregate>;
  _sum?: Maybe<VehicleInsuranceTypeSumAggregate>;
  _min?: Maybe<VehicleInsuranceTypeMinAggregate>;
  _max?: Maybe<VehicleInsuranceTypeMaxAggregate>;
};

export type VehicleInsuranceTypeMaxAggregate = {
  __typename?: 'VehicleInsuranceTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type VehicleInsuranceTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  contentVAG?: Maybe<SortOrder>;
  contentLinks?: Maybe<SortOrder>;
  showVAG?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  policyNumber?: Maybe<SortOrder>;
  policyHolder?: Maybe<SortOrder>;
  postalAddress?: Maybe<SortOrder>;
  billingAddress?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  emailAddress?: Maybe<SortOrder>;
  enabledReporting?: Maybe<SortOrder>;
  reportingInterval?: Maybe<SortOrder>;
  reportingDay?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
};

export type VehicleInsuranceTypeMinAggregate = {
  __typename?: 'VehicleInsuranceTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentVAG?: Maybe<Scalars['String']>;
  contentLinks?: Maybe<Scalars['String']>;
  showVAG?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyHolder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  enabledReporting?: Maybe<Scalars['Boolean']>;
  reportingInterval?: Maybe<InsuranceReporting>;
  reportingDay?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type VehicleInsuranceTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  contentVAG?: Maybe<SortOrder>;
  contentLinks?: Maybe<SortOrder>;
  showVAG?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  policyNumber?: Maybe<SortOrder>;
  policyHolder?: Maybe<SortOrder>;
  postalAddress?: Maybe<SortOrder>;
  billingAddress?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  emailAddress?: Maybe<SortOrder>;
  enabledReporting?: Maybe<SortOrder>;
  reportingInterval?: Maybe<SortOrder>;
  reportingDay?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
};

export type VehicleInsuranceTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  contentVAG?: Maybe<SortOrder>;
  contentLinks?: Maybe<SortOrder>;
  showVAG?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  policyNumber?: Maybe<SortOrder>;
  policyHolder?: Maybe<SortOrder>;
  postalAddress?: Maybe<SortOrder>;
  billingAddress?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  emailAddress?: Maybe<SortOrder>;
  enabledReporting?: Maybe<SortOrder>;
  reportingInterval?: Maybe<SortOrder>;
  reportingDay?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  _count?: Maybe<VehicleInsuranceTypeCountOrderByAggregateInput>;
  _avg?: Maybe<VehicleInsuranceTypeAvgOrderByAggregateInput>;
  _max?: Maybe<VehicleInsuranceTypeMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleInsuranceTypeMinOrderByAggregateInput>;
  _sum?: Maybe<VehicleInsuranceTypeSumOrderByAggregateInput>;
};

export type VehicleInsuranceTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  contentVAG?: Maybe<SortOrder>;
  contentLinks?: Maybe<SortOrder>;
  showVAG?: Maybe<SortOrder>;
  isPrivate?: Maybe<SortOrder>;
  policyNumber?: Maybe<SortOrder>;
  policyHolder?: Maybe<SortOrder>;
  postalAddress?: Maybe<SortOrder>;
  billingAddress?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  emailAddress?: Maybe<SortOrder>;
  enabledReporting?: Maybe<SortOrder>;
  reportingInterval?: Maybe<SortOrder>;
  reportingDay?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  vehicles?: Maybe<VehicleOrderByRelationAggregateInput>;
  documents?: Maybe<VehicleInsuranceDocumentOrderByRelationAggregateInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetOrderByRelationAggregateInput>;
  bookings?: Maybe<BookingOrderByRelationAggregateInput>;
};

export type VehicleInsuranceTypeRelationFilter = {
  is?: Maybe<VehicleInsuranceTypeWhereInput>;
  isNot?: Maybe<VehicleInsuranceTypeWhereInput>;
};

export enum VehicleInsuranceTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
  ContentVag = 'contentVAG',
  ContentLinks = 'contentLinks',
  ShowVag = 'showVAG',
  IsPrivate = 'isPrivate',
  PolicyNumber = 'policyNumber',
  PolicyHolder = 'policyHolder',
  PostalAddress = 'postalAddress',
  BillingAddress = 'billingAddress',
  PhoneNumber = 'phoneNumber',
  EmailAddress = 'emailAddress',
  EnabledReporting = 'enabledReporting',
  ReportingInterval = 'reportingInterval',
  ReportingDay = 'reportingDay',
  IsDefault = 'isDefault',
}

export type VehicleInsuranceTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleInsuranceTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleInsuranceTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleInsuranceTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
  contentVAG?: Maybe<StringNullableWithAggregatesFilter>;
  contentLinks?: Maybe<StringNullableWithAggregatesFilter>;
  showVAG?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrivate?: Maybe<BoolNullableWithAggregatesFilter>;
  policyNumber?: Maybe<StringNullableWithAggregatesFilter>;
  policyHolder?: Maybe<StringNullableWithAggregatesFilter>;
  postalAddress?: Maybe<StringNullableWithAggregatesFilter>;
  billingAddress?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  emailAddress?: Maybe<StringNullableWithAggregatesFilter>;
  enabledReporting?: Maybe<BoolWithAggregatesFilter>;
  reportingInterval?: Maybe<EnumInsuranceReportingWithAggregatesFilter>;
  reportingDay?: Maybe<IntWithAggregatesFilter>;
  isDefault?: Maybe<BoolNullableWithAggregatesFilter>;
};

export type VehicleInsuranceTypeSumAggregate = {
  __typename?: 'VehicleInsuranceTypeSumAggregate';
  reportingDay?: Maybe<Scalars['Int']>;
};

export type VehicleInsuranceTypeSumOrderByAggregateInput = {
  reportingDay?: Maybe<SortOrder>;
};

export type VehicleInsuranceTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentVAG?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentLinks?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showVAG?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  policyNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  policyHolder?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postalAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emailAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledReporting?: Maybe<BoolFieldUpdateOperationsInput>;
  reportingInterval?: Maybe<EnumInsuranceReportingFieldUpdateOperationsInput>;
  reportingDay?: Maybe<IntFieldUpdateOperationsInput>;
  isDefault?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutVehicleInsuranceTypeInput>;
  documents?: Maybe<VehicleInsuranceDocumentUpdateManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetUpdateManyWithoutVehicleInsuranceInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentVAG?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentLinks?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showVAG?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  policyNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  policyHolder?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postalAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emailAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledReporting?: Maybe<BoolFieldUpdateOperationsInput>;
  reportingInterval?: Maybe<EnumInsuranceReportingFieldUpdateOperationsInput>;
  reportingDay?: Maybe<IntFieldUpdateOperationsInput>;
  isDefault?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type VehicleInsuranceTypeUpdateOneRequiredWithoutVehicleInsuranceRuleSetsInput =
  {
    create?: Maybe<VehicleInsuranceTypeCreateWithoutVehicleInsuranceRuleSetsInput>;
    connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutVehicleInsuranceRuleSetsInput>;
    upsert?: Maybe<VehicleInsuranceTypeUpsertWithoutVehicleInsuranceRuleSetsInput>;
    connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
    update?: Maybe<VehicleInsuranceTypeUpdateWithoutVehicleInsuranceRuleSetsInput>;
  };

export type VehicleInsuranceTypeUpdateOneWithoutBookingsInput = {
  create?: Maybe<VehicleInsuranceTypeCreateWithoutBookingsInput>;
  connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutBookingsInput>;
  upsert?: Maybe<VehicleInsuranceTypeUpsertWithoutBookingsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
  update?: Maybe<VehicleInsuranceTypeUpdateWithoutBookingsInput>;
};

export type VehicleInsuranceTypeUpdateOneWithoutDocumentsInput = {
  create?: Maybe<VehicleInsuranceTypeCreateWithoutDocumentsInput>;
  connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutDocumentsInput>;
  upsert?: Maybe<VehicleInsuranceTypeUpsertWithoutDocumentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
  update?: Maybe<VehicleInsuranceTypeUpdateWithoutDocumentsInput>;
};

export type VehicleInsuranceTypeUpdateOneWithoutVehiclesInput = {
  create?: Maybe<VehicleInsuranceTypeCreateWithoutVehiclesInput>;
  connectOrCreate?: Maybe<VehicleInsuranceTypeCreateOrConnectWithoutVehiclesInput>;
  upsert?: Maybe<VehicleInsuranceTypeUpsertWithoutVehiclesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleInsuranceTypeWhereUniqueInput>;
  update?: Maybe<VehicleInsuranceTypeUpdateWithoutVehiclesInput>;
};

export type VehicleInsuranceTypeUpdateWithoutBookingsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentVAG?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentLinks?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showVAG?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  policyNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  policyHolder?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postalAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emailAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledReporting?: Maybe<BoolFieldUpdateOperationsInput>;
  reportingInterval?: Maybe<EnumInsuranceReportingFieldUpdateOperationsInput>;
  reportingDay?: Maybe<IntFieldUpdateOperationsInput>;
  isDefault?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutVehicleInsuranceTypeInput>;
  documents?: Maybe<VehicleInsuranceDocumentUpdateManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetUpdateManyWithoutVehicleInsuranceInput>;
};

export type VehicleInsuranceTypeUpdateWithoutDocumentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentVAG?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentLinks?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showVAG?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  policyNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  policyHolder?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postalAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emailAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledReporting?: Maybe<BoolFieldUpdateOperationsInput>;
  reportingInterval?: Maybe<EnumInsuranceReportingFieldUpdateOperationsInput>;
  reportingDay?: Maybe<IntFieldUpdateOperationsInput>;
  isDefault?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetUpdateManyWithoutVehicleInsuranceInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeUpdateWithoutVehicleInsuranceRuleSetsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentVAG?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentLinks?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showVAG?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  policyNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  policyHolder?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postalAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emailAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledReporting?: Maybe<BoolFieldUpdateOperationsInput>;
  reportingInterval?: Maybe<EnumInsuranceReportingFieldUpdateOperationsInput>;
  reportingDay?: Maybe<IntFieldUpdateOperationsInput>;
  isDefault?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  vehicles?: Maybe<VehicleUpdateManyWithoutVehicleInsuranceTypeInput>;
  documents?: Maybe<VehicleInsuranceDocumentUpdateManyWithoutVehicleInsuranceTypeInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeUpdateWithoutVehiclesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentVAG?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contentLinks?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showVAG?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  isPrivate?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  policyNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  policyHolder?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postalAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emailAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledReporting?: Maybe<BoolFieldUpdateOperationsInput>;
  reportingInterval?: Maybe<EnumInsuranceReportingFieldUpdateOperationsInput>;
  reportingDay?: Maybe<IntFieldUpdateOperationsInput>;
  isDefault?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  documents?: Maybe<VehicleInsuranceDocumentUpdateManyWithoutVehicleInsuranceTypeInput>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetUpdateManyWithoutVehicleInsuranceInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInsuranceTypeInput>;
};

export type VehicleInsuranceTypeUpsertWithoutBookingsInput = {
  update: VehicleInsuranceTypeUpdateWithoutBookingsInput;
  create: VehicleInsuranceTypeCreateWithoutBookingsInput;
};

export type VehicleInsuranceTypeUpsertWithoutDocumentsInput = {
  update: VehicleInsuranceTypeUpdateWithoutDocumentsInput;
  create: VehicleInsuranceTypeCreateWithoutDocumentsInput;
};

export type VehicleInsuranceTypeUpsertWithoutVehicleInsuranceRuleSetsInput = {
  update: VehicleInsuranceTypeUpdateWithoutVehicleInsuranceRuleSetsInput;
  create: VehicleInsuranceTypeCreateWithoutVehicleInsuranceRuleSetsInput;
};

export type VehicleInsuranceTypeUpsertWithoutVehiclesInput = {
  update: VehicleInsuranceTypeUpdateWithoutVehiclesInput;
  create: VehicleInsuranceTypeCreateWithoutVehiclesInput;
};

export type VehicleInsuranceTypeWhereInput = {
  AND?: Maybe<Array<VehicleInsuranceTypeWhereInput>>;
  OR?: Maybe<Array<VehicleInsuranceTypeWhereInput>>;
  NOT?: Maybe<Array<VehicleInsuranceTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  contentVAG?: Maybe<StringNullableFilter>;
  contentLinks?: Maybe<StringNullableFilter>;
  showVAG?: Maybe<BoolNullableFilter>;
  isPrivate?: Maybe<BoolNullableFilter>;
  policyNumber?: Maybe<StringNullableFilter>;
  policyHolder?: Maybe<StringNullableFilter>;
  postalAddress?: Maybe<StringNullableFilter>;
  billingAddress?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  emailAddress?: Maybe<StringNullableFilter>;
  enabledReporting?: Maybe<BoolFilter>;
  reportingInterval?: Maybe<EnumInsuranceReportingFilter>;
  reportingDay?: Maybe<IntFilter>;
  isDefault?: Maybe<BoolNullableFilter>;
  vehicles?: Maybe<VehicleListRelationFilter>;
  documents?: Maybe<VehicleInsuranceDocumentListRelationFilter>;
  vehicleInsuranceRuleSets?: Maybe<VehicleInsuranceRuleSetListRelationFilter>;
  bookings?: Maybe<BookingListRelationFilter>;
};

export type VehicleInsuranceTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleKindType = {
  __typename?: 'VehicleKindType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleKindTypeCount>;
  vehicle: Array<Vehicle>;
};

export type VehicleKindTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type VehicleKindTypeCount = {
  __typename?: 'VehicleKindTypeCount';
  vehicle: Scalars['Int'];
};

export type VehicleKindTypeCountAggregate = {
  __typename?: 'VehicleKindTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleKindTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type VehicleKindTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutVehicleKindTypeInput>;
};

export type VehicleKindTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type VehicleKindTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<VehicleKindTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<VehicleKindTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<VehicleKindTypeWhereUniqueInput>;
};

export type VehicleKindTypeCreateOrConnectWithoutVehicleInput = {
  where: VehicleKindTypeWhereUniqueInput;
  create: VehicleKindTypeCreateWithoutVehicleInput;
};

export type VehicleKindTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type VehicleKindTypeGroupBy = {
  __typename?: 'VehicleKindTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleKindTypeCountAggregate>;
  _min?: Maybe<VehicleKindTypeMinAggregate>;
  _max?: Maybe<VehicleKindTypeMaxAggregate>;
};

export type VehicleKindTypeMaxAggregate = {
  __typename?: 'VehicleKindTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type VehicleKindTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type VehicleKindTypeMinAggregate = {
  __typename?: 'VehicleKindTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type VehicleKindTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type VehicleKindTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<VehicleKindTypeCountOrderByAggregateInput>;
  _max?: Maybe<VehicleKindTypeMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleKindTypeMinOrderByAggregateInput>;
};

export type VehicleKindTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type VehicleKindTypeRelationFilter = {
  is?: Maybe<VehicleKindTypeWhereInput>;
  isNot?: Maybe<VehicleKindTypeWhereInput>;
};

export enum VehicleKindTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type VehicleKindTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleKindTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleKindTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleKindTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VehicleKindTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutVehicleKindTypeInput>;
};

export type VehicleKindTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type VehicleKindTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<VehicleKindTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<VehicleKindTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<VehicleKindTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleKindTypeWhereUniqueInput>;
  update?: Maybe<VehicleKindTypeUpdateWithoutVehicleInput>;
};

export type VehicleKindTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type VehicleKindTypeUpsertWithoutVehicleInput = {
  update: VehicleKindTypeUpdateWithoutVehicleInput;
  create: VehicleKindTypeCreateWithoutVehicleInput;
};

export type VehicleKindTypeWhereInput = {
  AND?: Maybe<Array<VehicleKindTypeWhereInput>>;
  OR?: Maybe<Array<VehicleKindTypeWhereInput>>;
  NOT?: Maybe<Array<VehicleKindTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type VehicleKindTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type VehicleListRelationFilter = {
  every?: Maybe<VehicleWhereInput>;
  some?: Maybe<VehicleWhereInput>;
  none?: Maybe<VehicleWhereInput>;
};

export type VehicleLocation = {
  __typename?: 'VehicleLocation';
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  radius: Scalars['Float'];
  vehicleId: Scalars['String'];
  _count?: Maybe<VehicleLocationCount>;
  vehicle: Vehicle;
  booking: Array<Booking>;
};

export type VehicleLocationBookingArgs = {
  where?: Maybe<BookingWhereInput>;
  orderBy?: Maybe<Array<BookingOrderByWithRelationInput>>;
  cursor?: Maybe<BookingWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BookingScalarFieldEnum>>;
};

export type VehicleLocationAvgAggregate = {
  __typename?: 'VehicleLocationAvgAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

export type VehicleLocationAvgOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  radius?: Maybe<SortOrder>;
};

export type VehicleLocationCount = {
  __typename?: 'VehicleLocationCount';
  booking: Scalars['Int'];
};

export type VehicleLocationCountAggregate = {
  __typename?: 'VehicleLocationCountAggregate';
  id: Scalars['Int'];
  isDefault: Scalars['Int'];
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  name: Scalars['Int'];
  radius: Scalars['Int'];
  vehicleId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleLocationCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  radius?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type VehicleLocationCreateInput = {
  id?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  radius?: Maybe<Scalars['Float']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleLocationInput;
  booking?: Maybe<BookingCreateNestedManyWithoutVehicleLocationInput>;
};

export type VehicleLocationCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  radius?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
};

export type VehicleLocationCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  radius?: Maybe<Scalars['Float']>;
};

export type VehicleLocationCreateManyVehicleInputEnvelope = {
  data: Array<VehicleLocationCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehicleLocationCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleLocationCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleLocationCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleLocationCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<VehicleLocationWhereUniqueInput>>;
};

export type VehicleLocationCreateNestedOneWithoutBookingInput = {
  create?: Maybe<VehicleLocationCreateWithoutBookingInput>;
  connectOrCreate?: Maybe<VehicleLocationCreateOrConnectWithoutBookingInput>;
  connect?: Maybe<VehicleLocationWhereUniqueInput>;
};

export type VehicleLocationCreateOrConnectWithoutBookingInput = {
  where: VehicleLocationWhereUniqueInput;
  create: VehicleLocationCreateWithoutBookingInput;
};

export type VehicleLocationCreateOrConnectWithoutVehicleInput = {
  where: VehicleLocationWhereUniqueInput;
  create: VehicleLocationCreateWithoutVehicleInput;
};

export type VehicleLocationCreateWithoutBookingInput = {
  id?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  radius?: Maybe<Scalars['Float']>;
  vehicle: VehicleCreateNestedOneWithoutVehicleLocationInput;
};

export type VehicleLocationCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  radius?: Maybe<Scalars['Float']>;
  booking?: Maybe<BookingCreateNestedManyWithoutVehicleLocationInput>;
};

export type VehicleLocationGroupBy = {
  __typename?: 'VehicleLocationGroupBy';
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  radius: Scalars['Float'];
  vehicleId: Scalars['String'];
  _count?: Maybe<VehicleLocationCountAggregate>;
  _avg?: Maybe<VehicleLocationAvgAggregate>;
  _sum?: Maybe<VehicleLocationSumAggregate>;
  _min?: Maybe<VehicleLocationMinAggregate>;
  _max?: Maybe<VehicleLocationMaxAggregate>;
};

export type VehicleLocationListRelationFilter = {
  every?: Maybe<VehicleLocationWhereInput>;
  some?: Maybe<VehicleLocationWhereInput>;
  none?: Maybe<VehicleLocationWhereInput>;
};

export type VehicleLocationMaxAggregate = {
  __typename?: 'VehicleLocationMaxAggregate';
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type VehicleLocationMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  radius?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type VehicleLocationMinAggregate = {
  __typename?: 'VehicleLocationMinAggregate';
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  vehicleId?: Maybe<Scalars['String']>;
};

export type VehicleLocationMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  radius?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
};

export type VehicleLocationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleLocationOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  radius?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  _count?: Maybe<VehicleLocationCountOrderByAggregateInput>;
  _avg?: Maybe<VehicleLocationAvgOrderByAggregateInput>;
  _max?: Maybe<VehicleLocationMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleLocationMinOrderByAggregateInput>;
  _sum?: Maybe<VehicleLocationSumOrderByAggregateInput>;
};

export type VehicleLocationOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  radius?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  booking?: Maybe<BookingOrderByRelationAggregateInput>;
};

export type VehicleLocationRelationFilter = {
  is?: Maybe<VehicleLocationWhereInput>;
  isNot?: Maybe<VehicleLocationWhereInput>;
};

export enum VehicleLocationScalarFieldEnum {
  Id = 'id',
  IsDefault = 'isDefault',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  Radius = 'radius',
  VehicleId = 'vehicleId',
}

export type VehicleLocationScalarWhereInput = {
  AND?: Maybe<Array<VehicleLocationScalarWhereInput>>;
  OR?: Maybe<Array<VehicleLocationScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleLocationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  isDefault?: Maybe<BoolFilter>;
  latitude?: Maybe<FloatFilter>;
  longitude?: Maybe<FloatFilter>;
  name?: Maybe<StringFilter>;
  radius?: Maybe<FloatFilter>;
  vehicleId?: Maybe<StringFilter>;
};

export type VehicleLocationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleLocationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleLocationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleLocationScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDefault?: Maybe<BoolWithAggregatesFilter>;
  latitude?: Maybe<FloatWithAggregatesFilter>;
  longitude?: Maybe<FloatWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  radius?: Maybe<FloatWithAggregatesFilter>;
  vehicleId?: Maybe<StringWithAggregatesFilter>;
};

export type VehicleLocationSumAggregate = {
  __typename?: 'VehicleLocationSumAggregate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

export type VehicleLocationSumOrderByAggregateInput = {
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  radius?: Maybe<SortOrder>;
};

export type VehicleLocationUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDefault?: Maybe<BoolFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  radius?: Maybe<FloatFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleLocationInput>;
  booking?: Maybe<BookingUpdateManyWithoutVehicleLocationInput>;
};

export type VehicleLocationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDefault?: Maybe<BoolFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  radius?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type VehicleLocationUpdateManyWithWhereWithoutVehicleInput = {
  where: VehicleLocationScalarWhereInput;
  data: VehicleLocationUpdateManyMutationInput;
};

export type VehicleLocationUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<VehicleLocationCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleLocationCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<
    Array<VehicleLocationUpsertWithWhereUniqueWithoutVehicleInput>
  >;
  createMany?: Maybe<VehicleLocationCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<VehicleLocationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleLocationWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleLocationWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleLocationWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleLocationUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: Maybe<
    Array<VehicleLocationUpdateManyWithWhereWithoutVehicleInput>
  >;
  deleteMany?: Maybe<Array<VehicleLocationScalarWhereInput>>;
};

export type VehicleLocationUpdateOneWithoutBookingInput = {
  create?: Maybe<VehicleLocationCreateWithoutBookingInput>;
  connectOrCreate?: Maybe<VehicleLocationCreateOrConnectWithoutBookingInput>;
  upsert?: Maybe<VehicleLocationUpsertWithoutBookingInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleLocationWhereUniqueInput>;
  update?: Maybe<VehicleLocationUpdateWithoutBookingInput>;
};

export type VehicleLocationUpdateWithWhereUniqueWithoutVehicleInput = {
  where: VehicleLocationWhereUniqueInput;
  data: VehicleLocationUpdateWithoutVehicleInput;
};

export type VehicleLocationUpdateWithoutBookingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDefault?: Maybe<BoolFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  radius?: Maybe<FloatFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneRequiredWithoutVehicleLocationInput>;
};

export type VehicleLocationUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDefault?: Maybe<BoolFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  radius?: Maybe<FloatFieldUpdateOperationsInput>;
  booking?: Maybe<BookingUpdateManyWithoutVehicleLocationInput>;
};

export type VehicleLocationUpsertWithWhereUniqueWithoutVehicleInput = {
  where: VehicleLocationWhereUniqueInput;
  update: VehicleLocationUpdateWithoutVehicleInput;
  create: VehicleLocationCreateWithoutVehicleInput;
};

export type VehicleLocationUpsertWithoutBookingInput = {
  update: VehicleLocationUpdateWithoutBookingInput;
  create: VehicleLocationCreateWithoutBookingInput;
};

export type VehicleLocationWhereInput = {
  AND?: Maybe<Array<VehicleLocationWhereInput>>;
  OR?: Maybe<Array<VehicleLocationWhereInput>>;
  NOT?: Maybe<Array<VehicleLocationWhereInput>>;
  id?: Maybe<StringFilter>;
  isDefault?: Maybe<BoolFilter>;
  latitude?: Maybe<FloatFilter>;
  longitude?: Maybe<FloatFilter>;
  name?: Maybe<StringFilter>;
  radius?: Maybe<FloatFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  booking?: Maybe<BookingListRelationFilter>;
};

export type VehicleLocationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleMaxAggregate = {
  __typename?: 'VehicleMaxAggregate';
  id?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vin?: Maybe<SortOrder>;
  rootNumber?: Maybe<SortOrder>;
  convadisVehicle?: Maybe<SortOrder>;
  qnr?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  cantonalNotes?: Maybe<SortOrder>;
  insurance?: Maybe<SortOrder>;
  decreesAuthority?: Maybe<SortOrder>;
  departmentEmissionCode?: Maybe<SortOrder>;
  enabledSmsAlerts?: Maybe<SortOrder>;
  capacity?: Maybe<SortOrder>;
  power?: Maybe<SortOrder>;
  curbWeight?: Maybe<SortOrder>;
  capacityPerKg?: Maybe<SortOrder>;
  payloadWeight?: Maybe<SortOrder>;
  grossWeight?: Maybe<SortOrder>;
  trainWeight?: Maybe<SortOrder>;
  towingWeight?: Maybe<SortOrder>;
  roofLoadWeight?: Maybe<SortOrder>;
  catalogPrice?: Maybe<SortOrder>;
  initialOdometer?: Maybe<SortOrder>;
  valueOfAccessories?: Maybe<SortOrder>;
  vehicleCode?: Maybe<SortOrder>;
  plateNumber?: Maybe<SortOrder>;
  trunkDimension?: Maybe<SortOrder>;
  approvalType?: Maybe<SortOrder>;
  fuelPassword?: Maybe<SortOrder>;
  rules?: Maybe<SortOrder>;
  currentLong?: Maybe<SortOrder>;
  currentLat?: Maybe<SortOrder>;
  bookingInformation?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  ownInsurance?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  preBookingInterval?: Maybe<SortOrder>;
  postBookingInterval?: Maybe<SortOrder>;
  earlyOpenInterval?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  unpublishReason?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  commissioningAt?: Maybe<SortOrder>;
  testedAt?: Maybe<SortOrder>;
  cloudBoxxActivatedAt?: Maybe<SortOrder>;
  connectionType?: Maybe<SortOrder>;
  smartcarVehicle?: Maybe<SortOrder>;
  fuelTypeId?: Maybe<SortOrder>;
  doorTypeId?: Maybe<SortOrder>;
  vehicleTypeId?: Maybe<SortOrder>;
  vehicleKindTypeId?: Maybe<SortOrder>;
  transmissionTypeId?: Maybe<SortOrder>;
  driveTypeId?: Maybe<SortOrder>;
  seatTypeId?: Maybe<SortOrder>;
  seatFrontTypeId?: Maybe<SortOrder>;
  energyEfficiencyTypeId?: Maybe<SortOrder>;
  emissionCodeTypeId?: Maybe<SortOrder>;
  consumptionTypeId?: Maybe<SortOrder>;
  shieldColorTypeId?: Maybe<SortOrder>;
  particularUseTypeId?: Maybe<SortOrder>;
  brandTypeId?: Maybe<SortOrder>;
  modelTypeId?: Maybe<SortOrder>;
  bodyTypeId?: Maybe<SortOrder>;
  colorTypeId?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type VehicleMinAggregate = {
  __typename?: 'VehicleMinAggregate';
  id?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  rootNumber?: Maybe<Scalars['String']>;
  convadisVehicle?: Maybe<Scalars['String']>;
  qnr?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['DateTime']>;
  cantonalNotes?: Maybe<Scalars['String']>;
  insurance?: Maybe<Scalars['String']>;
  decreesAuthority?: Maybe<Scalars['String']>;
  departmentEmissionCode?: Maybe<Scalars['String']>;
  enabledSmsAlerts?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  vehicleCode?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  trunkDimension?: Maybe<Scalars['String']>;
  approvalType?: Maybe<Scalars['String']>;
  fuelPassword?: Maybe<Scalars['String']>;
  rules?: Maybe<Scalars['String']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  bookingInformation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  ownInsurance?: Maybe<Scalars['Boolean']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  unpublishReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  commissioningAt?: Maybe<Scalars['DateTime']>;
  testedAt?: Maybe<Scalars['DateTime']>;
  cloudBoxxActivatedAt?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<VehicleConnectionType>;
  smartcarVehicle?: Maybe<Scalars['String']>;
  fuelTypeId?: Maybe<Scalars['String']>;
  doorTypeId?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['String']>;
  vehicleKindTypeId?: Maybe<Scalars['String']>;
  transmissionTypeId?: Maybe<Scalars['String']>;
  driveTypeId?: Maybe<Scalars['String']>;
  seatTypeId?: Maybe<Scalars['String']>;
  seatFrontTypeId?: Maybe<Scalars['String']>;
  energyEfficiencyTypeId?: Maybe<Scalars['String']>;
  emissionCodeTypeId?: Maybe<Scalars['String']>;
  consumptionTypeId?: Maybe<Scalars['String']>;
  shieldColorTypeId?: Maybe<Scalars['String']>;
  particularUseTypeId?: Maybe<Scalars['String']>;
  brandTypeId?: Maybe<Scalars['String']>;
  modelTypeId?: Maybe<Scalars['String']>;
  bodyTypeId?: Maybe<Scalars['String']>;
  colorTypeId?: Maybe<Scalars['String']>;
  vehicleInsuranceTypeId?: Maybe<Scalars['String']>;
  ownership?: Maybe<Ownership>;
  companyId?: Maybe<Scalars['String']>;
};

export type VehicleMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  vin?: Maybe<SortOrder>;
  rootNumber?: Maybe<SortOrder>;
  convadisVehicle?: Maybe<SortOrder>;
  qnr?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  cantonalNotes?: Maybe<SortOrder>;
  insurance?: Maybe<SortOrder>;
  decreesAuthority?: Maybe<SortOrder>;
  departmentEmissionCode?: Maybe<SortOrder>;
  enabledSmsAlerts?: Maybe<SortOrder>;
  capacity?: Maybe<SortOrder>;
  power?: Maybe<SortOrder>;
  curbWeight?: Maybe<SortOrder>;
  capacityPerKg?: Maybe<SortOrder>;
  payloadWeight?: Maybe<SortOrder>;
  grossWeight?: Maybe<SortOrder>;
  trainWeight?: Maybe<SortOrder>;
  towingWeight?: Maybe<SortOrder>;
  roofLoadWeight?: Maybe<SortOrder>;
  catalogPrice?: Maybe<SortOrder>;
  initialOdometer?: Maybe<SortOrder>;
  valueOfAccessories?: Maybe<SortOrder>;
  vehicleCode?: Maybe<SortOrder>;
  plateNumber?: Maybe<SortOrder>;
  trunkDimension?: Maybe<SortOrder>;
  approvalType?: Maybe<SortOrder>;
  fuelPassword?: Maybe<SortOrder>;
  rules?: Maybe<SortOrder>;
  currentLong?: Maybe<SortOrder>;
  currentLat?: Maybe<SortOrder>;
  bookingInformation?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  ownInsurance?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  preBookingInterval?: Maybe<SortOrder>;
  postBookingInterval?: Maybe<SortOrder>;
  earlyOpenInterval?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  unpublishReason?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  commissioningAt?: Maybe<SortOrder>;
  testedAt?: Maybe<SortOrder>;
  cloudBoxxActivatedAt?: Maybe<SortOrder>;
  connectionType?: Maybe<SortOrder>;
  smartcarVehicle?: Maybe<SortOrder>;
  fuelTypeId?: Maybe<SortOrder>;
  doorTypeId?: Maybe<SortOrder>;
  vehicleTypeId?: Maybe<SortOrder>;
  vehicleKindTypeId?: Maybe<SortOrder>;
  transmissionTypeId?: Maybe<SortOrder>;
  driveTypeId?: Maybe<SortOrder>;
  seatTypeId?: Maybe<SortOrder>;
  seatFrontTypeId?: Maybe<SortOrder>;
  energyEfficiencyTypeId?: Maybe<SortOrder>;
  emissionCodeTypeId?: Maybe<SortOrder>;
  consumptionTypeId?: Maybe<SortOrder>;
  shieldColorTypeId?: Maybe<SortOrder>;
  particularUseTypeId?: Maybe<SortOrder>;
  brandTypeId?: Maybe<SortOrder>;
  modelTypeId?: Maybe<SortOrder>;
  bodyTypeId?: Maybe<SortOrder>;
  colorTypeId?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
};

export type VehicleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehicleOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  vin?: Maybe<SortOrder>;
  rootNumber?: Maybe<SortOrder>;
  convadisVehicle?: Maybe<SortOrder>;
  qnr?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  cantonalNotes?: Maybe<SortOrder>;
  insurance?: Maybe<SortOrder>;
  decreesAuthority?: Maybe<SortOrder>;
  departmentEmissionCode?: Maybe<SortOrder>;
  enabledSmsAlerts?: Maybe<SortOrder>;
  capacity?: Maybe<SortOrder>;
  power?: Maybe<SortOrder>;
  curbWeight?: Maybe<SortOrder>;
  capacityPerKg?: Maybe<SortOrder>;
  payloadWeight?: Maybe<SortOrder>;
  grossWeight?: Maybe<SortOrder>;
  trainWeight?: Maybe<SortOrder>;
  towingWeight?: Maybe<SortOrder>;
  roofLoadWeight?: Maybe<SortOrder>;
  catalogPrice?: Maybe<SortOrder>;
  initialOdometer?: Maybe<SortOrder>;
  valueOfAccessories?: Maybe<SortOrder>;
  vehicleCode?: Maybe<SortOrder>;
  plateNumber?: Maybe<SortOrder>;
  trunkDimension?: Maybe<SortOrder>;
  approvalType?: Maybe<SortOrder>;
  fuelPassword?: Maybe<SortOrder>;
  rules?: Maybe<SortOrder>;
  currentLong?: Maybe<SortOrder>;
  currentLat?: Maybe<SortOrder>;
  bookingInformation?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  ownInsurance?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  preBookingInterval?: Maybe<SortOrder>;
  postBookingInterval?: Maybe<SortOrder>;
  earlyOpenInterval?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  unpublishReason?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  commissioningAt?: Maybe<SortOrder>;
  testedAt?: Maybe<SortOrder>;
  cloudBoxxActivatedAt?: Maybe<SortOrder>;
  connectionType?: Maybe<SortOrder>;
  smartcarVehicle?: Maybe<SortOrder>;
  fuelTypeId?: Maybe<SortOrder>;
  doorTypeId?: Maybe<SortOrder>;
  vehicleTypeId?: Maybe<SortOrder>;
  vehicleKindTypeId?: Maybe<SortOrder>;
  transmissionTypeId?: Maybe<SortOrder>;
  driveTypeId?: Maybe<SortOrder>;
  seatTypeId?: Maybe<SortOrder>;
  seatFrontTypeId?: Maybe<SortOrder>;
  energyEfficiencyTypeId?: Maybe<SortOrder>;
  emissionCodeTypeId?: Maybe<SortOrder>;
  consumptionTypeId?: Maybe<SortOrder>;
  shieldColorTypeId?: Maybe<SortOrder>;
  particularUseTypeId?: Maybe<SortOrder>;
  brandTypeId?: Maybe<SortOrder>;
  modelTypeId?: Maybe<SortOrder>;
  bodyTypeId?: Maybe<SortOrder>;
  colorTypeId?: Maybe<SortOrder>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  _count?: Maybe<VehicleCountOrderByAggregateInput>;
  _avg?: Maybe<VehicleAvgOrderByAggregateInput>;
  _max?: Maybe<VehicleMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleMinOrderByAggregateInput>;
  _sum?: Maybe<VehicleSumOrderByAggregateInput>;
};

export type VehicleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  vin?: Maybe<SortOrder>;
  rootNumber?: Maybe<SortOrder>;
  convadisVehicle?: Maybe<SortOrder>;
  qnr?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  address?: Maybe<SortOrder>;
  houseNumber?: Maybe<SortOrder>;
  postCode?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  birthdate?: Maybe<SortOrder>;
  cantonalNotes?: Maybe<SortOrder>;
  insurance?: Maybe<SortOrder>;
  decreesAuthority?: Maybe<SortOrder>;
  departmentEmissionCode?: Maybe<SortOrder>;
  enabledSmsAlerts?: Maybe<SortOrder>;
  capacity?: Maybe<SortOrder>;
  power?: Maybe<SortOrder>;
  curbWeight?: Maybe<SortOrder>;
  capacityPerKg?: Maybe<SortOrder>;
  payloadWeight?: Maybe<SortOrder>;
  grossWeight?: Maybe<SortOrder>;
  trainWeight?: Maybe<SortOrder>;
  towingWeight?: Maybe<SortOrder>;
  roofLoadWeight?: Maybe<SortOrder>;
  catalogPrice?: Maybe<SortOrder>;
  initialOdometer?: Maybe<SortOrder>;
  valueOfAccessories?: Maybe<SortOrder>;
  vehicleCode?: Maybe<SortOrder>;
  plateNumber?: Maybe<SortOrder>;
  trunkDimension?: Maybe<SortOrder>;
  approvalType?: Maybe<SortOrder>;
  fuelPassword?: Maybe<SortOrder>;
  rules?: Maybe<SortOrder>;
  currentLong?: Maybe<SortOrder>;
  currentLat?: Maybe<SortOrder>;
  bookingInformation?: Maybe<SortOrder>;
  disabled?: Maybe<SortOrder>;
  ownInsurance?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  preBookingInterval?: Maybe<SortOrder>;
  postBookingInterval?: Maybe<SortOrder>;
  earlyOpenInterval?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  vehicleDamage?: Maybe<VehicleDamageOrderByRelationAggregateInput>;
  vehicleDamageId?: Maybe<SortOrder>;
  unpublishReason?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  commissioningAt?: Maybe<SortOrder>;
  testedAt?: Maybe<SortOrder>;
  cloudBoxxActivatedAt?: Maybe<SortOrder>;
  connectionType?: Maybe<SortOrder>;
  smartcarVehicle?: Maybe<SortOrder>;
  fuelType?: Maybe<FuelTypeOrderByWithRelationInput>;
  fuelTypeId?: Maybe<SortOrder>;
  doorType?: Maybe<DoorTypeOrderByWithRelationInput>;
  doorTypeId?: Maybe<SortOrder>;
  vehicleType?: Maybe<VehicleTypeOrderByWithRelationInput>;
  vehicleTypeId?: Maybe<SortOrder>;
  vehicleKindType?: Maybe<VehicleKindTypeOrderByWithRelationInput>;
  vehicleKindTypeId?: Maybe<SortOrder>;
  transmissionType?: Maybe<TransmissionTypeOrderByWithRelationInput>;
  transmissionTypeId?: Maybe<SortOrder>;
  driveType?: Maybe<DriveTypeOrderByWithRelationInput>;
  driveTypeId?: Maybe<SortOrder>;
  seatType?: Maybe<SeatTypeOrderByWithRelationInput>;
  seatTypeId?: Maybe<SortOrder>;
  seatFrontType?: Maybe<SeatFrontTypeOrderByWithRelationInput>;
  seatFrontTypeId?: Maybe<SortOrder>;
  childSeatTypes?: Maybe<ChildSeatTypeOrderByRelationAggregateInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeOrderByWithRelationInput>;
  energyEfficiencyTypeId?: Maybe<SortOrder>;
  emissionCodeType?: Maybe<EmissionCodeTypeOrderByWithRelationInput>;
  emissionCodeTypeId?: Maybe<SortOrder>;
  consumptionType?: Maybe<ConsumptionTypeOrderByWithRelationInput>;
  consumptionTypeId?: Maybe<SortOrder>;
  shieldColorType?: Maybe<ShieldColorTypeOrderByWithRelationInput>;
  shieldColorTypeId?: Maybe<SortOrder>;
  particularUseType?: Maybe<ParticularUseTypeOrderByWithRelationInput>;
  particularUseTypeId?: Maybe<SortOrder>;
  brandType?: Maybe<BrandTypeOrderByWithRelationInput>;
  brandTypeId?: Maybe<SortOrder>;
  modelType?: Maybe<ModelTypeOrderByWithRelationInput>;
  modelTypeId?: Maybe<SortOrder>;
  bodyType?: Maybe<BodyTypeOrderByWithRelationInput>;
  bodyTypeId?: Maybe<SortOrder>;
  colorType?: Maybe<ColorTypeOrderByWithRelationInput>;
  colorTypeId?: Maybe<SortOrder>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeOrderByWithRelationInput>;
  vehicleInsuranceTypeId?: Maybe<SortOrder>;
  ownership?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  companyId?: Maybe<SortOrder>;
  vehiclePhotos?: Maybe<VehiclePhotoOrderByRelationAggregateInput>;
  bookings?: Maybe<BookingOrderByRelationAggregateInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesOrderByRelationAggregateInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentOrderByRelationAggregateInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogOrderByRelationAggregateInput>;
  vehicleLocation?: Maybe<VehicleLocationOrderByRelationAggregateInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogOrderByRelationAggregateInput>;
  availability?: Maybe<AvailabilityOrderByRelationAggregateInput>;
  bookingQuestion?: Maybe<BookingQuestionOrderByRelationAggregateInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleOrderByRelationAggregateInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleOrderByRelationAggregateInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionOrderByRelationAggregateInput>;
  smartcarAuth?: Maybe<SmartcarAuthOrderByRelationAggregateInput>;
  vehicleConnection?: Maybe<VehicleConnectionOrderByRelationAggregateInput>;
};

export type VehiclePhoto = {
  __typename?: 'VehiclePhoto';
  id: Scalars['String'];
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  vehicle?: Maybe<Vehicle>;
  vehicleDamage?: Maybe<VehicleDamage>;
};

export type VehiclePhotoAvgAggregate = {
  __typename?: 'VehiclePhotoAvgAggregate';
  orderIndex?: Maybe<Scalars['Float']>;
};

export type VehiclePhotoAvgOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export type VehiclePhotoCountAggregate = {
  __typename?: 'VehiclePhotoCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  orderIndex: Scalars['Int'];
  type: Scalars['Int'];
  vehicleId: Scalars['Int'];
  vehicleDamageId: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehiclePhotoCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type VehiclePhotoCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutVehiclePhotosInput>;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedOneWithoutVehiclePhotosInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoCreateManyVehicleDamageInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicleId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoCreateManyVehicleDamageInputEnvelope = {
  data: Array<VehiclePhotoCreateManyVehicleDamageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehiclePhotoCreateManyVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicleDamageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoCreateManyVehicleInputEnvelope = {
  data: Array<VehiclePhotoCreateManyVehicleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VehiclePhotoCreateNestedManyWithoutVehicleDamageInput = {
  create?: Maybe<Array<VehiclePhotoCreateWithoutVehicleDamageInput>>;
  connectOrCreate?: Maybe<
    Array<VehiclePhotoCreateOrConnectWithoutVehicleDamageInput>
  >;
  createMany?: Maybe<VehiclePhotoCreateManyVehicleDamageInputEnvelope>;
  connect?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
};

export type VehiclePhotoCreateNestedManyWithoutVehicleInput = {
  create?: Maybe<Array<VehiclePhotoCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehiclePhotoCreateOrConnectWithoutVehicleInput>
  >;
  createMany?: Maybe<VehiclePhotoCreateManyVehicleInputEnvelope>;
  connect?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
};

export type VehiclePhotoCreateOrConnectWithoutVehicleDamageInput = {
  where: VehiclePhotoWhereUniqueInput;
  create: VehiclePhotoCreateWithoutVehicleDamageInput;
};

export type VehiclePhotoCreateOrConnectWithoutVehicleInput = {
  where: VehiclePhotoWhereUniqueInput;
  create: VehiclePhotoCreateWithoutVehicleInput;
};

export type VehiclePhotoCreateWithoutVehicleDamageInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicle?: Maybe<VehicleCreateNestedOneWithoutVehiclePhotosInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicleDamage?: Maybe<VehicleDamageCreateNestedOneWithoutVehiclePhotosInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoGroupBy = {
  __typename?: 'VehiclePhotoGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  type: VehiclePhotoType;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  _count?: Maybe<VehiclePhotoCountAggregate>;
  _avg?: Maybe<VehiclePhotoAvgAggregate>;
  _sum?: Maybe<VehiclePhotoSumAggregate>;
  _min?: Maybe<VehiclePhotoMinAggregate>;
  _max?: Maybe<VehiclePhotoMaxAggregate>;
};

export type VehiclePhotoListRelationFilter = {
  every?: Maybe<VehiclePhotoWhereInput>;
  some?: Maybe<VehiclePhotoWhereInput>;
  none?: Maybe<VehiclePhotoWhereInput>;
};

export type VehiclePhotoMaxAggregate = {
  __typename?: 'VehiclePhotoMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  type?: Maybe<VehiclePhotoType>;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type VehiclePhotoMinAggregate = {
  __typename?: 'VehiclePhotoMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  type?: Maybe<VehiclePhotoType>;
  vehicleId?: Maybe<Scalars['String']>;
  vehicleDamageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VehiclePhotoMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type VehiclePhotoOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VehiclePhotoOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicleId?: Maybe<SortOrder>;
  vehicleDamageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<VehiclePhotoCountOrderByAggregateInput>;
  _avg?: Maybe<VehiclePhotoAvgOrderByAggregateInput>;
  _max?: Maybe<VehiclePhotoMaxOrderByAggregateInput>;
  _min?: Maybe<VehiclePhotoMinOrderByAggregateInput>;
  _sum?: Maybe<VehiclePhotoSumOrderByAggregateInput>;
};

export type VehiclePhotoOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByWithRelationInput>;
  vehicleId?: Maybe<SortOrder>;
  vehicleDamage?: Maybe<VehicleDamageOrderByWithRelationInput>;
  vehicleDamageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum VehiclePhotoScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  OrderIndex = 'orderIndex',
  Type = 'type',
  VehicleId = 'vehicleId',
  VehicleDamageId = 'vehicleDamageId',
  CreatedAt = 'createdAt',
}

export type VehiclePhotoScalarWhereInput = {
  AND?: Maybe<Array<VehiclePhotoScalarWhereInput>>;
  OR?: Maybe<Array<VehiclePhotoScalarWhereInput>>;
  NOT?: Maybe<Array<VehiclePhotoScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  orderIndex?: Maybe<IntNullableFilter>;
  type?: Maybe<EnumVehiclePhotoTypeFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
  vehicleDamageId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type VehiclePhotoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehiclePhotoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehiclePhotoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehiclePhotoScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  orderIndex?: Maybe<IntNullableWithAggregatesFilter>;
  type?: Maybe<EnumVehiclePhotoTypeWithAggregatesFilter>;
  vehicleId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleDamageId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VehiclePhotoSumAggregate = {
  __typename?: 'VehiclePhotoSumAggregate';
  orderIndex?: Maybe<Scalars['Int']>;
};

export type VehiclePhotoSumOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export enum VehiclePhotoType {
  Gallery = 'GALLERY',
  DamageFrontLeft = 'DAMAGE_FRONT_LEFT',
  DamageFrontRight = 'DAMAGE_FRONT_RIGHT',
  DamageBackLeft = 'DAMAGE_BACK_LEFT',
  DamageBackRight = 'DAMAGE_BACK_RIGHT',
}

export type VehiclePhotoUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehiclePhotoTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutVehiclePhotosInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateOneWithoutVehiclePhotosInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehiclePhotoUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehiclePhotoTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehiclePhotoUpdateManyWithWhereWithoutVehicleDamageInput = {
  where: VehiclePhotoScalarWhereInput;
  data: VehiclePhotoUpdateManyMutationInput;
};

export type VehiclePhotoUpdateManyWithWhereWithoutVehicleInput = {
  where: VehiclePhotoScalarWhereInput;
  data: VehiclePhotoUpdateManyMutationInput;
};

export type VehiclePhotoUpdateManyWithoutVehicleDamageInput = {
  create?: Maybe<Array<VehiclePhotoCreateWithoutVehicleDamageInput>>;
  connectOrCreate?: Maybe<
    Array<VehiclePhotoCreateOrConnectWithoutVehicleDamageInput>
  >;
  upsert?: Maybe<
    Array<VehiclePhotoUpsertWithWhereUniqueWithoutVehicleDamageInput>
  >;
  createMany?: Maybe<VehiclePhotoCreateManyVehicleDamageInputEnvelope>;
  set?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  delete?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  connect?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  update?: Maybe<
    Array<VehiclePhotoUpdateWithWhereUniqueWithoutVehicleDamageInput>
  >;
  updateMany?: Maybe<
    Array<VehiclePhotoUpdateManyWithWhereWithoutVehicleDamageInput>
  >;
  deleteMany?: Maybe<Array<VehiclePhotoScalarWhereInput>>;
};

export type VehiclePhotoUpdateManyWithoutVehicleInput = {
  create?: Maybe<Array<VehiclePhotoCreateWithoutVehicleInput>>;
  connectOrCreate?: Maybe<
    Array<VehiclePhotoCreateOrConnectWithoutVehicleInput>
  >;
  upsert?: Maybe<Array<VehiclePhotoUpsertWithWhereUniqueWithoutVehicleInput>>;
  createMany?: Maybe<VehiclePhotoCreateManyVehicleInputEnvelope>;
  set?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  delete?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  connect?: Maybe<Array<VehiclePhotoWhereUniqueInput>>;
  update?: Maybe<Array<VehiclePhotoUpdateWithWhereUniqueWithoutVehicleInput>>;
  updateMany?: Maybe<Array<VehiclePhotoUpdateManyWithWhereWithoutVehicleInput>>;
  deleteMany?: Maybe<Array<VehiclePhotoScalarWhereInput>>;
};

export type VehiclePhotoUpdateWithWhereUniqueWithoutVehicleDamageInput = {
  where: VehiclePhotoWhereUniqueInput;
  data: VehiclePhotoUpdateWithoutVehicleDamageInput;
};

export type VehiclePhotoUpdateWithWhereUniqueWithoutVehicleInput = {
  where: VehiclePhotoWhereUniqueInput;
  data: VehiclePhotoUpdateWithoutVehicleInput;
};

export type VehiclePhotoUpdateWithoutVehicleDamageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehiclePhotoTypeFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateOneWithoutVehiclePhotosInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehiclePhotoUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<NullableIntFieldUpdateOperationsInput>;
  type?: Maybe<EnumVehiclePhotoTypeFieldUpdateOperationsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateOneWithoutVehiclePhotosInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type VehiclePhotoUpsertWithWhereUniqueWithoutVehicleDamageInput = {
  where: VehiclePhotoWhereUniqueInput;
  update: VehiclePhotoUpdateWithoutVehicleDamageInput;
  create: VehiclePhotoCreateWithoutVehicleDamageInput;
};

export type VehiclePhotoUpsertWithWhereUniqueWithoutVehicleInput = {
  where: VehiclePhotoWhereUniqueInput;
  update: VehiclePhotoUpdateWithoutVehicleInput;
  create: VehiclePhotoCreateWithoutVehicleInput;
};

export type VehiclePhotoWhereInput = {
  AND?: Maybe<Array<VehiclePhotoWhereInput>>;
  OR?: Maybe<Array<VehiclePhotoWhereInput>>;
  NOT?: Maybe<Array<VehiclePhotoWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  orderIndex?: Maybe<IntNullableFilter>;
  type?: Maybe<EnumVehiclePhotoTypeFilter>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringNullableFilter>;
  vehicleDamage?: Maybe<VehicleDamageRelationFilter>;
  vehicleDamageId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type VehiclePhotoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VehicleRelationFilter = {
  is?: Maybe<VehicleWhereInput>;
  isNot?: Maybe<VehicleWhereInput>;
};

export type VehicleReport = {
  __typename?: 'VehicleReport';
  id?: Maybe<Scalars['String']>;
  bookingCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  vehicle?: Maybe<Vehicle>;
  owner?: Maybe<User>;
  totalKm?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalVehicleOwner?: Maybe<Scalars['Float']>;
};

export type VehicleReportAvgAggregate = {
  __typename?: 'VehicleReportAvgAggregate';
  id?: Maybe<Scalars['String']>;
  bookingCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  totalKm?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalVehicleOwner?: Maybe<Scalars['Float']>;
};

export type VehicleReportCountAggregate = {
  __typename?: 'VehicleReportCountAggregate';
  id?: Maybe<Scalars['String']>;
  bookingCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  totalKm?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalVehicleOwner?: Maybe<Scalars['Float']>;
  _all?: Maybe<Scalars['Float']>;
};

export type VehicleReportMaxAggregate = {
  __typename?: 'VehicleReportMaxAggregate';
  id?: Maybe<Scalars['String']>;
  bookingCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  totalKm?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalVehicleOwner?: Maybe<Scalars['Float']>;
};

export type VehicleReportMinAggregate = {
  __typename?: 'VehicleReportMinAggregate';
  id?: Maybe<Scalars['String']>;
  bookingCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  totalKm?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalVehicleOwner?: Maybe<Scalars['Float']>;
};

export type VehicleReportSumAggregate = {
  __typename?: 'VehicleReportSumAggregate';
  id?: Maybe<Scalars['String']>;
  bookingCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  vehicleId?: Maybe<Scalars['String']>;
  totalKm?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalVehicleOwner?: Maybe<Scalars['Float']>;
};

export type VehicleReportWhereInput = {
  q?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleRelationFilter>;
  vehicleId?: Maybe<StringFilter>;
  owner?: Maybe<UserRelationFilter>;
  ownerId?: Maybe<StringFilter>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type VehicleRevokeAffectedBookingArgs = {
  revokeReason: Scalars['String'];
  bookingId: Scalars['String'];
};

export enum VehicleScalarFieldEnum {
  Id = 'id',
  Vin = 'vin',
  RootNumber = 'rootNumber',
  ConvadisVehicle = 'convadisVehicle',
  Qnr = 'qnr',
  FirstName = 'firstName',
  LastName = 'lastName',
  Address = 'address',
  HouseNumber = 'houseNumber',
  PostCode = 'postCode',
  City = 'city',
  Country = 'country',
  Birthdate = 'birthdate',
  CantonalNotes = 'cantonalNotes',
  Insurance = 'insurance',
  DecreesAuthority = 'decreesAuthority',
  DepartmentEmissionCode = 'departmentEmissionCode',
  EnabledSmsAlerts = 'enabledSmsAlerts',
  Capacity = 'capacity',
  Power = 'power',
  CurbWeight = 'curbWeight',
  CapacityPerKg = 'capacityPerKg',
  PayloadWeight = 'payloadWeight',
  GrossWeight = 'grossWeight',
  TrainWeight = 'trainWeight',
  TowingWeight = 'towingWeight',
  RoofLoadWeight = 'roofLoadWeight',
  CatalogPrice = 'catalogPrice',
  InitialOdometer = 'initialOdometer',
  ValueOfAccessories = 'valueOfAccessories',
  VehicleCode = 'vehicleCode',
  PlateNumber = 'plateNumber',
  TrunkDimension = 'trunkDimension',
  ApprovalType = 'approvalType',
  FuelPassword = 'fuelPassword',
  Rules = 'rules',
  CurrentLong = 'currentLong',
  CurrentLat = 'currentLat',
  BookingInformation = 'bookingInformation',
  Disabled = 'disabled',
  OwnInsurance = 'ownInsurance',
  PriceHour = 'priceHour',
  PriceDay = 'priceDay',
  PriceKm = 'priceKm',
  PriceWeek = 'priceWeek',
  IncludedKmHour = 'includedKmHour',
  IncludedKmDay = 'includedKmDay',
  IncludedKmWeek = 'includedKmWeek',
  PreBookingInterval = 'preBookingInterval',
  PostBookingInterval = 'postBookingInterval',
  EarlyOpenInterval = 'earlyOpenInterval',
  UserId = 'userId',
  VehicleDamageId = 'vehicleDamageId',
  UnpublishReason = 'unpublishReason',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  CommissioningAt = 'commissioningAt',
  TestedAt = 'testedAt',
  CloudBoxxActivatedAt = 'cloudBoxxActivatedAt',
  ConnectionType = 'connectionType',
  SmartcarVehicle = 'smartcarVehicle',
  FuelTypeId = 'fuelTypeId',
  DoorTypeId = 'doorTypeId',
  VehicleTypeId = 'vehicleTypeId',
  VehicleKindTypeId = 'vehicleKindTypeId',
  TransmissionTypeId = 'transmissionTypeId',
  DriveTypeId = 'driveTypeId',
  SeatTypeId = 'seatTypeId',
  SeatFrontTypeId = 'seatFrontTypeId',
  EnergyEfficiencyTypeId = 'energyEfficiencyTypeId',
  EmissionCodeTypeId = 'emissionCodeTypeId',
  ConsumptionTypeId = 'consumptionTypeId',
  ShieldColorTypeId = 'shieldColorTypeId',
  ParticularUseTypeId = 'particularUseTypeId',
  BrandTypeId = 'brandTypeId',
  ModelTypeId = 'modelTypeId',
  BodyTypeId = 'bodyTypeId',
  ColorTypeId = 'colorTypeId',
  VehicleInsuranceTypeId = 'vehicleInsuranceTypeId',
  Ownership = 'ownership',
  CompanyId = 'companyId',
}

export type VehicleScalarWhereInput = {
  AND?: Maybe<Array<VehicleScalarWhereInput>>;
  OR?: Maybe<Array<VehicleScalarWhereInput>>;
  NOT?: Maybe<Array<VehicleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  vin?: Maybe<StringFilter>;
  rootNumber?: Maybe<StringNullableFilter>;
  convadisVehicle?: Maybe<StringNullableFilter>;
  qnr?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  address?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringNullableFilter>;
  postCode?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  birthdate?: Maybe<DateTimeNullableFilter>;
  cantonalNotes?: Maybe<StringNullableFilter>;
  insurance?: Maybe<StringNullableFilter>;
  decreesAuthority?: Maybe<StringNullableFilter>;
  departmentEmissionCode?: Maybe<StringNullableFilter>;
  enabledSmsAlerts?: Maybe<BoolFilter>;
  capacity?: Maybe<FloatNullableFilter>;
  power?: Maybe<FloatNullableFilter>;
  curbWeight?: Maybe<FloatNullableFilter>;
  capacityPerKg?: Maybe<FloatNullableFilter>;
  payloadWeight?: Maybe<FloatNullableFilter>;
  grossWeight?: Maybe<FloatNullableFilter>;
  trainWeight?: Maybe<FloatNullableFilter>;
  towingWeight?: Maybe<FloatNullableFilter>;
  roofLoadWeight?: Maybe<FloatNullableFilter>;
  catalogPrice?: Maybe<FloatNullableFilter>;
  initialOdometer?: Maybe<FloatNullableFilter>;
  valueOfAccessories?: Maybe<FloatNullableFilter>;
  vehicleCode?: Maybe<StringNullableFilter>;
  plateNumber?: Maybe<StringNullableFilter>;
  trunkDimension?: Maybe<StringNullableFilter>;
  approvalType?: Maybe<StringNullableFilter>;
  fuelPassword?: Maybe<StringNullableFilter>;
  rules?: Maybe<StringNullableFilter>;
  currentLong?: Maybe<FloatNullableFilter>;
  currentLat?: Maybe<FloatNullableFilter>;
  bookingInformation?: Maybe<StringNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  ownInsurance?: Maybe<BoolFilter>;
  priceHour?: Maybe<FloatNullableFilter>;
  priceDay?: Maybe<FloatNullableFilter>;
  priceKm?: Maybe<FloatNullableFilter>;
  priceWeek?: Maybe<FloatNullableFilter>;
  includedKmHour?: Maybe<FloatNullableFilter>;
  includedKmDay?: Maybe<FloatNullableFilter>;
  includedKmWeek?: Maybe<FloatNullableFilter>;
  preBookingInterval?: Maybe<FloatNullableFilter>;
  postBookingInterval?: Maybe<FloatNullableFilter>;
  earlyOpenInterval?: Maybe<FloatNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicleDamageId?: Maybe<StringNullableFilter>;
  unpublishReason?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  commissioningAt?: Maybe<DateTimeNullableFilter>;
  testedAt?: Maybe<DateTimeNullableFilter>;
  cloudBoxxActivatedAt?: Maybe<DateTimeNullableFilter>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFilter>;
  smartcarVehicle?: Maybe<StringNullableFilter>;
  fuelTypeId?: Maybe<StringNullableFilter>;
  doorTypeId?: Maybe<StringNullableFilter>;
  vehicleTypeId?: Maybe<StringNullableFilter>;
  vehicleKindTypeId?: Maybe<StringNullableFilter>;
  transmissionTypeId?: Maybe<StringNullableFilter>;
  driveTypeId?: Maybe<StringNullableFilter>;
  seatTypeId?: Maybe<StringNullableFilter>;
  seatFrontTypeId?: Maybe<StringNullableFilter>;
  energyEfficiencyTypeId?: Maybe<StringNullableFilter>;
  emissionCodeTypeId?: Maybe<StringNullableFilter>;
  consumptionTypeId?: Maybe<StringNullableFilter>;
  shieldColorTypeId?: Maybe<StringNullableFilter>;
  particularUseTypeId?: Maybe<StringNullableFilter>;
  brandTypeId?: Maybe<StringNullableFilter>;
  modelTypeId?: Maybe<StringNullableFilter>;
  bodyTypeId?: Maybe<StringNullableFilter>;
  colorTypeId?: Maybe<StringNullableFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableFilter>;
  ownership?: Maybe<EnumOwnershipNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
};

export type VehicleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  vin?: Maybe<StringWithAggregatesFilter>;
  rootNumber?: Maybe<StringNullableWithAggregatesFilter>;
  convadisVehicle?: Maybe<StringNullableWithAggregatesFilter>;
  qnr?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringNullableWithAggregatesFilter>;
  lastName?: Maybe<StringNullableWithAggregatesFilter>;
  address?: Maybe<StringNullableWithAggregatesFilter>;
  houseNumber?: Maybe<StringNullableWithAggregatesFilter>;
  postCode?: Maybe<StringNullableWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  country?: Maybe<StringNullableWithAggregatesFilter>;
  birthdate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cantonalNotes?: Maybe<StringNullableWithAggregatesFilter>;
  insurance?: Maybe<StringNullableWithAggregatesFilter>;
  decreesAuthority?: Maybe<StringNullableWithAggregatesFilter>;
  departmentEmissionCode?: Maybe<StringNullableWithAggregatesFilter>;
  enabledSmsAlerts?: Maybe<BoolWithAggregatesFilter>;
  capacity?: Maybe<FloatNullableWithAggregatesFilter>;
  power?: Maybe<FloatNullableWithAggregatesFilter>;
  curbWeight?: Maybe<FloatNullableWithAggregatesFilter>;
  capacityPerKg?: Maybe<FloatNullableWithAggregatesFilter>;
  payloadWeight?: Maybe<FloatNullableWithAggregatesFilter>;
  grossWeight?: Maybe<FloatNullableWithAggregatesFilter>;
  trainWeight?: Maybe<FloatNullableWithAggregatesFilter>;
  towingWeight?: Maybe<FloatNullableWithAggregatesFilter>;
  roofLoadWeight?: Maybe<FloatNullableWithAggregatesFilter>;
  catalogPrice?: Maybe<FloatNullableWithAggregatesFilter>;
  initialOdometer?: Maybe<FloatNullableWithAggregatesFilter>;
  valueOfAccessories?: Maybe<FloatNullableWithAggregatesFilter>;
  vehicleCode?: Maybe<StringNullableWithAggregatesFilter>;
  plateNumber?: Maybe<StringNullableWithAggregatesFilter>;
  trunkDimension?: Maybe<StringNullableWithAggregatesFilter>;
  approvalType?: Maybe<StringNullableWithAggregatesFilter>;
  fuelPassword?: Maybe<StringNullableWithAggregatesFilter>;
  rules?: Maybe<StringNullableWithAggregatesFilter>;
  currentLong?: Maybe<FloatNullableWithAggregatesFilter>;
  currentLat?: Maybe<FloatNullableWithAggregatesFilter>;
  bookingInformation?: Maybe<StringNullableWithAggregatesFilter>;
  disabled?: Maybe<BoolWithAggregatesFilter>;
  ownInsurance?: Maybe<BoolWithAggregatesFilter>;
  priceHour?: Maybe<FloatNullableWithAggregatesFilter>;
  priceDay?: Maybe<FloatNullableWithAggregatesFilter>;
  priceKm?: Maybe<FloatNullableWithAggregatesFilter>;
  priceWeek?: Maybe<FloatNullableWithAggregatesFilter>;
  includedKmHour?: Maybe<FloatNullableWithAggregatesFilter>;
  includedKmDay?: Maybe<FloatNullableWithAggregatesFilter>;
  includedKmWeek?: Maybe<FloatNullableWithAggregatesFilter>;
  preBookingInterval?: Maybe<FloatNullableWithAggregatesFilter>;
  postBookingInterval?: Maybe<FloatNullableWithAggregatesFilter>;
  earlyOpenInterval?: Maybe<FloatNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleDamageId?: Maybe<StringNullableWithAggregatesFilter>;
  unpublishReason?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  commissioningAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  testedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cloudBoxxActivatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  connectionType?: Maybe<EnumVehicleConnectionTypeWithAggregatesFilter>;
  smartcarVehicle?: Maybe<StringNullableWithAggregatesFilter>;
  fuelTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  doorTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleKindTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  transmissionTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  driveTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  seatTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  seatFrontTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  energyEfficiencyTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  emissionCodeTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  consumptionTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  shieldColorTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  particularUseTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  brandTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  modelTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  bodyTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  colorTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  ownership?: Maybe<EnumOwnershipNullableWithAggregatesFilter>;
  companyId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VehicleStatus = {
  __typename?: 'VehicleStatus';
  immobilizer?: Maybe<Scalars['String']>;
  ignition?: Maybe<Scalars['String']>;
  keyfob?: Maybe<Scalars['String']>;
  rfid_tag_state_1?: Maybe<Scalars['String']>;
  rfid_tag_state_2?: Maybe<Scalars['String']>;
  central_lock?: Maybe<Scalars['String']>;
  fuel_level?: Maybe<Scalars['Float']>;
  battery?: Maybe<Scalars['Float']>;
  charging?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Float']>;
};

export type VehicleSumAggregate = {
  __typename?: 'VehicleSumAggregate';
  capacity?: Maybe<Scalars['Float']>;
  power?: Maybe<Scalars['Float']>;
  curbWeight?: Maybe<Scalars['Float']>;
  capacityPerKg?: Maybe<Scalars['Float']>;
  payloadWeight?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  trainWeight?: Maybe<Scalars['Float']>;
  towingWeight?: Maybe<Scalars['Float']>;
  roofLoadWeight?: Maybe<Scalars['Float']>;
  catalogPrice?: Maybe<Scalars['Float']>;
  initialOdometer?: Maybe<Scalars['Float']>;
  valueOfAccessories?: Maybe<Scalars['Float']>;
  currentLong?: Maybe<Scalars['Float']>;
  currentLat?: Maybe<Scalars['Float']>;
  priceHour?: Maybe<Scalars['Float']>;
  priceDay?: Maybe<Scalars['Float']>;
  priceKm?: Maybe<Scalars['Float']>;
  priceWeek?: Maybe<Scalars['Float']>;
  includedKmHour?: Maybe<Scalars['Float']>;
  includedKmDay?: Maybe<Scalars['Float']>;
  includedKmWeek?: Maybe<Scalars['Float']>;
  preBookingInterval?: Maybe<Scalars['Float']>;
  postBookingInterval?: Maybe<Scalars['Float']>;
  earlyOpenInterval?: Maybe<Scalars['Float']>;
};

export type VehicleSumOrderByAggregateInput = {
  capacity?: Maybe<SortOrder>;
  power?: Maybe<SortOrder>;
  curbWeight?: Maybe<SortOrder>;
  capacityPerKg?: Maybe<SortOrder>;
  payloadWeight?: Maybe<SortOrder>;
  grossWeight?: Maybe<SortOrder>;
  trainWeight?: Maybe<SortOrder>;
  towingWeight?: Maybe<SortOrder>;
  roofLoadWeight?: Maybe<SortOrder>;
  catalogPrice?: Maybe<SortOrder>;
  initialOdometer?: Maybe<SortOrder>;
  valueOfAccessories?: Maybe<SortOrder>;
  currentLong?: Maybe<SortOrder>;
  currentLat?: Maybe<SortOrder>;
  priceHour?: Maybe<SortOrder>;
  priceDay?: Maybe<SortOrder>;
  priceKm?: Maybe<SortOrder>;
  priceWeek?: Maybe<SortOrder>;
  includedKmHour?: Maybe<SortOrder>;
  includedKmDay?: Maybe<SortOrder>;
  includedKmWeek?: Maybe<SortOrder>;
  preBookingInterval?: Maybe<SortOrder>;
  postBookingInterval?: Maybe<SortOrder>;
  earlyOpenInterval?: Maybe<SortOrder>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleTypeCount>;
  vehicle: Array<Vehicle>;
};

export type VehicleTypeVehicleArgs = {
  where?: Maybe<VehicleWhereInput>;
  orderBy?: Maybe<Array<VehicleOrderByWithRelationInput>>;
  cursor?: Maybe<VehicleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<VehicleScalarFieldEnum>>;
};

export type VehicleTypeCount = {
  __typename?: 'VehicleTypeCount';
  vehicle: Scalars['Int'];
};

export type VehicleTypeCountAggregate = {
  __typename?: 'VehicleTypeCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  content: Scalars['Int'];
  _all: Scalars['Int'];
};

export type VehicleTypeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type VehicleTypeCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleCreateNestedManyWithoutVehicleTypeInput>;
};

export type VehicleTypeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type VehicleTypeCreateNestedOneWithoutVehicleInput = {
  create?: Maybe<VehicleTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<VehicleTypeCreateOrConnectWithoutVehicleInput>;
  connect?: Maybe<VehicleTypeWhereUniqueInput>;
};

export type VehicleTypeCreateOrConnectWithoutVehicleInput = {
  where: VehicleTypeWhereUniqueInput;
  create: VehicleTypeCreateWithoutVehicleInput;
};

export type VehicleTypeCreateWithoutVehicleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type VehicleTypeGroupBy = {
  __typename?: 'VehicleTypeGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  _count?: Maybe<VehicleTypeCountAggregate>;
  _min?: Maybe<VehicleTypeMinAggregate>;
  _max?: Maybe<VehicleTypeMaxAggregate>;
};

export type VehicleTypeListItem = {
  __typename?: 'VehicleTypeListItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  vehicleEquipments: Array<VehicleTypeListItem>;
};

export type VehicleTypeMaxAggregate = {
  __typename?: 'VehicleTypeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type VehicleTypeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type VehicleTypeMinAggregate = {
  __typename?: 'VehicleTypeMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type VehicleTypeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
};

export type VehicleTypeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  _count?: Maybe<VehicleTypeCountOrderByAggregateInput>;
  _max?: Maybe<VehicleTypeMaxOrderByAggregateInput>;
  _min?: Maybe<VehicleTypeMinOrderByAggregateInput>;
};

export type VehicleTypeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  vehicle?: Maybe<VehicleOrderByRelationAggregateInput>;
};

export type VehicleTypeRelationFilter = {
  is?: Maybe<VehicleTypeWhereInput>;
  isNot?: Maybe<VehicleTypeWhereInput>;
};

export enum VehicleTypeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
}

export type VehicleTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VehicleTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VehicleTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VehicleTypeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VehicleTypeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
  vehicle?: Maybe<VehicleUpdateManyWithoutVehicleTypeInput>;
};

export type VehicleTypeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type VehicleTypeUpdateOneWithoutVehicleInput = {
  create?: Maybe<VehicleTypeCreateWithoutVehicleInput>;
  connectOrCreate?: Maybe<VehicleTypeCreateOrConnectWithoutVehicleInput>;
  upsert?: Maybe<VehicleTypeUpsertWithoutVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleTypeWhereUniqueInput>;
  update?: Maybe<VehicleTypeUpdateWithoutVehicleInput>;
};

export type VehicleTypeUpdateWithoutVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  content?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type VehicleTypeUpsertWithoutVehicleInput = {
  update: VehicleTypeUpdateWithoutVehicleInput;
  create: VehicleTypeCreateWithoutVehicleInput;
};

export type VehicleTypeWhereInput = {
  AND?: Maybe<Array<VehicleTypeWhereInput>>;
  OR?: Maybe<Array<VehicleTypeWhereInput>>;
  NOT?: Maybe<Array<VehicleTypeWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringNullableFilter>;
  vehicle?: Maybe<VehicleListRelationFilter>;
};

export type VehicleTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type VehicleTypesDto = {
  __typename?: 'VehicleTypesDto';
  fuelTypes: Array<VehicleTypeListItem>;
  vehicleTypes: Array<VehicleTypeListItem>;
  transmissionTypes: Array<VehicleTypeListItem>;
  driveTypes: Array<VehicleTypeListItem>;
  seatTypes: Array<VehicleTypeListItem>;
  seatFrontTypes: Array<VehicleTypeListItem>;
  doorTypes: Array<VehicleTypeListItem>;
  energyEfficiencyTypes: Array<VehicleTypeListItem>;
  emissionCodeTypes: Array<VehicleTypeListItem>;
  consumptionTypes: Array<VehicleTypeListItem>;
  shieldColorTypes: Array<VehicleTypeListItem>;
  particularUseTypes: Array<VehicleTypeListItem>;
  brandTypes: Array<VehicleTypeListItem>;
  modelTypes: Array<VehicleTypeListItem>;
  chassisTypes: Array<VehicleTypeListItem>;
  bodyTypes: Array<VehicleTypeListItem>;
  colorTypes: Array<VehicleTypeListItem>;
  frontSeatsTypes: Array<VehicleTypeListItem>;
  approvalTypes: Array<VehicleTypeListItem>;
  equipmentTypes: Array<VehicleTypeListItem>;
  vehicleKindTypes: Array<VehicleTypeListItem>;
  unpublishReasonTypes: Array<VehicleTypeListItem>;
  revokeReasonTypes: Array<VehicleTypeListItem>;
  vehicleInsuranceType: Array<VehicleTypeListItem>;
};

export type VehicleUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
};

export type VehicleUpdateManyWithWhereWithoutBodyTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutBrandTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutChildSeatTypesInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutColorTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutCompanyInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutConsumptionTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutDoorTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutDriveTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutEmissionCodeTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutEnergyEfficiencyTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutFuelTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutModelTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutParticularUseTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutSeatFrontTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutSeatTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutShieldColorTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutTransmissionTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutUserInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutVehicleInsuranceTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutVehicleKindTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithWhereWithoutVehicleTypeInput = {
  where: VehicleScalarWhereInput;
  data: VehicleUpdateManyMutationInput;
};

export type VehicleUpdateManyWithoutBodyTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutBodyTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutBodyTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutBodyTypeInput>>;
  createMany?: Maybe<VehicleCreateManyBodyTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutBodyTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutBodyTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutBrandTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutBrandTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutBrandTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutBrandTypeInput>>;
  createMany?: Maybe<VehicleCreateManyBrandTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutBrandTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutBrandTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutChildSeatTypesInput = {
  create?: Maybe<Array<VehicleCreateWithoutChildSeatTypesInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutChildSeatTypesInput>
  >;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutChildSeatTypesInput>>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutChildSeatTypesInput>>;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutChildSeatTypesInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutColorTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutColorTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutColorTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutColorTypeInput>>;
  createMany?: Maybe<VehicleCreateManyColorTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutColorTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutColorTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<VehicleCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutCompanyInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<VehicleCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutCompanyInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutConsumptionTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutConsumptionTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutConsumptionTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutConsumptionTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyConsumptionTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutConsumptionTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutConsumptionTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutDoorTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutDoorTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutDoorTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutDoorTypeInput>>;
  createMany?: Maybe<VehicleCreateManyDoorTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutDoorTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutDoorTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutDriveTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutDriveTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutDriveTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutDriveTypeInput>>;
  createMany?: Maybe<VehicleCreateManyDriveTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutDriveTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutDriveTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutEmissionCodeTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutEmissionCodeTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutEmissionCodeTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutEmissionCodeTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyEmissionCodeTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutEmissionCodeTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutEmissionCodeTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutEnergyEfficiencyTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutEnergyEfficiencyTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutEnergyEfficiencyTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutEnergyEfficiencyTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyEnergyEfficiencyTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutEnergyEfficiencyTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutEnergyEfficiencyTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutFuelTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutFuelTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutFuelTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutFuelTypeInput>>;
  createMany?: Maybe<VehicleCreateManyFuelTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutFuelTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutFuelTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutModelTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutModelTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutModelTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutModelTypeInput>>;
  createMany?: Maybe<VehicleCreateManyModelTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutModelTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutModelTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutParticularUseTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutParticularUseTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutParticularUseTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutParticularUseTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyParticularUseTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutParticularUseTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutParticularUseTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutSeatFrontTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutSeatFrontTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutSeatFrontTypeInput>
  >;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutSeatFrontTypeInput>>;
  createMany?: Maybe<VehicleCreateManySeatFrontTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutSeatFrontTypeInput>>;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutSeatFrontTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutSeatTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutSeatTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutSeatTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutSeatTypeInput>>;
  createMany?: Maybe<VehicleCreateManySeatTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutSeatTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutSeatTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutShieldColorTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutShieldColorTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutShieldColorTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutShieldColorTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyShieldColorTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutShieldColorTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutShieldColorTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutTransmissionTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutTransmissionTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutTransmissionTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutTransmissionTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyTransmissionTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutTransmissionTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutTransmissionTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutUserInput = {
  create?: Maybe<Array<VehicleCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<VehicleCreateManyUserInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutVehicleInsuranceTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutVehicleInsuranceTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutVehicleInsuranceTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutVehicleInsuranceTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyVehicleInsuranceTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutVehicleInsuranceTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutVehicleInsuranceTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutVehicleKindTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutVehicleKindTypeInput>>;
  connectOrCreate?: Maybe<
    Array<VehicleCreateOrConnectWithoutVehicleKindTypeInput>
  >;
  upsert?: Maybe<
    Array<VehicleUpsertWithWhereUniqueWithoutVehicleKindTypeInput>
  >;
  createMany?: Maybe<VehicleCreateManyVehicleKindTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<
    Array<VehicleUpdateWithWhereUniqueWithoutVehicleKindTypeInput>
  >;
  updateMany?: Maybe<
    Array<VehicleUpdateManyWithWhereWithoutVehicleKindTypeInput>
  >;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateManyWithoutVehicleTypeInput = {
  create?: Maybe<Array<VehicleCreateWithoutVehicleTypeInput>>;
  connectOrCreate?: Maybe<Array<VehicleCreateOrConnectWithoutVehicleTypeInput>>;
  upsert?: Maybe<Array<VehicleUpsertWithWhereUniqueWithoutVehicleTypeInput>>;
  createMany?: Maybe<VehicleCreateManyVehicleTypeInputEnvelope>;
  set?: Maybe<Array<VehicleWhereUniqueInput>>;
  disconnect?: Maybe<Array<VehicleWhereUniqueInput>>;
  delete?: Maybe<Array<VehicleWhereUniqueInput>>;
  connect?: Maybe<Array<VehicleWhereUniqueInput>>;
  update?: Maybe<Array<VehicleUpdateWithWhereUniqueWithoutVehicleTypeInput>>;
  updateMany?: Maybe<Array<VehicleUpdateManyWithWhereWithoutVehicleTypeInput>>;
  deleteMany?: Maybe<Array<VehicleScalarWhereInput>>;
};

export type VehicleUpdateOneRequiredWithoutAvailabilityInput = {
  create?: Maybe<VehicleCreateWithoutAvailabilityInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutAvailabilityInput>;
  upsert?: Maybe<VehicleUpsertWithoutAvailabilityInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutAvailabilityInput>;
};

export type VehicleUpdateOneRequiredWithoutBookingVehicleDamagesInput = {
  create?: Maybe<VehicleCreateWithoutBookingVehicleDamagesInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutBookingVehicleDamagesInput>;
  upsert?: Maybe<VehicleUpsertWithoutBookingVehicleDamagesInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutBookingVehicleDamagesInput>;
};

export type VehicleUpdateOneRequiredWithoutBookingsInput = {
  create?: Maybe<VehicleCreateWithoutBookingsInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutBookingsInput>;
  upsert?: Maybe<VehicleUpsertWithoutBookingsInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutBookingsInput>;
};

export type VehicleUpdateOneRequiredWithoutSmartcarAuthInput = {
  create?: Maybe<VehicleCreateWithoutSmartcarAuthInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutSmartcarAuthInput>;
  upsert?: Maybe<VehicleUpsertWithoutSmartcarAuthInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutSmartcarAuthInput>;
};

export type VehicleUpdateOneRequiredWithoutVehicleCloudBoxxLogInput = {
  create?: Maybe<VehicleCreateWithoutVehicleCloudBoxxLogInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleCloudBoxxLogInput>;
  upsert?: Maybe<VehicleUpsertWithoutVehicleCloudBoxxLogInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutVehicleCloudBoxxLogInput>;
};

export type VehicleUpdateOneRequiredWithoutVehicleCloudBoxxStatusLogInput = {
  create?: Maybe<VehicleCreateWithoutVehicleCloudBoxxStatusLogInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleCloudBoxxStatusLogInput>;
  upsert?: Maybe<VehicleUpsertWithoutVehicleCloudBoxxStatusLogInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutVehicleCloudBoxxStatusLogInput>;
};

export type VehicleUpdateOneRequiredWithoutVehicleConnectionInput = {
  create?: Maybe<VehicleCreateWithoutVehicleConnectionInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleConnectionInput>;
  upsert?: Maybe<VehicleUpsertWithoutVehicleConnectionInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutVehicleConnectionInput>;
};

export type VehicleUpdateOneRequiredWithoutVehicleDamageInput = {
  create?: Maybe<VehicleCreateWithoutVehicleDamageInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleDamageInput>;
  upsert?: Maybe<VehicleUpsertWithoutVehicleDamageInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutVehicleDamageInput>;
};

export type VehicleUpdateOneRequiredWithoutVehicleEquipmentInput = {
  create?: Maybe<VehicleCreateWithoutVehicleEquipmentInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleEquipmentInput>;
  upsert?: Maybe<VehicleUpsertWithoutVehicleEquipmentInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutVehicleEquipmentInput>;
};

export type VehicleUpdateOneRequiredWithoutVehicleLocationInput = {
  create?: Maybe<VehicleCreateWithoutVehicleLocationInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehicleLocationInput>;
  upsert?: Maybe<VehicleUpsertWithoutVehicleLocationInput>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutVehicleLocationInput>;
};

export type VehicleUpdateOneWithoutBookingQuestionInput = {
  create?: Maybe<VehicleCreateWithoutBookingQuestionInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutBookingQuestionInput>;
  upsert?: Maybe<VehicleUpsertWithoutBookingQuestionInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutBookingQuestionInput>;
};

export type VehicleUpdateOneWithoutGateControllerRelVehiclesInput = {
  create?: Maybe<VehicleCreateWithoutGateControllerRelVehiclesInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutGateControllerRelVehiclesInput>;
  upsert?: Maybe<VehicleUpsertWithoutGateControllerRelVehiclesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutGateControllerRelVehiclesInput>;
};

export type VehicleUpdateOneWithoutGateControllerTransactionsInput = {
  create?: Maybe<VehicleCreateWithoutGateControllerTransactionsInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutGateControllerTransactionsInput>;
  upsert?: Maybe<VehicleUpsertWithoutGateControllerTransactionsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutGateControllerTransactionsInput>;
};

export type VehicleUpdateOneWithoutSharedVehicleGroupRelVehicleInput = {
  create?: Maybe<VehicleCreateWithoutSharedVehicleGroupRelVehicleInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutSharedVehicleGroupRelVehicleInput>;
  upsert?: Maybe<VehicleUpsertWithoutSharedVehicleGroupRelVehicleInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutSharedVehicleGroupRelVehicleInput>;
};

export type VehicleUpdateOneWithoutVehiclePhotosInput = {
  create?: Maybe<VehicleCreateWithoutVehiclePhotosInput>;
  connectOrCreate?: Maybe<VehicleCreateOrConnectWithoutVehiclePhotosInput>;
  upsert?: Maybe<VehicleUpsertWithoutVehiclePhotosInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<VehicleWhereUniqueInput>;
  update?: Maybe<VehicleUpdateWithoutVehiclePhotosInput>;
};

export type VehicleUpdateWithWhereUniqueWithoutBodyTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutBodyTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutBrandTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutBrandTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutChildSeatTypesInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutChildSeatTypesInput;
};

export type VehicleUpdateWithWhereUniqueWithoutColorTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutColorTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutCompanyInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutCompanyInput;
};

export type VehicleUpdateWithWhereUniqueWithoutConsumptionTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutConsumptionTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutDoorTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutDoorTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutDriveTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutDriveTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutEmissionCodeTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutEmissionCodeTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutEnergyEfficiencyTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutEnergyEfficiencyTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutFuelTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutFuelTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutModelTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutModelTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutParticularUseTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutParticularUseTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutSeatFrontTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutSeatFrontTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutSeatTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutSeatTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutShieldColorTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutShieldColorTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutTransmissionTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutTransmissionTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutUserInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutUserInput;
};

export type VehicleUpdateWithWhereUniqueWithoutVehicleInsuranceTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutVehicleInsuranceTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutVehicleKindTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutVehicleKindTypeInput;
};

export type VehicleUpdateWithWhereUniqueWithoutVehicleTypeInput = {
  where: VehicleWhereUniqueInput;
  data: VehicleUpdateWithoutVehicleTypeInput;
};

export type VehicleUpdateWithoutAvailabilityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutBodyTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutBookingQuestionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutBookingVehicleDamagesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutBookingsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutBrandTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutChildSeatTypesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutColorTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutConsumptionTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutDoorTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutDriveTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutEmissionCodeTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutEnergyEfficiencyTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutFuelTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutGateControllerRelVehiclesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutGateControllerTransactionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutModelTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutParticularUseTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutSeatFrontTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutSeatTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutSharedVehicleGroupRelVehicleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutShieldColorTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutSmartcarAuthInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutTransmissionTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleCloudBoxxLogInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleCloudBoxxStatusLogInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleConnectionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleDamageInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleEquipmentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleInsuranceTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleKindTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleLocationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehiclePhotosInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleType?: Maybe<VehicleTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpdateWithoutVehicleTypeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  vin?: Maybe<StringFieldUpdateOperationsInput>;
  rootNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  convadisVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  qnr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  postCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  birthdate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cantonalNotes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  insurance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  decreesAuthority?: Maybe<NullableStringFieldUpdateOperationsInput>;
  departmentEmissionCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enabledSmsAlerts?: Maybe<BoolFieldUpdateOperationsInput>;
  capacity?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  power?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  curbWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  capacityPerKg?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  payloadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  grossWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  trainWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  towingWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  roofLoadWeight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  catalogPrice?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  initialOdometer?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  valueOfAccessories?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  vehicleCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  plateNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  trunkDimension?: Maybe<NullableStringFieldUpdateOperationsInput>;
  approvalType?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelPassword?: Maybe<NullableStringFieldUpdateOperationsInput>;
  rules?: Maybe<NullableStringFieldUpdateOperationsInput>;
  currentLong?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  currentLat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bookingInformation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  ownInsurance?: Maybe<BoolFieldUpdateOperationsInput>;
  priceHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceKm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  priceWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmHour?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmDay?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  includedKmWeek?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  preBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  postBookingInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  earlyOpenInterval?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutVehiclesInput>;
  vehicleDamage?: Maybe<VehicleDamageUpdateManyWithoutVehicleInput>;
  vehicleDamageId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unpublishReason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  commissioningAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  testedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cloudBoxxActivatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFieldUpdateOperationsInput>;
  smartcarVehicle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fuelType?: Maybe<FuelTypeUpdateOneWithoutVehicleInput>;
  doorType?: Maybe<DoorTypeUpdateOneWithoutVehicleInput>;
  vehicleKindType?: Maybe<VehicleKindTypeUpdateOneWithoutVehicleInput>;
  transmissionType?: Maybe<TransmissionTypeUpdateOneWithoutVehicleInput>;
  driveType?: Maybe<DriveTypeUpdateOneWithoutVehicleInput>;
  seatType?: Maybe<SeatTypeUpdateOneWithoutVehicleInput>;
  seatFrontType?: Maybe<SeatFrontTypeUpdateOneWithoutVehicleInput>;
  childSeatTypes?: Maybe<ChildSeatTypeUpdateManyWithoutVehicleInput>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeUpdateOneWithoutVehicleInput>;
  emissionCodeType?: Maybe<EmissionCodeTypeUpdateOneWithoutVehicleInput>;
  consumptionType?: Maybe<ConsumptionTypeUpdateOneWithoutVehicleInput>;
  shieldColorType?: Maybe<ShieldColorTypeUpdateOneWithoutVehicleInput>;
  particularUseType?: Maybe<ParticularUseTypeUpdateOneWithoutVehicleInput>;
  brandType?: Maybe<BrandTypeUpdateOneWithoutVehicleInput>;
  modelType?: Maybe<ModelTypeUpdateOneWithoutVehicleInput>;
  bodyType?: Maybe<BodyTypeUpdateOneWithoutVehicleInput>;
  colorType?: Maybe<ColorTypeUpdateOneWithoutVehicleInput>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeUpdateOneWithoutVehiclesInput>;
  ownership?: Maybe<NullableEnumOwnershipFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneWithoutVehicleInput>;
  vehiclePhotos?: Maybe<VehiclePhotoUpdateManyWithoutVehicleInput>;
  bookings?: Maybe<BookingUpdateManyWithoutVehicleInput>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesUpdateManyWithoutVehicleInput>;
  vehicleEquipment?: Maybe<VehicleEquipmentUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogUpdateManyWithoutVehicleInput>;
  vehicleLocation?: Maybe<VehicleLocationUpdateManyWithoutVehicleInput>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogUpdateManyWithoutVehicleInput>;
  availability?: Maybe<AvailabilityUpdateManyWithoutVehicleInput>;
  bookingQuestion?: Maybe<BookingQuestionUpdateManyWithoutVehicleInput>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleUpdateManyWithoutVehicleInput>;
  gateControllerTransactions?: Maybe<GateControllerTransactionUpdateManyWithoutVehicleInput>;
  smartcarAuth?: Maybe<SmartcarAuthUpdateManyWithoutVehicleInput>;
  vehicleConnection?: Maybe<VehicleConnectionUpdateManyWithoutVehicleInput>;
};

export type VehicleUpsertWithWhereUniqueWithoutBodyTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutBodyTypeInput;
  create: VehicleCreateWithoutBodyTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutBrandTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutBrandTypeInput;
  create: VehicleCreateWithoutBrandTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutChildSeatTypesInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutChildSeatTypesInput;
  create: VehicleCreateWithoutChildSeatTypesInput;
};

export type VehicleUpsertWithWhereUniqueWithoutColorTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutColorTypeInput;
  create: VehicleCreateWithoutColorTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutCompanyInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutCompanyInput;
  create: VehicleCreateWithoutCompanyInput;
};

export type VehicleUpsertWithWhereUniqueWithoutConsumptionTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutConsumptionTypeInput;
  create: VehicleCreateWithoutConsumptionTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutDoorTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutDoorTypeInput;
  create: VehicleCreateWithoutDoorTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutDriveTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutDriveTypeInput;
  create: VehicleCreateWithoutDriveTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutEmissionCodeTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutEmissionCodeTypeInput;
  create: VehicleCreateWithoutEmissionCodeTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutEnergyEfficiencyTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutEnergyEfficiencyTypeInput;
  create: VehicleCreateWithoutEnergyEfficiencyTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutFuelTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutFuelTypeInput;
  create: VehicleCreateWithoutFuelTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutModelTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutModelTypeInput;
  create: VehicleCreateWithoutModelTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutParticularUseTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutParticularUseTypeInput;
  create: VehicleCreateWithoutParticularUseTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutSeatFrontTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutSeatFrontTypeInput;
  create: VehicleCreateWithoutSeatFrontTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutSeatTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutSeatTypeInput;
  create: VehicleCreateWithoutSeatTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutShieldColorTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutShieldColorTypeInput;
  create: VehicleCreateWithoutShieldColorTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutTransmissionTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutTransmissionTypeInput;
  create: VehicleCreateWithoutTransmissionTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutUserInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutUserInput;
  create: VehicleCreateWithoutUserInput;
};

export type VehicleUpsertWithWhereUniqueWithoutVehicleInsuranceTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutVehicleInsuranceTypeInput;
  create: VehicleCreateWithoutVehicleInsuranceTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutVehicleKindTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutVehicleKindTypeInput;
  create: VehicleCreateWithoutVehicleKindTypeInput;
};

export type VehicleUpsertWithWhereUniqueWithoutVehicleTypeInput = {
  where: VehicleWhereUniqueInput;
  update: VehicleUpdateWithoutVehicleTypeInput;
  create: VehicleCreateWithoutVehicleTypeInput;
};

export type VehicleUpsertWithoutAvailabilityInput = {
  update: VehicleUpdateWithoutAvailabilityInput;
  create: VehicleCreateWithoutAvailabilityInput;
};

export type VehicleUpsertWithoutBookingQuestionInput = {
  update: VehicleUpdateWithoutBookingQuestionInput;
  create: VehicleCreateWithoutBookingQuestionInput;
};

export type VehicleUpsertWithoutBookingVehicleDamagesInput = {
  update: VehicleUpdateWithoutBookingVehicleDamagesInput;
  create: VehicleCreateWithoutBookingVehicleDamagesInput;
};

export type VehicleUpsertWithoutBookingsInput = {
  update: VehicleUpdateWithoutBookingsInput;
  create: VehicleCreateWithoutBookingsInput;
};

export type VehicleUpsertWithoutGateControllerRelVehiclesInput = {
  update: VehicleUpdateWithoutGateControllerRelVehiclesInput;
  create: VehicleCreateWithoutGateControllerRelVehiclesInput;
};

export type VehicleUpsertWithoutGateControllerTransactionsInput = {
  update: VehicleUpdateWithoutGateControllerTransactionsInput;
  create: VehicleCreateWithoutGateControllerTransactionsInput;
};

export type VehicleUpsertWithoutSharedVehicleGroupRelVehicleInput = {
  update: VehicleUpdateWithoutSharedVehicleGroupRelVehicleInput;
  create: VehicleCreateWithoutSharedVehicleGroupRelVehicleInput;
};

export type VehicleUpsertWithoutSmartcarAuthInput = {
  update: VehicleUpdateWithoutSmartcarAuthInput;
  create: VehicleCreateWithoutSmartcarAuthInput;
};

export type VehicleUpsertWithoutVehicleCloudBoxxLogInput = {
  update: VehicleUpdateWithoutVehicleCloudBoxxLogInput;
  create: VehicleCreateWithoutVehicleCloudBoxxLogInput;
};

export type VehicleUpsertWithoutVehicleCloudBoxxStatusLogInput = {
  update: VehicleUpdateWithoutVehicleCloudBoxxStatusLogInput;
  create: VehicleCreateWithoutVehicleCloudBoxxStatusLogInput;
};

export type VehicleUpsertWithoutVehicleConnectionInput = {
  update: VehicleUpdateWithoutVehicleConnectionInput;
  create: VehicleCreateWithoutVehicleConnectionInput;
};

export type VehicleUpsertWithoutVehicleDamageInput = {
  update: VehicleUpdateWithoutVehicleDamageInput;
  create: VehicleCreateWithoutVehicleDamageInput;
};

export type VehicleUpsertWithoutVehicleEquipmentInput = {
  update: VehicleUpdateWithoutVehicleEquipmentInput;
  create: VehicleCreateWithoutVehicleEquipmentInput;
};

export type VehicleUpsertWithoutVehicleLocationInput = {
  update: VehicleUpdateWithoutVehicleLocationInput;
  create: VehicleCreateWithoutVehicleLocationInput;
};

export type VehicleUpsertWithoutVehiclePhotosInput = {
  update: VehicleUpdateWithoutVehiclePhotosInput;
  create: VehicleCreateWithoutVehiclePhotosInput;
};

export type VehicleWhereInput = {
  AND?: Maybe<Array<VehicleWhereInput>>;
  OR?: Maybe<Array<VehicleWhereInput>>;
  NOT?: Maybe<Array<VehicleWhereInput>>;
  id?: Maybe<StringFilter>;
  vin?: Maybe<StringFilter>;
  rootNumber?: Maybe<StringNullableFilter>;
  convadisVehicle?: Maybe<StringNullableFilter>;
  qnr?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  address?: Maybe<StringNullableFilter>;
  houseNumber?: Maybe<StringNullableFilter>;
  postCode?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  birthdate?: Maybe<DateTimeNullableFilter>;
  cantonalNotes?: Maybe<StringNullableFilter>;
  insurance?: Maybe<StringNullableFilter>;
  decreesAuthority?: Maybe<StringNullableFilter>;
  departmentEmissionCode?: Maybe<StringNullableFilter>;
  enabledSmsAlerts?: Maybe<BoolFilter>;
  capacity?: Maybe<FloatNullableFilter>;
  power?: Maybe<FloatNullableFilter>;
  curbWeight?: Maybe<FloatNullableFilter>;
  capacityPerKg?: Maybe<FloatNullableFilter>;
  payloadWeight?: Maybe<FloatNullableFilter>;
  grossWeight?: Maybe<FloatNullableFilter>;
  trainWeight?: Maybe<FloatNullableFilter>;
  towingWeight?: Maybe<FloatNullableFilter>;
  roofLoadWeight?: Maybe<FloatNullableFilter>;
  catalogPrice?: Maybe<FloatNullableFilter>;
  initialOdometer?: Maybe<FloatNullableFilter>;
  valueOfAccessories?: Maybe<FloatNullableFilter>;
  vehicleCode?: Maybe<StringNullableFilter>;
  plateNumber?: Maybe<StringNullableFilter>;
  trunkDimension?: Maybe<StringNullableFilter>;
  approvalType?: Maybe<StringNullableFilter>;
  fuelPassword?: Maybe<StringNullableFilter>;
  rules?: Maybe<StringNullableFilter>;
  currentLong?: Maybe<FloatNullableFilter>;
  currentLat?: Maybe<FloatNullableFilter>;
  bookingInformation?: Maybe<StringNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  ownInsurance?: Maybe<BoolFilter>;
  priceHour?: Maybe<FloatNullableFilter>;
  priceDay?: Maybe<FloatNullableFilter>;
  priceKm?: Maybe<FloatNullableFilter>;
  priceWeek?: Maybe<FloatNullableFilter>;
  includedKmHour?: Maybe<FloatNullableFilter>;
  includedKmDay?: Maybe<FloatNullableFilter>;
  includedKmWeek?: Maybe<FloatNullableFilter>;
  preBookingInterval?: Maybe<FloatNullableFilter>;
  postBookingInterval?: Maybe<FloatNullableFilter>;
  earlyOpenInterval?: Maybe<FloatNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
  vehicleDamage?: Maybe<VehicleDamageListRelationFilter>;
  vehicleDamageId?: Maybe<StringNullableFilter>;
  unpublishReason?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  commissioningAt?: Maybe<DateTimeNullableFilter>;
  testedAt?: Maybe<DateTimeNullableFilter>;
  cloudBoxxActivatedAt?: Maybe<DateTimeNullableFilter>;
  connectionType?: Maybe<EnumVehicleConnectionTypeFilter>;
  smartcarVehicle?: Maybe<StringNullableFilter>;
  fuelType?: Maybe<FuelTypeRelationFilter>;
  fuelTypeId?: Maybe<StringNullableFilter>;
  doorType?: Maybe<DoorTypeRelationFilter>;
  doorTypeId?: Maybe<StringNullableFilter>;
  vehicleType?: Maybe<VehicleTypeRelationFilter>;
  vehicleTypeId?: Maybe<StringNullableFilter>;
  vehicleKindType?: Maybe<VehicleKindTypeRelationFilter>;
  vehicleKindTypeId?: Maybe<StringNullableFilter>;
  transmissionType?: Maybe<TransmissionTypeRelationFilter>;
  transmissionTypeId?: Maybe<StringNullableFilter>;
  driveType?: Maybe<DriveTypeRelationFilter>;
  driveTypeId?: Maybe<StringNullableFilter>;
  seatType?: Maybe<SeatTypeRelationFilter>;
  seatTypeId?: Maybe<StringNullableFilter>;
  seatFrontType?: Maybe<SeatFrontTypeRelationFilter>;
  seatFrontTypeId?: Maybe<StringNullableFilter>;
  childSeatTypes?: Maybe<ChildSeatTypeListRelationFilter>;
  energyEfficiencyType?: Maybe<EnergyEfficiencyTypeRelationFilter>;
  energyEfficiencyTypeId?: Maybe<StringNullableFilter>;
  emissionCodeType?: Maybe<EmissionCodeTypeRelationFilter>;
  emissionCodeTypeId?: Maybe<StringNullableFilter>;
  consumptionType?: Maybe<ConsumptionTypeRelationFilter>;
  consumptionTypeId?: Maybe<StringNullableFilter>;
  shieldColorType?: Maybe<ShieldColorTypeRelationFilter>;
  shieldColorTypeId?: Maybe<StringNullableFilter>;
  particularUseType?: Maybe<ParticularUseTypeRelationFilter>;
  particularUseTypeId?: Maybe<StringNullableFilter>;
  brandType?: Maybe<BrandTypeRelationFilter>;
  brandTypeId?: Maybe<StringNullableFilter>;
  modelType?: Maybe<ModelTypeRelationFilter>;
  modelTypeId?: Maybe<StringNullableFilter>;
  bodyType?: Maybe<BodyTypeRelationFilter>;
  bodyTypeId?: Maybe<StringNullableFilter>;
  colorType?: Maybe<ColorTypeRelationFilter>;
  colorTypeId?: Maybe<StringNullableFilter>;
  vehicleInsuranceType?: Maybe<VehicleInsuranceTypeRelationFilter>;
  vehicleInsuranceTypeId?: Maybe<StringNullableFilter>;
  ownership?: Maybe<EnumOwnershipNullableFilter>;
  company?: Maybe<CompanyRelationFilter>;
  companyId?: Maybe<StringNullableFilter>;
  vehiclePhotos?: Maybe<VehiclePhotoListRelationFilter>;
  bookings?: Maybe<BookingListRelationFilter>;
  bookingVehicleDamages?: Maybe<BookingVehicleDamagesListRelationFilter>;
  vehicleEquipment?: Maybe<VehicleEquipmentListRelationFilter>;
  vehicleCloudBoxxLog?: Maybe<VehicleCloudBoxxLogListRelationFilter>;
  vehicleLocation?: Maybe<VehicleLocationListRelationFilter>;
  vehicleCloudBoxxStatusLog?: Maybe<VehicleCloudBoxxStatusLogListRelationFilter>;
  availability?: Maybe<AvailabilityListRelationFilter>;
  bookingQuestion?: Maybe<BookingQuestionListRelationFilter>;
  sharedVehicleGroupRelVehicle?: Maybe<SharedVehicleGroupRelVehicleListRelationFilter>;
  gateControllerRelVehicles?: Maybe<GateControllerRelVehicleListRelationFilter>;
  gateControllerTransactions?: Maybe<GateControllerTransactionListRelationFilter>;
  smartcarAuth?: Maybe<SmartcarAuthListRelationFilter>;
  vehicleConnection?: Maybe<VehicleConnectionListRelationFilter>;
};

export type VehicleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};
