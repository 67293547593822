import {
  AutocompleteInput,
  Datagrid,
  DateTimeInput,
  Filter,
  FunctionField,
  Link,
  List,
  NumberField,
  ReferenceInput,
  downloadCSV,
  useGetIdentity,
} from 'react-admin';

import CustomPagination from '../../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { UserRole } from '../../../@generated/schemas';
import { gql } from '@apollo/client';
import jsonExport from 'jsonexport/dist';

export const VehicleReportFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput source="vehicleId" reference="Vehicle" alwaysOn>
      <AutocompleteInput
        optionText={(choice: any) => choice?.vin}
        style={{ width: 300 }}
      />
    </ReferenceInput>
    <ReferenceInput source="ownerId" reference="User" alwaysOn>
      <AutocompleteInput
        optionText={(choice: any) =>
          `${choice?.firstName} ${choice?.lastName} (${choice?.email})`
        }
        style={{ width: 300 }}
      />
    </ReferenceInput>
    <DateTimeInput source="startDate" label="Start date" alwaysOn />
    <DateTimeInput source="endDate" label="End date" alwaysOn />
  </Filter>
);

const customExporter = (items) => {
  const listForExport = items.map((row) => ({
    Vehicle: `${row.vehicle.vin} (${row.vehicle.brandType.name}, ${row.vehicle.modelType.name})`,
    Owner: row?.vehicle?.user?.email,
    'Total bookings': row.bookingCount,
    'Total km': row.totalKm,
    'Total income': row.totalIncome,
    'Total vehicle owner': row.totalVehicleOwner,
  }));

  jsonExport(listForExport, { rowDelimiter: ';' }, (err, csv) =>
    downloadCSV(csv, 'Vehicle report'),
  );
};

export const VehicleReportList = (props: any) => {
  const { identity } = useGetIdentity();

  const defaultFilter = {
    vehicle:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? {
            is: {
              user: {
                is: {
                  companyId: {
                    equals: identity.companyId
                  },
                },
              },
            },
          }
        : {},
  };

  console.log('defaultFilter', defaultFilter);

  return (
    <List
      {...props}
      exporter={customExporter}
      filter={defaultFilter}
      filters={<VehicleReportFilter />}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          source="vehicle"
          label="Vehicle"
          render={(row) => {
            return `${row.vehicle.vin} (${row.vehicle.brandType.name}, ${row.vehicle.modelType.name})`;
          }}
        />
        <FunctionField
          source="owner"
          label="Owner"
          render={(row) => {
            return (
              <Link to={`/User/${row?.vehicle?.user?.id}`}>
                {row?.vehicle?.user?.email}
              </Link>
            );
          }}
        />
        <NumberField source="bookingCount" />
        <FunctionField source="totalKm" render={(row) => `${row.totalKm}km`} />
        <FunctionField
          source="totalIncome"
          render={(row) => `CHF ${row.totalIncome}`}
        />
        <FunctionField
          source="totalVehicleOwner"
          render={(row) => `CHF ${row.totalVehicleOwner}`}
        />
      </Datagrid>
    </List>
  );
};

export const VehicleReport: ResourceView = {
  resource: 'VehicleReport',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment VehicleReportFragment on VehicleReport {
          vehicle {
            id
            vin
            user {
              id
              email
            }
            brandType {
              id
              name
            }
            modelType {
              id
              name
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment VehicleReportFragment on VehicleReport {
          vehicle {
            id
            vin
            user {
              id
              email
            }
            brandType {
              id
              name
            }
            modelType {
              id
              name
            }
          }
        }
      `,
    },
  },
};
